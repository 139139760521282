import { Component } from '@angular/core';
import { SetupService } from '../../../shared/service/setup.service';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'src/app/components/shared/service/shared.service';


@Component({
  selector: 'app-accounting-setup',
  templateUrl: './accounting-setup.component.html',
  styleUrls: ['./accounting-setup.component.css']
})
export class AccountingSetupComponent {

  constructor(
    public _sharedSvc: SharedService,
    private _SetupService: SetupService,
    private nav: NgbNav,
  ) { }

  submittAccountSetup() {
    this._SetupService.saveRefCdGroup().subscribe(data => {
      alert('acc-chng submitted')
    })
  }
  moveToNextTab() {
    if (this.nav) {
      this._sharedSvc.showSpinner3();
      const currentTab = this.nav.activeId || 1;
      const nextTab = currentTab < this.nav.items.length ? currentTab + 1 : 1;
      this.nav.select(nextTab);
      this._sharedSvc.hideSpinner3();
    }
  }
}
