import { Component } from '@angular/core';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { SetupService } from '../../../shared/service/setup.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'src/app/components/shared/service/shared.service';
import { FormBuilder, Validators } from '@angular/forms';
import { PicklistService } from 'src/app/components/shared/pickList/picklist.service';
import { TranslateService } from '@ngx-translate/core';
import { ProductServices } from '../../../shared/service/product.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-basic-info',
  templateUrl: './basic-info.component.html',
  styleUrls: ['./basic-info.component.css']
})
export class BasicInfoComponent {


  basicFormFormdata: any;


  constructor(
    private _SetupService: SetupService,
    private modalService: NgbModal,
    public _sharedSvc: SharedService,
    private formBuilder: FormBuilder,
    public _picklistService: PicklistService,
    public translate: TranslateService,
    private nav: NgbNav,
    private route: ActivatedRoute,

    public _productServices:ProductServices

  ) { }

  ngOnInit(): void {
    this.basicFormFormdata = this.formBuilder.group({
      prdId: ['', Validators.required],
      prdNm: ['', Validators.required],
      irrFlg: ['', Validators.required],
      irrVal: ['', Validators.required],
      mltCrdtFlg: ['', Validators.required],
      prdTypKey: ['', Validators.required],
      rndngScl: ['', Validators.required],
      rndngAdj: ['', Validators.required],
      pdcNum: ['', Validators.required],
      prdCmnt: ['', Validators.required],
      prdSts: ["", Validators.required]
    })

    this.basicFormFormdata
    .get("irrVal")
    .disable({ emitEvent: false });

    this.basicFormFormdata.controls['irrFlg'].valueChanges.subscribe(value => {
      if(value === "1"){
        this.basicFormFormdata
        .get("irrVal")
        .enable({ emitEvent: false });
      } else {
        this.basicFormFormdata
        .get("irrVal")
        .disable({ emitEvent: false });
      }
    });

    if(this.route.snapshot.paramMap.get("id")){
      console.log("param ID " + this.route.snapshot.paramMap.get("id"));
      this.getProductFromID(this.route.snapshot.paramMap.get("id"))
    } 


  }
  get prdId() { return this.basicFormFormdata.get("prdId") }
  get prdNm() { return this.basicFormFormdata.get("prdNm") }
  get irrFlg() { return this.basicFormFormdata.get("irrFlg") }
  get irrVal() { return this.basicFormFormdata.get("irrVal") }
  get prdTypKey() { return this.basicFormFormdata.get("prdTypKey") }
  get rndngScl() { return this.basicFormFormdata.get("rndngScl") }
  get rndngAdj() { return this.basicFormFormdata.get("rndngAdj") }
  get pdcNum() { return this.basicFormFormdata.get("pdcNum") }
  get prdCmnt() { return this.basicFormFormdata.get("prdCmnt") }
  get prdSts() { return this.basicFormFormdata.get("prdSts") }




  submitBasicForm(formData: any) {
    if (this.basicFormFormdata.invalid) {
      for (const control of Object.keys(this.basicFormFormdata.controls)) {
        this.basicFormFormdata.controls[control].markAsTouched();
      }
      return;
    }

    let data = {
      ...this.basicFormFormdata.value,
      irrVal:Number(this.basicFormFormdata.value.irrVal || 0),
      mltCrdtFlg:Number(this.basicFormFormdata.value.mltCrdtFlg),
      pdcNum:Number(this.basicFormFormdata.value.pdcNum),
      prdGrpSeq:Number(localStorage.getItem("prdGrpSeq")),
      prdTypKey:Number(this.basicFormFormdata.value.prdTypKey),
      rndngAdj:Number(this.basicFormFormdata.value.rndngAdj),
      rndngScl:Number(this.basicFormFormdata.value.rndngScl),
      irrFlg:Number(this.basicFormFormdata.value.irrFlg),
      fndByKey:0,
      prdSeq:null,
      delFlg: 0
    }

    delete data["prdSts"];
   
    this._productServices.saveProductModel = data;

    this._productServices.save_product().subscribe({
      next: response => {
        if (response) {
          this._sharedSvc.success(this.translate.instant('toast.productSaved'));
          this._sharedSvc.hideSpinner3();
          this.modalService.dismissAll();
        }
      },
      error: e => {
        this._sharedSvc.hideSpinner3();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    })
  }


  getProductFromID(key:any){
    this._productServices.get_product(Number(key)).subscribe({
      next: response => {
        if (response) {
          this._sharedSvc.hideSpinner3();
          console.log(response)
          //@ts-ignore
          this.basicFormFormdata.patchValue({...response,irrFlg: response.irrFlg === 1 ? "1" : "0"})
        }
      },
      error: e => {
        this._sharedSvc.hideSpinner3();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    })
  }



  moveToNextTab() {
    if (this.nav) {
      this._sharedSvc.showSpinner3();
      const currentTab = this.nav.activeId || 1;
      const nextTab = currentTab < this.nav.items.length ? currentTab + 1 : 1;
      this.nav.select(nextTab);
      this._sharedSvc.hideSpinner3();
    }
  }
}
