<!-- <div class="row mb-3">
    <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="add-button" (click)="openModal(disbursement_upFront)"><i class="fa fa-plus"></i></div>
    </div>
</div> -->

<div class="table-responsive">
  <table class="table table-bordered">
    <thead>
      <tr>
        <td>{{ "vaultMangement.serialNo" | translate }}</td>
        <td>{{ "disbursementUpfrontRecovery.detail" | translate }}</td>
        <td>{{ "common.description" | translate }}</td>
        <td>{{ "common.amount" | translate }}</td>
        <td>{{ "common.status" | translate }}</td>
        <!-- <td class="text-center">Action</td> -->
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let x of upfront; let i = index">
        <td>{{ i + 1 }}</td>
        <td>{{ x?.chargeDetail }}</td>
        <td>{{ x?.dscription }}</td>
        <td>{{ x?.amount }}</td>
        <td>{{ x?.status }}</td>
        <!-- <td class="text-center" [ngClass]="{'disabled':x.post}">
                    <div class="action-inner" (click)="openModal(disbursement_upFront,i)">
                        <i class="fa-solid fa-rupee-sign"></i>
                    </div>
                </td> -->
      </tr>
    </tbody>
  </table>
  <div class="row mb-3">
    <div class="col-sm-12 col-md-12 col-lg-12">
      <div class="upfront-recovery">
        <p>{{ "common.totalAmount" | translate }}</p>
        <p class="clrbfbfce">{{ total }}&nbsp;&nbsp;&nbsp;</p>
      </div>
    </div>
  </div>
  <!-- <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-6">
            <span class="clr-9d9a9a">Showing 1 to 3 of 3 entries</span>
        </div>

        <div class="col-sm-12 col-md-12 col-lg-6">
            <div class="float-right">
                <ngb-pagination [(page)]="_sharedSvc.page"
                    [pageSize]="_sharedSvc.pageSize" [maxSize]="10" ngDefaultControl
                    [collectionSize]="_sharedSvc.collectionSize" [rotate]="true"
                    (pageChange)="_clintOnboardingSvc.pageChange()">
                    <ng-template ngbPaginationPrevious>Prev</ng-template>
                    <ng-template ngbPaginationNext>Next</ng-template>
                </ngb-pagination>
            </div>
        </div>
    </div> -->

  <div class="row mb-3">
    <div class="col-sm-12 col-md-12 col-lg-12">
      <div class="button_div">
        <button class="btn login-button-class" (click)="UpdateDISBURSESTS()">
          {{ "disbursementUpfrontRecovery.disburseCredit" | translate }}
        </button>
        <!-- <button class=" btn login-button-class secondaryBtn">SAVE</button> -->
      </div>
    </div>
  </div>
</div>

<ng-template
  id="display-modal"
  #disbursement_upFront
  let-c="close"
  let-d="dismiss"
>
  <div class="modal-body">
    <div class="p-3 pb-0">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center">
            <h4>
              {{
                "disbursementUpfrontRecovery.disburseCreditApplication"
                  | translate
              }}
            </h4>
          </div>
        </div>
      </div>
      <form>
        <div class="row mb-3">
          <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
            <div>
              <h4 class="clr5c87ef">
                {{
                  "disbursementUpfrontRecovery.postTransactionAlert" | translate
                }}
                <span
                  ><input type="checkbox" class="form-check-input pointer"
                /></span>
              </h4>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="button_div">
              <button class="btn login-button-class" (click)="changeStatus()">
                {{ "common.ok" | translate }}
              </button>
              <button
                class="btn login-button-class cancelBtn"
                (click)="d('Cross click')"
                type="reset"
              >
                {{ "common.cancel" | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
