<section>
    <div class="sidebar" [class.sidebar-close]="!_sharedSvc.openSidebar">
        <app-sidebar></app-sidebar>
    </div>
    <div class="home-section">
        <div class="home-main" [class.sidebar-close_home-main]="!_sharedSvc.openSidebar">
            <app-navbar></app-navbar>

            <div class="home-view" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
                [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
                <section>
                    <div class="row mb-3">
                        <div class="col-sm-8 col-md-8 col-lg-8">
                            <h4> {{'closing.closingType' | translate}}</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <div class="dashboard-card loan-table">
                                <ng-container>
                                    <div class="row mb-3">
                                        <div class="col-sm-12 col-md-12 col-lg-12">
                                            <h4>{{'sideBarMenu.disbursement' | translate}}</h4>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12 col-lg-12">
                                            <div class="table-responsive">
                                                <table class="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <td>{{'closing.txId' | translate}} </td>
                                                            <td>{{'common.clientId' | translate}}</td>
                                                            <td>{{'common.clientName' | translate}}</td>
                                                            <td>{{'common.product' | translate}}</td>
                                                            <td>{{'closing.voucherDate' | translate}}</td>
                                                            <td>{{'common.paymentMode' | translate}}</td>
                                                            <td>{{'common.amount' | translate}}</td>
                                                            <td>{{'common.status' | translate}}</td>
                                                            <td class="text-center">{{'common.action' | translate}}</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>

                                                            <td>1ABC</td>
                                                            <td>1ABC</td>
                                                            <td>1ABC</td>
                                                            <td>1ABC</td>
                                                            <td>1ABC</td>
                                                            <td>1ABC</td>
                                                            <td>1ABC</td>
                                                            <td>1ABC</td>
                                                            <td class="text-center">
                                                                <div class="action-inner">
                                                                    <i class="fa  fa-pencil"></i>
                                                                    <i class="fa fa-trash"></i>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div class="row g-0 mb-3">
                                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                                        <div class="ms-3 upfront-recovery">
                                                            <p>{{'common.totalAmount' | translate}} </p>
                                                            <p class="clrbfbfce">39,000&nbsp;&nbsp;&nbsp;</p>
                                                        </div>
                                                        <div class="ms-3 upfront-recovery">
                                                            <p>{{'closing.totalTransaction' | translate}} </p>
                                                            <p class="clrbfbfce">39,000&nbsp;&nbsp;&nbsp;</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container>
                                    <div class="row mb-3">
                                        <div class="col-sm-12 col-md-12 col-lg-12">
                                            <h4>{{'sideBarMenu.recovery' | translate}}</h4>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12 col-lg-12">
                                            <div class="table-responsive">
                                                <table class="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <td>{{'closing.txId' | translate}} </td>
                                                            <td>{{'common.clientId' | translate}}</td>
                                                            <td>{{'common.clientName' | translate}}</td>
                                                            <td>{{'common.product' | translate}}</td>
                                                            <td>{{'closing.voucherDate' | translate}}</td>
                                                            <td>{{'common.paymentMode' | translate}}</td>
                                                            <td>{{'common.amount' | translate}}</td>
                                                            <td>{{'common.status' | translate}}</td>
                                                            <td class="text-center">{{'common.action' | translate}}</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>

                                                            <td>1ABC</td>
                                                            <td>1ABC</td>
                                                            <td>1ABC</td>
                                                            <td>1ABC</td>
                                                            <td>1ABC</td>
                                                            <td>1ABC</td>
                                                            <td>1ABC</td>
                                                            <td>1ABC</td>
                                                            <td class="text-center">
                                                                <div class="action-inner">
                                                                    <i class="fa  fa-pencil"></i>
                                                                    <i class="fa fa-trash"></i>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div class="row g-0 mb-3">
                                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                                        <div class="ms-3 upfront-recovery">
                                                            <p>{{'common.totalAmount' | translate}} </p>
                                                            <p class="clrbfbfce">39,000&nbsp;&nbsp;&nbsp;</p>
                                                        </div>
                                                        <div class="ms-3 upfront-recovery">
                                                            <p>{{'closing.totalTransaction' | translate}} </p>
                                                            <p class="clrbfbfce">39,000&nbsp;&nbsp;&nbsp;</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container>
                                    <div class="row mb-3">
                                        <div class="col-sm-12 col-md-12 col-lg-12">
                                            <h4>{{'closing.expenses' | translate}}</h4>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12 col-lg-12">
                                            <div class="table-responsive">
                                                <table class="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <td>{{'closing.txId' | translate}} </td>
                                                            <td>{{'common.clientId' | translate}}</td>
                                                            <td>{{'common.clientName' | translate}}</td>
                                                            <td>{{'common.product' | translate}}</td>
                                                            <td>{{'closing.voucherDate' | translate}}</td>
                                                            <td>{{'common.paymentMode' | translate}}</td>
                                                            <td>{{'common.amount' | translate}}</td>
                                                            <td>{{'common.status' | translate}}</td>
                                                            <td class="text-center">{{'common.action' | translate}}</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>

                                                            <td>1ABC</td>
                                                            <td>1ABC</td>
                                                            <td>1ABC</td>
                                                            <td>1ABC</td>
                                                            <td>1ABC</td>
                                                            <td>1ABC</td>
                                                            <td>1ABC</td>
                                                            <td>1ABC</td>
                                                            <td class="text-center">
                                                                <div class="action-inner">
                                                                    <i class="fa  fa-pencil"></i>
                                                                    <i class="fa fa-trash"></i>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div class="row g-0 mb-3">
                                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                                        <div class="ms-3 upfront-recovery">
                                                            <p>{{'common.totalAmount' | translate}} </p>
                                                            <p class="clrbfbfce">39,000&nbsp;&nbsp;&nbsp;</p>
                                                        </div>
                                                        <div class="ms-3 upfront-recovery">
                                                            <p>{{'closing.totalTransaction' | translate}} </p>
                                                            <p class="clrbfbfce">39,000&nbsp;&nbsp;&nbsp;</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</section>