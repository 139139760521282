import { Component, Input, OnInit, DestroyRef, inject } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'src/app/components/shared/service/shared.service';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { SharedPopupService } from 'src/app/components/shared/service/shared-popup.service';
import { PicklistService } from 'src/app/components/shared/pickList/picklist.service';
import { DatePipe } from "@angular/common";
import { CreditAppService } from './shared/service/credit-app.service';
import { ClintOnboardingService } from 'src/app/components/clint-onboarding/shared/service/clint-onboarding.service';
// import { NgSelectModule, NgOption } from '@ng-select/ng-select';
import { CreditApp } from './shared/model/cerdit-app';
import { SaveClient } from 'src/app/components/clint-onboarding/shared/model/clintOnboarding';
import { userDataClientCreditService } from 'src/app/components/shared/service/userData-client-credit.service';
import { RouteInfo } from 'src/app/auth/model/route-info';
import { RoutingConditionsService } from 'src/app/components/clint-onboarding/shared/service/routing-conditions.service';
import { TranslateService } from '@ngx-translate/core';

interface ICreditApp {
  crdtAppSeq: number;
  appId: number;
  crdtCyclNum: number;
  rqstdAmt: number;
  aprvdAmt: number;
  rcmndAmt: number;
  delFlg: boolean;
  crdtAppStsKey: number;
  cmnt: string;
  reasonCd: number;
  clntSeq: number;
  prdSeq: number;
  portSeq: number;
  crdtAppStsDt: string;
  tblScrn: boolean;
  prntCrdtAppSeq: number;
  prdRul: number;
  crdtBnd: string;
  isMulticrdt: boolean;
  multicrdt: boolean;
  prdGroupName: any;
  noOfInstallment: any;
}
@Component({
  selector: 'app-client-credit-info',
  templateUrl: './client-credit-info.component.html',
  styleUrls: ['./client-credit-info.component.css']
})
export class ClientCreditInfoComponent implements OnInit {
  allowedRoutes: RouteInfo[];
  userData: any;
  creditAppForm: FormGroup;
  creditApp: ICreditApp;
  saveCnicData: any;
  modelHeading: any;
  // clientId: any;
  crdtApp: any;
  // noOfInstallment: any;
  allProduct_Data_Dup: any;
  creditData: any;
  // prdGroupName: any;
  creditAppFormData: any;



  #destroyRef = inject(DestroyRef);

  constructor(
    public router: Router,
    public _sharedSvc: SharedService,
    public _creditAppSvc: CreditAppService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    public _sharedPopupSvc: SharedPopupService,
    public _picklistService: PicklistService,
    public route: ActivatedRoute,
    public _clintOnboardingSvc: ClintOnboardingService,
    public _userDataClientCreditService: userDataClientCreditService,
    public _conditionsService: RoutingConditionsService,
    public translate: TranslateService,


  ) {
    this.allowedRoutes = JSON.parse(sessionStorage.getItem("routeInfo"));
    this.creditApp = {} as ICreditApp;
    this.userData = this._userDataClientCreditService.getSharedData();
    this.userData = !!sessionStorage.getItem('shareUserData') ? JSON.parse(sessionStorage.getItem("shareUserData")) : this._userDataClientCreditService.getSharedData();
    // this._clintOnboardingSvc.InItGetApprovedClients();
    this.#destroyRef.onDestroy(() => {
      const invalidControls = Object.keys(this.creditAppForm.controls).filter(controlName =>
        this.creditAppForm.get(controlName).invalid
      );
      console.log('Invalid controls:', invalidControls);
      console.log('Credit Model_1:', this._creditAppSvc.creditAppModel);

      if (this.allowedRoutes[0].rolId === 'CMO') {
        let data = {
          "crdtAppSeq": this._creditAppSvc.creditAppModel.crdtAppSeq,
          "crdtCyclNum": this._creditAppSvc.creditAppModel.crdtCyclNum,
          "clntSeq": this._creditAppSvc.creditAppModel.clntSeq,
          "rqstdAmt": this._creditAppSvc.creditAppModel.rqstdAmt,
          "aprvdAmt": this._creditAppSvc.creditAppModel.aprvdAmt,
          "rcmndAmt": this._creditAppSvc.creditAppModel.rcmndAmt,
          "prdSeq": this._creditAppSvc.creditAppModel.prdSeq
        }
        sessionStorage.setItem("CCI", JSON.stringify(data))
      } else {
        sessionStorage.setItem("CCI", JSON.stringify(this.creditAppForm.value))
      }

    });
  }




  ngOnInit(): void {
    
    console.log('Credit Model_2:', this._creditAppSvc.creditAppModel);
    this.validationForm();
    this.setD_V();
    this._picklistService.InItGetALlProduct();
    this.setValueToForm();

    if (localStorage.getItem('SaveCnicData')) {
      this.saveCnicData = localStorage.getItem('SaveCnicData');
      this.saveCnicData = JSON.parse(this.saveCnicData);
    }
    if (localStorage.getItem('crdtApp')) {
      this.crdtApp = localStorage.getItem('crdtApp');
      this._creditAppSvc.creditApp = true;
      
      this.crdtApp = JSON.parse(this.crdtApp);
      // this._creditAppSvc.InItGetCreditApp(this.crdtApp);
      this.InItGetCreditAppLocal(this.crdtApp);
    }
    if (this.route.snapshot.paramMap.get("id")) {
      if (localStorage.getItem('creditData')) {
        this.creditData = localStorage.getItem('creditData');
        this._creditAppSvc.creditEdit = true;
        this.creditData = JSON.parse(this.creditData);
        let crdtAppSeq = this.route.snapshot.paramMap.get("id");
        localStorage.setItem('crdtApp', crdtAppSeq);
        this._creditAppSvc.creditApp = true;
        this._clintOnboardingSvc.InItClientBasicInfo(this.creditData.clntSeq);
        // this._creditAppSvc.InItGetLatestCreditApp(this.creditData?.clntSeq);
        this.InItGetLatestCreditAppLocal(this.creditData?.clntSeq);
      }

    }
if (this._creditAppSvc.creditApp === true && this.allowedRoutes.length > 0 && (this.allowedRoutes[0].rolId === 'CMO' || this.allowedRoutes[0].rolId ==='BS' || this.allowedRoutes[0].rolId ==='AM' || this.allowedRoutes[0].rolId ==='BH' )) {
    this.prdGroupName.disable();
    this.prdSeq.disable();
    this.rqstdAmt.disable();
    this.aprvdAmt.disable();
} else {
  this.prdGroupName.enable();
  this.prdSeq.enable();
  this.rqstdAmt.enable();
  this.aprvdAmt.enable();
}
    // if (localStorage.getItem("reload")) {
    //   localStorage.removeItem("reload");
    //   window.location.reload();
    // }

  }
  ngAfterViewInit() {
    console.log("calling NgAFterView")
    this.populateAllDataOnInIt();
    console.log('Credit Model_3 After View:', this._creditAppSvc.creditAppModel);
  }

  populateAllDataOnInIt() {
    let usertoFind = this.userData[0];
    // let list = this._clintOnboardingSvc.getAllApprovedClientPG;
    let list = sessionStorage.getItem("getAllApprovedClient") ? JSON.parse(sessionStorage.getItem('getAllApprovedClient')) : [];
    console.log('List:', list)

    if (list && list.length > 0) {
      let user = list.find(x => x.clntId === usertoFind.clntId);
      if (user) {
        this.selectClientData(user);
      }
    }
  }

  product() {
    console.log("working on change")
    if (this._creditAppSvc.creditAppModel.prdSeq) {
      for (let i = 0; i < this._picklistService.allProduct_Data?.length; i++) {
        if (this._creditAppSvc.creditAppModel.prdSeq == this._picklistService.allProduct_Data[i].productSeq) {
          this._creditAppSvc.noOfInstallment = this._picklistService.allProduct_Data[i].productTerms;
          this._creditAppSvc.prdGroupName = this._picklistService.allProduct_Data[i].productGrpSeq;
        }
      }
    }
  }

  selectClientData(data: any) {
    if (data) {
      this.setD_V();
      this._creditAppSvc.noOfInstallment = null;
      this._creditAppSvc.creditAppModel.clntSeq = data?.clntSeq;

      // this._creditAppSvc.creditAppModel.aprvdAmt = data?.aprvdAmount;
      // this._creditAppSvc.creditAppModel.prdSeq = data?.productID;

      let list = sessionStorage.getItem("getAllApprovedClient") ? JSON.parse(sessionStorage.getItem('getAllApprovedClient')) : [];
      // for (let index = 0; index < this._clintOnboardingSvc.getAllApprovedClientPG?.length; index++) {
      //   if (this._clintOnboardingSvc.getAllApprovedClientPG[index].clntSeq == this._creditAppSvc.creditAppModel.clntSeq) {
      //     let clntSeq = this._clintOnboardingSvc.getAllApprovedClientPG[index].clntSeq;
      //     this._clintOnboardingSvc.InItClientBasicInfo(clntSeq);
      //     this._creditAppSvc.InItGetLatestCreditApp(clntSeq);
      //   }
      // }
      for (let index = 0; index < list?.length; index++) {
        if (list[index].clntSeq == this._creditAppSvc.creditAppModel.clntSeq) {
          let clntSeq = list[index].clntSeq;
          this._clintOnboardingSvc.InItClientBasicInfo(clntSeq);
          //this._creditAppSvc.InItGetLatestCreditApp(clntSeq);
          this.InItGetLatestCreditAppLocal(clntSeq);
        }
      }
    } else {
      this._creditAppSvc.creditAppModel = new CreditApp();
      this.setD_V();
      this._clintOnboardingSvc.saveClientModel = new SaveClient();
      this.setValueToForm();
    }

  }

  setD_V() {
    this._creditAppSvc.creditAppModel = {
      crdtAppSeq: 0,
      appId: null,
      crdtCyclNum: 1,
      rqstdAmt: null,
      aprvdAmt: null,
      rcmndAmt: 1,
      delFlg: false,
      crdtAppStsKey: 0,
      cmnt: "",
      reasonCd: 0,
      clntSeq: 0,
      prdSeq: null,
      portSeq: 17, //null,
      crdtAppStsDt: "",
      tblScrn: false,
      prntCrdtAppSeq: 0,
      prdRul: 0,
      crdtBnd: "",
      isMulticrdt: false,
      multicrdt: false,
    }
  }

  Edit(content: any, data: any, h4: any) {
    this.modelHeading = h4;
    if (this.route.snapshot.paramMap.get("id")) {
      this._creditAppSvc.edit = true;
      this._sharedSvc.msg = this.translate.instant('toast.recordUpdated');
      this.modalService.open(content, {
        windowClass: "popupWidth800",
        backdropClass: 'light-blue-backdrop',
        centered: true
      });
      this._creditAppSvc.creditAppModel = data;
      const datePipe = new DatePipe('en-Us');
    }
  }

  onSubmit(): void {
    if (this.creditAppForm.invalid) {
      for (const control of Object.keys(this.creditAppForm.controls)) {
        this.creditAppForm.controls[control].markAsTouched();
      }
      return;
    }
    this._sharedSvc.showSpinner()
    if (!this.route.snapshot.paramMap.get("id")) {
    }
    this.setValueToModel();
    // sessionStorage.setItem("CCI" , JSON.stringify(this.creditAppForm.value))
    console.log('Credit Model_3:', this._creditAppSvc.creditAppModel)
    this._creditAppSvc.SaveCreditApp().subscribe({
      next: (response: any) => {
        if (response) {
          localStorage.setItem('crdtApp', response.crdtApp);
          this._creditAppSvc.creditApp = true;
          this.creditAppForm.get('crdtAppSeq').setValue(response.crdtApp);
          this._creditAppSvc.creditAppModel.crdtAppSeq = response.crdtApp;
          // this._creditAppSvc.InItGetCreditApp(response.crdtApp);
          this._sharedSvc.success(this._sharedSvc.msg);
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }

  validationForm() {
    this.creditAppForm = new FormGroup({
      crdtAppSeq: new FormControl(this.creditApp.crdtAppSeq, []),
      crdtCyclNum: new FormControl(this.creditApp.crdtCyclNum, []),
      clntSeq: new FormControl(this.creditApp.clntSeq, []),
      rqstdAmt: new FormControl(this.creditApp.rqstdAmt, [
        Validators.required,
        Validators.min(1),
        Validators.max(500000),
      ]),
      aprvdAmt: new FormControl(this.creditApp.aprvdAmt, [
        Validators.required,
        Validators.min(1),
        Validators.max(500000),
      ]),
      rcmndAmt: new FormControl(this.creditApp.rcmndAmt, [
      ]),
      prdSeq: new FormControl(this.creditApp.prdSeq, [
        Validators.required,
      ]),
      prdGroupName: new FormControl(this.creditApp.prdGroupName, [
        Validators.required,
      ]),
      noOfInstallment: new FormControl(this.creditApp.noOfInstallment, [
        Validators.required,
      ]),

    });
  }

  setValueToModel() {
    this._creditAppSvc.creditAppModel.crdtAppSeq = Number(this.crdtAppSeq.value);
    this._creditAppSvc.creditAppModel.crdtCyclNum = Number(this.crdtCyclNum.value);
    this._creditAppSvc.creditAppModel.prdSeq = Number(this.prdSeq.value);
    this._creditAppSvc.creditAppModel.rqstdAmt = Number(this.rqstdAmt.value);
    this._creditAppSvc.creditAppModel.rcmndAmt = Number(this.rcmndAmt.value)
    this._creditAppSvc.creditAppModel.aprvdAmt = Number(this.aprvdAmt.value);
    this._creditAppSvc.prdGroupName = this.prdGroupName.value;
    this._creditAppSvc.noOfInstallment = this.noOfInstallment.value;
  }
  setValueToForm() {
    
    this.crdtAppSeq.setValue(this._creditAppSvc.creditAppModel.crdtAppSeq);
    this.crdtCyclNum.setValue(this._creditAppSvc.creditAppModel.crdtCyclNum);
    this.prdSeq.setValue(this._creditAppSvc.creditAppModel.prdSeq);
    this.rqstdAmt.setValue(this._creditAppSvc.creditAppModel.rqstdAmt);
    this.rcmndAmt.setValue(this._creditAppSvc.creditAppModel.rcmndAmt);
    this.aprvdAmt.setValue(this._creditAppSvc.creditAppModel.aprvdAmt);
    if (this._creditAppSvc.prdGroupName) {
      this.prdGroupName.setValue(this._creditAppSvc.prdGroupName);
    }
    if (this._creditAppSvc.noOfInstallment) {
      this.noOfInstallment.setValue(this._creditAppSvc.noOfInstallment);
    } else {
      this.noOfInstallment.setValue(0);
    }
  }

  get crdtAppSeq() {
    return this.creditAppForm.get('crdtAppSeq')!;
  }
  get appId() {
    return this.creditAppForm.get('appId')!;
  }
  get crdtCyclNum() {
    return this.creditAppForm.get('crdtCyclNum')!;
  }
  get rqstdAmt() {
    return this.creditAppForm.get('rqstdAmt')!;
  }
  get aprvdAmt() {
    return this.creditAppForm.get('aprvdAmt')!;
  }
  get rcmndAmt() {
    return this.creditAppForm.get('rcmndAmt')!;
  }
  get crdtAppStsKey() {
    return this.creditAppForm.get('crdtAppStsKey')!;
  }
  get cmnt() {
    return this.creditAppForm.get('cmnt')!;
  }
  get reasonCd() {
    return this.creditAppForm.get('reasonCd')!;
  }
  get clntSeq() {
    return this.creditAppForm.get('clntSeq')!;
  }

  get prdSeq() {
    return this.creditAppForm.get('prdSeq')!;
  }
  get portSeq() {
    return this.creditAppForm.get('portSeq')!;
  }
  get crdtAppStsDt() {
    return this.creditAppForm.get('crdtAppStsDt')!;
  }
  get tblScrn() {
    return this.creditAppForm.get('tblScrn')!;
  }
  get prntCrdtAppSeq() {
    return this.creditAppForm.get('prntCrdtAppSeq')!;
  }
  get prdRul() {
    return this.creditAppForm.get('prdRul')!;
  }
  get crdtBnd() {
    return this.creditAppForm.get('crdtBnd')!;
  }
  get isMulticrdt() {
    return this.creditAppForm.get('isMulticrdt')!;
  }
  get multicrdt() {
    return this.creditAppForm.get('multicrdt')!;
  }
  get prdGroupName() {
    return this.creditAppForm.get('prdGroupName')!;
  }
  get noOfInstallment() {
    return this.creditAppForm.get('noOfInstallment')!;
  }


  //getCreditApp Functions Begins
  InItGetCreditAppLocal(crdt_app_seq: any) {
    this._creditAppSvc.getCreditApp(crdt_app_seq).subscribe({
      next: (response: any) => {
        
        if (response) {
          this._creditAppSvc.creditAppModel = new CreditApp();
          this._creditAppSvc.getAllCreditAppData = response;
          this._creditAppSvc.creditAppModel = this._creditAppSvc.getAllCreditAppData;
          this._creditAppSvc.creditAppModel.crdtCyclNum = this._creditAppSvc.creditAppModel.crdtCyclNum + 1;
          this._creditAppSvc.creditAppModel.prdSeq = 1;// response.prdSeq;
          for (let i = 0; i < this._picklistService.allProduct_Data?.length; i++) {
            if (this._creditAppSvc.creditAppModel.prdSeq == this._picklistService.allProduct_Data[i].productSeq) {
              this._creditAppSvc.noOfInstallment = this._picklistService.allProduct_Data[i].productTerms;
              this._creditAppSvc.prdGroupName = this._picklistService.allProduct_Data[i].productGrpSeq;
            }
          }
          this._creditAppSvc.InItClientBasicInfo(this._creditAppSvc.creditAppModel.clntSeq);
          this._creditAppSvc.pageChange();
          this.setValueToForm();
          this._sharedSvc.hideSpinner3();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner3();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    })
  }
  InItGetLatestCreditAppLocal(clnt_seq: any): void {

    this._sharedSvc.showSpinner();
    this._creditAppSvc.getLatestCreditApp(clnt_seq).subscribe({
      next: (response: any) => {
        
        if (response) {
          this._creditAppSvc.creditAppModel = new CreditApp();
          this._creditAppSvc.getLatestCreditAppData = response;
          localStorage.setItem('crdtApp', response.crdtAppSeq);
          this._creditAppSvc.creditAppModel = response;
          this._creditAppSvc.creditAppModel.crdtCyclNum = this._creditAppSvc.creditAppModel.crdtCyclNum + 1;
          this._creditAppSvc.creditAppModel.prdSeq = 1;//response.prdSeq;
          for (let i = 0; i < this._picklistService.allProduct_Data?.length; i++) {
            if (this._creditAppSvc.creditAppModel.prdSeq == this._picklistService.allProduct_Data[i].productSeq) {
              this._creditAppSvc.noOfInstallment = this._picklistService.allProduct_Data[i].productTerms;
              this._creditAppSvc.prdGroupName = this._picklistService.allProduct_Data[i].productGrpSeq;
            }
          }
          this.setValueToForm();
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }
  //getCreditApp Functions End



  ngOnDestroy() {
    localStorage.setItem("reload", 'true');
  }
}
