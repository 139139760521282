import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CookieService } from 'ngx-cookie-service';
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { environment } from "src/app/global";
import { SharedService } from "src/app/components/shared/service/shared.service";
import { DatePipe } from "@angular/common";
import { SaveEmployer } from "../model/employer";
import { Endpoint } from "src/app/components/shared/serviceEndPoints";

@Injectable({
  providedIn: 'root'
})
export class EmployerInfoService {

  saveEmployerModel = new SaveEmployer();
  getAllEmployer: any;
  getAllEmployerPG: any;
  edit: boolean = false;
  saveEmployerModelObj: any = [];

  constructor(
    public router: Router,
    private _http: HttpClient,
    private _cookieSvc: CookieService,
    public _sharedSvc: SharedService,

  ) { }

  // APIS Employer Start 
  saveEmployerDetail() {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL + Endpoint.Employer.save_emplyr_dtl, this.saveEmployerModel, this._sharedSvc.requestOptions);
  }

  getEmployerDetail(emplyrDtlSeq: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL + Endpoint.Employer.get_emplyr_dtl + emplyrDtlSeq, this._sharedSvc.requestOptions);
  }

  getAllEmployerDetail(clnt_seq: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL + Endpoint.Employer.get_all_emplyr_dtl + clnt_seq, this._sharedSvc.requestOptions);
  }

  InItGetEmployerDetail(emplyrDtlSeq: any): void {
    this._sharedSvc.showSpinner()
    this.getEmployerDetail(emplyrDtlSeq).subscribe({
      next: (response) => {
        if (response) {

          this._sharedSvc.hideSpinner();

        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }
  // APIS Employer End 



  InItGetAllEmployerDetail(clnt_seq: any): void {
    // try {
    //   this._sharedSvc.showSpinner();
    //   const data = await this.getAllEmployerDetail(clnt_seq).toPromise();
    //   this.getAllEmployer = data;
    //   localStorage.setItem('saveEmployerModelObj', JSON.stringify(this.getAllEmployer))
    //   this.pageChange();
    //   this._sharedSvc.hideSpinner();
    // } catch (error) {
    //   this._sharedSvc.hideSpinner();
    //   console.error('Error fetching safe list:', error);
    //   throw error;
    // }


    this._sharedSvc.showSpinner()
    this.getAllEmployerDetail(clnt_seq).subscribe({
      next: (response) => {
        if (response) {
          this.getAllEmployer = response;
          localStorage.setItem('saveEmployerModelObj', JSON.stringify(this.getAllEmployer))
          this.pageChange();
          this._sharedSvc.hideSpinner();

        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }

  pageChange() {
    this._sharedSvc.collectionSize = this.getAllEmployer?.length;
    this.getAllEmployerPG = this.getAllEmployer.slice((this._sharedSvc.page - 1) * this._sharedSvc.pageSize, (this._sharedSvc.page - 1) * this._sharedSvc.pageSize + this._sharedSvc.pageSize);
  }

}
