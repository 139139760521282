import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CookieService } from 'ngx-cookie-service';
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { environment } from "src/app/global";
import { CreditApp } from "../model/cerdit-app";
import { SharedService } from "src/app/components/shared/service/shared.service";
import { DatePipe } from "@angular/common";
import { PicklistService } from "src/app/components/shared/pickList/picklist.service";
import { Endpoint } from "src/app/components/shared/serviceEndPoints";

@Injectable({
  providedIn: 'root'
})
export class CreditAppService {

  creditAppModel = new CreditApp();
  getAllCreditAppData: any;
  getLatestCreditAppData: any;
  getAllCreditAppDataPG: any;
  edit: boolean = false;
  saveClientModel: any;
  getCAListData: any;
  getCAListDataPG: any;
  creditApp: boolean = false;
  creditEdit: boolean = false;
  constructor(
    public router: Router,
    private _http: HttpClient,
    private _cookieSvc: CookieService,
    public _sharedSvc: SharedService,
    public _picklistService: PicklistService

  ) { }
  // APIS CreditApp Start 
  getCAList() {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL + Endpoint.creditApp.get_ca_list, this._sharedSvc.requestOptions);
  }
  SaveCreditApp() {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL + Endpoint.creditApp.save_credit_app, this.creditAppModel, this._sharedSvc.requestOptions);
  }
  getLatestCreditApp(clnt_seq: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL + Endpoint.creditApp.get_latest_credit_app + clnt_seq, this._sharedSvc.requestOptions);
  }
  getCreditApp(crdt_app_seq: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL + Endpoint.creditApp.get_credit_app + crdt_app_seq, this._sharedSvc.requestOptions);
  }
  clientBasicInfo(clntSeq: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL + Endpoint.OnBoarding.GetClientBasicInfo + clntSeq, this._sharedSvc.requestOptions);
  }
  // APIS CreditApp End 

  InItGetCAList(): void {
    this._sharedSvc.showSpinner();
    this.getCAList().subscribe({
      next: (response) => {
        if (response) {
          this.getCAListData = response;
          this.pageChangeCAList();
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }

  pageChangeCAList() {
    this._sharedSvc.collectionSize = this.getCAListData?.length;
    this.getCAListDataPG = this.getCAListData.slice((this._sharedSvc.page - 1) * this._sharedSvc.pageSize, (this._sharedSvc.page - 1) * this._sharedSvc.pageSize + this._sharedSvc.pageSize);
  }

  InItGetLatestCreditApp(clnt_seq: any): void {

    this._sharedSvc.showSpinner();
    this.getLatestCreditApp(clnt_seq).subscribe({
      next: (response: any) => {
        if (response) {
          this.creditAppModel = new CreditApp();
          this.getLatestCreditAppData = response;
          localStorage.setItem('crdtApp', response.crdtAppSeq);
          this.creditAppModel = response;
          this.creditAppModel.crdtCyclNum = this.creditAppModel.crdtCyclNum + 1;
          this.creditAppModel.prdSeq = 1;//response.prdSeq;
          for (let i = 0; i < this._picklistService.allProduct_Data?.length; i++) {
            if (this.creditAppModel.prdSeq == this._picklistService.allProduct_Data[i].productSeq) {
              this.noOfInstallment = this._picklistService.allProduct_Data[i].productTerms;
              this.prdGroupName = this._picklistService.allProduct_Data[i].productGrpSeq;
            }
          }
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }
  noOfInstallment: any;
  prdGroupName: any;
  product() {
    if (this.creditAppModel.prdSeq != null) {
      for (let i = 0; i < this._picklistService.allProduct_Data?.length; i++) {
        if (this.creditAppModel.prdSeq == this._picklistService.allProduct_Data[i].productSeq) {
          this.noOfInstallment = this._picklistService.allProduct_Data[i].productTerms;
          this.prdGroupName = this._picklistService.allProduct_Data[i].productGrpSeq;
        }
      }
    }
  }


  InItGetCreditApp(crdt_app_seq: any): void {

    this._sharedSvc.showSpinner3();
    this.getCreditApp(crdt_app_seq).subscribe({
      next: (response: any) => {
        if (response) {

          this.creditAppModel = new CreditApp();
          this.getAllCreditAppData = response;
          this.creditAppModel = this.getAllCreditAppData;
          this.creditAppModel.crdtCyclNum = this.creditAppModel.crdtCyclNum + 1;
          this.creditAppModel.prdSeq = 1;// response.prdSeq;
          for (let i = 0; i < this._picklistService.allProduct_Data?.length; i++) {
            if (this.creditAppModel.prdSeq == this._picklistService.allProduct_Data[i].productSeq) {
              this.noOfInstallment = this._picklistService.allProduct_Data[i].productTerms;
              this.prdGroupName = this._picklistService.allProduct_Data[i].productGrpSeq;
            }
          }
          this.InItClientBasicInfo(this.creditAppModel.clntSeq);
          this.pageChange();
          this._sharedSvc.hideSpinner3();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner3();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }

  InItClientBasicInfo(clntSeq?: any): void {
    this._sharedSvc.showSpinner()
    this.clientBasicInfo(clntSeq).subscribe({
      next: (response) => {
        if (response) {
          this.saveClientModel = response;
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }

  pageChange() {
    this._sharedSvc.collectionSize = this.getAllCreditAppData?.length;
    // this.getAllCreditAppDataPG = this.getAllCreditAppData.slice((this._sharedSvc.page - 1) * this._sharedSvc.pageSize, (this._sharedSvc.page - 1) * this._sharedSvc.pageSize + this._sharedSvc.pageSize);
    // this._sharedSvc.pageSize  = this.getAllOutstandingPG?.length;
  }
}
