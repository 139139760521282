import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { from, Observable } from 'rxjs';

@Injectable()
export class AuthService {
  helper: any;
  constructor(private keycloakService: KeycloakService,private router:Router) {
    this.helper = new JwtHelperService();
    keycloakService.keycloakEvents$.subscribe({
      next: (e) => {
        if (e.type == KeycloakEventType.OnTokenExpired) {
          keycloakService.updateToken(20);
          sessionStorage.setItem('Token',JSON.stringify(keycloakService.getToken()));
          this.jwtToken(keycloakService.getToken());
        }
      }
    });
   }

  get LoggedUser() {
    try {
      let userDetails = this.keycloakService.getKeycloakInstance().idTokenParsed;
    // console.log('UserDetails', userDetails);
    // console.log('UserRoles', this.keycloakService.getUserRoles());
      return userDetails;
    }
    catch (e) {
    // console.log('getLoggedUser Exception', e);
      return undefined;
    }
  }

  public isLoggedIn(): Promise<boolean> { return this.keycloakService.isLoggedIn();}

  public logout() { 
    sessionStorage.clear();
    localStorage.clear();
    this.keycloakService.logout();}
  
  public redirectToProfile() { this.keycloakService.getKeycloakInstance().accountManagement();}

  public getRoles(): string[] {  return this.keycloakService.getUserRoles();}

  public jwtToken(JwtTok: any) {
    let token = JwtTok;
    const decoded = this.helper.decodeToken(token);
    
    sessionStorage.setItem('JWT', JSON.stringify(decoded));
    /*roles*/
    let realumRoles:string[]= decoded.realm_access.roles;
    if(realumRoles && realumRoles.length){
      let rName:string[]=realumRoles.filter(it=> it.startsWith("LNLY_"))
      if(rName && rName.length)
      {let role=rName[0].split("_")[1];
      return role;
      }
      else{
      let role=realumRoles[realumRoles.length-1];
      return role;}
    }
    return null;
  }
}