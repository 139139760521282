<section>
    <div class="sidebar" [class.sidebar-close]="!_sharedSvc.openSidebar">
        <app-sidebar></app-sidebar>
    </div>
    <div class="home-section">
        <div class="home-main" [class.sidebar-close_home-main]="!_sharedSvc.openSidebar">
            <app-navbar></app-navbar>

            <div class="home-view" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
                [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
                <section>
                    <div class="row mb-3">
                        <div class="col-sm-8 col-md-8 col-lg-8">
                            <h4> {{'journalVoucher.journalVouchers' | translate}}</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <div class="dashboard-card loan-table">
                                <div class="row mb-3"
                                    *ngIf="allowedRoutes.length > 0 && allowedRoutes[0].rolId === 'SA'">
                                    <div class="col-4">
                                        <div class="form-floating">
                                            <select (change)="changeVouchers($event)"
                                                class="form-control form-select input-radius">
                                                <option *ngFor="let list of branchList" value="{{list.branchSequence}}">
                                                    {{list.branchName}}
                                                </option>
                                            </select>
                                            <label>{{'common.branch' | translate}}</label>
                                        </div>
                                    </div>

                                </div>

                                <div class="row printableDiv">
                                    <div class="col-sm-12 col-md-12 col-lg-12 col-12">
                                        <div *ngFor="let list of voucherList;  let i=index;"
                                            [class.hideinPrint]="!list.isCollapsed"
                                            (click)="collapseDown(list.jrnlVchrSeq,i)" class="headerAdjustment">
                                            <div class="row hedaer">
                                                <div class="col-md-2 col-2">{{list.jrnlVchrSeq}}</div>
                                                <div class="col-md-2 col-2">{{list.refTypKey}}</div>
                                                <div class="col-md-4 col-4">{{list.dscr}}</div>
                                                <div class="col-md-2 col-2">{{list.refKey}}</div>
                                                <div class="col-md-2 col-2">{{list.jrnlVchrSeq}}
                                                    <div class="add-button hideinPrint" (click)="printPage()"
                                                        *ngIf="list.isCollapsed">
                                                        <i class="fa fa-print"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div #collapse="ngbCollapse" [(ngbCollapse)]="!list.isCollapsed">
                                                <div class="p-3 border-top">
                                                    <table class="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>{{ "common.id" | translate }}</th>
                                                                <th>{{ "journalVoucher.ledgerAccountNumber" | translate }}</th>
                                                                <th>{{"common.description" | translate}}</th>
                                                                <th>{{ "journalVoucher.debitAmount" | translate }}</th>
                                                                <th>{{ "journalVoucher.creditAmount" | translate }}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let nlist of list.details">
                                                                <td>{{nlist.jrnlVchrDtlSeq}}</td>
                                                                <td>{{nlist.acctNbr}}</td>
                                                                <td>{{nlist.dscr}}</td>
                                                                <td>{{nlist.dbAmt}}</td>
                                                                <td>{{nlist.crAmt}}</td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td colspan="2"></td>
                                                                <td>{{ "common.total" | translate }}:</td>
                                                                <td>{{getSumOfDbAmt(i)}}</td>
                                                                <td>{{getSumOfcrAmt(i)}}</td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</section>