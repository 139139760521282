import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent {
  closeResult = '';
  @Input() title: string;
  @Input() message: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() contractNametoDelete : any;
  constructor(private activeModal: NgbActiveModal,) {}
  ngOnInit(): void {
  
  }
  
  deleteContract(){
    this.activeModal.close(true);
  }

  close(){
    this.activeModal.close(false);
  }

  public dismiss() {
    this.activeModal.dismiss(false);
  }

}