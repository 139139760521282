<section>
  <div class="sidebar" [class.sidebar-close]="!_sharedSvc.openSidebar">
    <app-sidebar></app-sidebar>
  </div>
  <div class="home-section">
    <div class="home-main" [class.sidebar-close_home-main]="!_sharedSvc.openSidebar">
      <app-navbar></app-navbar>
      <div class="home-view" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
        [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
        <section>
          <div>
            <div class="row mb-3">
              <div class="col-sm-7 col-md-7 col-lg-7">
                <h4>{{ "common.product" | translate }}</h4>
              </div>
              <!-- <div class="col-sm-4 col-md-4 col-lg-4 marginAuto">
                                <div class="float-right pointer" routerLink="/setup-products">
                                    <p>
                                        <span><i class="fa fa-angle-left"></i></span> {{'common.back' | translate}}
                                    </p>
                                </div>
                            </div> -->
              <div class="col-sm-5 col-md-5 col-lg-5">
                <div class="float-right" routerLink="/setup-products">
                  <div class="back-btn me-2">
                    <i class="fa fa-reply"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="dashboard-card">
                  <div class="personal-info">
                    <ul ngbNav #nav="ngbNav" class="nav-tabs">
                      <li [ngbNavItem]="1">
                        <a ngbNavLink >{{
                          "addProduct.basicInfo" | translate
                          }}</a>
                        <ng-template ngbNavContent>
                          <app-basic-info></app-basic-info>
                        </ng-template>
                      </li>
                      <li [ngbNavItem]="2">
                        <ng-container [ngbNavItem]="2"
                        [disabled]="disableTabs">
                          <a ngbNavLink>{{
                            "addProduct.productRules" | translate
                            }}</a>
                          <ng-template ngbNavContent>
                            <app-product-rules></app-product-rules>
                          </ng-template>
                        </ng-container>
                      </li>
                      <li [ngbNavItem]="3">
                        <ng-container [ngbNavItem]="3"
                        [disabled]="disableTabs">
                        <a ngbNavLink>{{ "addProduct.charges" | translate }}</a>
                        <ng-template ngbNavContent>
                          <app-charges></app-charges>
                        </ng-template>
                      </ng-container>
                      </li>
                      <li [ngbNavItem]="4">
                        <ng-container [ngbNavItem]="4"
                        [disabled]="disableTabs">
                        <a ngbNavLink>{{ "addProduct.accountingSetup" | translate }}
                        </a>
                        <ng-template ngbNavContent>
                          <app-accounting-setup></app-accounting-setup>
                        </ng-template>
                      </ng-container>
                      </li>
                      <li [ngbNavItem]="5">
                        <ng-container [ngbNavItem]="5"
                        [disabled]="disableTabs">
                        <a ngbNavLink>{{
                          "addProduct.formAssignment" | translate
                          }}</a>
                        <ng-template ngbNavContent>
                          <app-form-assignment></app-form-assignment>
                        </ng-template>
                      </ng-container>
                      </li>
                      <li [ngbNavItem]="6">
                        <ng-container [ngbNavItem]="6"
                        [disabled]="disableTabs">
                        <a ngbNavLink>{{
                          "addProduct.documentAssignment" | translate
                          }}</a>
                        <ng-template ngbNavContent>
                          <app-document-assignment></app-document-assignment>
                        </ng-template>
                      </ng-container>
                      </li>
                      <li [ngbNavItem]="7">
                        <ng-container [ngbNavItem]="7"
                        [disabled]="disableTabs">
                        <a ngbNavLink>{{
                          "addProduct.assignmentSequence" | translate
                          }}</a>
                        <ng-template ngbNavContent>
                          <app-assignment-sequence></app-assignment-sequence>
                        </ng-template>
                      </ng-container>
                      </li>
                      <li [ngbNavItem]="8">
                        <ng-container [ngbNavItem]="8"
                        [disabled]="disableTabs">
                        <a ngbNavLink>{{
                          "common.businessSector" | translate
                          }}</a>
                        <ng-template ngbNavContent>
                          <!-- <app-business-sector></app-business-sector> -->
                          <app-business-sector-product></app-business-sector-product>
                        </ng-template>
                      </ng-container>
                      </li>
                      <li [ngbNavItem]="9">
                        <ng-container [ngbNavItem]="8"
                        [disabled]="disableTabs">
                        <a ngbNavLink>{{
                          "addProduct.associateProduct" | translate
                          }}</a>
                        <ng-template ngbNavContent>
                          <app-associate-product></app-associate-product>
                        </ng-template>
                      </ng-container>
                      </li>
                    </ul>
                    <div [ngbNavOutlet]="nav" class="mt-2"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>