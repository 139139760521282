<div class="row mb-3">
    <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="button_div mt-3">
            <button class="btn login-button-class" type="button" (click)="moveToNextTab()">
                {{'common.continue' | translate}}
            </button>
            <button class="btn login-button-class secondaryBtn" [disabled]="_sharedSvc.loading"
                [class.pointerNone]="_sharedSvc.loading">
                {{'common.save' | translate}}
            </button>
        </div>
    </div>
</div>