import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { AdminService } from '../components/admin/shared/service/admin.service';
import { RouteInfo } from './model/route-info';
import { AuthService } from './service/auth.service';

@Injectable()
export class AuthGuard extends KeycloakAuthGuard {

  constructor(protected override router: Router, protected override keycloakAngular: KeycloakService,
    private adminService:AdminService,private authService:AuthService) {
    super(router, keycloakAngular);
  }
  public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    return new Promise(async (resolve, reject) => {
      if (!this.authenticated) {
       await this.keycloakAngular.login();
        resolve(false);
        return;
      }



      const allowedRoutes:RouteInfo[]= JSON.parse(sessionStorage.getItem("routeInfo"));
      console.log(allowedRoutes);
      if(allowedRoutes && allowedRoutes.length){
        if(route.url && route.url.length) 
          {
            let path=route.url[0].path;
            // console.log(path);
            let filtered=allowedRoutes.filter (it => it.sbModUrl.toLowerCase().includes(path.toLowerCase()) ||  path.toLowerCase().includes(it.sbModUrl.toLowerCase()));
            if(filtered.length>0)
            resolve(true);
            else resolve(false);
            return;
          }
      }else {
         
    this.keycloakAngular.getToken().then((token) => {

      // console.log(token);
      if(token){
        sessionStorage.setItem('Token',JSON.stringify(token));
        let role=this.authService.jwtToken(token);
           // console.log(role);
           if(role){
             this.adminService.getAuthList(role.trim(), (result)=>{
              sessionStorage.setItem('routeInfo', JSON.stringify(result)); 
              this.router.navigate(['dashboard']);
            });
      }
    }
    })
      }

      // const requiredRoles = route.data['roles'];
     
      // let granted: boolean = false;
      // if (!requiredRoles || requiredRoles.length === 0) {
      //   granted = true;
      // } else {
      //   for (const requiredRole of requiredRoles) {
      //     if (this.roles.indexOf(requiredRole) > -1) {
      //       granted = true;
      //       break;
      //     }
      //   }
      // }
      // if (granted === false) {
      //   resolve(granted)
      // }
      // resolve(granted);
    });
  }
}