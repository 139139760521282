import { Directive, ElementRef, Input, forwardRef, HostListener } from "@angular/core";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";

@Directive({
  selector: "[phoneMask]",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneMaskDirective),
      multi: true,
    },
  ],
})
export class PhoneMaskDirective implements ControlValueAccessor {
  private _value: string = "";

  @Input() phoneMask: string = "9999-9999999";

  constructor(private el: ElementRef) {}

  writeValue(value: any): void {
    if (value !== undefined && value !== null) {
      this._value = value.toString().replace(/\D/g,''); // value?.replace(/\D/g, "");
      this._value = this.applyUnmask(value);
      //   if (!this._value.includes("-")) {
      //     // If the value doesn't contain hyphens, apply the mask
      //     this._value = this.applyMask(this._value);
      //   }
      this.updateInputValue(!this._value.includes("-") ? this.applyMask(this._value) : this._value);
      this.onChange(this._value);
    } else {
      this._value = "";
      this.updateInputValue("");
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  @HostListener("input", ["$event"])
  onInput($event: any) {
    const input = $event.target;
    const value = input.value.toString().replace(/\D/g,''); //input.value.replace(/\D/g, "");
    this._value = this.applyMask(value);
    this.updateInputValue(this._value);
    this.onChange(this._value);
  }

  private applyMask(value: string): string {
    const mask = this.phoneMask.split("");
    let result = "";
    let valueIndex = 0;

    for (const char of mask) {
      if (valueIndex >= value.length) {
        break;
      }

      if (char === "9") {
        result += value[valueIndex];
        valueIndex++;
      } else {
        result += char;
      }
    }

    return result;
  }

  private applyUnmask(value: string): string {
    return value.toString().replace(/\D/g,''); //value.replace(/\D/g, "");
  }

  private updateInputValue(value: string): void {
    this.el.nativeElement.value = value;
  }

  private onChange: any = () => {};
  private onTouched: any = () => {};
}
