import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from '../../shared/service/shared.service';
import { ClintOnboardingService } from '../../clint-onboarding/shared/service/clint-onboarding.service';
import { SetupService } from '../../setup/shared/service/setup.service';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-roll-over',
  templateUrl: './roll-over.component.html',
  styleUrls: ['./roll-over.component.css']
})
export class RollOverComponent {

  constructor(
    private modalService: NgbModal,
    public router: Router,
    public _sharedSvc: SharedService,
    public _clintOnboardingSvc: ClintOnboardingService,
    public _SetupService: SetupService,
    private formBuilder: FormBuilder,
    public translate: TranslateService
  ){

  }

}
