import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'src/app/components/shared/service/shared.service';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, Validators , NgForm} from '@angular/forms';

import { ClintOnboardingService } from 'src/app/components/clint-onboarding/shared/service/clint-onboarding.service';
import { TranslateService } from '@ngx-translate/core';
import { ProductServices } from '../../shared/service/product.service';
import { PicklistService } from 'src/app/components/shared/pickList/picklist.service';


@Component({
  selector: 'app-setup-product-listing',
  templateUrl: './setup-product-listing.component.html',
  styleUrls: ['./setup-product-listing.component.css']
})
export class SetupProductListingComponent  implements OnInit {

  
  loadingIndicator: boolean = false;
  rows: any;

  page: number = 5;
  pageSize: number = 5;
  collectionSize: number = 5;

  loginForm!: FormGroup;
  submitted = false;
  // currentDate: any = new Date().toISOString().split("T")[0];
  onBoardingTablePG: any;
  maskedCNIC: string;

  getAllProducsList:any = [];


  constructor(
    private modalService: NgbModal,
    public router: Router,
    public _sharedSvc: SharedService,
    public _clintOnboardingSvc: ClintOnboardingService,
    
    private formBuilder: FormBuilder,
    public translate: TranslateService,
    public _picklistServices: PicklistService,
    public _productServices:ProductServices
    
  ) { }

  ngOnInit(): void {
    this.getAllProducs();
  }

  pageChange() {
    this.collectionSize = this._clintOnboardingSvc.onBoardingTable.length;
    this.onBoardingTablePG = this._clintOnboardingSvc.onBoardingTable.slice(
      (this.page - 1) * this.pageSize,
      (this.page - 1) * this.pageSize + this.pageSize
    );
    this.onBoardingTablePG;
  }

  openModal(content: any) {
    localStorage.removeItem("selectedOrderData");
    this.modalService.open(content, {
      // backdrop: "static",
      // keyboard: false,
      windowClass: "popupWidth1k",
      // backdropClass: 'light-blue-backdrop',
      centered: true
    });
  }

  getAllProducs(){
    let key = localStorage.getItem("prdGrpSeq");
    this._sharedSvc.showSpinner();
    this._picklistServices.allProductByGrpSeq(key).subscribe({
      next: (response: any) => {
        this._sharedSvc.hideSpinner();
        if (response) {
          if (response.error) {
            this._sharedSvc.error(response.error);
            return;
          }
          this.getAllProducsList = response
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }


  navigate(id:any): void {
    if(id){
      localStorage.setItem("prdSeqID" , id);
      this.router.navigate(['/setup-add-products/'+ id]);
    } else {
      this.router.navigate(['/setup-add-products']);

    }
  }

}