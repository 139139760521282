import { Component, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SharedService } from "src/app/components/shared/service/shared.service";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
  NgForm,
} from "@angular/forms";

import { ClintOnboardingService } from "src/app/components/clint-onboarding/shared/service/clint-onboarding.service";
import { SetupService } from "../../shared/service/setup.service";
import { PicklistService } from "src/app/components/shared/pickList/picklist.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-community",
  templateUrl: "./community.component.html",
  styleUrls: ["./community.component.css"],
})
export class CommunityComponent implements OnInit {
  loadingIndicator: boolean = false;
  rows: any;

  page: number = 5;
  pageSize: number = 5;
  collectionSize: number = 5;

  loginForm!: FormGroup;
  submitted = false;
  // currentDate: any = new Date().toISOString().split("T")[0];
  onBoardingTablePG: any;
  maskedCNIC: string;
  isEdit: boolean = false;

  addCommunityformdata: any;
  singleRecord: any;
  currentPortID: any;
  branchID: any;
  branchName: any;

  constructor(
    private modalService: NgbModal,
    public router: Router,
    public _sharedSvc: SharedService,
    public _clintOnboardingSvc: ClintOnboardingService,
    public _SetupService: SetupService,
    public _picklistService: PicklistService,

    private formBuilder: FormBuilder,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    let portSeq = sessionStorage.getItem("portSeq");
    this.currentPortID = portSeq;
    this._SetupService.get_port_community_list(portSeq);
    this._picklistService.SHROFF_STATUS();

    this.branchID = sessionStorage.getItem("singlebranchID");
    this.branchName = sessionStorage.getItem("singlebranchName");

    this.addCommunityformdata = this.formBuilder.group({
      cmntyNm: ["", Validators.required],
      cmntyStsKey: ["", Validators.required],
      cmntyCmnt: ["", Validators.required],
    });
  }

  get cmntyNm() {
    return this.addCommunityformdata.get("cmntyNm");
  }
  get cmntyStsKey() {
    return this.addCommunityformdata.get("cmntyStsKey");
  }
  get cmntyCmnt() {
    return this.addCommunityformdata.get("cmntyCmnt");
  }

  pageChange() {
    this.collectionSize = this._clintOnboardingSvc.onBoardingTable.length;
    this.onBoardingTablePG = this._clintOnboardingSvc.onBoardingTable.slice(
      (this.page - 1) * this.pageSize,
      (this.page - 1) * this.pageSize + this.pageSize
    );
    this.onBoardingTablePG;
  }

  openAddEditCommunityModal(content: any, edit: boolean) {
    localStorage.removeItem("selectedOrderData");
    this.isEdit = edit;
    this.modalService.open(content, {
      // backdrop: "static",
      // keyboard: false,
      windowClass: "popupWidth800",
      // backdropClass: 'light-blue-backdrop',
      centered: true,
    });
  }
  submitCommunity(val) {
    if (this.addCommunityformdata.invalid) {
      for (const control of Object.keys(this.addCommunityformdata.controls)) {
        this.addCommunityformdata.controls[control].markAsTouched();
      }
      return;
    }
    let data = {
      ...this.addCommunityformdata.value,
      cmntyCd: "0",
      portSeq: Number(this.currentPortID),
      delFlg: 0,
    };
    if (this.isEdit) {
      data.cmntySeq = this.singleRecord.cmntySeq;
    }
    this._SetupService.setupCommunityModel = data;
    this._SetupService.saveCommunity().subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.success(
            this.translate.instant("common.community") +
            (this.isEdit === true
              ? this.translate.instant("toast.updated")
              : this.translate.instant("toast.added"))
          );
          this._sharedSvc.hideSpinner3();
          this.modalService.dismissAll();
          this._SetupService.get_port_community_list(this.currentPortID);
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner3();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(
            ([key, value]: any, index: number) => {
              this._sharedSvc.error(value);
            }
          );
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    });
  }

  editCommunity(content: any, id: any, edit: boolean) {
    let list = this._SetupService.portCommunityList;
    let record = list.find((x) => x.cmntySeq === id);
    this.singleRecord = record;

    if (record) {
      let data = {
        cmntyNm: record.cmntyNm,
        cmntyStsKey: record.cmntyStsKey,
        cmntyCmnt: record.cmntyCmnt,
      };
      this.addCommunityformdata.patchValue(data);
      this.openAddEditCommunityModal(content, edit);
    }
  }

  deleteRecord(id) {
    
    let list = this._SetupService.portCommunityList;
    let record = list.find((x) => x.cmntySeq === id);
    this.singleRecord = record;

    if (record) {
      let data = {
        ...record,
        delFlg: 1,
      };
      this._SetupService.setupCommunityModel = data;
      this._SetupService.saveCommunity().subscribe({
        next: (response) => {
          if (response) {
            this._sharedSvc.success(this.translate.instant('toast.communityDeleted'));
            this._sharedSvc.hideSpinner3();
            this.modalService.dismissAll();
            this._SetupService.get_port_community_list(this.currentPortID);
          }
        },
        error: (e) => {
          this._sharedSvc.hideSpinner3();
          if (e.status == 401) {
            this._sharedSvc.error(this._sharedSvc.authMsg);
            this._sharedSvc.logout();
            return;
          }
          if (e?.error) {
            Object.entries(e?.error).forEach(
              ([key, value]: any, index: number) => {
                this._sharedSvc.error(value);
              }
            );
          }
          if (e?.error?.errorMessage) {
            this._sharedSvc.error(e?.error?.error);
          }
        },
      });
    }
  }

  navigate() {
    this.router.navigate(["/disbursement-form"]);
  }

  portfolioFn() {
    this.router.navigate(["/setup-portfolio"]);
  }
}
