<div class="modal-header">
    <h4>Alert</h4>
    <div class="header_cross" (click)="deleteContract()">
        <h2>&times;</h2>
    </div>
</div>

<div class="modal-body">
    <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="text-center">
                <h6 class="red-clr">{{message}}</h6>
                <p *ngIf="contractNametoDelete" ><b>{{contractNametoDelete}}</b></p>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="button_div mt-3">
                <button class="btn login-button-class" (click)="deleteContract()">{{btnOkText}}</button>
                <button class="btn login-button-class cancelBtn" (click)="close()">{{btnCancelText}}</button>
            </div>
        </div>
    </div>

</div>