<section>
    <div class="sidebar" [class.sidebar-close]="!_sharedSvc.openSidebar">
        <app-sidebar></app-sidebar>
    </div>
    <div class="home-section">
        <div class="home-main" [class.sidebar-close_home-main]="!_sharedSvc.openSidebar">
            <app-navbar></app-navbar>

            <div class="home-view" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
                [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
                <section>
                    <div class="row mb-3">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <h4> {{ "userManagement.rightsAssignment" | translate }}</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4 col-md-4 col-lg-4">
                            <section>
                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <div class="dashboard-card">
                                            <div class="personal-info">
                                                <div class="add-button mt-2" (click)="openModal(userMangement,false)"><i class="fa fa-plus"></i></div>
                                                <ul ngbNav #nav="ngbNav" class="nav-tabs"
                                                    style="justify-content: start;">
                                                    <li [ngbNavItem]="2">
                                                        <a ngbNavLink> {{ "userManagement.roles" | translate }}</a>
                                                        <ng-template ngbNavContent>
                                                            <div class="row">
                                                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                    <div class="managementDiv">
                                                                        <a> {{ "userManagement.adminCapital" | translate
                                                                            }} <i class="fa fa-pencil"></i></a>
                                                                    </div>
                                                                    <div class="managementDiv">
                                                                        <a> {{ "userManagement.bdo" | translate }} <i
                                                                                class="fa fa-pencil"></i></a>
                                                                    </div>
                                                                    <div class="managementDiv">
                                                                        <a> {{ "userManagement.bm" | translate }} <i
                                                                                class="fa fa-pencil"></i></a>
                                                                    </div>
                                                                    <div class="managementDiv">
                                                                        <a> {{ "userManagement.ra" | translate }} <i
                                                                                class="fa fa-pencil"></i></a>
                                                                    </div>
                                                                    <div class="managementDiv">
                                                                        <a> {{ "userManagement.finance" | translate }}
                                                                            <i class="fa fa-pencil"></i></a>
                                                                    </div>
                                                                    <div class="managementDiv">
                                                                        <a> {{ "userManagement.insurance" | translate }}
                                                                            <i class="fa fa-pencil"></i></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-template>
                                                    </li>
                                                </ul>
                                                <div [ngbNavOutlet]="nav" class="mt-2">                                             
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div class="col-sm-8 col-md-8 col-lg-8">
                            
                            <div class="row mb-3">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <h4> {{ "common.admin" | translate }}</h4>
                                </div>
                            </div>
                            <div class="col-md-12" *ngFor="let item of ModesList; let i = index">
                                <div class="dashboard-card loan-table mb-2">
                                  <div class="row">
                                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                                      <span class="ms-2"><b>{{ item.modNm }}</b></span>
                                      <i class="fa float-right" [class]="item.expanded ? ' fa-angle-up' : 'fa-angle-down'" (click)="showHideDiv(i)"></i>
                                    </div>
                                  </div>
                                  <div class="row" *ngIf="item.expanded">
                                    <div class="col-sm-6 col-md-6 col-lg-3 mb-3" *ngFor="let subItem of getSubItems(item.modSeq)">
                                        <input type="checkbox" class="form-check-input pointer" [checked]="isModuleChecked(subItem.sbModSeq)"
                                          (change)="handleCheckboxChange(subItem, $event)">
                                        <span class="ms-2">{{ subItem.sbModNm }}</span>
                                      </div>
                                  </div>
                                </div>
                              </div>     
                             </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</section>

<ng-template id="display-modal" #userMangement let-c="close" let-d="dismiss">
    <div class="modal-body">
        <div class="p-3 pb-0">
            <div class="row mb-3">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="text-center">
                        <h4>{{isEdit?'Edit Role':'Add Role'}}</h4>
                    </div>
                </div>
            </div>
            <form [formGroup]="addRoleForm" (ngSubmit)="submitRoleForm(addRoleForm)">
                <div class="row mb-3">
                    <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
                        <div class="form-floating">
                            <input type="text" style="text-transform: capitalize;" autocomplete="off" class="form-control input-radius" formControlName="userRoleName"
                            [class.is-invalid]="userRoleName.invalid && (userRoleName.dirty || userRoleName.touched)" >
                            <div *ngIf="userRoleName.invalid && (userRoleName.dirty || userRoleName.touched)" class="invalid-feedback">
                                <div *ngIf="userRoleName.errors?.['required']">
                                  Name is required.
                                </div>
                            </div>
                            <label >Name</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
                        <div class="form-floating">
                            <input type="text" style="text-transform: capitalize;" autocomplete="off" class="form-control input-radius" formControlName="userRoleComment"
                            [class.is-invalid]="userRoleComment.invalid && (userRoleComment.dirty || userRoleComment.touched)" >
                            <div *ngIf="userRoleComment.invalid && (userRoleComment.dirty || userRoleComment.touched)" class="invalid-feedback">
                                <div *ngIf="userRoleComment.errors?.['required']">
                                Description is required.
                                </div>
                            </div>
                            <label >Description</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="button_div">
                            <button class="btn login-button-class" [class.pointerNone]="_sharedSvc.loading"
                                [disabled]="_sharedSvc.loading">{{'common.ok' | translate}}</button>
                            <button class="btn login-button-class cancelBtn" (click)="d('Cross click')"
                                type="reset">{{'common.cancel' | translate}}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>