import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-psc',
  templateUrl: './psc.component.html',
  styleUrls: ['./psc.component.css']
})
export class PscComponent implements OnInit {

  currentDate: any = new Date().toISOString().split("T")[0];

  constructor( ) { }

  ngOnInit(): void {  }



}
