<section>
  <div class="sidebar" [class.sidebar-close]="!_sharedSvc.openSidebar">
    <app-sidebar></app-sidebar>
  </div>
  <div class="home-section">
    <div class="home-main" [class.sidebar-close_home-main]="!_sharedSvc.openSidebar">
      <app-navbar></app-navbar>
      <div class="home-view">
        <section class="dashboard-icon"
          [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''">
          <div class="row mb-3">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <h4>
                <span (click)="clickDashboard()" *ngIf="showOption('dashboard')">
                  <div class="btn infoBtn mt-2 text-uppercase" [class.infoBtn_bg]="dashboardView" (click)="test()">
                    {{'common.dashboard' |
                    translate}}</div>
                </span>
                <span (click)="clickOther()" class="ms-4" *ngIf="showOption('tasks')">
                  <div class="btn infoBtn mt-2" [class.infoBtn_bg]="!dashboardView">{{'common.task' | translate}}</div>
                </span>
              </h4>
            </div>
          </div>
          <ng-container *ngIf="showOption('dashboard')">
            <ng-container *ngIf="dashboardView">
              <div class="row mb-3">
                <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                  <div class="dashboard-card dashboardPara">
                    <div class="row">
                      <div class="col-sm-12 col-md-7 col-lg-7">
                        <div>
                          <div id="chart-pie-1" style="height: 130px;"></div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-5 col-lg-5 marginAuto">
                        <h6> {{'dashboard.par1D' | translate}}</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                  <div class="dashboard-card dashboardPara">
                    <div class="row">
                      <div class="col-sm-12 col-md-7 col-lg-7">
                        <div>
                          <div id="chart-pie-2" style="height: 130px;"></div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-5 col-lg-5 marginAuto">
                        <h6>{{'dashboard.par3D' | translate}}</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                  <div class="dashboard-card dashboardPara">
                    <div class="row">
                      <div class="col-sm-12 col-md-7 col-lg-7">
                        <div>
                          <div id="chart-pie-3" style="height: 130px;"></div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-5 col-lg-5 marginAuto">
                        <h6> {{'dashboard.tat' | translate}}</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                  <div class="dashboard-card dashboardPara">
                    <div class="row">
                      <div class="col-sm-12 col-md-7 col-lg-7">
                        <div>
                          <div id="chart-pie-4" style="height: 130px;"></div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-5 col-lg-5 marginAuto">
                        <h6> {{'dashboard.ror' | translate}}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                  <div class="dashboard-card gradient-org">
                    <div class="row">
                      <div class="col-sm-2 col-md-2 col-lg-2">
                        <i class="fa fa-usd"></i>
                      </div>
                      <div class="col-sm-10 col-md-10 col-lg-10">
                        <h5>{{'dashboard.currentOutstanding' | translate}}</h5>
                        <h5>{{'common.rs' | translate}} 650,000</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                  <app-active-loans></app-active-loans>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                  <app-overdue></app-overdue>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-8 mb-3">
                  <div class="dashboard-card">
                    <div class="p-2">
                      <div id="chart-column" style="height: 230px;"></div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                  <div class="mb-3">
                    <div class="dashboard-card">
                      <div class="text-center">
                        <h5>{{'dashboard.averageDisbursement' | translate}}</h5>
                      </div>
                      <div class="gray-Bg">
                        <div class="row">
                          <div class="col-sm-6 col-md-6 col-lg-6">
                            <p>{{'common.repeat' | translate}}: <span>10</span></p>
                          </div>
                          <div class="col-sm-6 col-md-6 col-lg-6">
                            <div class="text-end">
                              <p>{{'common.new' | translate}}: <span>10,000</span></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-1">
                    <div class="dashboard-card dashboard-card-pink">
                      <div class="text-center">
                        <h5>{{'dashboard.femaleParticipation' | translate}}</h5>
                      </div>
                      <div class="gray-Bg gray-fff">
                        <div class="row">
                          <div class="col-sm-6 col-md-6 col-lg-6">
                            <p>{{'common.repeat' | translate}}: <span>10</span></p>
                          </div>
                          <div class="col-sm-6 col-md-6 col-lg-6">
                            <div class="text-end">
                              <p>{{'common.new' | translate}}: <span>10,000</span></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                  <div class="dashboard-card gradient-purple1">
                    <div class="row">
                      <div class="col-sm-2 col-md-2 col-lg-2">
                        <i class="fa fa-list"></i>
                      </div>
                      <div class="col-sm-10 col-md-10 col-lg-10">
                        <h5>{{'dashboard.loanUtilizationCheck' | translate}}</h5>
                        <h5>12 {{'dashboard.clint' | translate}}</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                  <div class="dashboard-card gradient-purple2">
                    <div class="row">
                      <div class="col-sm-2 col-md-2 col-lg-2">
                        <i class="fa fa-hourglass-o"></i>
                      </div>
                      <div class="col-sm-10 col-md-10 col-lg-10">
                        <h5>{{'dashboard.pendingClients' | translate}}</h5>
                        <h5>12 {{'dashboard.clint' | translate}}</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                  <div class="dashboard-card gradient-purple3">
                    <div class="row">
                      <div class="col-sm-2 col-md-2 col-lg-2">
                        <i class="fa fa-line-chart"></i>
                      </div>
                      <div class="col-sm-10 col-md-10 col-lg-10">
                        <h5>{{'dashboard.projectedClients' | translate}}</h5>
                        <h5>12 {{'dashboard.clint' | translate}}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="showOption('tasks')">
            <ng-container *ngIf="!dashboardView">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <div class="dashboard-card loan-table">
                    <div class="row">
                      <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="table-responsive">
                          <table class="table table-bordered">
                            <thead>
                              <tr>
                                <td>{{'dashboard.taskId' | translate}}</td>
                                <td>{{'dashboard.taskDescription' | translate}}</td>
                                <td>{{'dashboard.assignedDate' | translate}}</td>
                                <td>{{'dashboard.assignedTo' | translate}}</td>
                                <td>{{'dashboard.desigination' | translate}}</td>
                                <td>{{'dashboard.taskStatus' | translate}}</td>
                                <td class="text-center">{{'common.action' | translate}}</td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let item of _dashboardSvc.taskListDataPG; let i = index">
                                <td>{{item?.aprvlTaskId}}</td>
                                <td (click)="readOnlyView(item)" class="link pointer">{{item?.aprvlTskDscrptn}}</td>
                                <td>{{item?.asgnDate | date : "dd-MM-yyyy"}}</td>
                                <td>{{item?.asndToUsrId}}</td>
                                <td>{{item?.stsKey}}</td>
                                <td>{{item?.aprvlTaskId}}</td>
                                <td>
                                  <div class="taskBtn">

                                    <button class="btn infoBtn verifiedBg m-1"
                                      (click)="(allowedRoutes.length > 0 && allowedRoutes[0].rolId === 'BS' && item.entyFlg === 1) ? openClientModal(empAssignment,item) : approveTask(item)"
                                      [disabled]="_sharedSvc.loading">Approved</button>
                                    <button class="btn infoBtn cancelBtn m-1" (click)="rejectTask(item)"
                                      [disabled]="_sharedSvc.loading">Declined</button>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>

        </section>
      </div>
    </div>
  </div>
</section>
<ng-template id="display-modal" #empAssignment let-c="close" let-d="dismiss">
  <div class="modal-body">
    <div class="p-3 pb-0">
      <div class="row mb-4">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center">
            <h4>Select User</h4>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-floating position-relative mb-3">
            <select (change)="onSelectManager($event)" [(ngModel)]="selectedManager" [disabled]="!!selectedManager"
              name="gndrKey" id="gndrKey" class="form-control input-radius form-select">
              <option *ngFor="let item of _dashboardSvc.BranchEmpListByRol; let i = index" [value]="item?.empLanId">
                {{ item.empNm }}
              </option>

            </select>
            <div (click)="clearSelectedManager()"
              [ngClass]="{'remove-selection-box': selectedManager, 'd-none': !selectedManager}">
              <i class="fa fa-times icon"></i>
            </div>
            <!-- <div *ngIf="stsKey.invalid && (stsKey.dirty || stsKey.touched)" class="invalid-feedback">
                          <div *ngIf="stsKey.errors?.['required']">
                              Status is required.
                          </div>

                      </div> -->
            <label>Vo Users</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="button_div">
            <button (click)="assignTaskToUser()" class="btn login-button-class" [class.pointerNone]="_sharedSvc.loading"
              [disabled]="_sharedSvc.loading">Ok</button>
            <button class="btn login-button-class cancelBtn" (click)="clearSelectedManager();d('Cross click')"
              type="reset">{{'common.cancel' | translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>