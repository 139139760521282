import { Component, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SharedService } from "src/app/components/shared/service/shared.service";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
  NgForm,
  ValidationErrors,
} from "@angular/forms";

import { ClintOnboardingService } from "src/app/components/clint-onboarding/shared/service/clint-onboarding.service";
import { SetupService } from "../../shared/service/setup.service";
import { PicklistService } from "src/app/components/shared/pickList/picklist.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-question",
  templateUrl: "./question.component.html",
  styleUrls: ["./question.component.css"],
})
export class QuestionComponent implements OnInit {
  loadingIndicator: boolean = false;
  rows: any;

  page: number = 5;
  pageSize: number = 5;
  collectionSize: number = 5;

  loginForm!: FormGroup;
  submitted = false;
  // currentDate: any = new Date().toISOString().split("T")[0];
  onBoardingTablePG: any;
  maskedCNIC: string;

  qstnrKey: any;
  singleRecord: any;
  isEdit: boolean = false;
  questionFormdata: any;

  constructor(
    private modalService: NgbModal,
    public router: Router,
    public _sharedSvc: SharedService,
    public _clintOnboardingSvc: ClintOnboardingService,
    public _SetupService: SetupService,
    private formBuilder: FormBuilder,
    public _picklistService: PicklistService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.questionFormdata = this.formBuilder.group({
      qstCtgryKey: [null, Validators.required],
      qstSortOrdr: [null, [Validators.required, this.numberValidator]],
      qstStr: [null, Validators.required],
      qstStsKey: [null, Validators.required],
      qstTypKey: [null, Validators.required],
    });

    this.qstnrKey = sessionStorage.getItem("qstnr_key");
    this._SetupService.get_question_list(this.qstnrKey);
    this._picklistService.SHROFF_STATUS();
    this._picklistService.QST_CTGRY();
    this._picklistService.QST_TYPE();
  }
  get qstStr() {
    return this.questionFormdata.get("qstStr");
  }
  get qstCtgryKey() {
    return this.questionFormdata.get("qstCtgryKey");
  }
  get qstSortOrdr() {
    return this.questionFormdata.get("qstSortOrdr");
  }
  get qstStsKey() {
    return this.questionFormdata.get("qstStsKey");
  }
  get qstTypKey() {
    return this.questionFormdata.get("qstTypKey");
  }

  openModal(content: any, edit: boolean) {
    localStorage.removeItem("selectedOrderData");
    if (!edit) {
      this.questionFormdata.reset();
    }
    this.isEdit = edit;

    this.modalService.open(content, {
      // backdrop: "static",
      // keyboard: false,
      windowClass: "popupWidth800",
      // backdropClass: 'light-blue-backdrop',
      centered: true,
    });
  }

  submitQuestion(val: any) {
    
    if (this.questionFormdata.invalid) {
      for (const control of Object.keys(this.questionFormdata.controls)) {
        this.questionFormdata.controls[control].markAsTouched();
      }
      return;
    }
    let data = {
      ...this.questionFormdata.value,
      qstSeq: null,
      qstCtgryKey: Number(this.questionFormdata.value.qstCtgryKey),
      qstSortOrdr: Number(this.questionFormdata.value.qstSortOrdr),
      qstStsKey: Number(this.questionFormdata.value.qstStsKey),
      qstTypKey: Number(this.questionFormdata.value.qstTypKey),
      qstnrSeq: Number(this.qstnrKey),
      delFlg: 0,
    };
    if (this.isEdit) {
      data.qstSeq = this.singleRecord.qstSeq;
    }
    this._SetupService.setupsaveQuestionModel = data;
    this._sharedSvc.showSpinner3();
    this._SetupService.saveQuestion().subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.success(
            this.translate.instant("toast.question") +
              (this.isEdit === true
                ? this.translate.instant("toast.updated")
                : this.translate.instant("toast.added"))
          );
          this.questionFormdata.reset();
          this._sharedSvc.hideSpinner3();
          this.modalService.dismissAll();
          this._SetupService.get_question_list(this.qstnrKey);
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner3();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(
            ([key, value]: any, index: number) => {
              this._sharedSvc.error(value);
            }
          );
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    });
  }

  editQuestion(content: any, id: any, edit: any) {
    
    let list = this._SetupService.questionsList;
    let record = list.find((x) => x.qstSeq === id);
    this.singleRecord = record;
    if (record) {
      let data = {
        qstSeq: record.qstSeq,
        qstStr: record.qstStr,
        qstCtgryKey: Number(record.qstCtgryKey),
        qstSortOrdr: Number(record.qstSortOrdr),
        qstStsKey: Number(record.qstStsKey),
        qstTypKey: Number(record.qstTypKey),
        qstnrSeq: Number(this.qstnrKey),
        delFlg: 0,
      };
      this.questionFormdata.patchValue(data);
      this.openModal(content, edit);
    }
  }
  deleteQuestion(id: any) {
    let list = this._SetupService.questionsList;
    let record = list.find((x) => x.qstSeq === id);
    this.singleRecord = record;

    if (record) {
      let data = {
        ...record,
        delFlg: 1,
      };
      this._sharedSvc.showSpinner3();
      this._SetupService.setupsaveQuestionModel = data;
      this._SetupService.saveQuestion().subscribe({
        next: (response) => {
          if (response) {
            this._sharedSvc.success(
              this.translate.instant("toast.questionDeleted")
            );
            this.questionFormdata.reset();
            this._sharedSvc.hideSpinner3();
            this._SetupService.get_question_list(this.qstnrKey);
          }
        },
        error: (e) => {
          this._sharedSvc.hideSpinner3();
          if (e.status == 401) {
            this._sharedSvc.error(this._sharedSvc.authMsg);
            this._sharedSvc.logout();
            return;
          }
          if (e?.error) {
            Object.entries(e?.error).forEach(
              ([key, value]: any, index: number) => {
                this._sharedSvc.error(value);
              }
            );
          }
          if (e?.error?.errorMessage) {
            this._sharedSvc.error(e?.error?.error);
          }
        },
      });
    }
  }

  pageChange() {
    this.collectionSize = this._clintOnboardingSvc.onBoardingTable.length;
    this.onBoardingTablePG = this._clintOnboardingSvc.onBoardingTable.slice(
      (this.page - 1) * this.pageSize,
      (this.page - 1) * this.pageSize + this.pageSize
    );
    this.onBoardingTablePG;
  }
  back() {
    this.router.navigate(["/setup-questionnaire"]);
  }
  next(key: any) {
    sessionStorage.setItem("quest_key", key);
    this.router.navigate(["/setup-answer"]);
  }
  numberValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value === null || value === "" || isNaN(value)) {
      return { notANumber: true };
    }
    return null;
  }
  getStatus(orderIndex: any) {
    
    for (let i = 0; i < this._picklistService.SHROFF_STATUS_Data.length; i++) {
      if (
        Number(this._picklistService.SHROFF_STATUS_Data[i].refCd) === orderIndex
      ) {
        return this._picklistService.SHROFF_STATUS_Data[i].refCdDscr;
      }
    }
  }
  getCategory(orderIndex: any) {
    
    for (let i = 0; i < this._picklistService.QST_CTGRY_LIST.length; i++) {
      if (
        Number(this._picklistService.QST_CTGRY_LIST[i].refCd) === orderIndex
      ) {
        return this._picklistService.QST_CTGRY_LIST[i].refCdDscr;
      }
    }
  }
  getType(orderIndex: any) {
    
    for (let i = 0; i < this._picklistService.QST_TYPE_LIST.length; i++) {
      if (Number(this._picklistService.QST_TYPE_LIST[i].refCd) === orderIndex) {
        return this._picklistService.QST_TYPE_LIST[i].refCdDscr;
      }
    }
  }
}
