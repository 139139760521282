export class SaveBizAprsl {
    bizAprslSeq: number;
    clntSeq: number;
    sectKey: number;
    actyKey: number;
    bizDtlStr: string;
    mnthInBiz: number;
    bizPhNum: any;
    delFlg: boolean;
}
export class AdditionalInfo {
    additionalInfoIn: any;
}
export class AdditionalInfoIn {
    bizAprslAdtnlInfoSeq: number;
    qstSeq: number;
    answrSeq: number;
    clntSeq: number;
    delFlg: boolean;
    tntKey: number
}
export class SaveBusiness3 {
    bizAprslAdtnlInfoSeq: number;
    qstSeq: number;
    answrSeq: number;
    clntSeq: number;
    delFlg: boolean;
    tntKey: number
}
export class BusinessExpense {
    incomeType: string;
    frequency: string;
    quantity: string;
    incomeAmount: string;
    averageMonthlyAmount: string;
}
export class BusinessIncome {
    incmDtlSeq: number;
    clntSeq: number;
    incmCtgryKey: number;
    incmAmt: number;
    incmTypKey: number;
    qnty: number;
    frqncy: number;
    cmnt: string;
    delFlg: boolean
}
