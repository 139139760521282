import { Component } from '@angular/core';
import { DisbursementFormService } from '../shared/service/disbursement-form.service';
import { DisbustDetail } from '../shared/model/disbustDetail';
import { SharedService } from 'src/app/components/shared/service/shared.service';

@Component({
  selector: 'app-collateral-information',
  templateUrl: './collateral-information.component.html',
  styleUrls: ['./collateral-information.component.css']
})
export class CollateralInformationComponent {

  disbustment: DisbustDetail;
  collaterialDtl:any;
  // = [
  //   {
  //     brnchBgNum: 20001,
  //     crdtAppSeq: 2233784276830440,
  //     crdtCltrlHdrSeq: 2233784276830440,
  //     dscr: "item",
  //     efctvCrdtAmnt: 5164013,
  //     gldQltyKrt: 66,
  //     grossWghtExcl: 6,
  //     grossWghtIncl: 6,
  //     mrktByngRt: 19359,
  //     shrffId: 2051,
  //     shrfBgNum: 10001,
  //     shrfNm: "Lahore Jewellers"
  //   },
  //   {
  //     brnchBgNum: 20001,
  //     crdtAppSeq: 2233784276830440,
  //     crdtCltrlHdrSeq: 2233784276830440,
  //     dscr: "item",
  //     efctvCrdtAmnt: 5164013,
  //     gldQltyKrt: 66,
  //     grossWghtExcl: 6,
  //     grossWghtIncl: 6,
  //     mrktByngRt: 19359,
  //     shrffId: 2051,
  //     shrfBgNum: 10001,
  //     shrfNm: "Lahore Jewellers"
  //   }
  // ]
  totalGrossWghtExcl: any;
  totalGldQltyKrt: any;
  totalNetWeight: any;
  totalMrktByngRt: any;
  totalMarketVAl: any;
  effctvCrdtAmnt: any;
  crdtUpto: any;
  constructor(
    private dismtService: DisbursementFormService,
    public _sharedSvc: SharedService,) {
    let c = localStorage.getItem('disbustment');
    if (c)
      this.disbustment = JSON.parse(c);
      // console.log(this.disbustment.crdtAppSeq);
    this.dismtService.getCtrll(this.disbustment?.crdtAppSeq, (res: any) => {
      // this.dismtService.getCtrll('5090595609509440', (res: any) => {
      this.collaterialDtl = res
      // console.log(res);
      this.calculationTotalDis();
    })
  }


  calculationTotalDis() {
    this.totalGrossWghtExcl = 0;
    this.totalGldQltyKrt = 0;
    this.totalNetWeight = 0;
    this.totalMrktByngRt = 0;
    this.totalMarketVAl = 0;
    if(this.collaterialDtl)
    for (let i = 0; i < this.collaterialDtl.length; i++) {
      this.totalGrossWghtExcl = this.collaterialDtl[i].grossWghtExcl;
      this.totalGldQltyKrt = this.collaterialDtl[i].gldQltyKrt;
      let totalNetWeight = (this.totalGrossWghtExcl * this.totalGldQltyKrt) / 24;
      this.totalNetWeight = this.totalNetWeight + totalNetWeight;

      let a = ((this.totalGrossWghtExcl * this.totalGldQltyKrt) / 24) * this.collaterialDtl[i].mrktByngRt;
      this.totalMarketVAl = this.totalMarketVAl + a;
    }
    this.effctvCrdtAmnt = (this.totalMarketVAl / 100) * 90;
    this.crdtUpto = this.effctvCrdtAmnt;
  }

  onContinue(){
    this.dismtService.currentTab=4;
  }
}
