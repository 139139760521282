<section [class.positionFX]="_sharedSvc.loading">
    <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="mt-3 loan-table">
                <div class="row mb-3">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="btnCenter otherOutstanding mb-3">
                            <h3>{{'collateralInfo.collateralDetail' | translate}}</h3>
                        </div>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <form>
                            <div class="row">
                                <div class="col-sm-4 col-md-4 col-lg-3 mb-3">
                                    <div class="form-floating">
                                        <input type="text" class="form-control input-radius readonly-field"
                                            value="{{collaterialDtl!=null? collaterialDtl[0]?.shrffId :''}}" readonly>
                                        <label>{{'collateralInfo.shroffID' | translate}}</label>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4 col-lg-3 mb-3">
                                    <div class="form-floating">
                                        <input type="text" class="form-control input-radius readonly-field"
                                            value="{{collaterialDtl!=null? collaterialDtl[0]?.shrfNm:''}}" readonly>
                                        <label>{{'collateralInfo.shroffName' | translate}}</label>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4 col-lg-3 mb-3">
                                    <div class="form-floating">
                                        <input type="text" class="form-control input-radius readonly-field"
                                            value="{{effctvCrdtAmnt}}" readonly>
                                        <label>{{'collateralInfo.effectiveLoanValue' | translate}}</label>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4 col-lg-3 mb-3">
                                    <div class="form-floating">
                                        <input type="text" class="form-control input-radius readonly-field"
                                            value="{{effctvCrdtAmnt}}" readonly>
                                        <label>{{'collateralInfo.recommendedEffectiveLoan' |
                                            translate}}</label>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4 col-lg-3 mb-3">
                                    <div class="form-floating">
                                        <input type="text" class="form-control input-radius readonly-field"
                                            value="{{collaterialDtl!=null ? collaterialDtl[0]?.shrfBgNum: ''}}"
                                            readonly>
                                        <label>{{'collateralInfo.shroffBag' | translate}} #</label>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4 col-lg-3 mb-3">
                                    <div class="form-floating">
                                        <input type="text" class="form-control input-radius readonly-field"
                                            value="{{collaterialDtl!=null ? collaterialDtl[0]?.brnchBgNum :''}}"
                                            readonly>
                                        <label>{{'collateralInfo.branchBag' | translate}} #</label>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr class="text-center">
                                    <td>#</td>
                                    <td>{{'common.description' | translate}}</td>
                                    <td>{{'collateralInfo.grossWeightIncludingWax' | translate}} (GM)</td>
                                    <td>{{'collateralInfo.grossWeightExcludingWax' | translate}} (GM)</td>
                                    <td>{{'collateralInfo.qualityOfGoldInKarat' | translate}} (B)</td>
                                    <td>{{'collateralInfo.netWeightOfPurityInGrams' | translate}} C=(AxB)/24 (GM)</td>
                                    <td>{{'collateralInfo.marketBuyingRate' | translate}} (D)</td>
                                    <td>{{'collateralInfo.marketValue' | translate}} E = (CxD)</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of collaterialDtl;let i = index">
                                    <td>{{i}}</td>
                                    <td>{{item!=null? (item?.dscr | titlecase) :''}}</td>
                                    <td>{{item!=null? (item?.grossWghtIncl | number):''}}</td>
                                    <td>{{item!=null? (item?.grossWghtExcl | number):''}}</td>
                                    <td>{{item!=null? (item?.gldQltyKrt | number):''}}</td>
                                    <td>{{item!=null? ((item?.grossWghtExcl*item?.gldQltyKrt)/24 | number):''}}</td>
                                    <td>{{item!=null? (item?.mrktByngRt | number):''}}</td>
                                    <td>{{item!=null? (((item?.grossWghtExcl*item?.gldQltyKrt)/24)*item?.mrktByngRt
                                        | number):''}}
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><b>{{totalNetWeight | number}}</b></td>
                                    <td></td>
                                    <td><b>{{totalMarketVAl | number}}</b></td>
                                <tr>

                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- <div class="row mb-3">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="button_div">
                            <button class="btn login-button-class" type="button" (click)="onContinue()">CONTINUE</button>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</section>