import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CookieService } from 'ngx-cookie-service';
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { environment } from "src/app/global";
import { SharedService } from "src/app/components/shared/service/shared.service";
import { DatePipe } from "@angular/common";
import { PicklistService } from "src/app/components/shared/pickList/picklist.service";
import { Endpoint } from "src/app/components/shared/serviceEndPoints";

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  taskListData: any;
  taskListDataPG: any;
  BranchEmpListByRol: any
  branchID:any

  constructor(
    public router: Router,
    private _http: HttpClient,
    private _cookieSvc: CookieService,
    public _sharedSvc: SharedService,
    public _picklistService: PicklistService

  ) { }

  // APIS Start
  rejectTask(taskSeq: any) {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL + Endpoint.Task.rjct_my_task + taskSeq, 111, this._sharedSvc.requestOptions);
  }

  approveTask(taskSeq: any) {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL + Endpoint.Task.aprv_my_task + taskSeq, 111, this._sharedSvc.requestOptions);
  }
  assignTaskToUser(body:any){
    
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL + Endpoint.Task.assign_task, body, this._sharedSvc.requestOptions);
  }

  getTaskList(usrId: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL + Endpoint.Task.get_my_tasks + usrId, this._sharedSvc.requestOptions);
  }

  getTaskEmpList(branch_seq: any, rol_seq: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.get_branch_emp_list_by_rol + branch_seq + "/" + rol_seq, this._sharedSvc.requestOptions)
  }
  getTaskBranchEmpbyPort(port_seq: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.get_branch_emp_by_port + port_seq, this._sharedSvc.requestOptions)
  }



  // APIS End
  InItGetTaskList(id: any): void {
    this._sharedSvc.showSpinner();
    this.getTaskList(id).subscribe({
      next: (response: any) => {
        this._sharedSvc.hideSpinner();
        if (response) {
          this.taskListData = response;
          this.pageChange();
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    })
  }

  pageChange() {
    this._sharedSvc.collectionSize = this.taskListData?.length;
    this.taskListDataPG = this.taskListData.slice((this._sharedSvc.page - 1) * this._sharedSvc.pageSize, (this._sharedSvc.page - 1) * this._sharedSvc.pageSize + this._sharedSvc.pageSize);
    // this._sharedSvc.pageSize  = this.getAllOutstandingPG?.length;
  }



  get_Branch_ID(port_seq:any) {
    this._sharedSvc.showSpinner()
    this.getTaskBranchEmpbyPort(port_seq).subscribe(response => {
      this._sharedSvc.hideSpinner()
      this.branchID = response;
      console.log(response);
      //@ts-ignore
      localStorage.setItem("BranchSeqIDByPort", JSON.stringify(response));
      //@ts-ignore
      this.get_task_branch_emp_list(response?.brnchSeq , 10)
    })

  }
  get_task_branch_emp_list(branch_seq: any, rol_seq: any) {
    this._sharedSvc.showSpinner()
    this.getTaskEmpList(branch_seq, rol_seq).subscribe(response => {
      this._sharedSvc.hideSpinner();
      this.BranchEmpListByRol = response;
      console.log(response)
    })

  }


  




}
