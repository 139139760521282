import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { Router } from "@angular/router";
import { emailValidator } from './shared/service/email-validator.directive';
import { LoginService } from './shared/service/login.service';
import { CustomCrudToastrService } from '../shared/service/custom-crud-toastr.service';
import { SharedService } from '../shared/service/shared.service';
import { AdminService } from '../admin/shared/service/admin.service';
import { AuthService } from 'src/app/auth/service/auth.service';
import { TranslateService } from '@ngx-translate/core';

// interface ILogin {
//   name: string;
//   nickname: string;
//   email: string;
//  
// }

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  loginData: any;
  rememberMeData: any;
  rememberV: any;
  helper: any;
  showPassword: boolean;

  constructor(
    public _loginSvc: LoginService,
    public router: Router,
    private _cookieSvc: CookieService,
    public customCrudToaster: CustomCrudToastrService,
    public _sharedSvc: SharedService, private adminService: AdminService,
    private authService: AuthService,
    public translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this._sharedSvc.hideSpinner();
    this.loginForm = this.validateForm();
    this.checkRememberMe();
  }

  remember() {
    if (this.rememberMeVar.value == false) {
      this.rememberMeVar.setValue(true);
    }
    else {
      this.rememberMeVar.setValue(false);
    }
    if (this.loginForm.invalid) {
      for (const control of Object.keys(this.loginForm.controls)) {
        this.loginForm.controls[control].markAsTouched();
      }
      this.rememberMeVar.setValue(false);
      this._sharedSvc.error(this.translate.instant('toast.fillRequiredFields'))
      return;
    }
    if (this.rememberMeVar.value == true) {
      this._loginSvc.loginModel.username = this.name.value;
      this._loginSvc.loginModel.password = this.password.value;
      let bdy = {
        u: this.name.value,
        p: this.password.value
      }
      localStorage.setItem('rememberMe', JSON.stringify(this.rememberMeVar.value));
      localStorage.setItem('rememberMeData', JSON.stringify(bdy));

    }
    else {
      localStorage.removeItem('rememberMe')
      localStorage.removeItem('rememberMeData')

    }
  }

  checkRememberMe() {
    this.rememberV = localStorage.getItem('rememberMe')
    this.rememberMeData = localStorage.getItem('rememberMeData');
    this.rememberMeData = JSON.parse(this.rememberMeData)
    if (this.rememberV == 'true') {
      this.rememberMeVar.setValue(this.rememberV);
      this._loginSvc.loginModel.username = this.rememberMeData?.u
      this._loginSvc.loginModel.password = this.rememberMeData?.p
    }
  }

  submitLogin(): void {
    
    if (this.loginForm.invalid) {
      for (const control of Object.keys(this.loginForm.controls)) {
        this.loginForm.controls[control].markAsTouched();
      }
      return;
    }
    this._sharedSvc.showSpinner()
    if (this.rememberMeVar.value == true) {
      this.remember();
    }

    this._loginSvc.loginModel.username = this.name.value;
    this._loginSvc.loginModel.password = this.password.value;

    this._loginSvc.Authentication().subscribe({
      next: (response: any) => {
        if (response) {
          this._sharedSvc.hideSpinner()
          this.loginData = response;
          this._sharedSvc.success(this.translate.instant('toast.userLoginSuccessfully'));
          sessionStorage.setItem('Token', 'Bearer ' + response.access_token);
          // this._cookieSvc.set("Token", 'Bearer ' + response.access_token);
          let role = this.authService.jwtToken(response.access_token);

          // console.log(role);
          if (role) {
            this.adminService.getAuthList(role.trim(), (result) => {
              sessionStorage.setItem('routeInfo', JSON.stringify(result));
              this.router.navigate(['dashboard']);
            });
          }
          else
            this.router.navigate(['dashboard']);

        }
      },
      error: (e: any) => {
        this._sharedSvc.error(this.translate.instant('toast.genericError'));
        this._sharedSvc.hideSpinner()
      },
      // complete: () => console.info('complete')
    })


  }

  validateForm() {
    return new FormGroup({
      name: new FormControl(null, [Validators.required, Validators.minLength(3), Validators.maxLength(100),]),
      password: new FormControl(null, [Validators.required, Validators.minLength(6), Validators.maxLength(6),]),
      rememberMeVar: new FormControl(false, []),
    });
  }

  get name() { return this.loginForm.get("name") }
  get password() { return this.loginForm.get("password") }
  get rememberMeVar() { return this.loginForm.get("rememberMeVar") }


}
