import { Component, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SharedService } from "src/app/components/shared/service/shared.service";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
  NgForm,
} from "@angular/forms";

import { ClintOnboardingService } from "src/app/components/clint-onboarding/shared/service/clint-onboarding.service";
import { SetupService } from "../../shared/service/setup.service";
import { PicklistService } from "src/app/components/shared/pickList/picklist.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-province",
  templateUrl: "./province.component.html",
  styleUrls: ["./province.component.css"],
})
export class ProvinceComponent implements OnInit {
  loadingIndicator: boolean = false;
  rows: any;

  page: number = 5;
  pageSize: number = 5;
  collectionSize: number = 5;

  loginForm!: FormGroup;
  submitted = false;
  // currentDate: any = new Date().toISOString().split("T")[0];
  onBoardingTablePG: any;
  maskedCNIC: string;
  singleRecord: any;
  isEdit: boolean = false;
  addStateFormdata: any;
  CountryID: any;
  constructor(
    private modalService: NgbModal,
    public router: Router,
    public _sharedSvc: SharedService,
    public _clintOnboardingSvc: ClintOnboardingService,
    public _SetupService: SetupService,
    private formBuilder: FormBuilder,
    public _picklistService: PicklistService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    let countryID = sessionStorage.getItem("countrySeqID");
    this.CountryID = countryID;
    this._SetupService.get_geo_state_list(countryID);

    this.addStateFormdata = this.formBuilder.group({
      stateName: ["", Validators.required],
      stateStatusKey: ["", Validators.required],
      stateDescription: ["", Validators.required],
    });
    this._picklistService.SHROFF_STATUS();
  }

  get stateName() {
    return this.addStateFormdata.get("stateName");
  }
  get stateStatusKey() {
    return this.addStateFormdata.get("stateStatusKey");
  }
  get stateDescription() {
    return this.addStateFormdata.get("stateDescription");
  }

  submitState(val) {
    if (this.addStateFormdata.invalid) {
      for (const control of Object.keys(this.addStateFormdata.controls)) {
        this.addStateFormdata.controls[control].markAsTouched();
      }
      return;
    }
    let data = {
      ...this.addStateFormdata.value,
      countrySequence: Number(this.CountryID),
      stateCode: "0",
      tenantKey: "0",
      stateSequence: null,
      delFlg: 0,
    };
    if (this.isEdit) {
      data.stateSequence = this.singleRecord.stateSequence;
    }
    this._SetupService.setupGeoStateModel = data;
    this._SetupService.saveGeoState().subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.success(
            this.translate.instant("toast.state") +
              (this.isEdit === true
                ? this.translate.instant("toast.updated")
                : this.translate.instant("toast.added"))
          );
          this._sharedSvc.hideSpinner3();
          this.modalService.dismissAll();
          this._SetupService.get_geo_state_list(this.CountryID);
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner3();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(
            ([key, value]: any, index: number) => {
              this._sharedSvc.error(value);
            }
          );
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    });
  }

  editProvince(content: any, id: any, edit: any) {
    let list = this._SetupService.geoStateList;
    let record = list.find((x) => x.stateSequence === id);
    this.singleRecord = record;

    if (record) {
      let data = {
        stateName: record.stateName,
        stateStatusKey: record.stateStatusKey,
        stateDescription: record.stateDescription,
      };
      this.addStateFormdata.patchValue(data);
      this.openModal(content, edit);
    }
  }

  deleteRecord(id) {
    
    let list = this._SetupService.geoStateList;
    let record = list.find((x) => x.stateSequence === id);
    this.singleRecord = record;

    if (record) {
      let data = {
        ...record,
        delFlg: 1,
      };
      this._SetupService.setupGeoStateModel = data;
      this._SetupService.saveGeoState().subscribe({
        next: (response) => {
          if (response) {
            this._sharedSvc.success(
              this.translate.instant("toast.stateDeleted")
            );
            this._sharedSvc.hideSpinner3();
            this.modalService.dismissAll();
            this._SetupService.get_geo_state_list(this.CountryID);
          }
        },
        error: (e) => {
          this._sharedSvc.hideSpinner3();
          if (e.status == 401) {
            this._sharedSvc.error(this._sharedSvc.authMsg);
            this._sharedSvc.logout();
            return;
          }
          if (e?.error) {
            Object.entries(e?.error).forEach(
              ([key, value]: any, index: number) => {
                this._sharedSvc.error(value);
              }
            );
          }
          if (e?.error?.errorMessage) {
            this._sharedSvc.error(e?.error?.error);
          }
        },
      });
    }
  }

  pageChange() {
    this.collectionSize = this._clintOnboardingSvc.onBoardingTable.length;
    this.onBoardingTablePG = this._clintOnboardingSvc.onBoardingTable.slice(
      (this.page - 1) * this.pageSize,
      (this.page - 1) * this.pageSize + this.pageSize
    );
    this.onBoardingTablePG;
  }

  openModal(content: any, edit: boolean) {
    localStorage.removeItem("selectedOrderData");
    this.isEdit = edit;
    this.modalService.open(content, {
      // backdrop: "static",
      // keyboard: false,
      windowClass: "popupWidth800",
      // backdropClass: 'light-blue-backdrop',
      centered: true,
    });
  }
  back() {
    this.router.navigate(["/setup-geography"]);
  }

  next(state) {
    sessionStorage.setItem("stateSeqID", state);
    this.router.navigate(["/setup-district"]);
  }

  submittProvince() {
    this._SetupService.saveCity().subscribe((data) => {
      alert("geo added");
      // console.log(data)
    });
  }
}
