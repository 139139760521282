<div class="row">
    <div class="col-sm-9 col-md-9 col-lg-9 logoColDiv">
        <div class="logo-details">
            <div class="logo">
                <img class="img-fluid" src="../../../../assets/images/logo-inner-pages.png">
            </div>
        </div>
    </div>
    <div class="col-sm-4 col-md-3 col-lg-3">
        <div class="hamburger-menu">
            <input type="checkbox" class="form-check-input pointer" id="checkbox-hamburger-menu" (click)="showMenu()" />
            <label class="label-hamburger-menu" for="checkbox-hamburger-menu">
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
            </label>
        </div>
    </div>

</div>

<ul class="nav-links" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
    [class.remove-pr]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
    <li #itemEl [class.sidebar-close_icon]="!_sharedSvc.openSidebar">
        <div class="dropdown-title" routerLinkActive="active" *ngIf="showOption('dashboard')">
            <a routerLink="/dashboard">
                <i class="bx bx-grid-alt"></i>
                <span class="link_name">{{'common.dashboard' | translate}}</span>
            </a>
        </div>
        <div class="dropdown-title" routerLinkActive="active" *ngIf="showOption('onboarding')">
            <a routerLink="/onboarding">
                <i class="bx bx-heart"></i>
                <!-- <span class="link_name">Client Onboarding</span> -->
                <span class="link_name">{{'sideBarMenu.clientOnboarding' | translate}}</span>
            </a>
        </div>
        <div class="dropdown-title" routerLinkActive="active" *ngIf="showOption('individual-group-info')">
            <a routerLink="/individual-group-info">
                <i class="bx bx-dollar"></i>
                <span class="link_name">{{'sideBarMenu.indiGroupLoanFormation' | translate}}</span>
            </a>
        </div>
        <div class="dropdown-title" routerLinkActive="active" *ngIf="showOption('disbursement-listing')">
            <a routerLink="/disbursement-listing">
                <i class="fa fa-handshake-o" aria-hidden="true"></i>
                <span class="link_name">{{'sideBarMenu.disbursement' | translate}}</span>
            </a>
        </div>
        <div class="dropdown-title" routerLinkActive="active" *ngIf="showOption('recovery-listing')">
            <a routerLink="/recovery-listing">
                <i class="fa fa-repeat" aria-hidden="true"></i>
                <span class="link_name">{{'sideBarMenu.recovery' | translate}}</span>
            </a>
        </div>
        <div class="dropdown-title mb-0" [ngClass]="{'activeHover': (_sharedSvc.adminSubMenu == true)}"
            *ngIf="hasChild('admin')">
            <a (click)="_sharedSvc.admin_subMenu()">
                <i class="fas fa-user-cog" aria-hidden="true"></i>
                <span class="link_name">{{'common.admin' | translate}}</span>
            </a>
            <i class='fa  arrow'
                [ngClass]="{'fa-angle-right': (_sharedSvc.adminSubMenu == false), ' fa-angle-down': (_sharedSvc.adminSubMenu == true)}"></i>
        </div>
        <ul class="sub-menu" *ngIf="_sharedSvc.adminSubMenu == true">
            <li class="sub-menu-active" routerLinkActive="active" *ngIf="showOption('admin-closingType')">
                <a class="link_name" routerLink="/admin-closingType">
                    <!-- <i class="fa fa-circle" aria-hidden="true"></i> -->
                    <span class="link_name">{{'sideBarMenu.admin.closing' | translate}}</span>
                </a>
            </li>
            <li class="sub-menu-active" routerLinkActive="active" *ngIf="showOption('admin-journalVoucher')">
                <a class="link_name" routerLink="/admin-journalVoucher">
                    <!-- <i class="fa fa-circle" aria-hidden="true"></i> -->
                    <span class="link_name">{{'sideBarMenu.admin.journalVoucher' | translate}}</span>
                </a>
            </li>
            <li class="sub-menu-active" routerLinkActive="active" *ngIf="showOption('admin-goldRate')">
                <a class="link_name" routerLink="/admin-goldRate">
                    <!-- <i class="fa fa-circle" aria-hidden="true"></i> -->
                    <span class="link_name">{{'sideBarMenu.admin.goldRates' | translate}}</span>
                </a>
            </li>
            <li class="sub-menu-active" routerLinkActive="active" *ngIf="showOption('admin-excessRecovery')">
                <a class="link_name" routerLink="/admin-excessRecovery">
                    <!-- <i class="fa fa-circle" aria-hidden="true"></i> -->
                    <span class="link_name">{{'sideBarMenu.admin.excessRecovery' | translate}}</span>
                </a>
            </li>
            <li class="sub-menu-active" routerLinkActive="active" *ngIf="showOption('admin-IncomingBags')">
                <a class="link_name" routerLink="/admin-IncomingBags">
                    <!-- <i class="fa fa-circle" aria-hidden="true"></i> -->
                    <span class="link_name">{{'sideBarMenu.admin.incomingBags' | translate}}</span>
                </a>
            </li>
        </ul>

        <div class="dropdown-title mb-0" [ngClass]="{'activeHover': (_sharedSvc.setupSubMenu == true)}"
            *ngIf="hasChild('setup')">
            <a (click)="_sharedSvc.setup_subMenu()">
                <i class="fas fa-cog" aria-hidden="true"></i>
                <span class="link_name">{{'common.setup' | translate}}</span>
            </a>
            <i class='fa  arrow'
                [ngClass]="{'fa-angle-right': (_sharedSvc.setupSubMenu == false), ' fa-angle-down': (_sharedSvc.setupSubMenu == true)}"></i>
        </div>
        <ul class="sub-menu" *ngIf="_sharedSvc.setupSubMenu == true">
            <li class="sub-menu-active" routerLinkActive="active" *ngIf="showOption('common-codes')">
                <a class="link_name" routerLink="/common-codes">
                    <!-- <i class="fa fa-circle" aria-hidden="true"></i> -->
                    <span class="link_name">{{'sideBarMenu.setup.commonCodes' | translate}}</span>
                </a>
            </li>
            <li class="sub-menu-active" routerLinkActive="active" *ngIf="showOption('setup-area')">
                <a class="link_name" routerLink="/setup-region">
                    <!-- <i class="fa fa-circle" aria-hidden="true"></i> -->
                    <span class="link_name">{{'sideBarMenu.setup.organization' | translate}}</span>
                </a>
            </li>
            <li class="sub-menu-active" routerLinkActive="active" *ngIf="showOption('setup-geography')">
                <a class="link_name" routerLink="/setup-geography">
                    <!-- <i class="fa fa-circle" aria-hidden="true"></i> -->
                    <span class="link_name">{{'sideBarMenu.setup.geography' | translate}}</span>
                </a>
            </li>
            <li class="sub-menu-active" routerLinkActive="active" *ngIf="showOption('setup-product-groups')">
                <a class="link_name" routerLink="/setup-product-groups">
                    <!-- <i class="fa fa-circle" aria-hidden="true"></i> -->
                    <span class="link_name">{{'sideBarMenu.setup.products' | translate}}</span>
                </a>
            </li>

            <li class="sub-menu-active" routerLinkActive="active" *ngIf="showOption('setup-Rules')">
                <a class="link_name" routerLink="/setup-Rules">
                    <!-- <i class="fa fa-circle" aria-hidden="true"></i> -->
                    <span class="link_name">{{'sideBarMenu.setup.rules' | translate}}</span>
                </a>
            </li>
            <li class="sub-menu-active" routerLinkActive="active" *ngIf="showOption('setup-userManagement')">
                <a class="link_name" routerLink="/setup-userManagement">
                    <!-- <i class="fa fa-circle" aria-hidden="true"></i> -->
                    <span class="link_name">{{'sideBarMenu.setup.userManagement' | translate}}</span>
                </a>
            </li>
            <li class="sub-menu-active" routerLinkActive="active" *ngIf="showOption('setup-questionnaire')">
                <a class="link_name" routerLink="/setup-questionnaire">
                    <!-- <i class="fa fa-circle" aria-hidden="true"></i> -->
                    <span class="link_name">{{'sideBarMenu.setup.questionnaire' | translate}}</span>
                </a>
            </li>
            <li class="sub-menu-active" routerLinkActive="active" *ngIf="showOption('setup-healthIP')">
                <a class="link_name" routerLink="/setup-healthIP">
                    <!-- <i class="fa fa-circle" aria-hidden="true"></i> -->
                    <span class="link_name">{{'sideBarMenu.setup.healthInsurancePlan' | translate}}</span>
                </a>
            </li>
            <li class="sub-menu-active" routerLinkActive="active" *ngIf="showOption('setup-payment-type')">
                <a class="link_name" routerLink="/setup-payment-type">
                    <!-- <i class="fa fa-circle" aria-hidden="true"></i> -->
                    <span class="link_name">{{'sideBarMenu.setup.paymentTypes' | translate}}</span>
                </a>
            </li>
            <li class="sub-menu-active" routerLinkActive="active" *ngIf="showOption('setup-expense-type')">
                <a class="link_name" routerLink="/setup-expense-type">
                    <!-- <i class="fa fa-circle" aria-hidden="true"></i> -->
                    <span class="link_name">{{'sideBarMenu.setup.expenseType' | translate}}</span>
                </a>
            </li>
            <li class="sub-menu-active" routerLinkActive="active" *ngIf="showOption('setup-charges-type')">
                <a class="link_name" routerLink="/setup-charges-type">
                    <!-- <i class="fa fa-circle" aria-hidden="true"></i> -->
                    <span class="link_name">{{'sideBarMenu.setup.chargeTypes' | translate}}</span>
                </a>
            </li>
            <li class="sub-menu-active" routerLinkActive="active" *ngIf="showOption('setup-city')">
                <a class="link_name" routerLink="/setup-city">
                    <!-- <i class="fa fa-circle" aria-hidden="true"></i> -->
                    <span class="link_name">{{'sideBarMenu.setup.city' | translate}}</span>
                </a>
            </li>
            <li class="sub-menu-active" routerLinkActive="active" *ngIf="showOption('setup-business-sector')">
                <a class="link_name" routerLink="/setup-business-sector">
                    <!-- <i class="fa fa-circle" aria-hidden="true"></i> -->
                    <span class="link_name">{{'sideBarMenu.setup.businessSector' | translate}}</span>
                </a>
            </li>
            <li class="sub-menu-active" routerLinkActive="active" *ngIf="showOption('setup-tags')">
                <a class="link_name" routerLink="/setup-tags">
                    <!-- <i class="fa fa-circle" aria-hidden="true"></i> -->
                    <span class="link_name">{{'sideBarMenu.setup.tags' | translate}}</span>
                </a>
            </li>
            <li class="sub-menu-active" routerLinkActive="active" *ngIf="showOption('setup-file-upload')">
                <a class="link_name" routerLink="/setup-file-upload">
                    <!-- <i class="fa fa-circle" aria-hidden="true"></i> -->
                    <span class="link_name">{{'sideBarMenu.setup.fileUpload' | translate}}</span>
                </a>
            </li>
            <li class="sub-menu-active" routerLinkActive="active" *ngIf="showOption('setup-shroff-management')">
                <a class="link_name" routerLink="/setup-shroff-management">
                    <!-- <i class="fa fa-circle" aria-hidden="true"></i> -->
                    <span class="link_name">{{'sideBarMenu.setup.shroffManagement' | translate}} </span>
                </a>
            </li>
            <li class="sub-menu-active" routerLinkActive="active" *ngIf="showOption('setup-vault-management')">
                <a class="link_name" routerLink="/setup-vault-management">
                    <!-- <i class="fa fa-circle" aria-hidden="true"></i> -->
                    <span class="link_name">{{'sideBarMenu.setup.vaultManagement' | translate}} </span>
                </a>
            </li>
            <li class="sub-menu-active" routerLinkActive="active" *ngIf="showOption('setup-partner')">
                <a class="link_name" routerLink="/setup-partner">
                    <!-- <i class="fa fa-circle" aria-hidden="true"></i> -->
                    <span class="link_name">{{'sideBarMenu.setup.partnerSetup' | translate}} </span>
                </a>
            </li>

        </ul>
        <div class="dropdown-title" routerLinkActive="active" *ngIf="showOption('branch-accounting')">
            <a routerLink="/branch-accounting">
                <i class="fa fa-calculator"></i>
                <span class="link_name">{{'sideBarMenu.branchAccounting' | translate}} </span>
            </a>
        </div>
        <div class="dropdown-title" routerLinkActive="active" *ngIf="showOption('cnic-expiry')">
            <a routerLink="/cnic-expiry">
                <i class="fa fa-calendar-times-o" aria-hidden="true"></i>
                <span class="link_name">{{'sideBarMenu.cnicExpiry' | translate}}</span>
            </a>
        </div>
    </li>
</ul>

<!-- <ul class="nav-links" id="nav-links">
        <li *ngFor="let item of menuSidebar" #itemEl routerLinkActive="active"
            [class.sidebar-close_icon]="!_sharedSvc.openSidebar">
            <div *ngIf="item.sub_menu.length == 0" class="dropdown-title">
                <a [routerLink]="[item.link]">
                    <i [class]="item.icon"></i>
                    <span class="link_name">{{item.link_name}}</span>
                </a>
            </div>
            <div *ngIf="item.sub_menu.length > 0" class="dropdown-title" (click)="showSubmenu(itemEl)">
                <a>
                    <i [class]="item.icon"></i>
                    <span class="link_name">{{item.link_name}}</span>
                </a>
                <i class='bx bxs-chevron-down arrow'></i>
            </div>
            <ul class="sub-menu" [class.blank]="item.sub_menu.length == 0">
                <li><a class="link_name">{{item.link_name}}</a></li>
                <li *ngFor="let item_sub of item.sub_menu" routerLinkActive="active">
                    <a [routerLink]="[item_sub.link]">{{item_sub.link_name}}</a>
                </li>
            </ul>
        </li>
    </ul>  -->