<section class="p-3">
    <div class="row mb-4">
        <div class="col-sm-8 col-md-8 col-lg-8">
            <h4>{{'common.businessSector' | translate}}</h4>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-6 col-md-6 col-lg-3">
            <div class="checkbox-wrapper custom-checkbox mb-4">
                <input id="handicrafts" type="checkbox" class="form-check-input pointer">
                <label for="handicrafts" class="h6 ms-2 cursor-pointer"> {{'businessSectorTab.handicrafts' |
                    translate}}</label>
            </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3">
            <div class="checkbox-wrapper custom-checkbox mb-4">
                <input id="food" type="checkbox" class="form-check-input pointer">
                <label for="food" class="h6 ms-2 cursor-pointer"> {{'businessSectorTab.food' | translate}}</label>
            </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3">
            <div class="checkbox-wrapper custom-checkbox mb-4">
                <input id="agriculture" type="checkbox" class="form-check-input pointer">
                <label for="agriculture" class="h6 ms-2 cursor-pointer"> {{'businessSectorTab.agriculture' |
                    translate}}</label>
            </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3">
            <div class="checkbox-wrapper custom-checkbox mb-4">
                <input id="garments" type="checkbox" class="form-check-input pointer">
                <label for="garments" class="h6 ms-2 cursor-pointer"> {{'businessSectorTab.garments' |
                    translate}}</label>
            </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3">
            <div class="checkbox-wrapper custom-checkbox mb-4">
                <input id="live-stock" type="checkbox" class="form-check-input pointer">
                <label for="live-stock" class="h6 ms-2 cursor-pointer"> {{'businessSectorTab.liveStock' |
                    translate}}</label>
            </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3">
            <div class="checkbox-wrapper custom-checkbox mb-4">
                <input id="manufacturing" type="checkbox" class="form-check-input pointer">
                <label for="manufacturing" class="h6 ms-2 cursor-pointer"> {{'businessSectorTab.manufacturing' |
                    translate}}</label>
            </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3">
            <div class="checkbox-wrapper custom-checkbox mb-4">
                <input id="services" type="checkbox" class="form-check-input pointer">
                <label for="services" class="h6 ms-2 cursor-pointer"> {{'businessSectorTab.services' |
                    translate}}</label>
            </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3">
            <div class="checkbox-wrapper custom-checkbox mb-4">
                <input id="trading" type="checkbox" class="form-check-input pointer">
                <label for="trading" class="h6 ms-2 cursor-pointer">Trading{{'businessSectorTab.trading' |
                    translate}}</label>
            </div>
        </div>
    </div>
</section>


<div class="row mb-3">
    <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="button_div mt-3">
            <button class="btn login-button-class" type="button" (click)="moveToNextTab()">
                {{'common.continue' | translate}}
            </button>
            <button class="btn login-button-class secondaryBtn" [disabled]="_sharedSvc.loading"
                [class.pointerNone]="_sharedSvc.loading">
                {{'common.save' | translate}}
            </button>
        </div>
    </div>
</div>