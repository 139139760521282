import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-delete-popup',
  templateUrl: './delete-popup.component.html',
  styleUrls: ['./delete-popup.component.css']
})
export class DeletePopupComponent implements OnInit {
  closeResult = '';
  @Input() title: string;
  @Input() message: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() contractNametoDelete: any;
  constructor(private activeModal: NgbActiveModal, public translate: TranslateService,) { }
  ngOnInit(): void {

  }

  deleteContract() {
    this.activeModal.close(true);
  }

  close() {
    this.activeModal.close(false);
  }

  public dismiss() {
    this.activeModal.dismiss(false);
  }

}