<section>
    <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="mt-3 loan-table">
                <div class="row mb-3">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="btnCenter otherOutstanding mb-3">
                            <h3>CLIENT RELATIVES</h3>
                        </div>
                        <div class="btnCenter otherOutstanding">
                            <h6>{{'common.clintName' | translate}}: <b>Murtaza</b> / {{'common.clintId' | translate}}:
                                <b>001</b></h6>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>