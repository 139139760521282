<section>
  <div class="sidebar" [class.sidebar-close]="!_sharedSvc.openSidebar">
    <app-sidebar></app-sidebar>
  </div>
  <div class="home-section">
    <div class="home-main" [class.sidebar-close_home-main]="!_sharedSvc.openSidebar">
      <app-navbar></app-navbar>
      <div class="home-view" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
        [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
        <section>
          <div class="row mb-3">
            <div class="col-sm-8 col-md-8 col-lg-8">
              <h4>{{ "common.businessSector" | translate }}</h4>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4">
              <div class="add-button" (click)="openModal(businessSector, false)">
                <i class="fa fa-plus"></i>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="dashboard-card loan-table">
                <div class="row mb-3">
                  <div class="col-sm-7 col-md-7 col-lg-8">
                    <ng-container *ngIf="_SetupService.pageSize >= 10">
                      <span>{{ "common.show" | translate }}</span>
                      <input name="entries" class="m-1 text-center" [value]="_SetupService.businessSectTablePG?.length"
                        readonly style="width: 45px" />
                      <span>{{ "common.entries" | translate }}</span>
                    </ng-container>
                  </div>
                  <div class="col-sm-5 col-md-5 col-lg-4">
                    <div class="inside-icon">
                      <i class="fa fa-search"></i>
                      <input type="search" class="form-control search-input"
                        placeholder="{{ 'common.search' | translate }}" />
                    </div>
                  </div>
                </div>
                <div class="table-responsive">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <td>
                          {{ "businessSector.businessSectorID" | translate }}
                        </td>
                        <td>
                          {{ "businessSector.businessSectorName" | translate }}
                        </td>
                        <td>{{ "common.sortOrder" | translate }}</td>
                        <td>{{ "common.status" | translate }}</td>
                        <td class="text-center">
                          {{ "common.action" | translate }}
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="
                          let list of _SetupService.businessSectTablePG
                            | sortbyPipe : 'bizSectNm'
                        ">
                        <td>{{ list.bizSectSeq }}</td>
                        <td class="clr186ed1 pointer" (click)="navToBizAct(list)">
                          {{ list.bizSectNm }}
                        </td>
                        <td>{{ list.bizSectSortOrder }}</td>
                        <td>
                          {{
                          list.bizSectStsKey === 2210
                          ? this.translate.instant("common.active")
                          : this.translate.instant("common.inActive")
                          }}
                        </td>
                        <td class="text-center">
                          <div class="action-inner">
                            <i class="fa fa-pencil" (click)="
                                editBizSec(
                                  businessSector,
                                  list.bizSectSeq,
                                  true
                                )
                              "></i>
                            <i class="fa fa-trash" (click)="deleteRecord(list.bizSectSeq)"></i>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="row g-0" *ngIf="_sharedSvc.pageSize > 0">
                    <div class="col-sm-12 col-md-12 col-lg-6">
                      <span class="clr-9d9a9a">{{ "common.showing" | translate }}
                        {{ _SetupService.businessSectTablePG?.length }}
                        {{ "common.of" | translate }}
                        {{ _SetupService.collectionSizeBizSect }}
                        {{ "common.entries" | translate }}</span>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6">
                      <div class="float-right">
                        <ngb-pagination [(page)]="_sharedSvc.page" [pageSize]="_sharedSvc.pageSize" [maxSize]="10"
                          ngDefaultControl [collectionSize]="_SetupService.collectionSizeBizSect" [rotate]="true"
                          (pageChange)="_SetupService.pageChangeSect()">
                          <ng-template ngbPaginationPrevious>{{
                            "common.prev" | translate
                            }}</ng-template>
                          <ng-template ngbPaginationNext>{{
                            "common.next" | translate
                            }}</ng-template>
                        </ngb-pagination>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>

<ng-template id="display-modal" #businessSector let-c="close" let-d="dismiss">
  <div class="modal-body" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
    [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
    <div class="p-3 pb-0">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center">
            <h4>{{ "common.businessSector" | translate }}</h4>
          </div>
        </div>
      </div>
      <form [formGroup]="addBizSecformData" (ngSubmit)="submitBizSecForm(addBizSecformData)">
        <div class="row mb-3">
          <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
            <div class="form-floating">
              <input type="text" style="text-transform: capitalize" autocomplete="off" class="form-control input-radius"
                formControlName="bizSectNm" [class.is-invalid]="
                  bizSectNm.invalid && (bizSectNm.dirty || bizSectNm.touched)
                " />
              <div *ngIf="
                  bizSectNm.invalid && (bizSectNm.dirty || bizSectNm.touched)
                " class="invalid-feedback">
                <div *ngIf="bizSectNm.errors?.['required']">
                  {{ "businessSector.businessSectorNameRequire" | translate }}
                </div>
              </div>
              <label>{{
                "businessSector.businessSectorName" | translate
                }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
            <div class="form-floating">
              <input type="text" style="text-transform: capitalize" autocomplete="off" class="form-control input-radius"
                formControlName="bizSectSortOrder" [class.is-invalid]="
                  bizSectSortOrder.invalid &&
                  (bizSectSortOrder.dirty || bizSectSortOrder.touched)
                " />
              <div *ngIf="
                  bizSectSortOrder.invalid &&
                  (bizSectSortOrder.dirty || bizSectSortOrder.touched)
                " class="invalid-feedback">
                <div *ngIf="bizSectSortOrder.errors?.['required']">
                  {{ "common.sortOrderRequire" | translate }}
                </div>
                <div *ngIf="bizSectSortOrder.errors?.isSortOrderExist">
                  {{ "common.sortOrderAssign" | translate }}
                </div>
              </div>
              <label>{{ "common.sortOrder" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
            <div class="form-floating">
              <select name="gndrKey" id="gndrKey" class="form-control input-radius form-select"
                formControlName="bizSectStsKey" [class.is-invalid]="
                  bizSectStsKey.invalid &&
                  (bizSectStsKey.dirty || bizSectStsKey.touched)
                ">
                <option *ngFor="
                    let item of _picklistService.Ref_Code_Grp_Data;
                    let i = index
                  " [value]="item?.refCdSeq">
                  {{ item.refCdDscr }}
                </option>
              </select>
              <div *ngIf="
                  bizSectStsKey.invalid &&
                  (bizSectStsKey.dirty || bizSectStsKey.touched)
                " class="invalid-feedback">
                <div *ngIf="bizSectStsKey.errors?.['required']">
                  {{ "common.statusRequire" | translate }}
                </div>
              </div>
              <label>{{ "common.status" | translate }}</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="button_div">
              <button class="btn login-button-class" [class.pointerNone]="_sharedSvc.loading"
                [disabled]="_sharedSvc.loading">
                {{ "common.ok" | translate }}
              </button>
              <button class="btn login-button-class cancelBtn" (click)="d('Cross click')" type="reset">
                {{ "common.cancel" | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>