<section class="p-3">
  <div class="row mb-3">
    <div class="col-sm-8 col-md-8 col-lg-8">
      <h4>{{ "addProduct.assignmentSequence" | translate }}</h4>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12">
      <div class="modal-card loan-table p-0">
        <div class="table-responsive">
          <table class="table table-bordered mb-0">
            <thead>
              <tr>
                <td>{{ "assignmentSequenceTab.sequence" | translate }}</td>
                <td>{{ "common.type" | translate }}</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div class="form-floating">
                    <select
                      class="form-control form-select input-radius"
                      (change)="changeServiceCharges($event)"
                    >
                      <option hidden value=""></option>
                      <option
                        *ngFor="let item of serviceChargesOption; let i = index"
                        [value]="item.value"
                      >
                        {{ item.label }}
                      </option>
                    </select>
                    <label>{{
                      "assignmentSequenceTab.order" | translate
                    }}</label>
                  </div>
                </td>
                <td>{{ "recoveryListing.serviceCharges" | translate }} (GK)</td>
              </tr>
              <tr>
                <td>
                  <div class="form-floating">
                    <select
                      class="form-control form-select input-radius"
                      (change)="principleAmountChange($event)"
                    >
                      <option hidden value=""></option>
                      <option
                        *ngFor="
                          let item of principleAmountOption;
                          let i = index
                        "
                        [value]="item.value"
                      >
                        {{ item.label }}
                      </option>
                    </select>
                    <label>{{
                      "assignmentSequenceTab.order" | translate
                    }}</label>
                  </div>
                </td>
                <td>{{ "productRules.principalAmount" | translate }}</td>
              </tr>
              <tr>
                <td>
                  <div class="form-floating">
                    <select
                      class="form-control form-select input-radius"
                      (change)="kszbChange($event)"
                    >
                      <option hidden value=""></option>
                      <option
                        *ngFor="let item of kszbOption; let i = index"
                        [value]="item.value"
                      >
                        {{ item.label }}
                      </option>
                    </select>
                    <label>{{
                      "assignmentSequenceTab.order" | translate
                    }}</label>
                  </div>
                </td>
                <td>KSZB</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="row mb-3">
  <div class="col-sm-12 col-md-12 col-lg-12">
    <div class="button_div mt-3">
      <button
        class="btn login-button-class"
        type="button"
        (click)="moveToNextTab()"
      >
        {{ "common.continue" | translate }}
      </button>
      <button
        class="btn login-button-class secondaryBtn"
        [disabled]="_sharedSvc.loading"
        [class.pointerNone]="_sharedSvc.loading"
      >
        {{ "common.save" | translate }}
      </button>
    </div>
  </div>
</div>
