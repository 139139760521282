export class DisbustmentUpfront{
    crdtAppSeq:number;
    status:string;
    amount:number;
    chargeDetail:string;
    dscription:string;
    post=false;
}

export class UpdateDisbursement{
    crdtAppSeq: number
    stsKey: number
}