import { Component } from '@angular/core';
import { SetupService } from '../../../shared/service/setup.service';
import { SharedService } from 'src/app/components/shared/service/shared.service';

@Component({
  selector: 'app-associate-product',
  templateUrl: './associate-product.component.html',
  styleUrls: ['./associate-product.component.css']
})
export class AssociateProductComponent {

  constructor(
    public _sharedSvc: SharedService,
    private _SetupService: SetupService,
  ) { }


  submittAssociate() {
    this._SetupService.saveRefCdGroup().subscribe(data => {
      alert("associate submitted")
    })
  }

}
