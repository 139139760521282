<div class="row mb-3">
  <div class="col-sm-12 col-md-12 col-lg-12">
    <div class="add-button" (click)="openModal(disbursement_voucher)">
      <i class="fa fa-plus"></i>
    </div>
  </div>
</div>

<div class="table-responsive">
  <table class="table table-bordered">
    <thead>
      <tr>
        <td>{{ "branchAccounting.instrumentNo" | translate }}</td>
        <td>{{ "disbursementRepaymentSchedule.dueDate" | translate }}</td>
        <td>
          {{ "disbursementRepaymentSchedule.principalAmountDue" | translate }}
        </td>
        <td>
          {{
            "disbursementRepaymentSchedule.totalChargedAmountDue" | translate
          }}
        </td>
        <td>
          {{ "disbursementRepaymentSchedule.totalAmountDue" | translate }}
        </td>
        <td>
          {{ "disbursementRepaymentSchedule.dailyAmountDue" | translate }}
        </td>
        <!-- <td class="text-center">Action</td> -->
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let schedule of schedules">
        <td>{{ schedule.instNum }}</td>
        <td>{{ schedule.dueDt }}</td>
        <td>{{ schedule.prnAmt }}</td>
        <td>{{ schedule.srvcChrgAmt ?? "" }}</td>
        <td>
          {{
            schedule.srvcChrgAmt == null || schedule.prnAmt == null
              ? ""
              : schedule.srvcChrgAmt + schedule.prnAmt
          }}
        </td>
        <td>
          {{
            schedule.srvcChrgAmt == null
              ? ""
              : (schedule.srvcChrgAmt / 365).toFixed(2)
          }}
        </td>
        <!-- <td class="text-center">
                    <div class="action-inner">
                        <i class="fa-solid fa-rupee-sign" ></i>
                    </div>
                </td> -->
      </tr>
    </tbody>
  </table>
  <!-- <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-6">
            <span class="clr-9d9a9a">Showing 1 to 3 of 3 entries</span>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6">
            <div class="float-right">
                <ngb-pagination [(page)]="_sharedSvc.page"
                    [pageSize]="_sharedSvc.pageSize" [maxSize]="10" ngDefaultControl
                    [collectionSize]="_sharedSvc.collectionSize" [rotate]="true"
                    (pageChange)="_clintOnboardingSvc.pageChange()">
                    <ng-template ngbPaginationPrevious>Prev</ng-template>
                    <ng-template ngbPaginationNext>Next</ng-template>
                </ngb-pagination>
            </div>
        </div>
    </div> -->
</div>

<ng-template
  id="display-modal"
  #disbursement_voucher
  let-c="close"
  let-d="dismiss"
>
  <div
    class="modal-body"
    [dir]="
      translate?.currentLang === 'ar' || translate?.currentLang === 'ur'
        ? 'rtl'
        : ''
    "
    [class.fix-direction]="
      translate?.currentLang === 'ar' || translate?.currentLang === 'ur'
    "
  >
    <div class="p-3 pb-0">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center">
            <h4>
              {{ "disbursementRepaymentSchedule.paymentSchedule" | translate }}
            </h4>
          </div>
        </div>
      </div>
      <form [formGroup]="paymentScheduleForm" #PaymentSchedule_Form="ngForm">
        <div class="row mb-3">
          <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
            <div class="form-floating">
              <!-- <input type="date" class="form-control input-radius" min="{{currentDate}}"
                                [(ngModel)]="disbustment.firstInstallmentDate" (keydown)="disableDate()"> -->

              <input
                type="date"
                class="form-control input-radius"
                formControlName="firstInstallmentDate"
                min="{{ yearMinDate }}"
                max="{{yearMaxDate}}"
                [class.is-invalid]="
                  firstInstallmentDate.invalid &&
                  (firstInstallmentDate.dirty || firstInstallmentDate.touched)
                "
                (keydown)="disableDate()"
              />
              <div
                *ngIf="
                  firstInstallmentDate.invalid &&
                  (firstInstallmentDate.dirty || firstInstallmentDate.touched)
                "
                class="invalid-feedback"
              >
                <div *ngIf="firstInstallmentDate.errors?.['required']">
                  {{ "common.dateRequire" | translate }}
                </div>
              </div>
              <label>{{
                "disbursement.firstInstallmentDate" | translate
              }}</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="button_div">
              <button class="btn login-button-class" (click)="createSchedule()">
                {{ "common.ok" | translate }}
              </button>
              <button
                class="btn login-button-class cancelBtn"
                (click)="d('Cross click')"
                type="reset"
              >
                {{ "common.cancel" | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
