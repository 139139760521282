import { Injectable } from '@angular/core';
import { DisbustmentCredit } from '../model/disbust-credit';
import { DisbursementVoucher } from '../model/disbust-vocher';
import { SharedService } from 'src/app/components/shared/service/shared.service';
import { environment } from "src/app/global";
import { HttpClient } from '@angular/common/http';
import { Endpoint } from 'src/app/components/shared/serviceEndPoints';
import { DisbustmentSchedule } from '../model/disbust-schedule';
import { DisbustDetail } from '../model/disbustDetail';
import { UpdateDisbursement } from '../model/disbut-upfront';
@Injectable({
  providedIn: 'root'
})
export class DisbursementFormService {

  disbustmentVocher:DisbursementVoucher[]=[]
  schedulePayment:DisbustmentSchedule[]=[];
  disContinueButton=true;
  saveButton=true;
  currentTab=1;
  vocherValidated=false;
  disbustment:DisbustDetail;

  UpdateDisbursement = new UpdateDisbursement();

  constructor( public _sharedSvc: SharedService,private _http: HttpClient,) { }

  private getCALApprist() {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL + Endpoint.creditApp.get_ca_aprvd_list, this._sharedSvc.requestOptions);
  }

  private getCAById(crdAppSeq:any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL + Endpoint.Summary.get_ca_summary+crdAppSeq, this._sharedSvc.requestOptions);
  }

/* Dispustment API*/
private getDsbmtDetail(dsmtSeq: any) {
  this._sharedSvc.tokenHeader();
  return this._http.get<any>(environment.config.BASE_API_DSBMT_URL + Endpoint.DisbustmentCredit.get_disbustment_detail + dsmtSeq, this._sharedSvc.requestOptions);
}

private addPaymentSchedule(crdtAppSeq: any,firstInstallmentDate:any,userId:any) {
  this._sharedSvc.tokenHeader();
  return this._http.get<any>(environment.config.BASE_API_DSBMT_URL + Endpoint.DisbustmentCredit.create_paymrnt_schedule + crdtAppSeq+"/"+firstInstallmentDate+"/"+userId, this._sharedSvc.requestOptions);
}

private getPaymentScheduleInfo(crdtAppSeq: any) {
  this._sharedSvc.tokenHeader();
  return this._http.get<any>(environment.config.BASE_API_DSBMT_URL + Endpoint.DisbustmentCredit.get_payment_schedule_info + crdtAppSeq, this._sharedSvc.requestOptions);
}

private getUpfrontPaymentInfo(crdtAppSeq: any) {
  this._sharedSvc.tokenHeader();
  return this._http.get<any>(environment.config.BASE_API_DSBMT_URL + Endpoint.DisbustmentCredit.get_upfront_payment_info + crdtAppSeq, this._sharedSvc.requestOptions);
}

private getCltrlInfo(crdtAppSeq: any) {
  this._sharedSvc.tokenHeader();
  return this._http.get<any>(environment.config.BASE_API_DSBMT_URL + Endpoint.DisbustmentCredit.get_cltrl_info + crdtAppSeq, this._sharedSvc.requestOptions);
}

private saveAllDsbmtDtl(body:any){
  this._sharedSvc.tokenHeader();
  return this._http.post<any>(environment.config.BASE_API_DSBMT_URL + Endpoint.DisbustmentCredit.save_all_dsbmt_dtl, body,this._sharedSvc.requestOptions);
}


private getDbmt(crdtAppSeq: any) {
  this._sharedSvc.tokenHeader();
  return this._http.get<any>(environment.config.BASE_API_DSBMT_URL + Endpoint.DisbustmentCredit.get_dsbmt + crdtAppSeq, this._sharedSvc.requestOptions);
}


private saveDsbmtAPI(body:any){
  this._sharedSvc.tokenHeader();
  return this._http.post<any>(environment.config.BASE_API_DSBMT_URL + Endpoint.DisbustmentCredit.save_dsbmt, body,this._sharedSvc.requestOptions);
}
UpdateDsbmtAPI(){
  this._sharedSvc.tokenHeader();
  return this._http.post<any>(environment.config.BASE_API_URL + Endpoint.DisbustmentCredit.update_credit_app_sts, this.UpdateDisbursement,this._sharedSvc.requestOptions);
}


  getAllCaApproList(callback:any): void {
    this._sharedSvc.showSpinner();
    this.getCALApprist().subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.hideSpinner();
          return callback(response);
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
            return null;
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
          return null;
        }
      },
      // complete: () => console.info('complete')
    })
  }

  getCADetailById(crdAppSeq:any,callback:any): void {
    this._sharedSvc.showSpinner();
    this.getCAById(crdAppSeq).subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.hideSpinner();
          return callback(response);
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
            return null;
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
          return null;
        }
      },
      // complete: () => console.info('complete')
    })
  }


  getDbsmtVocherList( dsmtSeq: any,callback:any): void {
    this._sharedSvc.showSpinner();
    this.getDsbmtDetail(dsmtSeq).subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.hideSpinner();
          return callback(response);
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
            return null;
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
          return null;
        }
      },
      // complete: () => console.info('complete')
    })
  }


  createPaymentSchedule( crdtAppSeq: any,firstInstallmentDate:any,userId:any,callback:any): void {
    this._sharedSvc.showSpinner();
    this.addPaymentSchedule(crdtAppSeq,firstInstallmentDate,userId).subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.hideSpinner();
          return callback(response);
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
            return null;
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
          return null;
        }
      },
      // complete: () => console.info('complete')
    })
  }

  getDisbustmentPaymentSchedule( crdtAppSeq: any,callback:any): void {
    this._sharedSvc.showSpinner();
    this.getPaymentScheduleInfo(crdtAppSeq).subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.hideSpinner();
          return callback(response);
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
            return null;
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
          return null;
        }
      },
      // complete: () => console.info('complete')
    })
  }


  getUpfrontSchedule( crdtAppSeq: any,callback:any): void {
    this._sharedSvc.showSpinner();
    this.getUpfrontPaymentInfo(crdtAppSeq).subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.hideSpinner();
          return callback(response);
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
            return null;
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
          return null;
        }
      },
      // complete: () => console.info('complete')
    })
  }


  getCtrll( crdtAppSeq: any,callback:any): void {
    this._sharedSvc.showSpinner();
    this.getCltrlInfo(crdtAppSeq).subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.hideSpinner();
          return callback(response);
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
            return null;
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
          return null;
        }
      },
      // complete: () => console.info('complete')
    })
  }

  saveAllDsbmt( body: any,callback:any): void {
    this._sharedSvc.showSpinner();
    this.saveAllDsbmtDtl(body).subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.hideSpinner();
          return callback(response);
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
            return null;
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
          return null;
        }
      },
      // complete: () => console.info('complete')
    })
  }

  getDbmtInfo( crdtAppSeq: any,callback:any): void {
    this._sharedSvc.showSpinner();
    this.getDbmt(crdtAppSeq).subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.hideSpinner();
          return callback(response);
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
            return null;
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
          return null;
        }
      },
      // complete: () => console.info('complete')
    })
  }


  async saveDsbmt( body: any,callback:any) {
    this._sharedSvc.showSpinner();
    this.saveDsbmtAPI(body).subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.hideSpinner();
          return callback(response);
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
            return null;
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
          return null;
        }
      },
      // complete: () => console.info('complete')
    })
  }


  resetAll(){
    this.disbustmentVocher.length=0;
    this.schedulePayment.length=0;
    this.disContinueButton=true;
    this.saveButton=true;
    this.currentTab=1;
    this.vocherValidated=false;
  }
}
