<section>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12">
      <div class="mt-3">
        <div class="row mb-3">
          <div class="col-sm-12 col-md-12 col-lg-3"></div>
          <div class="col-sm-12 col-md-12 col-lg-6">
            <div class="text-center">
              <div class="btnCenter otherOutstanding mb-3">
                <h3>{{ "clientCreditDoc.documents" | translate }}</h3>
              </div>
              <div class="btnCenter otherOutstanding">
                <h6>
                  {{ "common.clintName" | translate }}:
                  <b>{{ saveCnicData?.frstNm | titlecase }}</b> /
                  {{ "common.clintId" | translate }}:
                  <b>{{ saveCnicData?.clntId }}</b>
                </h6>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-3">
            <div class="text-end">
              <div ngbDropdown class="d-inline-block">
                <button
                  type="button"
                  class="btn secondaryBtn login-button-class dropdown-btn-custom"
                  id="dropdownBasic1"
                  ngbDropdownToggle
                >
                  {{ "common.download" | translate }}
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <button ngbDropdownItem>
                    {{ "clientCreditCollateral.facilityLetter" | translate }}
                  </button>
                  <button ngbDropdownItem>
                    {{ "clientCreditCollateral.pledgeLetter" | translate }}
                  </button>
                  <button ngbDropdownItem>
                    {{ "clientCreditCollateral.financeAgreement" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <form>
              <div class="row mt-3 mb-3">
                <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                  <div class="cnic_box dashboard-card dashboard-model">
                    <div class="dashes">
                      <button
                        class=""
                        (click)="
                          allowedRoutes.length > 0 &&
                            allowedRoutes[0].rolId !== 'CMO' &&
                            compressFile('rUtilityBill')
                        "
                      >
                        <label class="hoverable cnic_box">
                          <div class="vertical-center" *ngIf="!rUtilityBill">
                            <i class="fa fa-picture-o mb-3"></i>
                            <p>
                              {{
                                "clientCreditDoc.attachResidenceUtilityBill"
                                  | translate
                              }}
                            </p>
                          </div>
                          <div class="vertical-center-img" *ngIf="rUtilityBill">
                            <img [src]="rUtilityBill ? rUtilityBill : ''" />
                          </div>
                          <div
                            *ngIf="
                              allowedRoutes.length > 0 &&
                              allowedRoutes[0].rolId !== 'CMO'
                            "
                            class="hover-text"
                          >
                            {{ "common.chooseFile" | translate }}
                          </div>
                        </label>
                      </button>
                    </div>
                    <div *ngIf="imgPath && rUtilityBill">
                      <div class="text-center pointer mt-2">
                        <span
                          (click)="
                            openModal(
                              rUtilityBillModel,
                              this.translate.instant(
                                'clientCreditDoc.residenceUtilityBill'
                              )
                            )
                          "
                          >{{ "common.showImage" | translate }}
                          <i class="fa fa-angle-double-down"></i
                        ></span>
                      </div>
                    </div>
                    <div class="btnCenter">
                      <!-- <button class="btn infoBtn cancelBtn img-del-btn" *ngIf="rUtilityBill"
                                                (click)="delete('rUtilityBill')">Delete</button> -->
                    </div>
                  </div>
                  <br />
                  <div class="btnCenter">
                    <label class="text-danger">{{
                      "clientCreditDoc.residenceUtilityBill" | translate
                    }}</label>
                  </div>
                  <div class="btnCenter"></div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                  <div class="cnic_box dashboard-card dashboard-model">
                    <div class="dashes">
                      <button
                        class=""
                        (click)="
                          allowedRoutes.length > 0 &&
                            allowedRoutes[0].rolId !== 'CMO' &&
                            compressFile('bUtilityBill')
                        "
                      >
                        <label class="hoverable cnic_box">
                          <div class="vertical-center" *ngIf="!bUtilityBill">
                            <i class="fa fa-picture-o mb-3"></i>
                            <p>
                              {{
                                "clientCreditDoc.attachBusinessUtilityBill"
                                  | translate
                              }}
                            </p>
                          </div>
                          <div class="vertical-center-img" *ngIf="bUtilityBill">
                            <img [src]="bUtilityBill ? bUtilityBill : ''" />
                          </div>
                          <div
                            *ngIf="
                              allowedRoutes.length > 0 &&
                              allowedRoutes[0].rolId !== 'CMO'
                            "
                            class="hover-text"
                          >
                            {{ "common.chooseFile" | translate }}
                          </div>
                        </label>
                      </button>
                    </div>
                    <div *ngIf="imgPath && bUtilityBill">
                      <div class="text-center pointer mt-2">
                        <span
                          (click)="
                            openModal(
                              bUtilityBillModel,
                              this.translate.instant(
                                'clientCreditDoc.businessUtilityBill'
                              )
                            )
                          "
                          >{{ "common.showImage" | translate }}
                          <i class="fa fa-angle-double-down"></i
                        ></span>
                      </div>
                    </div>
                    <div class="btnCenter">
                      <!-- <button class="btn infoBtn cancelBtn img-del-btn" *ngIf="bUtilityBill"
                                                (click)="delete('bUtilityBill')">Delete</button> -->
                    </div>
                  </div>
                  <br />
                  <div class="btnCenter">
                    <label class="text-danger">{{
                      "clientCreditDoc.businessUtilityBill" | translate
                    }}</label>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                  <div class="cnic_box dashboard-card dashboard-model">
                    <div class="dashes">
                      <button
                        class=""
                        (click)="
                          allowedRoutes.length > 0 &&
                            allowedRoutes[0].rolId !== 'CMO' &&
                            compressFile('fAgreement1')
                        "
                      >
                        <label class="hoverable cnic_box">
                          <div class="vertical-center" *ngIf="!fAgreement1">
                            <i class="fa fa-picture-o mb-3"></i>
                            <p>
                              {{
                                "clientCreditDoc.attachFinanceAgreement1"
                                  | translate
                              }}
                            </p>
                          </div>
                          <div class="vertical-center-img" *ngIf="fAgreement1">
                            <img [src]="fAgreement1 ? fAgreement1 : ''" />
                          </div>
                          <div
                            *ngIf="
                              allowedRoutes.length > 0 &&
                              allowedRoutes[0].rolId !== 'CMO'
                            "
                            class="hover-text"
                          >
                            {{ "common.chooseFile" | translate }}
                          </div>
                        </label>
                      </button>
                    </div>
                    <div *ngIf="imgPath && fAgreement1">
                      <div class="text-center pointer mt-2">
                        <span
                          (click)="
                            openModal(
                              fAgreement1Model,
                              this.translate.instant(
                                'clientCreditDoc.financeAgreement1'
                              )
                            )
                          "
                          >{{ "common.showImage" | translate }}
                          <i class="fa fa-angle-double-down"></i
                        ></span>
                      </div>
                    </div>
                    <!-- <div class="btnCenter">
                                            <button class="btn infoBtn cancelBtn img-del-btn" *ngIf="fAgreement1"
                                                (click)="delete('fAgreement1')">Delete</button>
                                        </div> -->
                  </div>
                  <br />
                  <div class="btnCenter">
                    <label class="text-danger">{{
                      "clientCreditDoc.financeAgreement1" | translate
                    }}</label>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                  <div class="cnic_box dashboard-card dashboard-model">
                    <div class="dashes">
                      <button
                        (click)="
                          allowedRoutes.length > 0 &&
                            allowedRoutes[0].rolId !== 'CMO' &&
                            compressFile('fAgreement2')
                        "
                      >
                        <label class="hoverable cnic_box">
                          <div class="vertical-center" *ngIf="!fAgreement2">
                            <i class="fa fa-picture-o mb-3"></i>
                            <!-- <p>Attach&nbsp;Finance&nbsp;Agreement&nbsp;2</p> -->
                            <p>
                              {{
                                "clientCreditDoc.attachPledgeLetter" | translate
                              }}
                            </p>
                          </div>
                          <div class="vertical-center-img" *ngIf="fAgreement2">
                            <img [src]="fAgreement2 ? fAgreement2 : ''" />
                          </div>
                          <div
                            *ngIf="
                              allowedRoutes.length > 0 &&
                              allowedRoutes[0].rolId !== 'CMO'
                            "
                            class="hover-text"
                          >
                            {{ "common.chooseFile" | translate }}
                          </div>
                        </label>
                      </button>
                    </div>
                    <div *ngIf="imgPath && fAgreement2">
                      <div class="text-center pointer mt-2">
                        <span
                          (click)="
                            openModal(
                              fAgreement2Model,
                              this.translate.instant(
                                'clientCreditDoc.pledgeLetter'
                              )
                            )
                          "
                          >{{ "common.showImage" | translate }}
                          <i class="fa fa-angle-double-down"></i
                        ></span>
                      </div>
                    </div>
                    <div class="btnCenter">
                      <!-- <button class="btn infoBtn cancelBtn img-del-btn" *ngIf="fAgreement2"
                                                (click)="delete('fAgreement2')">Delete</button> -->
                    </div>
                  </div>
                  <br />
                  <div class="btnCenter">
                    <!-- <label>Finance&nbsp;Agreement&nbsp;2</label> -->
                    <label class="text-danger">{{
                      "clientCreditDoc.pledgeLetter" | translate
                    }}</label>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                  <div class="cnic_box dashboard-card dashboard-model">
                    <div class="dashes">
                      <button
                        class=""
                        (click)="
                          allowedRoutes.length > 0 &&
                            allowedRoutes[0].rolId !== 'CMO' &&
                            compressFile('facilityLetter')
                        "
                      >
                        <label class="hoverable cnic_box">
                          <div class="vertical-center" *ngIf="!facilityLetter">
                            <i class="fa fa-picture-o mb-3"></i>
                            <p>
                              {{
                                "clientCreditDoc.attachFacilityLetter"
                                  | translate
                              }}
                            </p>
                          </div>
                          <div
                            class="vertical-center-img"
                            *ngIf="facilityLetter"
                          >
                            <img [src]="facilityLetter ? facilityLetter : ''" />
                          </div>
                          <div
                            *ngIf="
                              allowedRoutes.length > 0 &&
                              allowedRoutes[0].rolId !== 'CMO'
                            "
                            class="hover-text"
                          >
                            {{ "common.chooseFile" | translate }}
                          </div>
                        </label>
                      </button>
                    </div>
                    <div *ngIf="imgPath && facilityLetter">
                      <div class="text-center pointer mt-2">
                        <span
                          (click)="
                            openModal(
                              facilityLetterModel,
                              this.translate.instant(
                                'clientCreditDoc.facilityLetter'
                              )
                            )
                          "
                          >{{ "common.showImage" | translate }}
                          <i class="fa fa-angle-double-down"></i
                        ></span>
                      </div>
                    </div>
                    <div class="btnCenter">
                      <!-- <button class="btn infoBtn cancelBtn img-del-btn" *ngIf="facilityLetter"
                                                (click)="delete('facilityLetter')">Delete</button> -->
                    </div>
                  </div>
                  <br />
                  <div class="btnCenter">
                    <label class="text-danger">{{
                      "clientCreditDoc.facilityLetter" | translate
                    }}</label>
                  </div>
                  <div class="btnCenter"></div>
                </div>
                <div
                  class="col-sm-6 col-md-6 col-lg-3 mb-3"
                  *ngIf="_sharedSvc.businessAppraisal_FS"
                >
                  <div class="cnic_box dashboard-card dashboard-model">
                    <div class="dashes">
                      <button
                        class=""
                        (click)="
                          allowedRoutes.length > 0 &&
                            allowedRoutes[0].rolId !== 'CMO' &&
                            compressFile('businessApprove')
                        "
                      >
                        <label class="hoverable cnic_box">
                          <div class="vertical-center" *ngIf="!businessApprove">
                            <i class="fa fa-picture-o mb-3"></i>
                            <p>
                              {{
                                "clientCreditDoc.attachBusinessProof"
                                  | translate
                              }}
                            </p>
                          </div>
                          <div
                            class="vertical-center-img"
                            *ngIf="businessApprove"
                          >
                            <img
                              [src]="businessApprove ? businessApprove : ''"
                            />
                          </div>
                          <div
                            *ngIf="
                              allowedRoutes.length > 0 &&
                              allowedRoutes[0].rolId !== 'CMO'
                            "
                            class="hover-text"
                          >
                            {{ "common.chooseFile" | translate }}
                          </div>
                        </label>
                      </button>
                    </div>
                    <div *ngIf="imgPath && businessApprove">
                      <div class="text-center pointer mt-2">
                        <span
                          (click)="
                            openModal(
                              businessApproveModel,
                              this.translate.instant(
                                'clientCreditDoc.businessProof'
                              )
                            )
                          "
                          >{{ "common.showImage" | translate }}
                          <i class="fa fa-angle-double-down"></i
                        ></span>
                      </div>
                    </div>
                    <div class="btnCenter">
                      <!-- <button class="btn infoBtn cancelBtn img-del-btn" *ngIf="businessApprove"
                                                (click)="delete('businessApprove')">Delete</button> -->
                    </div>
                  </div>
                  <br />
                  <div class="btnCenter">
                    <label>{{
                      "clientCreditDoc.businessProof" | translate
                    }}</label>
                  </div>
                  <div class="btnCenter"></div>
                </div>
                <div
                  class="col-sm-6 col-md-6 col-lg-3 mb-3"
                  *ngIf="_sharedSvc.EmployerInfo_FS"
                >
                  <div class="cnic_box dashboard-card dashboard-model">
                    <div class="dashes">
                      <button
                        class=""
                        (click)="
                          allowedRoutes.length > 0 &&
                            allowedRoutes[0].rolId !== 'CMO' &&
                            compressFile('salaryApprove')
                        "
                      >
                        <label class="hoverable cnic_box">
                          <div class="vertical-center" *ngIf="!salaryApprove">
                            <i class="fa fa-picture-o mb-3"></i>
                            <p>Attach&nbsp;Salary&nbsp;Proof</p>
                          </div>
                          <div
                            class="vertical-center-img"
                            *ngIf="salaryApprove"
                          >
                            <img [src]="salaryApprove ? salaryApprove : ''" />
                          </div>
                          <div
                            *ngIf="
                              allowedRoutes.length > 0 &&
                              allowedRoutes[0].rolId !== 'CMO'
                            "
                            class="hover-text"
                          >
                            {{ "common.chooseFile" | translate }}
                          </div>
                        </label>
                      </button>
                    </div>
                    <div *ngIf="imgPath && salaryApprove">
                      <div class="text-center pointer mt-2">
                        <span
                          (click)="
                            openModal(salaryApproveModel, 'Salary Approve')
                          "
                          >{{ "common.showImage" | translate }}
                          <i class="fa fa-angle-double-down"></i
                        ></span>
                      </div>
                    </div>
                    <div class="btnCenter">
                      <!-- <button class="btn infoBtn cancelBtn img-del-btn" *ngIf="salaryApprove"
                                                (click)="delete('salaryApprove')">Delete</button> -->
                    </div>
                  </div>
                  <br />
                  <div class="btnCenter">
                    <label>Salary&nbsp;Proof</label>
                  </div>
                  <div class="btnCenter"></div>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <div class="button_div">
                    <button class="btn login-button-class" (click)="continue()">
                      {{ "common.continue" | translate }}
                    </button>
                    <button
                      *ngIf="!_conditionsService.readOnlyView_Credit"
                      class="btn login-button-class secondaryBtn"
                      [class.pointerNone]="_sharedSvc.loading"
                      [disabled]="_sharedSvc.loading"
                      (click)="continue()"
                    >
                      {{ "common.save" | translate }}
                    </button>
                    <!-- <button class=" btn login-button-class secondaryBtn"
                                            [class.pointerNone]="_sharedSvc.loading" [disabled]="_sharedSvc.loading"
                                            (click)="continue()">Submit</button> -->
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <br />

        <!-- <div class="btnCenter">
                    <p>image size was: {{ imgSizeBefore }}</p>
                    <p>Now image size is: {{ imgSizeAfter }}</p>
                </div> -->
      </div>
    </div>
  </div>
</section>

<ng-template
  id="display-modal"
  #rUtilityBillModel
  let-c="close"
  let-d="dismiss"
>
  <div class="p-3 pb-0">
    <div class="modal-header">
      <h4>{{ modelHeading }}</h4>
      <div class="header_cross" (click)="d('Cross click')">
        <h2>&times;</h2>
      </div>
    </div>
    <div class="modal-body">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center credit_pic_siz">
            <img [src]="rUtilityBill ? rUtilityBill : ''" />
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template
  id="display-modal"
  #bUtilityBillModel
  let-c="close"
  let-d="dismiss"
>
  <div class="p-3 pb-0">
    <div class="modal-header">
      <h4>{{ modelHeading }}</h4>
      <div class="header_cross" (click)="d('Cross click')">
        <h2>&times;</h2>
      </div>
    </div>
    <div class="modal-body">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center">
            <h4>{{ modelHeading }}</h4>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="">
            <img [src]="bUtilityBill ? bUtilityBill : ''" />
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template id="display-modal" #fAgreement1Model let-c="close" let-d="dismiss">
  <div class="p-3 pb-0">
    <div class="modal-header">
      <h4>{{ modelHeading }}</h4>
      <div class="header_cross" (click)="d('Cross click')">
        <h2>&times;</h2>
      </div>
    </div>
    <div class="modal-body">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center credit_pic_siz">
            <img [src]="fAgreement1 ? fAgreement1 : ''" />
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template id="display-modal" #fAgreement2Model let-c="close" let-d="dismiss">
  <div class="p-3 pb-0">
    <div class="modal-header">
      <h4>{{ modelHeading }}</h4>
      <div class="header_cross" (click)="d('Cross click')">
        <h2>&times;</h2>
      </div>
    </div>
    <div class="modal-body">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center">
            <h4>{{ modelHeading }}</h4>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="">
            <img [src]="fAgreement2 ? fAgreement2 : ''" />
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template
  id="display-modal"
  #facilityLetterModel
  let-c="close"
  let-d="dismiss"
>
  <div class="p-3 pb-0">
    <div class="modal-header">
      <h4>{{ modelHeading }}</h4>
      <div class="header_cross" (click)="d('Cross click')">
        <h2>&times;</h2>
      </div>
    </div>
    <div class="modal-body">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center credit_pic_siz">
            <img [src]="facilityLetter ? facilityLetter : ''" />
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template
  id="display-modal"
  #businessApproveModel
  let-c="close"
  let-d="dismiss"
>
  <div class="p-3 pb-0">
    <div class="modal-header">
      <h4>{{ modelHeading }}</h4>
      <div class="header_cross" (click)="d('Cross click')">
        <h2>&times;</h2>
      </div>
    </div>
    <div class="modal-body">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center">
            <h4>{{ modelHeading }}</h4>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="">
            <img [src]="businessApprove ? businessApprove : ''" />
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template
  id="display-modal"
  #salaryApproveModel
  let-c="close"
  let-d="dismiss"
>
  <div class="p-3 pb-0">
    <div class="modal-header">
      <h4>{{ modelHeading }}</h4>
      <div class="header_cross" (click)="d('Cross click')">
        <h2>&times;</h2>
      </div>
    </div>
    <div class="modal-body">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center credit_pic_siz">
            <img [src]="salaryApprove ? salaryApprove : ''" />
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
