<section>
    <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="mt-3 loan-table">
                <div class="row mb-3">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="btnCenter otherOutstanding mb-3">
                            <h3>{{'clientOnboarding.outstandingTab.otherOutstanding' | translate}}</h3>

                        </div>
                        <div class="btnCenter otherOutstanding">
                            <h6>{{'common.clintName' | translate}}: <b>{{saveCnicData?.frstNm | titlecase}}</b> /
                                {{'common.clintId' | translate}}:
                                <b>{{saveCnicData?.clntId}}</b>
                            </h6>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-sm-6 col-md-6 col-lg-6">
                        <div class="d-flex">
                            <div>
                                <ng-container *ngIf="_sharedSvc.pageSize > 10">
                                    <span>{{'common.show' | translate}}</span>
                                    <input id="tabl" type="number" [value]="_otherOutstandingSvc.getAllOutstandingPG"
                                        readonly class="m-1 text-center" style="width: 45px;">
                                    <span>{{'common.entries' | translate}}</span>
                                </ng-container>
                            </div>
                            <div class="ms-2">
                                <app-tasdeeq-report></app-tasdeeq-report>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-6" *ngIf="!_conditionsService.readOnlyView_Client">
                        <div class="add-button"
                            (click)="openModal(otherOutstanding, this.translate.instant('clientOnboarding.outstandingTab.addOtherOutstanding'))">
                            <i class="fa fa-plus"></i>
                        </div>
                        <!-- <button class="btn infoBtn fcmBtn infoBtn_bg" [class.infoBtn_bg]="address"
                            (click)="fetchClientMFIB()">Fetch
                            Clint MFIB</button> -->
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <td>{{'clientOnboarding.outstandingTab.institute' | translate}}</td>
                                        <td>{{'clientOnboarding.outstandingTab.totalCreditAmount' |
                                            translate}}({{'common.rs' | translate}})</td>
                                        <td>{{'clientOnboarding.outstandingTab.purpose' | translate}}</td>
                                        <td>{{'clientOnboarding.outstandingTab.currentOutstandingAmount' |
                                            translate}}({{'common.rs' | translate}})</td>
                                        <td> {{'clientOnboarding.outstandingTab.completionDate' | translate}}</td>
                                        <td> {{'clientOnboarding.outstandingTab.includeInExpenses' | translate}}</td>
                                        <td class="text-center" *ngIf="!_conditionsService.readOnlyView_Client">
                                            {{'common.action' | translate}}
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let data of _otherOutstandingSvc.getAllOutstandingPG ;let i = index ;">
                                        <ng-container *ngIf="!data.delFlg">
                                            <ng-container *ngFor="let item of _picklistService.INSTITUTE_Data; ">
                                                <td *ngIf="item?.refCdSeq == data.instnNm">
                                                    {{item?.refCdDscr}}
                                                </td>
                                            </ng-container>
                                            <td>{{data?.totCrdtAmt | number : "1.0-0"}}</td>
                                            <td>{{data?.crdtPrps | titlecase}}</td>
                                            <td>{{data?.crntOutsdAmt | number : "1.0-0"}}</td>
                                            <td class="text-center">{{data?.cmplDt | date: 'dd-MM-yyyy'}}</td>
                                            <td class="text-center">
                                                <input id="mnthExpFlg" *ngIf="data.mnthExpFlg == true" type="checkbox"
                                                    checked class="pointerNone">
                                                <input id="mnthExpFlg1" *ngIf="data.mnthExpFlg != true" type="checkbox"
                                                    class="pointerNone">
                                                <!-- {{data.mnthExpFlg}} -->
                                            </td>
                                            <td class="text-center" *ngIf="!_conditionsService.readOnlyView_Client">
                                                <div class="action-inner">
                                                    <i class="fa fa-pencil"
                                                        (click)="Edit(otherOutstanding,data,'Update Other Outstanding', i)"></i>
                                                    <i class="fa fa-trash" (click)="DeletePopup(data, i)"></i>
                                                </div>
                                            </td>
                                        </ng-container>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="row g-0" *ngIf="_sharedSvc.pageSize > 10">
                                <div class="col-sm-12 col-md-12 col-lg-6">
                                    <span class="clr-9d9a9a">{{'common.showing' | translate}}
                                        {{_otherOutstandingSvc.getAllOutstandingPG?.length}} {{'common.of' | translate}}
                                        {{_sharedSvc.collectionSize}} {{'common.entries' | translate}}</span>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-6">
                                    <div class="float-right">
                                        <ngb-pagination [(page)]="_sharedSvc.page" [pageSize]="_sharedSvc.pageSize"
                                            [maxSize]="10" ngDefaultControl [collectionSize]="_sharedSvc.collectionSize"
                                            [rotate]="true" (pageChange)="_otherOutstandingSvc.pageChange()">
                                            <ng-template ngbPaginationPrevious>{{'common.prev' |
                                                translate}}</ng-template>
                                            <ng-template ngbPaginationNext>{{'common.next' | translate}}</ng-template>
                                        </ngb-pagination>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="button_div">
                            <button class="btn login-button-class" type="button" *ngIf="_sharedSvc.businessAppraisal_FS"
                                [disabled]="_conditionsService.savePartiallyOSData"
                                (click)="_sharedSvc.active = 5">{{'common.continue' | translate}}</button>
                            <button class="btn login-button-class" type="button"
                                *ngIf="!_sharedSvc.businessAppraisal_FS"
                                [disabled]="_conditionsService.savePartiallyOSData"
                                (click)="_sharedSvc.active = 6">{{'common.continue' | translate}}</button>
                            <button class=" btn login-button-class secondaryBtn" type="button"
                                *ngIf="!_conditionsService.readOnlyView_Client"
                                [disabled]="!_conditionsService.savePartiallyOSData"
                                (click)="onSubmit()">{{'common.save' | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<ng-template #otherOutstanding let-c="close" let-d="dismiss">
    <div class="modal-body" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
        [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
        <div class="p-3 pb-0">
            <div class="row mb-3">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="text-center">
                        <h4>{{modelHeading}}</h4>
                    </div>
                </div>
            </div>
            <form [formGroup]="outstandingForm">
                <div class="row mb-3">
                    <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
                        <span *ngIf="_picklistService.INSTITUTE_DataClone.length == 0" class="negativeValue">
                            No more Institute Name exist.
                        </span>
                        <div class="form-floating" *ngIf="_picklistService.INSTITUTE_DataClone.length != 0">
                            <select id="instnNm" class="form-control input-radius form-select "
                                formControlName="instnNm"
                                [class.is-invalid]="instnNm.invalid && (instnNm.dirty || instnNm.touched)"
                                [ngClass]="instnNm.invalid ? 'requiredInput' : ''">
                                <option *ngFor="let item of _picklistService.INSTITUTE_DataClone; let i = index ;"
                                    [value]=item?.refCdSeq>
                                    {{item.refCdDscr}}
                                </option>
                            </select>
                            <div *ngIf="instnNm.invalid && (instnNm.dirty || instnNm.touched)" class="invalid-feedback">
                                <div *ngIf="instnNm.errors?.['required']">
                                    {{'clientOnboarding.outstandingTab.instituteNameRequire' | translate}}
                                </div>
                                <div *ngIf="instnNm.errors?.['minlength']">
                                    This field must have at least 3
                                    characters.
                                </div>
                                <div *ngIf="instnNm.errors?.['maxlength']">
                                    This field must have at most 100
                                    characters.
                                </div>
                            </div>
                            <label for="instnNm">{{'clientOnboarding.outstandingTab.instituteName' | translate}}</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
                        <div class="form-floating">
                            <input id="totCrdtAmt" type="number" class="form-control input-radius "
                                formControlName="totCrdtAmt" min="1" max="500000" (input)="setValueToModel()"
                                [class.is-invalid]="totCrdtAmt.invalid && (totCrdtAmt.dirty || totCrdtAmt.touched)"
                                [ngClass]="totCrdtAmt.invalid ? 'requiredInput' : ''">
                            <div *ngIf="totCrdtAmt.invalid && (totCrdtAmt.dirty || totCrdtAmt.touched)"
                                class="invalid-feedback">
                                <div *ngIf="totCrdtAmt.errors?.['required']">
                                    {{'clientOnboarding.outstandingTab.totalCreditAmountRequire' | translate}}
                                </div>
                                <div *ngIf="totCrdtAmt.errors?.['min']">
                                    This field must have at least 1
                                    digit.
                                </div>
                                <div *ngIf="totCrdtAmt.errors?.['max']">
                                    This field must have at most 6
                                    digit.
                                </div>
                            </div>
                            <label for="totCrdtAmt"> {{'clientOnboarding.outstandingTab.totalCreditAmount' |
                                translate}}</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
                        <div class="form-floating">
                            <input id="crdtPrps" type="text" style="text-transform: capitalize;" autocomplete="off"
                                class="form-control input-radius " formControlName="crdtPrps" minlength="3"
                                maxLength="100"
                                [class.is-invalid]="crdtPrps.invalid && (crdtPrps.dirty || crdtPrps.touched)"
                                [ngClass]="crdtPrps.invalid ? 'requiredInput' : ''">
                            <div *ngIf="crdtPrps.invalid && (crdtPrps.dirty || crdtPrps.touched)"
                                class="invalid-feedback">
                                <div *ngIf="crdtPrps.errors?.['required']">
                                    {{'clientOnboarding.outstandingTab.purposeRequire' | translate}}
                                </div>
                                <div *ngIf="crdtPrps.errors?.['minlength']">
                                    This field must have at least 3
                                    characters.
                                </div>
                                <div *ngIf="crdtPrps.errors?.['maxlength']">
                                    This field must have at most 100
                                    characters.
                                </div>
                            </div>
                            <label for="crdtPrps"> {{'clientOnboarding.outstandingTab.purpose' | translate}}</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
                        <div class="form-floating">
                            <input id="crntOutsdAmt" type="number" class="form-control input-radius "
                                formControlName="crntOutsdAmt" min="1"
                                [max]="_otherOutstandingSvc.otherStandingModel.totCrdtAmt" (input)="setValueToModel()"
                                [ngClass]="crntOutsdAmt.invalid ? 'requiredInput' : ''"
                                [class.is-invalid]="crntOutsdAmt.invalid && (crntOutsdAmt.dirty || crntOutsdAmt.touched)">
                            <div *ngIf="crntOutsdAmt.invalid && (crntOutsdAmt.dirty || crntOutsdAmt.touched)"
                                class="invalid-feedback">
                                <div *ngIf="crntOutsdAmt.errors?.['required']">
                                    {{'clientOnboarding.outstandingTab.currentOutstandingAmountRequire' | translate}}
                                </div>
                                <div *ngIf="crntOutsdAmt.errors?.['min']">
                                    This field must have at least 1
                                    digit.
                                </div>
                                <div *ngIf="crntOutsdAmt.errors?.['max']">
                                    Value not greater then Total Credit Amount.
                                </div>
                            </div>
                            <label for="crntOutsdAmt"> {{'clientOnboarding.outstandingTab.currentOutstandingAmount' |
                                translate}}</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
                        <div class="form-floating">
                            <input id="cmplDt" class="form-control input-radius readonly-field" formControlName="cmplDt"
                                name="dp" ngbDatepicker #d4="ngbDatepicker" [minDate]="_sharedSvc.todayDate"
                                [class.is-invalid]="cmplDt.invalid && (cmplDt.dirty || cmplDt.touched)"
                                [ngClass]="cmplDt.invalid ? 'requiredInput' : ''" readonly />
                            <i class="fa fa-calendar nbgCalender" (click)="d4.toggle()"></i>
                            <div *ngIf="cmplDt.invalid && (cmplDt.dirty || cmplDt.touched)" class="invalid-feedback">
                                <div *ngIf="cmplDt.errors?.['required']">
                                    {{'clientOnboarding.outstandingTab.completionDateRequire' | translate}}
                                </div>
                            </div>
                            <label for="cmplDt"> {{'clientOnboarding.outstandingTab.completionDate' | translate}}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
                        <div class="ms-2">
                            <input id="mnthExpFlg" type="checkbox" class="form-check-input pointer"
                                class="checkbox-input" value="_otherOutstandingSvc.otherStandingModel.mnthExpFlg"
                                formControlName="mnthExpFlg">
                            <span>&nbsp;{{'clientOnboarding.outstandingTab.includeInExpenses' | translate}}</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="button_div">
                            <button class="btn login-button-class" [class.pointerNone]="_sharedSvc.loading"
                                [disabled]="_sharedSvc.loading" *ngIf="!_otherOutstandingSvc.edit" type="button"
                                (click)="saveData()">{{'common.save' | translate}}</button>
                            <button class="btn login-button-class" [class.pointerNone]="_sharedSvc.loading"
                                [disabled]="_sharedSvc.loading" *ngIf="_otherOutstandingSvc.edit" type="button"
                                (click)="onUpdate()">{{'common.update' | translate}}</button>
                            <button class="btn login-button-class cancelBtn" type="reset" (click)="this.dismissModal()"
                                type="reset">{{'common.cancel' | translate}}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>