import { Component, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SharedService } from "src/app/components/shared/service/shared.service";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
  NgForm,
} from "@angular/forms";

import { ClintOnboardingService } from "src/app/components/clint-onboarding/shared/service/clint-onboarding.service";
import { SetupService } from "../../shared/service/setup.service";
import { PicklistService } from "src/app/components/shared/pickList/picklist.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-district",
  templateUrl: "./district.component.html",
  styleUrls: ["./district.component.css"],
})
export class DistrictComponent implements OnInit {
  loadingIndicator: boolean = false;
  rows: any;

  page: number = 5;
  pageSize: number = 5;
  collectionSize: number = 5;

  loginForm!: FormGroup;
  submitted = false;
  // currentDate: any = new Date().toISOString().split("T")[0];
  onBoardingTablePG: any;
  maskedCNIC: string;
  singleRecord: any;
  isEdit: boolean = false;
  addDistFormdata: any;
  stateID: any;

  constructor(
    private modalService: NgbModal,
    public router: Router,
    public _sharedSvc: SharedService,
    public _clintOnboardingSvc: ClintOnboardingService,
    public _SetupService: SetupService,
    private formBuilder: FormBuilder,
    public _picklistService: PicklistService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    let stateID = sessionStorage.getItem("stateSeqID");
    this.stateID = stateID;
    this._SetupService.get_geo_dist_list(stateID);

    this.addDistFormdata = this.formBuilder.group({
      districtName: ["", Validators.required],
      districtStatusKey: ["", Validators.required],
      districtDescription: ["", Validators.required],
    });
    this._picklistService.SHROFF_STATUS();
    console.log("Test", this._picklistService.SHROFF_STATUS_Data);
  }

  get districtName() {
    return this.addDistFormdata.get("districtName");
  }
  get districtStatusKey() {
    return this.addDistFormdata.get("districtStatusKey");
  }
  get districtDescription() {
    return this.addDistFormdata.get("districtDescription");
  }

  submitdistrict(val) {
    
    if (this.addDistFormdata.invalid) {
      for (const control of Object.keys(this.addDistFormdata.controls)) {
        this.addDistFormdata.controls[control].markAsTouched();
      }
      return;
    }
    let data = {
      ...this.addDistFormdata.value,
      districtCode: "0",
      tenantKey: "0",
      stateSequence: Number(this.stateID),
      delFlg: 0,
    };
    if (this.isEdit) {
      data.stateSequence = this.singleRecord.stateSequence;
    }
    this._SetupService.setupGeoDistModel = data;
    this._SetupService.saveGeoDist().subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.success(
            this.translate.instant("toast.district") +
              (this.isEdit === true
                ? this.translate.instant("toast.updated")
                : this.translate.instant("toast.added"))
          );
          this._sharedSvc.hideSpinner3();
          this.modalService.dismissAll();
          this._SetupService.get_geo_dist_list(this.stateID);
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner3();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(
            ([key, value]: any, index: number) => {
              this._sharedSvc.error(value);
            }
          );
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    });
  }

  editDistrict(content: any, id: any, edit: any) {
    let list = this._SetupService.geoDistList;
    let record = list.find((x) => x.districtSequence === id);
    this.singleRecord = record;

    if (record) {
      let data = {
        districtName: record.districtName,
        districtStatusKey: record.districtStatusKey,
        districtDescription: record.districtDescription,
      };
      this.addDistFormdata.patchValue(data);
      this.openModal(content, edit);
    }
  }

  deleteRecord(id) {
    
    let list = this._SetupService.geoDistList;
    let record = list.find((x) => x.districtSequence === id);
    this.singleRecord = record;

    if (record) {
      let data = {
        ...record,
        delFlg: 1,
      };
      this._SetupService.setupGeoDistModel = data;
      this._SetupService.saveGeoDist().subscribe({
        next: (response) => {
          if (response) {
            this._sharedSvc.success(
              this.translate.instant("toast.districtDeleted")
            );
            this._sharedSvc.hideSpinner3();
            this.modalService.dismissAll();
            this._SetupService.get_geo_dist_list(this.stateID);
          }
        },
        error: (e) => {
          this._sharedSvc.hideSpinner3();
          if (e.status == 401) {
            this._sharedSvc.error(this._sharedSvc.authMsg);
            this._sharedSvc.logout();
            return;
          }
          if (e?.error) {
            Object.entries(e?.error).forEach(
              ([key, value]: any, index: number) => {
                this._sharedSvc.error(value);
              }
            );
          }
          if (e?.error?.errorMessage) {
            this._sharedSvc.error(e?.error?.error);
          }
        },
      });
    }
  }

  pageChange() {
    this.collectionSize = this._clintOnboardingSvc.onBoardingTable.length;
    this.onBoardingTablePG = this._clintOnboardingSvc.onBoardingTable.slice(
      (this.page - 1) * this.pageSize,
      (this.page - 1) * this.pageSize + this.pageSize
    );
    this.onBoardingTablePG;
  }

  openModal(content: any, edit: boolean) {
    localStorage.removeItem("selectedOrderData");
    this.isEdit = edit;
    this.modalService.open(content, {
      // backdrop: "static",
      // keyboard: false,
      windowClass: "popupWidth800",
      // backdropClass: 'light-blue-backdrop',
      centered: true,
    });
  }
  back() {
    this.router.navigate(["/setup-province"]);
  }

  next(districtSequence: any) {
    sessionStorage.setItem("districtSeqID", districtSequence);
    this.router.navigate(["/setup-tehseel"]);
  }
  submittDistrict() {
    this._SetupService.saveCity().subscribe((data) => {
      alert("district added");
      // console.log(data)
    });
  }
}
