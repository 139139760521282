

export const environment = {
    production: false,

    // config: {
    //     BASE_API_URLKL: 'http://65.109.51.132:8080', // used for token
    //     BASE_API_URL: 'http://65.109.51.132:9191',
    //     BASE_API_URL_pickList: 'http://65.109.51.132:9898',
    //     BASE_API_DSBMT_URL: 'http://65.109.51.132:9393',
    //     BASE_API_ADMIN_URL: 'http://65.109.51.132:9292',
    // },


    config: {
        BASE_API_URLKL: 'http://65.109.51.132:8080', // used for token
        BASE_API_URL: 'http://65.109.51.132:9191',
        BASE_API_URL_pickList: 'http://65.109.51.132:9898',
        BASE_API_DSBMT_URL: 'http://65.109.51.132:9393',
        BASE_API_Recovery_URL: 'http://65.109.51.132:9797',
        BASE_API_ADMIN_URL: 'http://65.109.51.132:9292',

    },
};

export const tasdRepDataString: any = {
    statusCode: "111",
    messageCode: "00922001",
    message: "Report generated successfully",
    data: {
        reportDate: "13 Aug 2020",
        reportTime: "15:26:38",
        refNo: "202008-2665020",
        personalInformation: {
            NAME: "",
            FATHER_OR_HUSBAND_NAME: "",
            GENDER: "",
            CNIC: "xxxxx-xxxxxxx-x",
            PASSPORT: "",
            DOB: "",
            NIC: "",
            BUSINESS_OR_PROFESSION: "",
            NATIONALITY: "",
            NTN: "",
            BORROWER_TYPE: "*",
            CURRENT_RESIDENTIAL_ADDRESS: "",
            CURRENT_RESIDENTIAL_ADDRESS_DATE: "",
            PERMANENT_ADDRESS: "",
            PERMANENT_ADDRESS_DATE: "",
            PREVIOUS_RESIDENTIAL_ADDRESS: "",
            PREVIOUS_RESIDENTIAL_ADDRESS_DATE: "",
            EMPLOYER_OR_BUSINESS: "",
            EMPLOYER_OR_BUSINESS_DATE: ""
        },
        noOfCreditEnquiry: 0,
        noOfActiveAccounts: 0,
        totalOutstandingBalance: "*",
        summaryOverdue_24M: [
            {
                PLUS_30_24M: "*",
                PLUS_60_24M: "*",
                PLUS_90_24M: "*",
                PLUS_120_24M: "*",
                PLUS_150_24M: "*",
                PLUS_180_24M: "*",
                MFI_DEFAULT: "*"
            }
        ],
        detailsOfStatusCreditApplication: [
            {
                PRODUCT: "*",
                FINANCIAL_INSTITUTION: "*",
                DATE_OF_APPLICATION: "*",
                AMOUNT_OF_FACILITY: "*",
                STATUS: "*"
            }
        ],
        detailsOfLoansSettlement: [
            {
                PRODUCT: "*",
                TOTAL_LIMIT: "*",
                APPROVAL_DATE: "*",
                RELATIONSHIP_DATE: "*",
                MATURITY_DATE: "*",
                DATE_OF_SETTLEMENT: "*"
            }
        ],
        personalGuarantees: [
            {
                PRODUCT: "*",
                PRINCIPAL_BORROWER_NAME: "*",
                PRINCIPAL_BORROWER_CNIC: "*",
                DATE_OF_INVOCATION: "*",
                GUARANTEE_DATE: "*",
                GUARANTEE_AMOUNT: "*"
            }
        ],
        coborrowerDetail: [
            {
                NAME: "*",
                CNIC: "*"
            }
        ],
        detailsOfBankruptcyCases: [
            {
                COURT_NAME: "*",
                DECLARATION_DATE: "*"
            }
        ],
        creditEnquiry: [
            {
                SR_NO: "*",
                FI_TYPE: "*",
                DATE_OF_ENQUIRY: "*"
            }
        ],
        loanDetails: [
            {
                LOAN_SERIAL_NUMBER: 0,
                PRODUCT: "*",
                BANK_NAME: "*",
                LOAN_ACCOUNT_STATUS: "",
                LOAN_LAST_PAYMENT_AMOUNT: "",
                LOAN_ID: "",
                LOAN_LIMIT: "*",
                LOAN_TYPE: "*",
                POSITION_AS_OF: "*",
                OUTSTANDING_BALANCE: "*",
                DATE_OF_LAST_PAYMENT_MADE: "*",
                REPAYMENT_FREQUENCY: "*",
                MINIMUM_AMOUNT_DUE: "*",
                FACILITY_DATE: "*",
                CLASSIFICATION_NATURE: "*",
                COLLATERAL_AMOUNT: "*",
                MATURITY_DATE: "*",
                CLASSIFICATION_TYPE: "*",
                LITIGATION_AMOUNT: "*",
                BOUNCED_REPAYMENT_CHEQUES: "*",
                RESTRUCTURING_DATE: "*",
                SECURED_UNSECURED: "*",
                SECURITY_COLLATERAL: "*",
                RESTRUCTURING_AMOUNT: "*",
                WRITEOFF_TYPE: "*",
                WRITE_OFF_AMOUNT: "*",
                WRITEOFF_DATE: "*",
                RECOVERY_AMOUNT: "*",
                RECOVERY_DATE: "*",
                PLUS_30: "*",
                PLUS_60: "*",
                PLUS_90: "*",
                PLUS_120: "*",
                PLUS_150: "*",
                PLUS_180: "*",
                MFI_DEFAULT: "*",
                LATE_PAYMENT_1TO15: "*",
                LATE_PAYMENT_16TO20: "*",
                LATE_PAYMENT_21TO29: "*",
                LATE_PAYMENT_30: "*"
            },
            {
                LOAN_SERIAL_NUMBER: 1,
                PRODUCT: "*",
                BANK_NAME: "*",
                LOAN_ACCOUNT_STATUS: "",
                LOAN_LAST_PAYMENT_AMOUNT: "",
                LOAN_ID: "",
                LOAN_LIMIT: "*",
                LOAN_TYPE: "*",
                POSITION_AS_OF: "*",
                OUTSTANDING_BALANCE: "*",
                DATE_OF_LAST_PAYMENT_MADE: "*",
                REPAYMENT_FREQUENCY: "*",
                MINIMUM_AMOUNT_DUE: "*",
                FACILITY_DATE: "*",
                CLASSIFICATION_NATURE: "*",
                COLLATERAL_AMOUNT: "*",
                MATURITY_DATE: "*",
                CLASSIFICATION_TYPE: "*",
                LITIGATION_AMOUNT: "*",
                BOUNCED_REPAYMENT_CHEQUES: "*",
                RESTRUCTURING_DATE: "*",
                SECURED_UNSECURED: "*",
                SECURITY_COLLATERAL: "*",
                RESTRUCTURING_AMOUNT: "*",
                WRITEOFF_TYPE: "*",
                WRITE_OFF_AMOUNT: "*",
                WRITEOFF_DATE: "*",
                RECOVERY_AMOUNT: "*",
                RECOVERY_DATE: "*",
                PLUS_30: "*",
                PLUS_60: "*",
                PLUS_90: "*",
                PLUS_120: "*",
                PLUS_150: "*",
                PLUS_180: "*",
                MFI_DEFAULT: "*",
                LATE_PAYMENT_1TO15: "*",
                LATE_PAYMENT_16TO20: "*",
                LATE_PAYMENT_21TO29: "*",
                LATE_PAYMENT_30: "*"
            }
        ],
        creditHistory: [
            {
                LOAN_SERIAL_NUMBER: 0,
                MONTH_NAME: "JAN 23",
                PLUS_30: "0",
                PLUS_60: "0",
                PLUS_90: "0",
                PLUS_120: "0",
                PLUS_150: "0",
                PLUS_180: "0",
                MFI_DEFAULT: "0",
                LATE_PMT_DAYS: "0"
            },
            {
                LOAN_SERIAL_NUMBER: 0,
                MONTH_NAME: "FEB 23",
                PLUS_30: "*",
                PLUS_60: "*",
                PLUS_90: "*",
                PLUS_120: "*",
                PLUS_150: "*",
                PLUS_180: "*",
                MFI_DEFAULT: "*",
                LATE_PMT_DAYS: "*"
            },
            {
                LOAN_SERIAL_NUMBER: 0,
                MONTH_NAME: "MAR 23",
                PLUS_30: "*",
                PLUS_60: "*",
                PLUS_90: "*",
                PLUS_120: "*",
                PLUS_150: "*",
                PLUS_180: "*",
                MFI_DEFAULT: "*",
                LATE_PMT_DAYS: "*"
            },
            {
                LOAN_SERIAL_NUMBER: 1,
                MONTH_NAME: "APR 23",
                PLUS_30: "*",
                PLUS_60: "*",
                PLUS_90: "*",
                PLUS_120: "*",
                PLUS_150: "*",
                PLUS_180: "*",
                MFI_DEFAULT: "*",
                LATE_PMT_DAYS: "*"
            },
            {
                LOAN_SERIAL_NUMBER: 1,
                MONTH_NAME: "MAY 23",
                PLUS_30: "*",
                PLUS_60: "*",
                PLUS_90: "*",
                PLUS_120: "*",
                PLUS_150: "*",
                PLUS_180: "*",
                MFI_DEFAULT: "*",
                LATE_PMT_DAYS: "*"
            },
            {
                LOAN_SERIAL_NUMBER: 1,
                MONTH_NAME: "JUN 23",
                PLUS_30: "*",
                PLUS_60: "*",
                PLUS_90: "*",
                PLUS_120: "*",
                PLUS_150: "*",
                PLUS_180: "*",
                MFI_DEFAULT: "*",
                LATE_PMT_DAYS: "*"
            },
        ],
        disclaimerText: "The information contained in this report has been compiled from da ta provided by financial institutions and does not represent the opinion of Aequitas Inform ation Services Limited with regards to the credit worthiness of the subject. As such, Aequitas Information Services Limited will not be liable for any loss or damage arising from the i nformation contained herein and does not warrant the completeness, timeliness or accura cy of any data. The information contained in this report is supplied on a confidential basis to you and shall not be disclosed to any other person.",
        remarks: "Testing...."
    }
}

