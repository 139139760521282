import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CookieService } from 'ngx-cookie-service';
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { environment } from "src/app/global";
import { SaveCollateralDTL, SaveCollateralHDR } from "../model/credit-collaterial";
import { SharedService } from "src/app/components/shared/service/shared.service";
import { DatePipe } from "@angular/common";
import { PicklistService } from "src/app/components/shared/pickList/picklist.service";
import { Endpoint } from "src/app/components/shared/serviceEndPoints";

@Injectable({
  providedIn: 'root'
})
export class CreditCollateralService {

  collateralModel = new SaveCollateralDTL();
  saveCollateralHDRModel = new SaveCollateralHDR();
  getAllCreditAppData: any;
  getAllCreditAppDataPG: any;
  getAllCollatrialDtl_data: any = []
  getAllCollatrialDtl_dataPg: any;
  edit: boolean = false;
  crdtApp: any;
  crdtAppData: any;
  totalGrossWghtExcl: any;
  totalGldQltyKrt: any;
  totalNetWeight: any;
  totalMrktByngRt: any;
  totalMarketVAl: any;
  collaterialTable: any;
  collaterialTablePG: any;
  getAllCollatrialDtl_dataModel: any = [];

  getLatestGoldRate: any = []
  GoldbagsList: any = []
  ShroffbagsList: any = []

  constructor(
    public router: Router,
    private _http: HttpClient,
    private _cookieSvc: CookieService,
    public _sharedSvc: SharedService,
    public _picklistService: PicklistService

  ) { }

  // APIS Start
  saveCollateral() {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL + Endpoint.creditCollaterial.save_collateral_dtl, this.collateralModel, this._sharedSvc.requestOptions);
  }

  saveCollateralHDR() {
    this.crdtApp = localStorage.getItem("crdtApp");
    this.crdtApp = JSON.parse(this.crdtApp);
    this.saveCollateralHDRModel.crdtAppSeq = this.crdtApp;
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL + Endpoint.creditCollaterial.save_collateral_hdr, this.saveCollateralHDRModel, this._sharedSvc.requestOptions);
  }

  getCollateralHDR(crdtAppSeq: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL + Endpoint.creditCollaterial.get_collateral_hdr + crdtAppSeq, this._sharedSvc.requestOptions);
  }
  getCollateralDTL(crdtCltrlDtlSeq: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL + Endpoint.creditCollaterial.get_collateral_dtl + crdtCltrlDtlSeq, this._sharedSvc.requestOptions);
  }
  getAllCollateralDTL(crdtCltrlHdrSeq: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL + Endpoint.creditCollaterial.get_all_collateral_dtl + crdtCltrlHdrSeq, this._sharedSvc.requestOptions);
  }
  callGetGoldRates() {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_ADMIN_URL + Endpoint.Admin.get_gold_rate_lst, this._sharedSvc.requestOptions);
  }
  callBagsGold(branchseq: any, type: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.creditCollaterial.get_all_bags_collateral + branchseq + '/' + type, this._sharedSvc.requestOptions);
  }
  // APIS End
  InItGetCollateralHDR(crdtAppSeq: any): void {
    this._sharedSvc.showSpinner();
    this.getCollateralHDR(crdtAppSeq).subscribe({
      next: (response: any) => {
        if (response) {
          
          this.saveCollateralHDRModel = response;
          this.saveCollateralHDRModel.shrffId = null;
          this.InItGetAllCollateralDTL(response.crdtCltrlHdrSeq);
          localStorage.setItem('crdtCltrlHdrSeq', response.crdtCltrlHdrSeq)
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }

  InItGetCollateralDTL(crdtCltrlDtlSeq: any): void {
    this._sharedSvc.showSpinner();
    this.getCollateralDTL(crdtCltrlDtlSeq).subscribe({
      next: (response) => {
        if (response) {
          this.collaterialTable = response;
          // this.pageChangeCol();
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }

  pageChangeCol() {
    this._sharedSvc.collectionSize = this.collaterialTable?.length;
    this.collaterialTablePG = this.collaterialTable.slice((this._sharedSvc.page - 1) * this._sharedSvc.pageSize, (this._sharedSvc.page - 1) * this._sharedSvc.pageSize + this._sharedSvc.pageSize);
    // this._sharedSvc.pageSize  = this.getAllOutstandingPG?.length;
  }

  InItGetAllCollateralDTL(crdtCltrlHdrSeq: any): void {

    this._sharedSvc.showSpinner();
    this.getAllCollateralDTL(crdtCltrlHdrSeq).subscribe({
      next: (response) => {
        if (response) {

          this.getAllCollatrialDtl_data = response;
          this.calculationTotalDis();
          // this.pageChange();
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }
  getAllGoldBags(branchseq: any, type: any): void {

    this._sharedSvc.showSpinner();
    this.callBagsGold(branchseq, type).subscribe({
      next: (response) => {
        if (response) {

          this.GoldbagsList = response;
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }
  getAllShroffBags(branchseq: any, type: any): void {

    this._sharedSvc.showSpinner();
    this.callBagsGold(branchseq, type).subscribe({
      next: (response) => {
        if (response) {

          this.ShroffbagsList = response;
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }

  calculationTotalDis() {
    
    this.totalGrossWghtExcl = 0;
    this.totalGldQltyKrt = 0;
    this.totalNetWeight = 0;
    this.totalMrktByngRt = 0;
    this.totalMarketVAl = 0;
    for (let i = 0; i < this.getAllCollatrialDtl_data.length; i++) {
      if (this.getAllCollatrialDtl_data[i].delFlg == false) {
        this.totalGrossWghtExcl = this.getAllCollatrialDtl_data[i].grossWghtExcl;
        this.totalGldQltyKrt = this.getAllCollatrialDtl_data[i].gldQltyKrt;
        let totalNetWeight = (this.totalGrossWghtExcl * this.totalGldQltyKrt) / 24;
        this.totalNetWeight = this.totalNetWeight + totalNetWeight;
        let b=this.getLatestGoldRate?.find(item =>item.goldRateSeq === parseInt(this.getAllCollatrialDtl_data[i].mrktByngRt))?.mrktRate
        let a = ((this.totalGrossWghtExcl * this.totalGldQltyKrt) / 24) * b;
        this.totalMarketVAl = this.totalMarketVAl + a;
      }
    }
    // this.totalMarketVAl = this.totalNetWeight * this.totalMrktByngRt;
    this.saveCollateralHDRModel.effctvCrdtAmnt = (this.totalMarketVAl / 100) * 90;
    this.saveCollateralHDRModel.effctvCrdtAmnt = Math.round(this.saveCollateralHDRModel.effctvCrdtAmnt);//this.saveCollateralHDRModel.effctvCrdtAmnt.toFixed(5);
    localStorage.setItem('effctvCrdtAmnt', JSON.stringify(this.saveCollateralHDRModel.effctvCrdtAmnt));
    this.crdtAppData = localStorage.getItem("creditData");
    this.crdtAppData = JSON.parse(this.crdtAppData);
    // this.saveCollateralHDRModel.crdtUpto = this.saveCollateralHDRModel.effctvCrdtAmnt*0.9;
    this.saveCollateralHDRModel.crdtUpto = this.crdtAppData?.aprvdAmount;
    this.saveCollateralHDRModel.crdtUpto = Math.round(this.saveCollateralHDRModel.crdtUpto);
    localStorage.setItem('crdtUpto', JSON.stringify(this.saveCollateralHDRModel.crdtUpto));
  }

  pageChange() {
    this._sharedSvc.collectionSize = this.getAllCollatrialDtl_data?.length;
    this.getAllCollatrialDtl_dataPg = this.getAllCollatrialDtl_data.slice((this._sharedSvc.page - 1) * this._sharedSvc.pageSize, (this._sharedSvc.page - 1) * this._sharedSvc.pageSize + this._sharedSvc.pageSize);
    // this._sharedSvc.pageSize  = this.getAllOutstandingPG?.length;
  }

  get_GoldRate_list() {
    this._sharedSvc.showSpinner()
    this.callGetGoldRates().subscribe(res => {
      this._sharedSvc.hideSpinner();
      this.getLatestGoldRate = res;
      console.log(res)
    })
  }

}
