
export class SaveEmployer {
        clntEmplDtlSeq: number
        clntSeq: number
        emplNm: string;
        dsgntn: string;
        phNum: string;
        emplWbstUrl: string;
        emplStsKey: number
        // crtdBy: string;
        strtDt: any;
        // endDt: any;
        cmpnyAddr: string;
        // crtdDt: any;
        // lastUpdBy: string;
        // lastUpdDt: any;
        delFlg: boolean;
        tntKey: number
}
