<section>
  <div class="sidebar" [class.sidebar-close]="!_sharedSvc.openSidebar">
    <app-sidebar></app-sidebar>
  </div>
  <div class="home-section">
    <div
      class="home-main"
      [class.sidebar-close_home-main]="!_sharedSvc.openSidebar"
    >
      <app-navbar></app-navbar>

      <div
        class="home-view"
        [dir]="
          translate?.currentLang === 'ar' || translate?.currentLang === 'ur'
            ? 'rtl'
            : ''
        "
        [class.fix-direction]="
          translate?.currentLang === 'ar' || translate?.currentLang === 'ur'
        "
      >
        <section>
          <div class="row mb-3">
            <div class="col-sm-12 col-md-12 col-lg-6">
              <h4>{{ "sideBarMenu.recovery" | translate }}</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="dashboard-card loan-table">
                <form [formGroup]="searchPaymentForm">
                  <div class="row">
                    <div class="col-sm-10 col-md-10 col-lg-10">
                      <div class="row">
                        <div class="col-sm-6 col-md-6 col-lg-4 mb-3">
                          <div class="form-floating">
                            <input
                              type="text"
                              style="text-transform: capitalize"
                              autocomplete="off"
                              formControlName="clntId"
                              class="form-control search-input"
                            />
                            <label>{{ "common.clientId" | translate }}:</label>
                          </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-4 mb-3">
                          <div class="form-floating">
                            <input
                              type="text"
                              style="text-transform: capitalize"
                              autocomplete="off"
                              formControlName="cnic"
                              class="form-control search-input"
                            />
                            <label>CNIC #:</label>
                          </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-4 mb-3">
                          <div class="form-floating">
                            <input
                              type="text"
                              style="text-transform: capitalize"
                              phoneMask="9999-9999999"
                              autocomplete="off"
                              formControlName="phone"
                              class="form-control search-input"
                            />
                            <label>{{ "common.phone" | translate }} #:</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-2 col-md-2 col-lg-2">
                      <div class="button_div rec_searchBtn">
                        <button
                          class="btn login-button-class secondaryBtn marginAuto"
                          (click)="searchPaymentSchedule()"
                        >
                          {{ "common.search" | translate }}
                          <span> <i class="fa fa-search"></i></span>
                        </button>
                      </div>
                    </div>
                    <!-- [value]="_recoveryService._recoverydata[0].clntName" -->
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-3">
                      <div class="form-floating">
                        <input
                          style="text-transform: capitalize"
                          autocomplete="off"
                          class="form-control search-input readonly-field"
                          value="{{ _recoverydata_0?.clntName }}"
                        />
                        <label>{{ "common.clientName" | translate }}:</label>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-8 mb-3">
                      <div class="form-floating">
                        <input
                          style="text-transform: capitalize"
                          autocomplete="off"
                          class="form-control search-input readonly-field"
                        />
                        <label>{{ "common.address" | translate }}:</label>
                      </div>
                    </div>
                  </div>
                </form>
                <div
                  class="row"
                  *ngIf="_recoveryService._recoverydata?.length > 0"
                >
                  <div class="col-sm-6 col-md-6 col-lg-6 marginAuto mb-3">
                    <h4>{{ "recoveryListing.listView" | translate }}</h4>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-6 mb-3">
                    <div class="dispersement-btn">
                      <button
                        class="btn infoBtn infoBtn_bg mt-0 me-2"
                        (click)="openModal(excessRecovery)"
                      >
                        {{ "recoveryListing.excessRecovery" | translate }}
                      </button>
                      <button
                        class="btn infoBtn infoBtn_bg mt-0 me-2"
                        (click)="openModal(applyPayment)"
                      >
                        {{ "recoveryListing.makePayment" | translate }}
                      </button>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="table-responsive">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <td>
                              {{ "recoveryListing.installmentNo" | translate }}
                            </td>
                            <td>
                              {{
                                "disbursementRepaymentSchedule.principalAmountDue"
                                  | translate
                              }}
                            </td>
                            <td>
                              {{ "recoveryListing.serviceCharges" | translate }}
                            </td>
                            <td>
                              {{ "recoveryListing.otherCharges" | translate }}
                            </td>
                            <td>
                              {{ "recoveryListing.dailyRate" | translate }}
                            </td>
                            <td>
                              {{ "recoveryListing.totalDue" | translate }}
                            </td>
                            <td>
                              {{
                                "disbursementRepaymentSchedule.dueDate"
                                  | translate
                              }}
                            </td>
                            <td>
                              {{ "recoveryListing.paidAmount" | translate }}
                            </td>
                            <td>
                              {{ "recoveryListing.paidDate" | translate }}
                            </td>
                            <td>
                              {{ "recoveryListing.paymentMethod" | translate }}
                            </td>
                            <td>
                              {{ "common.instrument" | translate }} #/
                              {{ "recoveryListing.TransId" | translate }}
                            </td>
                            <td>
                              {{
                                "recoveryListing.totalRemainingAmount"
                                  | translate
                              }}
                            </td>
                            <td>{{ "common.status" | translate }}</td>
                            <td>{{ "common.action" | translate }}</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="let rec of _recoveryService._recoverydata"
                          >
                            <td>{{ rec?.instNum }}</td>
                            <td>{{ rec?.prnAmt | number }}</td>
                            <td>{{ rec?.srvcChrgAmt | number }}</td>
                            <td>{{ rec?.othChrgAmt }}</td>
                            <td>
                              {{ rec?.srvcChrgAmt / 365 | number : "1.0-0" }}
                            </td>
                            <td>
                              {{
                                rec?.prnAmt + rec?.othChrgAmt + rec?.srvcChrgAmt
                                  | number : "1.0-0"
                              }}
                            </td>
                            <td>{{ rec?.dueDt}}</td>
                            <td>{{ rec?.paidAmount }}</td>
                            <td>{{ rec?.paidDate }}</td>
                            <td>{{ rec?.instituteName }}</td>
                            <td>{{ rec?.instrumentNumber | number }}</td>
                            <td>
                              {{
                                rec?.prnAmt +
                                  rec?.othChrgAmt +
                                  rec?.srvcChrgAmt -
                                  rec?.paidAmount | number : "1.0-0"
                              }}
                            </td>
                            <td>{{ rec?.instStatus }}</td>
                            <td class="text-center">
                              <div class="action-inner">
                                <i
                                  aria-hidden="true"
                                  class="fa fa-step-backward"
                                ></i>
                                <i class="fa fa-print"></i>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div
                  class="row mb-3"
                  *ngIf="_recoveryService._recoverydata?.length > 0"
                >
                  <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="button_div">
                      <button
                        class="btn login-button-class"
                        [class.pointerNone]="_sharedSvc.loading"
                        [disabled]="_sharedSvc.loading"
                      >
                        {{ "common.continue" | translate }}
                      </button>
                      <button class="btn login-button-class secondaryBtn">
                        {{ "common.save" | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>

<ng-template id="display-modal" #applyPayment let-c="close" let-d="dismiss">
  <div
    class="modal-body"
    [dir]="
      translate?.currentLang === 'ar' || translate?.currentLang === 'ur'
        ? 'rtl'
        : ''
    "
    [class.fix-direction]="
      translate?.currentLang === 'ar' || translate?.currentLang === 'ur'
    "
  >
    <div class="p-3 pb-0">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center">
            <h4>{{ "recoveryListing.applyPayment" | translate }}</h4>
          </div>
        </div>
      </div>
      <form [formGroup]="savePaymentTxnForm">
        <div class="row mb-3">
          <div class="col-sm-12 col-md-12 col-lg-3 mb-3">
            <div class="form-floating">
              <select
                class="form-control input-radius form-select"
                formControlName="pymtMode"
                [class.is-invalid]="
                  pymtMode.invalid && (pymtMode.dirty || pymtMode.touched)
                "
                [ngClass]="pymtMode.invalid ? 'requiredInput' : ''"
                (change)="paymentMod($event)"
              >
                <option
                  *ngFor="
                    let item of _picklistService.paymentTypeList_data;
                    let i = index
                  "
                  [value]="item?.typSeq"
                >
                  {{ item.typStr }}
                </option>
              </select>
              <div
                *ngIf="pymtMode.invalid && (pymtMode.dirty || pymtMode.touched)"
                class="invalid-feedback"
              >
                <div *ngIf="pymtMode.errors?.['required']">
                  {{ "common.paymentModeRequire" | translate }}
                </div>
              </div>
              <label>{{ "common.paymentMode" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-3 mb-3">
            <div class="form-floating">
              <input
                type="number"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius"
                formControlName="instrumentNumber"
                maxlength="15"
                [class.is-invalid]="
                  (instrumentNumber.invalid || instrumentNumber.errors?.['maxlength']) &&
                  (instrumentNumber.dirty || instrumentNumber.touched)
                "
              />
              <div
                *ngIf="
                  (instrumentNumber.invalid || instrumentNumber.errors?.['maxlength']) &&
                  (instrumentNumber.dirty || instrumentNumber.touched)
                "
                class="invalid-feedback"
              >
                <div *ngIf="instrumentNumber.errors?.['required']">
                  {{ "branchAccounting.instrumentNoRequire" | translate }}
                </div>
                <div *ngIf="instrumentNumber.errors?.['minlength']">
                  {{ "common.field1DigitRequire" | translate }}
                </div>
                <div *ngIf="instrumentNumber.errors?.['maxlength']">
                  <!-- {{ "common.fieldMustHaveAtMostFifteenKDigits" | translate }}  -->
                  This field must have at most 15 digits
                </div>
              </div>
              <label>{{ "branchAccounting.instrumentNo" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-3 mb-3">
            <div class="form-floating">
              <input
                type="date"
                class="form-control input-radius"
                formControlName="slipDate"
                (change)="onDateSelected($event)"
              />
              <label>Slip Date</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-3 mb-3">
            <div class="form-floating">
              <input
                type="number"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius"
                formControlName="paymentAmount"
                [class.is-invalid]="
                  paymentAmount.invalid &&
                  (paymentAmount.dirty || paymentAmount.touched)
                "
              />
              <div
                *ngIf="
                  paymentAmount.invalid &&
                  (paymentAmount.dirty || paymentAmount.touched)
                "
                class="invalid-feedback"
              >
                <div *ngIf="paymentAmount.errors?.['required']">
                  {{ "recoveryListing.paymentAmountRequire" | translate }}
                </div>
                <div *ngIf="paymentAmount.errors?.['min']">
                  {{ "common.field1DigitRequire" | translate }}
                </div>
                <div *ngIf="paymentAmount.errors?.['max']">
                  {{ "common.fiftyThousandChars" | translate }}
                </div>
              </div>
              <label>{{ "recoveryListing.paymentAmount" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
            <input type="checkbox" class="form-check-input pointer" />
            <span class="ms-2">{{ "common.post" | translate }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="button_div">
              <button
                class="btn login-button-class"
                [class.pointerNone]="_sharedSvc.loading"
                [disabled]="_sharedSvc.loading"
                (click)="savePaymentTXN()"
              >
                {{ "common.save" | translate }}
              </button>
              <button
                class="btn login-button-class cancelBtn"
                (click)="d('Cross click')"
                type="reset"
              >
                {{ "common.cancel" | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template id="display-modal" #excessRecovery let-c="close" let-d="dismiss">
  <div
    class="modal-body"
    [dir]="
      translate?.currentLang === 'ar' || translate?.currentLang === 'ur'
        ? 'rtl'
        : ''
    "
    [class.fix-direction]="
      translate?.currentLang === 'ar' || translate?.currentLang === 'ur'
    "
  >
    <div class="p-3 pb-0">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center">
            <h4>{{ "sideBarMenu.admin.excessRecovery" | translate }}</h4>
          </div>
        </div>
      </div>
      <form [formGroup]="savePaymentTxnForm">
        <div class="row mb-3">
          <div class="col-sm-12 col-md-12 col-lg-3 mb-3">
            <div class="form-floating">
              <select
                class="form-control input-radius form-select"
                formControlName="pymtMode"
                [class.is-invalid]="
                  pymtMode.invalid && (pymtMode.dirty || pymtMode.touched)
                "
                [ngClass]="pymtMode.invalid ? 'requiredInput' : ''"
                (change)="paymentMod($event)"
              >
                <option
                  *ngFor="
                    let item of _picklistService.paymentTypeList_data;
                    let i = index
                  "
                  [value]="item?.typSeq"
                >
                  {{ item.typStr }}
                </option>
              </select>
              <div
                *ngIf="pymtMode.invalid && (pymtMode.dirty || pymtMode.touched)"
                class="invalid-feedback"
              >
                <div *ngIf="pymtMode.errors?.['required']">
                  {{ "common.paymentModeRequire" | translate }}
                </div>
              </div>
              <label>{{ "common.paymentMode" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-3 mb-3">
            <div class="form-floating">
              <input
                type="number"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius"
                formControlName="instrumentNumber"
                maxlength="15"
                [class.is-invalid]="
                  instrumentNumber.invalid &&
                  (instrumentNumber.dirty || instrumentNumber.touched)
                "
              />
              <div
                *ngIf="
                  instrumentNumber.invalid &&
                  (instrumentNumber.dirty || instrumentNumber.touched)
                "
                class="invalid-feedback"
              >
                <div *ngIf="instrumentNumber.errors?.['required']">
                  {{ "branchAccounting.instrumentNoRequire" | translate }}
                </div>
                <div *ngIf="instrumentNumber.errors?.['minlength']">
                  {{ "common.field1DigitRequire" | translate }}
                </div>
                <div *ngIf="instrumentNumber.errors?.['maxlength']">
                  <!-- {{ "common.fieldMustHaveAtMostFifteenKDigits" | translate }} -->
                  This field must have at most 15 digits
                </div>
              </div>
              <label>{{ "branchAccounting.instrumentNo" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-3 mb-3">
            <div class="form-floating">
              <input
              type="date"
              class="form-control input-radius"
              formControlName="slipDate"
              (change)="onDateSelected($event)"
              />
              <label>{{ "recoveryListing.slipDate" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-3 mb-3">
            <div class="form-floating">
              <input
                type="number"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius"
                formControlName="paymentAmount"
                [class.is-invalid]="
                  paymentAmount.invalid &&
                  (paymentAmount.dirty || paymentAmount.touched)
                "
              />
              <div
                *ngIf="
                  paymentAmount.invalid &&
                  (paymentAmount.dirty || paymentAmount.touched)
                "
                class="invalid-feedback"
              >
                <div *ngIf="paymentAmount.errors?.['required']">
                  {{ "recoveryListing.paymentAmountRequire" | translate }}
                </div>
                <div *ngIf="paymentAmount.errors?.['min']">
                  {{ "common.field1DigitRequire" | translate }}
                </div>
                <!-- <div *ngIf="paymentAmount.errors?.['max']">
                  {{ "common.fiftyThousandChars" | translate }}
                </div> -->
              </div>
              <label>{{ "recoveryListing.paymentAmount" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
            <input type="checkbox" class="form-check-input pointer" />
            <span class="ms-2">{{ "common.post" | translate }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="button_div">
              <button
                class="btn login-button-class"
                [class.pointerNone]="_sharedSvc.loading"
                [disabled]="_sharedSvc.loading"
                (click)="saveEXCSPayment()"
              >
                {{ "common.save" | translate }}
              </button>
              <button
                class="btn login-button-class cancelBtn"
                (click)="d('Cross click')"
                type="reset"
              >
                {{ "common.cancel" | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
