<section class="p-3">
  <div class="row mb-3">
    <div class="col-sm-6 col-md-6 col-lg-6">
      <h4>{{ "basicInfoTab.basicInfoTitle" | translate }}</h4>
    </div>
  </div>
  <form [formGroup]="basicFormFormdata" #basicForm_Formdata="ngForm" (ngSubmit)="submitBasicForm(basicFormFormdata)">
    <div class="row">
      <div class="col-sm-12 col-lg-7">
        <div class="row">
          <div class="col-sm-12 col-lg-4">
            <div class="form-floating mb-3">
              <input type="text" style="text-transform: capitalize" autocomplete="off" class="form-control input-radius"
                formControlName="prdId" [class.is-invalid]="
                  prdId.invalid && (prdId.dirty || prdId.touched)
                " />
              <div *ngIf="prdId.invalid && (prdId.dirty || prdId.touched)" class="invalid-feedback">
                <div *ngIf="prdId.errors?.['required']">
                  {{ "common.productIdRequire" | translate }}
                </div>
              </div>
              <label>{{ "common.productId" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-lg-4">
            <div class="form-floating mb-3">
              <input type="text" style="text-transform: capitalize" autocomplete="off" class="form-control input-radius"
                formControlName="prdNm" [class.is-invalid]="
                  prdNm.invalid && (prdNm.dirty || prdNm.touched)
                " />
              <div *ngIf="prdNm.invalid && (prdNm.dirty || prdNm.touched)" class="invalid-feedback">
                <div *ngIf="prdNm.errors?.['required']">
                  {{ "common.productNameRequire" | translate }}
                </div>
              </div>
              <label>{{ "common.productName" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-lg-4 align-self-center">
            <div class="d-flex align-items-center justify-content-between mb-3">
              <div class="radio">
                <input id="irr"  type="radio" value="1" formControlName="irrFlg" />
                <label for="irr" class="radio-label h6">IRR</label>
              </div>
              <div class="radio">
                <input id="Flat"  type="radio" value="0" formControlName="irrFlg" />
                <label for="Flat" class="radio-label h6">{{
                  "basicInfoTab.flat" | translate
                  }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-lg-5">
        <div class="row">
          <div class="col-sm-12 col-lg-6">
            <div class="form-floating mb-3">
              <input type="text" style="text-transform: capitalize" autocomplete="off" class="form-control input-radius"
                formControlName="irrVal" [class.is-invalid]="
                  irrVal.invalid && (irrVal.dirty || irrVal.touched)
                " />
              <div *ngIf="irrVal.invalid && (irrVal.dirty || irrVal.touched)" class="invalid-feedback">
                <div *ngIf="irrVal.errors?.['required']">
                  IRR {{ "charges.valueRequire" | translate }}
                </div>
              </div>
              <label>IRR {{ "charges.value" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-lg-6 align-self-center">
            <div class="d-flex align-items-center justify-content-between mb-3 pl-lg-2">
              <div class="checkbox-wrapper custom-checkbox">
                <input type="checkbox" class="form-check-input pointer" formControlName="mltCrdtFlg" />
                <span class="h6">&nbsp;{{ "basicInfoTab.multiCredit" | translate }}</span>
              </div>
              <!-- <div class="checkbox-wrapper custom-checkbox">
                <input type="checkbox" class="form-check-input pointer" />
                <span class="h6"
                  >&nbsp;{{ "basicInfoTab.dailyArrural" | translate }}</span
                >
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-lg-7">
        <div class="row">
          <div class="col-lg-4">
            <div class="form-floating mb-3">
              <select class="form-control form-select input-radius" formControlName="prdTypKey" [class.is-invalid]="
                  prdTypKey.invalid && (prdTypKey.dirty || prdTypKey.touched)
                ">
                <option hidden value=""></option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
              </select>
              <div *ngIf="
                  prdTypKey.invalid && (prdTypKey.dirty || prdTypKey.touched)
                " class="invalid-feedback">
                <div *ngIf="prdTypKey.errors?.['required']">
                  {{ "common.productType" | translate }}
                </div>
              </div>
              <label> {{ "common.productType" | translate }}</label>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="form-floating mb-3">
              <input type="text" style="text-transform: capitalize" autocomplete="off" class="form-control input-radius"
                formControlName="rndngScl" [class.is-invalid]="
                  rndngScl.invalid && (rndngScl.dirty || rndngScl.touched)
                " />
              <div *ngIf="rndngScl.invalid && (rndngScl.dirty || rndngScl.touched)" class="invalid-feedback">
                <div *ngIf="rndngScl.errors?.['required']">
                  {{ "basicInfoTab.roundingScaleRequire" | translate }}
                </div>
              </div>
              <label>{{ "basicInfoTab.roundingScale" | translate }}</label>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="form-floating mb-3">
              <input type="text" style="text-transform: capitalize" autocomplete="off" class="form-control input-radius"
                formControlName="rndngAdj" [class.is-invalid]="
                  rndngAdj.invalid && (rndngAdj.dirty || rndngAdj.touched)
                " />
              <div *ngIf="rndngAdj.invalid && (rndngAdj.dirty || rndngAdj.touched)" class="invalid-feedback">
                <div *ngIf="rndngAdj.errors?.['required']">
                  {{ "basicInfoTab.roundingAdjustmentRequire" | translate }}
                </div>
              </div>
              <label>{{ "basicInfoTab.roundingAdjustment" | translate }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-lg-5">
        <div class="row">
          <div class="col-lg-6">
            <div class="form-floating mb-3">
              <input type="text" style="text-transform: capitalize" autocomplete="off" class="form-control input-radius"
                formControlName="pdcNum" [class.is-invalid]="
                  pdcNum.invalid && (pdcNum.dirty || pdcNum.touched)
                " />
              <div *ngIf="pdcNum.invalid && (pdcNum.dirty || pdcNum.touched)" class="invalid-feedback">
                <div *ngIf="pdcNum.errors?.['required']">
                  {{ "basicInfoTab.numberOfPDCRequire" | translate }}
                </div>
              </div>
              <label>{{ "basicInfoTab.numberOfPDC" | translate }}</label>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-floating mb-3">
              <select class="form-control form-select input-radius" formControlName="prdSts" [class.is-invalid]="
                  prdSts.invalid && (prdSts.dirty || prdSts.touched)
                ">
                <option hidden value=""></option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
              </select>
              <div *ngIf="prdSts.invalid && (prdSts.dirty || prdSts.touched)" class="invalid-feedback">
                <div *ngIf="prdSts.errors?.['required']">
                  {{ "common.productTypeRequire" | translate }}
                </div>
              </div>
              <label>{{ "common.status" | translate }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-floating mb-3">
          <input type="text" style="text-transform: capitalize" autocomplete="off" class="form-control input-radius"
            formControlName="prdCmnt" [class.is-invalid]="
              prdCmnt.invalid && (prdCmnt.dirty || prdCmnt.touched)
            " />
          <div *ngIf="prdCmnt.invalid && (prdCmnt.dirty || prdCmnt.touched)" class="invalid-feedback">
            <div *ngIf="prdCmnt.errors?.['required']">
              {{ "common.commentsRequire" | translate }}
            </div>
          </div>
          <label>{{ "common.comments" | translate }}</label>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="button_div mt-3">
          <button class="btn login-button-class" type="button" (click)="moveToNextTab()">
            {{ "common.continue" | translate }}
          </button>
          <button class="btn login-button-class secondaryBtn" [disabled]="_sharedSvc.loading"
            [class.pointerNone]="_sharedSvc.loading">
            {{ "common.save" | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
</section>