import { Component } from '@angular/core';
import { SetupService } from '../../../shared/service/setup.service';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'src/app/components/shared/service/shared.service';

@Component({
  selector: 'app-business-sector-product',
  templateUrl: './business-sector-product.component.html',
  styleUrls: ['./business-sector-product.component.css']
})
export class BusinessSectorProductComponent {

  constructor(
    public _sharedSvc: SharedService,
    private _SetupService: SetupService,
    private nav: NgbNav,
  ) { }

  submittAssignmentForm() {
    this._SetupService.saveRefCdGroup().subscribe(data => {
      alert('a.f submitted')
    })
  }

  moveToNextTab() {
    if (this.nav) {
      this._sharedSvc.showSpinner3();
      const currentTab = this.nav.activeId || 1;
      const nextTab = currentTab < this.nav.items.length ? currentTab + 1 : 1;
      this.nav.select(nextTab);
      this._sharedSvc.hideSpinner3();
    }
  }

}
