import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from '../../shared/service/shared.service';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';

import { ClintOnboardingService } from '../../clint-onboarding/shared/service/clint-onboarding.service';
import { DisbursementFormService } from './shared/service/disbursement-form.service';
import { DisbustmentCredit } from './shared/model/disbust-credit';
import { DisbursementVoucher } from './shared/model/disbust-vocher';
import { DisbustDetail } from './shared/model/disbustDetail';
import { DisbustmentSchedule } from './shared/model/disbust-schedule';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'app-disbursement-form',
  templateUrl: './disbursement-form.component.html',
  styleUrls: ['./disbursement-form.component.css']
})
export class DisbursementFormComponent implements OnInit {


  Print: boolean;
  page: number = 5;
  pageSize: number = 5;
  collectionSize: number = 5;
  // currentDate: any = new Date().toISOString().split("T")[0];
  onBoardingTablePG: any;
  disbustment: DisbustDetail;
  disbustmentVocher: DisbursementVoucher[];
  schedulePayment: DisbustmentSchedule[];
  total = 0;
  currentTab: number;
  userId: string;
  user: any;
  constructor(
    private modalService: NgbModal,
    public router: Router,
    public _sharedSvc: SharedService,
    public _clintOnboardingSvc: ClintOnboardingService,
    private dismtService: DisbursementFormService,
    private formBuilder: FormBuilder,
    public translate: TranslateService,
  ) {
    this.disbustmentVocher = this.dismtService.disbustmentVocher;
    this.schedulePayment = this.dismtService.schedulePayment;
    let c = localStorage.getItem('disbustment');
    if (c)
      this.dismtService.disbustment = JSON.parse(c);
    this.disbustment = this.dismtService.disbustment;
    this.currentTab = this.dismtService.currentTab;


    let jwt = sessionStorage.getItem("JWT");
    this.user = JSON.parse(jwt);
    this.userId = this.user.preferred_username;
  }

  ngOnInit(): void {
  }

  pageChange() {
    this.collectionSize = this._clintOnboardingSvc.onBoardingTable.length;
    this.onBoardingTablePG = this._clintOnboardingSvc.onBoardingTable.slice(
      (this.page - 1) * this.pageSize,
      (this.page - 1) * this.pageSize + this.pageSize
    );
    this.onBoardingTablePG;
  }

  navigate() {
    this.router.navigate(['/disbursement-voucher']);
  }


  continueButton(): boolean {
    return this.dismtService.disContinueButton;
  }
  saveButton(): boolean {
    return this.dismtService.saveButton;
  }

  changeTab() {
    switch (this.currentTab) {
      case 1: this.currentTab = 2; break;
      case 2: this.currentTab = 3; break;
      case 3: this.currentTab = 4; break;
      default: this.currentTab = 1;
    }
  }

  vocherValidated() { return !this.dismtService.vocherValidated }

  async saveVouchers() {
    let body = this.disbustmentVocher.filter(v => v.changed == true)

    if (body) { await this.saveitems(body); }


  }

  async saveitems(body) {
    for (let it = 0; it < body.length; it++) {
      // body[it].delFlg=false;
      body[it].crtdBy = this.userId;
      body[it].crtdDt = new Date().toISOString();
      body[it].firstInstDate = new Date().toISOString();
      body[it].lastUpdBy = this.userId;
      body[it].lastUpdDt = new Date().toISOString();
      body[it].dsbmtStsKey = 0;
      body[it].dsbmtDt = new Date().toISOString();
      body[it].crdtAppSeq = this.disbustment.crdtAppSeq; body[it].dsbmtSeq = 0; body[it].dsbmtId = 0; body[it].vchrTyp = 1;
      body[it].instrNum = body[it].instrNum.replaceAll("-", "");
      await this.dismtService.saveDsbmt(body[it], (res) => {
        if (res) {
          body[it].dsbmtHdrSeq = res.dsbmtSeq;
          if (it == body.length - 1) {
            this.callingSavaAllDisburstment(body)
          }
        }
      });
    }
  }

  async callingSavaAllDisburstment(body) {
    this.dismtService.saveAllDsbmt(
      body, (res) => {
        if (res) { this.dismtService.saveButton = true; }
      }
    )
  }


  warningModal(content: any) {
    if (!this.disbustmentVocher.filter(v => v.changed == true).length) this.router.navigate(['/disbursement-listing']);
    else
      this.modalService.open(content, { windowClass: "popupWidth800", centered: true });
  }

  discard() {
    this.router.navigate(['/disbursement-listing']);
    this.modalService.dismissAll('Cross click');
  }
}