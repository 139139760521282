import { Component, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SharedService } from "../../shared/service/shared.service";
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from "@angular/router";
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators, NgForm } from "@angular/forms";
import { EmployerInfoService } from "./shared/service/employer-info.service";
import { SharedPopupService } from "../../shared/service/shared-popup.service";
import { FiniancialInfoService } from "../financial-info/shared/service/finiancial-info.service";
import { PicklistService } from "../../shared/pickList/picklist.service";
import { SaveClntExpnsDtl, SaveClntIncmDtl } from "../financial-info/shared/model/financial-info";
import { SaveEmployer } from "./shared/model/employer";
import { RoutingConditionsService } from "../shared/service/routing-conditions.service";
import { TranslateService } from "@ngx-translate/core";

// interface IEmployerInfo {
//   clntEmplDtlSeq: number
//   clntSeq: number
//   emplNm: string;
//   dsgntn: string;
//   phNum: string;
//   emplWbstUrl: string;
//   emplStsKey: number
//   // crtdBy: string;
//   strtDt: string;
//   endDt: string;
//   cmpnyAddr: string;
//   // crtdDt: any;
//   // lastUpdBy: string;
//   // lastUpdDt: any;
//   delFlg: boolean;
//   tntKey: number
// }
// interface ISalaryDetail {
//   incmDtlSeq: number;
//   clntSeq: number;
//   incmCtgryKey: number;
//   incmAmt: number;
//   incmTypKey: number;
//   qnty: number;
//   frqncy: number;
//   avgMnthlyIncm: any;
//   cmnt: string;
//   delFlg: boolean;
// }

@Component({
  selector: "app-employer-info",
  templateUrl: "./employer-info.component.html",
  styleUrls: ["./employer-info.component.css"],
})
export class EmployerInfoComponent implements OnInit {
  employerForm: FormGroup;
  // employer: IEmployerInfo;
  salaryDetailForm: FormGroup;
  // salaryDtl: ISalaryDetail;
  employerInfo: boolean = true;
  salaryDetail: boolean = false;
  saveCnicData: any;
  modelHeading: any;
  averageMonthlyAmount: any = null;
  endDate: boolean = false;
  jwt: any
  index: number = 0

  constructor(
    public router: Router,
    public _sharedSvc: SharedService,
    public _employerInfoSvc: EmployerInfoService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    public _sharedPopupSvc: SharedPopupService,
    public _finiancialInfoSvc: FiniancialInfoService,
    public _picklistService: PicklistService,
    public route: ActivatedRoute,
    public _conditionsService: RoutingConditionsService,
    public translate: TranslateService

  ) {
    // this.employer = {} as IEmployerInfo;
    // this.salaryDtl = {} as ISalaryDetail;
  }

  ngOnInit(): void {
    if (localStorage.getItem("readOnlyView_Client")) {
      this._conditionsService.readOnlyView_Client = true;
    }
    else {
      this._conditionsService.readOnlyView_Client = false;
    }
    if (localStorage.getItem('save_ClientModel')) {
      this.saveCnicData = localStorage.getItem('save_ClientModel');
    }
    if (localStorage.getItem('SaveCnicData')) {
      this.saveCnicData = localStorage.getItem('SaveCnicData');
    }
    this.saveCnicData = JSON.parse(this.saveCnicData);
    this.validationForm();
    this.pickListFn();
    this._employerInfoSvc.InItGetAllEmployerDetail(this.saveCnicData.clntSeq);
  }

  pickListFn() {
    this._picklistService.SALARY_TYPE();
    this._picklistService.BUSINESS_FREQUENCY();
    this._picklistService.WORK_STATUS();
  }

  openModal(content: any, h4: any) {
    if (this._conditionsService.employerInfoData) {
      this._sharedSvc.error(this.translate.instant('toast.saveRecord'));
      return;
    }
    this.validationForm();
    this.modelHeading = h4;
    this._employerInfoSvc.edit = false;
    this._sharedSvc.msg = "Record added";
    // this._finiancialInfoSvc.saveClntIncmDtlModel.avgMnthlyIncm = null;
    // this.salaryDtl = new SaveClntIncmDtl();
    // this._finiancialInfoSvc.saveClntIncmDtlModel = new SaveClntIncmDtl();
    // this.employer = new SaveEmployer();
    // this._employerInfoSvc.saveEmployerModel = new SaveEmployer();
    this.setSalaryDetailForm();
    this.setEmpInfoForm();
    this.modalService.open(content, {
      windowClass: "popupWidth1k",
      backdropClass: "light-blue-backdrop",
      centered: true,
    });
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  showBtn(B1: any, B2: any) {
    this.employerInfo = B1;
    this.salaryDetail = B2;

    if (B1) {
      this.validationForm();
      // this.employerForm?.reset(this.employerForm.value);
      // this._employerInfoSvc.saveEmployerModel = new SaveEmployer();
      this._employerInfoSvc.InItGetAllEmployerDetail(this.saveCnicData.clntSeq);
    }
    if (B2) {
      this.validationForm();
      // this.salaryDetailForm?.reset(this.salaryDetailForm.value);
      // this._finiancialInfoSvc.saveClntIncmDtlModel = new SaveClntIncmDtl();
      this._finiancialInfoSvc.InItGetClntAllIncmDtlSeq(this.saveCnicData.clntSeq, 4);
    }
  }

  numberOnly(event: any): boolean {
    let ph = this.phNum.value;
    if (ph && ph.length == 1 && ph.substring(0) != '0')
      this.phNum.setValue("0");
    if (ph && ph.length == 2 && ph.substring(0, 1) != '03')
      this.phNum.setValue("03");

    return true;
  }

  checkStatus(event: any) {

    if (event.target.value) {
      this._employerInfoSvc.saveEmployerModel.emplStsKey = event.target.value;
    }
    if (this._employerInfoSvc.saveEmployerModel.emplStsKey == 2040) {
      // this._employerInfoSvc.saveEmployerModel.endDt = null;
      // this.endDt.setValue(this._employerInfoSvc.saveEmployerModel.endDt);
      this.endDate = true;
    } else {
      this.endDate = false;
    }
  }
  setEmpInfoForm() {
    this.jwt = sessionStorage.getItem('JWT');
    this.jwt = JSON.parse(this.jwt);
    let date = new Date();
    this._employerInfoSvc.saveEmployerModel = {
      clntEmplDtlSeq: 0,
      clntSeq: this.saveCnicData.clntSeq,
      emplNm: null,
      dsgntn: null,
      phNum: null,
      emplWbstUrl: null,
      emplStsKey: null,
      // crtdBy: this.jwt?.preferred_username,
      strtDt: null,
      // endDt: null,
      cmpnyAddr: null,
      // crtdDt: date,
      // lastUpdBy: this.jwt?.preferred_username,
      // lastUpdDt: date,
      delFlg: false,
      tntKey: 0
    };
  }
  editEmpDet(content: any, data: any, h4: any, index: any) {

    if (this._conditionsService.employerInfoData) {
      this._sharedSvc.error(this.translate.instant('toast.saveRecord'));
      return;
    }
    this.index = index;
    this.modelHeading = h4;
    this._employerInfoSvc.edit = true;
    this._sharedSvc.msg = this.translate.instant('toast.recordUpdated');
    this.modalService.open(content, {
      windowClass: "popupWidth1k",
      backdropClass: "light-blue-backdrop",
      centered: true,
    });
    this._employerInfoSvc.saveEmployerModel = data;
    this.setValueToForm1();
    // this._conditionsService.employerInfoData = true;
    // this._conditionsService.employerInfoDataFn();

    // let tempDepositDate = new Date(this._otherOutstandingSvc.otherStandingModel.cmplDt);
    // this._otherOutstandingSvc.otherStandingModel.cmplDt = { year: tempDepositDate.getFullYear(), month: tempDepositDate.getMonth() + 1, day: tempDepositDate.getDate() };


  }
  onUpdateEmp() {
    this.setValueToModel1();
    this._employerInfoSvc.getAllEmployerPG[this.index] = this._employerInfoSvc.saveEmployerModel;
    // this._conditionsService.employerInfoData = true;
    // this._conditionsService.employerInfoDataFn();
    this.saveEmployerDetail();
    this.dismissModal();

  }
  DeletePopup_EmpDtl(data: any, index: any) {
    if (this._conditionsService.employerInfoData) {
      this._sharedSvc.error(this.translate.instant('toast.saveRecord'));
      return;
    }
    this._sharedSvc.msg = this.translate.instant('toast.recordDeleted');
    this._sharedPopupSvc
      .popup("", this.translate.instant('common.areSelectRecordDelete'), "", this.translate.instant('common.delete'), this.translate.instant('common.cancelSmall'), null, this.translate.instant('common.employerName') + " : " + data?.emplNm)
      .then((confirmed: any) => {
        if (confirmed) {
          // Delete function call

          this._sharedSvc.msg = "Record Deleted";
          this._employerInfoSvc.saveEmployerModel = data;
          this._employerInfoSvc.saveEmployerModel.delFlg = true;
          // this._employerInfoSvc.getAllEmployerPG[index].delFlg = true;
          // this._employerInfoSvc.getAllEmployerPG.splice(index, 1);
          // this._employerInfoSvc.getAllEmployer = this._employerInfoSvc.getAllEmployerPG;
          // this._conditionsService.employerInfoData = true;
          // this._conditionsService.employerInfoDataFn();
          this.saveEmployerDetail();

          // console.log("Delete Success");
        } else {
          //in case of cancel
          return;
        }
      })
      .catch(() => {
        // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)');
        return;
      });
  }
  onSubmitEmp(): void {
    this.setValueToModel1();
    if (this.employerForm.invalid) {
      for (const control of Object.keys(this.employerForm.controls)) {
        this.employerForm.controls[control].markAsTouched();
      }
      return;
    }
    this._employerInfoSvc.saveEmployerModel.phNum = this._employerInfoSvc.saveEmployerModel.phNum.replace(/\D/g, "");

    this._employerInfoSvc.saveEmployerModelObj.push(this._employerInfoSvc.saveEmployerModel);
    this._conditionsService.employerInfoData = true;
    this._conditionsService.employerInfoDataFn();

    this._employerInfoSvc.getAllEmployer.push(this._employerInfoSvc.saveEmployerModel);
    this._sharedSvc.collectionSize = this._employerInfoSvc.getAllEmployer?.length;
    this._employerInfoSvc.getAllEmployerPG = this._employerInfoSvc.getAllEmployer.slice((this._sharedSvc.page - 1) * this._sharedSvc.pageSize, (this._sharedSvc.page - 1) * this._sharedSvc.pageSize + this._sharedSvc.pageSize);
    localStorage.setItem("EmloyerInfo", JSON.stringify(this._employerInfoSvc.getAllEmployerPG));

    this.modalService.dismissAll();
    // this._sharedSvc.success(this._sharedSvc.msg);
    this.saveEmployerDetail();
  }
  saveEmployerDetail() {
    this._employerInfoSvc.saveEmployerModel.strtDt = new Date(this._employerInfoSvc.saveEmployerModel.strtDt);
    //end date set as start date
    // this._employerInfoSvc.saveEmployerModel.endDt = new Date(this._employerInfoSvc.saveEmployerModel.strtDt);
    this._employerInfoSvc.saveEmployerModel //= this._employerInfoSvc.getAllEmployerPG;
    this._sharedSvc.showSpinner();
    this._employerInfoSvc.saveEmployerDetail().subscribe({
      next: response => {
        if (response) {
          this._sharedSvc.hideSpinner();
          // this._employerInfoSvc.getAllEmployerPG = response;
          this._conditionsService.employerInfoData = false;
          this._conditionsService.employerInfoDataFn();

          this._employerInfoSvc.saveEmployerModelObj = [];
          localStorage.removeItem('saveEmployerModelObj');
          this._sharedSvc.success(this._sharedSvc.msg);
        }
      },
      error: e => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    });
  }

  // Salary Detail

  calAvgAmount(event: any, name: any) {
    if (name == 'frqncy') {
      this._finiancialInfoSvc.saveClntIncmDtlModel.frqncy = this.frqncy.value;
    }
    if (name == 'qnty') {
      this._finiancialInfoSvc.saveClntIncmDtlModel.qnty = this.qnty.value;
    }
    if (name == 'incmAmt') {
      this._finiancialInfoSvc.saveClntIncmDtlModel.incmAmt = this.incmAmt.value;
    }
    this.setValueToModel2();
    if (
      this._finiancialInfoSvc.saveClntIncmDtlModel.frqncy &&
      this._finiancialInfoSvc.saveClntIncmDtlModel?.qnty &&
      this._finiancialInfoSvc.saveClntIncmDtlModel?.incmAmt
    ) {
      let frequency = this._finiancialInfoSvc.saveClntIncmDtlModel.frqncy;
      let qty = this._finiancialInfoSvc.saveClntIncmDtlModel?.qnty;
      let amount = this._finiancialInfoSvc.saveClntIncmDtlModel?.incmAmt;
      for (let i = 0; i < this._picklistService.BUSINESS_FREQUENCY_Data.length; i++) {
        if (frequency == 2000) {
          this._finiancialInfoSvc.saveClntIncmDtlModel.avgMnthlyIncm = (amount * qty) / 1;
        } else if (frequency == 2001) {
          this._finiancialInfoSvc.saveClntIncmDtlModel.avgMnthlyIncm = (amount * qty) / 3;
        } else if (frequency == 2002) {
          this._finiancialInfoSvc.saveClntIncmDtlModel.avgMnthlyIncm = (amount * qty) / 6;
        } else if (frequency == 2003) {
          this._finiancialInfoSvc.saveClntIncmDtlModel.avgMnthlyIncm = (amount * qty) / 12;
        }
        // this._finiancialInfoSvc.saveClntIncmDtlModel.avgMnthlyIncm =  this._finiancialInfoSvc.saveClntIncmDtlModel.avgMnthlyIncm.toFixed(2);
        this._finiancialInfoSvc.saveClntIncmDtlModel.avgMnthlyIncm = Math.round(
          this._finiancialInfoSvc.saveClntIncmDtlModel.avgMnthlyIncm
        );
        this.setValueToForm2();
      }
    }
  }
  setSalaryDetailForm() {
    this.jwt = sessionStorage.getItem('JWT');
    this.jwt = JSON.parse(this.jwt);

    let date = new Date()
    this._finiancialInfoSvc.saveClntIncmDtlModel = {
      clntSeq: this.saveCnicData.clntSeq,
      incmDtlSeq: null,
      incmCtgryKey: 4,
      incmAmt: null,
      incmTypKey: null,
      qnty: null,
      frqncy: null,
      avgMnthlyIncm: null,
      cmnt: '',
      crtdBy: this.jwt?.preferred_username,
      crtdDt: date,
      lastUpdBy: this.jwt?.preferred_username,
      lastUpdDt: date,
      delFlg: false
    };
  }
  editSalaryDetail(content: any, data: any, h4: any, index: any) {

    this.index = index;
    this.modelHeading = h4;
    this._employerInfoSvc.edit = true;
    this._sharedSvc.msg = this.translate.instant('toast.recordUpdated');
    this.modalService.open(content, {
      windowClass: "popupWidth1k",
      backdropClass: "light-blue-backdrop",
      centered: true,
    });
    this._finiancialInfoSvc.saveClntIncmDtlModel = data;
    this.setValueToForm2();
    // this._conditionsService.salaryDetailData = true;
    //       this._conditionsService.salaryDetailDataFn();
  }
  onUpdateSalaryDetail() {
    this.setValueToModel2();
    this._finiancialInfoSvc.getAllIncome[this.index] = this._finiancialInfoSvc.saveClntIncmDtlModel;
    this._conditionsService.salaryDetailData = true;
    this._conditionsService.salaryDetailDataFn();
    this._finiancialInfoSvc.calculationOfIncomeAmounts(4, this._finiancialInfoSvc.getAllIncome)

    this.dismissModal();
  }
  DeletePopup_SD(data: any, index: any) {
    this._sharedSvc.msg = "Record deleted";
    this._sharedPopupSvc
      .popup("", this.translate.instant('common.areSelectRecordDelete'), "", this.translate.instant('common.delete'), this.translate.instant('common.cancelSmall'), null, this.translate.instant('common.incomeAmount') + " : " + data?.incmAmt)
      .then((confirmed: any) => {
        if (confirmed) {
          // Delete function call
          this._sharedSvc.msg = "Record Deleted";
          this._finiancialInfoSvc.getAllIncome[index].delFlg = true;
          if (data.incmDtlSeq == null) {
            this._finiancialInfoSvc.getAllIncome.splice(index, 1);
          }
          if (this._finiancialInfoSvc.getAllIncome?.length == 0) {
            this._conditionsService.salaryDetailData = false;
            this._conditionsService.salaryDetailDataFn();
            this._finiancialInfoSvc.totalSalaryAmt = 0;
            localStorage.setItem('totalSalaryAmt', this._finiancialInfoSvc.totalSalaryAmt);

            return;
          }
          this._finiancialInfoSvc.getAllIncome = this._finiancialInfoSvc.getAllIncome;
          this._conditionsService.salaryDetailData = true;
          this._conditionsService.salaryDetailDataFn();
          this._finiancialInfoSvc.calculationOfIncomeAmounts(4, this._finiancialInfoSvc.getAllIncome)

          // console.log("Delete Success");
        } else {
          //in case of cancel
          return;
        }
      })
      .catch(() => {
        // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)');
        return;
      });
  }
  onSubmitSalaryDetail(): void {

    if (this.salaryDetailForm.invalid) {
      for (const control of Object.keys(this.salaryDetailForm.controls)) {
        this.salaryDetailForm.controls[control].markAsTouched();
      }
      return;
    }
    this._finiancialInfoSvc.saveSalaryDataModel.push(this._finiancialInfoSvc.saveClntIncmDtlModel);

    this._conditionsService.salaryDetailData = true;
    this._conditionsService.salaryDetailDataFn();
    this._finiancialInfoSvc.getAllIncome.push(this._finiancialInfoSvc.saveClntIncmDtlModel);
    // this._sharedSvc.collectionSize = this._finiancialInfoSvc.getAllIncome?.length;
    // this._finiancialInfoSvc.getAllIncome = this._finiancialInfoSvc.getAllIncome.slice((this._sharedSvc.page - 1) * this._sharedSvc.pageSize, (this._sharedSvc.page - 1) * this._sharedSvc.pageSize + this._sharedSvc.pageSize);
    this.modalService.dismissAll();
    this._sharedSvc.success(this._sharedSvc.msg);
    this._finiancialInfoSvc.calculationOfIncomeAmounts(4, this._finiancialInfoSvc.getAllIncome)
    localStorage.setItem("salaryINCOME", JSON.stringify(this._finiancialInfoSvc.getAllIncome));
  }
  saveClntIncmDtl() {

    this._finiancialInfoSvc.saveClntIncmDtlModel = this._finiancialInfoSvc.getAllIncome;
    this._sharedSvc.showSpinner();
    this._finiancialInfoSvc.saveClntIncmDtl().subscribe({
      next: response => {
        if (response) {
          this._sharedSvc.hideSpinner();
          this._finiancialInfoSvc.getAllIncome = response;
          this._finiancialInfoSvc.calculationOfIncomeAmounts(4, this._finiancialInfoSvc.getAllIncome)
          this._conditionsService.salaryDetailData = false;
          this._conditionsService.salaryDetailDataFn();
          this._finiancialInfoSvc.saveSalaryDataModel = [];
          localStorage.removeItem('saveSalaryDataModel');
          this._sharedSvc.success(this._sharedSvc.msg);
        }
      },
      error: e => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    });
  }

  setValueToModel1() {
    this._employerInfoSvc.saveEmployerModel.emplNm = this.emplNm.value
    this._employerInfoSvc.saveEmployerModel.dsgntn = this.dsgntn.value
    this._employerInfoSvc.saveEmployerModel.phNum = this.phNum.value
    this._employerInfoSvc.saveEmployerModel.emplWbstUrl = this.emplWbstUrl.value
    this._employerInfoSvc.saveEmployerModel.emplStsKey = this.emplStsKey.value
    this._employerInfoSvc.saveEmployerModel.strtDt = this.strtDt.value
    // this._employerInfoSvc.saveEmployerModel.endDt = this.endDt.value
    this._employerInfoSvc.saveEmployerModel.cmpnyAddr = this.cmpnyAddr.value
  }

  setValueToForm1() {
    this.emplNm.setValue(this._employerInfoSvc.saveEmployerModel.emplNm);
    this.dsgntn.setValue(this._employerInfoSvc.saveEmployerModel.dsgntn);
    this.phNum.setValue(this._employerInfoSvc.saveEmployerModel.phNum);
    this.emplWbstUrl.setValue(this._employerInfoSvc.saveEmployerModel.emplWbstUrl);
    this.emplStsKey.setValue(this._employerInfoSvc.saveEmployerModel.emplStsKey);
    this.strtDt.setValue(this._employerInfoSvc.saveEmployerModel.strtDt);
    // this.endDt.setValue(this._employerInfoSvc.saveEmployerModel.endDt);
    this.cmpnyAddr.setValue(this._employerInfoSvc.saveEmployerModel.cmpnyAddr);
  }

  setValueToModel2() {
    this._finiancialInfoSvc.saveClntIncmDtlModel.incmTypKey = this.incmTypKey.value
    this._finiancialInfoSvc.saveClntIncmDtlModel.frqncy = this.frqncy.value
    this._finiancialInfoSvc.saveClntIncmDtlModel.qnty = this.qnty.value
    this._finiancialInfoSvc.saveClntIncmDtlModel.incmAmt = this.incmAmt.value
    this._finiancialInfoSvc.saveClntIncmDtlModel.avgMnthlyIncm = this.avgMnthlyIncm.value
  }

  setValueToForm2() {
    this.incmTypKey.setValue(this._finiancialInfoSvc.saveClntIncmDtlModel.incmTypKey);
    this.frqncy.setValue(this._finiancialInfoSvc.saveClntIncmDtlModel.frqncy);
    this.qnty.setValue(this._finiancialInfoSvc.saveClntIncmDtlModel.qnty);
    this.incmAmt.setValue(this._finiancialInfoSvc.saveClntIncmDtlModel.incmAmt);
    this.avgMnthlyIncm.setValue(this._finiancialInfoSvc.saveClntIncmDtlModel.avgMnthlyIncm);
  }


  validationForm() {
    this.employerForm = new FormGroup({
      emplNm: new FormControl(null, [Validators.required, Validators.minLength(3), Validators.maxLength(250)]),
      dsgntn: new FormControl(null, [Validators.required, Validators.minLength(3), Validators.maxLength(250)]),
      phNum: new FormControl(null, [Validators.required]),
      emplWbstUrl: new FormControl(null, [Validators.required, Validators.minLength(3), Validators.maxLength(250),]),
      emplStsKey: new FormControl(null, [Validators.required]),
      strtDt: new FormControl(null, [Validators.required]),
      // endDt: new FormControl(null, []),
      cmpnyAddr: new FormControl(null, [Validators.required, Validators.minLength(3), Validators.maxLength(250),]),
    });
    this.salaryDetailForm = new FormGroup({
      incmTypKey: new FormControl(null, [Validators.required]),
      incmAmt: new FormControl(null, [Validators.required, Validators.min(1), Validators.max(1000000)]),
      frqncy: new FormControl(null, [Validators.required]),
      avgMnthlyIncm: new FormControl(null, []),
      qnty: new FormControl(null, [Validators.required, Validators.min(1), Validators.max(100)]),
    });
  }

  // salaryDetailForm

  get incmTypKey() {
    return this.salaryDetailForm.get("incmTypKey")!;
  }
  get incmAmt() {
    return this.salaryDetailForm.get("incmAmt")!;
  }
  get frqncy() {
    return this.salaryDetailForm.get("frqncy")!;
  }
  get avgMnthlyIncm() {
    return this.salaryDetailForm.get("avgMnthlyIncm")!;
  }

  get qnty() {
    return this.salaryDetailForm.get("qnty")!;
  }

  // employerForm
  get emplNm() { return this.employerForm.get("emplNm") }
  get dsgntn() { return this.employerForm.get("dsgntn") }
  get phNum() { return this.employerForm.get("phNum") }
  get emplWbstUrl() { return this.employerForm.get("emplWbstUrl") }
  get emplStsKey() { return this.employerForm.get("emplStsKey") }
  get strtDt() { return this.employerForm.get("strtDt") }
  // get endDt() { return this.employerForm.get("endDt") }
  get cmpnyAddr() { return this.employerForm.get("cmpnyAddr") }




  employerInfoContinue() {
    let temp = localStorage.getItem("SaveCnicData");
    // if (temp) {
    //   let clnt_seq = JSON.parse(temp).clntSeq;

    //   this._employerInfoSvc.InItGetAllEmployerDetail(clnt_seq).then((result) => {
    //     sessionStorage.setItem('employerInfo', JSON.stringify(!!this._employerInfoSvc.getAllEmployerPG ? this._employerInfoSvc.getAllEmployerPG : 0))
    //   }).catch((error) => {
    //     console.error('Error:', error);
    //   });
    //   console.log('Employer Info:', JSON.parse(sessionStorage.getItem('employerInfo')));
    // }
  }
  salaryDetailContinue() {
    let temp = localStorage.getItem("SaveCnicData");
    // if (temp) {
    //   let clnt_seq = JSON.parse(temp).clntSeq;

    //   this._finiancialInfoSvc.InItGetClntAllIncmDtlSeq(clnt_seq, 4).then((result) => {
    //     sessionStorage.setItem('salaryDetail', JSON.stringify(!!this._finiancialInfoSvc.getAllIncome ? this._finiancialInfoSvc.getAllIncome : 0));
    //   }).catch((error) => {
    //     console.error('Error:', error);
    //   });
    //   console.log('Salary Detail:', JSON.parse(sessionStorage.getItem('salaryDetail')));
    // }
  }





}
