import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CookieService } from 'ngx-cookie-service';
import { Observable } from "rxjs";
import { Address, ClientStatus, CNICValidation, SaveClient } from "../model/clintOnboarding";
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { environment } from "src/app/global";
import { SharedService } from "src/app/components/shared/service/shared.service";
import { DatePipe } from "@angular/common";
import { Endpoint } from "src/app/components/shared/serviceEndPoints";
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})

export class ClintOnboardingService {

  cnicValidationModel: CNICValidation = new CNICValidation();
  clientStatusModel: ClientStatus = new ClientStatus();
  saveClientModel: SaveClient = new SaveClient();
  addressModel: Address;
  getAllClient: any;
  onBoardingTable: any // delete after
  getAllClientPG: any;
  edit: boolean = false;
  nosOfResidence: number | null;
  resOfResidence: number | null;
  appAddressVal: boolean = false;
  cnicNumFormate: any;
  walletInput: boolean = false;
  phoneInput: boolean = false;
  _phNum: any;
  _walletNumber: any;
  locationData: any;
  SaveCnicData: any;
  isPermAddress: boolean = true;
  cityName: any;
  districtName: any;
  stateName: any;
  thslName: any;
  ucName: any;
  locSeq: any;
  areaFieldsInvalid: boolean = true;
  getAllApprovedClient: any;
  getAllApprovedClientPG: any;

  clientOnBrdDocs: any = [];
  user: any;
  userId: any;


  constructor(
    public router: Router,
    private _http: HttpClient,
    private _cookieSvc: CookieService,
    public _sharedSvc: SharedService,
    private modalService: NgbModal,
    public translate: TranslateService,
  ) { }

  // APIS OnBoarding Start 
  CNICValidation() {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL + Endpoint.OnBoarding.CNICValidation, this.cnicValidationModel, this._sharedSvc.requestOptions);
  }

  updateClientStatus() {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL + Endpoint.OnBoarding.UpdateClientStatus, this.clientStatusModel, this._sharedSvc.requestOptions);
  }

  saveClient() {
    this._sharedSvc.tokenHeader();

    return this._http.post<any>(environment.config.BASE_API_URL + Endpoint.OnBoarding.SaveClient, this.saveClientModel, this._sharedSvc.requestOptions);
  }

  clientBasicInfo(clntSeq: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL + Endpoint.OnBoarding.GetClientBasicInfo + clntSeq, this._sharedSvc.requestOptions);
  }

  getAllClients() {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL + Endpoint.OnBoarding.GetAllClients, this._sharedSvc.requestOptions);
  }

  getAllApprovedClients() {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL + Endpoint.OnBoarding.GetAllApprovClients, this._sharedSvc.requestOptions);
  }

  saveAddress() {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL + Endpoint.OnBoarding.SaveAddress, this.addressModel, this._sharedSvc.requestOptions);
  }

  getAddress(entySeq: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL + Endpoint.OnBoarding.GetAddress + entySeq, this._sharedSvc.requestOptions);
  }
  // APIS OnBoarding End 


  InItClientBasicInfo(clntSeq?: any): void {
    this._sharedSvc.showSpinner()
    this.clientBasicInfo(clntSeq).subscribe({
      next: (response: any) => {
        if (response) {
          localStorage.setItem('SaveCnicData', JSON.stringify(response));
          this.saveClientModel = response;
          localStorage.setItem('reload', "true");
          this._sharedSvc.calculateDOB(this.saveClientModel.dob);
          this.convertDates();
          this.saveClientModel.cnicNum = JSON.stringify(this.saveClientModel.cnicNum);
          var checkNumber = this.saveClientModel.cnicNum?.substring(this.saveClientModel.cnicNum.length - 1, this.saveClientModel.cnicNum.length);
          if (checkNumber % 2 == 0) {
            this.saveClientModel.gndrKey = 19;
          }
          else {
            this.saveClientModel.gndrKey = 18;
          }
          if (response.mnthsOfResidence) {
            this.addressModel.mnthsOfResidence = response.mnthsOfResidence
          }
          var CNIC1 = this.saveClientModel.cnicNum?.substring(0, 5);
          var CNIC2 = this.saveClientModel.cnicNum?.substring(5, 12);
          var CNIC3 = this.saveClientModel.cnicNum?.substring(12, 13);
          this.cnicNumFormate = CNIC1 + "-" + CNIC2 + "-" + CNIC3; //this.saveCNICModel?.cnicNum;
          var w1 = this.saveClientModel?.walletNumber?.substring(0, 4);
          var w2 = this.saveClientModel?.walletNumber?.substring(4, 11);
          this._walletNumber = w1 + "-" + w2;
          this.walletInput = true;
          var p1 = this.saveClientModel?.phNum?.substring(0, 4);
          var p2 = this.saveClientModel?.phNum?.substring(4, 11);
          this._phNum = p1 + "-" + p2;
          this.phoneInput = true;
          // calculate address month of residence
          // this.addressModel.mnthsOfResidence = this.saveClientModel.mnthsRes;
          // this.nosOfResidence = Math.floor(this.addressModel.mnthsOfResidence / 12);
          // this.resOfResidence = this.addressModel.mnthsOfResidence % 12;
          if (response.prmAddr) {
            this.isPermAddress = false;
          }
          else {
            this.isPermAddress = true;
          }
          if (window.location.href.includes("individual-group-info")) { } else {
            if (localStorage.getItem('ras')) {
              localStorage.removeItem('ras');
              window.location.reload();
            }
          }
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }

  convertDates() {
    let tempDepositDate = new Date(this.saveClientModel.cnicIssueDt);
    this.saveClientModel.cnicIssueDt = { year: tempDepositDate.getFullYear(), month: tempDepositDate.getMonth() + 1, day: tempDepositDate.getDate() };
    let tempDepositDate2 = new Date(this.saveClientModel.cnicExpryDt);
    this.saveClientModel.cnicExpryDt = { year: tempDepositDate2.getFullYear(), month: tempDepositDate2.getMonth() + 1, day: tempDepositDate2.getDate() };
    let tempDepositDate3 = new Date(this.saveClientModel.dob);
    this.saveClientModel.dob = { year: tempDepositDate3.getFullYear(), month: tempDepositDate3.getMonth() + 1, day: tempDepositDate3.getDate() };
  }

  InItGetAllClients(): void {
    this._sharedSvc.showSpinner2();
    this.getAllClients().subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.hideSpinner2();
          this.getAllClient = response;
          this.pageChange();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner2();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }
  pageChange() {
    let jwt = sessionStorage.getItem("JWT");
    this.user = JSON.parse(jwt);
    this.userId = this.user.preferred_username;
    if(this.userId === 'vousr'){
      this.getAllClient=this.getAllClient?.filter(item=>item.clntStsKey === 2133);
      // this._sharedSvc.pageSize = this.getAllClientPG?.length;
    }
  this._sharedSvc.collectionSize = this.getAllClient?.length;
  this.getAllClientPG = this.getAllClient?.slice((this._sharedSvc.page - 1) * this._sharedSvc.pageSize, (this._sharedSvc.page - 1) * this._sharedSvc.pageSize + this._sharedSvc.pageSize);
  }
  InItGetApprovedClients(): void {
    this._sharedSvc.showSpinner()
    this.getAllApprovedClients().subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.hideSpinner();
          this.getAllApprovedClient = response;
          console.log("getAllApprovedClient")
          console.log(response)
          sessionStorage.setItem("getAllApprovedClient", JSON.stringify(response))
          this.pageChange2();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }
  pageChange2() {
    console.log("page2Done")
    this._sharedSvc.collectionSize = this.getAllApprovedClient?.length;
    this.getAllApprovedClientPG = this.getAllApprovedClient.slice((this._sharedSvc.page - 1) * this._sharedSvc.pageSize, (this._sharedSvc.page - 1) * this._sharedSvc.pageSize + this._sharedSvc.pageSize);
    // this._sharedSvc.pageSize = this.getAllApprovedClientPG?.length;
  }

  allAddressData: any;
  isHomeAddress: boolean = true;
  clntSeqAdd: any;
  bizAddAsClientAdd: boolean = false;
  InItGetAddress(entySeq?: any, callback?: any): void {
    this._sharedSvc.showSpinner();
    this.getAddress(entySeq).subscribe({
      next: (response: any) => {
        if (response) {
          if (response.error) {
            response.error != 'Address not found'?this._sharedSvc.error(response.error):'';
            this._sharedSvc.hideSpinner();
            return;
          }
          this.allAddressData = response;
          if (this.allAddressData.addr_rel.entyType == '2') {
            this.isHomeAddress = false
          }
          // this.InItClientBasicInfo(entySeq);
          this.addressModel = response.addr;
          localStorage.setItem('personalAddress', JSON.stringify(this.addressModel));
          this.locationData = localStorage.getItem('locationData');
          this.locationData = JSON.parse(this.locationData);
          for (let i = 0; i < this.locationData?.length; i++) {
            if (this.locationData[i].locationSeq == this.addressModel.locSeq) {
              this.onSelectionArea(this.locationData[i]);
            }
          }
          // this.SaveCnicData = localStorage.getItem('SaveCnicData');
          // this.SaveCnicData.
          this._sharedSvc.hideSpinner();

          if (this.bizAddAsClientAdd) {
            this.bizAddAsClientAddress()
          }
          if (callback) {
            return callback(this.addressModel);
          }
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }

  bizAddAsClientAddress(): void {

    this.addressModel.isPermAddress = true;
    // this.addressModel.mnthsOfResidence = 0;
    this.addressModel.clntSeq = this.clntSeqAdd;
    this.addressModel.addrSeq = null;
    this.addressModel.addrRelSeq = null;
    this.addressModel.delFlg = false;
    this.addressModel.locSeq = this.locSeq;
    this.addressModel.latitude = 0;
    this.addressModel.longitude = 0;
    this.addressModel.entySeq = this.clntSeqAdd;
    this.addressModel.entyType = 1;
    this._sharedSvc.showSpinner();
    this.saveAddress().subscribe({
      next: response => {
        if (response) {
          this._sharedSvc.hideSpinner();
          this._sharedSvc.success(this.translate.instant('toast.addressSavedClient'));
        }
      },
      error: e => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    });
  }

  onSelectionArea(area: any) {
    this.cityName = area?.cityName;
    this.districtName = area?.districtName;
    this.stateName = area?.stateName;
    this.thslName = area?.thslName;
    this.ucName = area?.ucName;
    this.locSeq = area.locationSeq;
    if (this.districtName == undefined && this.thslName == undefined && this.cityName == undefined &&
      this.ucName == undefined && this.stateName == undefined) {
      this.areaFieldsInvalid = true;
      return
    }
    else {
      this.areaFieldsInvalid = false;
    }
    this.modalService.dismissAll();
  }

}
