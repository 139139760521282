import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'src/app/components/shared/service/shared.service';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators, NgForm, ValidationErrors } from '@angular/forms';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';

import { ClintOnboardingService } from 'src/app/components/clint-onboarding/shared/service/clint-onboarding.service';
import { SetupService } from '../../../shared/service/setup.service';
import { TranslateService } from '@ngx-translate/core';
import { PicklistService } from 'src/app/components/shared/pickList/picklist.service';
import { ProductServices } from '../../../shared/service/product.service';


@Component({
  selector: 'app-charges',
  templateUrl: './charges.component.html',
  styleUrls: ['./charges.component.css']
})
export class ChargesComponent implements OnInit {
  chargesFormData: any;
  valueFormData: any;
  dropdownChargeTypeID: any = [];
  prdSeq: any;
  isEdit: boolean = false;
  singleRecord: any;

  constructor(
    public router: Router,
    public _sharedSvc: SharedService,
    public _clintOnboardingSvc: ClintOnboardingService,
    public _SetupService: SetupService,
    private formBuilder: FormBuilder,
    private nav: NgbNav,
    private modalService: NgbModal,
    public translate: TranslateService,
    public _picklistService: PicklistService,
    private route: ActivatedRoute,
    public _productServices: ProductServices,
  ) { }

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get("id")) {
      console.log("param ID " + this.route.snapshot.paramMap.get("id"));
      this.prdSeq = this.route.snapshot.paramMap.get("id");
    }

    this.chargesFormData = this.formBuilder.group({
      chrgTypSeq: ['', Validators.required],
      rulSeq: ['', Validators.required],
      calcTypKey: ['', Validators.required],
      calcOfChrg: ['', Validators.required, this.numberValidator.bind(this)],
      calcOrdr: ['', Validators.required, this.numberValidator.bind(this)],
      chrgPymntTypKey: ['', Validators.required],
      chrgVal: ['', Validators.required, this.numberValidator.bind(this)],
      adjRndngFlg: ['', Validators.required],
      sgrtInstNum: ['', Validators.required, this.numberValidator.bind(this)],
      chrgTypFlg: ['', Validators.required]
    });

    this.valueFormData = this.formBuilder.group({
      minLimit: ['', Validators.required],
      maxLimit: ['', Validators.required],
      value: ['', Validators.required],
    });
    this.getChargeTypeID();

  }

  get chrgTypSeq() { return this.chargesFormData.get('chrgTypSeq') }
  get chrgPymntTypKey() { return this.chargesFormData.get('chrgPymntTypKey') }
  get rulSeq() { return this.chargesFormData.get('rulSeq') }
  get calcTypKey() { return this.chargesFormData.get('calcTypKey') }
  get chrgVal() { return this.chargesFormData.get('chrgVal') }
  get calcOrdr() { return this.chargesFormData.get('calcOrdr') }
  get calcOfChrg() { return this.chargesFormData.get('calcOfChrg') }
  get sgrtInstNum() { return this.chargesFormData.get('sgrtInstNum') }
  get adjRndngFlg() { return this.chargesFormData.get('adjRndngFlg') }
  get chrgTypFlg() { return this.chargesFormData.get('chrgTypFlg') }

  get minLimit() { return this.valueFormData.get('minLimit') }
  get maxLimit() { return this.valueFormData.get('maxLimit') }
  get value() { return this.valueFormData.get('value') }


  openModal(content: any, styleClass?: any) {
    this.modalService.open(content, {
      // backdrop: "static",
      // keyboard: false,
      windowClass: styleClass ? styleClass : "popupWidth800",
      backdropClass: 'light-blue-backdrop',
      centered: true
    });
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  saveChargesForm(formData: any) {
    
    if (this.chargesFormData.invalid) {
      for (const control of Object.keys(this.chargesFormData.controls)) {
        this.chargesFormData.controls[control].markAsTouched();
      }
      return;
    }
    let data = {
      ...this.chargesFormData.value,
      prdChrgSeq: null,
      prdSeq: Number(this.prdSeq),
      chrgTypSeq: Number(this.chargesFormData.value.chrgTypSeq),
      rulSeq: Number(this.chargesFormData.value.rulSeq),
      calcTypKey: Number(this.chargesFormData.value.calcTypKey),
      calcOfChrg: Number(this.chargesFormData.value.calcOfChrg),
      calcOrdr: Number(this.chargesFormData.value.calcOrdr),
      chrgPymntTypKey: Number(this.chargesFormData.value.chrgPymntTypKey),
      chrgVal: Number(this.chargesFormData.value.chrgVal),
      adjRndngFlg: Number(this.chargesFormData.value.chrgTypSeq),
      sgrtInstNum: Number(this.chargesFormData.value.chrgTypSeq),
      chrgTypFlg: Number(this.chargesFormData.value.chrgTypSeq),
      delFlg: 0
    }
    if (this.isEdit == true) {
      data.prdChrgSeq = this.singleRecord.prdChrgSeq
    }
    this._productServices.saveProductChrgModel = data;
    this._sharedSvc.showSpinner3();
    this._productServices.save_product_chrg().subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.success(
            this.translate.instant("toast.question") +
            (this.isEdit === true
              ? this.translate.instant("toast.updated")
              : this.translate.instant("toast.added"))
          );
          this.chargesFormData.reset();
          this._sharedSvc.hideSpinner3();
          this.modalService.dismissAll();
          this._productServices.get_product_chrg(this.prdSeq);
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner3();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(
            ([key, value]: any, index: number) => {
              this._sharedSvc.error(value);
            }
          );
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    });



  }
  saveValueForm(formData: any) {
    if (this.valueFormData.invalid) {
      for (const control of Object.keys(this.valueFormData.controls)) {
        this.valueFormData.controls[control].markAsTouched();
      }
      return;
    }
  }

  getChargeTypeID() {
    this._sharedSvc.showSpinner();

    this._picklistService.pickListAPI(this._picklistService.pickListModel.LOAN_FREQUENCY).subscribe({
      next: (response) => {
        if (response) {
          console.log(response)
          this.dropdownChargeTypeID = response;
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }



  numberValidator(control: AbstractControl): Promise<ValidationErrors | null> {
    return Promise.resolve(this.validateNumber(control));
  }
  private validateNumber(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value === null || value === "" || isNaN(value)) {
      return { notANumber: true };
    }
    return null;
  }
  moveToNextTab() {
    if (this.nav) {
      const currentTab = this.nav.activeId || 1;
      const nextTab = currentTab < this.nav.items.length ? currentTab + 1 : 1;
      this.nav.select(nextTab);
    }
  }

}

