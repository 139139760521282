import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { CommonModule, DecimalPipe } from "@angular/common";
import { RouterModule } from "@angular/router";
import { routes } from "../app/app-routing.module";
import { AppRoutingModule } from "./app-routing.module";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { NgbDateParserFormatter, NgbNavModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { HashLocationStrategy, LocationStrategy, DatePipe } from "@angular/common";
import { KeycloakAngularModule } from "keycloak-angular";
// import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskModule } from 'ngx-mask';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { AuthModule } from './auth/auth.module';
import { KzMaskDirective } from "./components/shared/kz-mask.directive";
import { PhoneMaskDirective } from "./components/shared/phone-mask.directive";
import { CustomCrudToastrService } from "./components/shared/service/custom-crud-toastr.service";
import { NgxLoadingModule } from "ngx-loading";

// +++++ COMPONENTS STARTS
import { AppComponent } from "./app.component";
import { LoginComponent } from "./components/login/login.component";
import { NavbarComponent } from "./components/partial/navbar/navbar.component";
import { SidebarComponent } from "./components/partial/sidebar/sidebar.component";
//  +++++ dashboard
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { ActiveLoansComponent } from "./components/dashboard/active-loans/active-loans.component";
import { OverdueComponent } from "./components/dashboard/overdue/overdue.component";
//  +++++ clint-onboarding
import { ClintOnboardingComponent } from "./components/clint-onboarding/clint-onboarding.component";
import { BusinessAppraisalComponent } from "./components/clint-onboarding/business-appraisal/business-appraisal.component";
import { ClientDocsComponent } from "./components/clint-onboarding/client-docs/client-docs.component";
import { ClientRelativesComponent } from "./components/clint-onboarding/client-relatives/client-relatives.component";
import { EmployerInfoComponent } from "./components/clint-onboarding/employer-info/employer-info.component";
import { FinancialInfoComponent } from "./components/clint-onboarding/financial-info/financial-info.component";
import { OtherOutstandingComponent } from "./components/clint-onboarding/other-outstanding/other-outstanding.component";
import { PersonalInfoComponent } from "./components/clint-onboarding/personal-info/personal-info.component";
import { PscComponent } from "./components/clint-onboarding/psc/psc.component";
import { SummaryComponent } from "./components/clint-onboarding/summary/summary.component";
//  +++++ Individual-Group-Loan
import { AddIndividualCreditAppComponent } from "./components/Individual-Group-Loan/add-individual-credit-app/add-individual-credit-app.component";
import { ClientCreditCollateralDetailComponent } from "./components/Individual-Group-Loan/add-individual-credit-app/client-credit-collateral-detail/client-credit-collateral-detail.component";
import { ClientCreditDocComponent } from "./components/Individual-Group-Loan/add-individual-credit-app/client-credit-doc/client-credit-doc.component";
import { ClientCreditInfoComponent } from "./components/Individual-Group-Loan/add-individual-credit-app/client-credit-info/client-credit-info.component";
import { ClientCreditUtilizationPlanComponent } from "./components/Individual-Group-Loan/add-individual-credit-app/client-credit-utilization-plan/client-credit-utilization-plan.component";
import { IndividualCreditAppComponent } from "./components/Individual-Group-Loan/individual-credit-app/individual-credit-app.component";
//  +++++ disbursement
import { DisbursementListingComponent } from "./components/disbursement/disbursement-listing/disbursement-listing.component";
import { DisbursementUpFrontRecoveryComponent } from "./components/disbursement/disbursement-form/disbursement-up-front-recovery/disbursement-up-front-recovery.component";
import { DisbursementVoucherComponent } from "./components/disbursement/disbursement-form/disbursement-voucher/disbursement-voucher.component";
import { DisbursementRepaymentScheduleComponent } from "./components/disbursement/disbursement-form/disbursement-repayment-schedule/disbursement-repayment-schedule.component";
import { DisbursementFormComponent } from "./components/disbursement/disbursement-form/disbursement-form.component";
import { CollateralInformationComponent } from "./components/disbursement/disbursement-form/collateral-information/collateral-information.component";
//  +++++ recovery
import { RecoveryListingComponent } from "./components/recovery/recovery-listing/recovery-listing.component";
import { SearchIndividualRecoveryComponent } from "./components/recovery/search-individual-recovery/search-individual-recovery.component";
//  +++++ admin
import { ClosingTypeComponent } from "./components/admin/closing-type/closing-type.component";
import { ExcessRecoveryComponent } from "./components/admin/excess-recovery/excess-recovery.component";
import { IncomingBagsComponent } from "./components/admin/incoming-bags/incoming-bags.component";
import { JournelVoucherComponent } from "./components/admin/journel-voucher/journel-voucher.component";
import { GoldRateComponent } from "./components/admin/gold-rate/gold-rate.component";
//  +++++ setup
import { BusinessActivityComponent } from "./components/setup/business-sector/business-activity/business-activity.component";
import { BusinessSectorComponent } from "./components/setup/business-sector/business-sector.component";
import { ChargesTypesComponent } from "./components/setup/charges-types/charges-types.component";
import { CityComponent } from "./components/setup/city/city.component";
import { CommanCodesComponent } from "./components/setup/comman-codes/comman-codes.component";
import { ExpenseTypesComponent } from "./components/setup/expense-types/expense-types.component";
import { FileUploadComponent } from "./components/setup/file-upload/file-upload.component";
import { DistrictComponent } from "./components/setup/geography/district/district.component";
import { GeographyComponent } from "./components/setup/geography/geography/geography.component";
import { ProvinceComponent } from "./components/setup/geography/province/province.component";
import { TehseelComponent } from "./components/setup/geography/tehseel/tehseel.component";
import { UinioCouncilComponent } from "./components/setup/geography/uinio-council/uinio-council.component";
import { HealthPlanComponent } from "./components/setup/health-plan/health-plan.component";
import { AreaComponent } from "./components/setup/organization/area/area.component";
import { BranchComponent } from "./components/setup/organization/branch/branch.component";
import { CommunityComponent } from "./components/setup/organization/community/community.component";
import { PortfolioComponent } from "./components/setup/organization/portfolio/portfolio.component";
import { PartnerComponent } from "./components/setup/partner/partner.component";
import { PaymentTypesComponent } from "./components/setup/payment-types/payment-types.component";
import { AccountingSetupComponent } from "./components/setup/products/setup-add-product/accounting-setup/accounting-setup.component";
import { AssignmentSequenceComponent } from "./components/setup/products/setup-add-product/assignment-sequence/assignment-sequence.component";
import { AssociateProductComponent } from "./components/setup/products/setup-add-product/associate-product/associate-product.component";
import { BasicInfoComponent } from "./components/setup/products/setup-add-product/basic-info/basic-info.component";
import { ChargesComponent } from "./components/setup/products/setup-add-product/charges/charges.component";
import { DocumentAssignmentComponent } from "./components/setup/products/setup-add-product/document-assignment/document-assignment.component";
import { FormAssignmentComponent } from "./components/setup/products/setup-add-product/form-assignment/form-assignment.component";
import { BusinessSectorProductComponent } from "./components/setup/products/setup-add-product/business-sector-product/business-sector-product.component";
import { ProductRulesComponent } from "./components/setup/products/setup-add-product/product-rules/product-rules.component";
import { SetupAddProductComponent } from "./components/setup/products/setup-add-product/setup-add-product.component";
import { SetupProductListingComponent } from "./components/setup/products/setup-product-listing/setup-product-listing.component";
import { AnswerComponent } from "./components/setup/questionnair/answer/answer.component";
import { QuestionComponent } from "./components/setup/questionnair/question/question.component";
import { QuestionnairComponent } from "./components/setup/questionnair/questionnair.component";
import { RulesComponent } from "./components/setup/rules/rules.component";
import { ShroffManagementComponent } from "./components/setup/shroff-management/shroff-management.component";
import { TagsComponent } from "./components/setup/tags/tags.component";
import { UsermanagementComponent } from "./components/setup/usermanagement/usermanagement.component";
//  +++++ branchAccounting
import { BranchAccountingComponent } from "./components/branchAccounting/branch-accounting/branch-accounting.component";
//  +++++ cnic-expiry
import { CnicExpiryComponent } from "./components/cnic-expiry/cnic-expiry/cnic-expiry.component";
import { DeletePopupComponent } from "./components/shared/popup/delete-popup/delete-popup.component";
import { AlertComponent } from './components/shared/popup/alert/alert.component';
import { RegionComponent } from './components/setup/organization/region/region.component';
import { VaultManagementComponent } from './components/setup/vault-management/vault-management.component';
import { RollOverComponent } from './components/recovery/roll-over/roll-over.component';
import { RefrenceGroupValuesComponent } from "./components/setup/comman-codes/refrence-group-values/refrence-group-values.component";
import { SortbyPipePipe } from './components/shared/service/sortby-pipe.pipe';
import { TasdeeqReportComponent } from "./components/clint-onboarding/tasdeeq-report/tasdeeq-report.component";
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TableFilterPipe } from "./components/shared/service/table-filter.pipe";
import { SetupProductGroupsComponent } from "./components/setup/products/setup-product-groups/setup-product-groups.component";
import { TableFilterOnboardingPipe } from "./components/shared/service/table-filter-onboarding.pipe ";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavbarComponent,
    SidebarComponent,
    KzMaskDirective,
    PhoneMaskDirective,
    //  +++++ dashboard
    DashboardComponent,
    ActiveLoansComponent,
    OverdueComponent,
    //  +++++ clint-onboarding
    ClintOnboardingComponent,
    BusinessAppraisalComponent,
    ClientDocsComponent,
    ClientRelativesComponent,
    EmployerInfoComponent,
    FinancialInfoComponent,
    OtherOutstandingComponent,
    PersonalInfoComponent,
    PscComponent,
    SummaryComponent,
    //  +++++ Individual-Group-Loan
    AddIndividualCreditAppComponent,
    ClientCreditCollateralDetailComponent,
    ClientCreditDocComponent,
    ClientCreditInfoComponent,
    ClientCreditUtilizationPlanComponent,
    IndividualCreditAppComponent,
    //  +++++ disbursement
    DisbursementListingComponent,
    DisbursementUpFrontRecoveryComponent,
    DisbursementVoucherComponent,
    DisbursementRepaymentScheduleComponent,
    DisbursementFormComponent,
    CollateralInformationComponent,
    //  +++++ recovery
    RecoveryListingComponent,
    SearchIndividualRecoveryComponent,
    //  +++++ admin
    ClosingTypeComponent,
    JournelVoucherComponent,
    IncomingBagsComponent,
    ExcessRecoveryComponent,
    //  +++++ setup
    CommanCodesComponent,
    AreaComponent,
    PortfolioComponent,
    BranchComponent,
    CommunityComponent,
    ProvinceComponent,
    DistrictComponent,
    UinioCouncilComponent,
    TehseelComponent,
    GeographyComponent,
    SetupProductListingComponent,
    SetupProductGroupsComponent,
    SetupAddProductComponent,
    BasicInfoComponent,
    ProductRulesComponent,
    ChargesComponent,
    AccountingSetupComponent,
    FormAssignmentComponent,
    BusinessSectorProductComponent,
    DocumentAssignmentComponent,
    AssignmentSequenceComponent,
    BusinessSectorComponent,
    AssociateProductComponent,
    RulesComponent,
    UsermanagementComponent,
    QuestionnairComponent,
    QuestionComponent,
    AnswerComponent,
    HealthPlanComponent,
    PaymentTypesComponent,
    ExpenseTypesComponent,
    ChargesTypesComponent,
    CityComponent,
    TagsComponent,
    FileUploadComponent,
    ShroffManagementComponent,
    VaultManagementComponent,
    PartnerComponent,
    GoldRateComponent,
    BusinessActivityComponent,
    RefrenceGroupValuesComponent,
    //  +++++ branchAccounting
    BranchAccountingComponent,
    //  +++++ cnic-expiry
    CnicExpiryComponent,
    DeletePopupComponent,
    AlertComponent,
    RegionComponent,
    SortbyPipePipe,
    RollOverComponent,
    TasdeeqReportComponent,
    TableFilterPipe,
    TableFilterOnboardingPipe
  ],
  imports: [
    CommonModule,
    NgbCollapseModule,

    NgbModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    NgbNavModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes),
    ToastrModule.forRoot(),
    KeycloakAngularModule,
    NgxLoadingModule,
    // NgSelectModule,
    NgxMaskModule.forRoot(),
    // NgxDatatableModule,
    AuthModule,
    // TextMaskModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    [DatePipe, SortbyPipePipe],
    [CustomCrudToastrService],
    [Location, { provide: LocationStrategy, useClass: HashLocationStrategy }],
    // [{ provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true }],
    [DecimalPipe],
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}