import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-active-loans',
  templateUrl: './active-loans.component.html',
  styleUrls: ['./active-loans.component.css']
})
export class ActiveLoansComponent implements OnInit {

  constructor(private modalService: NgbModal,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
  }

  openLg(content: any) {
    localStorage.removeItem("selectedOrderData");
    this.modalService.open(content, {
      // backdrop: "static",
      // keyboard: false,
      windowClass: "customClassName",
      backdropClass: 'light-blue-backdrop',
      centered: true
    });
  }
}
