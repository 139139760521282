import { Component, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SharedService } from "src/app/components/shared/service/shared.service";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
  NgForm,
} from "@angular/forms";

import { ClintOnboardingService } from "src/app/components/clint-onboarding/shared/service/clint-onboarding.service";
import { SetupService } from "../../shared/service/setup.service";
import { PicklistService } from "src/app/components/shared/pickList/picklist.service";
import { formatDate } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-portfolio",
  templateUrl: "./portfolio.component.html",
  styleUrls: ["./portfolio.component.css"],
})
export class PortfolioComponent implements OnInit {
  loadingIndicator: boolean = false;
  rows: any;

  page: number = 5;
  pageSize: number = 5;
  collectionSize: number = 5;

  loginForm!: FormGroup;
  submitted = false;
  // currentDate: any = new Date().toISOString().split("T")[0];
  onBoardingTablePG: any;
  maskedCNIC: string;
  branchID: any;
  branchName: any;
  addBPortfolioformdata: any;
  isEdit: boolean = false;
  singleRecord: any;
  currentPortID: any;

  addEmployeeAssignment: any;

  coveringEMPLIST: any = [];

  isNewCase: boolean = true;

  selectedEmp: any;

  constructor(
    private modalService: NgbModal,
    public router: Router,
    public _sharedSvc: SharedService,
    public _clintOnboardingSvc: ClintOnboardingService,
    public _SetupService: SetupService,
    private formBuilder: FormBuilder,
    public _picklistService: PicklistService,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.addBPortfolioformdata = this.formBuilder.group({
      portName: ["", Validators.required],
      portStatusKey: ["", Validators.required],
      portDescription: ["", Validators.required],
    });

    this.addEmployeeAssignment = this.formBuilder.group({
      coveringEmpSequence: [""],
      employeeSequence: ["", Validators.required],
      coveringEmpFromDate: [""],
      coveringEmpToDate: [""],
    });

    this.addEmployeeAssignment.controls[
      "employeeSequence"
    ].valueChanges.subscribe((value) => {
      if (value) {
        this.coveringEMPLIST = this._SetupService.allEMPLIST.filter(
          (x) => x.empSeq != value
        );
        this.addEmployeeAssignment
          .get("employeeSequence")
          .disable({ emitEvent: false });
      }
    });
    this.addEmployeeAssignment.controls[
      "coveringEmpSequence"
    ].valueChanges.subscribe((value) => {
      if (value) {
        this.addEmployeeAssignment
          .get("coveringEmpSequence")
          .disable({ emitEvent: false });
      }
    });

    this.branchID = sessionStorage.getItem("singlebranchID");
    this.branchName = sessionStorage.getItem("singlebranchName");
    this._SetupService.get_port_list(sessionStorage.getItem("singlebranchID"));
    this._picklistService.SHROFF_STATUS();
    this._picklistService.InItGetLocations();
    this._SetupService.get_all_emp_list();
  }

  get portName() {
    return this.addBPortfolioformdata.get("portName");
  }
  get portStatusKey() {
    return this.addBPortfolioformdata.get("portStatusKey");
  }
  get portDescription() {
    return this.addBPortfolioformdata.get("portDescription");
  }

  get employeeSequence() {
    return this.addEmployeeAssignment.get("employeeSequence");
  }

  pageChange() {
    this.collectionSize = this._clintOnboardingSvc.onBoardingTable.length;
    this.onBoardingTablePG = this._clintOnboardingSvc.onBoardingTable.slice(
      (this.page - 1) * this.pageSize,
      (this.page - 1) * this.pageSize + this.pageSize
    );
    this.onBoardingTablePG;
  }

  submitportfolio(val) {
    if (this.addBPortfolioformdata.invalid) {
      for (const control of Object.keys(this.addBPortfolioformdata.controls)) {
        this.addBPortfolioformdata.controls[control].markAsTouched();
      }
      return;
    }
    let data = {
      ...this.addBPortfolioformdata.value,
      portCode: "0",
      branchSequence: this.branchID,
      delFlg: 0,
    };
    if (this.isEdit) {
      data.portSequence = this.singleRecord.portSequence;
    }
    this._SetupService.setupPortfolioModel = data;
    this._SetupService.savePortfolio().subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.success(
            this.translate.instant("toast.portfolio") +
            (this.isEdit === true
              ? this.translate.instant("toast.updated")
              : this.translate.instant("toast.added"))
          );
          this._sharedSvc.hideSpinner3();
          this.modalService.dismissAll();
          this._SetupService.get_port_list(this.branchID);
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner3();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(
            ([key, value]: any, index: number) => {
              this._sharedSvc.error(value);
            }
          );
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    });
  }

  editportfolio(content: any, id: any, edit: boolean) {
    let list = this._SetupService.allbranchPortList;
    let record = list.find((x) => x.portSequence === id);
    this.singleRecord = record;

    if (record) {
      let data = {
        portName: record.portName,
        portStatusKey: record.portStatusKey,
        portDescription: record.portDescription,
      };
      this.addBPortfolioformdata.patchValue(data);
      this.openModalAddEditPortfolio(content, edit);
    }
  }

  deleteRecord(id) {
    
    let list = this._SetupService.allbranchPortList;
    let record = list.find((x) => x.portSequence === id);
    this.singleRecord = record;

    if (record) {
      let data = {
        ...record,
        delFlg: 1,
      };
      this._SetupService.setupPortfolioModel = data;
      this._SetupService.savePortfolio().subscribe({
        next: (response) => {
          if (response) {
            this._sharedSvc.success(
              this.translate.instant("toast.portfolioDeleted")
            );
            this._sharedSvc.hideSpinner3();
            this.modalService.dismissAll();
            this._SetupService.get_port_list(this.branchID);
          }
        },
        error: (e) => {
          this._sharedSvc.hideSpinner3();
          if (e.status == 401) {
            this._sharedSvc.error(this._sharedSvc.authMsg);
            this._sharedSvc.logout();
            return;
          }
          if (e?.error) {
            Object.entries(e?.error).forEach(
              ([key, value]: any, index: number) => {
                this._sharedSvc.error(value);
              }
            );
          }
          if (e?.error?.errorMessage) {
            this._sharedSvc.error(e?.error?.error);
          }
        },
      });
    }
  }

  openModalAddEditPortfolio(content: any, edit: boolean) {
    localStorage.removeItem("selectedOrderData");
    this.isEdit = edit;
    this.modalService.open(content, {
      // backdrop: "static",
      // keyboard: false,
      windowClass: "popupWidth800",
      // backdropClass: 'light-blue-backdrop',
      centered: true,
    });
  }
  openModalTabletAssignment(content: any) {
    localStorage.removeItem("selectedOrderData");
    this.modalService.open(content, {
      // backdrop: "static",
      // keyboard: false,
      windowClass: "popupWidth800",
      // backdropClass: 'light-blue-backdrop',
      centered: true,
    });
  }
  openModalAssignLocation(content: any, id: any) {
    this.currentPortID = id;
    this._SetupService.get_port_location_list(id);
    localStorage.removeItem("selectedOrderData");
    this.modalService
      .open(content, {
        // backdrop: "static",
        // keyboard: false,
        windowClass: "popupWidth800",
        // backdropClass: 'light-blue-backdrop',
        centered: true,
      })
      .result.then(
        (result) => {
          // Your logic when modal is closed
          this._SetupService.portLocationsList = [];
        },
        (reason) => {
          // Your logic when modal is dismissed
          this._SetupService.portLocationsList = [];
        }
      );
  }
  openModalEmployeeAssignment(content: any, id: any) {
    localStorage.removeItem("selectedOrderData");
    this.get_port_covering_emp_list(id);

    this.currentPortID = id;
    this.modalService
      .open(content, {
        // backdrop: "static",
        // keyboard: false,
        windowClass: "popupWidth800",
        // backdropClass: 'light-blue-backdrop',
        centered: true,
      })
      .result.then(
        (result) => {
          // Your logic when modal is closed
          this.coveringEMPLIST = [];
          this.addEmployeeAssignment
            .get("employeeSequence")
            .enable({ emitEvent: false });
          this.addEmployeeAssignment
            .get("coveringEmpSequence")
            .enable({ emitEvent: false });
        },
        (reason) => {
          // Your logic when modal is dismissed
          this.coveringEMPLIST = [];
          this.addEmployeeAssignment
            .get("employeeSequence")
            .enable({ emitEvent: false });
          this.addEmployeeAssignment
            .get("coveringEmpSequence")
            .enable({ emitEvent: false });
        }
      );
  }

  branchFn() {
    this.router.navigate(["/setup-branch"]);
  }

  community(id: any) {
    console.log("comunity route");
    sessionStorage.setItem("portSeq", id);
    this.router.navigate(["/setup-community"]);
  }

  submittPoetfolio() {
    this._SetupService.saveCity().subscribe((data) => {
      alert("poertfolio added");
      // console.log(data)
    });
  }

  pushLocationInList(e: any) {
    let data = {
      portLocationSequence:
        e.target.checked === true
          ? 0
          : this.isPortLocationSeqPresent(e.target.value),
      portSequence: this.currentPortID,
      locationSequence: Number(e.target.value),
      delFlg: e.target.checked === true ? 0 : 1,
    };

    // this._SetupService.setupBranchLocationModel = data;
    this._SetupService.portLocationsList.push(data);
  }

  isPortLocationSeqPresent(locationSeq: any) {
    let result = this._SetupService?.portLocationList?.find(
      (x) => x?.locationSequence === Number(locationSeq)
    );
    if (result) {
      return result.portLocationSequence;
    }
  }

  isLocationSeqPresent(locationSeq: any) {
    return this._SetupService?.portLocationList?.some(
      (x) => x?.locationSequence === locationSeq
    );
  }
  
  submitLocation(){
    

    this._SetupService.savePortLocations().subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.success(this.translate.instant('toast.locationsAssignSuccessfully'));
          this._sharedSvc.hideSpinner3();
          this.modalService.dismissAll();
          this._SetupService.portLocationsList = [];
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner3();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(
            ([key, value]: any, index: number) => {
              this._sharedSvc.error(value);
            }
          );
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    });
  }
  openCommunityModal(content: any, id: any) {
    this._SetupService.get_port_community_list(id);
    this.modalService.open(content, {
      // backdrop: "static",
      // keyboard: false,
      windowClass: "popupWidth800",
      // backdropClass: 'light-blue-backdrop',
      centered: true,
    });
  }

  get_port_covering_emp_list(key: any) {
    this._sharedSvc.showSpinner();
    this._SetupService.getCoveringEmpListofPort(key).subscribe((res) => {
      this._sharedSvc.hideSpinner();
      //@ts-ignore
      if (res.error) {
        this.isNewCase = true;

        return;
      }
      console.log(res);
      this.addEmployeeAssignment.patchValue(res[0]);
      this.selectedEmp = res[0];
      this.isNewCase = false;
      this.addEmployeeAssignment.controls["coveringEmpFromDate"].setValue(
        formatDate(res[0].coveringEmpFromDate, "yyyy-MM-dd", "en")
      );
      this.addEmployeeAssignment.controls["coveringEmpToDate"].setValue(
        formatDate(res[0].coveringEmpToDate, "yyyy-MM-dd", "en")
      );
    });
  }

  submitEmpAssign(value) {
    if (this.addEmployeeAssignment.invalid) {
      for (const control of Object.keys(this.addEmployeeAssignment.controls)) {
        this.addEmployeeAssignment.controls[control].markAsTouched();
      }
      return;
    }

    
    
    let data = {
      ...this.addEmployeeAssignment.value,
      portSequence: this.currentPortID,
      portEmpSequence: null,
      coveringEmpSequence: Number(
        this.addEmployeeAssignment.controls["coveringEmpSequence"].value
      ),
      employeeSequence: Number(
        this.addEmployeeAssignment.controls["employeeSequence"].value
      ),
      coveringEmpFromDate: new Date(
        this.addEmployeeAssignment.controls["coveringEmpFromDate"].value
      ).toISOString(),
      coveringEmpToDate: new Date(
        this.addEmployeeAssignment.controls["coveringEmpToDate"].value
      ).toISOString(),
      delFlg: 0,
    };

    this._SetupService.setupPortEMPModel = data;
    this._SetupService.savePortEMP().subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.success(this.translate.instant('toast.portolioEmployeeAssign'));
          this._sharedSvc.hideSpinner3();
          this.modalService.dismissAll();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner3();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(
            ([key, value]: any, index: number) => {
              this._sharedSvc.error(value);
            }
          );
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    });
  }
  removePortAreaManager(name){
    if(this.addEmployeeAssignment.controls['employeeSequence'].value && this.addEmployeeAssignment.controls['coveringEmpSequence'].value && this.isNewCase === false){
      let record = this._SetupService.allEMPLIST.find(x=> x.empSeq === Number(this.addEmployeeAssignment.controls['employeeSequence'].value));
      
      if(record){
        let data = {
          portSequence: this.selectedEmp.portSequence,
          portEmpSequence: this.selectedEmp.portEmpSequence,
          coveringEmpSequence: this.selectedEmp.coveringEmpSequence,
          employeeSequence: this.selectedEmp.employeeSequence,
          coveringEmpFromDate: this.selectedEmp.coveringEmpFromDate,
          coveringEmpToDate: this.selectedEmp.coveringEmpToDate,
          delFlg: true,
        };

        this._SetupService.setupPortEMPModel = data;
        this._SetupService.savePortEMP().subscribe({
          next: (response) => {
            if (response) {
              this._sharedSvc.success(this.translate.instant('toast.employeeRemoved'));
              this._sharedSvc.hideSpinner3();
              this.addEmployeeAssignment.controls["employeeSequence"].setValue(
                ""
              );
              this.addEmployeeAssignment.controls[
                "coveringEmpSequence"
              ].setValue("");
              this.addEmployeeAssignment.controls[
                "coveringEmpSequence"
              ].enable();
              this.addEmployeeAssignment.controls["employeeSequence"].enable();
            }
          },
          error: (e) => {
            this._sharedSvc.hideSpinner3();
            if (e.status == 401) {
              this._sharedSvc.error(this._sharedSvc.authMsg);
              this._sharedSvc.logout();
              return;
            }
            if (e?.error) {
              Object.entries(e?.error).forEach(
                ([key, value]: any, index: number) => {
                  this._sharedSvc.error(value);
                }
              );
            }
            if (e?.error?.errorMessage) {
              this._sharedSvc.error(e?.error?.error);
            }
          },
        });
      }
    } else {
      this.addEmployeeAssignment.controls[name].setValue("");
      this.addEmployeeAssignment.controls[name].enable();
    }
  }
}
