import { Injectable } from '@angular/core';
import { SharedService } from '../../../shared/service/shared.service';
import { HttpClient } from '@angular/common/http';
import { Endpoint } from 'src/app/components/shared/serviceEndPoints';
import { environment } from "src/app/global";
import { AppAccess, UserRole } from 'src/app/components/setup/shared/model/setup';
@Injectable({
  providedIn: 'root'
})
export class AdminService {

  UserRoleModel=new UserRole();
  AppAccessModel=new AppAccess();
  vaultList:any;
  constructor( public _sharedSvc: SharedService,private _http: HttpClient,) { }


  callSaveUserRole() {
    this._sharedSvc.tokenHeader();
      return this._http.post<any>(environment.config.BASE_API_ADMIN_URL + Endpoint.Admin.save_user_role, this.UserRoleModel, this._sharedSvc.requestOptions);
  }
  callSaveAppAccess() {
    this._sharedSvc.tokenHeader();
      return this._http.post<any>(environment.config.BASE_API_ADMIN_URL + Endpoint.Admin.save_app_access, this.AppAccessModel, this._sharedSvc.requestOptions);
  }
  private callGetIncomimgBag() {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_ADMIN_URL + Endpoint.Admin.get_cltrl_info_lst, this._sharedSvc.requestOptions);
  }
  private add_bag_lctn(data:any) {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_ADMIN_URL + Endpoint.Admin.add_bag_lctn,data, this._sharedSvc.requestOptions);
  }
  private get_bag_lctn_app(key:any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_ADMIN_URL + Endpoint.Admin.get_bag_lctn_app+key, this._sharedSvc.requestOptions);
  }
  private callGetAccessRecovery() {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_ADMIN_URL + Endpoint.Admin.get_excs_pymnt_lst, this._sharedSvc.requestOptions);
  }
  private callGetGoldBySeq(goldSeq) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_ADMIN_URL + Endpoint.Admin.get_gold_rate+goldSeq, this._sharedSvc.requestOptions);
  }
  private callGetGoldRates() {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_ADMIN_URL + Endpoint.Admin.get_gold_rate_lst, this._sharedSvc.requestOptions);
  }

  private callSaveGoldRate(body:any){
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_ADMIN_URL + Endpoint.Admin.save_gold_rate, body,this._sharedSvc.requestOptions);
  }

  private callGetAuthList(role) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_ADMIN_URL + Endpoint.Admin.get_auth_list+role, this._sharedSvc.requestOptions);
  }
  private callGetAuthListForRole(id) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_ADMIN_URL + Endpoint.Admin.get_auth_list_for_role+id, this._sharedSvc.requestOptions);
  }
  private callGetRoleList() {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_ADMIN_URL + Endpoint.Admin.get_role_list, this._sharedSvc.requestOptions);
  }
  private callGetModesList() {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_ADMIN_URL + Endpoint.Admin.get_modes_list, this._sharedSvc.requestOptions);
  }
  private callGetSubModesList() {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_ADMIN_URL + Endpoint.Admin.get_sub_modes_list, this._sharedSvc.requestOptions);
  }

  journalVoucher(branchSeq) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_ADMIN_URL + Endpoint.Admin.get_jrnl_vchr_list+branchSeq, this._sharedSvc.requestOptions);
  }
  journalVoucherdetail(vchrSeq) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_ADMIN_URL + Endpoint.Admin.get_jrnl_vchr_detail_list+vchrSeq, this._sharedSvc.requestOptions);
  }
 
  async getIncomingBags( callback:any) {
    this._sharedSvc.showSpinner();
    this.callGetIncomimgBag().subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.hideSpinner();
          return callback(response);
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
            return null;
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
          return null;
        }
      },
      // complete: () => console.info('complete')
    })
  }

  async addBagLctn( data:any,callback:any) {
    this._sharedSvc.showSpinner();
    this.add_bag_lctn(data).subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.hideSpinner();
          return callback(response);
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
            return null;
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
          return null;
        }
      },
      // complete: () => console.info('complete')
    })
  }
  async getBagLctn( key:any,callback:any) {
    this._sharedSvc.showSpinner();
    this.get_bag_lctn_app(key).subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.hideSpinner();
          return callback(response);
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
            return null;
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
          return null;
        }
      },
      // complete: () => console.info('complete')
    })
  }

  async getExcessRecovery( callback:any) {
    this._sharedSvc.showSpinner();
    this.callGetAccessRecovery().subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.hideSpinner();
          return callback(response);
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
            return null;
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
          return null;
        }
      },
      // complete: () => console.info('complete')
    })
  }


  async getGoldRateBySeq( goldSeq,callback:any) {
    this._sharedSvc.showSpinner();
    this.callGetGoldBySeq(goldSeq).subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.hideSpinner();
          return callback(response);
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
            return null;
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
          return null;
        }
      },
      // complete: () => console.info('complete')
    })
  }

  async getGoldRates( callback:any) {
    this._sharedSvc.showSpinner();
    this.callGetGoldRates().subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.hideSpinner();
          return callback(response);
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
            return null;
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
          return null;
        }
      },
      // complete: () => console.info('complete')
    })
  } 
  saveGolRate( body: any,callback:any): void {
    this._sharedSvc.showSpinner();
    this.callSaveGoldRate(body).subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.hideSpinner();
          return callback(response);
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
            return null;
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
          return null;
        }
      },
      // complete: () => console.info('complete')
    })
  }

  async getAuthList( role:any,callback:any) {
    this._sharedSvc.showSpinner();
    this.callGetAuthList(role).subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.hideSpinner();
          return callback(response);
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
            return null;
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
          return null;
        }
      },
      // complete: () => console.info('complete')
    })
  }
  async getAuthListForRole( role:any,callback:any) {
    this._sharedSvc.showSpinner();
    this.callGetAuthListForRole(role).subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.hideSpinner();
          return callback(response);
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
            return null;
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
          return null;
        }
      },
      // complete: () => console.info('complete')
    })
  }

  async getRoleList(callback:any) {
    this._sharedSvc.showSpinner();
    this.callGetRoleList().subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.hideSpinner();
          return callback(response);
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
            return null;
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
          return null;
        }
      },
      // complete: () => console.info('complete')
    })
  }

  async getModesList(callback:any) {
    this._sharedSvc.showSpinner();
    this.callGetModesList().subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.hideSpinner();
          return callback(response);
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
            return null;
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
          return null;
        }
      },
      // complete: () => console.info('complete')
    })
  }

  async getSubModesList(callback:any) {
    this._sharedSvc.showSpinner();
    this.callGetSubModesList().subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.hideSpinner();
          return callback(response);
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
            return null;
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
          return null;
        }
      },
      // complete: () => console.info('complete')
    })
  }
}
