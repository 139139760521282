import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CookieService } from 'ngx-cookie-service';
import { Observable } from "rxjs";
import { Authentication } from "../model/login";
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { environment } from "src/app/global";
import { SharedService } from "src/app/components/shared/service/shared.service";
import { Endpoint } from "src/app/components/shared/serviceEndPoints";
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  loginModel = new Authentication();

  constructor(
    public router: Router,
    private _http: HttpClient,
    public _sharedSvc: SharedService,
    private _cookieSvc: CookieService
  ) { }


  Authentication() {
    let body = `grant_type=${'password'}&client_id=${'GoldFin'}&client_secret=${'QMmHq2U46wcvLAbkNzGvuNaUK9ZGR1I0'}&username=${this.loginModel.username}&password=${this.loginModel.password}`;

    // const headers = new HttpHeaders({
    //   'content-type': 'application/x-www-form-urlencoded',
    // });
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  };
    // const requestOptions = { headers: headers };
    return this._http.post<any>(environment.config.BASE_API_URLKL + '/realms/loanly/protocol/openid-connect/token', body, options);
  }



}

