import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CookieService } from 'ngx-cookie-service';
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { environment } from "src/app/global";
import { SaveDocs } from "../model/clientDocs";
import { SharedService } from "src/app/components/shared/service/shared.service";
import { Endpoint } from "src/app/components/shared/serviceEndPoints";

@Injectable({
  providedIn: 'root'
})

export class ClientDocsService {

  saveDocsModel = new SaveDocs();
  getDoc: any;
  cnicFUrl: any = '';
  cnicBUrl: any = '';
  userUrl: any = '';
  repUrl: any = '';
  docList: any = '';
  clientAllDocs: any;


  constructor(
    public router: Router,
    private _http: HttpClient,
    private _cookieSvc: CookieService,
    public _sharedSvc: SharedService,
  ) { }

  // APIS ClientDoc Start 
  SaveDocs() {
    this._sharedSvc.tokenHeader();
    let str = this.saveDocsModel.docImg
    this.saveDocsModel.docImg = str.split("base64,").pop()
    return this._http.post<any>(environment.config.BASE_API_URL + Endpoint.ClientDoc.SaveDocs, this.saveDocsModel, this._sharedSvc.requestOptions);
  }

  getClientDocs(clnt_doc_seq: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL + Endpoint.ClientDoc.GetClientDoc + clnt_doc_seq, this._sharedSvc.requestOptions);
  }

  getAllClientDocs(clnt_seq: any, enty_flg: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL + Endpoint.ClientDoc.GetClientDocByFlag + clnt_seq + "/" + enty_flg, this._sharedSvc.requestOptions);
  }
  getClientDocsByEntityKeyFlag(enty_seq: any, enty_flg: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL + Endpoint.ClientDoc.GetClientDocByEntetyKeyFlag + enty_seq + "/" + enty_flg, this._sharedSvc.requestOptions);
  }
  // APIS ClientDoc End 

  InItGetClientDocs(clnt_doc_seq: any): void {
    this.getClientDocs(clnt_doc_seq).subscribe({
      next: (response: any) => {
        if (response) {

        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }


  //  get_client_doc_by_entity(enty_seq: any, enty_flg: any) {
  //   this._sharedSvc.showSpinner()
  //    this.getClientDocsByEntityKeyFlag(enty_seq, enty_flg).subscribe(response => {
  //     this._sharedSvc.hideSpinner()
  //      this.docList = response;
  //     console.log(this.docList);
  //   })
  // }

  async get_client_doc_by_entity(enty_seq: any, enty_flg: any): Promise<any> {
    try {
      this._sharedSvc.showSpinner();

      // Convert the Observable to a Promise using toPromise
      const response = await this.getClientDocsByEntityKeyFlag(enty_seq, enty_flg).toPromise();

      this._sharedSvc.hideSpinner();
      this.docList = response;

      console.log(this.docList);

      return response; // You can return the response if needed
    } catch (error) {
      console.error('Error fetching client docs:', error);
      throw error; // Re-throw the error to propagate it to the calling code
    }
  }


  async getClientAllDocs(clnt_seq: any, enty_flg: any): Promise<any> {
    try {
      this._sharedSvc.showSpinner3();
      const response = await this.getAllClientDocs(clnt_seq, enty_flg).toPromise();
      this.clientAllDocs = response;
      localStorage.setItem('clientAllDocs', JSON.stringify(response))
      this._sharedSvc.hideSpinner3();
    }
    catch (error) {
      console.error('Error fetching client docs:', error);
      throw error;
    }
  }








}


