import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'src/app/components/shared/service/shared.service';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';

import { ClintOnboardingService } from 'src/app/components/clint-onboarding/shared/service/clint-onboarding.service';
import { TranslateService } from '@ngx-translate/core';
import { ProductServices } from '../../shared/service/product.service';
import { PicklistService } from 'src/app/components/shared/pickList/picklist.service';


@Component({
    selector: 'app-setup-product-groups',
    templateUrl: './setup-product-groups.component.html',
    styleUrls: ['./setup-product-groups.component.css']
})
export class SetupProductGroupsComponent implements OnInit {

    page: number = 5;
    pageSize: number = 5;
    collectionSize: number = 5;
    onBoardingTablePG: any;
    getAllProductsGroupsList:any = [];
    addProductGroupFormdata: any;
    singleRecord: any;
    isEdit: boolean = false;

    constructor(
        private modalService: NgbModal,
        public router: Router,
        public _sharedSvc: SharedService,
        public _clintOnboardingSvc: ClintOnboardingService,
        private formBuilder: FormBuilder,
        public translate: TranslateService,
        public _picklistService: PicklistService,
        public _productServices: ProductServices

    ) { }

    ngOnInit(): void {
        this.addProductGroupFormdata = this.formBuilder.group({
            prdGrpNm: ["", Validators.required],
            prdGrpSts: ["", Validators.required],
          });
        this.getAllProducsGroup();
        this._picklistService.SHROFF_STATUS();
    }

  
    get prdGrpNm() {
        return this.addProductGroupFormdata.get("prdGrpNm");
      }
    get prdGrpSts() {
        return this.addProductGroupFormdata.get("prdGrpSts");
      }


      submitProductGroup(value){
        if (this.addProductGroupFormdata.invalid) {
            for (const control of Object.keys(this.addProductGroupFormdata.controls)) {
              this.addProductGroupFormdata.controls[control].markAsTouched();
            }
            return;
          }
          let data = {
            ...this.addProductGroupFormdata.value,
            prdGrpSts:Number(this.addProductGroupFormdata.value.prdGrpSts),
            prdGrpStsKey:Number(this.addProductGroupFormdata.value.prdGrpSts),
            tntKey: 0,
            prdGrpId: '0',
            prdGrpSeq: null,
            delFlg: 0,
          };
          if (this.isEdit) {
            data.prdGrpSeq = this.singleRecord.prdGrpSeq;
          }
          this._productServices.saveProductGrpModel = data;
          this._productServices.save_product_grp().subscribe({
            next: (response) => {
              if (response) {
                this._sharedSvc.success(
                  'Product Group' +
                    (this.isEdit === true
                      ? this.translate.instant("toast.updated")
                      : this.translate.instant("toast.added"))
                );
                this._sharedSvc.hideSpinner3();
                this.modalService.dismissAll();
                this.getAllProducsGroup();
              }
            },
            error: (e) => {
              this._sharedSvc.hideSpinner3();
              if (e.status == 401) {
                this._sharedSvc.error(this._sharedSvc.authMsg);
                this._sharedSvc.logout();
                return;
              }
              if (e?.error) {
                Object.entries(e?.error).forEach(
                  ([key, value]: any, index: number) => {
                    this._sharedSvc.error(value);
                  }
                );
              }
              if (e?.error?.errorMessage) {
                this._sharedSvc.error(e?.error?.error);
              }
            },
          });




      }

    openModal(content: any , isEdit:boolean) {

        localStorage.removeItem("selectedOrderData");
        this.isEdit = isEdit;
        this.modalService.open(content, {
            // backdrop: "static",
            // keyboard: false,
            windowClass: "popupWidth1k",
            // backdropClass: 'light-blue-backdrop',
            centered: true
        });
    }
    editproductGroup(content: any ,id:any, isEdit:boolean){
        let list = this.getAllProductsGroupsList;
        let record = list.find((x) => x.prdGrpSeq === id);
        this.singleRecord = record;
    
        if (record) {
          let data = {
            prdGrpNm: record.prdGrpNm,
            prdGrpSts: record.prdGrpSts,
          };
          this.addProductGroupFormdata.patchValue(data);
          this.openModal(content, isEdit);
        }
    }

    navigate(prdSeqGrp:any): void {
        localStorage.setItem("prdGrpSeq" , prdSeqGrp);
        this.router.navigate(['/setup-products']);
    }


    getAllProducsGroup(){
        this._sharedSvc.showSpinner();
        this._productServices.get_product_grp_list().subscribe({
          next: (response: any) => {
            this._sharedSvc.hideSpinner();
            if (response) {
              if (response.error) {
                this._sharedSvc.error(response.error);
                return;
              }
              this.getAllProductsGroupsList = response
            }
          },
          error: (e) => {
            this._sharedSvc.hideSpinner();
            if (e.status == 401) {
              this._sharedSvc.error(this._sharedSvc.authMsg);
              this._sharedSvc.logout();
              return;
            }
            if (e?.error) {
              Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
                this._sharedSvc.error(value);
              });
            }
            if (e?.error?.errorMessage) {
              this._sharedSvc.error(e?.error?.error);
            }
          },
          // complete: () => console.info('complete')
        })
      }

    pageChange() {
        this.collectionSize = this._clintOnboardingSvc.onBoardingTable.length;
        this.onBoardingTablePG = this._clintOnboardingSvc.onBoardingTable.slice(
            (this.page - 1) * this.pageSize,
            (this.page - 1) * this.pageSize + this.pageSize
        );
        this.onBoardingTablePG;
    }

}