<section>
  <div class="sidebar" [class.sidebar-close]="!_sharedSvc.openSidebar">
    <app-sidebar></app-sidebar>
  </div>
  <div class="home-section">
    <div class="home-main" [class.sidebar-close_home-main]="!_sharedSvc.openSidebar">
      <app-navbar></app-navbar>
      <div class="home-view" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
        [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
        <section>
          <div class="row mb-3">
            <div class="col-sm-8 col-md-8 col-lg-8">
              <h4>
                {{ "sideBarMenu.setup.shroffManagement" | translate }}
              </h4>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4">
              <div class="add-button" (click)="openModalforShroff(shroffManagement, false)">
                <i class="fa fa-plus"></i>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="dashboard-card loan-table">
                <div class="row mb-3">
                  <div class="col-sm-7 col-md-7 col-lg-8">
                    <ng-container *ngIf="_sharedSvc.pageSize >= 10">
                      <span>{{ "common.show" | translate }}</span>
                      <input type="number" class="m-1 text-center" style="width: 45px" />
                      <span>{{ "common.entries" | translate }}</span>
                    </ng-container>
                  </div>
                  <div class="col-sm-5 col-md-5 col-lg-4">
                    <div class="inside-icon">
                      <i class="fa fa-search"></i>
                      <input type="search" class="form-control search-input"
                        placeholder="{{ 'common.search' | translate }}" />
                    </div>
                  </div>
                </div>
                <div class="table-responsive">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <td>{{ "common.id" | translate }}</td>
                        <td>{{ "common.firstName" | translate }}</td>
                        <td>{{ "common.lastName" | translate }}</td>
                        <td>{{ "common.cnic" | translate }}&nbsp;#.</td>
                        <td>{{ "common.phone" | translate }}&nbsp;#.</td>
                        <td>{{ "common.createdBy" | translate }}</td>
                        <td class="text-center">
                          {{ "common.action" | translate }}
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let shroflist of _setupService.shoroffList">
                        <td>{{ shroflist.shrfSeq }}</td>
                        <td>{{ shroflist.firstName }}</td>
                        <td>{{ shroflist.lastName }}</td>
                        <td>{{ shroflist.cnicNum }}</td>
                        <td>{{ shroflist.phNum }}</td>
                        <td>{{ shroflist.crtdBy }}</td>

                        <td class="text-center">
                          <div class="action-inner">
                            <i class="fa-solid fa-pencil" (click)="
                                editShrof(
                                  shroffManagement,
                                  shroflist.shrfSeq,
                                  true,
                                  false
                                )
                              "></i>
                            <i class="fa fa-eye" (click)="
                                editShrof(
                                  shroffManagement,
                                  shroflist.shrfSeq,
                                  true,
                                  true
                                )
                              "></i>
                            <i class="fa fa-trash" (click)="deleteRecord(shroflist.shrfSeq)"></i>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="row g-0">
                    <div class="col-sm-12 col-md-12 col-lg-6">
                      <span class="clr-9d9a9a">{{ "common.showing" | translate }}
                        {{ "common.1To3Of" | translate }} 3
                        {{ "common.entries" | translate }}</span>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6">
                      <div class="float-right">
                        <nav aria-label="Page navigation example">
                          <ul class="pagination">
                            <li class="page-item">
                              <a class="page-link">{{
                                "common.first" | translate
                                }}</a>
                            </li>
                            <li class="page-item">
                              <a class="page-link">{{
                                "common.previous" | translate
                                }}</a>
                            </li>
                            <li class="page-item">
                              <a class="page-link">1</a>
                            </li>
                            <li class="page-item">
                              <a class="page-link">2</a>
                            </li>
                            <li class="page-item">
                              <a class="page-link">3</a>
                            </li>
                            <li class="page-item">
                              <a class="page-link">4</a>
                            </li>
                            <li class="page-item">
                              <a class="page-link">5</a>
                            </li>
                            <li class="page-item">
                              <a class="page-link">{{
                                "common.next" | translate
                                }}</a>
                            </li>
                            <li class="page-item">
                              <a class="page-link">{{
                                "common.last" | translate
                                }}</a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="button_div">
                      <button class="btn login-button-class" [class.pointerNone]="_sharedSvc.loading"
                        [disabled]="_sharedSvc.loading">
                        {{ "common.continue" | translate }}
                      </button>
                      <button class="btn login-button-class secondaryBtn">
                        {{ "common.save" | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>

<ng-template id="display-modal" #shroffManagement let-c="close" let-d="dismiss">
  <div class="modal-body" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
    [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
    <div class="p-3 pb-0">
      <div class="row mb-4">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center">
            <h4>
              {{
              isEdit === true
              ? this.translate.instant("common.edit")
              : this.translate.instant("common.add")
              }}
              {{ "common.shroff" | translate }}
            </h4>
          </div>
        </div>
      </div>
      <form [formGroup]="formdata" (ngSubmit)="submitShroffValue(formdata)">
        <div class="row mb-3">
          <!-- <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
                        <div class="form-floating">
                            <input type="text" style="text-transform: capitalize;" autocomplete="off"
                                class="form-control input-radius" formControlName="shroffId"
                                minlength="3" maxlength="100"
                                [class.is-invalid]="shroffId.invalid && (shroffId.dirty || shroffId.touched)"
                                >
                                <div *ngIf="shroffId.invalid && (shroffId.dirty || shroffId.touched)"
                                class="invalid-feedback">
                                <div *ngIf="shroffId.errors?.['required']">
                                    First Name is required.
                                </div>
                                <div *ngIf="shroffId.errors?.['minlength']">
                                    This field must have at least 3
                                    characters
                                </div>
                                <div *ngIf="shroffId.errors?.['maxlength']">
                                    This field must have at most 100
                                    characters.
                                </div>
                            </div>
                            <label>ID #</label>
                        </div>
                    </div> -->
          <!-- <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
                    </div> -->
          <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div class="form-floating">
              <input type="text" style="text-transform: capitalize" autocomplete="off" class="form-control input-radius"
                formControlName="firstName" minlength="3" maxlength="100" [class.is-invalid]="
                  firstName.invalid && (firstName.dirty || firstName.touched)
                " />
              <div *ngIf="
                  firstName.invalid && (firstName.dirty || firstName.touched)
                " class="invalid-feedback">
                <div *ngIf="firstName.errors?.['required']">
                  {{ "common.firstNameRequire" | translate }}
                </div>
                <div *ngIf="firstName.errors?.['minlength']">
                  {{ "common.fieldMustHaveLeastThreeLetters" | translate }}
                </div>
                <div *ngIf="firstName.errors?.['maxlength']">
                  {{ "common.fieldMustHaveAtMostHundredLetters" | translate }}
                </div>
              </div>
              <label>{{ "common.firstName" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div class="form-floating">
              <input type="text" style="text-transform: capitalize" autocomplete="off" class="form-control input-radius"
                formControlName="lastName" minlength="3" maxlength="100" [class.is-invalid]="
                  lastName.invalid && (lastName.dirty || lastName.touched)
                " />
              <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)" class="invalid-feedback">
                <div *ngIf="lastName.errors?.['required']">
                  {{ "common.lastNameRequire" | translate }}
                </div>
                <div *ngIf="lastName.errors?.['minlength']">
                  {{ "common.fieldMustHaveLeastThreeLetters" | translate }}
                </div>
                <div *ngIf="lastName.errors?.['maxlength']">
                  {{ "common.fieldMustHaveAtMostHundredLetters" | translate }}
                </div>
              </div>
              <label>{{ "common.lastName" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div class="form-floating">
              <input type="text" style="text-transform: capitalize" autocomplete="off" class="form-control input-radius"
                formControlName="cnicNum" mask="9999-9999999-9" [class.is-invalid]="
                  cnicNum.invalid && (cnicNum.dirty || cnicNum.touched)
                " />
              <div *ngIf="cnicNum.invalid && (cnicNum.dirty || cnicNum.touched)" class="invalid-feedback">
                <div *ngIf="cnicNum.errors?.['required']">
                  CNIC {{ "common.isRequired" | translate }}.
                </div>
                <!-- <div *ngIf="cnic.errors?.['minLength']">
                                    This field must have at least 3
                                    characters
                                </div>
                                <div *ngIf="cnic.errors?.['maxLength']">
                                    This field must have at most 100
                                    characters.
                                </div> -->
              </div>
              <label>CNIC#</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div class="form-floating">
              <input type="date" style="text-transform: capitalize" autocomplete="off" class="form-control input-radius"
                formControlName="cnicIssueDt" [class.is-invalid]="
                  cnicIssueDt.invalid &&
                  (cnicIssueDt.dirty || cnicIssueDt.touched)
                " />
              <div *ngIf="
                  cnicIssueDt.invalid &&
                  (cnicIssueDt.dirty || cnicIssueDt.touched)
                " class="invalid-feedback">
                <div *ngIf="cnicIssueDt.errors?.['required']">
                  {{ "shroffManagement.cnicIssueDateRequire" | translate }}
                </div>
              </div>
              <label>{{ "shroffManagement.cnicIssueDate" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div class="form-floating">
              <input type="date" style="text-transform: capitalize" autocomplete="off" class="form-control input-radius"
                formControlName="cnicExpryDt" [class.is-invalid]="
                  cnicExpryDt.invalid &&
                  (cnicExpryDt.dirty || cnicExpryDt.touched)
                " />
              <div *ngIf="
                  cnicExpryDt.invalid &&
                  (cnicExpryDt.dirty || cnicExpryDt.touched)
                " class="invalid-feedback">
                <div *ngIf="cnicExpryDt.errors?.['required']">
                  {{ "shroffManagement.cnicExpiryDateRequire" | translate }}
                </div>
              </div>
              <label>{{ "shroffManagement.cnicExpiryDate" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div class="form-floating">
              <input type="date" style="text-transform: capitalize" autocomplete="off" class="form-control input-radius"
                formControlName="dob" [class.is-invalid]="dob.invalid && (dob.dirty || dob.touched)" />
              <div *ngIf="dob.invalid && (dob.dirty || dob.touched)" class="invalid-feedback">
                <div *ngIf="dob.errors?.['required']">
                  {{ "common.dateOfBirthRequire" | translate }}
                </div>
              </div>
              <label>{{ "common.dateOfBirth" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div class="form-floating">
              <select name="gndrKey" id="gndrKey" class="form-control input-radius form-select" formControlName="stsKey"
                [class.is-invalid]="
                  stsKey.invalid && (stsKey.dirty || stsKey.touched)
                ">
                <option *ngFor="
                    let item of _picklistService.SHROFF_STATUS_Data;
                    let i = index
                  " [value]="item?.refCdSeq">
                  {{ item.refCdDscr }}
                </option>
              </select>

              <div *ngIf="stsKey.invalid && (stsKey.dirty || stsKey.touched)" class="invalid-feedback">
                <div *ngIf="stsKey.errors?.['required']">
                  {{ "common.statusRequire" | translate }}
                </div>
              </div>
              <label>{{ "common.status" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div class="form-floating">
              <select name="gndrKey" id="gndrKey" class="form-control input-radius form-select"
                formControlName="gndrKey" [class.is-invalid]="
                  gndrKey.invalid && (gndrKey.dirty || gndrKey.touched)
                ">
                <option *ngFor="
                    let item of _picklistService.GENDER_Data;
                    let i = index
                  " [value]="item?.refCdSeq">
                  {{ item.refCdDscr }}
                </option>
              </select>
              <div *ngIf="gndrKey.invalid && (gndrKey.dirty || gndrKey.touched)" class="invalid-feedback">
                <div *ngIf="gndrKey.errors?.['required']">
                  {{ "common.clientGenderRequire" | translate }}
                </div>
              </div>
              <label for="gndrKey">{{
                "common.clientGender" | translate
                }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div class="form-floating">
              <input type="text" style="text-transform: capitalize" autocomplete="off" class="form-control input-radius"
                formControlName="phNum" mask="9999-9999999" [class.is-invalid]="
                  phone.invalid && (phone.dirty || phone.touched)
                " />
              <div *ngIf="phone.invalid && (phone.dirty || phone.touched)" class="invalid-feedback">
                <div *ngIf="phone.errors?.['required']">
                  {{ "common.phoneRequire" | translate }}
                </div>
              </div>
              <label>{{ "common.phone" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
            <div class="form-floating">
              <input type="text" style="text-transform: capitalize" autocomplete="off" class="form-control input-radius"
                formControlName="shrofAddr" minlength="3" maxlength="100" [class.is-invalid]="
                  permAddress.invalid &&
                  (permAddress.dirty || permAddress.touched)
                " />
              <div *ngIf="
                  permAddress.invalid &&
                  (permAddress.dirty || permAddress.touched)
                " class="invalid-feedback">
                <div *ngIf="permAddress.errors?.['required']">
                  {{ "shroffManagement.permanentAddressRequire" | translate }}
                </div>
                <div *ngIf="permAddress.errors?.['minlength']">
                  {{ "common.fieldMustHaveLeastThreeLetters" | translate }}
                </div>
                <div *ngIf="permAddress.errors?.['maxlength']">
                  {{ "common.fieldMustHaveAtMostHundredLetters" | translate }}
                </div>
              </div>
              <label>{{
                "clientOnboarding.permanentAddress" | translate
                }}</label>
            </div>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-4 mb-3">
            <div class="inside-icon pointer" [class.is-invalid]="
                cnicFrontField.invalid &&
                (cnicFrontField.dirty || cnicFrontField.touched)
              " (click)="disabledAll === false && compressFile('cnicFront')">
              <label>{{ "common.browse" | translate }}</label>
              <input *ngIf="!cnicFronts" type="search" [placeholder]="'shroffManagement.uploadCnicFront' | translate"
                readonly class="form-control search-input" formControlName="cnicFront" [class.is-invalid]="
                  cnicFrontField.invalid &&
                  (cnicFrontField.dirty || cnicFrontField.touched)
                " style="padding: 20px; font-size: 11px" />
              <input *ngIf="cnicFronts" readonly formControlName="cnicFront" [class.is-invalid]="
                  cnicFrontField.invalid &&
                  (cnicFrontField.dirty || cnicFrontField.touched)
                " class="form-control search-input" style="padding: 20px; font-size: 11px" />
              <div class="inside-icon-small">
                <img [src]="cnicFronts ? cnicFronts : ''" />
              </div>
            </div>
            <div *ngIf="cnicFronts" [class.is-invalid]="
                cnicFrontField.invalid &&
                (cnicFrontField.dirty || cnicFrontField.touched)
              ">
              <div class="text-center pointer mt-2">
                <span (click)="openModal(cnicFront)">{{ "common.showImage" | translate }}
                  <i class="fa fa-angle-down"></i></span>
                <!-- <span (click)="d('Cross click')" *ngIf="shoImg">Hide Image <i
                                        class="fa fa-angle-up"></i></span> -->
              </div>
            </div>

            <div *ngIf="
                cnicFrontField.invalid &&
                (cnicFrontField.dirty || cnicFrontField.touched)
              " class="invalid-feedback">
              <div *ngIf="cnicFrontField.errors?.['required']">
                {{ "shroffManagement.uploadCnicFrontRequire" | translate }}
              </div>
            </div>
          </div>
          <!-- <div class="col-sm-6 col-md-6 col-lg-4 mb-3 ">
                        <div class="inside-icon pointer">
                            <label>Browse</label>
                            <input type="search" placeholder="Upload CNIC Front" readonly
                                class="form-control search-input" style="padding: 20px; font-size: 11px;">

                            <div class="inside-icon-small">
                                <img [src]="cnicFront ? cnicFront : ''" />
                            </div>
                        </div>
                    </div> -->
          <div class="col-sm-6 col-md-6 col-lg-4 mb-3">
            <div class="inside-icon pointer" [class.is-invalid]="
                cnicBackField.invalid &&
                (cnicBackField.dirty || cnicBackField.touched)
              " (click)="disabledAll === false && compressFile('cnicBack')">
              <label>{{ "common.browse" | translate }}</label>
              <input *ngIf="!cnicBacks" type="search" [placeholder]="'shroffManagement.uploadCnicBack' | translate"
                readonly class="form-control search-input" formControlName="cnicBack" [class.is-invalid]="
                  cnicBackField.invalid &&
                  (cnicBackField.dirty || cnicBackField.touched)
                " style="padding: 20px; font-size: 11px" />
              <input *ngIf="cnicBacks" readonly formControlName="cnicBack" [class.is-invalid]="
                  cnicBackField.invalid &&
                  (cnicBackField.dirty || cnicBackField.touched)
                " class="form-control search-input" style="padding: 20px; font-size: 11px" />
              <div class="inside-icon-small">
                <img [src]="cnicBacks ? cnicBacks : ''" />
              </div>
            </div>
            <div *ngIf="cnicBacks" [class.is-invalid]="
                cnicBackField.invalid &&
                (cnicBackField.dirty || cnicBackField.touched)
              ">
              <div class="text-center pointer mt-2">
                <span (click)="openModal(cnicBack)">{{ "common.showImage" | translate }}
                  <i class="fa fa-angle-down"></i></span>
                <!-- <span (click)="d('Cross click')" *ngIf="shoImg">Hide Image <i
                                            class="fa fa-angle-up"></i></span> -->
              </div>
            </div>

            <div *ngIf="
                cnicBackField.invalid &&
                (cnicBackField.dirty || cnicBackField.touched)
              " class="invalid-feedback">
              <div *ngIf="cnicBackField.errors?.['required']">
                {{ "shroffManagement.uploadCnicBackRequire" | translate }}
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-4 mb-3">
            <div class="inside-icon pointer" [class.is-invalid]="
                certificatesField.invalid &&
                (certificatesField.dirty || certificatesField.touched)
              " (click)="disabledAll === false && compressFile('certificate')">
              <label>{{ "common.browse" | translate }}</label>
              <input *ngIf="!certificates" type="search"
                [placeholder]="'shroffManagement.uploadCertificate' | translate" readonly
                class="form-control search-input" formControlName="certificate" [class.is-invalid]="
                  certificatesField.invalid &&
                  (certificatesField.dirty || certificatesField.touched)
                " style="padding: 20px; font-size: 11px" />
              <input *ngIf="certificates" readonly formControlName="certificate" [class.is-invalid]="
                  certificatesField.invalid &&
                  (certificatesField.dirty || certificatesField.touched)
                " class="form-control search-input" style="padding: 20px; font-size: 11px" />
              <div class="inside-icon-small">
                <img [src]="certificates ? certificates : ''" />
              </div>
            </div>
            <div *ngIf="certificates" [class.is-invalid]="
                certificatesField.invalid &&
                (certificatesField.dirty || certificatesField.touched)
              ">
              <div class="text-center pointer mt-2">
                <span (click)="openModal(certificate)">{{ "common.showImage" | translate }}
                  <i class="fa fa-angle-down"></i></span>
                <!-- <span (click)="d('Cross click')" *ngIf="shoImg">Hide Image <i
                                            class="fa fa-angle-up"></i></span> -->
              </div>
            </div>
            <div *ngIf="
                certificatesField.invalid &&
                (certificatesField.dirty || certificatesField.touched)
              " class="invalid-feedback">
              <div *ngIf="certificatesField.errors?.['required']">
                {{ "shroffManagement.uploadCertificateRequire" | translate }}
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-4 mb-3">
            <div class="inside-icon pointer" [class.is-invalid]="
                verficationsField.invalid &&
                (verficationsField.dirty || verficationsField.touched)
              " (click)="disabledAll === false && compressFile('verification')">
              <label>{{ "common.browse" | translate }}</label>
              <input *ngIf="!verfications" type="search" [placeholder]="
                  'shroffManagement.uploadVerification' | translate
                " readonly class="form-control search-input" formControlName="verification" [class.is-invalid]="
                  verficationsField.invalid &&
                  (verficationsField.dirty || verficationsField.touched)
                " style="padding: 20px; font-size: 11px" />
              <input *ngIf="verfications" readonly formControlName="verification" [class.is-invalid]="
                  verficationsField.invalid &&
                  (verficationsField.dirty || verficationsField.touched)
                " class="form-control search-input" style="padding: 20px; font-size: 11px" />
              <div class="inside-icon-small">
                <img [src]="verfications ? verfications : ''" />
              </div>
            </div>
            <div *ngIf="verfications" [class.is-invalid]="
                verficationsField.invalid &&
                (verficationsField.dirty || verficationsField.touched)
              ">
              <div class="text-center pointer mt-2">
                <span (click)="openModal(verification)">{{ "common.showImage" | translate }}
                  <i class="fa fa-angle-down"></i></span>
                <!-- <span (click)="d('Cross click')" *ngIf="shoImg">Hide Image <i
                                            class="fa fa-angle-up"></i></span> -->
              </div>
            </div>
            <div *ngIf="
                verficationsField.invalid &&
                (verficationsField.dirty || verficationsField.touched)
              " class="invalid-feedback">
              <div *ngIf="verficationsField.errors?.['required']">
                {{ "shroffManagement.uploadVerificationRequire" | translate }}
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-4 mb-3">
            <div class="inside-icon pointer" [class.is-invalid]="
                agreementField.invalid &&
                (agreementField.dirty || agreementField.touched)
              " (click)="disabledAll === false && compressFile('agreement')">
              <label>{{ "common.browse" | translate }}</label>
              <input *ngIf="!agreements" type="search" [placeholder]="'shroffManagement.uploadAgreement' | translate"
                readonly class="form-control search-input" formControlName="agreement" [class.is-invalid]="
                  agreementField.invalid &&
                  (agreementField.dirty || agreementField.touched)
                " style="padding: 20px; font-size: 11px" />
              <input *ngIf="agreements" readonly formControlName="agreement" [class.is-invalid]="
                  agreementField.invalid &&
                  (agreementField.dirty || agreementField.touched)
                " class="form-control search-input" style="padding: 20px; font-size: 11px" />
              <div class="inside-icon-small">
                <img [src]="agreements ? agreements : ''" />
              </div>
            </div>
            <div *ngIf="agreements" [class.is-invalid]="
                agreementField.invalid &&
                (agreementField.dirty || agreementField.touched)
              ">
              <div class="text-center pointer mt-2">
                <span (click)="openModal(agreement)">{{ "common.showImage" | translate }}
                  <i class="fa fa-angle-down"></i></span>
                <!-- <span (click)="d('Cross click')" *ngIf="shoImg">Hide Image <i
                                            class="fa fa-angle-up"></i></span> -->
              </div>
            </div>
            <div *ngIf="
                agreementField.invalid &&
                (agreementField.dirty || agreementField.touched)
              " class="invalid-feedback">
              <div *ngIf="agreementField.errors?.['required']">
                {{ "shroffManagement.uploadAgreementRequire" | translate }}
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-4 mb-3">
            <div class="inside-icon pointer" [class.is-invalid]="
                addressField.invalid &&
                (addressField.dirty || addressField.touched)
              " (click)="disabledAll === false && compressFile('address')">
              <label>{{ "common.browse" | translate }}</label>
              <input *ngIf="!addresss" type="search" [placeholder]="'shroffManagement.uploadAddress' | translate"
                readonly class="form-control search-input" formControlName="addresss" [class.is-invalid]="
                  addressField.invalid &&
                  (addressField.dirty || addressField.touched)
                " style="padding: 20px; font-size: 11px" />
              <input *ngIf="addresss" readonly formControlName="addresss" [class.is-invalid]="
                  addressField.invalid &&
                  (addressField.dirty || addressField.touched)
                " class="form-control search-input" style="padding: 20px; font-size: 11px" />
              <div class="inside-icon-small">
                <img [src]="addresss ? addresss : ''" />
              </div>
            </div>
            <div *ngIf="addresss" [class.is-invalid]="
                addressField.invalid &&
                (addressField.dirty || addressField.touched)
              ">
              <div class="text-center pointer mt-2">
                <span (click)="openModal(addresss)">{{ "common.showImage" | translate }}
                  <i class="fa fa-angle-down"></i></span>
                <!-- <span (click)="d('Cross click')" *ngIf="shoImg">Hide Image <i
                                            class="fa fa-angle-up"></i></span> -->
              </div>
            </div>
            <div *ngIf="
                addressField.invalid &&
                (addressField.dirty || addressField.touched)
              " class="invalid-feedback">
              <div *ngIf="addressField.errors?.['required']">
                {{ "shroffManagement.uploadAddressRequire" | translate }}
              </div>
            </div>
          </div>
          <!-- <div class="col-sm-6 col-md-6 col-lg-4 mb-3 ">
                        <div class="inside-icon pointer">
                            <label>Browse</label>
                            <input type="search" placeholder="Upload verfication" readonly
                                class="form-control search-input" style="padding: 20px; font-size: 11px;">

                            <div class="inside-icon-small">
                                <img [src]="verfication ? verfication : ''" />
                            </div>
                        </div>
                    </div> -->
          <!-- <div class="col-sm-6 col-md-6 col-lg-4 mb-3 ">
                        <div class="inside-icon pointer">
                            <label>Browse</label>
                            <input type="search" placeholder="Upload Agreement" readonly
                                class="form-control search-input" style="padding: 20px; font-size: 11px;">

                            <div class="inside-icon-small">
                                <img [src]="agreement ? agreement : ''" />
                            </div>
                        </div>
                    </div> -->
          <!-- <div class="col-sm-6 col-md-6 col-lg-4 mb-3 ">
                        <div class="inside-icon pointer">
                            <label>Browse</label>
                            <input type="search" placeholder="Upload Shop Address" readonly
                                class="form-control search-input" style="padding: 20px; font-size: 11px;">

                            <div class="inside-icon-small">
                                <img [src]="agreement ? agreement : ''" />
                            </div>
                        </div>
                    </div> -->

          <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
            <div class="form-floating">
              <input type="text" style="text-transform: capitalize" autocomplete="off" formControlName="bizName"
                minlength="3" maxlength="100" class="form-control input-radius" minlength="3" maxlength="100"
                [class.is-invalid]="
                  shopName.invalid && (shopName.dirty || shopName.touched)
                " />
              <div *ngIf="shopName.invalid && (shopName.dirty || shopName.touched)" class="invalid-feedback">
                <div *ngIf="shopName.errors?.['required']">
                  {{ "shroffManagement.shopNameRequire" | translate }}
                </div>
              </div>
              <label>{{ "shroffManagement.shopName" | translate }}</label>
            </div>
          </div>

          <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
            <div class="form-floating">
              <input type="text" style="text-transform: capitalize" autocomplete="off" formControlName="bizPhonNumber"
                mask="9999-9999999" class="form-control input-radius" minlength="3" maxlength="100" [class.is-invalid]="
                  shopNumber.invalid && (shopNumber.dirty || shopNumber.touched)
                " />

              <div *ngIf="
                  shopNumber.invalid && (shopNumber.dirty || shopNumber.touched)
                " class="invalid-feedback">
                <div *ngIf="shopNumber.errors?.['required']">
                  {{ "shroffManagement.shopPhoneRequire" | translate }}
                </div>
              </div>
              <label>{{ "shroffManagement.shopName" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
            <div class="form-floating">
              <input type="text" style="text-transform: capitalize" autocomplete="off" formControlName="bizAddress"
                class="form-control input-radius" minlength="3" maxlength="100" [class.is-invalid]="
                  shopAddress.invalid &&
                  (shopAddress.dirty || shopAddress.touched)
                " />
              <div *ngIf="
                  shopAddress.invalid &&
                  (shopAddress.dirty || shopAddress.touched)
                " class="invalid-feedback">
                <div *ngIf="shopAddress.errors?.['required']">
                  {{ "shroffManagement.shopAddressRequire" | translate }}
                </div>
              </div>
              <label>{{ "shroffManagement.shopName" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
            <div class="form-floating">
              <input type="text" style="text-transform: capitalize" autocomplete="off" class="form-control input-radius"
                formControlName="cmnt" minlength="3" maxlength="100" [class.is-invalid]="
                  shopCmnt.invalid && (shopCmnt.dirty || shopCmnt.touched)
                " />

              <div *ngIf="shopCmnt.invalid && (shopCmnt.dirty || shopCmnt.touched)" class="invalid-feedback">
                <div *ngIf="shopCmnt.errors?.['required']">
                  {{ "shroffManagement.shopCommentsRequire" | translate }}
                </div>
              </div>
              <label>{{ "shroffManagement.shopName" | translate }}</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="button_div">
              <button *ngIf="disabledAll === false" class="btn login-button-class"
                [class.pointerNone]="_sharedSvc.loading" [disabled]="_sharedSvc.loading">
                {{ "common.ok" | translate }}
              </button>
              <button class="btn login-button-class cancelBtn" (click)="d('Cross click')" type="reset">
                {{ "common.cancel" | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<!-- <ng-template id="display-modal" #shroffShow let-c="close" let-d="dismiss">
    <div class="modal-body">
        <div class="p-3 pb-0">
            <div class="row mb-4">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="text-center">
                        <h4>Shroff Detail</h4>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="d-flex align-items-center mb-2">
                        <p><b>First Name:</b></p>
                        <p class="ms-3">{{singleRecord.firstName}}</p>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="d-flex align-items-center mb-2">
                        <p><b>Last Name</b></p>
                        <p class="ms-3">{{singleRecord.lastName}}</p>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="d-flex align-items-center mb-2">
                        <p><b>CNIC#</b></p>
                        <p class="ms-3">{{singleRecord.cnicNum}}</p>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="d-flex align-items-center mb-2">
                        <p><b>CNIC Issuance Date</b></p>
                        <p class="ms-3">{{singleRecord.cnicIssueDt}}</p>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="d-flex align-items-center mb-2">
                        <p><b>CNIC Expire Date</b></p>
                        <p class="ms-3">{{singleRecord.cnicExpryDt}}</p>
                    </div>

                </div>
                <div class="col-md-12">
                    <div class="d-flex align-items-center mb-2">
                        <p><b>Date of Birth</b></p>
                        <p class="ms-3">{{singleRecord.dob}}</p>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="d-flex align-items-center mb-2">
                        <p><b>Status</b></p>
                        <p class="ms-3">{{getStatus(singleRecord.stsKey)}}</p>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="d-flex align-items-center mb-2">
                        <p><b>Client Gender</b></p>
                        <p class="ms-3">{{getShrofGender(singleRecord.gndrKey)}}</p>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="d-flex align-items-center mb-2">
                        <p><b>Phone</b></p>
                        <p class="ms-3">{{singleRecord.phNum}}</p>
                    </div>
                </div>
                <div *ngIf="!!getShrofDocs().cnicFront" class="col-md-4">
                    <div class="d-flex align-items-center mb-2">
                        <p><b>CNIC Front</b></p>
                        <div class="ms-3">
                            <img [src]="'data:image/png;base64,' + getShrofDocs().cnicFront" width="70"
                                alt="CNIC Front" />
                        </div>
                    </div>
                </div>
                <div *ngIf="!!getShrofDocs().cnicBack" class="col-md-4">
                    <div class="d-flex align-items-center mb-2">
                        <p><b>CNIC Back</b></p>
                        <div class="ms-3">
                            <img [src]="'data:image/png;base64,' + getShrofDocs().cnicBack" width="70"
                                alt="CNIC Front" />
                        </div>
                    </div>
                </div>
                <div *ngIf="!!getShrofDocs().certificate" class="col-md-4">
                    <div class="d-flex align-items-center mb-2">
                        <p><b>Certificate</b></p>
                        <div class="ms-3">
                            <img [src]="'data:image/png;base64,' + getShrofDocs().certificate" width="70"
                                alt="CNIC Front" />
                        </div>
                    </div>
                </div>
                <div *ngIf="!!getShrofDocs().verification" class="col-md-4">
                    <div class="d-flex align-items-center mb-2">
                        <p><b>Verification</b></p>
                        <div class="ms-3">
                            <img [src]="'data:image/png;base64,' + getShrofDocs().verification" width="70"
                                alt="CNIC Front" />
                        </div>
                    </div>
                </div>
                <div *ngIf="getShrofDocs().agreement" class="col-md-4">
                    <div class="d-flex align-items-center mb-2">
                        <p><b>Agreement</b></p>
                        <div class="ms-3">
                            <img [src]="'data:image/png;base64,' + getShrofDocs().agreement" width="70"
                                alt="CNIC Front" />
                        </div>
                    </div>
                </div>
                <div *ngIf="getShrofDocs().addresss" class="col-md-4">
                    <div class="d-flex align-items-center mb-2">
                        <p><b>Addresss</b></p>
                        <div class="ms-3">
                            <img [src]="'data:image/png;base64,' + getShrofDocs().addresss" width="70"
                                alt="CNIC Front" />
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="d-flex align-items-center mb-2">
                        <p><b>Shop Name</b></p>
                        <p class="ms-3">{{singleRecord.bizName}}</p>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="d-flex align-items-center mb-2">
                        <p><b>Shope Phone</b></p>
                        <p class="ms-3">{{singleRecord.bizPhonNumber}}</p>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="d-flex align-items-center mb-2">
                        <p><b> Ahop Address</b></p>
                        <p class="ms-3">{{singleRecord.bizAddress}}</p>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="d-flex align-items-center mb-2">
                        <p><b>Shop Comments</b></p>
                        <p class="ms-3">{{singleRecord.cmnt}}</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="button_div">
                        <button class="btn login-button-class cancelBtn" (click)="d('Cross click')"
                            type="reset">CLOSE</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template> -->
<ng-template id="display-modal" #cnicFront let-c="close" let-d="dismiss">
  <div class="p-3 pb-0" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
    [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
    <div class="modal-header">
      <h4>CNIC {{ "common.front" | translate }}</h4>
      <div class="header_cross" (click)="d('Cross click')">
        <h2>&times;</h2>
      </div>
    </div>
    <div class="modal-body">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center credit_pic_siz">
            <img [src]="cnicFronts ? cnicFronts : ''" />
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template id="display-modal" #cnicBack let-c="close" let-d="dismiss">
  <div class="p-3 pb-0" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
    [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
    <div class="modal-header">
      <h4>CNIC {{ "common.back" | translate }}</h4>
      <div class="header_cross" (click)="d('Cross click')">
        <h2>&times;</h2>
      </div>
    </div>
    <div class="modal-body">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center credit_pic_siz">
            <img [src]="cnicBacks ? cnicBacks : ''" />
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template id="display-modal" #certificate let-c="close" let-d="dismiss">
  <div class="p-3 pb-0" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
    [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
    <div class="modal-header">
      <h4>{{ "common.certificate" | translate }}</h4>
      <div class="header_cross" (click)="d('Cross click')">
        <h2>&times;</h2>
      </div>
    </div>
    <div class="modal-body">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center credit_pic_siz">
            <img [src]="certificates ? certificates : ''" />
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template id="display-modal" #verification let-c="close" let-d="dismiss">
  <div class="p-3 pb-0" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
    [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
    <div class="modal-header">
      <h4>{{ "common.verification" | translate }}</h4>
      <div class="header_cross" (click)="d('Cross click')">
        <h2>&times;</h2>
      </div>
    </div>
    <div class="modal-body">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center credit_pic_siz">
            <img [src]="verfications ? verfications : ''" />
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template id="display-modal" #agreement let-c="close" let-d="dismiss">
  <div class="p-3 pb-0" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
    [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
    <div class="modal-header">
      <h4>{{ "common.agreement" | translate }}</h4>
      <div class="header_cross" (click)="d('Cross click')">
        <h2>&times;</h2>
      </div>
    </div>
    <div class="modal-body">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center credit_pic_siz">
            <img [src]="agreements ? agreements : ''" />
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template id="display-modal" #address let-c="close" let-d="dismiss">
  <div class="p-3 pb-0" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
    [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
    <div class="modal-header">
      <h4>{{ "common.address" | translate }}</h4>
      <div class="header_cross" (click)="d('Cross click')">
        <h2>&times;</h2>
      </div>
    </div>
    <div class="modal-body">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center credit_pic_siz">
            <img [src]="addresss ? addresss : ''" />
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>