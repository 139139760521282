import { Component, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SharedService } from "src/app/components/shared/service/shared.service";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
  NgForm,
  ValidatorFn,
  ValidationErrors,
} from "@angular/forms";

import { ClintOnboardingService } from "src/app/components/clint-onboarding/shared/service/clint-onboarding.service";
import { SetupService } from "../../shared/service/setup.service";
import { PicklistService } from "src/app/components/shared/pickList/picklist.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-business-activity",
  templateUrl: "./business-activity.component.html",
  styleUrls: ["./business-activity.component.css"],
})
export class BusinessActivityComponent implements OnInit {
  loadingIndicator: boolean = false;
  rows: any;

  page: number = 5;
  pageSize: number = 5;
  collectionSize: number = 5;

  loginForm!: FormGroup;
  submitted = false;
  // currentDate: any = new Date().toISOString().split("T")[0];
  onBoardingTablePG: any;
  maskedCNIC: string;
  isEdit: boolean;
  singleRecord: any;
  bizSecID: any;
  addBizActyformData: any;

  constructor(
    private modalService: NgbModal,
    public router: Router,
    public _sharedSvc: SharedService,
    public _clintOnboardingSvc: ClintOnboardingService,
    public _SetupService: SetupService,
    private formBuilder: FormBuilder,
    public _picklistService: PicklistService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.createBizSecActyForm();
    this.bizSecID = sessionStorage.getItem("singleBizSecID");
    this._SetupService.get_biz_acty_list(this.bizSecID);
    this._picklistService.WORK_STATUS();
  }
  createBizSecActyForm() {
    this.addBizActyformData = this.formBuilder.group({
      bizActyNm: ["", Validators.required],
      bizActySortOrder: ["", [Validators.required, this.isSortOrderExist()]],
      bizActyStsKey: ["", Validators.required],
    });
  }
  get bizActyNm() {
    return this.addBizActyformData.get("bizActyNm");
  }
  get bizActySortOrder() {
    return this.addBizActyformData.get("bizActySortOrder");
  }
  get bizActyStsKey() {
    return this.addBizActyformData.get("bizActyStsKey");
  }

  isSortOrderExist(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value || value == this.singleRecord?.bizActySortOrder) {
        return null;
      }
      let list = this._SetupService.businessActyList;
      let record = list?.find((x) => x.bizActySortOrder === value);
      return record ? { isSortOrderExist: true } : null;
    };
  }
  submitBizActyForm(val) {
    if (this.addBizActyformData.invalid) {
      for (const control of Object.keys(this.addBizActyformData.controls)) {
        this.addBizActyformData.controls[control].markAsTouched();
      }
      return;
    }
    let data = {
      tntKey: 1,
      ...this.addBizActyformData.value,
      bizSectSeq: this.bizSecID,
      bizActySeq: null,
      delFlg: 0,
    };
    if (this.isEdit) {
      data.bizActySeq = this.singleRecord.bizActySeq;
    }
    this._SetupService.setupBizActivityModel = data;
    this._sharedSvc.showSpinner3();
    this._SetupService.saveBizActivity().subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.success(
            this.translate.instant("toast.activity") +
              (this.isEdit === true
                ? this.translate.instant("toast.updated")
                : this.translate.instant("toast.added"))
          );
          this._sharedSvc.hideSpinner3();
          this.modalService.dismissAll();
          this.addBizActyformData.reset();
          this._SetupService.get_biz_acty_list(this.bizSecID);
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner3();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(
            ([key, value]: any, index: number) => {
              this._sharedSvc.error(value);
            }
          );
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    });
  }

  editBizActy(content: any, id: any, edit: any) {
    let list = this._SetupService.businessActyList;
    let record = list.find((x) => x.bizActySeq === id);
    this.singleRecord = record;
    if (record) {
      let data = {
        bizActyNm: record.bizActyNm,
        bizActySortOrder: record.bizActySortOrder,
        bizActyStsKey: record.bizActyStsKey,
      };
      this.addBizActyformData.patchValue(data);
      this.openModal(content, edit);
    }
  }

  deleteRecord(id) {
    let list = this._SetupService.businessActyList;
    let record = list.find((x) => x.bizActySeq === id);
    this.singleRecord = record;

    if (record) {
      let data = {
        ...record,
        delFlg: 1,
      };
      this._sharedSvc.showSpinner3();
      this._SetupService.setupBizActivityModel = data;
      this._SetupService.saveBizActivity().subscribe({
        next: (response) => {
          if (response) {
            this._sharedSvc.success(
              this.translate.instant("toast.activityDeleted")
            );
            this._sharedSvc.hideSpinner3();
            this.modalService.dismissAll();
            this._SetupService.get_biz_acty_list(this.bizSecID);
          }
        },
        error: (e) => {
          this._sharedSvc.hideSpinner3();
          if (e.status == 401) {
            this._sharedSvc.error(this._sharedSvc.authMsg);
            this._sharedSvc.logout();
            return;
          }
          if (e?.error) {
            Object.entries(e?.error).forEach(
              ([key, value]: any, index: number) => {
                this._sharedSvc.error(value);
              }
            );
          }
          if (e?.error?.errorMessage) {
            this._sharedSvc.error(e?.error?.error);
          }
        },
      });
    }
  }
  pageChange() {
    this.collectionSize = this._clintOnboardingSvc.onBoardingTable.length;
    this.onBoardingTablePG = this._clintOnboardingSvc.onBoardingTable.slice(
      (this.page - 1) * this.pageSize,
      (this.page - 1) * this.pageSize + this.pageSize
    );
    this.onBoardingTablePG;
  }

  openModal(content: any, edit: boolean) {
    localStorage.removeItem("selectedOrderData");
    this.isEdit = edit;
    this.modalService.open(content, {
      // backdrop: "static",
      // keyboard: false,
      windowClass: "popupWidth800",
      // backdropClass: 'light-blue-backdrop',
      centered: true,
    });
  }

  bizSectNav() {
    this.router.navigate(["/setup-business-sector"]);
  }
  navigate() {
    this.router.navigate(["/disbursement-form"]);
  }
}
