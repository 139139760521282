<section>
    <div class="sidebar" [class.sidebar-close]="!_sharedSvc.openSidebar">
        <app-sidebar></app-sidebar>
    </div>
    <div class="home-section">
        <div class="home-main" [class.sidebar-close_home-main]="!_sharedSvc.openSidebar">
            <app-navbar></app-navbar>

            <div class="home-view" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
                [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
                <section>
                    <div class="row mb-3">
                        <div class="col-sm-8 col-md-8 col-lg-8">
                            <h4>{{'expiringCnic.expiring' | translate}} CNIC</h4>
                        </div>
                        <!-- <div class="col-sm-4 col-md-4 col-lg-4">
                            <div class="add-button" (click)="openModal()"><i class="fa fa-plus"></i></div>
                        </div> -->
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <div class="dashboard-card loan-table">
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <td>{{'common.clientName' | translate}}</td>
                                                <td>{{'expiringCnic.clientExpiryDate' | translate}}</td>
                                                <td> {{'expiringCnic.clientFront' | translate}}</td>
                                                <td>{{'expiringCnic.clientBack' | translate}}</td>
                                                <td>{{'common.status' | translate}}</td>
                                                <td>{{'common.comments' | translate}}</td>
                                                <td class="text-center">{{'common.action' | translate}}</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1ABC</td>
                                                <td>1ABC</td>
                                                <td>1ABC</td>
                                                <td>1ABC</td>
                                                <td>1ABC</td>
                                                <td>1ABC</td>
                                                <td class="text-center">
                                                    <div class="action-inner">

                                                        <i class="fa fa-calendar"></i>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12 col-lg-6">
                                            <span class="clr-9d9a9a">{{'common.showing' | translate}} {{'common.1To3Of'
                                                | translate}} 3
                                                {{'common.entries' |
                                                translate}}</span>
                                        </div>
                                        <div class="col-sm-12 col-md-12 col-lg-6">
                                            <div class="float-right">
                                                <nav aria-label="Page navigation example">
                                                    <ul class="pagination">
                                                        <li class="page-item"><a class="page-link">{{'common.first' |
                                                                translate}}</a></li>
                                                        <li class="page-item"><a class="page-link">{{'common.previous' |
                                                                translate}}</a></li>
                                                        <li class="page-item"><a class="page-link">1</a></li>
                                                        <li class="page-item"><a class="page-link">2</a></li>
                                                        <li class="page-item"><a class="page-link">3</a></li>
                                                        <li class="page-item"><a class="page-link">4</a></li>
                                                        <li class="page-item"><a class="page-link">5</a></li>
                                                        <li class="page-item"><a class="page-link">{{'common.next' |
                                                                translate}}</a></li>
                                                        <li class="page-item"><a class="page-link">{{'common.last' |
                                                                translate}}</a></li>
                                                    </ul>
                                                </nav>


                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>

    </div>
</section>