<section>
  <div class="sidebar" [class.sidebar-close]="!_sharedSvc.openSidebar">
    <app-sidebar></app-sidebar>
  </div>
  <div class="home-section">
    <div
      class="home-main"
      [class.sidebar-close_home-main]="!_sharedSvc.openSidebar"
    >
      <app-navbar></app-navbar>
      <div class="home-view">
        <section>
          <div class="row mb-3">
            <div class="col-sm-8 col-md-8 col-lg-8">
              <h4>{{ "common.businessActivity" | translate }}</h4>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4">
              <div
                class="add-button"
                (click)="openModal(businessActivity, false)"
              >
                <i class="fa fa-plus"></i>
              </div>
              <div class="back-btn me-2" (click)="bizSectNav()">
                <i class="fa fa-reply"></i>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="dashboard-card loan-table">
                <div class="row mb-3">
                  <div class="col-sm-7 col-md-7 col-lg-8">
                    <ng-container *ngIf="_SetupService.pageSize >= 10">
                      <span>{{ "common.show" | translate }}</span>
                      <input
                        name="entries"
                        class="m-1 text-center"
                        [value]="_SetupService.businessActyTablePG?.length"
                        readonly
                        style="width: 45px"
                      />
                      <span>{{ "common.entries" | translate }}</span>
                    </ng-container>
                  </div>
                  <div class="col-sm-5 col-md-5 col-lg-4">
                    <div class="inside-icon">
                      <i class="fa fa-search"></i>
                      <input
                        type="search"
                        class="form-control search-input"
                        placeholder="{{ 'common.search' | translate }}"
                      />
                    </div>
                  </div>
                </div>
                <div class="table-responsive">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <td>
                          {{
                            "businessActivity.businessActivityID" | translate
                          }}
                        </td>
                        <td>
                          {{
                            "businessActivity.businessActivityName" | translate
                          }}
                        </td>
                        <td>{{ "common.sortOrder" | translate }}</td>
                        <td>{{ "common.status" | translate }}</td>
                        <td class="text-center">
                          {{ "common.action" | translate }}
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container
                        *ngIf="_SetupService.businessActyList?.length > 0"
                      >
                        <tr
                          *ngFor="let list of _SetupService.businessActyTablePG"
                        >
                          <td>{{ list.bizActySeq }}</td>
                          <td>{{ list.bizActyNm }}</td>
                          <td>{{ list.bizActySortOrder }}</td>
                          <td>
                            {{
                              list.bizActyStsKey === 2040
                                ? this.translate.instant("common.active")
                                : this.translate.instant("common.inActive")
                            }}
                          </td>
                          <td class="text-center">
                            <div class="action-inner">
                              <i
                                class="fa fa-pencil"
                                (click)="
                                  editBizActy(
                                    businessActivity,
                                    list.bizActySeq,
                                    true
                                  )
                                "
                              ></i>
                              <i
                                class="fa fa-trash"
                                (click)="deleteRecord(list.bizActySeq)"
                              ></i>
                            </div>
                          </td>
                        </tr>
                      </ng-container>
                      <ng-container
                        *ngIf="
                          _SetupService.businessActyList &&
                          _SetupService.businessActyList?.error &&
                          !_SetupService.businessActyList?.length
                        "
                      >
                        <tr>
                          <td colspan="7" class="text-center">
                            {{ _SetupService.businessActyList?.error }}
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                  <div
                    class="row"
                    *ngIf="
                      _sharedSvc.pageSize >= 10 &&
                      !_SetupService.businessActyList?.error
                    "
                  >
                    <div class="col-sm-12 col-md-12 col-lg-6">
                      <span class="clr-9d9a9a"
                        >{{ "common.showing" | translate }}
                        {{ _SetupService.businessActyTablePG?.length }}
                        {{ "common.of" | translate }}
                        {{ _SetupService.collectionSizeBizActy }} entries</span
                      >
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6">
                      <div class="float-right">
                        <ngb-pagination
                          [(page)]="_sharedSvc.page"
                          [pageSize]="_sharedSvc.pageSize"
                          [maxSize]="10"
                          ngDefaultControl
                          [collectionSize]="_SetupService.collectionSizeBizActy"
                          [rotate]="true"
                          (pageChange)="_SetupService.pageChangeActy()"
                        >
                          <ng-template ngbPaginationPrevious>{{
                            "common.prev" | translate
                          }}</ng-template>
                          <ng-template ngbPaginationNext>{{
                            "common.next" | translate
                          }}</ng-template>
                        </ngb-pagination>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>

<ng-template id="display-modal" #businessActivity let-c="close" let-d="dismiss">
  <div class="modal-body">
    <div class="p-3 pb-0">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center">
            <h4>{{ "common.businessActivity" | translate }}</h4>
          </div>
        </div>
      </div>
      <form
        [formGroup]="addBizActyformData"
        (ngSubmit)="submitBizActyForm(addBizActyformData)"
      >
        <div class="row mb-3">
          <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
            <div class="form-floating">
              <input
                type="text"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius"
                formControlName="bizActyNm"
                [class.is-invalid]="
                  bizActyNm.invalid && (bizActyNm.dirty || bizActyNm.touched)
                "
              />
              <div
                *ngIf="
                  bizActyNm.invalid && (bizActyNm.dirty || bizActyNm.touched)
                "
                class="invalid-feedback"
              >
                <div *ngIf="bizActyNm.errors?.['required']">
                  {{
                    "businessActivity.businessActivityNameRequire" | translate
                  }}
                </div>
              </div>
              <label>{{
                "businessActivity.businessActivityName" | translate
              }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
            <div class="form-floating">
              <input
                type="text"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius"
                formControlName="bizActySortOrder"
                [class.is-invalid]="
                  bizActySortOrder.invalid &&
                  (bizActySortOrder.dirty || bizActySortOrder.touched)
                "
              />
              <div
                *ngIf="
                  bizActySortOrder.invalid &&
                  (bizActySortOrder.dirty || bizActySortOrder.touched)
                "
                class="invalid-feedback"
              >
                <div *ngIf="bizActySortOrder.errors?.['required']">
                  {{ "common.sortOrderRequire" | translate }}
                </div>
                <div *ngIf="bizActySortOrder.errors?.isSortOrderExist">
                  {{ "common.sortOrderAssign" | translate }}
                </div>
              </div>
              <label>{{ "common.sortOrder" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
            <div class="form-floating">
              <select
                name="gndrKey"
                id="gndrKey"
                class="form-control input-radius form-select"
                formControlName="bizActyStsKey"
                [class.is-invalid]="
                  bizActyStsKey.invalid &&
                  (bizActyStsKey.dirty || bizActyStsKey.touched)
                "
              >
                <option
                  *ngFor="
                    let item of _picklistService.WORK_STATUS_Data;
                    let i = index
                  "
                  [value]="item?.refCdSeq"
                >
                  {{ item.refCdDscr }}
                </option>
              </select>
              <div
                *ngIf="
                  bizActyStsKey.invalid &&
                  (bizActyStsKey.dirty || bizActyStsKey.touched)
                "
                class="invalid-feedback"
              >
                <div *ngIf="bizActyStsKey.errors?.['required']">
                  {{ "common.statusRequire" | translate }}
                </div>
              </div>
              <label>{{ "common.status" | translate }}</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="button_div">
              <button
                class="btn login-button-class"
                [class.pointerNone]="_sharedSvc.loading"
                [disabled]="_sharedSvc.loading"
              >
                {{ "common.ok" | translate }}
              </button>
              <button
                class="btn login-button-class cancelBtn"
                (click)="d('Cross click')"
                type="reset"
              >
                {{ "common.cancel" | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
