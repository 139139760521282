import { Component, OnInit, SimpleChanges } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SharedService } from "src/app/components/shared/service/shared.service";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
  NgForm,
} from "@angular/forms";

import { ClintOnboardingService } from "src/app/components/clint-onboarding/shared/service/clint-onboarding.service";
import { SetupService } from "../shared/service/setup.service";
import { PicklistService } from "../../shared/pickList/picklist.service";
import { ClientDocsService } from "../../clint-onboarding/client-docs/shared/service/client-docs.service";
import {
  DataUrl,
  DOC_ORIENTATION,
  NgxImageCompressService,
  UploadResponse,
} from "ngx-image-compress";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-shroff-management",
  templateUrl: "./shroff-management.component.html",
  styleUrls: ["./shroff-management.component.css"],
})
export class ShroffManagementComponent implements OnInit {
  loadingIndicator: boolean = false;
  rows: any;

  page: number = 5;
  pageSize: number = 5;
  collectionSize: number = 5;

  currentIndex = 0;

  loginForm!: FormGroup;
  submitted = false;
  // currentDate: any = new Date().toISOString().split("T")[0];
  onBoardingTablePG: any;
  maskedCNIC: string;
  formdata: any;

  cnicBack: any;

  shopAdd: any;
  shroffSeqID: any;
  uploadMsg: any;

  imgPath: boolean = false;

  imgResultBeforeCompress: any;
  imgResultAfterCompress: any;

  cnicFronts: any = "";
  cnicBacks: any = "";
  certificates: any = "";
  verfications: any = "";
  agreements: any = "";
  addresss: any = "";

  isEdit: boolean = false;

  singleRecord: any;

  entyFlag: any = 5;
  disabledAll: boolean = false;

  constructor(
    private modalService: NgbModal,
    public router: Router,
    public _sharedSvc: SharedService,
    public _clintOnboardingSvc: ClintOnboardingService,
    public _setupService: SetupService,
    public _picklistService: PicklistService,
    private formBuilder: FormBuilder,
    public _clientDocsSvc: ClientDocsService,
    private imageCompress: NgxImageCompressService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.formdata = this.formBuilder.group({
      // shroffId: ['', Validators.required, Validators.minLength(3),Validators.maxLength(100)],
      firstName: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
        ],
      ],
      lastName: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
        ],
      ],
      cnicNum: ["", Validators.required],
      phNum: ["", Validators.required],
      shrofAddr: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
        ],
      ],
      cnicExpryDt: [new Date().toISOString(), Validators.required],
      cnicIssueDt: [new Date().toISOString(), Validators.required],
      gndrKey: ["", Validators.required],
      stsKey: ["", Validators.required],
      dob: [new Date().toISOString(), Validators.required],
      bizName: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
        ],
      ],
      bizPhonNumber: ["", Validators.required],
      bizAddress: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
        ],
      ],
      cmnt: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
        ],
      ],

      cnicFront: ["", Validators.required],
      cnicBack: ["", Validators.required],
      certificate: ["", Validators.required],
      verification: ["", Validators.required],
      agreement: ["", Validators.required],
      addresss: ["", Validators.required],
    });
    this._setupService.get_shrof_list();
    this._picklistService.GENDER();
    this._picklistService.SHROFF_STATUS();
  }

  ngOnChanges(changes: SimpleChanges) {}

  pageChange() {
    this.collectionSize = this._clintOnboardingSvc.onBoardingTable.length;
    this.onBoardingTablePG = this._clintOnboardingSvc.onBoardingTable.slice(
      (this.page - 1) * this.pageSize,
      (this.page - 1) * this.pageSize + this.pageSize
    );
    this.onBoardingTablePG;
  }

  openModal(content: any) {
    localStorage.removeItem("selectedOrderData");
    this.modalService.open(content, {
      // backdrop: "static",
      // keyboard: false,
      windowClass: "popupWidth1k",
      // backdropClass: 'light-blue-backdrop',
      centered: true,
    });
  }

  openModalforShroff(content: any, edit: boolean) {
    localStorage.removeItem("selectedOrderData");
    this.isEdit = edit;
    this.modalService
      .open(content, {
        // backdrop: "static",
        // keyboard: false,
        windowClass: "popupWidth1k",
        // backdropClass: 'light-blue-backdrop',
        centered: true,
      })
      .result.then(
        (result) => {
          // Your logic when modal is closed
          this.disabledAll = false;
          this.formdata.enable();
        },
        (reason) => {
          // Your logic when modal is dismissed
          this.disabledAll = false;
          this.formdata.enable();
        }
      );
  }

  // async openModalViewShroff(content: any, id: any) {
  //   this._sharedSvc.showSpinner();
  //   localStorage.removeItem("selectedOrderData");
  //   let list = this._setupService.shoroffList;
  //   let record = list.find(x => x.shrfSeq === id);
  //   this.singleRecord = record;
  //   await this._clientDocsSvc.get_client_doc_by_entity(this.singleRecord.shrfSeq, this.entyFlag);
  //   this._clientDocsSvc.docList;

  //   this.modalService.open(content, {
  //     // backdrop: "static",
  //     // keyboard: false,
  //     windowClass: "popupWidth1k",
  //     // backdropClass: 'light-blue-backdrop',
  //     centered: true
  //   });
  //   this._sharedSvc.hideSpinner();
  // }

  navigate() {
    this.router.navigate(["/disbursement-form"]);
  }
  submitShroffValue(val) {
    if (this.formdata.invalid) {
      for (const control of Object.keys(this.formdata.controls)) {
        this.formdata.controls[control].markAsTouched();
      }
      return;
    }

    let data = {
      ...this.formdata.value,
      cnicIssueDt: new Date(this.formdata.value.cnicIssueDt).toISOString(),
      cnicExpryDt: new Date(this.formdata.value.cnicExpryDt).toISOString(),
      dob: new Date(this.formdata.value.dob).toISOString(),
      delFlg: 0,
    };
    if (this.isEdit) {
      data.shrfSeq = this.singleRecord.shrfSeq;
    }
    let newData = this.removeKeysFromOBJ(data);
    console.log(newData);
    this._setupService.saveShrof(newData).subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.success(
            this.translate.instant("toast.shroff") +
              (this.isEdit === true
                ? this.translate.instant("toast.updated")
                : this.translate.instant("toast.added"))
          );
          this._sharedSvc.hideSpinner3();
          this.modalService.dismissAll();
          this.shroffSeqID = data;
          this.submitNextDocument();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner3();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(
            ([key, value]: any, index: number) => {
              this._sharedSvc.error(value);
            }
          );
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    });
  }

  deleteRecord(id) {
    let list = this._setupService.shoroffList;
    let record = list.find((x) => x.shrfSeq === id);
    this.singleRecord = record;

    if (record) {
      let data = {
        ...record,
        delFlg: 1,
      };
      this._setupService.saveShrof(data).subscribe({
        next: (response) => {
          if (response) {
            this._sharedSvc.success(
              this.translate.instant("toast.shroffDeleted")
            );
            this._sharedSvc.hideSpinner3();
            this.modalService.dismissAll();
            this._setupService.get_shrof_list();
          }
        },
        error: (e) => {
          this._sharedSvc.hideSpinner3();
          if (e.status == 401) {
            this._sharedSvc.error(this._sharedSvc.authMsg);
            this._sharedSvc.logout();
            return;
          }
          if (e?.error) {
            Object.entries(e?.error).forEach(
              ([key, value]: any, index: number) => {
                this._sharedSvc.error(value);
              }
            );
          }
          if (e?.error?.errorMessage) {
            this._sharedSvc.error(e?.error?.error);
          }
        },
      });
    }
  }

  removeKeysFromOBJ(obj) {
    // Specify the keys to remove
    const keysToRemove = [
      "cnicFront",
      "cnicBack",
      "certificate",
      "verification",
      "agreement",
      "addresss",
    ];

    // Create a new object without the specified keys
    let newObj = Object.fromEntries(
      Object.entries(obj).filter(([key]) => !keysToRemove.includes(key))
    );
    return newObj;
  }

  onClickSubmit(formValue) {
    alert("onClickSubmit");
  }

  async editShrof(content: any, id: any, edit: boolean, viewShroff: boolean) {
    let list = this._setupService.shoroffList;
    let record = list.find((x) => x.shrfSeq === id);
    this.singleRecord = record;
    await this._clientDocsSvc.get_client_doc_by_entity(
      this.singleRecord.shrfSeq,
      this.entyFlag
    );

    if (record) {
      let data = {
        firstName: record.firstName,
        lastName: record.lastName,
        cnicNum: record.cnicNum,
        phNum: record.phNum,
        shrofAddr: record.shrofAddr,
        cnicExpryDt: this.formatDate(record.cnicExpryDt),
        cnicIssueDt: this.formatDate(record.cnicIssueDt),
        gndrKey: record.gndrKey,
        stsKey: record.stsKey,
        dob: this.formatDate(record.dob),
        bizName: record.bizName,
        bizPhonNumber: record.bizPhonNumber,
        bizAddress: record.bizAddress,
        cmnt: record.cmnt,
        cnicFront: !!this._clientDocsSvc.docList.find((x) => x.docSeq === 20)
          ? this._clientDocsSvc.docList.find((x) => x.docSeq === 20).docImg
          : "",
        cnicBack: !!this._clientDocsSvc.docList.find((x) => x.docSeq === 21)
          ? this._clientDocsSvc.docList.find((x) => x.docSeq === 21).docImg
          : "",
        certificate: !!this._clientDocsSvc.docList.find((x) => x.docSeq === 22)
          ? this._clientDocsSvc.docList.find((x) => x.docSeq === 22).docImg
          : "",
        verification: !!this._clientDocsSvc.docList.find((x) => x.docSeq === 23)
          ? this._clientDocsSvc.docList.find((x) => x.docSeq === 23).docImg
          : "",
        agreement: !!this._clientDocsSvc.docList.find((x) => x.docSeq === 24)
          ? this._clientDocsSvc.docList.find((x) => x.docSeq === 24).docImg
          : "",
        addresss: !!this._clientDocsSvc.docList.find((x) => x.docSeq === 25)
          ? this._clientDocsSvc.docList.find((x) => x.docSeq === 25).docImg
          : "",
      };
      this.cnicFronts = !!this._clientDocsSvc.docList.find(
        (x) => x.docSeq === 20
      )
        ? "data:image/png;base64," +
          this._clientDocsSvc.docList.find((x) => x.docSeq === 20).docImg
        : "";
      this.cnicBacks = !!this._clientDocsSvc.docList.find(
        (x) => x.docSeq === 21
      )
        ? "data:image/png;base64," +
          this._clientDocsSvc.docList.find((x) => x.docSeq === 21).docImg
        : "";
      this.certificates = !!this._clientDocsSvc.docList.find(
        (x) => x.docSeq === 22
      )
        ? "data:image/png;base64," +
          this._clientDocsSvc.docList.find((x) => x.docSeq === 22).docImg
        : "";
      this.verfications = !!this._clientDocsSvc.docList.find(
        (x) => x.docSeq === 23
      )
        ? "data:image/png;base64," +
          this._clientDocsSvc.docList.find((x) => x.docSeq === 23).docImg
        : "";
      this.agreements = !!this._clientDocsSvc.docList.find(
        (x) => x.docSeq === 24
      )
        ? "data:image/png;base64," +
          this._clientDocsSvc.docList.find((x) => x.docSeq === 24).docImg
        : "";
      this.addresss = !!this._clientDocsSvc.docList.find((x) => x.docSeq === 25)
        ? "data:image/png;base64," +
          this._clientDocsSvc.docList.find((x) => x.docSeq === 25).docImg
        : "";

      if (viewShroff) {
        this.disabledAll = true;
        this.formdata.disable();
      }

      this.formdata.patchValue(data);
      this.openModalforShroff(content, edit);
    }
  }

  formatDate(date: string): string {
    return date ? new Date(date).toISOString().split("T")[0] : null;
  }
  submitNextDocument = () => {
    let arra = [];
    if (this.isEdit) {
      if (
        this.cnicFronts.split(",/")[1] !==
          this._clientDocsSvc.docList.find((x) => x.docSeq === 20)?.docImg &&
        !!this.cnicFronts.split(",/")[1]
      ) {
        arra.push("cnicFront");
      }
      if (
        this.cnicBacks.split(",/")[1] !==
          this._clientDocsSvc.docList.find((x) => x.docSeq === 21)?.docImg &&
        !!this.cnicBacks.split(",/")[1]
      ) {
        arra.push("cnicBack");
      }
      if (
        this.certificates.split(",/")[1] !==
          this._clientDocsSvc.docList.find((x) => x.docSeq === 22)?.docImg &&
        this.certificates.split(",/")[1]
      ) {
        arra.push("certificate");
      }
      if (
        this.verfications.split(",/")[1] !==
          this._clientDocsSvc.docList.find((x) => x.docSeq === 23)?.docImg &&
        this.verfications.split(",/")[1]
      ) {
        arra.push("verification");
      }
      if (
        this.agreements.split(",/")[1] !==
          this._clientDocsSvc.docList.find((x) => x.docSeq === 24)?.docImg &&
        this.agreements.split(",/")[1]
      ) {
        arra.push("agreement");
      }
      if (
        this.addresss.split(",/")[1] !==
          this._clientDocsSvc.docList.find((x) => x.docSeq === 25)?.docImg &&
        this.addresss.split(",/")[1]
      ) {
        arra.push("address");
      }
    } else {
      arra = [
        "cnicFront",
        "cnicBack",
        "certificate",
        "verification",
        "agreement",
        "address",
      ];
    }
    if (this.currentIndex < arra.length) {
      const url = arra[this.currentIndex];
      console.log("calling next document");
      this.onSubmitDoc(url)
        .then(() => {
          // Move to the next item in the array
          console.log("calling next document success ");

          this.currentIndex++;
          this.submitNextDocument(); // Call the function recursively
        })
        .catch((error) => {
          console.error("Error submitting document:", error);
          console.log("calling next document error ");

          this._sharedSvc.hideSpinner(); // Hide the spinner in case of an error
        });
    } else {
      // All API calls have completed successfully
      console.log("All documents submitted successfully");
      this._sharedSvc.hideSpinner(); // Hide the spinner
      this.modalService.dismissAll();
    }
  };

  //  get shroffId() { return this.formdata.get("shroffId") }
  get firstName() {
    return this.formdata.get("firstName");
  }
  get lastName() {
    return this.formdata.get("lastName");
  }
  get cnicNum() {
    return this.formdata.get("cnicNum");
  }
  get cnicExpryDt() {
    return this.formdata.get("cnicExpryDt");
  }
  get cnicIssueDt() {
    return this.formdata.get("cnicIssueDt");
  }
  get phone() {
    return this.formdata.get("phNum");
  }
  get permAddress() {
    return this.formdata.get("shrofAddr");
  }
  get gndrKey() {
    return this.formdata.get("gndrKey");
  }
  get stsKey() {
    return this.formdata.get("stsKey");
  }
  get dob() {
    return this.formdata.get("dob");
  }

  get shopName() {
    return this.formdata.get("bizName");
  }
  get shopAddress() {
    return this.formdata.get("bizAddress");
  }
  get shopNumber() {
    return this.formdata.get("bizPhonNumber");
  }
  get shopCmnt() {
    return this.formdata.get("cmnt");
  }

  get cnicFrontField() {
    return this.formdata.get("cnicFront");
  }
  get cnicBackField() {
    return this.formdata.get("cnicBack");
  }
  get certificatesField() {
    return this.formdata.get("certificate");
  }
  get verficationsField() {
    return this.formdata.get("verification");
  }
  get agreementField() {
    return this.formdata.get("agreement");
  }
  get addressField() {
    return this.formdata.get("addresss");
  }

  compressFile(url: any) {
    this.setD_V3();
    return this.imageCompress
      .uploadFile()
      .then(({ image, orientation, fileName }: UploadResponse) => {
        this.imgResultBeforeCompress = image;

        if (url == "cnicFront") {
          this.formdata.controls["cnicFront"].setValue(image);
          this.cnicFronts = image;
        }
        if (url == "cnicBack") {
          this.formdata.controls["cnicBack"].setValue(image);
          this.cnicBacks = image;
        }
        if (url == "certificate") {
          this.formdata.controls["certificate"].setValue(image);
          this.certificates = image;
        }
        if (url == "verification") {
          this.formdata.controls["verification"].setValue(image);
          this.verfications = image;
        }
        if (url == "agreement") {
          this.formdata.controls["agreement"].setValue(image);
          this.agreements = image;
        }
        if (url == "address") {
          this.formdata.controls["addresss"].setValue(image);
          this.addresss = image;
        }

        this.imageCompress
          .compressFile(image, orientation, 10, 50)
          .then((result: DataUrl) => {
            this.imgResultAfterCompress = result;
            this._clientDocsSvc.saveDocsModel.entySeq = this.shroffSeqID;

            if (url == "cnicFront") {
              this._clientDocsSvc.saveDocsModel.docImg = result;
              this._clientDocsSvc.saveDocsModel.docSeq = 20;
              this.uploadMsg = "cnicFront Image Uploaded";
            }
            if (url == "cnicBack") {
              this._clientDocsSvc.saveDocsModel.docImg = result;
              this._clientDocsSvc.saveDocsModel.docSeq = 21;
              this.uploadMsg = "cnicBack Image Uploaded";
            }
            if (url == "certificate") {
              this._clientDocsSvc.saveDocsModel.docImg = result;
              this._clientDocsSvc.saveDocsModel.docSeq = 22;
              this.uploadMsg = "Certificate  Uploaded";
            }
            if (url == "verification") {
              this._clientDocsSvc.saveDocsModel.docImg = result;
              this._clientDocsSvc.saveDocsModel.docSeq = 23;
              this.uploadMsg = "Verification Upload";
            }
            if (url == "agreement") {
              this._clientDocsSvc.saveDocsModel.docImg = result;
              this._clientDocsSvc.saveDocsModel.docSeq = 24;
              this.uploadMsg = "Agreement Upload";
            }
            if (url == "address") {
              this._clientDocsSvc.saveDocsModel.docImg = result;
              this._clientDocsSvc.saveDocsModel.docSeq = 25;
              this.uploadMsg = "Address Upload";
            }
          });
      });
  }

  setD_V3() {
    this._clientDocsSvc.saveDocsModel = {
      clntDocSeq: null,
      clntSeq: 0,
      entySeq: this.shroffSeqID,
      entyFlag: 5,
      docSeq: 20,
      docImg: this.cnicFronts,
      delFlg: false,
    };
  }

  LoopDocfiles(url) {
    if (url == "cnicFront") {
      this._clientDocsSvc.saveDocsModel = {
        clntDocSeq: null,
        clntSeq: 0,
        entySeq: this.shroffSeqID,
        entyFlag: 5,
        docSeq: 20,
        docImg: this.cnicFronts,
        delFlg: false,
      };

      this.uploadMsg = "cnicFront Image Uploaded";
    }
    if (url == "cnicBack") {
      this._clientDocsSvc.saveDocsModel = {
        clntDocSeq: null,
        clntSeq: 0,
        entySeq: this.shroffSeqID,
        entyFlag: 5,
        docSeq: 21,
        docImg: this.cnicBacks,
        delFlg: false,
      };

      this.uploadMsg = "cnicBack Image Uploaded";
    }
    if (url == "certificate") {
      this._clientDocsSvc.saveDocsModel = {
        clntDocSeq: null,
        clntSeq: 0,
        entySeq: this.shroffSeqID,
        entyFlag: 5,
        docSeq: 22,
        docImg: this.certificates,
        delFlg: false,
      };

      this.uploadMsg = "Certificate  Uploaded";
    }
    if (url == "verification") {
      this._clientDocsSvc.saveDocsModel = {
        clntDocSeq: null,
        clntSeq: 0,
        entySeq: this.shroffSeqID,
        entyFlag: 5,
        docSeq: 23,
        docImg: this.verfications,
        delFlg: false,
      };

      this.uploadMsg = "Verification Upload";
    }
    if (url == "agreement") {
      this._clientDocsSvc.saveDocsModel = {
        clntDocSeq: null,
        clntSeq: 0,
        entySeq: this.shroffSeqID,
        entyFlag: 5,
        docSeq: 24,
        docImg: this.agreements,
        delFlg: false,
      };

      this.uploadMsg = "Agreement Upload";
    }
    if (url == "address") {
      this._clientDocsSvc.saveDocsModel = {
        clntDocSeq: null,
        clntSeq: 0,
        entySeq: this.shroffSeqID,
        entyFlag: 5,
        docSeq: 25,
        docImg: this.addresss,
        delFlg: false,
      };
      this.uploadMsg = "Address Upload";
    }
  }

  onSubmitDoc(url): any {
    // this.compressFile("cnicFront")

    this.LoopDocfiles(url);

    return new Promise<void>((resolve, reject) => {
      this._sharedSvc.showSpinner3();
      this._clientDocsSvc.SaveDocs().subscribe({
        next: (response) => {
          if (response) {
            this.imgPath = true;
            this._sharedSvc.success(this.uploadMsg);
            this._sharedSvc.hideSpinner3();
            resolve();
          }
        },
        error: (e) => {
          this._sharedSvc.hideSpinner3();
          if (e.status == 401) {
            this._sharedSvc.error(this._sharedSvc.authMsg);
            this._sharedSvc.logout();
            return;
          }
          if (e?.error) {
            Object.entries(e?.error).forEach(
              ([key, value]: any, index: number) => {
                this._sharedSvc.error(value);
              }
            );
          }
          if (e?.error?.errorMessage) {
            this._sharedSvc.error(e?.error?.error);
          }
          reject(e);
        },
      });
    });

    // }
    this._sharedSvc.hideSpinner();
  }

  getStatus(statusIndex: any) {
    for (let i = 0; i < this._picklistService.SHROFF_STATUS_Data.length; i++) {
      if (
        this._picklistService.SHROFF_STATUS_Data[i].refCdSeq === statusIndex
      ) {
        return this._picklistService.SHROFF_STATUS_Data[i].refCdDscr;
      }
    }
  }
  getShrofGender(genderIndex: any) {
    for (let i = 0; i < this._picklistService.GENDER_Data.length; i++) {
      if (this._picklistService.GENDER_Data[i].refCdSeq === genderIndex) {
        return this._picklistService.GENDER_Data[i].refCdDscr;
      }
    }
  }
  getShrofDocs() {
    let temp = {
      cnicFront: "",
      cnicBack: "",
      certificate: "",
      verification: "",
      agreement: "",
      addresss: "",
    };
    temp.cnicFront = this._clientDocsSvc.docList.find((x) => x.docSeq === 20)
      ? this._clientDocsSvc.docList.find((x) => x.docSeq === 20).docImg
      : "Khali";
    temp.cnicBack = this._clientDocsSvc.docList.find((x) => x.docSeq === 21)
      ? this._clientDocsSvc.docList.find((x) => x.docSeq === 21).docImg
      : "";
    temp.certificate = this._clientDocsSvc.docList.find((x) => x.docSeq === 22)
      ? this._clientDocsSvc.docList.find((x) => x.docSeq === 22).docImg
      : "";
    temp.verification = this._clientDocsSvc.docList.find((x) => x.docSeq === 23)
      ? this._clientDocsSvc.docList.find((x) => x.docSeq === 23).docImg
      : "";
    temp.agreement = this._clientDocsSvc.docList.find((x) => x.docSeq === 24)
      ? this._clientDocsSvc.docList.find((x) => x.docSeq === 24).docImg
      : "";
    temp.addresss = this._clientDocsSvc.docList.find((x) => x.docSeq === 25)
      ? this._clientDocsSvc.docList.find((x) => x.docSeq === 25).docImg
      : "";
    return temp;
  }
}

// cnicFront: any
// cnicBack: any
// certificate: any
// verfication: any
// agreement: any
// shopAdd: any
