<section>
    <div class="loginPage">
        <div class="login-content">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-6 marginAuto">
                    <img class="img-fluid" src=".../../../../../../assets/images/login-screen-logo.png">
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6 marginAuto">
                    <img class="img-fluid float-right"
                        src=".../../../../../../assets/images/client-logo-inner-pages.png">
                </div>
            </div>
            <div class="login-heading-class mt-5">
                <h3 class="ms-0">Welcome To</h3>
                <h3 class="login-heading-blue ms-0">Loan Management System</h3>
            </div>
            <form [formGroup]="loginForm" (ngSubmit)="submitLogin()">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="inside-icon inside-icon-login">
                            <i class="fa fa-user"></i>
                            <input type="text" autocomplete="off" class="form-control" formControlName="name"
                                placeholder="Enter username" required minlength="3" maxlength="100"
                                [class.is-invalid]="name.invalid && (name.dirty || name.touched)">
                            <div *ngIf="name.invalid && (name.dirty || name.touched)" class="invalid-feedback">
                                <div *ngIf="name.errors?.['required']">
                                    {{'common.nameRequire' | translate}}
                                </div>
                                <div *ngIf="name.errors?.['minlength']">
                                    Name must have at least 3 character.
                                </div>
                                <div *ngIf="name.errors?.['maxlength']">
                                    Name must have at most 100 characters.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="inside-icon inside-icon-login">
                            <i class="fa pointer" [ngClass]="{' fa-eye': showPassword, ' fa-eye-slash ': !showPassword}"
                                (click)="showPassword = !showPassword"></i>
                            <input [type]="showPassword ? 'text' : 'password'" placeholder="Enter password"
                                formControlName="password" required minlength="6" maxlength="6" class="form-control "
                                [class.is-invalid]="password.invalid && (password.dirty || password.touched)">
                            <!-- pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{6,}$" -->
                            <div *ngIf="password.invalid && (password.dirty || password.touched)"
                                class="invalid-feedback">
                                <div *ngIf="password.errors?.['required']">
                                    Password is required.
                                </div>
                                <div *ngIf="password.errors?.['minlength']">
                                    This field must have at least 6 characters.
                                </div>
                                <div *ngIf="password.errors?.['maxlength']">
                                    This field must have at most 6 characters.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-8 col-md-8 col-lg-8">
                        <div class="forgot-pass">
                            <a href="">Forgot Username/Password</a>
                        </div>
                    </div>
                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                        <div class="checkbox-wrapper">
                            <input type="checkbox" class="form-check-input pointer" formControlName="rememberMeVar"
                                (click)="remember()">
                            <span>&nbsp;Remember</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="text-center">
                        <button type="button" class="btn login-button-class" [class.pointerNone]="_sharedSvc.loading"
                            [disabled]="_sharedSvc.loading">
                            Sign In
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>