<section>
    <div class="sidebar" [class.sidebar-close]="!_sharedSvc.openSidebar">
        <app-sidebar></app-sidebar>
    </div>
    <div class="home-section">
        <div class="home-main" [class.sidebar-close_home-main]="!_sharedSvc.openSidebar">
            <app-navbar></app-navbar>
            <div class="home-view" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
                [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
                <section>
                    <div class="row mb-3">
                        <div class="col-sm-8 col-md-8 col-lg-8">
                            <h4>{{'geography.geography' | translate}}</h4>
                        </div>
                        <div class="col-sm-4 col-md-4 col-lg-4">
                            <div class="add-button" (click)="openModal(branch,false)"><i class="fa fa-plus"></i></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <div class="dashboard-card loan-table">
                                <div class="row mb-3">
                                    <div class="col-sm-7 col-md-7 col-lg-8">
                                        <ng-container *ngIf="_sharedSvc.pageSize >= 10">
                                            <span>{{'common.show' | translate}}</span>
                                            <input type="number" class="m-1 text-center" style="width: 45px;">
                                            <span>{{'common.entries' | translate}}</span>
                                        </ng-container>
                                    </div>
                                    <div class="col-sm-5 col-md-5 col-lg-4">
                                        <div class="inside-icon">
                                            <i class="fa fa-search"></i>
                                            <input type="search" class="form-control search-input"
                                                placeholder="{{'common.search' | translate}}">
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                        <div class="table-responsive">
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <td>{{'geography.countryCode' | translate}}</td>
                                                        <td>{{'geography.countryName' | translate}}</td>
                                                        <td>{{'common.description' | translate}}</td>
                                                        <td class="text-center">{{'common.action' | translate}}</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let data of _SetupService.geoCountryList">
                                                        <td>
                                                            {{data.cntryCd}}</td>
                                                        <td (click)="next(data.cntrySeq)" class="clr186ed1 pointer">
                                                            {{data.cntryNm}}</td>
                                                        <td>{{data.cntryDesc}}</td>
                                                        <td class="text-center">
                                                            <div class="action-inner">
                                                                <i class="fa fa-pencil"
                                                                    (click)="editCountry(branch,data.cntrySeq,true)"></i>
                                                                <i class="fa fa-trash"
                                                                    (click)="deleteRecord(data.cntrySeq)"></i>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <!-- <div class="row g-0">
                                                <div class="col-sm-12 col-md-12 col-lg-6">
                                                    <span class="clr-9d9a9a">Showing 1 to 3 of 3 entries</span>
                                                </div>
                                                <div class="col-sm-12 col-md-12 col-lg-6">
                                                    <div class="float-right">
                                                        <nav aria-label="Page navigation example">
                                                            <ul class="pagination">
                                                                <li class="page-item"><a class="page-link">First</a>
                                                                </li>
                                                                <li class="page-item"><a class="page-link">Previous</a>
                                                                </li>
                                                                <li class="page-item"><a class="page-link">1</a></li>
                                                                <li class="page-item"><a class="page-link">2</a></li>
                                                                <li class="page-item"><a class="page-link">3</a></li>
                                                                <li class="page-item"><a class="page-link">4</a></li>
                                                                <li class="page-item"><a class="page-link">5</a></li>
                                                                <li class="page-item"><a class="page-link">Next</a></li>
                                                                <li class="page-item"><a class="page-link">Last</a></li>
                                                            </ul>
                                                        </nav>
                                                    </div>
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</section>

<ng-template id="display-modal" #branch let-c="close" let-d="dismiss">
    <div class="modal-body" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
        [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
        <div class="p-3 pb-0">
            <div class="row mb-3">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="text-center">
                        <h4>{{isEdit === true ? this.translate.instant('common.edit') :
                            this.translate.instant('common.add')}} {{'geography.country'| translate}}</h4>
                    </div>
                </div>
            </div>
            <form [formGroup]="addCountryFormdata" (ngSubmit)="submitCountry(addCountryFormdata)">
                <div class="row mb-3">
                    <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                        <div class="form-floating">
                            <input type="text" style="text-transform: capitalize;" formControlName="cntryNm"
                                autocomplete="off" class="form-control input-radius"
                                [class.is-invalid]="cntryNm.invalid && (cntryNm.dirty || cntryNm.touched)">
                            <div *ngIf="cntryNm.invalid && (cntryNm.dirty || cntryNm.touched)" class="invalid-feedback">
                                <div *ngIf="cntryNm.errors?.['required']">
                                    {{'geography.countryNameReq' | translate}}.
                                </div>

                            </div>
                            <label>{{'geography.countryName' | translate}}</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                        <div class="form-floating">
                            <select name="gndrKey" id="gndrKey" class="form-control input-radius form-select"
                                formControlName="cntryStsKey"
                                [class.is-invalid]="cntryStsKey.invalid && (cntryStsKey.dirty || cntryStsKey.touched)">
                                <option *ngFor="let item of _picklistService.SHROFF_STATUS_Data; let i = index"
                                    [value]="item?.refCdSeq">
                                    {{ item.refCdDscr }}
                                </option>
                            </select>

                            <div *ngIf="cntryStsKey.invalid && (cntryStsKey.dirty || cntryStsKey.touched)"
                                class="invalid-feedback">
                                <div *ngIf="cntryStsKey.errors?.['required']">
                                    {{'common.statusRequire' | translate}}
                                </div>

                            </div>
                            <label>{{'common.status' | translate}}</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                        <div class="form-floating">
                            <input type="text" style="text-transform: capitalize;" formControlName="cntryDesc"
                                autocomplete="off" class="form-control input-radius"
                                [class.is-invalid]="cntryDesc.invalid && (cntryDesc.dirty || cntryDesc.touched)">
                            <div *ngIf="cntryDesc.invalid && (cntryDesc.dirty || cntryDesc.touched)"
                                class="invalid-feedback">
                                <div *ngIf="cntryDesc.errors?.['required']">
                                    {{'common.descriptionRequire' | translate}}
                                </div>

                            </div>
                            <label>{{'common.description' | translate}}</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="button_div">
                            <button class="btn login-button-class" [class.pointerNone]="_sharedSvc.loading"
                                [disabled]="_sharedSvc.loading">{{'common.ok' | translate}}</button>
                            <button class="btn login-button-class cancelBtn" (click)="d('Cross click')"
                                type="reset">{{'common.cancel' | translate}}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>