import { Component, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SharedService } from "src/app/components/shared/service/shared.service";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
  NgForm,
} from "@angular/forms";

import { ClintOnboardingService } from "src/app/components/clint-onboarding/shared/service/clint-onboarding.service";
import { SetupService } from "../shared/service/setup.service";
import { PicklistService } from "../../shared/pickList/picklist.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-payment-types",
  templateUrl: "./payment-types.component.html",
  styleUrls: ["./payment-types.component.css"],
})
export class PaymentTypesComponent implements OnInit {
  loadingIndicator: boolean = false;
  addPaymentTypeformData: any;
  typCtgryKey: number = 3;
  singleRecord: any;
  isEdit: any;
  constructor(
    private modalService: NgbModal,
    public router: Router,
    public _sharedSvc: SharedService,
    public _SetupService: SetupService,
    private formBuilder: FormBuilder,
    public _picklistService: PicklistService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.createPaymentTypeForm();
    this._SetupService.get_all_branch_list();
    this._picklistService.Ref_Code_Grp();
    this._SetupService.get_types_Key(this.typCtgryKey);
  }

  createPaymentTypeForm() {
    this.addPaymentTypeformData = this.formBuilder.group({
      typId: ["", Validators.required],
      typStr: ["", Validators.required],
      typStsKey: ["", Validators.required],
      glAcctNum: ["", Validators.required],
      brnchSeq: ["", Validators.required],
    });
  }
  get typId() {
    return this.addPaymentTypeformData.get("typId");
  }
  get typStr() {
    return this.addPaymentTypeformData.get("typStr");
  }
  get typStsKey() {
    return this.addPaymentTypeformData.get("typStsKey");
  }
  get glAcctNum() {
    return this.addPaymentTypeformData.get("glAcctNum");
  }
  get brnchSeq() {
    return this.addPaymentTypeformData.get("brnchSeq");
  }

  submitPaymentTypeForm(val) {
    if (this.addPaymentTypeformData.invalid) {
      for (const control of Object.keys(this.addPaymentTypeformData.controls)) {
        this.addPaymentTypeformData.controls[control].markAsTouched();
      }
      return;
    }
    let data = {
      tntKey: 1,
      ...this.addPaymentTypeformData.value,
      perdFlg: 0,
      typCtgryKey: this.typCtgryKey,
      typSeq: null,
      delFlg: 0,
    };
    // data.blockFlag = data.blockFlag === "true"?"true":"false";
    if (this.isEdit) {
      data.typSeq = this.singleRecord.typSeq;
      data.typId = this.singleRecord.typId;
    }
    this._SetupService.getTypesOfSetupModel = data;
    this._sharedSvc.showSpinner3();
    this._SetupService.saveTyps().subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.success(
            this.translate.instant("toast.payment") +
              (this.isEdit === true
                ? this.translate.instant("toast.updated")
                : this.translate.instant("toast.added"))
          );
          this._sharedSvc.hideSpinner3();
          this.modalService.dismissAll();
          this._SetupService.get_types_Key(this.typCtgryKey);
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner3();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(
            ([key, value]: any, index: number) => {
              this._sharedSvc.error(value);
            }
          );
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    });
  }

  editSetupType(content: any, id: any, edit: any) {
    let list = this._SetupService.typesValueList;
    let record = list.find((x) => x.typSeq === id);
    this.singleRecord = record;
    if (record) {
      let data = {
        typId: record.typId,
        typStr: record.typStr,
        typStsKey: record.typStsKey,
        glAcctNum: record.glAcctNum,
        brnchSeq: record.brnchSeq,
      };
      this.addPaymentTypeformData.patchValue(data);
      this.openModal(content, edit);
    }
  }

  deleteRecord(id) {
    let list = this._SetupService.typesValueList;
    let record = list.find((x) => x.typSeq === id);
    this.singleRecord = record;

    if (record) {
      let data = {
        ...record,
        delFlg: 1,
      };
      this._sharedSvc.showSpinner3();
      this._SetupService.getTypesOfSetupModel = data;
      this._SetupService.saveTyps().subscribe({
        next: (response) => {
          if (response) {
            this._sharedSvc.success(
              this.translate.instant("toast.paymentDeleted")
            );
            this._sharedSvc.hideSpinner3();
            this.modalService.dismissAll();
            this._SetupService.get_types_Key(this.typCtgryKey);
          }
        },
        error: (e) => {
          this._sharedSvc.hideSpinner3();
          if (e.status == 401) {
            this._sharedSvc.error(this._sharedSvc.authMsg);
            this._sharedSvc.logout();
            return;
          }
          if (e?.error) {
            Object.entries(e?.error).forEach(
              ([key, value]: any, index: number) => {
                this._sharedSvc.error(value);
              }
            );
          }
          if (e?.error?.errorMessage) {
            this._sharedSvc.error(e?.error?.error);
          }
        },
      });
    }
  }

  openModal(content: any, edit: boolean) {
    this.isEdit = edit;
    this.modalService.open(content, {
      // backdrop: "static",
      // keyboard: false,
      windowClass: "popupWidth800",
      // backdropClass: 'light-blue-backdrop',
      centered: true,
    });
  }

  navigate() {
    this.router.navigate(["/disbursement-form"]);
  }
}
