// shared.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root', // This ensures that the service is available throughout your app
})
export class userDataClientCreditService {
  userData: any; // Define a property to store shared data

  constructor() {
    this.userData = {}; // Initialize the data as needed
  }
  
  // Define methods to get or set the shared data
  setSharedData(data: any) {
    this.userData = data;
  }

  getSharedData() {
    return this.userData;
  }
}
