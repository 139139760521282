<section class="p-3">
  <div class="row mb-3">
    <div class="col-sm-8 col-md-8 col-lg-8">
      <h4>{{ "addProduct.charges" | translate }}</h4>
    </div>
    <div class="col-sm-4 col-md-4 col-lg-4">
      <div class="add-button" (click)="openModal(chargesModal, 'popupWidth1k')">
        <i class="fa fa-plus"></i>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12">
      <div class="loan-table">
        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
              <tr>
                <td>{{ "charges.chargeType" | translate }}</td>
                <td>{{ "common.type" | translate }}</td>
                <td>{{ "charges.value" | translate }}</td>
                <td class="text-center">{{ "charges.upfront" | translate }}</td>
                <td>{{ "productRules.segregateInstallment" | translate }}</td>
                <td class="text-center">
                  {{ "charges.adjustRounding" | translate }}
                </td>
                <td>{{ "common.status" | translate }}</td>
                <td class="text-center">{{ "common.action" | translate }}</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Service Charges (GK)</td>
                <td>Percent</td>
                <td (click)="openModal(slabsModal, 'popupWidth1k')" class="cursor-pointer">
                  ***
                </td>
                <td class="text-center">
                  <div class="checkbox-wrapper custom-checkbox">
                    <input type="checkbox" class="form-check-input pointer" />
                    <span class="ms-2"></span>
                  </div>
                </td>
                <td (click)="openModal(installmentModal)" class="cursor-pointer">
                  +12
                </td>
                <td class="text-center">
                  <div class="checkbox-wrapper custom-checkbox">
                    <input type="checkbox" class="form-check-input pointer" checked />
                    <span class="ms-2"></span>
                  </div>
                </td>
                <td>{{ "common.active" | translate }}</td>
                <td class="text-center">
                  <div class="action-inner">
                    <i class="fa fa-pencil cursor-pointer"></i>
                    <i class="fa fa-trash cursor-pointer"></i>
                    <i class="fa fa-history cursor-pointer"></i>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Service Charges (GK)</td>
                <td>Percent</td>
                <td (click)="openModal(slabsModal, 'popupWidth1k')" class="cursor-pointer">
                  ***
                </td>
                <td class="text-center">
                  <div class="checkbox-wrapper custom-checkbox">
                    <input type="checkbox" class="form-check-input pointer" />
                    <span class="ms-2"></span>
                  </div>
                </td>
                <td (click)="openModal(installmentModal)" class="cursor-pointer">
                  +12
                </td>
                <td class="text-center">
                  <div class="checkbox-wrapper custom-checkbox">
                    <input type="checkbox" class="form-check-input pointer" checked />
                    <span class="ms-2"></span>
                  </div>
                </td>
                <td>{{ "common.active" | translate }}</td>
                <td class="text-center">
                  <div class="action-inner">
                    <i class="fa fa-pencil cursor-pointer"></i>
                    <i class="fa fa-trash cursor-pointer"></i>
                    <i class="fa fa-history cursor-pointer"></i>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6">
              <span class="clr-9d9a9a">{{ "common.showing" | translate }}
                {{ "common.1To3Of" | translate }} 3
                {{ "common.entries" | translate }}</span>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6">
              <div class="float-right">
                <nav aria-label="Page navigation example">
                  <ul class="pagination">
                    <li class="page-item">
                      <a class="page-link">{{ "common.first" | translate }}</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link">{{
                        "common.previous" | translate
                        }}</a>
                    </li>
                    <li class="page-item"><a class="page-link">1</a></li>
                    <li class="page-item"><a class="page-link">2</a></li>
                    <li class="page-item"><a class="page-link">3</a></li>
                    <li class="page-item"><a class="page-link">4</a></li>
                    <li class="page-item"><a class="page-link">5</a></li>
                    <li class="page-item">
                      <a class="page-link">{{ "common.next" | translate }}</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link">{{ "common.last" | translate }}</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="row mb-3">
  <div class="col-sm-12 col-md-12 col-lg-12">
    <div class="button_div mt-3">
      <button class="btn login-button-class" type="button" (click)="moveToNextTab()">
        {{ "common.continue" | translate }}
      </button>
      <button class="btn login-button-class secondaryBtn" [disabled]="_sharedSvc.loading"
        [class.pointerNone]="_sharedSvc.loading">
        {{ "common.save" | translate }}
      </button>
    </div>
  </div>
</div>

<!-- Charges Modal begins -->
<ng-template id="display-modal" #chargesModal let-c="close" let-d="dismiss">
  <div class="modal-body" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
    [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
    <div class="p-3">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center mb-3">
            <h4>{{ "addProduct.charges" | translate }}</h4>
          </div>
        </div>
      </div>
      <form [formGroup]="chargesFormData" #charges_FormData="ngForm" (ngSubmit)="saveChargesForm(chargesFormData)">
        <div class="row">
          <div class="col-md-4">
            <div class="form-floating mb-3">
              <select class="form-control form-select input-radius" formControlName="chrgTypSeq" [class.is-invalid]="
                  chrgTypSeq.invalid && (chrgTypSeq.dirty || chrgTypSeq.touched)
                ">
                <option hidden value=""></option>
                <option *ngFor="let item of dropdownChargeTypeID" [value]="item?.refCdSeq">{{ item.refCdDscr }}</option>
              </select>
              <div *ngIf="
                  chrgTypSeq.invalid && (chrgTypSeq.dirty || chrgTypSeq.touched)
                " class="invalid-feedback">
                <div *ngIf="chrgTypSeq.errors?.['required']">
                  {{ "charges.chargeTypeRequired" | translate }}
                </div>
              </div>
              <label>{{ "charges.chargeType" | translate }} ID</label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-floating mb-3">
              <select class="form-control form-select input-radius" formControlName="chrgPymntTypKey"
                [class.is-invalid]="
                  chrgPymntTypKey.invalid && (chrgPymntTypKey.dirty || chrgPymntTypKey.touched)
                ">
                <option hidden value=""></option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
              </select>
              <div *ngIf="chrgPymntTypKey.invalid && (chrgPymntTypKey.dirty || chrgPymntTypKey.touched)"
                class="invalid-feedback">
                <div *ngIf="chrgPymntTypKey.errors?.['required']">
                  Charge Payment Type is required
                </div>
              </div>
              <label>Charge Payment {{ "common.type" | translate }}</label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-floating mb-3">
              <select class="form-control form-select input-radius" formControlName="rulSeq" [class.is-invalid]="
                  rulSeq.invalid && (rulSeq.dirty || rulSeq.touched)
                ">
                <option hidden value=""></option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
              </select>
              <div *ngIf="rulSeq.invalid && (rulSeq.dirty || rulSeq.touched)" class="invalid-feedback">
                <div *ngIf="rulSeq.errors?.['required']">
                  Rule is required
                </div>
              </div>
              <label>Rule</label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-floating mb-3">
              <select class="form-control form-select input-radius" formControlName="calcTypKey" [class.is-invalid]="
                  calcTypKey.invalid && (calcTypKey.dirty || calcTypKey.touched)
                ">
                <option hidden value=""></option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
              </select>
              <div *ngIf="calcTypKey.invalid && (calcTypKey.dirty || calcTypKey.touched)" class="invalid-feedback">
                <div *ngIf="calcTypKey.errors?.['required']">
                  Calc Type is required
                </div>
              </div>
              <label>Calc Type</label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-floating mb-3">
              <input type="text" style="text-transform: capitalize" autocomplete="off" class="form-control input-radius"
                formControlName="chrgVal" [class.is-invalid]="
                  chrgVal.invalid && (chrgVal.dirty || chrgVal.touched)
                " />
              <div *ngIf="chrgVal.invalid && (chrgVal.dirty || chrgVal.touched)" class="invalid-feedback">
                <div *ngIf="chrgVal.errors?.['required']">
                  Charge Value is required
                </div>
                <div *ngIf="chrgVal.errors?.['notANumber']">
                  {{ "common.notANumber" | translate }}
                </div>
              </div>
              <label>Charge Value</label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-floating mb-3">
              <input type="text" style="text-transform: capitalize" autocomplete="off" class="form-control input-radius"
                formControlName="calcOrdr" [class.is-invalid]="
                  calcOrdr.invalid && (calcOrdr.dirty || calcOrdr.touched)
                " />
              <div *ngIf="calcOrdr.invalid && (calcOrdr.dirty || calcOrdr.touched)" class="invalid-feedback">
                <div *ngIf="calcOrdr.errors?.['required']">
                  Calculation Order is required
                </div>
                <div *ngIf="calcOrdr.errors?.['notANumber']">
                  {{ "common.notANumber" | translate }}
                </div>
              </div>
              <label>Calculation Order</label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-floating mb-3">
              <input type="text" style="text-transform: capitalize" autocomplete="off" class="form-control input-radius"
                formControlName="calcOfChrg" [class.is-invalid]="
                  calcOfChrg.invalid && (calcOfChrg.dirty || calcOfChrg.touched)
                " />
              <div *ngIf="calcOfChrg.invalid && (calcOfChrg.dirty || calcOfChrg.touched)" class="invalid-feedback">
                <div *ngIf="calcOfChrg.errors?.['required']">
                  Calculation of Charge is required
                </div>
                <div *ngIf="calcOfChrg.errors?.['notANumber']">
                  {{ "common.notANumber" | translate }}
                </div>
              </div>
              <label>Calculation of Charge</label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-floating mb-3">
              <input type="text" style="text-transform: capitalize" autocomplete="off" class="form-control input-radius"
                formControlName="sgrtInstNum" [class.is-invalid]="
                  sgrtInstNum.invalid &&
                  (sgrtInstNum.dirty || sgrtInstNum.touched)
                " />
              <div *ngIf="
                  sgrtInstNum.invalid &&
                  (sgrtInstNum.dirty || sgrtInstNum.touched)
                " class="invalid-feedback">
                <div *ngIf="sgrtInstNum.errors?.['required']">
                  {{ "productRules.segregateInstallmentRequire" | translate }}
                </div>
                <div *ngIf="sgrtInstNum.errors?.['notANumber']">
                  {{ "common.notANumber" | translate }}
                </div>
              </div>
              <label>{{
                "productRules.segregateInstallment" | translate
                }}</label>
            </div>
          </div>
          <div class="col-md-12 mt-2">
            <div class="d-flex align-items-center mb-3 ps-2">
              <div class="checkbox-wrapper custom-checkbox ">
                <input type="checkbox" class="form-check-input pointer" formControlName="adjRndngFlg" />
                <span class="h6">&nbsp;Rounding Adj Flag</span>
              </div>
              <!-- <div class="checkbox-wrapper custom-checkbox mx-5">
                <input type="checkbox" class="form-check-input pointer" />
                <span class="h6">&nbsp;GI Accrul</span>
              </div> -->
              <div class="d-flex align-items-center justify-content-between">
                <div class="radio mx-5">
                  <input id="chrgTypFlg" type="radio" value="1" formControlName="chrgTypFlg" />
                  <label for="chrgTypFlg" class="radio-label h6 mb-0">Rate Type Flat</label>
                </div>
                <div class="radio">
                  <input id="percent" type="radio" value="0" formControlName="chrgTypFlg" />
                  <label for="percent" class="radio-label h6 mb-0">Percent</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="button_div mt-3">
              <button class="btn login-button-class" [class.pointerNone]="_sharedSvc.loading"
                [disabled]="_sharedSvc.loading">
                {{ "common.ok" | translate }}
              </button>
              <button class="btn login-button-class cancelBtn" (click)="d('Cross click')" type="reset">
                {{ "common.cancel" | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<!-- Charges Modal End -->

<!-- Slabs Modal begins -->
<ng-template id="display-modal" #slabsModal let-c="close" let-d="dismiss">
  <div class="modal-body" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
    [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
    <div class="p-3">
      <div class="row mb-3">
        <div class="col-sm-8 col-md-8 col-lg-8">
          <h4>Slabs of Charges</h4>
        </div>
        <div class="col-sm-4 col-md-4 col-lg-4">
          <div class="add-button" (click)="openModal(valueModal, 'popupWidth1k')">
            <i class="fa fa-plus"></i>
          </div>
        </div>
      </div>
      <div class="modal-card p-0">
        <div class="table-responsive">
          <table class="table table-bordered modal-table mb-0">
            <thead>
              <tr>
                <td>Start Limit</td>
                <td>End Limit</td>
                <td>{{ "charges.value" | translate }}</td>
                <td class="text-center">Action</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Start Limit 1</td>
                <td>End Limit 1</td>
                <td>Value wil be here</td>
                <td class="text-center">
                  <div class="action-inner">
                    <i class="fa fa-pencil cursor-pointer"></i>
                    <i class="fa fa-trash cursor-pointer"></i>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="button_div mt-3">
          <button class="btn login-button-class" [class.pointerNone]="_sharedSvc.loading"
            [disabled]="_sharedSvc.loading">
            {{ "common.ok" | translate }}
          </button>
          <button class="btn login-button-class cancelBtn" (click)="d('Cross click')" type="reset">
            {{ "common.cancel" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- Slabs Modal End -->

<!-- Value Modal begins -->
<ng-template id="display-modal" #valueModal let-c="close" let-d="dismiss">
  <div class="modal-body" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
    [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
    <div class="p-3">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center mb-3">
            <h4>{{ "charges.value" | translate }}</h4>
          </div>
        </div>
      </div>
      <form [formGroup]="valueFormData" #value_FormData="ngForm" (ngSubmit)="saveValueForm(valueFormData)">
        <div class="row">
          <div class="col-md-4">
            <div class="form-floating mb-3">
              <input type="text" style="text-transform: capitalize" autocomplete="off" class="form-control input-radius"
                formControlName="minLimit" [class.is-invalid]="
                  minLimit.invalid && (minLimit.dirty || minLimit.touched)
                " />
              <div *ngIf="minLimit.invalid && (minLimit.dirty || minLimit.touched)" class="invalid-feedback">
                <div *ngIf="minLimit.errors?.['required']">
                  Min Limit is required.
                </div>
              </div>
              <label>Min Limit</label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-floating mb-3">
              <input type="text" style="text-transform: capitalize" autocomplete="off" class="form-control input-radius"
                formControlName="maxLimit" [class.is-invalid]="
                  maxLimit.invalid && (maxLimit.dirty || maxLimit.touched)
                " />
              <div *ngIf="maxLimit.invalid && (maxLimit.dirty || maxLimit.touched)" class="invalid-feedback">
                <div *ngIf="maxLimit.errors?.['required']">
                  Max Limit is required.
                </div>
              </div>
              <label>Max Limit</label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-floating mb-3">
              <input type="text" style="text-transform: capitalize" autocomplete="off" class="form-control input-radius"
                formControlName="value" [class.is-invalid]="
                  value.invalid && (value.dirty || value.touched)
                " />
              <div *ngIf="value.invalid && (value.dirty || value.touched)" class="invalid-feedback">
                <div *ngIf="value.errors?.['required']">
                  {{ "charges.valueRequire" | translate }}
                </div>
              </div>
              <label>{{ "charges.value" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="button_div mt-3">
              <button class="btn login-button-class" [class.pointerNone]="_sharedSvc.loading"
                [disabled]="_sharedSvc.loading">
                {{ "common.ok" | translate }}
              </button>
              <button class="btn login-button-class cancelBtn" (click)="d('Cross click')" type="reset">
                {{ "common.cancel" | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<!-- Value Modal End -->

<!-- Installment Modal begins -->
<ng-template id="display-modal" #installmentModal let-c="close" let-d="dismiss">
  <div class="modal-body" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
    [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
    <div class="p-3">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center mb-3">
            <h4>Assign Cheque</h4>
          </div>
        </div>
      </div>
      <div class="modal-card p-0">
        <div class="table-responsive">
          <table class="table table-bordered modal-table mb-0">
            <thead>
              <tr>
                <td class="text-start">Installment #</td>
                <td class="text-end">Select</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-start">1</td>
                <td class="text-end">
                  <div class="checkbox-wrapper custom-checkbox">
                    <input type="checkbox" class="form-check-input pointer" />
                    <span></span>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="text-start">2</td>
                <td class="text-end">
                  <div class="checkbox-wrapper custom-checkbox">
                    <input type="checkbox" class="form-check-input pointer" />
                    <span></span>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="text-start">3</td>
                <td class="text-end">
                  <div class="checkbox-wrapper custom-checkbox">
                    <input type="checkbox" class="form-check-input pointer" />
                    <span></span>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="text-start">4</td>
                <td class="text-end">
                  <div class="checkbox-wrapper custom-checkbox">
                    <input type="checkbox" class="form-check-input pointer" />
                    <span></span>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="text-start">5</td>
                <td class="text-end">
                  <div class="checkbox-wrapper custom-checkbox">
                    <input type="checkbox" class="form-check-input pointer" />
                    <span></span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="button_div mt-3">
          <button class="btn login-button-class" [class.pointerNone]="_sharedSvc.loading"
            [disabled]="_sharedSvc.loading">
            {{ "common.ok" | translate }}
          </button>
          <button class="btn login-button-class cancelBtn" (click)="d('Cross click')" type="reset">
            {{ "common.cancel" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- Installment Modal End -->