<section class="p-3">
  <div class="row mb-3">
    <div class="col-md-8">
      <h4>{{ "productRules.creditTerms" | translate }}</h4>
    </div>
    <div class="col-md-4">
      <div class="add-button" (click)="openModal(creditTerms , '' , false)">
        <i class="fa fa-plus"></i>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12">
      <div class="loan-table">
        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
              <tr>
                <td>{{ "productRules.terms" | translate }}</td>
                <td>{{ "productRules.installmentFrequency" | translate }}</td>
                <td>{{ "common.rules" | translate }}</td>
                <td class="text-center">{{ "common.action" | translate }}</td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let list of CreditTermsList">
                <td>{{list.trmKey}}</td>
                <td>{{list.pymtFreqKey === 247 ? "30" : ''}}</td>
                <td>{{list.rulSeq === 1 ? 'Default' : ''}}</td>
                <td class="text-center">
                  <div class="action-inner">
                    <i class="fa fa-pencil cursor-pointer" (click)="editCreditTerms(creditTerms,list.prdTrmSeq,true)"></i>
                    <i class="fa fa-trash cursor-pointer" (click)="deleteCreditTerm(list.prdTrmSeq)"></i>
                    <i class="fa fa-history cursor-pointer"></i>
                  </div>
                </td>
              </tr>
            
            </tbody>
          </table>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6">
              <span class="clr-9d9a9a">{{ "common.showing" | translate }}
                {{ "common.1To3Of" | translate }} 3
                {{ "common.entries" | translate }}</span>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6">
              <div class="float-right">
                <nav aria-label="Page navigation example">
                  <ul class="pagination">
                    <li class="page-item">
                      <a class="page-link">{{ "common.first" | translate }}</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link">{{
                        "common.previous" | translate
                        }}</a>
                    </li>
                    <li class="page-item"><a class="page-link">1</a></li>
                    <li class="page-item"><a class="page-link">2</a></li>
                    <li class="page-item"><a class="page-link">3</a></li>
                    <li class="page-item"><a class="page-link">4</a></li>
                    <li class="page-item"><a class="page-link">5</a></li>
                    <li class="page-item">
                      <a class="page-link">{{ "common.next" | translate }}</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link">{{ "common.last" | translate }}</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="p-3">
  <div class="row mb-3">
    <div class="col-md-8">
      <h4>{{ "productRules.principalAmount" | translate }}</h4>
    </div>
    <div class="col-md-4">
      <div class="add-button" (click)="openModal(principleAmount,'', false)">
        <i class="fa fa-plus"></i>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12">
      <div class="loan-table">
        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
              <tr>
                <td>{{ "productRules.minAmount" | translate }}</td>
                <td>{{ "productRules.maxAmount" | translate }}</td>
                <td>{{ "productRules.segregateInstallment" | translate }}</td>
                <td>{{ "questionnaire.rule" | translate }}</td>
                <td class="text-center">{{ "common.action" | translate }}</td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let list of principleAmountList">
                <td>{{ list.minAmt }}</td>
                <td>{{ list.maxAmt }}</td>
                <td>{{ list.sgrtInstNum }}</td>
                <td>{{ list.rulSeq }}</td>
                <td class="text-center">
                  <div class="action-inner">
                    <i class="fa fa-pencil cursor-pointer" (click)="editprincipleAmount(principleAmount,list.prdPpalLmtSeq,true)"></i>
                    <i class="fa fa-trash cursor-pointer" (click)="deletePrincipleAmount(list.prdPpalLmtSeq)"></i>
                    <i class="fa fa-history cursor-pointer"></i>
                  </div>
                </td>
              </tr>
              <!-- <tr>
                                <td>20000</td>
                                <td>150000</td>
                                <td>+12</td>
                                <td>+ Default</td>
                                <td class="text-center">
                                    <div class="action-inner">
                                        <i class="fa fa-pencil cursor-pointer"></i>
                                        <i class="fa fa-trash cursor-pointer"></i>
                                        <i class="fa fa-history cursor-pointer"></i>
                                    </div>
                                </td>
                            </tr> -->
            </tbody>
          </table>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6">
              <span class="clr-9d9a9a">{{ "common.showing" | translate }}
                {{ "common.1To3Of" | translate }} 3
                {{ "common.entries" | translate }}</span>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6">
              <div class="float-right">
                <nav aria-label="Page navigation example">
                  <ul class="pagination">
                    <li class="page-item">
                      <a class="page-link">{{
                        "common.first" | translate
                        }}</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link">{{
                        "common.previous" | translate
                        }}</a>
                    </li>
                    <li class="page-item"><a class="page-link">1</a></li>
                    <li class="page-item"><a class="page-link">2</a></li>
                    <li class="page-item"><a class="page-link">3</a></li>
                    <li class="page-item"><a class="page-link">4</a></li>
                    <li class="page-item"><a class="page-link">5</a></li>
                    <li class="page-item">
                      <a class="page-link">{{ "common.next" | translate }}</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link">{{ "common.last" | translate }}</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="p-3">
  <div class="row mb-3">
    <div class="col-md-6">
      <h4>{{ "productRules.advancedRules" | translate }}</h4>
    </div>
    <div class="col-md-6">
      <div class="display-flex float-right flex-reverse">
        <div class="add-button ms-2 me-2" (click)="openModal(advanceRules, 'popupWidth1k' , false)">
          <i class="fa fa-plus"></i>
        </div>
        <h4>{{ "productRules.selectRules" | translate }}</h4>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12">
      <div class="loan-table">
        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
              <tr>
                <td>{{ "rules.ruleID" | translate }}</td>
                <td>{{ "rules.ruleName" | translate }}</td>
                <td>{{ "rules.ruleCategory" | translate }}</td>
                <td>{{ "productRules.condition" | translate }}</td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let list of advanceRulesList">
                <td>12</td>
                <td>{{ "rules.ruleName" | translate }} 1</td>
                <td>{{ "rules.ruleCategory" | translate }} 1</td>
                <td>{{ "productRules.condition" | translate }}</td>
              </tr>
              
              <!-- <tr *ngFor="let data of _clintOnboardingSvc?.getAllClient">
                                    <td>{{data?.clntId}}</td>
                                    <td>{{data?.cnicNum}}</td>
                                    <td>{{data?.cnicNum}}</td>
                                    <td>{{data?.cnicNum}}</td>
                                </tr> -->
            </tbody>
          </table>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6">
              <span class="clr-9d9a9a">{{ "common.showing" | translate }}
                {{ "common.1To3Of" | translate }} 3
                {{ "common.entries" | translate }}</span>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6">
              <div class="float-right">
                <nav aria-label="Page navigation example">
                  <ul class="pagination">
                    <li class="page-item">
                      <a class="page-link">{{ "common.first" | translate }}</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link">{{
                        "common.previous" | translate
                        }}</a>
                    </li>
                    <li class="page-item"><a class="page-link">1</a></li>
                    <li class="page-item"><a class="page-link">2</a></li>
                    <li class="page-item"><a class="page-link">3</a></li>
                    <li class="page-item"><a class="page-link">4</a></li>
                    <li class="page-item"><a class="page-link">5</a></li>
                    <li class="page-item">
                      <a class="page-link">{{ "common.next" | translate }}</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link">{{ "common.last" | translate }}</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="row mb-3">
  <div class="col-sm-12 col-md-12 col-lg-12">
    <div class="button_div mt-3">
      <button class="btn login-button-class" type="button" (click)="moveToNextTab()">
        {{ "common.continue" | translate }}
      </button>
      <button class="btn login-button-class secondaryBtn" [disabled]="_sharedSvc.loading"
        [class.pointerNone]="_sharedSvc.loading">
        {{ "common.save" | translate }}
      </button>
    </div>
  </div>
</div>

<!-- Credit Terms Modal begins -->
<ng-template id="display-modal" #creditTerms let-c="close" let-d="dismiss">
  <div class="modal-body" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
    [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
    <div class="p-3">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center mb-3">
            <h4>{{ "productRules.creditTerms" | translate }}</h4>
          </div>
        </div>
      </div>
      <form [formGroup]="creditTermsFormdata" #creditTerms_Formdata="ngForm"
        (ngSubmit)="saveCreditTermsForm(creditTermsFormdata)">
        <div class="row">
          <div class="col-md-6">
            <div class="form-floating mb-3">
              <select class="form-control form-select input-radius" formControlName="trmKey" [class.is-invalid]="
                  terms.invalid && (terms.dirty || terms.touched)
                ">
                <option hidden value=""></option>
                <option *ngFor="let item of dropdowncreditTermsList"  [value]="item?.refCdSeq">{{ item.refCdDscr }}</option>
               
              </select>
              <div *ngIf="terms.invalid && (terms.dirty || terms.touched)" class="invalid-feedback">
                <div *ngIf="terms.errors?.['required']">
                  {{ "productRules.termsRequire" | translate }}
                </div>
              </div>
              <label>{{ "productRules.terms" | translate }}</label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-floating mb-3">
              <select class="form-control form-select input-radius" formControlName="pymtFreqKey"
                [class.is-invalid]="
                  installmentFrequency.invalid &&
                  (installmentFrequency.dirty || installmentFrequency.touched)
                ">
                <option hidden value=""></option>
                <option *ngFor="let item of dropdowngInstallmentFrequency"  [value]="item?.refCdSeq">{{ item.refCdDscr }}</option>
               
              </select>
              <div *ngIf="
                  installmentFrequency.invalid &&
                  (installmentFrequency.dirty || installmentFrequency.touched)
                " class="invalid-feedback">
                <div *ngIf="installmentFrequency.errors?.['required']">
                  {{ "productRules.installmentFrequencyRequire" | translate }}
                </div>
              </div>
              <label>{{
                "productRules.installmentFrequency" | translate
                }}</label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-floating mb-3">
              <select class="form-control form-select input-radius" formControlName="rulSeq"
                [class.is-invalid]="
                  rulSeq.invalid &&
                  (rulSeq.dirty || rulSeq.touched)
                ">
                <option hidden value=""></option>
                <option *ngFor="let item of dropdownRule"  [value]="item?.rulSeq">{{ item.rulNm }}</option>
               
              </select>
              <div *ngIf="
                  rulSeq.invalid &&
                  (rulSeq.dirty || rulSeq.touched)
                " class="invalid-feedback">
                <div *ngIf="rulSeq.errors?.['required']">
                  Rule is required
                </div>
              </div>
              <label>Rule</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="button_div mt-3">
              <button class="btn login-button-class" [class.pointerNone]="_sharedSvc.loading"
                [disabled]="_sharedSvc.loading">
                {{ "common.ok" | translate }}
              </button>
              <button class="btn login-button-class cancelBtn" (click)="d('Cross click')" type="reset">
                {{ "common.cancel" | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<!-- Credit Terms Modal End -->

<!-- Principle Amount Modal begins -->
<ng-template id="display-modal" #principleAmount let-c="close" let-d="dismiss">
  <div class="modal-body" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
    [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
    <div class="p-3">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center mb-3">
            <h4> {{isEdit === true ? 'Edit' : 'Add'}} {{ "productRules.principalAmount" | translate }}</h4>
          </div>
        </div>
      </div>
      <form [formGroup]="principleAmountFormdata" #principleAmount_Formdata="ngForm"
        (ngSubmit)="savePrincipleAmountForm(principleAmountFormdata)">
        <div class="row">
          <div class="col-md-4">
            <div class="form-floating mb-3">
              <input type="text" style="text-transform: capitalize" autocomplete="off" class="form-control input-radius"
                formControlName="minAmt" [class.is-invalid]="
                  minAmount.invalid && (minAmount.dirty || minAmount.touched)
                " />
              <div *ngIf="
                  minAmount.invalid && (minAmount.dirty || minAmount.touched)
                " class="invalid-feedback">
                <div *ngIf="minAmount.errors?.['required']">
                  {{ "productRules.minAmountRequire" | translate }}
                </div>
              </div>
              <label>{{ "productRules.minAmount" | translate }}</label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-floating mb-3">
              <input type="text" style="text-transform: capitalize" autocomplete="off" class="form-control input-radius"
                formControlName="maxAmt" [class.is-invalid]="
                  maxAmount.invalid && (maxAmount.dirty || maxAmount.touched)
                " />
              <div *ngIf="
                  maxAmount.invalid && (maxAmount.dirty || maxAmount.touched)
                " class="invalid-feedback">
                <div *ngIf="maxAmount.errors?.['required']">
                  {{ "productRules.maxAmountRequire" | translate }}
                </div>
              </div>
              <label>{{ "productRules.maxAmount" | translate }}</label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-floating mb-3">
              <input type="text" style="text-transform: capitalize" autocomplete="off" class="form-control input-radius"
                formControlName="sgrtInstNum" [class.is-invalid]="
                  segregateInstallment.invalid &&
                  (segregateInstallment.dirty || segregateInstallment.touched)
                " />
              <div *ngIf="
                  segregateInstallment.invalid &&
                  (segregateInstallment.dirty || segregateInstallment.touched)
                " class="invalid-feedback">
                <div *ngIf="segregateInstallment.errors?.['required']">
                  {{ "productRules.segregateInstallmentRequire" | translate }}
                </div>
              </div>
              <label>{{
                "productRules.segregateInstallment" | translate
                }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12">
            <div class="button_div mt-3">
              <button class="btn login-button-class" [class.pointerNone]="_sharedSvc.loading"
                [disabled]="_sharedSvc.loading">
                {{ "common.ok" | translate }}
              </button>
              <button class="btn login-button-class cancelBtn" (click)="d('Cross click')" type="reset">
                {{ "common.cancel" | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<!-- Principle Amount Modal End -->

<!-- Advance Rules Modal begins -->
<ng-template id="display-modal" #advanceRules let-c="close" let-d="dismiss">
  <div class="modal-body" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
    [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
    <div class="p-3">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center">
            <h4>{{ "productRules.advancedRules" | translate }}</h4>
          </div>
        </div>
      </div>
      <div class="modal-card p-0">
        <div class="table-responsive">
          <table class="table table-bordered modal-table mb-0">
            <thead>
              <tr>
                <td class="text-start rtl-text-end">{{ "rules.ruleID" | translate }}</td>
                <td>{{ "rules.ruleName" | translate }}</td>
                <td>{{ "rules.ruleCategory" | translate }}</td>
                <td class="text-start" width="270">
                  {{ "productRules.condition" | translate }}
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-start rtl-text-end">
                  <div class="checkbox-wrapper custom-checkbox">
                    <input type="checkbox" class="form-check-input pointer" />
                    <span class="ms-2">Cleint Age 18 - 59</span>
                  </div>
                </td>
                <td>{{ "rules.ruleName" | translate }}</td>
                <td>{{ "rules.ruleCategory" | translate }} 1</td>
                <td class="text-start">(clnt_age betwen 18*12 and (59*12))</td>
              </tr>
              <tr>
                <td class="text-start rtl-text-end">
                  <div class="checkbox-wrapper custom-checkbox">
                    <input type="checkbox" class="form-check-input pointer" />
                    <span class="ms-2">Cleint Age 18 - 59</span>
                  </div>
                </td>
                <td>{{ "rules.ruleName" | translate }}</td>
                <td>{{ "rules.ruleCategory" | translate }} 2</td>
                <td class="text-start">(clnt_age betwen 18*12 and (59*12))</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-sm-12 col-md-12">
        <div class="button_div mt-3">
          <button class="btn login-button-class" [class.pointerNone]="_sharedSvc.loading"
            [disabled]="_sharedSvc.loading">
            {{ "common.ok" | translate }}
          </button>
          <button class="btn login-button-class cancelBtn" (click)="d('Cross click')" type="reset">
            {{ "common.cancel" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- Advance Rules Modal End -->