import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { SharedService } from '../../shared/service/shared.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule, DOCUMENT } from '@angular/common';
import { tasdRepDataString } from 'src/app/global';
import { TranslateService } from '@ngx-translate/core';
declare let html2pdf: any

@Component({
  selector: 'app-tasdeeq-report',
  templateUrl: './tasdeeq-report.component.html',
  styleUrls: ['./tasdeeq-report.component.css']
})
export class TasdeeqReportComponent {
  @ViewChild('pdfContent') pdfContent: ElementRef;
  isPdfContentVisible: boolean = false;
  filePath: string = '/assets/images/Tasdeeq_logo-2.png';
  reportTitle: string = 'CONSUMER CREDIT INFORMATION REPORT';

  JSONdata: any = tasdRepDataString;
  CurrentCreditHistoryFirstHalf: any;
  CurrentCreditHistorySecondHalf: any;


  today: Date = new Date();
  constructor(@Inject(DOCUMENT) private document: Document,
    public _sharedSvc: SharedService,
    private modalService: NgbModal,
    public translate: TranslateService
  ) {
  }
  ngOnInit(): void {
    console.log('Tasdeeq Report', this.JSONdata)
    console.log('Type', typeof (this.JSONdata))
  }

  openQstnrModal(content: any) {
    localStorage.removeItem("selectedOrderData");

    this.modalService.open(content, {
      // backdrop: "static",
      // keyboard: false,
      size: 'xl',
      windowClass: "popupWidth1-2k",
      // backdropClass: 'light-blue-backdrop',
      centered: true
    });
  }

  generatePDF() {
    const date = new Date();
    const formattedDate = date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
    const formattedTime = date.toLocaleTimeString('en-US', {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });
    this.fileToBase64(this.filePath)
      .then(base64String => {
        this.filePath = base64String;
      })
      .catch(error => {
        console.error('Error:', error);
      });

    const element = this.document.getElementById('pdf-content');
    const options = {
      margin: [20, 10, 15, 10],
      filename: 'tasdeeq-report.pdf',
      image: { type: 'jpeg', quality: 1 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'letter', orientation: 'portrait' },
    };
    html2pdf().from(element).set(options).toPdf().get('pdf').then(function (pdf) {
      var totalPages = pdf.internal.getNumberOfPages();

      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.addImage('/assets/images/Tasdeeq_logo-2.png', 'PNG', 10, 4, 30, 8.5);
        pdf.setFontSize(11);
        pdf.text(62, 9, "CONSUMER CREDIT INFORMATION REPORT");
        pdf.setFontSize(10);
        pdf.text(179.6, 6, "CONFIDENTIAL",);
        pdf.setFontSize(8);
        pdf.text(164, 9.5, "Date Time:" + formattedDate + " " + formattedTime);
        pdf.text(163, 12.7, "Report Ref No: 202307-46076439");
        pdf.text(10, 274, "Report Ref No: 202307-46076439");
        pdf.text(100, 274, "**Disclaimer");
        pdf.text(192, 274, "Page " + i + " of " + totalPages)
      }
    }).save();
  }


  fileToBase64(file: string) {
    return new Promise<string>((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        const reader = new FileReader();
        reader.onloadend = function () {
          const base64String = reader.result as string;
          const base64Content = base64String.split(',')[1]; // Get the Base64 string without the data URL prefix
          resolve(base64Content);
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.readAsDataURL(xhr.response); // Read the file as a data URL
      };
      xhr.onerror = function (error) {
        reject(error);
      };
      xhr.open('GET', file);
      xhr.responseType = 'blob';
      xhr.send();
    });
  }
  getCreditHistoryLength(serialNo: any) {
    this.getCurrentCreditHistory(serialNo);
    return this.JSONdata.data.creditHistory.length;
  }
  getCurrentCreditHistory(serialNo: any) {
    let temp: any = this.JSONdata.data.creditHistory.filter((item: any) => item.LOAN_SERIAL_NUMBER === serialNo);
    this.CurrentCreditHistoryFirstHalf = temp.slice(0, Math.ceil(temp.length / 2));
    this.CurrentCreditHistorySecondHalf = temp.slice(Math.ceil(temp.length / 2));
  }

}