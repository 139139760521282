import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { SharedService } from 'src/app/components/shared/service/shared.service';
import { DisbursementFormService } from '../shared/service/disbursement-form.service';
import { DisbustmentCredit } from '../shared/model/disbust-credit';
import { ClintOnboardingService } from 'src/app/components/clint-onboarding/shared/service/clint-onboarding.service';
import { DisbustmentSchedule } from '../shared/model/disbust-schedule';
import { DisbustDetail } from '../shared/model/disbustDetail';
import { TranslateService } from '@ngx-translate/core';

interface IPaymentSchedule {
  firstInstallmentDate: string;
}

@Component({
  selector: 'app-disbursement-repayment-schedule',
  templateUrl: './disbursement-repayment-schedule.component.html',
  styleUrls: ['./disbursement-repayment-schedule.component.css']
})
export class DisbursementRepaymentScheduleComponent implements OnInit {

  paymentScheduleForm: FormGroup;
  paymentSchedule: IPaymentSchedule;
  tableData2: any

  currentDate: any = new Date().toISOString().split("T")[0];
  maxDate = new Date();
  maxDateStr = "";
  userId: string;
  disbustment: DisbustDetail;
  schedules: DisbustmentSchedule[];
  yearMinDate: any ;
  yearMaxDate:any;
  constructor(
    private modalService: NgbModal,
    public router: Router,
    private formBuilder: FormBuilder,
    public _clintOnboardingSvc: ClintOnboardingService,
    public _sharedSvc: SharedService,
    private dismtService: DisbursementFormService,
    public translate: TranslateService
  ) {
    this.paymentSchedule = {} as IPaymentSchedule;
    this.maxDate.setDate(this.maxDate.getDate() + 15);
    this.maxDateStr = this.maxDate.toISOString().split("T")[0];

    this.disbustment = this.dismtService.disbustment;
    this.dismtService.getDisbustmentPaymentSchedule(this.disbustment.crdtAppSeq, (res) => {
      //   // console.log(res);
      //   if(res){this.dismtService.disbustmentVocher=res;

      //   }
      // } );

      // this.dismtService.getDisbustmentPaymentSchedule("5050503233774414", (res)=>{
      // console.log(res);
      if (res) {
        this.schedules = res;

        this.schedules.forEach(s => {
          s.dueDt = s.dueDt.split("T")[0];
          this.disbustment.totalReceivableAmount += (s.othChrgAmt + s.srvcChrgAmt);
        });
        localStorage.setItem('disbustment', JSON.stringify(this.disbustment));
        this.schedules.forEach(i => this.dismtService.schedulePayment.push(i))
        // let x= new DisbustmentSchedule();
        //  x.pymtSchedHdrSeq= 50061; x.crdtAppSeq= 5050503233774414; x.pymtSchdDtlSeq= 50061;x.instNum= 1;
        //  x.dueDt="2023-09-05T00:00:00Z".split("T")[0]; x.prnAmount= 98847;x.srvcChrgAmount= 27000;x.othChrgAmount= 0;x.pymtStsKey= 101;
        //  x.crtdBy="devusr";x.crtdDt="2023-09-04T19:52:28Z".split("T")[0];x.lastUpdBy= "devusr";x.lastUpdDt= "2023-09-04T19:52:28Z".split("T")[0];
        //  x.delFlg= "0";
        // this.schedules.push(x); 
      }

    });

    let jwt = sessionStorage.getItem("JWT");
    const user = JSON.parse(jwt);
    this.userId = user.preferred_username;
    // console.log(user)
  }

  ngOnInit(): void {
    this.paymentScheduleForm = new FormGroup({
      firstInstallmentDate: new FormControl(this.paymentSchedule.firstInstallmentDate, [Validators.required]),
    });
    this.yearMinDate=this.afterYearDate;
    this.yearMaxDate = this.afterYearDays;
  }
  get afterYearDate(): string {
    const today = new Date();
    const afterOneYear = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate());
    return afterOneYear.toISOString().split('T')[0];
  }
  get afterYearDays(): string {
    const today = new Date();
    const afterOneYear = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate()+9);
    return afterOneYear.toISOString().split('T')[0];
  }


  openModal(content: any) {
    localStorage.removeItem("selectedOrderData");
    this.modalService.open(content, {
      // backdrop: "static",
      // keyboard: false,
      windowClass: "500",
      // backdropClass: 'light-blue-backdrop',
      centered: true
    });
  }

  createSchedule(): void {
    
    if (this.paymentScheduleForm.invalid) {
      for (const control of Object.keys(this.paymentScheduleForm.controls)) {
        this.paymentScheduleForm.controls[control].markAsTouched();
      }
      return;
    }
    this.setValueToModel();


    if (this.disbustment.firstInstallmentDate) {
      // console.log( this.disbustment.firstInstallmentDate)
      let date = new Date(this.disbustment.firstInstallmentDate);
      let da = this.disbustment.firstInstallmentDate.split("-")[2] + "-" +
        date.toLocaleString('default', { month: 'short' }).toLowerCase().substring(0, 3) + "-" +
        this.disbustment.firstInstallmentDate.split("-")[0];
      // console.log(da);
      this.dismtService.createPaymentSchedule(this.disbustment.crdtAppSeq,
        da, this.userId, (e: DisbustmentSchedule[]) => {
          // console.log(e);
          if (e && e.length) {
            e.forEach(it => {
              if (it.dueDt)
                it.dueDt = it.dueDt.split("T")[0];
              if (it.srvcChrgAmount) it.srvcChrgAmt = it.srvcChrgAmount;
              if (it.othChrgAmount != null) it.othChrgAmt = it.othChrgAmount;
              if (it.prnAmount) it.prnAmt = it.prnAmount;
              this.disbustment.totalReceivableAmount += (it.othChrgAmt + it.srvcChrgAmt);
              this.schedules.push(it);
              this.dismtService.schedulePayment.push(it);
            }
            )
            this.dismtService.disbustment.firstInstallmentDate = this.disbustment.firstInstallmentDate;
            localStorage.setItem('disbustment', JSON.stringify(this.disbustment));

          }

        });
      this.modalService.dismissAll('Save click');
    }


    // this.router.navigate(['/dashboard']);

  }

  setValueToModel() {
    
    this.disbustment.firstInstallmentDate = this.firstInstallmentDate.value;
  }
  setValueToForm() {
    this.firstInstallmentDate.setValue(this.disbustment.firstInstallmentDate);
  }
  get firstInstallmentDate() {
    return this.paymentScheduleForm.get('firstInstallmentDate')!;
  }

  disableDate() { return false; }
}