import { Component, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SharedService } from "../../shared/service/shared.service";
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from "@angular/router";
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators, NgForm, FormArray } from "@angular/forms";
import { BusinessAppraisalService } from "./shared/service/business-appraisal.service";
import { SharedPopupService } from "../../shared/service/shared-popup.service";
import { FiniancialInfoService } from "../financial-info/shared/service/finiancial-info.service";
import { PicklistService } from "../../shared/pickList/picklist.service";
import { ClintOnboardingService } from "../shared/service/clint-onboarding.service";
import { SaveBizAprsl } from "./shared/model/businessAppraisal";
import { SaveClntIncmDtl, SaveClntExpnsDtl } from "../financial-info/shared/model/financial-info";
import { Address } from "../shared/model/clintOnboarding";
import { RoutingConditionsService } from "../shared/service/routing-conditions.service";
import { TranslateService } from "@ngx-translate/core";

// interface IBasicInfo {
//   bizAprslSeq: number;
//   clntSeq: number;
//   sectKey: number;
//   actyKey: number;
//   bizDtlStr: string;
//   mnthInBiz: number;
//   bizPhNum: string;
//   delFlg: boolean;
// }
// interface IAddress {
//   clntSeq: number;
//   addrSeq: number;
//   addrRelSeq: number;
//   delFlg: boolean;
//   locSeq: number;
//   hseNum: string;
//   strt: string;
//   cmntySeq: number;
//   vlg: string;
//   othDtl: string;
//   latitude: number;
//   longitude: number;
//   mnthsOfResidence: number;
//   isPermAddress: boolean;
//   entySeq: number;
//   entyType: string;
// }
// interface IAdditionalInfo {
//   bizAprslAdtnlInfoSeq: number;
//   qstSeq: number;
//   answrSeq: number;
//   clntSeq: number;
//   delFlg: boolean;
//   tntKey: number;
// }

// interface IIncome {
//   incmDtlSeq: number;
//   clntSeq: number;
//   incmCtgryKey: number;
//   incmAmt: number;
//   incmTypKey: number;
//   qnty: number;
//   frqncy: number;
//   avgMnthlyIncm: number;
//   cmnt: string;
//   delFlg: boolean;
// }
// interface IExpense {
//   expDtlSeq: number;
//   clntSeq: number;
//   expCtgryKey: number;
//   expAmt: number;
//   expTypKey: number;
//   delFlg: false;
// }

@Component({
  selector: "app-business-appraisal",
  templateUrl: "./business-appraisal.component.html",
  styleUrls: ["./business-appraisal.component.css"],
})

export class BusinessAppraisalComponent implements OnInit {

  basicInformation: boolean = true;
  businessAddress: boolean = false;
  additionalInformation: boolean = false;
  businessInc: boolean = false;
  businessExpenses: boolean = false;
  basicInfoForm: FormGroup;
  // basicInfo: IBasicInfo;
  addressForm: FormGroup;
  // address: IAddress;
  additionalInfoForm: FormGroup;
  // additionalInfo: IAdditionalInfo;
  businessIncomeForm: FormGroup;
  // businessIncome: IIncome;
  businessExpenseForm: FormGroup;
  // businessExpense: IExpense;
  saveCnicData: any;
  modelHeading: any;
  // cityName: any;
  // districtName: any;
  // stateName: any;
  // thslName: any;
  // ucName: any;
  nosOfResidence: number;
  resOfResidence: number;
  incmCtgryKey: number = 3; //business Income
  submitted: boolean = false;
  remainingMonths: number = 0;
  savePartiallyINCData: boolean = false;
  jwt: any
  clntBizAprslSeq: any;
  index: number = 0
  index2: number = 0
  saveBizAprslModelValues: any;
  saveClntIncmDtlModelValues: any;
  saveClntExpnsDtlModelValues: any;

  disableTabs: any;

  getquestions: any = []

  constructor(
    public router: Router,
    public _sharedSvc: SharedService,
    public _businessAppraisalSvc: BusinessAppraisalService,
    public _finiancialInfoSvc: FiniancialInfoService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    public _sharedPopupSvc: SharedPopupService,
    public _picklistService: PicklistService,
    public _clintOnboardingSvc: ClintOnboardingService,
    public route: ActivatedRoute,
    public _conditionsService: RoutingConditionsService,
    public translate: TranslateService

  ) {
    // this.basicInfo = {} as IBasicInfo;
    // this.address = {} as IAddress;
    // this.additionalInfo = {} as IAdditionalInfo;
    // this.businessIncome = {} as IIncome;
    // this.businessExpense = {} as IExpense;
  }

  ngOnInit(): void {
    
    this.validationForm();
    this.pickLists();
    this.setBasicInfoVal();

    if (localStorage.getItem("readOnlyView_Client")) {
      this._conditionsService.readOnlyView_Client = true;
    }
    if (localStorage.getItem('save_ClientModel')) {
      this.saveCnicData = localStorage.getItem('save_ClientModel');
    }
    if (localStorage.getItem('SaveCnicData')) {
      this.saveCnicData = localStorage.getItem('SaveCnicData');
    }
    this.saveCnicData = JSON.parse(this.saveCnicData);
    this._sharedSvc.msg = "Record added";
    // this._businessAppraisalSvc.saveBizAprslModel = new SaveBizAprsl();

    if (this.route.snapshot.paramMap.get("id") || localStorage.getItem('saveBizAprslModelValues')) {
      // this._businessAppraisalSvc.InItGetBusinessAppraisal(this.saveCnicData?.clntSeq);

      this._businessAppraisalSvc.InItGetBusinessAppraisal(this.saveCnicData?.clntSeq, (result: any) => {
        if (result) {
          this._businessAppraisalSvc.saveBizAprslModel = result;
          // this._picklistService.InItBusinessActivity(this._businessAppraisalSvc.saveBizAprslModel.sectKey);
          this._picklistService.InItBusinessActivity(this._businessAppraisalSvc.saveBizAprslModel.sectKey, (res: any) => {
            if (res) {
              this._businessAppraisalSvc.saveBizAprslModel = result;
              this.setValueToFormS1();
            }
          });

        }
      });
    }
    // if (!this.route.snapshot.paramMap.get("id")) {
    //   if (localStorage.getItem('saveBizAprslModelValues')) {
    //     this.saveBizAprslModelValues = localStorage.getItem('saveBizAprslModelValues');
    //     this._businessAppraisalSvc.saveBizAprslModel = JSON.parse(this.saveBizAprslModelValues);
    //     this.setValueToFormS1();
    //   }
    // }

    sessionStorage.setItem('isBizAprslOpen', JSON.stringify(true));


    let invalidBizAprslAddressForm = Object.keys(this.addressForm.controls).filter(controlName =>
      this.addressForm.get(controlName).invalid
    );
    sessionStorage.setItem('invalidBizAprslAddressForm', JSON.stringify(invalidBizAprslAddressForm))




  }

  pickLists() {
    this._picklistService.InItBusinessSector();
    this._picklistService.INCOME_TYPE();
    this._picklistService.BUSINESS_FREQUENCY();
    this._picklistService.BUSINESS_EXPENSES();
    this._picklistService.BUSINESS_INCOME_TYPE();
    this._picklistService.InItGetGoCommunity();
    this._picklistService.InItGetLocations();
    this._picklistService.InItGetAllQstn();

  }

  setD_V() {
    this.jwt = sessionStorage.getItem('JWT');
    this.jwt = JSON.parse(this.jwt);

    let date = new Date()
    this._finiancialInfoSvc.saveClntIncmDtlModel = {
      clntSeq: this.saveCnicData.clntSeq,
      incmDtlSeq: null,
      incmCtgryKey: this.incmCtgryKey,
      incmAmt: null,
      incmTypKey: null,
      qnty: null,
      frqncy: null,
      avgMnthlyIncm: null,
      cmnt: '',
      crtdBy: this.jwt?.preferred_username,
      crtdDt: date,
      lastUpdBy: this.jwt?.preferred_username,
      lastUpdDt: date,
      delFlg: false
    };
  }

  setBasicInfoVal() {
    this._businessAppraisalSvc.saveBizAprslModel = {
      bizAprslSeq: null,
      clntSeq: this.saveCnicData?.clntSeq,
      sectKey: null,
      actyKey: null,
      bizDtlStr: null,
      mnthInBiz: null,
      bizPhNum: null, //this.basicInfo?.bizPhNum?.replace(/\D/g, ""),
      delFlg: false,
    };
    ;
  }

  openModal(content: any, h4: any, typ?: any) {
    this.modelHeading = h4;
    this._sharedSvc.msg = "Record added";
    this._businessAppraisalSvc.edit = false;
    this.validationForm();
    // this.businessExpenseForm?.reset(this.businessExpenseForm.value);
    // this._finiancialInfoSvc.saveClntIncmDtlModel = new SaveClntIncmDtl();
    // this.businessIncomeForm?.reset(this.businessIncomeForm.value);
    // this._finiancialInfoSvc.saveClntExpnsDtlModel = new SaveClntExpnsDtl();
    // this.businessExpenseForm?.reset(this.businessExpenseForm.value);
    this.setD_V();
    this.setBusinessIncomeForm();
    this.setBusinessExpenseForm();
    // this.setValueToFormS2();
    this._finiancialInfoSvc.dropdowns();
    if (this._picklistService.BUSINESS_EXPENSES_DataClone?.length == 0 && typ == 'biz') {
      this._sharedSvc.error(this.translate.instant('toast.noIncomeExist'));
      return;
    }
    this.modalService.open(content, {
      // backdrop: "static",
      // keyboard: false,
      windowClass: "popupWidth800",
      backdropClass: "light-blue-backdrop",
      centered: true,
    });
  }

  openMod(content: any, h4: any,) {
    this.modelHeading = h4;
    this.modalService.open(content, {
      // backdrop: "static",
      // keyboard: false,
      windowClass: "popupWidth800",
      backdropClass: "light-blue-backdrop",
      centered: true,
    });
  }

  dismissModal() {
    // console.log(this._finiancialInfoSvc.getClntAllIncmDtlSeqPG);
    this.modalService.dismissAll();

  }

  numberOnly(event: any): boolean {
    let ph = this.bizPhNum.value;
    if (ph && ph.length == 1 && ph.substring(0) != '0')
      this.bizPhNum.setValue("0");
    if (ph && ph.length == 2 && ph.substring(0, 1) != '03')
      this.bizPhNum.setValue("03");

    return true;
  }

  showBtn(B1: any, B2: any, B3: any, B4: any, B5: any, incmCtgryKey?: any) {
    
    this.basicInformation = B1;
    this.businessAddress = B2;
    this.additionalInformation = B3;
    this.businessInc = B4;
    this.businessExpenses = B5;
    this._clintOnboardingSvc.isHomeAddress = true;
    if (B1) {
      // this._businessAppraisalSvc.saveBizAprslModel = new SaveBizAprsl();
      //this.pickLists();
      this.setBasicInfoVal();
      this._businessAppraisalSvc.noOfMonth = null;
      if (this.route.snapshot.paramMap.get("id") || localStorage.getItem('saveBizAprslModelValues')) {
        // this._businessAppraisalSvc.InItGetBusinessAppraisal(this.saveCnicData?.clntSeq);
        // this._businessAppraisalSvc.InItGetBusinessAppraisal(this.saveCnicData?.clntSeq, (result: any) => {
        //   if (result) {
        //     this._businessAppraisalSvc.saveBizAprslModel = result;
        //     // this._picklistService.InItBusinessActivity(this._businessAppraisalSvc.saveBizAprslModel.sectKey);
        //     this._picklistService.InItBusinessActivity(this._businessAppraisalSvc.saveBizAprslModel.sectKey, (res: any) => {
        //       if (res) {
        //         this._businessAppraisalSvc.saveBizAprslModel = result;
        //         this.setValueToFormS1();
        //       }
        //     });

        //   }
        // });
      }
    }
    if (B2) {
      
      sessionStorage.setItem('isBizAprslAddressOpen', JSON.stringify(true));
      sessionStorage.setItem('isBizAprslAddressSaveClicked', JSON.stringify(false));

      let check = JSON.parse(sessionStorage.getItem('clientOnbrdViewCheck'));
      let a = JSON.parse(sessionStorage.getItem('isBizAprslAddressOpen'));
      let b = JSON.parse(sessionStorage.getItem('isBizAprslAddressSaveClicked'));

      if (check === false || check === null) {
        if (a === true && b === false) {
          this._sharedSvc.disableTabsForAddress = false;
        }
      }

      this.validationForm();
      this._clintOnboardingSvc.bizAddAsClientAdd = false;
      // this._clintOnboardingSvc.addressModel = new Address();
      // this.address = new Address();
      if (localStorage.getItem('clntBizAprslSeq')) {
      } else {
        this._sharedSvc.error(this.translate.instant('toast.saveBasicInfoFirst'));
        return;
      }
      this.clntBizAprslSeq = localStorage.getItem('clntBizAprslSeq');
      this.clntBizAprslSeq = JSON.parse(this.clntBizAprslSeq);
      // this._clintOnboardingSvc.addressModel.isPermAddress = true;
      // this._picklistService.InItGetGoCommunity();
      // this._picklistService.InItGetLocations();
      if (localStorage.getItem('bizAppraisalAddress')) {
        this._clintOnboardingSvc.addressModel = JSON.parse(localStorage.getItem('bizAppraisalAddress'));

        this._clintOnboardingSvc.locationData = localStorage.getItem('locationData');
        this._clintOnboardingSvc.locationData = JSON.parse(this._clintOnboardingSvc.locationData);
        for (let i = 0; i < this._clintOnboardingSvc.locationData?.length; i++) {
          if (this._clintOnboardingSvc.locationData[i].locationSeq == this._clintOnboardingSvc.addressModel.locSeq) {
            this.onSelectionArea(this._clintOnboardingSvc.locationData[i]);
          }
        }
        this.setValueToFormS2();
        this._clintOnboardingSvc.isHomeAddress = false;

      }
      if (this.clntBizAprslSeq && this.route.snapshot.paramMap.get("id")) {
        
        // this._clintOnboardingSvc.InItGetAddress(this.clntBizAprslSeq);
        this._clintOnboardingSvc.InItGetAddress(this.clntBizAprslSeq, (result: any) => {
          if (result) {
            
            this._clintOnboardingSvc.addressModel = result;
            this._clintOnboardingSvc.locationData = localStorage.getItem('locationData');
            this._clintOnboardingSvc.locationData = JSON.parse(this._clintOnboardingSvc.locationData);
            for (let i = 0; i < this._clintOnboardingSvc.locationData?.length; i++) {
              if (this._clintOnboardingSvc.locationData[i].locationSeq == this._clintOnboardingSvc.addressModel.locSeq) {
                this.onSelectionArea(this._clintOnboardingSvc.locationData[i]);
              }
            }
            this.setValueToFormS2();
          }
        });
      }
      let invalidBizAprslAddressForm = Object.keys(this.addressForm.controls).filter(controlName =>
        this.addressForm.get(controlName).invalid
      );
      sessionStorage.setItem('invalidBizAprslAddressForm', JSON.stringify(invalidBizAprslAddressForm))
    }
    if (B3) {
      sessionStorage.setItem('isBizAprslAdditionalInfoOpen', JSON.stringify(true));

      // if (localStorage.getItem('saveBznAddress')) {
      // } else {
      //   this._sharedSvc.error('Please save Business Address First');
      //   return;
      // }
      this.submitted = false;
      this._businessAppraisalSvc.quesArr = [];
      this._businessAppraisalSvc.InItGetAllBusinessAppraisalAdd(this.saveCnicData.clntSeq);
      if (this.route.snapshot.paramMap.get("id")) {
        this._businessAppraisalSvc.InItGetAllBusinessAppraisalAdd(this.saveCnicData.clntSeq);
        this.setValueToFormS3()
      }
    }
    if (B4) {
      if (this.route.snapshot.paramMap.get("id")) {
        
        this._finiancialInfoSvc.InItGetClntAllIncmDtlSeq(this.saveCnicData.clntSeq, 3);
      }
      if (!this.route.snapshot.paramMap.get("id")) {
        if (localStorage.getItem('saveClntIncmDtlModelValues')) {
          this.saveClntIncmDtlModelValues = localStorage.getItem('saveClntIncmDtlModelValues');
          this.saveClntIncmDtlModelValues = JSON.parse(this.saveClntIncmDtlModelValues);
          this._finiancialInfoSvc.getClntAllIncmDtlSeqPG = this.saveClntIncmDtlModelValues;
          this._finiancialInfoSvc.businessIncome = this.saveClntIncmDtlModelValues;
          // this._sharedSvc.collectionSize = this._finiancialInfoSvc.getAllIncome?.length;
          // this._finiancialInfoSvc.getClntAllIncmDtlSeqPG = this._finiancialInfoSvc.getAllIncome.slice((this._sharedSvc.page - 1) * this._sharedSvc.pageSize, (this._sharedSvc.page - 1) * this._sharedSvc.pageSize + this._sharedSvc.pageSize);
        }
      }
    }
    if (B5) {
      if (this.route.snapshot.paramMap.get("id")) {
        this._finiancialInfoSvc.InItGetClntAllExpnsDtlSeq(this.saveCnicData.clntSeq, 1);
      }
      if (!this.route.snapshot.paramMap.get("id")) {
        if (localStorage.getItem('saveClntExpnsDtlModelValues')) {
          this.saveClntExpnsDtlModelValues = localStorage.getItem('saveClntExpnsDtlModelValues');
          this.saveClntExpnsDtlModelValues = JSON.parse(this.saveClntExpnsDtlModelValues);
          this._finiancialInfoSvc.getClntAllExpnsDtlSeqPG = this.saveClntExpnsDtlModelValues;

          // this._sharedSvc.collectionSize = this._finiancialInfoSvc.getAllIncome?.length;
          // this._finiancialInfoSvc.getClntAllIncmDtlSeqPG = this._finiancialInfoSvc.getAllIncome.slice((this._sharedSvc.page - 1) * this._sharedSvc.pageSize, (this._sharedSvc.page - 1) * this._sharedSvc.pageSize + this._sharedSvc.pageSize);
        }
      }
    }
  }

  // {{'common.basicInformation' | translate}}
  setValueToModelS1() {

    this.setBasicInfoVal();

    this._businessAppraisalSvc.saveBizAprslModel.sectKey = !!this.sectKey.value ? this.sectKey.value : '';
    this._businessAppraisalSvc.saveBizAprslModel.actyKey = !!this.actyKey.value ? this.actyKey.value : '';
    this._businessAppraisalSvc.saveBizAprslModel.bizDtlStr = this.bizDtlStr.value;
    this._businessAppraisalSvc.saveBizAprslModel.mnthInBiz = this.mnthInBiz.value;
    this._businessAppraisalSvc.saveBizAprslModel.bizPhNum = this.bizPhNum.value;
    this._businessAppraisalSvc.NoOfYears = this.NoOfYears.value;
    this._businessAppraisalSvc.noOfMonth = this.noOfMonth.value;
  }
  setValueToFormS1() {
    this.sectKey.setValue(this._businessAppraisalSvc.saveBizAprslModel.sectKey);
    this.actyKey.setValue(this._businessAppraisalSvc.saveBizAprslModel.actyKey);
    this.bizDtlStr.setValue(this._businessAppraisalSvc.saveBizAprslModel.bizDtlStr);
    this.bizPhNum.setValue(this._businessAppraisalSvc.saveBizAprslModel.bizPhNum);
    this.NoOfYears.setValue(this._businessAppraisalSvc.NoOfYears);
    this.noOfMonth.setValue(this._businessAppraisalSvc.noOfMonth);
    this.mnthInBiz.setValue(this._businessAppraisalSvc.saveBizAprslModel.mnthInBiz);
  }
  calNoOfYears(event: any) {

    this._businessAppraisalSvc.NoOfYears = event?.target?.value;
    if (this.noOfMonth.value == null) {
      this._businessAppraisalSvc.noOfMonth = 0;
      this.noOfMonth.setValue(this._businessAppraisalSvc.noOfMonth);
    }
    this.setValueToModelS1();
    const yearsToAdd = Math.floor(this._businessAppraisalSvc.noOfMonth / 12);
    this._businessAppraisalSvc.NoOfYears += yearsToAdd;
    const remainingMonths = this._businessAppraisalSvc.noOfMonth % 12;
    this._businessAppraisalSvc.saveBizAprslModel.mnthInBiz = this._businessAppraisalSvc.NoOfYears * 12 + remainingMonths;
    this.mnthInBiz.setValue(this._businessAppraisalSvc.saveBizAprslModel.mnthInBiz);

  }
  calNoOfMonth(event: any) {
    this._businessAppraisalSvc.noOfMonth = event?.target?.value;
    if (this.NoOfYears.value == null) {
      this._businessAppraisalSvc.NoOfYears = 0;
      this.NoOfYears.setValue(this._businessAppraisalSvc.NoOfYears);
    }
    this.setValueToModelS1();
    if (String(this._businessAppraisalSvc.noOfMonth).length < 3 || this._businessAppraisalSvc.noOfMonth == undefined) {
      if (this._businessAppraisalSvc.noOfMonth >= 12) {
        const additionalYears = Math.floor(this._businessAppraisalSvc.noOfMonth / 12);
        const remainingMonths = this._businessAppraisalSvc.noOfMonth % 12;
        this._businessAppraisalSvc.NoOfYears = (this._businessAppraisalSvc.NoOfYears || 0) + additionalYears;
        this._businessAppraisalSvc.saveBizAprslModel.mnthInBiz = (this._businessAppraisalSvc.NoOfYears || 0) * 12 + remainingMonths;
        this._businessAppraisalSvc.noOfMonth = remainingMonths;
        this.mnthInBiz.setValue(this._businessAppraisalSvc.saveBizAprslModel.mnthInBiz);
        this.NoOfYears.setValue(this._businessAppraisalSvc.NoOfYears);
        this.noOfMonth.setValue(this._businessAppraisalSvc.noOfMonth);

      } else {
        this._businessAppraisalSvc.saveBizAprslModel.mnthInBiz =
          (this._businessAppraisalSvc.NoOfYears || 0) * 12 + this._businessAppraisalSvc.noOfMonth;
        this.mnthInBiz.setValue(this._businessAppraisalSvc.saveBizAprslModel.mnthInBiz);

      }
    }
  }

  onSubmitBasicInfo(): void {
    this.setValueToModelS1();
    this.checkBasicFields();
    if (this.basicInfoForm.invalid) {
      for (const control of Object.keys(this.basicInfoForm.controls)) {
        this.basicInfoForm.controls[control].markAsTouched();
      }
      return;
    }
    this._sharedSvc.showSpinner();
    this._businessAppraisalSvc.saveBizAprslModel.bizPhNum = this._businessAppraisalSvc.saveBizAprslModel.bizPhNum?.replace(/\D/g, "");
    this._businessAppraisalSvc.saveBusinessAppraisal().subscribe({
      next: (response: any) => {
        if (response) {
          
          localStorage.setItem('clntBizAprslSeq', JSON.stringify(response.clntBizAprslSeq));
          if (this.route.snapshot.paramMap.get("id")) { }
          else {
            this._businessAppraisalSvc.saveBizAprslModel.bizAprslSeq = response.clntBizAprslSeq;
            localStorage.setItem('saveBizAprslModelValues', JSON.stringify(this._businessAppraisalSvc.saveBizAprslModel));
          }

          this._sharedSvc.success(this._sharedSvc.msg);
          this._sharedSvc.hideSpinner();
          // this.modalService.dismissAll();
          // this.basicInfoForm?.reset(this.basicInfoForm.value);
          // this.setBasicInfoVal();
          // this._businessAppraisalSvc.InItGetBusinessAppraisal(this.saveCnicData.clntSeq);
        }
      },
      error: e => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    });


  }

  // Address
  setValueToModelS2() {
    this._clintOnboardingSvc.addressModel.hseNum = this.hseNum.value
    this._clintOnboardingSvc.addressModel.strt = this.strt.value
    this._clintOnboardingSvc.addressModel.cmntySeq = this.cmntySeq.value
    this._clintOnboardingSvc.addressModel.vlg = this.vlg.value
    this._clintOnboardingSvc.addressModel.othDtl = this.othDtl.value
    this._clintOnboardingSvc.districtName = this.districtName.value
    this._clintOnboardingSvc.thslName = this.thslName.value
    this._clintOnboardingSvc.cityName = this.cityName.value
    this._clintOnboardingSvc.ucName = this.ucName.value
    this._clintOnboardingSvc.stateName = this.stateName.value

  }
  setValueToFormS2() {
    this.hseNum.setValue(this._clintOnboardingSvc.addressModel.hseNum);
    this.strt.setValue(this._clintOnboardingSvc.addressModel.strt);
    this.cmntySeq.setValue(this._clintOnboardingSvc.addressModel.cmntySeq);
    this.vlg.setValue(this._clintOnboardingSvc.addressModel.vlg);
    this.othDtl.setValue(this._clintOnboardingSvc.addressModel.othDtl);
    this.districtName.setValue(this._clintOnboardingSvc.districtName);
    this.thslName.setValue(this._clintOnboardingSvc.thslName);
    this.cityName.setValue(this._clintOnboardingSvc.cityName);
    this.ucName.setValue(this._clintOnboardingSvc.ucName);
    this.stateName.setValue(this._clintOnboardingSvc.stateName);

  }
  onSaveClick() {
    this.setValueToModelS2();
    localStorage.setItem('saveBznAddress', JSON.stringify(true));
    sessionStorage.setItem('isBizAprslAddressSaveClicked', JSON.stringify(true));
    let a = JSON.parse(sessionStorage.getItem('isBizAprslAddressOpen'));
    let b = JSON.parse(sessionStorage.getItem('isBizAprslAddressSaveClicked'));

    if (a === true && b === true) {
      this._sharedSvc.disableTabsForAddress = false;
    }

    if (this._clintOnboardingSvc.isHomeAddress) {
      this.isHomeAdd(true);
      // this.showBtn(false, false, true, false, false);
    }
    else {
      // this.showBtn(false, false, true, false, false);
      this.onSubmitBasicAddress();
    }


  }
  isHomeAdd(value: any) {
    this._clintOnboardingSvc.addressModel = new Address();
    this._clintOnboardingSvc.cityName = null;
    this._clintOnboardingSvc.districtName = null;
    this._clintOnboardingSvc.stateName = null;
    this._clintOnboardingSvc.thslName = null;
    this._clintOnboardingSvc.ucName = null;

    this._clintOnboardingSvc.isHomeAddress = value;
    if (value == false) {
      this._clintOnboardingSvc.bizAddAsClientAdd = false;
      if (localStorage.getItem('clntBizAprslSeq')) {
        // this._clintOnboardingSvc.InItGetAddress(this.clntBizAprslSeq);
      } else {
        this._sharedSvc.error(this.translate.instant('toast.saveBasicInfoFirst'));


        // this._clintOnboardingSvc.clntSeqAdd = this.saveCnicData.clntSeq;
        // this._clintOnboardingSvc.InItGetAddress(this.saveCnicData.clntSeq);
        return;
      }

    }
    else {
      // this._clintOnboardingSvc.addressModel = new Address();
      // this._clintOnboardingSvc.cityName = null;
      // this._clintOnboardingSvc.districtName = null;
      // this._clintOnboardingSvc.stateName = null;
      // this._clintOnboardingSvc.thslName = null;
      // this._clintOnboardingSvc.ucName = null;
      this._clintOnboardingSvc.bizAddAsClientAdd = true;
      this._clintOnboardingSvc.clntSeqAdd = this.saveCnicData.clntSeq;
      this._clintOnboardingSvc.InItGetAddress(this.saveCnicData.clntSeq);
    }


    this._clintOnboardingSvc.nosOfResidence = null;
    this._clintOnboardingSvc.resOfResidence = null;
    this._clintOnboardingSvc.addressModel.mnthsOfResidence = null;
    this._clintOnboardingSvc.areaFieldsInvalid = true;

  }
  onSelectionArea(area: any) {
    // this._clintOnboardingSvc.onSelectionArea(area);
    this._clintOnboardingSvc.cityName = area?.cityName;
    this._clintOnboardingSvc.districtName = area?.districtName;
    this._clintOnboardingSvc.stateName = area?.stateName;
    this._clintOnboardingSvc.thslName = area?.thslName;
    this._clintOnboardingSvc.ucName = area?.ucName;
    this._clintOnboardingSvc.locSeq = area.locationSeq;
    this.setAreaValueToForm2();
    if (this._clintOnboardingSvc.districtName == undefined && this._clintOnboardingSvc.thslName == undefined && this._clintOnboardingSvc.cityName == undefined && this._clintOnboardingSvc.ucName == undefined && this._clintOnboardingSvc.stateName == undefined) {
      this._clintOnboardingSvc.areaFieldsInvalid = true;
      return
    }
    else {
      this._clintOnboardingSvc.areaFieldsInvalid = false;
    }
    this.modalService.dismissAll();
  }
  setAreaValueToForm2() {
    this.districtName.setValue(this._clintOnboardingSvc.districtName);
    this.thslName.setValue(this._clintOnboardingSvc.thslName);
    this.cityName.setValue(this._clintOnboardingSvc.cityName);
    this.ucName.setValue(this._clintOnboardingSvc.ucName);
    this.stateName.setValue(this._clintOnboardingSvc.stateName);
  }
  setAddressVal() {
    this._clintOnboardingSvc.addressModel = {
      clntSeq: this.saveCnicData.clntSeq,
      addrSeq: null,
      addrRelSeq: null,
      delFlg: false,
      locSeq: this._clintOnboardingSvc.locSeq,
      hseNum: null,
      strt: null,
      cmntySeq: 0,
      vlg: null,
      othDtl: null,
      latitude: 0,
      longitude: 0,
      mnthsOfResidence: 0,
      isPermAddress: false,
      entySeq: null,
      entyType: 1,
    };
  }
  calNosOfResidence() {
    this.setValueToModelS2();
    const yearsToAdd = this._clintOnboardingSvc.resOfResidence > 0 ? Math.floor(this._clintOnboardingSvc.resOfResidence / 12) : 0;
    this._clintOnboardingSvc.addressModel.mnthsOfResidence += yearsToAdd;
    this._clintOnboardingSvc.addressModel.yearsOfResidence = this._clintOnboardingSvc.nosOfResidence;
    const remainingMonths = this._clintOnboardingSvc.resOfResidence > 0 ? this._clintOnboardingSvc.resOfResidence % 12 : 0;
    this._clintOnboardingSvc.addressModel.mnthsOfResidence = this._clintOnboardingSvc.nosOfResidence * 12 + remainingMonths;
    // this._clintOnboardingSvc.saveClientModel.mnthsRes = this._clintOnboardingSvc.addressModel.mnthsOfResidence
    ;
  }
  calMnthsOfResidence() {
    this.setValueToModelS2();
    // this.nosOfResidence = null;
    // this._clintOnboardingSvc.addressModel.mnthsOfResidence = this.resOfResidence;
    if (String(this._clintOnboardingSvc.resOfResidence).length < 3 || this._clintOnboardingSvc.resOfResidence == undefined) {
      if (this._clintOnboardingSvc.resOfResidence >= 12) {
        const additionalYears = Math.floor(this._clintOnboardingSvc.resOfResidence / 12);
        const remainingMonths = this._clintOnboardingSvc.resOfResidence % 12;
        this._clintOnboardingSvc.addressModel.yearsOfResidence =
          (this._clintOnboardingSvc.addressModel.yearsOfResidence || 0) + additionalYears;
        this._clintOnboardingSvc.addressModel.mnthsOfResidence =
          (this._clintOnboardingSvc.addressModel.yearsOfResidence || 0) * 12 + remainingMonths;
        this._clintOnboardingSvc.nosOfResidence = this._clintOnboardingSvc.nosOfResidence + additionalYears;
        this._clintOnboardingSvc.resOfResidence = remainingMonths;
        // this._clintOnboardingSvc.saveClientModel.mnthsRes = this._clintOnboardingSvc.addressModel.mnthsOfResidence
      } else {
        // Calculate mnthInBiz based on the updated NoOfYears and _businessAppraisalSvc.noOfMonth
        this._clintOnboardingSvc.addressModel.mnthsOfResidence =
          (this._clintOnboardingSvc.addressModel.yearsOfResidence || 0) * 12 + this._clintOnboardingSvc.resOfResidence;
        // this._clintOnboardingSvc.saveClientModel.mnthsRes = this._clintOnboardingSvc.addressModel.mnthsOfResidence
      }
    }
    ;
  }
  limitInputToTwoDigits(event: any) {
    const input = event.target;
    if (input.value.length > 2) {
      input.value = input.value.slice(0, 2);
      this._clintOnboardingSvc.resOfResidence = input.value;
    }
    // if (input.value > 11) {
    // }
  }
  onSubmitBasicAddress(): void {
    this.setValueToModelS2();
    if (localStorage.getItem('clntBizAprslSeq')) {
    } else {
      this._sharedSvc.error(this.translate.instant('toast.saveBasicInfoFirst'));
      return;
    }
    if (this.addressForm.invalid) {
      for (const control of Object.keys(this.addressForm.controls)) {
        this.addressForm.controls[control].markAsTouched();
      }
      return;
    }
    this._clintOnboardingSvc.addressModel.isPermAddress = true;
    // this._clintOnboardingSvc.addressModel.mnthsOfResidence = 0;
    this._clintOnboardingSvc.addressModel.clntSeq = this.saveCnicData.clntSeq;
    this._clintOnboardingSvc.addressModel.addrSeq = null;
    this._clintOnboardingSvc.addressModel.addrRelSeq = null;
    this._clintOnboardingSvc.addressModel.delFlg = false;
    this._clintOnboardingSvc.addressModel.locSeq = this._clintOnboardingSvc.locSeq;
    this._clintOnboardingSvc.addressModel.latitude = 0;
    this._clintOnboardingSvc.addressModel.longitude = 0;
    this._clintOnboardingSvc.addressModel.entySeq = this.clntBizAprslSeq;
    this._clintOnboardingSvc.addressModel.entyType = 2;
    this._sharedSvc.showSpinner();
    this._clintOnboardingSvc.saveAddress().subscribe({
      next: (response: any) => {
        if (response) {

          this._clintOnboardingSvc.addressModel.addrRelSeq = response.addr_rel_seq;
          this._clintOnboardingSvc.addressModel.addrSeq = response.addr_seq;
          localStorage.setItem('bizAppraisalAddress', JSON.stringify(this._clintOnboardingSvc.addressModel));
          this._sharedSvc.hideSpinner();
          this._sharedSvc.success(this._sharedSvc.msg);
          // this._clintOnboardingSvc.InItGetAddress(this.clntBizAprslSeq);

        }
      },
      error: e => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    });
  }

  // Additional Info
  setValueToModelS3() {
    this._businessAppraisalSvc.quesArr = this.quesArr.value
  }
  setValueToFormS3() {
    this.quesArr.setValue(this._businessAppraisalSvc.quesArr);
  }
  setAddInfoVal(event?: any, index?: number) {
    
    console.log("test");
    if (this._businessAppraisalSvc.quesArr.length > 0) {
      this.getquestions = this._businessAppraisalSvc.quesArr;
    }
    this.getquestions[index] = event.target.value;
    this.additionalInfoForm.controls["quesArr"].setValue(this.getquestions)
    console.log(this.additionalInfoForm.controls["quesArr"])
    this._businessAppraisalSvc.setQuesModel = [];
    this._businessAppraisalSvc.quesArr = this.getquestions;
    for (let i = 0; i < this._businessAppraisalSvc.quesArr.length; i++) {
      let body = {
        bizAprslAdtnlInfoSeq: 0,
        qstSeq: this._picklistService?.getAllQstn_data[i].qstSeq,
        answrSeq: this._businessAppraisalSvc.quesArr[i],
        clntSeq: this.saveCnicData?.clntSeq,
        delFlg: false,
        tntKey: 0,
      };
      this._businessAppraisalSvc.setQuesModel.push(body);
    }
    // console.log( this._businessAppraisalSvc.setQuesModel)
  }
  onSubmitAdditionalInfoFn(): void {
    this.setValueToModelS3();
    this.checkAdditionalFields();
    console.log('onSubmitAdditionalInfoFn', this.additionalInfoForm.value)

    if (this._businessAppraisalSvc.setQuesModel?.length != this._picklistService.getAllQstn_data?.length) {
      this._sharedSvc.error(this.translate.instant('toast.selectRequireField'));
      this.submitted = true;
      return;
    }
    this._sharedSvc.showSpinner();
    this._businessAppraisalSvc.saveAllBusinessAppraisalAdd().subscribe({
      next: response => {
        if (response) {
          this._sharedSvc.hideSpinner();
          this.modalService.dismissAll();
          this._sharedSvc.success(this._sharedSvc.msg);
          // this._businessAppraisalSvc.InItGetAllBusinessAppraisalAdd(this.saveCnicData.clntSeq);
        }
      },
      error: e => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    });



  }

  // Business Income
  setValueToModelS4() {
    this._finiancialInfoSvc.saveClntIncmDtlModel.incmTypKey = this.incmTypKey.value
    this._finiancialInfoSvc.saveClntIncmDtlModel.frqncy = this.frqncy.value
    this._finiancialInfoSvc.saveClntIncmDtlModel.qnty = this.qnty.value
    this._finiancialInfoSvc.saveClntIncmDtlModel.incmAmt = this.incmAmt.value
    this._finiancialInfoSvc.saveClntIncmDtlModel.avgMnthlyIncm = this.avgMnthlyIncm.value
  }
  setValueToFormS4() {
    this.incmTypKey.setValue(this._finiancialInfoSvc.saveClntIncmDtlModel.incmTypKey);
    this.frqncy.setValue(this._finiancialInfoSvc.saveClntIncmDtlModel.frqncy);
    this.qnty.setValue(this._finiancialInfoSvc.saveClntIncmDtlModel.qnty);
    this.incmAmt.setValue(this._finiancialInfoSvc.saveClntIncmDtlModel.incmAmt);
    this.avgMnthlyIncm.setValue(this._finiancialInfoSvc.saveClntIncmDtlModel.avgMnthlyIncm);
  }
  calAvgAmount(event: any, name: any) {
    if (name == 'frqncy') {
      this._finiancialInfoSvc.saveClntIncmDtlModel.frqncy = this.frqncy.value;
    }
    if (name == 'qnty') {
      this._finiancialInfoSvc.saveClntIncmDtlModel.qnty = this.qnty.value;
    }
    if (name == 'incmAmt') {
      this._finiancialInfoSvc.saveClntIncmDtlModel.incmAmt = this.incmAmt.value;
    }
    this.setValueToModelS4();
    if (
      this._finiancialInfoSvc.saveClntIncmDtlModel.frqncy &&
      this._finiancialInfoSvc.saveClntIncmDtlModel?.qnty &&
      this._finiancialInfoSvc.saveClntIncmDtlModel?.incmAmt
    ) {
      let frequency = this._finiancialInfoSvc.saveClntIncmDtlModel.frqncy;
      let qty = this._finiancialInfoSvc.saveClntIncmDtlModel?.qnty;
      let amount = this._finiancialInfoSvc.saveClntIncmDtlModel?.incmAmt;
      for (let i = 0; i < this._picklistService.BUSINESS_FREQUENCY_Data.length; i++) {
        if (frequency == 2000) {
          this._finiancialInfoSvc.saveClntIncmDtlModel.avgMnthlyIncm = (amount * qty) / 1;
        } else if (frequency == 2001) {
          this._finiancialInfoSvc.saveClntIncmDtlModel.avgMnthlyIncm = (amount * qty) / 3;
        } else if (frequency == 2002) {
          this._finiancialInfoSvc.saveClntIncmDtlModel.avgMnthlyIncm = (amount * qty) / 6;
        } else if (frequency == 2003) {
          this._finiancialInfoSvc.saveClntIncmDtlModel.avgMnthlyIncm = (amount * qty) / 12;
        }

        // this._finiancialInfoSvc.saveClntIncmDtlModel.avgMnthlyIncm =  this._finiancialInfoSvc.saveClntIncmDtlModel.avgMnthlyIncm.toFixed(2);
        this._finiancialInfoSvc.saveClntIncmDtlModel.avgMnthlyIncm = Math.round(
          this._finiancialInfoSvc.saveClntIncmDtlModel.avgMnthlyIncm
        );
        this.setValueToFormS4();
      }
    }
  }
  setBusinessIncomeForm() {
    this.jwt = sessionStorage.getItem('JWT');
    this.jwt = JSON.parse(this.jwt);

    let date = new Date()
    this._finiancialInfoSvc.saveClntIncmDtlModel = {
      clntSeq: this.saveCnicData?.clntSeq,
      incmDtlSeq: null,
      incmCtgryKey: 3,
      incmAmt: null,
      incmTypKey: null,
      qnty: null,
      frqncy: null,
      avgMnthlyIncm: null,
      cmnt: '',
      crtdBy: this.jwt?.preferred_username,
      crtdDt: date,
      lastUpdBy: this.jwt?.preferred_username,
      lastUpdDt: date,
      delFlg: false
    };
  }
  edit(content: any, data: any, h4: any, index: any) {
    this.index = index;
    this.modelHeading = h4;
    this._sharedSvc.msg = this.translate.instant('toast.recordUpdated');
    this._businessAppraisalSvc.edit = true;
    this.modalService.open(content, {
      windowClass: "popupWidth800",
      backdropClass: "light-blue-backdrop",
      centered: true,
    });
    this._finiancialInfoSvc.saveClntIncmDtlModel = data;
    this.setValueToFormS4();
    // this._conditionsService.savePartiallyINCData = true;
    // this._conditionsService.savePartiallyINCDataFn();
  }
  onUpdateBusinessIncome() {
    this.setValueToModelS4();
    this._finiancialInfoSvc.getClntAllIncmDtlSeqPG[this.index] = this._finiancialInfoSvc.saveClntIncmDtlModel;
    this._conditionsService.savePartiallyINCData = true;
    this._conditionsService.savePartiallyINCDataFn();
    this.calIncTAmount();
    this.dismissModal();
  }
  DeletePopup_Income(data: any, index: any) {
    this._sharedSvc.msg = "Record deleted";
    this._sharedPopupSvc
      .popup(
        "",
        this.translate.instant('common.areSelectRecordDelete'),
        "",
        this.translate.instant('common.delete'),
        this.translate.instant('common.cancelSmall'),
        null,
        this.translate.instant('common.incomeAmount') + " : " + data?.incmAmt
      )
      .then((confirmed: any) => {
        if (confirmed) {
          // Delete function call
          this._finiancialInfoSvc.getClntAllIncmDtlSeqPG[index].delFlg = true;
          if (data.incmDtlSeq == null) {
            this._finiancialInfoSvc.getClntAllIncmDtlSeqPG.splice(index, 1);
          }
          if (this._finiancialInfoSvc.getClntAllIncmDtlSeqPG?.length == 0) {
            this._conditionsService.savePartiallyINCData = false;
            this._conditionsService.savePartiallyINCDataFn();
            this._finiancialInfoSvc.totalIncmAmt = 0;

            localStorage.setItem('totalIncmAmt', this._finiancialInfoSvc.totalIncmAmt);

            return;
          }
          this._finiancialInfoSvc.getAllPriIncome = this._finiancialInfoSvc.getClntAllIncmDtlSeqPG;
          this._conditionsService.savePartiallyINCData = true;
          this._conditionsService.savePartiallyINCDataFn();
          this.calIncTAmount();
          // console.log("Delete Success");
        } else {
          //in case of cancel
          return;
        }
      })
      .catch(() => {
        // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)');
        return;
      });
  }
  onSubmitBusinessInc(): void {
    
    this.setValueToModelS4();
    if (this.businessIncomeForm.invalid) {
      for (const control of Object.keys(this.businessIncomeForm.controls)) {
        this.businessIncomeForm.controls[control].markAsTouched();
      }
      return;
    }
    this._finiancialInfoSvc.saveClntIncmDtlModel.incmTypKey = JSON.parse(this._finiancialInfoSvc.saveClntIncmDtlModel.incmTypKey);
    this._finiancialInfoSvc.saveBusinessIncModel.push(this._finiancialInfoSvc.saveClntIncmDtlModel);
    this._conditionsService.savePartiallyINCData = true;
    this._conditionsService.savePartiallyINCDataFn();
    // let addRow = {
    //   "clntSeq": this._finiancialInfoSvc.saveClntIncmDtlModel.clntSeq,
    //   "incmDtlSeq": this._finiancialInfoSvc.saveClntIncmDtlModel.incmDtlSeq,
    //   "incmCtgryKey": this._finiancialInfoSvc.saveClntIncmDtlModel.incmCtgryKey,
    //   "incmAmt": this._finiancialInfoSvc.saveClntIncmDtlModel.incmAmt,
    //   "incmTypKey": this._finiancialInfoSvc.saveClntIncmDtlModel.incmTypKey,
    //   "qnty": this._finiancialInfoSvc.saveClntIncmDtlModel.qnty,
    //   "frqncy": this._finiancialInfoSvc.saveClntIncmDtlModel.frqncy,
    //   "avgMnthlyIncm": this._finiancialInfoSvc.saveClntIncmDtlModel.avgMnthlyIncm,
    //   "cmnt": this._finiancialInfoSvc.saveClntIncmDtlModel.cmnt,
    //   "crtdBy": this._finiancialInfoSvc.saveClntIncmDtlModel.crtdBy,
    //   "crtdDt": this._finiancialInfoSvc.saveClntIncmDtlModel.crtdDt,
    //   "lastUpdBy": this._finiancialInfoSvc.saveClntIncmDtlModel.lastUpdBy,
    //   "lastUpdDt": this._finiancialInfoSvc.saveClntIncmDtlModel.lastUpdDt,
    //   "delFlg": this._finiancialInfoSvc.saveClntIncmDtlModel.delFlg,
    // }
    this._finiancialInfoSvc.getAllIncome.push(this._finiancialInfoSvc.saveClntIncmDtlModel);
    this._sharedSvc.collectionSize = this._finiancialInfoSvc.getAllIncome?.length;
    this._finiancialInfoSvc.getClntAllIncmDtlSeqPG = this._finiancialInfoSvc.getAllIncome.slice((this._sharedSvc.page - 1) * this._sharedSvc.pageSize, (this._sharedSvc.page - 1) * this._sharedSvc.pageSize + this._sharedSvc.pageSize);
    localStorage.setItem("businessIncome", JSON.stringify(this._finiancialInfoSvc.getClntAllIncmDtlSeqPG));

    this.modalService.dismissAll();
    this._sharedSvc.success(this._sharedSvc.msg);
    this.calIncTAmount();

  }
  saveClntIncmDtl() {
    this._finiancialInfoSvc.saveClntIncmDtlModel = this._finiancialInfoSvc.getClntAllIncmDtlSeqPG;
    this._sharedSvc.showSpinner();
    this._finiancialInfoSvc.saveClntIncmDtl().subscribe({
      next: response => {
        if (response) {
          this._finiancialInfoSvc.getClntAllIncmDtlSeqPG = response;
          this._sharedSvc.hideSpinner();
          this.spliceClntIncmDtl();
          this._conditionsService.savePartiallyINCData = false;
          this._conditionsService.savePartiallyINCDataFn();
          localStorage.removeItem('saveBusinessIncModel');
          if (this.route.snapshot.paramMap.get("id")) { }
          else {
            localStorage.setItem('saveClntIncmDtlModelValues', JSON.stringify(this._finiancialInfoSvc.getClntAllIncmDtlSeqPG));
          }
          this.modalService.dismissAll();
          this._sharedSvc.success(this._sharedSvc.msg);
          // this._finiancialInfoSvc.InItGetClntAllIncmDtlSeq(this.saveCnicData.clntSeq, this.incmCtgryKey);
        }
      },
      error: e => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    });
  }
  spliceClntIncmDtl() {
    this._finiancialInfoSvc.getAllIncome = this._finiancialInfoSvc.getClntAllIncmDtlSeqPG;
    for (let i = 0; i < this._finiancialInfoSvc.getAllIncome.length; i++) {
      if (this._finiancialInfoSvc.getAllIncome[i].delFlg == true) {
        this._finiancialInfoSvc.getAllIncome.splice(i, 1);
      }
    }
    this.calIncTAmount();
  }
  calIncTAmount() {
    this._finiancialInfoSvc.totalIncmAmt = 0;
    this._finiancialInfoSvc.getAllIncome = this._finiancialInfoSvc.getClntAllIncmDtlSeqPG;
    for (let i = 0; i < this._finiancialInfoSvc.getAllIncome.length; i++) {
      if (this._finiancialInfoSvc.getAllIncome[i].delFlg == false) {
        let incmAmt = this._finiancialInfoSvc.getAllIncome[i].avgMnthlyIncm;
        this._finiancialInfoSvc.totalIncmAmt = this._finiancialInfoSvc.totalIncmAmt + incmAmt;
      }
    }
    localStorage.setItem('totalIncmAmt', this._finiancialInfoSvc.totalIncmAmt);

  }

  // Business Expense
  setValueToModelS5() {
    this._finiancialInfoSvc.saveClntExpnsDtlModel.expTypKey = this.expTypKey.value
    this._finiancialInfoSvc.saveClntExpnsDtlModel.expAmt = this.expAmt.value
  }
  setValueToFormS5() {
    this.expTypKey.setValue(this._finiancialInfoSvc.saveClntExpnsDtlModel.expTypKey);
    this.expAmt.setValue(this._finiancialInfoSvc.saveClntExpnsDtlModel.expAmt);
  }
  setBusinessExpenseForm() {
    this.jwt = sessionStorage.getItem('JWT');
    this.jwt = JSON.parse(this.jwt);
    let date = new Date();
    this._finiancialInfoSvc.saveClntExpnsDtlModel = {
      clntSeq: this.saveCnicData.clntSeq,
      expDtlSeq: null,
      expCtgryKey: 1,
      expTypKey: null,
      expAmt: null,
      crtdBy: this.jwt?.preferred_username,
      crtdDt: date,
      lastUpdBy: this.jwt?.preferred_username,
      lastUpdDt: date,
      delFlg: false
    };
  }
  onEditBE(content: any, data: any, h4: any, index: any) {
    this.index2 = index;
    this.modelHeading = h4;
    this._sharedSvc.msg = this.translate.instant('toast.recordUpdated');
    this._businessAppraisalSvc.edit = true;
    this._finiancialInfoSvc.dropdownsDef(data);
    this.modalService.open(content, {
      windowClass: "popupWidth800",
      backdropClass: "light-blue-backdrop",
      centered: true,
    });
    this._finiancialInfoSvc.saveClntExpnsDtlModel = data;
    this.setValueToFormS5();
    // this._conditionsService.savePartiallyExpData = true;
    // this._conditionsService.savePartiallyExpDataFn();

  }
  onUpdateBusinessExpense() {
    this.setValueToModelS5();
    this._finiancialInfoSvc.getClntAllExpnsDtlSeqPG[this.index2] = this._finiancialInfoSvc.saveClntExpnsDtlModel;
    this._conditionsService.savePartiallyExpData = true;
    this._conditionsService.savePartiallyExpDataFn();
    this.calExpTAmount();
    this.dismissModal();
  }
  DeletePopup_BE(data: any, index: any) {
    this._sharedSvc.msg = "Record deleted";
    this._sharedPopupSvc
      .popup("", this.translate.instant('common.areSelectRecordDelete'), "", this.translate.instant('common.delete'), this.translate.instant('common.cancelSmall'), null, this.translate.instant('common.incomeAmount') + " : " + data?.expAmt)
      .then((confirmed: any) => {
        if (confirmed) {
          // Delete function call
          this._finiancialInfoSvc.getClntAllExpnsDtlSeqPG[index].delFlg = true;

          if (data.expDtlSeq == null) {
            this._finiancialInfoSvc.getClntAllExpnsDtlSeqPG.splice(index, 1);
          }
          if (this._finiancialInfoSvc.getClntAllExpnsDtlSeqPG?.length == 0) {
            this._finiancialInfoSvc.getAllExpense = this._finiancialInfoSvc.getClntAllExpnsDtlSeqPG;
            this.calExpTAmount();
            this._conditionsService.savePartiallyExpData = false;
            this._conditionsService.savePartiallyExpDataFn();
            this._finiancialInfoSvc.totalExpAmt = 0;
            localStorage.setItem('totalExpAmt', this._finiancialInfoSvc.totalExpAmt);
            return;
          }
          this._finiancialInfoSvc.getAllExpense = this._finiancialInfoSvc.getClntAllExpnsDtlSeqPG;
          this._conditionsService.savePartiallyExpData = true;
          this._conditionsService.savePartiallyExpDataFn();

          this.calExpTAmount();
        } else {
          //in case of cancel
          return;
        }
      })
      .catch(() => {
        // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)');
        return;
      });
  }
  onSubmitBusinessExp(): void {
    this.setValueToModelS5();
    if (this.businessExpenseForm.invalid) {
      for (const control of Object.keys(this.businessExpenseForm.controls)) {
        this.businessExpenseForm.controls[control].markAsTouched();
      }
      return;
    }
    this._finiancialInfoSvc.saveBusinessExpModel.push(this._finiancialInfoSvc.saveClntExpnsDtlModel);
    // let addRow = {
    //   "clntSeq": this._finiancialInfoSvc.saveClntExpnsDtlModel.clntSeq,
    //   "expDtlSeq": this._finiancialInfoSvc.saveClntExpnsDtlModel.expDtlSeq,
    //   "expCtgryKey": this._finiancialInfoSvc.saveClntExpnsDtlModel.expCtgryKey,
    //   "expTypKey": this._finiancialInfoSvc.saveClntExpnsDtlModel.expTypKey,
    //   "expAmt": this._finiancialInfoSvc.saveClntExpnsDtlModel.expAmt,
    //   "crtdBy": this._finiancialInfoSvc.saveClntExpnsDtlModel.crtdBy,
    //   "crtdDt": this._finiancialInfoSvc.saveClntExpnsDtlModel.crtdDt,
    //   "lastUpdBy": this._finiancialInfoSvc.saveClntExpnsDtlModel.lastUpdBy,
    //   "lastUpdDt": this._finiancialInfoSvc.saveClntExpnsDtlModel.lastUpdDt,
    //   "delFlg": this._finiancialInfoSvc.saveClntExpnsDtlModel.delFlg,
    // }
    this._finiancialInfoSvc.getAllExpense.push(this._finiancialInfoSvc.saveClntExpnsDtlModel);
    this._conditionsService.savePartiallyExpData = true;
    this._conditionsService.savePartiallyExpDataFn();
    this._sharedSvc.collectionSize = this._finiancialInfoSvc.getAllExpense?.length;
    this._finiancialInfoSvc.getClntAllExpnsDtlSeqPG = this._finiancialInfoSvc.getAllExpense?.slice((this._sharedSvc.page - 1) * this._sharedSvc.pageSize, (this._sharedSvc.page - 1) * this._sharedSvc.pageSize + this._sharedSvc.pageSize);
    localStorage.setItem("businessExpenses", JSON.stringify(this._finiancialInfoSvc.getClntAllExpnsDtlSeqPG));
    this.modalService.dismissAll();
    this._sharedSvc.success(this._sharedSvc.msg);
    this.calExpTAmount()

  }
  calExpTAmount() {
    this._finiancialInfoSvc.totalExpAmt = 0;
    this._finiancialInfoSvc.totalExpAmt = 0;
    for (let i = 0; i < this._finiancialInfoSvc.getAllExpense?.length; i++) {
      if (this._finiancialInfoSvc.getAllExpense[i].delFlg == false) {
        let expAmt = this._finiancialInfoSvc.getAllExpense[i].expAmt;
        this._finiancialInfoSvc.totalExpAmt = this._finiancialInfoSvc.totalExpAmt + expAmt;
        this._finiancialInfoSvc.totalExpAmt = (this._finiancialInfoSvc.totalExpAmt);
        localStorage.setItem('totalExpAmt', this._finiancialInfoSvc.totalExpAmt);
        this._finiancialInfoSvc.formulasCal();
      }
      if (this._finiancialInfoSvc.getAllExpense?.length == 0) {
        localStorage.removeItem('totalExpAmt');
      }

    }
    this._finiancialInfoSvc.formulasCal();
  }
  saveClntExpnsDtl() {
    this._finiancialInfoSvc.saveClntExpnsDtlModel = this._finiancialInfoSvc.getClntAllExpnsDtlSeqPG
    this._sharedSvc.showSpinner();
    this._finiancialInfoSvc.saveClntExpnsDtl().subscribe({
      next: response => {
        if (response) {
          this._finiancialInfoSvc.getAllExpense = response;
          this._sharedSvc.collectionSize = this._finiancialInfoSvc.getAllExpense?.length;
          this._finiancialInfoSvc.getClntAllExpnsDtlSeqPG = this._finiancialInfoSvc.getAllExpense?.slice((this._sharedSvc.page - 1) * this._sharedSvc.pageSize, (this._sharedSvc.page - 1) * this._sharedSvc.pageSize + this._sharedSvc.pageSize);
          this.calExpTAmount();
          this._conditionsService.savePartiallyExpData = false;
          this._conditionsService.savePartiallyExpDataFn();
          localStorage.removeItem('saveBusinessExpModel');
          if (this.route.snapshot.paramMap.get("id")) { }
          else {
            localStorage.setItem('saveClntExpnsDtlModelValues', JSON.stringify(this._finiancialInfoSvc.getClntAllExpnsDtlSeqPG));
          }
          this._sharedSvc.hideSpinner();
          this._sharedSvc.success(this._sharedSvc.msg);
          // this._finiancialInfoSvc.InItGetClntAllExpnsDtlSeq(this.saveCnicData.clntSeq, 1);
        }
      },
      error: e => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    });
  }

  validationForm() {
    this.basicInfoForm = new FormGroup({
      sectKey: new FormControl(null, [Validators.required]),
      actyKey: new FormControl(null, [Validators.required]),
      bizDtlStr: new FormControl(null, [Validators.required]),
      bizPhNum: new FormControl(null, [Validators.required]),
      NoOfYears: new FormControl(null, [Validators.required]),
      noOfMonth: new FormControl(null, [Validators.required]),
      mnthInBiz: new FormControl(null, [Validators.required]),

      // bizPhNum: new FormControl(this.basicInfo.bizPhNum?.replace(/\D/g, ""), [Validators.required]),
    });
    this.addressForm = new FormGroup({
      hseNum: new FormControl(null, [Validators.required, Validators.minLength(1), Validators.maxLength(40)]),
      strt: new FormControl(null, [Validators.required, Validators.minLength(1), Validators.maxLength(40)]),
      cmntySeq: new FormControl(null, [Validators.required]),
      vlg: new FormControl(null, [Validators.required, Validators.minLength(3), Validators.maxLength(100)]),
      othDtl: new FormControl(null, [Validators.required, Validators.minLength(3), Validators.maxLength(500)]),
      districtName: new FormControl(null, []),
      thslName: new FormControl(null, []),
      cityName: new FormControl(null, []),
      ucName: new FormControl(null, []),
      stateName: new FormControl(null, []),
      mnthsOfResidence: new FormControl(null, []),
      isPermAddress: new FormControl(null, []),
    });

    this.additionalInfoForm = new FormGroup({
      quesArr: new FormControl([], [Validators.required]),
      // quesArr: this.formBuilder.array([],[Validators.required]),
    });
    this._picklistService?.getAllQstn_data?.map(x => this.getquestions.push(""));
    this.getquestions
    // this.additionalInfoForm = this.formBuilder.group({
    //   quesArr: this.formBuilder.array([],[Validators.required]),
    // });
    this.businessIncomeForm = new FormGroup({
      incmTypKey: new FormControl(null, [Validators.required]),
      incmAmt: new FormControl(null, [Validators.required, Validators.min(1), Validators.max(1000000)]),
      frqncy: new FormControl(null, [Validators.required]),
      avgMnthlyIncm: new FormControl(null, [Validators.required]),
      qnty: new FormControl(null, [Validators.required, Validators.min(1), Validators.max(100)]),
    });
    this.businessExpenseForm = new FormGroup({
      expTypKey: new FormControl(null, [Validators.required]),
      expAmt: new FormControl(null, [Validators.required, Validators.min(1), Validators.max(1000000)]),
    });
  }


  checkBasicFields() {
    let invalidBizAprslBasicForm = Object.keys(this.basicInfoForm.controls).filter(controlName =>
      this.basicInfoForm.get(controlName).invalid
    );
    sessionStorage.setItem('invalidBizAprslBasicForm', JSON.stringify(invalidBizAprslBasicForm))
    sessionStorage.setItem('bizAprslBasicFormValues', JSON.stringify(this.basicInfoForm.value))
  }
  checkAdditionalFields() {
    let invalidBizAprslAdditionalForm = Object.keys(this.additionalInfoForm.controls).filter(controlName =>
      this.additionalInfoForm.get(controlName).invalid
    );
    sessionStorage.setItem('invalidBizAprslAdditionalForm', JSON.stringify(invalidBizAprslAdditionalForm))
    sessionStorage.setItem('bizAprslAdditionalFormValue', JSON.stringify(this.additionalInfoForm.value));
  }





  //basicInfoForm
  get sectKey() { return this.basicInfoForm.get("sectKey") }
  get actyKey() { return this.basicInfoForm.get("actyKey") }
  get bizDtlStr() { return this.basicInfoForm.get("bizDtlStr") }
  get bizPhNum() { return this.basicInfoForm.get("bizPhNum") }
  get NoOfYears() { return this.basicInfoForm.get("NoOfYears") }
  get noOfMonth() { return this.basicInfoForm.get("noOfMonth") }
  get mnthInBiz() { return this.basicInfoForm.get("mnthInBiz") }

  //  addressForm: FormGroup;
  get hseNum() { return this.addressForm.get("hseNum") }
  get strt() { return this.addressForm.get("strt") }
  get cmntySeq() { return this.addressForm.get("cmntySeq") }
  get vlg() { return this.addressForm.get("vlg") }
  get othDtl() { return this.addressForm.get("othDtl") }
  get districtName() { return this.addressForm.get("districtName") }
  get thslName() { return this.addressForm.get("thslName") }
  get cityName() { return this.addressForm.get("cityName") }
  get ucName() { return this.addressForm.get("ucName") }
  get stateName() { return this.addressForm.get("stateName") }
  get mnthsOfResidence() { return this.addressForm.get("mnthsOfResidence") }
  get isPermAddress() { return this.addressForm.get("isPermAddress") }
  // Additional Info Form
  get quesArr() { return this.additionalInfoForm.get("quesArr") }

  // business Income Form
  get incmTypKey() { return this.businessIncomeForm.get("incmTypKey") }
  get incmAmt() { return this.businessIncomeForm.get("incmAmt") }
  get frqncy() { return this.businessIncomeForm.get("frqncy") }
  get avgMnthlyIncm() { return this.businessIncomeForm.get("avgMnthlyIncm") }
  get qnty() { return this.businessIncomeForm.get("qnty") }

  // business Expense Form
  get expTypKey() { return this.businessExpenseForm.get("expTypKey") }
  get expAmt() { return this.businessExpenseForm.get("expAmt") }

}