<section class="p-3">
    <div class="row mb-4">
        <div class="col-sm-8 col-md-8 col-lg-8">
            <h4>{{'addProduct.formAssignment' | translate}}</h4>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-6 col-md-6 col-lg-3">
            <div class="checkbox-wrapper custom-checkbox mb-4">
                <input id="check-1" type="checkbox" class="form-check-input pointer">
                <label for="check-1" class="h6 ms-2 cursor-pointer"> {{'clientOnboarding.otherOutstandings' |
                    translate}}</label>
            </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3">
            <div class="checkbox-wrapper custom-checkbox mb-4">
                <input id="check-2" type="checkbox" class="form-check-input pointer">
                <label for="check-2" class="h6 ms-2 cursor-pointer"> {{'formAssignmentTab.insuranceInfo' |
                    translate}}</label>
            </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3">
            <div class="checkbox-wrapper custom-checkbox mb-4">
                <input id="check-3" type="checkbox" class="form-check-input pointer">
                <label for="check-3" class="h6 ms-2 cursor-pointer"> {{'formAssignmentTab.spouseFather' | translate}}
                </label>
            </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3">
            <div class="checkbox-wrapper custom-checkbox mb-4">
                <input id="check-4" type="checkbox" class="form-check-input pointer">
                <label for="check-4" class="h6 ms-2 cursor-pointer"> {{'formAssignmentTab.nextKinInfo' |
                    translate}}</label>
            </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3">
            <div class="checkbox-wrapper custom-checkbox mb-4">
                <input id="check-5" type="checkbox" class="form-check-input pointer">
                <label for="check-5" class="h6 ms-2 cursor-pointer"> {{'formAssignmentTab.relativeInfo' |
                    translate}}</label>
            </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3">
            <div class="checkbox-wrapper custom-checkbox mb-4">
                <input id="check-6" type="checkbox" class="form-check-input pointer">
                <label for="check-6" class="h6 ms-2 cursor-pointer"> {{'formAssignmentTab.coBorrowerInfo' |
                    translate}}</label>
            </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3">
            <div class="checkbox-wrapper custom-checkbox mb-4">
                <input id="check-7" type="checkbox" class="form-check-input pointer">
                <label for="check-7" class="h6 ms-2 cursor-pointer"> {{'formAssignmentTab.businessAppraisal' |
                    translate}}</label>
            </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3">
            <div class="checkbox-wrapper custom-checkbox mb-4">
                <input id="check-8" type="checkbox" class="form-check-input pointer">
                <label for="check-8" class="h6 ms-2 cursor-pointer"> {{'formAssignmentTab.schoolAppraisal' |
                    translate}}</label>
            </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3">
            <div class="checkbox-wrapper custom-checkbox mb-4">
                <input id="check-9" type="checkbox" class="form-check-input pointer">
                <label for="check-9" class="h6 ms-2 cursor-pointer"> {{'formAssignmentTab.schoolInformation' |
                    translate}}</label>
            </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3">
            <div class="checkbox-wrapper custom-checkbox mb-4">
                <input id="check-10" type="checkbox" class="form-check-input pointer">
                <label for="check-10" class="h6 ms-2 cursor-pointer"> {{'formAssignmentTab.creditUtalizationPlan' |
                    translate}}</label>
            </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3">
            <div class="checkbox-wrapper custom-checkbox mb-4">
                <input id="check-11" type="checkbox" class="form-check-input pointer">
                <label for="check-11" class="h6 ms-2 cursor-pointer"> {{'formAssignmentTab.pSC' | translate}}</label>
            </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3">
            <div class="checkbox-wrapper custom-checkbox mb-4">
                <input id="check-12" type="checkbox" class="form-check-input pointer">
                <label for="check-12" class="h6 ms-2 cursor-pointer"> {{'common.documents' | translate}}</label>
            </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3">
            <div class="checkbox-wrapper custom-checkbox mb-4">
                <input id="check-13" type="checkbox" class="form-check-input pointer">
                <label for="check-13" class="h6 ms-2 cursor-pointer"> {{'formAssignmentTab.submitApplication' |
                    translate}}</label>
            </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3">
            <div class="checkbox-wrapper custom-checkbox mb-4">
                <input id="check-14" type="checkbox" class="form-check-input pointer">
                <label for="check-14" class="h6 ms-2 cursor-pointer"> {{'formAssignmentTab.liveStockDairy' |
                    translate}}</label>
            </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3">
            <div class="checkbox-wrapper custom-checkbox mb-4">
                <input id="check-15" type="checkbox" class="form-check-input pointer">
                <label for="check-15" class="h6 ms-2 cursor-pointer"> {{'formAssignmentTab.liveStockMeat' |
                    translate}}</label>
            </div>
        </div>
    </div>
</section>
<div class="row mb-3">
    <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="button_div mt-3">
            <button class="btn login-button-class" type="button" (click)="moveToNextTab()">
                {{'common.continue' | translate}}
            </button>
            <button class="btn login-button-class secondaryBtn" [disabled]="_sharedSvc.loading"
                [class.pointerNone]="_sharedSvc.loading">
                {{'common.save' | translate}}
            </button>
        </div>
    </div>
</div>