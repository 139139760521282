import { Component, OnInit, DestroyRef, inject } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { SharedService } from '../../shared/service/shared.service';
import { CreditAppService } from './client-credit-info/shared/service/credit-app.service';
import { RoutingConditionsService } from '../../clint-onboarding/shared/service/routing-conditions.service';
import { RouteInfo } from 'src/app/auth/model/route-info';
import { ClintOnboardingService } from '../../clint-onboarding/shared/service/clint-onboarding.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-individual-credit-app',
  templateUrl: './add-individual-credit-app.component.html',
  styleUrls: ['./add-individual-credit-app.component.css']
})
export class AddIndividualCreditAppComponent implements OnInit {
  allowedRoutes: RouteInfo[];

  #destroyRef = inject(DestroyRef);

  constructor(
    private modalService: NgbModal,
    public router: Router,
    private formBuilder: FormBuilder,
    public _sharedSvc: SharedService,
    public _creditAppSvc: CreditAppService,
    public _conditionsService: RoutingConditionsService,
    public _clintOnboardingSvc: ClintOnboardingService,
    public translate: TranslateService,

  ) {
    this.allowedRoutes = JSON.parse(sessionStorage.getItem("routeInfo"));
    this.#destroyRef.onDestroy(() => {
      this._conditionsService.readOnlyView_Credit = false
    });
  }

  ngOnInit(): void {

    if (localStorage.getItem('crdtApp') || this._conditionsService.readOnlyView_Credit == true) {
      this._creditAppSvc.creditApp = true
    }
    else {
      this._creditAppSvc.creditApp = false;
    }
    this._sharedSvc.activeCredit = 1;


  }

}
