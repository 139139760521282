import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from '../../shared/service/shared.service';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { OtherOutstandingService } from './shared/service/other-outstanding.service';
import { SharedPopupService } from '../../shared/service/shared-popup.service';
import { PicklistService } from '../../shared/pickList/picklist.service';
import { DatePipe } from "@angular/common";
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { retry } from 'rxjs';
import { RoutingConditionsService } from '../shared/service/routing-conditions.service';
import { OtherStanding } from './shared/model/otherOutstanding';
import { TranslateService } from '@ngx-translate/core';

// interface IOtherOutstanding {
//   tenetKey: number;
//   clntSeq: number;
//   othOutsdCrdtSeq: number;
//   instnNm: string;
//   totCrdtAmt: number | null;
//   crdtPrps: string;
//   crntOutsdAmt: number | null;
//   cmplDt: any;
//   mnthExpFlg: boolean;
//   mfcibFlg: boolean;
//   crtdBy: string;
//   crtdDt: string;
//   lastUpdBy: string;
//   lastUpdDt: string;
//   delFlg: boolean;
// }

@Component({
  selector: 'app-other-outstanding',
  templateUrl: './other-outstanding.component.html',
  styleUrls: ['./other-outstanding.component.css']
})

export class OtherOutstandingComponent implements OnInit {

  model: NgbDateStruct;
  getAllOutstandingPG: any;
  maskedCNIC: string;
  outstandingForm: FormGroup;
  // outstanding: OtherStanding;
  saveCNICModel: any;
  saveCnicData: any;
  modelHeading: any;
  jwt: any;
  index: number = 0
  otherStandingModelValues: any;

  constructor(
    public router: Router,
    public _sharedSvc: SharedService,
    public _otherOutstandingSvc: OtherOutstandingService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    public _sharedPopupSvc: SharedPopupService,
    public _picklistService: PicklistService,
    public route: ActivatedRoute,
    public _conditionsService: RoutingConditionsService,
    public translate: TranslateService
  ) {
    // this.outstanding = {} as IOtherOutstanding;
  }

  ngOnInit(): void {
    this.validationForm();
    this._picklistService.INSTITUTE();
    if (localStorage.getItem("readOnlyView_Client")) {
      this._conditionsService.readOnlyView_Client = true;
    }
    if (localStorage.getItem('save_ClientModel')) {
      this.saveCnicData = localStorage.getItem('save_ClientModel');
    }
    if (localStorage.getItem('SaveCnicData')) {
      this.saveCnicData = localStorage.getItem('SaveCnicData');
    }
    this.saveCnicData = JSON.parse(this.saveCnicData);
    if (this.route.snapshot.paramMap.get("id")) {
      this._otherOutstandingSvc.InItGetAllClientOtherOutstanding(this.saveCnicData?.clntSeq);
    }
    if (!this.route.snapshot.paramMap.get("id")) {
      if (localStorage.getItem('otherStandingModelValues')) {
        this.otherStandingModelValues = localStorage.getItem('otherStandingModelValues');
        this.otherStandingModelValues = JSON.parse(this.otherStandingModelValues);
        this._otherOutstandingSvc.getAllOutstanding = this.otherStandingModelValues;
        this._sharedSvc.collectionSize = this._otherOutstandingSvc.getAllOutstanding?.length;
        this._otherOutstandingSvc.getAllOutstandingPG = this._otherOutstandingSvc.getAllOutstanding.slice((this._sharedSvc.page - 1) * this._sharedSvc.pageSize, (this._sharedSvc.page - 1) * this._sharedSvc.pageSize + this._sharedSvc.pageSize);
      }
    }
  }

  setDefault() {
    this.jwt = sessionStorage.getItem('JWT');
    this.jwt = JSON.parse(this.jwt);
    let date = new Date();
    this._otherOutstandingSvc.otherStandingModel = {
      tenetKey: 1,
      clntSeq: this.saveCnicData?.clntSeq,
      othOutsdCrdtSeq: null,
      instnNm: "",
      totCrdtAmt: null,
      crdtPrps: "",
      crntOutsdAmt: null,
      cmplDt: "",
      mnthExpFlg: false,
      mfcibFlg: false,
      crtdBy: this.jwt?.preferred_username,
      crtdDt: date,
      lastUpdBy: this.jwt?.preferred_username,
      lastUpdDt: date,
      delFlg: false,
    }
  }

  openModal(content: any, h4: any) {
    this.modelHeading = h4;
    this._otherOutstandingSvc.edit = false;
    this._sharedSvc.msg = "Record added";
    this._otherOutstandingSvc.dropdowns();
    this.outstandingForm.reset(this.outstandingForm.value);
    this.setDefault();
    this.validationForm();
    // if (this._picklistService.INSTITUTE_DataClone?.length == 0) {
    //   this._sharedSvc.error('No more Institute Exist');
    //   return;
    // }
    this.modalService.open(content, {
      windowClass: "popupWidth800",
      backdropClass: 'light-blue-backdrop',
      centered: true
    });
  }

  dismissModal() {
    this.outstandingForm.reset(this.outstandingForm.value);
    this.modalService.dismissAll();
    if (this._otherOutstandingSvc.otherStandingModel.cmplDt.year) {
      this._otherOutstandingSvc.otherStandingModel.cmplDt = this._otherOutstandingSvc.otherStandingModel.cmplDt.year + '-' + this._otherOutstandingSvc.otherStandingModel.cmplDt.month + '-' + this._otherOutstandingSvc.otherStandingModel.cmplDt.day
      this._otherOutstandingSvc.otherStandingModel.cmplDt = new Date(this._otherOutstandingSvc.otherStandingModel.cmplDt);
      this.setValueToForm();
    }
  }

  DeletePopup(data: any, index: any) {
    this._sharedPopupSvc.popup("", this.translate.instant('common.areSelectRecordDelete'), "", this.translate.instant('common.delete'), this.translate.instant('common.cancelSmall'), null, this.translate.instant('clientOnboarding.outstandingTab.totalCreditAmount') + " : " + data?.totCrdtAmt).then((confirmed: any) => {
      if (confirmed) {
        // Delete function call
        this._sharedSvc.msg = "Record Deleted";
        this._otherOutstandingSvc.getAllOutstandingPG[index].delFlg = true;
        if (data.othOutsdCrdtSeq == null) {
          this._otherOutstandingSvc.getAllOutstandingPG.splice(index, 1);
        }
        if (this._otherOutstandingSvc.getAllOutstandingPG?.length == 0) {
          this._conditionsService.savePartiallyOSData = false;
          this._conditionsService.savePartiallyOSDataFn();
          return;
        }
        this._otherOutstandingSvc.getAllOutstanding = this._otherOutstandingSvc.getAllOutstandingPG;
        this._conditionsService.savePartiallyOSData = true;
        this._conditionsService.savePartiallyOSDataFn();
        this._otherOutstandingSvc.calculateIEE();
        // this.onSubmit();
        // console.log("Delete Success");
      }
      else {
        //in case of cancel
        return;
      }
    })
      .catch(() => {
        // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)');
        return;
      });
  }

  Edit(content: any, data: any, h4: any, index: any) {
    this.index = index;
    this.modelHeading = h4;
    this._otherOutstandingSvc.dropdownsDef(data);
    this._otherOutstandingSvc.edit = true;

    this._sharedSvc.msg = this.translate.instant('toast.recordUpdated');
    this.modalService.open(content, {
      backdrop: "static",
      keyboard: false,
      windowClass: "popupWidth800",
      backdropClass: 'light-blue-backdrop',
      centered: true
    });
    this._otherOutstandingSvc.otherStandingModel = data;
    let tempDepositDate = new Date(this._otherOutstandingSvc.otherStandingModel.cmplDt);
    this._otherOutstandingSvc.otherStandingModel.cmplDt = { year: tempDepositDate.getFullYear(), month: tempDepositDate.getMonth() + 1, day: tempDepositDate.getDate() };

    this.setValueToForm();
    // this._conditionsService.savePartiallyOSData = true;
    // this._conditionsService.savePartiallyOSDataFn();

  }

  saveData(): void {
    this.setValueToModel();
    if (this.outstandingForm.invalid) {
      for (const control of Object.keys(this.outstandingForm.controls)) {
        this.outstandingForm.controls[control].markAsTouched();
      }
      return;
    }
    if (this._otherOutstandingSvc.otherStandingModel.cmplDt.year) {
      this._otherOutstandingSvc.otherStandingModel.cmplDt = this._otherOutstandingSvc.otherStandingModel.cmplDt.year + '-' + this._otherOutstandingSvc.otherStandingModel.cmplDt.month + '-' + this._otherOutstandingSvc.otherStandingModel.cmplDt.day
      this._otherOutstandingSvc.otherStandingModel.cmplDt = new Date(this._otherOutstandingSvc.otherStandingModel.cmplDt);
    }
    this._otherOutstandingSvc.saveOtherStandingModel.push(this._otherOutstandingSvc.otherStandingModel);
    this._conditionsService.savePartiallyOSData = true;
    this._conditionsService.savePartiallyOSDataFn();

    let addRow = {
      "tenetKey": this._otherOutstandingSvc.otherStandingModel.tenetKey,
      "clntSeq": this._otherOutstandingSvc.otherStandingModel.clntSeq,
      "othOutsdCrdtSeq": this._otherOutstandingSvc.otherStandingModel.othOutsdCrdtSeq,
      "instnNm": this._otherOutstandingSvc.otherStandingModel.instnNm,
      "totCrdtAmt": this._otherOutstandingSvc.otherStandingModel.totCrdtAmt,
      "crdtPrps": this._otherOutstandingSvc.otherStandingModel.crdtPrps,
      "crntOutsdAmt": this._otherOutstandingSvc.otherStandingModel.crntOutsdAmt,
      "cmplDt": this._otherOutstandingSvc.otherStandingModel.cmplDt,
      "mnthExpFlg": this._otherOutstandingSvc.otherStandingModel.mnthExpFlg,
      "mfcibFlg": this._otherOutstandingSvc.otherStandingModel.mfcibFlg,
      "crtdBy": this._otherOutstandingSvc.otherStandingModel.crtdBy,
      "crtdDt": this._otherOutstandingSvc.otherStandingModel.crtdDt,
      "lastUpdBy": this._otherOutstandingSvc.otherStandingModel.lastUpdBy,
      "lastUpdDt": this._otherOutstandingSvc.otherStandingModel.lastUpdDt,
      "delFlg": this._otherOutstandingSvc.otherStandingModel.delFlg,
    }

    this._otherOutstandingSvc.getAllOutstanding.push(addRow);
    this._sharedSvc.collectionSize = this._otherOutstandingSvc.getAllOutstanding?.length;
    this._otherOutstandingSvc.getAllOutstandingPG = this._otherOutstandingSvc.getAllOutstanding.slice((this._sharedSvc.page - 1) * this._sharedSvc.pageSize, (this._sharedSvc.page - 1) * this._sharedSvc.pageSize + this._sharedSvc.pageSize);

    this.modalService.dismissAll();
    this._otherOutstandingSvc.calculateIEE();
    this._sharedSvc.success(this._sharedSvc.msg);
  }

  onUpdate() {
    this.setValueToModel();
    this._otherOutstandingSvc.getAllOutstandingPG[this.index] = this._otherOutstandingSvc.otherStandingModel;
    if (this._otherOutstandingSvc.otherStandingModel.cmplDt.year) {
      this._otherOutstandingSvc.otherStandingModel.cmplDt = this._otherOutstandingSvc.otherStandingModel.cmplDt.year + '-' + this._otherOutstandingSvc.otherStandingModel.cmplDt.month + '-' + this._otherOutstandingSvc.otherStandingModel.cmplDt.day
      this._otherOutstandingSvc.otherStandingModel.cmplDt = new Date(this._otherOutstandingSvc.otherStandingModel.cmplDt);
    }
    this._conditionsService.savePartiallyOSData = true;
    this._conditionsService.savePartiallyOSDataFn();
    this._otherOutstandingSvc.getAllOutstanding = this._otherOutstandingSvc.getAllOutstandingPG;
    this._otherOutstandingSvc.calculateIEE();
    this.dismissModal();
  }

  onSubmit(): void {
    this.setValueToModel();
    for (let j = 0; j < this._otherOutstandingSvc.getAllOutstandingPG.length; j++) {
      if (this._otherOutstandingSvc.getAllOutstandingPG[j].cmplDt.year) {
        this._otherOutstandingSvc.getAllOutstandingPG[j].cmplDt = this._otherOutstandingSvc.getAllOutstandingPG[j].cmplDt.year + '-' + this._otherOutstandingSvc.getAllOutstandingPG[j].cmplDt.month + '-' + this._otherOutstandingSvc.getAllOutstandingPG[j].cmplDt.day
        this._otherOutstandingSvc.getAllOutstandingPG[j].cmplDt = new Date(this._otherOutstandingSvc.getAllOutstandingPG[j].cmplDt);
      }
      // this._otherOutstandingSvc.saveOtherStandingModel = this._otherOutstandingSvc.otherStandingModel;

    }
    this._otherOutstandingSvc.otherStandingModel = this._otherOutstandingSvc.getAllOutstandingPG;
    this._sharedSvc.showSpinner();
    this._otherOutstandingSvc.SaveClientOtherOutstanding().subscribe({
      next: (response) => {
        if (response) {
          this._otherOutstandingSvc.getAllOutstandingPG = response;
          if (this.route.snapshot.paramMap.get("id")) { }
          else {
            localStorage.setItem('otherStandingModelValues', JSON.stringify(this._otherOutstandingSvc.otherStandingModel));
          }
          this._sharedSvc.hideSpinner();
          this.spliceDel()
          this._conditionsService.savePartiallyOSData = false;
          this._conditionsService.savePartiallyOSDataFn();
          this._otherOutstandingSvc.saveOtherStandingModel = [];
          localStorage.removeItem('saveOtherStandingModel');
          this._sharedSvc.success(this._sharedSvc.msg);
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }

  spliceDel() {
    this._otherOutstandingSvc.IIE = 0;
    this._otherOutstandingSvc.getAllOutstanding = this._otherOutstandingSvc.getAllOutstandingPG;
    for (let i = 0; i < this._otherOutstandingSvc.getAllOutstanding?.length; i++) {
      if (this._otherOutstandingSvc.getAllOutstanding[i].delFlg == true) {
        this._otherOutstandingSvc.getAllOutstanding.splice(i, 1);
      }
    }
    this._otherOutstandingSvc.getAllOutstanding = this._otherOutstandingSvc.getAllOutstandingPG;
    this._otherOutstandingSvc.calculateIEE();
  }

  setValueToModel() {
    this._otherOutstandingSvc.otherStandingModel.instnNm = this.instnNm.value
    this._otherOutstandingSvc.otherStandingModel.totCrdtAmt = this.totCrdtAmt.value
    this._otherOutstandingSvc.otherStandingModel.crdtPrps = this.crdtPrps.value
    this._otherOutstandingSvc.otherStandingModel.crntOutsdAmt = this.crntOutsdAmt.value
    this._otherOutstandingSvc.otherStandingModel.cmplDt = this.cmplDt.value
    this._otherOutstandingSvc.otherStandingModel.mnthExpFlg = this.mnthExpFlg.value


  }

  setValueToForm() {
    this.instnNm.setValue(this._otherOutstandingSvc.otherStandingModel.instnNm);
    this.totCrdtAmt.setValue(this._otherOutstandingSvc.otherStandingModel.totCrdtAmt);
    this.crdtPrps.setValue(this._otherOutstandingSvc.otherStandingModel.crdtPrps);
    this.crntOutsdAmt.setValue(this._otherOutstandingSvc.otherStandingModel.crntOutsdAmt);
    this.cmplDt.setValue(this._otherOutstandingSvc.otherStandingModel.cmplDt);
    this.mnthExpFlg.setValue(this._otherOutstandingSvc.otherStandingModel.mnthExpFlg);

  }

  validationForm() {
    this.outstandingForm = new FormGroup({
      instnNm: new FormControl("", [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100),
      ]),
      totCrdtAmt: new FormControl(null, [
        Validators.required,
        Validators.min(1),
        Validators.max(500000),
      ]),
      crdtPrps: new FormControl("", [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100),
      ]),
      crntOutsdAmt: new FormControl(null, [
        Validators.required,
        Validators.min(1),
        Validators.max(500000),
      ]),
      cmplDt: new FormControl("", [
        Validators.required,
      ]),
      mnthExpFlg: new FormControl(false, [
        Validators.required,
      ]),
    });
  }


  get instnNm() { return this.outstandingForm.get("instnNm") }
  get totCrdtAmt() { return this.outstandingForm.get("totCrdtAmt") }
  get crdtPrps() { return this.outstandingForm.get("crdtPrps") }
  get crntOutsdAmt() { return this.outstandingForm.get("crntOutsdAmt") }
  get cmplDt() { return this.outstandingForm.get("cmplDt") }
  get mnthExpFlg() { return this.outstandingForm.get("mnthExpFlg") }

}
