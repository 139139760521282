<section>
  <div class="sidebar" [class.sidebar-close]="!_sharedSvc.openSidebar">
    <app-sidebar></app-sidebar>
  </div>
  <div class="home-section">
    <div class="home-main" [class.sidebar-close_home-main]="!_sharedSvc.openSidebar">
      <app-navbar></app-navbar>
      <div class="home-view" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
        [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
        <section>
          <div class="row mb-3">
            <div class="col-sm-8 col-md-8 col-lg-8">
              <h4>{{ "sideBarMenu.setup.expenseType" | translate }}</h4>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4">
              <div class="add-button" (click)="openModal(expenseType, false)">
                <i class="fa fa-plus"></i>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="dashboard-card loan-table">
                <div class="row mb-3">
                  <div class="col-sm-7 col-md-7 col-lg-8">
                    <ng-container *ngIf="_sharedSvc.pageSize >= 10">
                      <span>{{ "common.show" | translate }}</span>
                      <input name="entries" class="m-1 text-center" [value]="_SetupService.typesValueTablePG?.length"
                        readonly style="width: 45px" />
                      <span>{{ "common.entries" | translate }}</span>
                    </ng-container>
                  </div>
                  <div class="col-sm-5 col-md-5 col-lg-4">
                    <div class="inside-icon">
                      <i class="fa fa-search"></i>
                      <input type="search" class="form-control search-input"
                        placeholder="{{ 'common.search' | translate }}" />
                    </div>
                  </div>
                </div>
                <div class="table-responsive">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <td>{{ "expenseTypes.expenseTypeId" | translate }}</td>
                        <td>
                          {{ "sideBarMenu.setup.expenseType" | translate }}
                        </td>
                        <td>{{ "common.getAccountMapping" | translate }}</td>
                        <td>{{ "common.branch" | translate }}</td>
                        <td>{{ "common.status" | translate }}</td>
                        <td class="text-center">
                          {{ "common.action" | translate }}
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let list of _SetupService.typesValueTablePG">
                        <td>{{ list.typId }}</td>
                        <td>{{ list.typStr }}</td>
                        <td>{{ list.glAcctNum }}</td>
                        <td>{{ list.brnchSeq }}</td>
                        <td>{{ list.typStsKey }}</td>
                        <td class="text-center">
                          <div class="action-inner">
                            <i class="fa fa-pencil" (click)="
                                editSetupType(expenseType, list.typSeq, true)
                              "></i>
                            <i class="fa fa-trash" (click)="deleteRecord(list.typSeq)"></i>
                            <i class="fa fa-repeat"></i>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-6">
                      <span class="clr-9d9a9a">{{ "common.showing" | translate }}
                        {{ _SetupService.typesValueTablePG?.length }}
                        {{ "common.of" | translate }}
                        {{ _SetupService.collectionSizeSetupType }}
                        entries</span>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6">
                      <div class="float-right">
                        <ngb-pagination [(page)]="_sharedSvc.page" [pageSize]="_sharedSvc.pageSize" [maxSize]="10"
                          ngDefaultControl [collectionSize]="
                            _SetupService.collectionSizeSetupType
                          " [rotate]="true" (pageChange)="_SetupService.pageSetupTypes()">
                          <ng-template ngbPaginationPrevious>
                            {{ "common.prev" | translate }}</ng-template>
                          <ng-template ngbPaginationNext>{{
                            "common.next" | translate
                            }}</ng-template>
                        </ngb-pagination>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>

<ng-template id="display-modal" #expenseType let-c="close" let-d="dismiss">
  <div class="modal-body" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
    [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
    <div class="p-3 pb-0">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center">
            <h4>{{ "sideBarMenu.setup.expenseType" | translate }}</h4>
          </div>
        </div>
      </div>
      <form [formGroup]="addExpenseTypeformData" (ngSubmit)="submitExpenseTypeForm(addExpenseTypeformData)">
        <div class="row mb-3">
          <div class="col-sm-12 col-md-12 col-lg-3 mb-3">
            <div class="form-floating">
              <input type="text" style="text-transform: capitalize" autocomplete="off" class="form-control input-radius"
                formControlName="typId" [class.is-invalid]="
                  typId.invalid && (typId.dirty || typId.touched)
                " />
              <div *ngIf="typId.invalid && (typId.dirty || typId.touched)" class="invalid-feedback">
                <div *ngIf="typId.errors?.['required']">
                  {{ "common.idRequire" | translate }}
                </div>
              </div>
              <label>{{ "common.id" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-3 mb-3">
            <div class="form-floating">
              <div class="form-floating">
                <input type="text" style="text-transform: capitalize" autocomplete="off"
                  class="form-control input-radius" formControlName="typStr" [class.is-invalid]="
                    typStr.invalid && (typStr.dirty || typStr.touched)
                  " />
                <div *ngIf="typStr.invalid && (typStr.dirty || typStr.touched)" class="invalid-feedback">
                  <div *ngIf="typStr.errors?.['required']">
                    {{ "common.typeRequire" | translate }}
                  </div>
                </div>
                <label>{{ "common.type" | translate }}</label>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-12 col-lg-3 mb-3">
            <div class="form-floating">
              <select name="gndrKey" id="gndrKey" class="form-control input-radius form-select"
                formControlName="brnchSeq" [class.is-invalid]="
                  brnchSeq.invalid && (brnchSeq.dirty || brnchSeq.touched)
                ">
                <option *ngFor="
                    let item of _SetupService.typesBranchList;
                    let i = index
                  " [value]="item?.branchSequence">
                  {{ item.branchName }}
                </option>
              </select>
              <div *ngIf="brnchSeq.invalid && (brnchSeq.dirty || brnchSeq.touched)" class="invalid-feedback">
                <div *ngIf="brnchSeq.errors?.['required']">
                  {{ "common.branchRequire" | translate }}
                </div>
              </div>
              <label>{{ "common.branch" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-3 mb-3">
            <div class="form-floating">
              <select name="gndrKey" id="gndrKey" class="form-control input-radius form-select"
                formControlName="typStsKey" [class.is-invalid]="
                  typStsKey.invalid && (typStsKey.dirty || typStsKey.touched)
                ">
                <option *ngFor="
                    let item of _picklistService.Ref_Code_Grp_Data;
                    let i = index
                  " [value]="item?.refCdSeq">
                  {{ item.refCdDscr }}
                </option>
              </select>
              <div *ngIf="
                  typStsKey.invalid && (typStsKey.dirty || typStsKey.touched)
                " class="invalid-feedback">
                <div *ngIf="typStsKey.errors?.['required']">
                  {{ "common.statusRequire" | translate }}
                </div>
              </div>
              <label>{{ "common.status" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
            <div class="form-floating">
              <div class="form-floating">
                <select name="gndrKey" id="gndrKey" class="form-control input-radius form-select"
                  formControlName="glAcctNum" [class.is-invalid]="
                    glAcctNum.invalid && (glAcctNum.dirty || glAcctNum.touched)
                  ">
                  <option *ngFor="
                      let item of _SetupService.typesBranchList;
                      let i = index
                    " [value]="item?.branchSequence">
                    {{ item.branchName }}
                  </option>
                </select>
                <div *ngIf="
                    glAcctNum.invalid && (glAcctNum.dirty || glAcctNum.touched)
                  " class="invalid-feedback">
                  <div *ngIf="glAcctNum.errors?.['required']">
                    {{ "paymentTypes.glAccountRequired" | translate }}
                  </div>
                </div>
                <label>{{ "paymentTypes.glAccountNumber" | translate }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="button_div">
              <button class="btn login-button-class" [class.pointerNone]="_sharedSvc.loading"
                [disabled]="_sharedSvc.loading">
                {{ "common.ok" | translate }}
              </button>
              <button class="btn login-button-class cancelBtn" (click)="d('Cross click')" type="reset">
                {{ "common.cancel" | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>