export class Safe{
    cltrlLctnSeq: number;
    crdtAppSeq: number;
    safeSeq: number;
    statusKey: number;
    statusDt: string;
    aprvdById: string;
    crtdBy: string;
    crtdDt: string;
    lastUpdBy: string;
    lastUpdDt: string;
    delFlg: boolean;
  }