import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-client-credit-utilization-plan',
  templateUrl: './client-credit-utilization-plan.component.html',
  styleUrls: ['./client-credit-utilization-plan.component.css']
})
export class ClientCreditUtilizationPlanComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
