<section>
    <div class="sidebar" [class.sidebar-close]="!_sharedSvc.openSidebar">
        <app-sidebar></app-sidebar>
    </div>
    <div class="home-section">
        <div class="home-main" [class.sidebar-close_home-main]="!_sharedSvc.openSidebar">
            <app-navbar></app-navbar>
            <div class="home-view" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
                [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
                <section>
                    <div class="row mb-3">
                        <div class="col-sm-8 col-md-8 col-lg-8">
                            <h4>{{'productListing.productGroups' | translate}}</h4>
                        </div>
                        <div class="col-sm-4 col-md-4 col-lg-4">
                            <div class="add-button" (click)="openModal(addProductGroups , false)"><i class="fa fa-plus"></i>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <div class="dashboard-card loan-table">
                                <div class="row mb-3">
                                    <div class="col-sm-7 col-md-7 col-lg-8">
                                        <ng-container *ngIf="_sharedSvc.pageSize >= 10">
                                            <span>{{'common.show' | translate}}</span>
                                            <input type="number" class="m-1 text-center" style="width: 45px;">
                                            <span>{{'common.entries' | translate}}</span>
                                        </ng-container>
                                    </div>
                                    <div class="col-sm-5 col-md-5 col-lg-4">
                                        <div class="inside-icon">
                                            <i class="fa fa-search"></i>
                                            <input type="search" class="form-control search-input"
                                                placeholder="{{'common.search' | translate}}">
                                        </div>
                                    </div>
                                </div>
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <td>{{'common.productId' | translate}}</td>
                                                <td>{{'common.productName' | translate}}</td>
                                                <td>{{'common.status' | translate}}</td>
                                                <td class="text-center">{{'common.action' | translate}}</td>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let list of getAllProductsGroupsList">
                                                <td>{{list.prdGrpId}}</td>
                                                <td class="clr186ed1 pointer" (click)="navigate(list.prdGrpSeq)">{{list.prdGrpNm}}</td>
                                                <td>{{ list.prdGrpSts === 2131
                                                    ? this.translate.instant("common.active")
                                                    : this.translate.instant("common.inActive")
                                                }}</td>
                                                <td class="text-center">
                                                    <div class="action-inner">
                                                        <i class="fa-solid fa-pencil cursor-pointer" (click)="editproductGroup(addProductGroups,list.prdGrpSeq,true)"></i>
                                                        <i class="fa fa-history cursor-pointer"></i>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12 col-lg-6">
                                            <span class="clr-9d9a9a">{{'common.showing' | translate}} {{'common.1To3Of'
                                                | translate}} 3
                                                {{'common.entries' |
                                                translate}}</span>
                                        </div>
                                        <div class="col-sm-12 col-md-12 col-lg-6">
                                            <div class="float-right">
                                                <nav aria-label="Page navigation example">
                                                    <ul class="pagination">
                                                        <li class="page-item"><a class="page-link">{{'common.first' |
                                                                translate}}</a></li>
                                                        <li class="page-item"><a class="page-link">{{'common.previous' |
                                                                translate}}</a></li>
                                                        <li class="page-item"><a class="page-link">1</a></li>
                                                        <li class="page-item"><a class="page-link">2</a></li>
                                                        <li class="page-item"><a class="page-link">3</a></li>
                                                        <li class="page-item"><a class="page-link">4</a></li>
                                                        <li class="page-item"><a class="page-link">5</a></li>
                                                        <li class="page-item"><a class="page-link">{{'common.next' |
                                                                translate}}</a></li>
                                                        <li class="page-item"><a class="page-link">{{'common.last' |
                                                                translate}}</a></li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</section>


<ng-template id="display-modal" #addProductGroups let-c="close" let-d="dismiss">
    <div class="modal-body" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
        [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
        <div class="p-3 pb-0">
            <div class="row mb-3">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="text-center">
                        <h4>{{isEdit === true ? this.translate.instant('common.edit') :
                            this.translate.instant('common.add')}} Product Group</h4>
                    </div>
                </div>
            </div>
            <form [formGroup]="addProductGroupFormdata" (ngSubmit)="submitProductGroup(addProductGroupFormdata)">
                <div class="row mb-3">
                 
                    <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                        <div class="form-floating">
                            <input type="text" style="text-transform: capitalize;" formControlName="prdGrpNm"
                                autocomplete="off" class="form-control input-radius"
                                [class.is-invalid]="prdGrpNm.invalid && (prdGrpNm.dirty || prdGrpNm.touched)">
                            <div *ngIf="prdGrpNm.invalid && (prdGrpNm.dirty || prdGrpNm.touched)" class="invalid-feedback">
                                <div *ngIf="prdGrpNm.errors?.['required']">
                                    Product Name is required.
                                </div>

                            </div>
                            <label>Product Name</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                        <div class="form-floating">
                            <select name="gndrKey" id="gndrKey" class="form-control input-radius form-select"
                                formControlName="prdGrpSts"
                                [class.is-invalid]="prdGrpSts.invalid && (prdGrpSts.dirty || prdGrpSts.touched)">
                                <option *ngFor="let item of _picklistService.SHROFF_STATUS_Data; let i = index"
                                    [value]="item?.refCdSeq">
                                    {{ item.refCdDscr }}
                                </option>
                            </select>

                            <div *ngIf="prdGrpSts.invalid && (prdGrpSts.dirty || prdGrpSts.touched)"
                                class="invalid-feedback">
                                <div *ngIf="prdGrpSts.errors?.['required']">
                                    {{'common.statusRequire' | translate}}
                                </div>

                            </div>
                            <label>{{'common.status' | translate}}</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="button_div">
                            <button class="btn login-button-class" [class.pointerNone]="_sharedSvc.loading"
                                [disabled]="_sharedSvc.loading">{{'common.ok' | translate}}</button>
                            <button class="btn login-button-class cancelBtn" (click)="d('Cross click')"
                                type="reset">{{'common.cancel' | translate}}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <!-- <div class="modal-body" [dir]="
      translate?.currentLang === 'ar' || translate?.currentLang === 'ur'
        ? 'rtl'
        : ''
    " [class.fix-direction]="
      translate?.currentLang === 'ar' || translate?.currentLang === 'ur'
    ">
        <div class="p-3 pb-0">
            <div class="row mb-3">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="text-center">
                        <h4>{{isEdit === true ? "Edit" : "Add"}} Product Group</h4>
                    </div>
                </div>
            </div>
            <form>
                <div class="row mb-3">
                    <div class="col-sm-12 col-md-12 col-lg-3 mb-3">
                        <div class="form-floating">
                            <input type="text" style="text-transform: capitalize" autocomplete="off"
                                class="form-control input-radius" />
                            <label>{{ "common.amount" | translate }}</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-3 mb-3">
                        <div class="form-floating">
                            <select class="form-control form-select input-radius">
                                <option>{{ "common.select" | translate }}</option>
                                <option>{{ "common.select" | translate }}</option>
                                <option>{{ "common.select" | translate }}</option>
                            </select>
                            <label>{{ "common.paymentMode" | translate }}</label>
                        </div>
                    </div>
                  
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="button_div">
                            <button class="btn login-button-class" [class.pointerNone]="_sharedSvc.loading"
                                [disabled]="_sharedSvc.loading">
                                {{ "common.ok" | translate }}
                            </button>
                            <button class="btn login-button-class cancelBtn" (click)="d('Cross click')" type="reset">
                                {{ "common.cancel" | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div> -->
</ng-template>