import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../shared/service/shared.service';
import { AuthService } from 'src/app/auth/service/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  jwtInfo:any;
  currentLang:any = "";

  constructor(
    public _sharedSvc : SharedService,public authService:AuthService,  public translate: TranslateService
  ) { 
    translate.addLangs(['en', 'ar', 'ur']);
    translate.setDefaultLang('en');
    this.currentLang = 'en';
  }

  ngOnInit(): void {
    this.jwtInfo = sessionStorage.getItem('JWT');
    this.jwtInfo = JSON.parse(this.jwtInfo);
    let lang = localStorage.getItem("currentLang"); 
    if(lang){
      this.currentLang = lang;
      this.switchLang(lang);
    }
    
  }
  
  switchLang(lang: string) {
    localStorage.setItem("currentLang",lang); 
    this.translate.use(lang);
    this.currentLang = lang
  }
}
