import { Component } from '@angular/core';
import { SharedService } from './components/shared/service/shared.service';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { AfterViewChecked, ChangeDetectorRef } from '@angular/core';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewChecked {
  title = 'loan-project';


  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;

  public config = {
    animationType: ngxLoadingAnimationTypes.none,
    primaryColour: '#ffffff',
    secondaryColour: '#ffffff',
    backdropBorderRadius: '3px',
  };

  constructor(
    public _sharedSvc: SharedService,
    private readonly changeDetectorRef: ChangeDetectorRef, 
  ) {}

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
   
  }
}
