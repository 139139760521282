import { Injectable } from '@angular/core';
import { CreditCollateralService } from 'src/app/components/Individual-Group-Loan/add-individual-credit-app/client-credit-collateral-detail/shared/service/credit-collateral.service';
import { SharedPopupService } from 'src/app/components/shared/service/shared-popup.service';
import { SharedService } from 'src/app/components/shared/service/shared.service';
import { EmployerInfoService } from '../../employer-info/shared/service/employer-info.service';
import { FiniancialInfoService } from '../../financial-info/shared/service/finiancial-info.service';
import { OtherOutstandingService } from '../../other-outstanding/shared/service/other-outstanding.service';

@Injectable({
  providedIn: 'root'
})

export class RoutingConditionsService {

  personalInfo: boolean = false;
  personalInfoBtn: boolean = false;
  clientDoc: boolean = false;
  othOutStand: boolean = false;
  bizApprsl: boolean = false;
  empInfo: boolean = false;
  finInfo: boolean = false;
  summary: boolean = false;
  savePartiallyOSData: boolean = false; // Other Outstanding
  savePartiallyINCData: boolean = false; // Business Income
  savePartiallyExpData: boolean = false; // Business Expense
  savePrimaryDataData: boolean = false; // Primary Income
  saveSecondaryData: boolean = false; // Secondary Income
  saveHouseHldData: boolean = false; // House Hold Expense
  employerInfoData: boolean = false; // Employer Info
  salaryDetailData: boolean = false; // Salary Detail
  creditInfo: boolean = false;
  creditDoc: boolean = false;
  CreditCollateral: boolean = false;
  creditSummary: boolean = false;
  creditCollaterial: boolean = false; // Credit Collaterial Detail

  readOnlyView_Client:boolean = false;
  readOnlyView_Credit:boolean = false;
  constructor(
    public _sharedPopupSvc: SharedPopupService,
    public _otherOutstandingSvc: OtherOutstandingService,
    public _finiancialInfoSvc: FiniancialInfoService,
    public _employerInfoSvc: EmployerInfoService,
    public _creditCollateralSvc: CreditCollateralService,

  ) { }

  toggle() {
    if (this.savePartiallyOSData) {
      this._sharedPopupSvc.alert("", "Your Other Outstanding Record not Saved !", "", "Ok", "Discard", null, "Do you want to save it ?").then((confirmed: any) => {
        if (confirmed) {
          //in case of OK
        }
        else {
          //in case of cancel
          this.savePartiallyOSData = false;
          this.clientALlFalse();
          this._otherOutstandingSvc.getAllOutstandingPG = localStorage.getItem('getAllOutstandingData');
          this._otherOutstandingSvc.getAllOutstandingPG = JSON.parse(this._otherOutstandingSvc.getAllOutstandingPG);
          this._otherOutstandingSvc.IIE = 0;
          for (let i = 0; i < this._otherOutstandingSvc.getAllOutstandingPG?.length; i++) {
            if (this._otherOutstandingSvc.getAllOutstandingPG[i].mnthExpFlg == true) {
              let IIE = this._otherOutstandingSvc.getAllOutstandingPG[i].crntOutsdAmt;
              this._otherOutstandingSvc.IIE = this._otherOutstandingSvc.IIE + IIE;
            }
            localStorage.setItem('IIE', this._otherOutstandingSvc.IIE);
          }
          return;
        }
      })
        .catch(() => {
          // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)');
          return;
        });
    }
    if (this.savePartiallyINCData) {
      this._sharedPopupSvc.alert("", "Your Business Income Record not Saved !", "", "Ok", "Discard", null, "Do you want to save it ?").then((confirmed: any) => {
        if (confirmed) {
          //in case of OK
        }
        else {
          //in case of cancel
          this.savePartiallyINCData = false;
          this.clientALlFalse();
          if (localStorage.getItem('saveBusinessIncModel')) {
            this._finiancialInfoSvc.getClntAllSPriIncmDtlSeqPG = localStorage.getItem('saveBusinessIncModel');
            this._finiancialInfoSvc.getClntAllSPriIncmDtlSeqPG = JSON.parse(this._finiancialInfoSvc.getClntAllSPriIncmDtlSeqPG);
            let response = this._finiancialInfoSvc.getClntAllSPriIncmDtlSeqPG
            for (let i = 0; i < response.length; i++) {
              if (response[i].frqncy == 2000) {
                this._finiancialInfoSvc.tempIncAmt = (response[i].incmAmt * response[i].qnty) / 1
              }
              if (response[i].frqncy == 2001) {
                this._finiancialInfoSvc.tempIncAmt = (response[i].incmAmt * response[i].qnty) / 3
              }
              if (response[i].frqncy == 2002) {
                this._finiancialInfoSvc.tempIncAmt = (response[i].incmAmt * response[i].qnty) / 6
              }
              if (response[i].frqncy == 2003) {
                this._finiancialInfoSvc.tempIncAmt = (response[i].incmAmt * response[i].qnty) / 12
              }
              this._finiancialInfoSvc.totalIncmAmt = this._finiancialInfoSvc.totalIncmAmt + this._finiancialInfoSvc.tempIncAmt;
            }
            for (let i = 0; i < this._finiancialInfoSvc.getAllIncome.length; i++) {
              let incmAmt = this._finiancialInfoSvc.getAllIncome[i].incmAmt;
              this._finiancialInfoSvc.totalIncmAmt = this._finiancialInfoSvc.totalIncmAmt + incmAmt;

              localStorage.setItem('totalIncmAmt', this._finiancialInfoSvc.totalIncmAmt);
            }
          }
          return;
        }
      })
        .catch(() => {
          // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)');
          return;
        });
    }
    if (this.savePartiallyExpData) {
      this._sharedPopupSvc.alert("", "Your Business Expense Record not Saved !", "", "Ok", "Discard", null, "Do you want to save it ?").then((confirmed: any) => {
        if (confirmed) {
          //in case of OK
        }
        else {
          //in case of cancel
          this.savePartiallyExpData = false;
          this.clientALlFalse();
          this._finiancialInfoSvc.getClntAllExpnsDtlSeqPG = localStorage.getItem('saveBusinessExpModel');
          this._finiancialInfoSvc.getClntAllExpnsDtlSeqPG = JSON.parse(this._finiancialInfoSvc.getClntAllExpnsDtlSeqPG);
          this._finiancialInfoSvc.calculationTExp(1);
          this._finiancialInfoSvc.formulasCal();
          return;
        }
      })
        .catch(() => {
          // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)');
          return;
        });
    }
    if (this.savePrimaryDataData) {
      this._sharedPopupSvc.alert("", "Your Primary Income Record not Saved !", "", "Ok", "Discard", null, "Do you want to save it ?").then((confirmed: any) => {
        if (confirmed) {
          //in case of OK
        }
        else {
          //in case of cancel
          this.savePrimaryDataData = false;
          this.clientALlFalse();
          this._finiancialInfoSvc.getClntAllSPriIncmDtlSeqPG = localStorage.getItem('savePrimaryDataModel');
          this._finiancialInfoSvc.getClntAllSPriIncmDtlSeqPG = JSON.parse(this._finiancialInfoSvc.getClntAllSPriIncmDtlSeqPG);
          this._finiancialInfoSvc.totalIncmAmt = 0;
          this._finiancialInfoSvc.tempIncAmt = 0;
          let response = this._finiancialInfoSvc.getClntAllSPriIncmDtlSeqPG
          for (let i = 0; i < response.length; i++) {
            if (response[i].frqncy == 2000) {
              this._finiancialInfoSvc.tempIncAmt = (response[i].incmAmt * response[i].qnty) / 1
            }
            if (response[i].frqncy == 2001) {
              this._finiancialInfoSvc.tempIncAmt = (response[i].incmAmt * response[i].qnty) / 3
            }
            if (response[i].frqncy == 2002) {
              this._finiancialInfoSvc.tempIncAmt = (response[i].incmAmt * response[i].qnty) / 6
            }
            if (response[i].frqncy == 2003) {
              this._finiancialInfoSvc.tempIncAmt = (response[i].incmAmt * response[i].qnty) / 12
            }
            this._finiancialInfoSvc.totalIncmAmt = this._finiancialInfoSvc.totalIncmAmt + this._finiancialInfoSvc.tempIncAmt;
          }
          for (let i = 0; i < this._finiancialInfoSvc.getAllPriIncome.length; i++) {
            let incmAmt = this._finiancialInfoSvc.getAllPriIncome[i].incmAmt;
            this._finiancialInfoSvc.totalIncmAmt = this._finiancialInfoSvc.totalIncmAmt + incmAmt;
            localStorage.setItem('totalPrimaryAmt', this._finiancialInfoSvc.totalIncmAmt)
          }
          return;
        }
      })
        .catch(() => {
          // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)');
          return;
        });
    }
    if (this.saveSecondaryData) {
      this._sharedPopupSvc.alert("", "Your Secondary Income Record not Saved !", "", "Ok", "Discard", null, "Do you want to save it ?").then((confirmed: any) => {
        if (confirmed) {
          //in case of OK
        }
        else {
          //in case of cancel
          this.saveSecondaryData = false;
          this.clientALlFalse();
          this._finiancialInfoSvc.getClntAllSIncmDtlSeqPG = localStorage.getItem('saveSecondaryDataModel');
          this._finiancialInfoSvc.getClntAllSIncmDtlSeqPG = JSON.parse(this._finiancialInfoSvc.getClntAllSIncmDtlSeqPG);
          this._finiancialInfoSvc.totalIncmAmt = 0;
          this._finiancialInfoSvc.tempIncAmt = 0;
          let response = this._finiancialInfoSvc.getClntAllSIncmDtlSeqPG
          for (let i = 0; i < response.length; i++) {
            if (response[i].frqncy == 2000) {
              this._finiancialInfoSvc.tempIncAmt = (response[i].incmAmt * response[i].qnty) / 1
            }
            if (response[i].frqncy == 2001) {
              this._finiancialInfoSvc.tempIncAmt = (response[i].incmAmt * response[i].qnty) / 3
            }
            if (response[i].frqncy == 2002) {
              this._finiancialInfoSvc.tempIncAmt = (response[i].incmAmt * response[i].qnty) / 6
            }
            if (response[i].frqncy == 2003) {
              this._finiancialInfoSvc.tempIncAmt = (response[i].incmAmt * response[i].qnty) / 12
            }
            this._finiancialInfoSvc.totalIncmAmt = this._finiancialInfoSvc.totalIncmAmt + this._finiancialInfoSvc.tempIncAmt;
          }
          for (let i = 0; i < this._finiancialInfoSvc.getAllSecIncome.length; i++) {
            let incmAmt = this._finiancialInfoSvc.getAllSecIncome[i].incmAmt;
            this._finiancialInfoSvc.totalIncmAmt = this._finiancialInfoSvc.totalIncmAmt + incmAmt;
            localStorage.setItem('totalSecondaryAmt', this._finiancialInfoSvc.totalIncmAmt)
          }
          return;
        }
      })
        .catch(() => {
          // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)');
          return;
        });
    }
    if (this.saveHouseHldData) {
      this._sharedPopupSvc.alert("", "Your House Hold Expense Record not Saved !", "", "Ok", "Discard", null, "Do you want to save it ?").then((confirmed: any) => {
        if (confirmed) {
          //in case of OK
        }
        else {
          //in case of cancel
          this.saveHouseHldData = false;
          this.clientALlFalse();
          this._finiancialInfoSvc.getClntAllExpnsDtlSeqPG = localStorage.getItem('saveBusinessExpModel');
          this._finiancialInfoSvc.getClntAllExpnsDtlSeqPG = JSON.parse(this._finiancialInfoSvc.getClntAllExpnsDtlSeqPG);
          return;
        }
      })
        .catch(() => {
          // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)');
          return;
        });
    }
    if (this.employerInfoData) {
      this._sharedPopupSvc.alert("", "Your Employer Info Record not Saved !", "", "Ok", "Discard", null, "Do you want to save it ?").then((confirmed: any) => {
        if (confirmed) {
          //in case of OK
        }
        else {
          //in case of cancel
          this.employerInfoData = false;
          this.clientALlFalse();
          this._employerInfoSvc.getAllEmployerPG = localStorage.getItem('saveEmployerModelObj');
          this._employerInfoSvc.getAllEmployerPG = JSON.parse(this._employerInfoSvc.getAllEmployerPG);
          return;
        }
      })
        .catch(() => {
          // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)');
          return;
        });
    }
    if (this.salaryDetailData) {
      this._sharedPopupSvc.alert("", "Your Salary Detail  Record not Saved !", "", "Ok", "Discard", null, "Do you want to save it ?").then((confirmed: any) => {
        if (confirmed) {
          //in case of OK
        }
        else {
          //in case of cancel
          this.salaryDetailData = false;
          this.clientALlFalse();
          this._finiancialInfoSvc.getClntAllIncmDtlSeqPG = localStorage.getItem('saveSalaryDataModel');
          this._finiancialInfoSvc.getClntAllIncmDtlSeqPG = JSON.parse(this._finiancialInfoSvc.getClntAllIncmDtlSeqPG);
          return;
        }
      })
        .catch(() => {
          // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)');
          return;
        });
    }

  }
  newClient() {
    this.personalInfo = false;
    this.personalInfoBtn = true;
    this.clientDoc = true;
    this.othOutStand = true;
    this.bizApprsl = true;
    this.empInfo = true;
    this.finInfo = true;
    this.summary = true;
  }
  oldClient() {
    this.personalInfo = false;
    this.personalInfoBtn = false;
    this.clientDoc = false;
    this.othOutStand = false;
    this.bizApprsl = false;
    this.empInfo = false;
    this.finInfo = false;
    this.summary = false;
  }
  clientALlTrue() {
    this.personalInfo = true;
    this.clientDoc = true;
    this.othOutStand = true;
    this.bizApprsl = true;
    this.empInfo = true;
    this.finInfo = true;
    this.summary = true;
  }
  clientALlFalse() {
    this.personalInfo = false;
    this.clientDoc = false;
    this.othOutStand = false;
    this.bizApprsl = false;
    this.empInfo = false;
    this.finInfo = false;
    this.summary = false;
  }
  savePartiallyOSDataFn() {
    if (this.savePartiallyOSData) {
      this.clientALlTrue();
      this.othOutStand = false;
    }
    else {
      this.clientALlFalse();
    }
  }
  savePartiallyINCDataFn() {
    if (this.savePartiallyINCData) {
      this.clientALlTrue();
      this.bizApprsl = false;
    }
    else {
      this.clientALlFalse();
    }
  }
  savePartiallyExpDataFn() {
    if (this.savePartiallyExpData) {
      this.clientALlTrue();
      this.bizApprsl = false;
    }
    else {
      this.clientALlFalse();
    }
  }
  savePrimaryDataDataFn() {
    if (this.savePrimaryDataData) {
      this.clientALlTrue();
      this.finInfo = false;
    }
    else {
      this.clientALlFalse();
    }
  }
  saveSecondaryDataFn() {
    if (this.saveSecondaryData) {
      this.clientALlTrue();
      this.finInfo = false;
    }
    else {
      this.clientALlFalse();
    }
  }
  saveHouseHldDataFn() {
    if (this.saveHouseHldData) {
      this.clientALlTrue();
      this.finInfo = false;
    }
    else {
      this.clientALlFalse();
    }
  }
  employerInfoDataFn() {
    if (this.employerInfoData) {
      this.clientALlTrue();
      this.empInfo = false;
    }
    else {
      this.clientALlFalse();
    }
  }
  salaryDetailDataFn() {
    
    if (this.salaryDetailData) {
      this.clientALlTrue();
      this.empInfo = false;
    }
    else {
      this.clientALlFalse();
    }
  }


  toggleCredit() {
    if (this.creditCollaterial) {
      this._sharedPopupSvc.alert("", "Your Credit Collaterial Record not Saved !", "", "Ok", "Discard", null, "Do you want to save it ?").then((confirmed: any) => {
        if (confirmed) {
          //in case of OK
        }
        else {
          //in case of cancel
          this.creditCollaterial = false;
          this.creditFalse();
          this._creditCollateralSvc.getAllCollatrialDtl_data = localStorage.getItem('getAllCollatrialDtl_dataModel');
          this._creditCollateralSvc.getAllCollatrialDtl_data = JSON.parse(this._creditCollateralSvc.getAllCollatrialDtl_data);
          return;
        }
      })
        .catch(() => {
          // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)');
          return;
        });
    }
  }
  creditTrue() {
    this.creditInfo = true;
    this.creditDoc = true;
    this.CreditCollateral = true;
    this.creditSummary = true;
  }
  creditFalse() {
    this.creditInfo = false;
    this.creditDoc = false;
    this.CreditCollateral = false;
    this.creditSummary = false;
  }
  creditCollaterialFn() {
    if (this.creditCollaterial) {
      this.creditTrue();
      this.CreditCollateral = false;
    }
    else {
      this.creditFalse();
    }
  }
}
