<section>
    <div class="sidebar" [class.sidebar-close]="!_sharedSvc.openSidebar">
        <app-sidebar></app-sidebar>
    </div>
    <div class="home-section">
        <div class="home-main" [class.sidebar-close_home-main]="!_sharedSvc.openSidebar">
            <app-navbar></app-navbar>

            <div class="home-view" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
                [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
                <section>
                    <div class="row mb-3">
                        <div class="col-sm-8 col-md-8 col-lg-8">
                            <h4> {{'sideBarMenu.admin.goldRates' | translate}}</h4>
                        </div>
                        <div class="col-sm-4 col-md-4 col-lg-4">
                            <div class="add-button" (click)="openModal(addBag)"><i class="fa fa-plus"></i></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <div class="dashboard-card loan-table">
                                <div class="row mb-3">
                                    <div class="col-sm-7 col-md-7 col-lg-8">
                                        <ng-container *ngIf="_sharedSvc.pageSize >= 10">
                                            <span>{{'common.show' | translate}}</span>
                                            <input type="number" class="m-1 text-center" style="width: 45px;">
                                            <span>{{'common.entries' | translate}}</span>
                                        </ng-container>
                                    </div>
                                    <div class="col-sm-5 col-md-5 col-lg-4">
                                        <div class="inside-icon">
                                            <i class="fa fa-search"></i>
                                            <input type="search" class="form-control search-input"
                                                placeholder="{{'common.search' | translate}}">
                                        </div>
                                    </div>
                                </div>
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <td>{{'goldRate.dateAndTime' | translate}}</td>
                                                <td>{{'common.rate' | translate}}</td>
                                                <td>{{'goldRate.approvedBy' | translate}}</td>
                                                <td>{{'common.status' | translate}}</td>
                                                <td class="text-center">{{'common.action' | translate}}</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let rate of goldRates; let i=index;"
                                                [ngClass]="{'disabled':diasbleByIndex(i),'helighted':helight(i)}">
                                                <td>{{rate.rateDt!=null ? rate.rateDt.split("T")[0] :''}}</td>
                                                <td>{{rate.mrktRate!=null ? rate.mrktRate.toFixed(2): ''}}</td>
                                                <td>{{rate.aprvdById}}</td>
                                                <td>{{rate.rateStatusKey}}</td>
                                                <td class="text-center">
                                                    <div class="action-inner">
                                                        <i class="fa  fa-pencil" (click)="editModal(addBag,i)"></i>
                                                        <i class="fa fa-trash"></i>
                                                    </div>
                                                </td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>

    </div>
</section>

<ng-template id="display-modal" #addBag let-c="close" let-d="dismiss">
    <div class="modal-body" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
        [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
        <div class="p-3 pb-0">
            <div class="row mb-3">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="text-center">
                        <h4> {{'goldRate.addGoldRate' | translate}} </h4>
                    </div>
                </div>
            </div>
            <form [formGroup]="gold_rate_form">
                <div class="row  mb-3">
                    <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
                        <div class="form-floating">
                            <input type="date" class="form-control input-radius" formControlName="sDate"
                                (keydown)="disableDate()">
                            <label>{{'common.date' | translate}}</label>
                        </div>
                        <div *ngIf="sDate.invalid" class="error_alert">
                            <div *ngIf="sDate.errors?.['required'] && sDate.touched">{{'common.date' | translate}}
                                {{'common.isRequired' | translate}}</div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
                        <div class="form-floating">
                            <input type="text" style="text-transform: capitalize;" autocomplete="off" maxlength="10"
                                class="form-control input-radius" formControlName="goldrate">
                            <label> {{'goldRate.goldRate' | translate}}</label>
                        </div>
                        <div *ngIf="goldrate.invalid" class="error_alert">
                            <div *ngIf="goldrate.errors?.['required'] && goldrate.touched">
                                {{'goldRate.goldRateRequired' | translate}}</div>
                            <div *ngIf="goldrate.errors?.['minlength'] && goldrate.touched">
                                {{'goldRate.goldRate3characters' | translate}} </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="button_div">
                            <button class="btn login-button-class" (click)="onSubmitForm()">{{'common.ok' |
                                translate}}</button>
                            <button class="btn login-button-class cancelBtn" (click)="d('Cross click')"
                                type="reset">{{'common.cancel' | translate}}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>