import { Component, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SharedService } from "src/app/components/shared/service/shared.service";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
  NgForm,
} from "@angular/forms";

import { ClintOnboardingService } from "src/app/components/clint-onboarding/shared/service/clint-onboarding.service";
import { SetupService } from "../shared/service/setup.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-tags",
  templateUrl: "./tags.component.html",
  styleUrls: ["./tags.component.css"],
})
export class TagsComponent implements OnInit {
  loadingIndicator: boolean = false;
  addSetupTagformData: any;
  singleRecord: any;
  isEdit: boolean;

  constructor(
    private modalService: NgbModal,
    public router: Router,
    public _sharedSvc: SharedService,
    public _SetupService: SetupService,
    private formBuilder: FormBuilder,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.createSetupTagForm();
    this._SetupService.getRisk_Tag_List();
  }
  createSetupTagForm() {
    this.addSetupTagformData = this.formBuilder.group({
      tagNm: ["", Validators.required],
      tagDscr: ["", Validators.required],
      blockFlag: ["", Validators.required],
    });
  }
  get tagNm() {
    return this.addSetupTagformData.get("tagNm");
  }
  get tagDscr() {
    return this.addSetupTagformData.get("tagDscr");
  }
  get blockFlag() {
    return this.addSetupTagformData.get("blockFlag");
  }
  submitSetupTagForm(val) {
    if (this.addSetupTagformData.invalid) {
      for (const control of Object.keys(this.addSetupTagformData.controls)) {
        this.addSetupTagformData.controls[control].markAsTouched();
      }
      return;
    }
    let data = {
      tntKey: 1,
      ...this.addSetupTagformData.value,
      tagsSeq: null,
      delFlg: 0,
    };
    // data.blockFlag = data.blockFlag === "true"?"true":"false";
    if (this.isEdit) {
      data.tagsSeq = this.singleRecord.tagsSeq;
    }
    this._SetupService.setupRiskTagModel = data;
    this._sharedSvc.showSpinner3();
    this._SetupService.saveRiskTag().subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.success(
            this.translate.instant("toast.tag") +
            (this.isEdit === true
              ? this.translate.instant("toast.updated")
              : this.translate.instant("toast.added"))
          );
          this._sharedSvc.hideSpinner3();
          this.modalService.dismissAll();
          this._SetupService.getRisk_Tag_List();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner3();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(
            ([key, value]: any, index: number) => {
              this._sharedSvc.error(value);
            }
          );
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    });
  }

  editSetupTag(content: any, id: any, edit: any) {
    let list = this._SetupService.riskList;
    let record = list.find((x) => x.tagsSeq === id);
    this.singleRecord = record;
    if (record) {
      let data = {
        tagNm: record.tagNm,
        tagDscr: record.tagDscr,
        blockFlag: record.blockFlag === true ? this.translate.instant('toast.true') : this.translate.instant('toast.false'),
      };
      this.addSetupTagformData.patchValue(data);
      this.openModal(content, edit);
    }
  }

  deleteRecord(id) {
    let list = this._SetupService.riskList;
    let record = list.find((x) => x.tagsSeq === id);
    this.singleRecord = record;

    if (record) {
      let data = {
        ...record,
        delFlg: 1,
      };
      this._sharedSvc.showSpinner3();
      this._SetupService.setupRiskTagModel = data;
      this._SetupService.saveRiskTag().subscribe({
        next: (response) => {
          if (response) {
            this._sharedSvc.success(this.translate.instant("toast.tagDeleted"));
            this._sharedSvc.hideSpinner3();
            this.modalService.dismissAll();
            this._SetupService.getRisk_Tag_List();
          }
        },
        error: (e) => {
          this._sharedSvc.hideSpinner3();
          if (e.status == 401) {
            this._sharedSvc.error(this._sharedSvc.authMsg);
            this._sharedSvc.logout();
            return;
          }
          if (e?.error) {
            Object.entries(e?.error).forEach(
              ([key, value]: any, index: number) => {
                this._sharedSvc.error(value);
              }
            );
          }
          if (e?.error?.errorMessage) {
            this._sharedSvc.error(e?.error?.error);
          }
        },
      });
    }
  }

  openModal(content: any, edit: boolean) {
    this.isEdit = edit;
    this.modalService.open(content, {
      // backdrop: "static",
      // keyboard: false,
      windowClass: "popupWidth800",
      // backdropClass: 'light-blue-backdrop',
      centered: true,
    });
  }

  navigate() {
    this.router.navigate(["/disbursement-form"]);
  }
}
