<section>
  <div class="sidebar" [class.sidebar-close]="!_sharedSvc.openSidebar">
    <app-sidebar></app-sidebar>
  </div>
  <div class="home-section">
    <div class="home-main" [class.sidebar-close_home-main]="!_sharedSvc.openSidebar">
      <app-navbar></app-navbar>
      <div class="home-view" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
        [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
        <section>
          <div class="row mb-3">
            <div class="col-sm-8 col-md-8 col-lg-8">
              <h4>{{ "sideBarMenu.setup.vaultManagement" | translate }}</h4>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4">
              <div class="add-button" (click)="openAddEditVaultModal(addEditVaultModal, false)">
                <i class="fa fa-plus"></i>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="dashboard-card loan-table">
                <div class="row mb-3">
                  <div class="col-sm-7 col-md-7 col-lg-8">
                    <ng-container *ngIf="_sharedSvc.pageSize >= 10">
                      <span>{{ "common.show" | translate }}</span>
                      <input type="number" class="m-1 text-center" style="width: 45px" />
                      <span>{{ "common.entries" | translate }}</span>
                    </ng-container>
                  </div>
                  <div class="col-sm-5 col-md-5 col-lg-4">
                    <div class="inside-icon">
                      <i class="fa fa-search"></i>
                      <input type="search" class="form-control search-input"
                        placeholder="{{ 'common.search' | translate }}" />
                    </div>
                  </div>
                </div>
                <div class="table-responsive">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <td>{{ "vaultMangement.serialNo" | translate }}</td>
                        <td>{{ "incomingBag.vaultID" | translate }}</td>
                        <td>{{ "common.location" | translate }}</td>
                        <td>{{ "vaultMangement.safes" | translate }}</td>
                        <td>
                          {{ "vaultMangement.totalCoverage" | translate }}
                        </td>
                        <td class="text-center">
                          {{ "common.action" | translate }}
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="
                          let item of _setupService.vaultList;
                          let i = index
                        ">
                        <td>{{ item.vaultSeq }}</td>
                        <td>{{ item.vaultId }}</td>
                        <td>{{ item.description }}</td>
                        <td>{{ item.saveCount }}</td>
                        <td>{{ convertToMillion(vaultTotalCoverages[i]) }}</td>
                        <td class="text-center">
                          <div class="action-inner">
                            <i class="fa-solid fa-vault cursor-pointer" (click)="
                                openSafesListModal(safeListModal, item.vaultSeq)
                              "></i>
                            <i class="fa-solid fa-pencil cursor-pointer" (click)="
                                editVault(
                                  addEditVaultModal,
                                  item.vaultSeq,
                                  true
                                )
                              "></i>
                            <i class="fa fa-trash cursor-pointer" (click)="deleteVault(item.vaultSeq)"></i>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="row g-0">
                    <div class="col-sm-12 col-md-12 col-lg-6">
                      <span class="clr-9d9a9a">{{ "common.showing" | translate }}
                        {{ "common.1To3Of" | translate }} 3
                        {{ "common.entries" | translate }}</span>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6">
                      <div class="float-right">
                        <nav aria-label="Page navigation example">
                          <ul class="pagination">
                            <li class="page-item">
                              <a class="page-link">{{
                                "common.first" | translate
                                }}</a>
                            </li>
                            <li class="page-item">
                              <a class="page-link">{{
                                "common.previous" | translate
                                }}</a>
                            </li>
                            <li class="page-item">
                              <a class="page-link">1</a>
                            </li>
                            <li class="page-item">
                              <a class="page-link">2</a>
                            </li>
                            <li class="page-item">
                              <a class="page-link">3</a>
                            </li>
                            <li class="page-item">
                              <a class="page-link">4</a>
                            </li>
                            <li class="page-item">
                              <a class="page-link">5</a>
                            </li>
                            <li class="page-item">
                              <a class="page-link">{{
                                "common.next" | translate
                                }}</a>
                            </li>
                            <li class="page-item">
                              <a class="page-link">{{
                                "common.last" | translate
                                }}</a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="button_div">
                      <button class="btn login-button-class" [class.pointerNone]="_sharedSvc.loading"
                        [disabled]="_sharedSvc.loading">
                        {{ "common.continue" | translate }}
                      </button>
                      <button class="btn login-button-class secondaryBtn">
                        {{ "common.save" | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>

<!-- AddEdit Vault Modal Begin -->
<ng-template id="display-modal" #addEditVaultModal let-c="close" let-d="dismiss">
  <div class="modal-body" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
    [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
    <div class="p-3 pb-0">
      <div class="row mb-3">
        <div class="col-sm-8 col-md-12 col-lg-12">
          <div class="mb-3">
            <h4 class="text-center">
              {{
              isEditVault === true
              ? this.translate.instant("common.edit")
              : this.translate.instant("common.add")
              }}
              Vault
            </h4>
          </div>
        </div>
      </div>
      <form [formGroup]="vaultFormdata" (ngSubmit)="submitVaultForm(vaultFormdata)">
        <div class="row mb-3">
          <div class="col-sm-12 col-md-12 col-lg-6">
            <div class="form-floating mb-3">
              <input type="text" style="text-transform: capitalize" autocomplete="off" formControlName="saveCount"
                class="form-control input-radius" [class.is-invalid]="
                  getVaultSafeCount.invalid &&
                  (getVaultSafeCount.dirty || getVaultSafeCount.touched)
                " />
              <div *ngIf="
                  getVaultSafeCount.invalid &&
                  (getVaultSafeCount.dirty || getVaultSafeCount.touched)
                " class="invalid-feedback">
                <div *ngIf="getVaultSafeCount.errors?.['required']">
                  {{ "vaultMangement.safeCountRequire" | translate }}
                </div>
              </div>
              <label>{{ "vaultMangement.safeCount" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-6">
            <div class="form-floating mb-3">
              <select class="form-control form-select input-radius" formControlName="locationKey" [class.is-invalid]="
                  getVaultLocation.invalid &&
                  (getVaultLocation.dirty || getVaultLocation.touched)
                ">
                <option *ngFor="let item of branchList" [value]="item?.branchSequence">
                  {{ item.branchName }}
                </option>
                <!-- <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option> -->
              </select>
              <div *ngIf="
                  getVaultLocation.invalid &&
                  (getVaultLocation.dirty || getVaultLocation.touched)
                " class="invalid-feedback">
                <div *ngIf="getVaultLocation.errors?.['required']">
                  {{ "vaultMangement.vaultLocationRequire" | translate }}
                </div>
              </div>
              <label>{{ "vaultMangement.vaultLocation" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="form-floating mb-3">
              <input type="text" style="text-transform: capitalize" autocomplete="off" formControlName="description"
                class="form-control input-radius" [class.is-invalid]="
                  getVaultDescription.invalid &&
                  (getVaultDescription.dirty || getVaultDescription.touched)
                " />
              <div *ngIf="
                  getVaultDescription.invalid &&
                  (getVaultDescription.dirty || getVaultDescription.touched)
                " class="invalid-feedback">
                <div *ngIf="getVaultDescription.errors?.['required']">
                  {{ "common.descriptionRequire" | translate }}
                </div>
              </div>
              <label>{{ "vaultMangement.vaultDescription" | translate }}</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="button_div">
              <button class="btn login-button-class" [class.pointerNone]="_sharedSvc.loading"
                [disabled]="_sharedSvc.loading">
                {{ "common.ok" | translate }}
              </button>
              <button class="btn login-button-class cancelBtn" (click)="d('Cross click')" type="reset">
                {{ "common.cancel" | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<!-- AddEdit Vault Modal End -->

<!-- SafeList Modal Begin -->
<ng-template id="display-modal" #safeListModal let-c="close" let-d="dismiss">
  <div class="modal-body" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
    [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
    <div class="p-3 pb-0">
      <div class="row mb-3">
        <div class="col-sm-8 col-md-8 col-lg-8">
          <h4>{{ "vaultMangement.safeList" | translate }}</h4>
        </div>
        <div class="col-sm-4 col-md-4 col-lg-4">
          <div *ngIf="safeCount < vaultSafeCount">
            <div class="add-button" (click)="openSafeAddEdit(addEditSafeModal, false)">
              <i class="fa fa-plus"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-12 px-0">
          <div class="modal-card p-0">
            <div class="table-responsive">
              <table class="table table-bordered modal-table mb-0">
                <thead>
                  <tr>
                    <td>{{ "vaultMangement.manufacturedBy" | translate }}</td>
                    <td>{{ "vaultMangement.insurancedBy" | translate }}</td>
                    <td>
                      {{ "vaultMangement.insuranceCoverage" | translate }}
                    </td>
                    <td>{{ "vaultMangement.vaultPosition" | translate }}</td>
                    <td>{{ "vaultMangement.specColor" | translate }}</td>
                    <td class="text-center">
                      {{ "common.action" | translate }}
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of _setupService.safeList">
                    <td>{{ item.crtdBy }}</td>
                    <td>{{ item.crtdBy }}</td>
                    <td>{{ convertToMillion(item.coverageAmount) }}</td>
                    <td>{{ getPosition(item.positionKey) }}</td>
                    <td>{{ getColor(item.specColor) }}</td>
                    <td class="text-center">
                      <div class="action-inner">
                        <i class="fa-solid fa-pencil" (click)="
                            editVaultSafe(addEditSafeModal, item.safeSeq, true)
                          "></i>
                        <i class="fa fa-trash" (click)="deleteSafe(item.safeSeq)"></i>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="button_div">
            <button class="btn login-button-class" [class.pointerNone]="_sharedSvc.loading"
              [disabled]="_sharedSvc.loading">
              {{ "common.ok" | translate }}
            </button>
            <button class="btn login-button-class cancelBtn" (click)="d('Cross click')" type="reset">
              {{ "common.cancel" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- SafeList Modal End -->

<!-- AddEdit SafeVault Modal Begin -->
<ng-template id="display-modal" #addEditSafeModal let-c="close" let-d="dismiss">
  <div class="modal-body">
    <div class="p-3 pb-0">
      <div class="row mb-3">
        <div class="col-sm-8 col-md-12 col-lg-12">
          <h4>
            {{
            isEditSafe === true
            ? this.translate.instant("common.edit")
            : this.translate.instant("common.add")
            }}
            {{ "incomingBag.safe" | translate }}
          </h4>
        </div>
      </div>
      <form [formGroup]="safeFormdata" (ngSubmit)="submitSafeVaultForm(safeFormdata)">
        <div class="row mb-3">
          <!-- <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                        <div class="form-floating">
                            <input type="text" style="text-transform: capitalize;" autocomplete="off"
                                formControlName="manufacturedBy" class="form-control input-radius"
                                [class.is-invalid]="getSafeMnufcrBy.invalid && (getSafeMnufcrBy.dirty || getSafeMnufcrBy.touched)">
                            <div *ngIf="getSafeMnufcrBy.invalid && (getSafeMnufcrBy.dirty || getSafeMnufcrBy.touched)"
                                class="invalid-feedback">
                                <div *ngIf="getSafeMnufcrBy.errors?.['required']">
                                    Manufactured By is required.
                                </div>

                            </div>
                            <label>Manufactured By</label>
                        </div>
                    </div> -->
          <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
            <div class="form-floating">
              <input type="text" style="text-transform: capitalize" autocomplete="off" formControlName="insrncBy"
                class="form-control input-radius" [class.is-invalid]="
                  getInsrncBy.invalid &&
                  (getInsrncBy.dirty || getInsrncBy.touched)
                " />
              <div *ngIf="
                  getInsrncBy.invalid &&
                  (getInsrncBy.dirty || getInsrncBy.touched)
                " class="invalid-feedback">
                <div *ngIf="getInsrncBy.errors?.['required']">
                  {{ "vaultMangement.insurancedByRequire" | translate }}
                </div>
              </div>
              <label>{{ "vaultMangement.insurancedBy" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
            <div class="form-floating">
              <input type="text" style="text-transform: capitalize" autocomplete="off" formControlName="coverageAmount"
                class="form-control input-radius" [class.is-invalid]="
                  getCoverageAmount.invalid &&
                  (getCoverageAmount.dirty || getCoverageAmount.touched)
                " />
              <div *ngIf="
                  getCoverageAmount.invalid &&
                  (getCoverageAmount.dirty || getCoverageAmount.touched)
                " class="invalid-feedback">
                <div *ngIf="getCoverageAmount.errors?.['required']">
                  {{ "vaultMangement.coverageAmmountRequire" | translate }}
                </div>
              </div>
              <label>{{ "vaultMangement.coverageAmmount" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
            <div class="form-floating">
              <select class="form-control form-select input-radius" formControlName="positionKey" [class.is-invalid]="
                  getVaultPosition.invalid &&
                  (getVaultPosition.dirty || getVaultPosition.touched)
                ">
                <option *ngFor="let item of _picklistService.VAULT_POSITION_Data" [value]="item?.refCdSeq">
                  {{ item.refCdDscr }}
                </option>
              </select>
              <div *ngIf="
                  getVaultPosition.invalid &&
                  (getVaultPosition.dirty || getVaultPosition.touched)
                " class="invalid-feedback">
                <div *ngIf="getVaultPosition.errors?.['required']">
                  {{ "vaultMangement.vaultPositionRequire" | translate }}
                </div>
              </div>
              <label>{{ "vaultMangement.vaultPosition" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
            <div class="form-floating">
              <select class="form-control form-select input-radius" formControlName="specColor" [class.is-invalid]="
                  getSpecColor.invalid &&
                  (getSpecColor.dirty || getSpecColor.touched)
                ">
                <option *ngFor="let item of _picklistService.VAULT_COLOR_Data" [value]="item?.refCdSeq">
                  {{ item.refCdDscr }}
                </option>
              </select>
              <div *ngIf="
                  getSpecColor.invalid &&
                  (getSpecColor.dirty || getSpecColor.touched)
                " class="invalid-feedback">
                <div *ngIf="getSpecColor.errors?.['required']">
                  {{ "vaultMangement.specColorRequire" | translate }}
                </div>
              </div>
              <label>{{ "vaultMangement.specColor" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
            <div class="form-floating">
              <input type="text" style="text-transform: capitalize" autocomplete="off" formControlName="description"
                class="form-control input-radius" [class.is-invalid]="
                  getDescription.invalid &&
                  (getDescription.dirty || getDescription.touched)
                " />
              <div *ngIf="
                  getDescription.invalid &&
                  (getDescription.dirty || getDescription.touched)
                " class="invalid-feedback">
                <div *ngIf="getDescription.errors?.['required']">
                  {{ "common.descriptionRequire" | translate }}
                </div>
              </div>
              <label>{{ "common.description" | translate }}</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="button_div">
              <button class="btn login-button-class" [class.pointerNone]="_sharedSvc.loading"
                [disabled]="_sharedSvc.loading">
                {{ "common.ok" | translate }}
              </button>
              <button class="btn login-button-class cancelBtn" (click)="d('Cross click')" type="reset">
                {{ "common.cancel" | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<!-- AddEdit SafeVault Modal End -->