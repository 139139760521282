import { Injectable } from '@angular/core';
import { GetCityListOfSetup, GetCityUcListOfSetup, GetRiskTagListofSetup, GetTypesOfSetup, SetupBiz, SetupBizActivity, SetupCity, SetupCityUc, SetupGroup, SetupReferance, SetupRiskTag, SetupShrof, SetupTypes, saveAreaModel, saveQuestionModel, saveBranchAccountModel, saveBranchLocationModel, saveBranchModel, saveBranchProductModel, saveCommunityModel, saveGeoCountryModel, saveGeoDistModel, saveGeoStateModel, saveGeoUCModel, saveGeotehsilModel, savePortfolioModel, saveRegionEMPModel, saveRegionModel, saveQuestionnaireModel, saveAreaEMPModel, saveBranchEMPModel, savePortEMPModel, saveBagModel, saveAnswerModel } from '../model/setup';
import { HttpClient } from '@angular/common/http';
import { SharedService } from 'src/app/components/shared/service/shared.service';
import { environment } from 'src/app/global';
import { Endpoint } from 'src/app/components/shared/serviceEndPoints';
import { SortbyPipePipe } from 'src/app/components/shared/service/sortby-pipe.pipe';

@Injectable({
  providedIn: 'root'
})
export class SetupService {

  setupTypesModel = new SetupTypes();
  setupReferanceModel = new SetupReferance();
  setupGroupModel = new SetupGroup();
  setupBizModel = new SetupBiz();
  setupBizActivityModel = new SetupBizActivity();
  setupRiskTagModel = new SetupRiskTag();
  setupCityModel = new SetupCity();
  setupCityUcModel = new SetupCityUc();
  setupShrofModel = new SetupShrof();
  setupRegionModel = new saveRegionModel();
  setupAreaModel = new saveAreaModel();
  setupBranchModel = new saveBranchModel();
  setupBranchAccountModel = new saveBranchAccountModel();
  setupBranchProductModel = new saveBranchProductModel();
  setupBranchLocationModel = new saveBranchLocationModel();
  setupPortfolioModel = new savePortfolioModel();
  setupCommunityModel = new saveCommunityModel();
  getTypesOfSetupModel = new GetTypesOfSetup();
  getRiskTagListofSetupModel = new GetRiskTagListofSetup();
  getCityUcListOfSetupModel = new GetCityUcListOfSetup();
  getCityListOfSetupModel = new GetCityListOfSetup();
  setupGeoCountryModel = new saveGeoCountryModel();
  setupGeoStateModel = new saveGeoStateModel();
  setupGeoDistModel = new saveGeoDistModel();
  setupGeoTehsilModel = new saveGeotehsilModel();
  setupGeoUCModel = new saveGeoUCModel();
  setupQuestionnaireModel = new saveQuestionnaireModel();
  setupsaveQuestionModel = new saveQuestionModel();
  setupAnswerModel = new saveAnswerModel();
  setupRegionEMPModel = new saveRegionEMPModel();
  setupAreaEMPModel = new saveAreaEMPModel();
  setupQuestionnaire = new saveQuestionnaireModel();
  setupBranchEMPModel = new saveBranchEMPModel();
  setupPortEMPModel = new savePortEMPModel();
  setupBranchBagModel = new saveBagModel();

  branchProductsList: any = []
  branchLocationsList: any = []
  portLocationsList: any = []

  typesValue: any
  typesValueList: any
  typesBranchList: any
  typesAccList: any
  typesValueTablePG: any
  riskList: any
  cityUslist: any
  shoroffList: any
  vaultList: any
  safeList: any
  allRegionList: any
  allAreasList: any
  regionEmpList: any
  areaEmpList: any
  allBranchList: any
  questionnaireList: any
  questionsList: any
  answerList: any
  branchProductList: any
  branchLocationList: any
  allbranchPortList: any
  businessSectList: any
  businessSectTable: any
  getbusinessSectPG: any;
  businessActyList: any;
  businessActyTable: any
  getbusinessActyPG: any;

  page: number = 1;
  pageSize: number = 10;
  collectionSize: number = 0;
  collectionSizeActy: number = 0
  portLocationList: any
  portCommunityList: any
  businessSectTablePG: any
  businessActyTablePG: any
  refCdGrpList: any;
  refCdGrpTablePG: any;
  refCdValList: any;
  refCdValTablePG: any;
  riskTagTablePG: any;
  collectionSizeRiskTag: number = 0;
  collectionSizeSetupType: number = 0;
  collectionSizeBizSect: number = 0;
  collectionSizeBizActy: number = 0
  collectionSizeCdGrp: number = 0;
  collectionSizeCdVal: number = 0;

  //geo vars
  geoCountryList: any = []
  geoStateList: any = []
  geoDistList: any = []
  geotehseelList: any = []
  geoUCList: any = []
  allEMPLIST: any = []
  SelectedAreaEMP: any = []
  branchBagsList: any;


  constructor(
    private _http: HttpClient,
    public _sharedSvc: SharedService,
    private sortPipe: SortbyPipePipe
  ) { }








  // APIS Setup 
  saveTyps() {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.save_types, this.getTypesOfSetupModel, this._sharedSvc.requestOptions);
  }
  saveRefCdValue() {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.save_ref_cd_val, this.setupReferanceModel, this._sharedSvc.requestOptions)
  }

  saveRefCdGroup() {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.save_ref_cd_grap, this.setupGroupModel, this._sharedSvc.requestOptions)
  }

  saveBizSec() {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.save_biz_sect, this.setupBizModel, this._sharedSvc.requestOptions)
  }

  saveBizActivity() {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.save_biz_acty, this.setupBizActivityModel, this._sharedSvc.requestOptions)
  }

  saveRiskTag() {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.save_risk_tag, this.setupRiskTagModel, this._sharedSvc.requestOptions)
  }
  saveCity() {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.save_city, this.setupCityModel, this._sharedSvc.requestOptions)
  }
  saveCityUc() {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.save_city_uc, this.getCityUcListOfSetupModel, this._sharedSvc.requestOptions)
  }
  saveShrof(data: any) {
    
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.save_shrof, data, this._sharedSvc.requestOptions)
  }
  saveVault(data: any) {
    
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.save_vault, data, this._sharedSvc.requestOptions)
  }
  saveSafe(data: any) {
    
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.save_safe, data, this._sharedSvc.requestOptions)
  }

  saveRegion() {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.save_region, this.setupRegionModel, this._sharedSvc.requestOptions)
  }
  saveArea() {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.save_area, this.setupAreaModel, this._sharedSvc.requestOptions)
  }

  saveBranch() {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.save_branch, this.setupBranchModel, this._sharedSvc.requestOptions)
  }
  saveBranchAccount() {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.save_branch_acct, this.setupBranchAccountModel, this._sharedSvc.requestOptions)
  }
  saveBranchProducts() {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.save_branch_prod, this.branchProductsList, this._sharedSvc.requestOptions)
  }
  saveBranchLocations() {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.save_branch_lctn, this.branchLocationsList, this._sharedSvc.requestOptions)
  }
  savePortLocations() {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.save_port_lctn, this.portLocationsList, this._sharedSvc.requestOptions)
  }

  savePortfolio() {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.save_portfolio, this.setupPortfolioModel, this._sharedSvc.requestOptions)
  }
  saveCommunity() {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.save_port_cmnty, this.setupCommunityModel, this._sharedSvc.requestOptions)
  }

  getTypes(type_catgry_key: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.get_types + type_catgry_key, this._sharedSvc.requestOptions)
  }
  getAllBranchList() {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.get_branch_list2, this._sharedSvc.requestOptions)
  }
  getAccList() {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.get_acc_typ_list, this._sharedSvc.requestOptions)
  }
 
  getRiskTagList() {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.get_risk_tag_list, this._sharedSvc.requestOptions)
  }
  getCityUcList() {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.save_city_uc, this._sharedSvc.requestOptions)
  }
  getCityList() {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.get_city_list, this._sharedSvc.requestOptions)
  }

  getShrofList() {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.get_shroff_list, this._sharedSvc.requestOptions)
  }

  getVaultList() {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.get_vault_list, this._sharedSvc.requestOptions)
  }
  getSafeList(vault_safe_key: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.get_safe_list + vault_safe_key, this._sharedSvc.requestOptions)
  }
  getPortList(brnchseq: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.get_port_list + brnchseq, this._sharedSvc.requestOptions)
  }
  // Questionnaire API Begins
  getQuestionnaireList() {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.get_questionnaire_list, this._sharedSvc.requestOptions)
  }
  saveQuestionnaire() {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.save_questionnaire, this.setupQuestionnaireModel, this._sharedSvc.requestOptions)
  }
  getQuestionList(qstnr_key: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.get_question_list + qstnr_key, this._sharedSvc.requestOptions)
  }
  saveQuestion() {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.save_question, this.setupsaveQuestionModel, this._sharedSvc.requestOptions)
  }
  getAnswerList(quest_key: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.get_answer_list + quest_key, this._sharedSvc.requestOptions)
  }
  saveAnswer() {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.save_answer, this.setupAnswerModel, this._sharedSvc.requestOptions)
  }
  // Questionnaire API End

  getRegionList() {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.get_region_list, this._sharedSvc.requestOptions);
  }
  getAreaList(regionID: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.get_area_list + regionID, this._sharedSvc.requestOptions);
  }
  getRegionEmpList(reg_key: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.get_region_empl_list + reg_key, this._sharedSvc.requestOptions);
  }
  getAreaEmpList(area_key: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.get_area_empl_list + area_key, this._sharedSvc.requestOptions);
  }

  getBranchList(areaSeq: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.get_branch_list + areaSeq, this._sharedSvc.requestOptions)

  }
  getBranchProductList(brandSeq: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.get_branch_prd_list + brandSeq, this._sharedSvc.requestOptions)

  }
  getBranchlocationList(brandSeq: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.get_branch_lctn_list + brandSeq, this._sharedSvc.requestOptions)

  }
  getBusinessSectorList() {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.get_biz_sect_list, this._sharedSvc.requestOptions)

  }
  getBusinessActivityList(sect_seq: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.get_biz_acty_list + sect_seq, this._sharedSvc.requestOptions)

  }
  getPortlocationList(portSeq: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.get_port_lctn_list + portSeq, this._sharedSvc.requestOptions)

  }
  getPortcmntyList(portSeq: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.get_port_cmnty_list + portSeq, this._sharedSvc.requestOptions)
  }
  getCdGrpList() {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.get_ref_grps, this._sharedSvc.requestOptions)

  }
  getCdValList(grp_seq: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.get_ref_grp_vals + grp_seq, this._sharedSvc.requestOptions)
  }
  getCoveringEmpListofBranch(grp_seq: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.get_branch_covering_emp + grp_seq, this._sharedSvc.requestOptions)
  }
  getCoveringEmpListofPort(port_seq: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.get_port_emp_list + port_seq, this._sharedSvc.requestOptions)
  }


  //GEO APIS

  getGeoCountryList() {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.get_geo_country_list, this._sharedSvc.requestOptions)
  }
  getGeoStateList(cntrySeq: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.get_geo_state_list + cntrySeq, this._sharedSvc.requestOptions)
  }
  getGeoDistList(stateSeq: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.get_geo_dist_list + stateSeq, this._sharedSvc.requestOptions)
  }
  getGeotehseelList(distSeq: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.get_geo_thsl_list + distSeq, this._sharedSvc.requestOptions)
  }
  getGeoUCList(thslSeq: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.get_geo_uc_list + thslSeq, this._sharedSvc.requestOptions)
  }
  getBranchBagList(branchSeq: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.get_branch_bag_list + branchSeq, this._sharedSvc.requestOptions)
  }

  saveGeoCountry() {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.save_geo_country, this.setupGeoCountryModel, this._sharedSvc.requestOptions)
  }
  saveGeoState() {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.save_geo_state, this.setupGeoStateModel, this._sharedSvc.requestOptions)
  }
  saveGeoDist() {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.save_geo_district, this.setupGeoDistModel, this._sharedSvc.requestOptions)
  }
  saveGeotehsil() {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.save_geo_tehsil, this.setupGeoTehsilModel, this._sharedSvc.requestOptions)
  }
  saveGeoUC() {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.save_geo_uc, this.setupGeoUCModel, this._sharedSvc.requestOptions)
  }
  saveRegionEMP() {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.save_region_emp, this.setupRegionEMPModel, this._sharedSvc.requestOptions)
  }
  saveAreaEMP() {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.save_area_emp, this.setupAreaEMPModel, this._sharedSvc.requestOptions)
  }
  getALlEmp() {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.get_all_emp_list, this._sharedSvc.requestOptions)

  }

  saveBranchEMP() {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.save_branch_emp, this.setupBranchEMPModel, this._sharedSvc.requestOptions)
  }
  savePortEMP() {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.save_port_emp, this.setupPortEMPModel, this._sharedSvc.requestOptions)
  }

  saveBranchBag() {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.save_branch_bag, this.setupBranchBagModel, this._sharedSvc.requestOptions)
  }





  // APIS end

  get_types_Key(key) {
    this._sharedSvc.showSpinner()
    this.getTypes(key).subscribe(data => {
      this._sharedSvc.hideSpinner()
      this.typesValueList = data;
      if (this.typesValueList.length) {
        this.pageSetupTypes();
      }
    })
  }
  get_all_branch_list() {
    this._sharedSvc.showSpinner()
    this.getAllBranchList().subscribe(data => {
      this._sharedSvc.hideSpinner()
      this.typesBranchList = data;
    })
  }
  get_acc_list() {
    this._sharedSvc.showSpinner()
    this.getAccList().subscribe(data=> {
      this._sharedSvc.hideSpinner()
      this.typesAccList = data;
    })
  }

  getRisk_Tag_List() {
    this._sharedSvc.showSpinner()
    this.getRiskTagList().subscribe(data => {
      this._sharedSvc.hideSpinner()
      this.riskList = this.sortPipe.transform(data, 'tagNm');
      if (this.riskList.length) {
        this.pageChangeRiskTag();
      }
    })
  }

  get_City_us_list() {
    this._sharedSvc.showSpinner()
    this.getCityUcList().subscribe(data => {
      this.cityUslist = data;
    })
  }

  get_City_List() {
    this._sharedSvc.showSpinner()
    this.getCityUcList().subscribe(response => {
      if (response) {

      }
      this.cityUslist = response;
    })
  }

  get_shrof_list() {
    this._sharedSvc.showSpinner()
    this.getShrofList().subscribe(response => {
      this._sharedSvc.hideSpinner()
      this.shoroffList = response;
      localStorage.setItem('shoroffList', JSON.stringify(this.shoroffList));
      console.log(response)
    })

  }

  // get_vault_list() {
  //   this._sharedSvc.showSpinner()
  //   this.getVaultList().subscribe(response => {
  //     this._sharedSvc.hideSpinner()
  //     this.vaultList = response;

  //   })

  // }
  async get_vault_list(): Promise<any> {
    try {
      this._sharedSvc.showSpinner();
      const data = await this.getVaultList().toPromise();
      this.vaultList = data;
      this._sharedSvc.hideSpinner();
    } catch (error) {
      console.error('Error fetching vault list:', error);
      throw error;
    }
  }


  // get_safe_list(key: any) {
  //   this._sharedSvc.showSpinner()
  //   this.getSafeList(key).subscribe(data => {
  //     this._sharedSvc.hideSpinner()
  //     this.safeList = data;

  //   })
  // }
  async get_safe_list(key: any): Promise<any> {
    try {
      this._sharedSvc.showSpinner();

      const data = await this.getSafeList(key).toPromise();

      this._sharedSvc.hideSpinner();
      this.safeList = data;

      return data;
    } catch (error) {
      this._sharedSvc.hideSpinner();
      console.error('Error fetching safe list:', error);
      throw error;
    }
  }



  // Questionnaire
  get_qstnr_list() {
    this._sharedSvc.showSpinner()
    this.getQuestionnaireList().subscribe(response => {
      this._sharedSvc.hideSpinner()
      this.questionnaireList = response;
    })
  }
  get_question_list(key: any) {
    this._sharedSvc.showSpinner()
    this.getQuestionList(key).subscribe(response => {
      this._sharedSvc.hideSpinner()
      this.questionsList = response;
    })
  }
  get_Answer_list(key: any) {
    this._sharedSvc.showSpinner()
    this.getAnswerList(key).subscribe(response => {
      this._sharedSvc.hideSpinner()
      this.answerList = response;
    })
  }

  //Bussines Sector
  get_biz_sect_list() {
    this._sharedSvc.showSpinner()
    this.getBusinessSectorList().subscribe(res => {
      this._sharedSvc.hideSpinner();
      this.businessSectList = this.sortPipe.transform(res, 'bizSectNm');;
      this.pageChangeSect();
    })
  }
  get_biz_acty_list(key) {
    this._sharedSvc.showSpinner()
    this.getBusinessActivityList(key).subscribe(res => {
      this._sharedSvc.hideSpinner();
      this.businessActyList = this.sortPipe.transform(res, 'bizActyNm');
      if (this.businessActyList.length) {
        this.pageChangeActy();
      }
    })
  }

  //Organizations 
  get_region_list() {
    this._sharedSvc.showSpinner()
    this.getRegionList().subscribe(res => {
      this._sharedSvc.hideSpinner();
      this.allRegionList = res;
    })
  }



  get_areas_list(regionID) {
    this._sharedSvc.showSpinner()
    this.allAreasList = [];

    this.getAreaList(regionID).subscribe(res => {
      this._sharedSvc.hideSpinner();
      this.allAreasList = res;
    })
  }
  get_area_emp_list(key: any) {
    this._sharedSvc.showSpinner()
    this.getAreaEmpList(key).subscribe(res => {
      this._sharedSvc.hideSpinner();
      this.areaEmpList = res;
    })
  }
  get_branch_list(key: any) {
    this._sharedSvc.showSpinner()
    this.getBranchList(key).subscribe(res => {
      this._sharedSvc.hideSpinner();
      this.allBranchList = res;
    })
  }
  get_branch_product_list(key: any) {
    this._sharedSvc.showSpinner()
    this.getBranchProductList(key).subscribe(res => {
      this._sharedSvc.hideSpinner();
      //@ts-ignore
      if (res?.error) {
        this.branchProductList = [];

      } else {
        this.branchProductList = res;

      }
      console.log(res)
    })
  }
  get_branch_location_list(key: any) {
    this._sharedSvc.showSpinner()
    this.getBranchlocationList(key).subscribe(res => {
      this._sharedSvc.hideSpinner();
      //@ts-ignore
      if (res?.error) {
        this.branchLocationList = [];

      } else {
        this.branchLocationList = res;

      }
      console.log(res)
    })
  }
  get_port_list(key: any) {
    this._sharedSvc.showSpinner()
    this.getPortList(key).subscribe(res => {
      this._sharedSvc.hideSpinner();
      this.allbranchPortList = res;
      console.log(res)
    })
  }
  get_port_location_list(key: any) {
    this._sharedSvc.showSpinner()
    this.getPortlocationList(key).subscribe(res => {
      this._sharedSvc.hideSpinner();
      //@ts-ignore
      if (res?.error) {
        this.portLocationList = [];

      } else {
        this.portLocationList = res;

      }
      console.log(res)
    })
  }
  get_port_community_list(key: any) {
    this._sharedSvc.showSpinner()
    this.getPortcmntyList(key).subscribe(res => {
      this._sharedSvc.hideSpinner();
      this.portCommunityList = res;
      console.log(res)
    })
  }




  // Common Codes
  get_ref_cd_grp_list() {
    this._sharedSvc.showSpinner()
    this.getCdGrpList().subscribe(res => {
      this._sharedSvc.hideSpinner();
      this.refCdGrpList = this.sortPipe.transform(res, 'grpName');
      if (this.refCdGrpList.length) {
        this.pageChangeCdGrp();
      }
    })
  }
  get_ref_cd_val_list(grpSeq: any) {
    this._sharedSvc.showSpinner()
    this.getCdValList(grpSeq).subscribe(res => {
      this._sharedSvc.hideSpinner();
      this.refCdValList = this.sortPipe.transform(res, 'refCdDscr');
      if (this.refCdValList.length) {
        this.pageChangeCdVal();
      }
    })
  }


  get_geo_country_list() {
    this._sharedSvc.showSpinner()
    this.getGeoCountryList().subscribe(res => {
      //@ts-ignore
      if (res.error) {
        this.geoCountryList = [];
        this._sharedSvc.hideSpinner();

        return;
      }
      this.geoCountryList = res;
      console.log(res)
      this._sharedSvc.hideSpinner();

    })
  }
  get_geo_state_list(key: any) {
    this._sharedSvc.showSpinner()
    this.getGeoStateList(key).subscribe(res => {
      //@ts-ignore
      if (res.error) {
        this.geoStateList = []
        this._sharedSvc.hideSpinner();

        return;
      }
      this.geoStateList = res;
      console.log(res)
      this._sharedSvc.hideSpinner();

    })
  }
  get_geo_dist_list(key: any) {
    this._sharedSvc.showSpinner()
    this.getGeoDistList(key).subscribe(res => {
      //@ts-ignore
      if (res.error) {
        this.geoDistList = []
        this._sharedSvc.hideSpinner();

        return;
      }
      this.geoDistList = res;
      console.log(res)
      this._sharedSvc.hideSpinner();

    })
  }
  get_geo_tehseel_list(key: any) {
    this._sharedSvc.showSpinner()
    this.getGeotehseelList(key).subscribe(res => {
      //@ts-ignore
      if (res.error) {
        this.geotehseelList = []
        this._sharedSvc.hideSpinner();

        return;
      }
      this.geotehseelList = res;
      console.log(res)
      this._sharedSvc.hideSpinner();

    })
  }
  get_geo_UC_list(key: any) {
    this._sharedSvc.showSpinner()
    this.getGeoUCList(key).subscribe(res => {
      //@ts-ignore
      if (res.error) {
        this.geoUCList = [];
        this._sharedSvc.hideSpinner();

        return;
      }
      this.geoUCList = res;
      console.log(res)
      this._sharedSvc.hideSpinner();

    })
  }
  get_all_emp_list() {
    this._sharedSvc.showSpinner()
    this.getALlEmp().subscribe(res => {
      this._sharedSvc.hideSpinner();
      this.allEMPLIST = res;
      console.log(res)
    })
  }








  //Risk Tag
  // get_risk_tag_list(){
  //   this._sharedSvc.showSpinner()
  //    this.getRiskTagList().subscribe(res => {
  //      this._sharedSvc.hideSpinner();
  //      this.riskTagList = this.sortPipe.transform(res,'tagNm') ;
  //      if(this.riskTagList.length){      
  //        this.pageChangeRiskTag();
  //      }
  //    })
  //  }

  //========================pagination====================
  //Business Sector
  pageChangeSect() {
    this.collectionSizeBizSect = this.businessSectList?.length;
    this.businessSectTablePG = this.businessSectList?.slice(
      (this._sharedSvc.page - 1) * this._sharedSvc.pageSize,
      (this._sharedSvc.page - 1) * this._sharedSvc.pageSize + this._sharedSvc.pageSize
    );
    this.businessSectTablePG;
  }
  pageChangeActy() {
    this.collectionSizeBizActy = this.businessActyList?.length;
    this.businessActyTablePG = this.businessActyList?.slice(
      (this._sharedSvc.page - 1) * this._sharedSvc.pageSize,
      (this._sharedSvc.page - 1) * this._sharedSvc.pageSize + this._sharedSvc.pageSize
    );
    this.businessActyTablePG;
  }

  // Common Codes
  pageChangeCdGrp() {
    this.collectionSizeCdGrp = this.refCdGrpList?.length;
    this.refCdGrpTablePG = this.refCdGrpList?.slice(
      (this._sharedSvc.page - 1) * this._sharedSvc.pageSize,
      (this._sharedSvc.page - 1) * this._sharedSvc.pageSize + this._sharedSvc.pageSize
    );
    this.refCdGrpTablePG;
  }
  pageChangeCdVal() {
    this.collectionSizeCdVal = this.refCdValList?.length;
    this.refCdValTablePG = this.refCdValList?.slice(
      (this._sharedSvc.page - 1) * this._sharedSvc.pageSize,
      (this._sharedSvc.page - 1) * this._sharedSvc.pageSize + this._sharedSvc.pageSize
    );
    this.refCdValTablePG;
  }

//Risk Tag
pageChangeRiskTag(){
  this.collectionSizeRiskTag = this.riskList?.length;
  this.riskTagTablePG = this.riskList?.slice(
    (this._sharedSvc.page - 1) * this._sharedSvc.pageSize,
    (this._sharedSvc.page - 1) * this._sharedSvc.pageSize +this._sharedSvc.pageSize
  );
  this.riskTagTablePG;
}
pageSetupTypes(){
  this.collectionSizeSetupType = this.typesValueList?.length;
  this.typesValueTablePG = this.typesValueList?.slice(
    (this._sharedSvc.page - 1) * this._sharedSvc.pageSize,
    (this._sharedSvc.page - 1) * this._sharedSvc.pageSize +this._sharedSvc.pageSize
  );
  this.typesValueTablePG;
}
getBranchName(brnchSeq: number): string {
  const branch = this.typesBranchList.find(branch => branch.branchSequence === brnchSeq);
  return branch ? branch.branchName : '';
}

get_branch_bag_list(key: any) {
  this._sharedSvc.showSpinner()
  this.getBranchBagList(key).subscribe(res => {
    this._sharedSvc.hideSpinner();
    //@ts-ignore
    if(res.error){
        return;
      }
    console.log(res)
    this.branchBagsList = res;

  })
}
}


