import { Component, OnInit, DestroyRef, inject } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'src/app/components/shared/service/shared.service';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { CreditCollateralService } from './shared/service/credit-collateral.service';
import { SharedPopupService } from 'src/app/components/shared/service/shared-popup.service';
import { PicklistService } from 'src/app/components/shared/pickList/picklist.service';
import { DatePipe } from "@angular/common";
import {
  DataUrl,
  DOC_ORIENTATION,
  NgxImageCompressService,
  UploadResponse,
} from 'ngx-image-compress';
import { ClientDocsService } from 'src/app/components/clint-onboarding/client-docs/shared/service/client-docs.service';
import { RoutingConditionsService } from 'src/app/components/clint-onboarding/shared/service/routing-conditions.service';
import { SetupService } from 'src/app/components/setup/shared/service/setup.service';
import { RouteInfo } from 'src/app/auth/model/route-info';
import { TranslateService } from '@ngx-translate/core';
interface ICollateralHDR {
  // crdtCltrlHdrSeq: number;
  crdtAppSeq: number;
  crdtUpto: number;
  effctvCrdtAmnt: number;
  shrffId: number;
  shrffBgNum: number;
  brnchBgNum: number;
  crtdDt: string;
  lastUpdBy: string;
  lastUpdDt: string;
  delFlg: boolean;
  // crdtUpto: number;
  
}

interface ICollateral {
  crdtCltrlDtlSeq: number;
  // crdtCltrlHdrSeq: number;
  crdtAppSeq:number;
  dscr: number;
  grossWghtIncl: number;
  grossWghtExcl: number;
  gldQltyKrt: number;
  mrktByngRt: number;
  crtdBy: string;
  crtdDt: string;
  lastUpdBy: string;
  lastUpdDt: string;
  delFlg: boolean;
}

@Component({
  selector: 'app-client-credit-collateral-detail',
  templateUrl: './client-credit-collateral-detail.component.html',
  styleUrls: ['./client-credit-collateral-detail.component.css']
})
export class ClientCreditCollateralDetailComponent implements OnInit {
  allowedRoutes: RouteInfo[];

  CollateralForm: FormGroup;
  Collateral: ICollateral;
  CollateralHDRForm: FormGroup;
  CollateralHDR: ICollateralHDR;
  saveCnicData: any;
  modelHeading: any;
  shroffName: any = null;
  crdtApp: any;
  crdtCltrlHdrSeq: any = 0;
  netWeight: any;
  pureGold: any;
  imgResultBeforeCompress: any;
  imgResultAfterCompress: any;
  src: any;
  imgSizeAfter: any;
  imgSizeBefore: any;
  recmAmount: any;
  imgPath: boolean = false;
  shoImg: boolean = false;
  openPopup: boolean = false;
  uploadMsg: any;
  goldPicUrl: any;
  shroffPicUrl: any;
  modal1: any;
  modal2: any;
  jwt: any
  index: number = 0
  #destroyRef = inject(DestroyRef);
  shareUserData: any

  constructor(
    public router: Router,
    public _sharedSvc: SharedService,
    public _creditCollateralSvc: CreditCollateralService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    public _sharedPopupSvc: SharedPopupService,
    public _picklistService: PicklistService,
    public route: ActivatedRoute,
    private imageCompress: NgxImageCompressService,
    public _clientDocsSvc: ClientDocsService,
    public _conditionsService: RoutingConditionsService,
    public _setupService: SetupService,
    public translate: TranslateService

  ) {
    this.Collateral = {} as ICollateral;
    this.CollateralHDR = {} as ICollateralHDR;
    this.allowedRoutes = JSON.parse(sessionStorage.getItem("routeInfo"));
    this.#destroyRef.onDestroy(() => {
      sessionStorage.setItem("CLTD", JSON.stringify(this.CollateralHDRForm.value))
      document.body.classList.remove("printFormofColletral")
    });
  }

  ngOnInit(): void {
    document.body.classList.add("printFormofColletral")
    this.validationForm();
    this.saveCnicData = localStorage.getItem('SaveCnicData');
    this.saveCnicData = JSON.parse(this.saveCnicData);

    this.shareUserData = sessionStorage.getItem('shareUserData');
    this.shareUserData = JSON.parse(this.shareUserData);
    this.setD_V2();
    this._picklistService.callApisCOLLATERAL();
    this.crdtApp = localStorage.getItem("crdtApp");
    this.crdtApp = JSON.parse(this.crdtApp);
    this.crdtCltrlHdrSeq = localStorage.getItem("crdtCltrlHdrSeq");
    this.crdtCltrlHdrSeq = JSON.parse(this.crdtCltrlHdrSeq);

    this._setupService.get_shrof_list();
    this._creditCollateralSvc.get_GoldRate_list();
    let branchSeq = localStorage.getItem("BranchSeqIDByPort");
    branchSeq = JSON.parse(branchSeq)?.brnchSeq;
    this._creditCollateralSvc.getAllGoldBags(branchSeq, 2503);
    this._creditCollateralSvc.getAllShroffBags(branchSeq, 2504);

    this.setValueToCollateralForm();

    if (this.crdtCltrlHdrSeq) {
      this.openPopup = true;
      this._creditCollateralSvc.InItGetAllCollateralDTL(this.crdtCltrlHdrSeq);
    }
    if (this.crdtApp) {
      //this._creditCollateralSvc.InItGetCollateralHDR(this.crdtApp);
      this.InItGetCollateralHDRLocal(this.crdtApp);
      this.InItGetAllClientDocs(this.saveCnicData?.clntSeq)
    }

    this.CollateralHDRForm.get('shrffId')?.valueChanges.subscribe(() => {
      
      this._creditCollateralSvc.saveCollateralHDRModel.shrffId = Number(this.shrffId.value);
      this.setShroffName();
    });
  }

  setShroffName() {
    
    // for (let i = 0; i < this._setupService.shoroffList?.length; i++) {
    //   if (this._creditCollateralSvc.saveCollateralHDRModel.shrffId == this._setupService.shoroffList[i].shrfSeq) {
    //     this.shroffName = this._setupService.shoroffList[i].firstName + " " + this._setupService.shoroffList[i].lastName
    //   }

    // }
    if (localStorage.getItem('shoroffList')) {
      let shoroffList = JSON.parse(localStorage.getItem('shoroffList'));
      for (let i = 0; i < shoroffList.length; i++) {
        if (this._creditCollateralSvc.saveCollateralHDRModel.shrffId == shoroffList[i].shrfSeq) {
          this.shroffName = shoroffList[i].firstName + " " + shoroffList[i].lastName
        }

      }
    }
  }

  CalNetWeight() {
    
    this._creditCollateralSvc.collateralModel.grossWghtExcl = this.CollateralForm.value.grossWghtExcl;
    this._creditCollateralSvc.collateralModel.gldQltyKrt = this.CollateralForm.value.gldQltyKrt;
    if (this._creditCollateralSvc.collateralModel.grossWghtExcl && this._creditCollateralSvc.collateralModel.gldQltyKrt) {
      this.netWeight = (this._creditCollateralSvc.collateralModel.grossWghtExcl * this._creditCollateralSvc.collateralModel.gldQltyKrt) / 24;
    }
    this.pureGoldFn();
  }

  pureGoldFn(event?: any) {
    
    if (event && event.target && event.target.value) {
      let selectedGoldRateSeq = event.target.value;
      this._creditCollateralSvc.collateralModel.mrktByngRt = this.getCurrentGoldRate(selectedGoldRateSeq);
    }
    if (this.netWeight && this._creditCollateralSvc.collateralModel.mrktByngRt) {
      this.pureGold = this.netWeight * this._creditCollateralSvc.collateralModel.mrktByngRt;
    }
  }
  printPage() {
    window.print();
  }

  openModal(content: any, h4: any, newEntry?: any) {
    this.shoImg = true;
    // if(newEntry){
    //   if (localStorage.getItem("crdtCltrlHdrSeq")) {
    //   }
    //   else {
    //     this._sharedSvc.error('Please save header From First');
    //     return;
    //   }
    // }
    this.modelHeading = h4;
    this._creditCollateralSvc.edit = false;
    this._sharedSvc.msg = "Record added";
    this.CollateralForm.reset(this.CollateralForm.value);
    this.CollateralHDRForm.reset(this.CollateralHDRForm.value);
    this.netWeight = this.pureGold = null;
    this.setD_V();
    this.modalService.open(content, {
      // backdrop: "static",
      // keyboard: false,
      windowClass: "popupWidth1k",
      backdropClass: 'light-blue-backdrop',
      centered: true
    });
  }

  setD_V() {
    if (localStorage.getItem("crdtCltrlHdrSeq")) {
      this.crdtCltrlHdrSeq = localStorage.getItem("crdtCltrlHdrSeq");
      this.crdtCltrlHdrSeq = JSON.parse(this.crdtCltrlHdrSeq);
    }
    else {
      this.crdtCltrlHdrSeq = 0;
    }
    this.jwt = sessionStorage.getItem('JWT');
    this.jwt = JSON.parse(this.jwt);
    let date = new Date();
    this._creditCollateralSvc.collateralModel = {
      crdtCltrlDtlSeq: null,
      // crdtCltrlHdrSeq: this.crdtCltrlHdrSeq,
      crdtAppSeq: this.crdtCltrlHdrSeq,
      dscr: null,
      grossWghtIncl: null,
      grossWghtExcl: null,
      gldQltyKrt: null,
      mrktByngRt: null,
      crtdBy: this.jwt?.preferred_username,
      crtdDt: date,
      lastUpdBy: this.jwt?.preferred_username,
      lastUpdDt: date,
      delFlg: false
    }

  }

  setD_V2() {
    this._creditCollateralSvc.saveCollateralHDRModel = {
      // crdtCltrlHdrSeq: 0,
      crdtAppSeq: this.crdtApp,
      crdtUpto: 0,
      effctvCrdtAmnt: null,
      //effctvCrdtAmnt: localStorage.getItem('effctvCrdtAmnt') ? JSON.parse(localStorage.getItem('effctvCrdtAmnt')) : 0,
      shrffId: 0,
      shrffBgNum: null,
      brnchBgNum: null,
      crtdDt: '',
      lastUpdBy: '',
      lastUpdDt: '',
      delFlg: false,
      //crdtUpto: localStorage.getItem('crdtUpto') ? JSON.parse(localStorage.getItem('crdtUpto')) : 0
    }
  }

  setD_V3() {
    this._clientDocsSvc.saveDocsModel = {
      clntDocSeq: 0,
      clntSeq: this.saveCnicData?.clntSeq,
      entySeq: this.crdtApp,
      entyFlag: 4,
      docSeq: 0,
      docImg: "",
      delFlg: false,
    };
  }

  dismissModal() {
    this.CollateralForm.reset(this.CollateralForm.value);
    this.modalService.dismissAll();
    this.shoImg = true;

  }

  onDeletePopup(data: any, index: any) {
    this._sharedPopupSvc.popup("", this.translate.instant('common.areSelectRecordDelete'), "", this.translate.instant('common.delete'), this.translate.instant('common.cancelSmall'), null, this.translate.instant('common.collaterialDescription') + " : " + data?.dscr).then((confirmed: any) => {
      if (confirmed) {
        // Delete function call
        // this._creditCollateralSvc.getAllCollatrialDtl_data = Object.assign({}, data);
        this._sharedSvc.msg = "Record Deleted";
        this._creditCollateralSvc.getAllCollatrialDtl_data[index].delFlg = true;
        // this._creditCollateralSvc.getAllCollatrialDtl_data.splice(index, 1);
        this._creditCollateralSvc.calculationTotalDis();
        this.effctvCrdtAmnt.setValue(localStorage.getItem('effctvCrdtAmnt') ? JSON.parse(localStorage.getItem('effctvCrdtAmnt')) : null);
        this.crdtUpto.setValue(localStorage.getItem('crdtUpto') ? JSON.parse(localStorage.getItem('crdtUpto')) : null);

        this.setValueToCollateralModel();

        this._conditionsService.creditCollaterial = true;
        this._conditionsService.creditCollaterialFn();

        // console.log("Delete Success");
      }
      else {
        //in case of cancel
        return;
      }
    })
      .catch(() => {
        // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)');
        return;
      });
  }
  onEdit(content: any, data: any, h4: any, index: any) {
    this.index = index;
    this.modelHeading = h4;
    this._creditCollateralSvc.edit = true;
    this._creditCollateralSvc.collateralModel = data;
    localStorage.setItem('netWeight', JSON.stringify(Number((this._creditCollateralSvc.collateralModel?.grossWghtExcl * this._creditCollateralSvc.collateralModel?.gldQltyKrt) / 24)));
    localStorage.setItem('pureGold', JSON.stringify(Number(((data?.grossWghtExcl * data?.gldQltyKrt) / 24) * this.getCurrentGoldRate(data?.mrktByngRt))));
    this.setValueToCollateralForm();
    // this._conditionsService.creditCollaterial = true;
    // this._conditionsService.creditCollaterialFn();
    this._sharedSvc.msg = this.translate.instant('toast.recordUpdated');
    this.modalService.open(content, {
      // backdrop: "static",
      // keyboard: false,
      windowClass: "popupWidth1k",
      backdropClass: 'light-blue-backdrop',
      centered: true
    });
  }
  onUpdate() {
    this._creditCollateralSvc.getAllCollatrialDtl_data[this.index] = this._creditCollateralSvc.collateralModel;
    this._conditionsService.creditCollaterial = true;
    this._conditionsService.creditCollaterialFn();
    this._creditCollateralSvc.getAllCollatrialDtl_data[this.index].mrktByngRt=this._creditCollateralSvc.getLatestGoldRate?.find(item=> item.mrktRate === this._creditCollateralSvc.collateralModel.mrktByngRt || item.goldRateSeq === Number(this._creditCollateralSvc.collateralModel.mrktByngRt))?.goldRateSeq;
    this._creditCollateralSvc.calculationTotalDis();
    this.effctvCrdtAmnt.setValue(localStorage.getItem('effctvCrdtAmnt') ? JSON.parse(localStorage.getItem('effctvCrdtAmnt')) : null);
    this.crdtUpto.setValue(localStorage.getItem('crdtUpto') ? JSON.parse(localStorage.getItem('crdtUpto')) : null);

    this.dismissModal();
  }

  onSubmit(): void {
    
    if (this.CollateralForm.invalid) {
      for (const control of Object.keys(this.CollateralForm.controls)) {
        this.CollateralForm.controls[control].markAsTouched();
      }
      return;
    }
    this._creditCollateralSvc.getAllCollatrialDtl_dataModel.push(this._creditCollateralSvc.collateralModel);
    this._conditionsService.creditCollaterial = true;
    this._conditionsService.creditCollaterialFn();
    this._creditCollateralSvc.collateralModel.mrktByngRt=this._creditCollateralSvc.getLatestGoldRate?.find(item=>item.mrktRate === this._creditCollateralSvc.collateralModel.mrktByngRt || item.goldRateSeq === Number(this._creditCollateralSvc.collateralModel.mrktByngRt))?.goldRateSeq;
    this._creditCollateralSvc.getAllCollatrialDtl_data.push(this._creditCollateralSvc.collateralModel);
    this._creditCollateralSvc.calculationTotalDis();
    this.effctvCrdtAmnt.setValue(localStorage.getItem('effctvCrdtAmnt') ? JSON.parse(localStorage.getItem('effctvCrdtAmnt')) : null);
    this.crdtUpto.setValue(localStorage.getItem('crdtUpto') ? JSON.parse(localStorage.getItem('crdtUpto')) : null);
    this.setValueToCollateralModel();
    this.modalService.dismissAll();
    this.CollateralForm.reset();
    this._sharedSvc.success(this._sharedSvc.msg);
  }

  // Table POPUP
  onSave(): void {
    this._creditCollateralSvc.collateralModel = this._creditCollateralSvc.getAllCollatrialDtl_data;
    this._sharedSvc.showSpinner()
    this._creditCollateralSvc.saveCollateral().subscribe({
      next: (response: any) => {
        if (response) {
          this._sharedSvc.hideSpinner();
          // this._otherOutstandingSvc.getAllOutstandingPG = response;
          this._conditionsService.creditCollaterial = false;
          this._conditionsService.creditCollaterialFn();
          this._creditCollateralSvc.getAllCollatrialDtl_dataModel = [];
          localStorage.removeItem('getAllCollatrialDtl_dataModel');
          this._sharedSvc.success(this._sharedSvc.msg);

          // this._creditCollateralSvc.InItGetCollateralDTL(response?.crdtCltrlDtlSeq);
          // if (this.crdtCltrlHdrSeq) {
          //   this._creditCollateralSvc.InItGetAllCollateralDTL(this.crdtCltrlHdrSeq);
          // }

        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }

  onSubmitHDR(): void {
    
    if (this.CollateralHDRForm.invalid) {
      for (const control of Object.keys(this.CollateralHDRForm.controls)) {
        this.CollateralHDRForm.controls[control].markAsTouched();
      }
      return;
    }

    if (this.shroffPicUrl && this.goldPicUrl) { }
    else {
      this._sharedSvc.error(this.translate.instant('toast.selectImages'));
      return;
    }
    // if (this.CollateralHDRForm.invalid) {
    //   for (const control of Object.keys(this.CollateralHDRForm.controls)) {
    //     this.CollateralHDRForm.controls[control].markAsTouched();
    //   }
    //   return;
    // }
    this.setValueToHDRModel();
    this._sharedSvc.showSpinner()
    this._creditCollateralSvc.saveCollateralHDR().subscribe({
      next: (response: any) => {
        if (response) {
          localStorage.setItem('crdtCltrlHdrSeq', response.crdtCltrlHdrSeq);
          // this._creditCollateralSvc.InItGetCollateralHDR(this.crdtApp);
          // this._creditCollateralSvc.InItGetAllCollateralDTL(response.crdtCltrlHdrSeq);
          this.setCrdtCltrlHdrSeq(response.crdtCltrlHdrSeq);
          this.modalService.dismissAll();
          this._sharedSvc.success(this._sharedSvc.msg);
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }

  setCrdtCltrlHdrSeq(crdtCltrlHdrSeq: any) {

    for (let i = 0; i < this._creditCollateralSvc.getAllCollatrialDtl_data?.length; i++) {
      this._creditCollateralSvc.getAllCollatrialDtl_data[i].crdtCltrlHdrSeq = crdtCltrlHdrSeq;
    }
    this.onSave();
  }

  compressFile(url: any) {
    this.setD_V3();
    return this.imageCompress
      .uploadFile()
      .then(({ image, orientation, fileName }: UploadResponse) => {
        this.imgResultBeforeCompress = image;
        // console.warn('File Name:', fileName);
        // console.warn(
        //   `Original: ${image.substring(0, 50)}... (${image.length} characters)`
        // );
        if (url == "gold") {
          this.goldPicUrl = image;
        }
        if (url == "shroff") {
          this.shroffPicUrl = image;
        }
        // if (image.length > 500000) { this.s1 = 50; } // 0.5Mb
        // if (image.length > 1000000) { this.s1 = 30; } // 1MB
        // if (image.length > 5000000) { this.s1 = 10; } // 5MB
        // if (image.length > 10000000) { this.s1 = 1; } // 10MB
        // if (image.length > 50000000) { this.s1 = 0.3; } // 50MB
        // console.warn('Size in bytes was:', this.imageCompress.byteCount(image));
        this.imageCompress
          .compressFile(image, orientation, 10, 50)
          .then((result: DataUrl) => {
            this.imgResultAfterCompress = result;
            // console.warn(
            //   `Compressed: ${result.substring(0, 50)}... (${result.length
            //   } characters)`
            // );
            // console.warn(
            //   'Size in bytes is now:',
            //   this.imageCompress.byteCount(result)
            // );
            if (url == "gold") {
              // this.goldPicUrl = result;
              this._clientDocsSvc.saveDocsModel.docImg = this.goldPicUrl;
              this._clientDocsSvc.saveDocsModel.docSeq = 18;
              this._clientDocsSvc.saveDocsModel.entySeq = this.crdtApp;
              this.modal1 = this._clientDocsSvc.saveDocsModel;
              this.uploadMsg = "Gold Image Upload"

              this.onSubmitDoc();
            }
            if (url == "shroff") {
              // this.shroffPicUrl = result;
              this._clientDocsSvc.saveDocsModel.docImg = this.shroffPicUrl;
              this._clientDocsSvc.saveDocsModel.docSeq = 19;
              this._clientDocsSvc.saveDocsModel.entySeq = this.crdtApp;
              this.modal2 = this._clientDocsSvc.saveDocsModel;
              this.uploadMsg = "Shroff Image Upload"

              this.onSubmitDoc();
            }
          });
      });
  }

  // uploadFile(url: any) {
  //   this.setD_V3();
  //   this.imageCompress.uploadFile().then(({ image, orientation }) => {
  //     this.formatBytes(this.imageCompress.byteCount(image));
  //     this.imgResultBeforeCompress = image;
  //     // console.warn('Size in bytes was:', this.imageCompress.byteCount(image));
  //     this.imageCompress
  //       .compressFile(image, orientation, 50, 50)
  //       .then((result) => {
  //         this.src = result;

  //         if (url == "gold") {
  //           this.goldPicUrl = result;
  //           this._clientDocsSvc.saveDocsModel.docImg = this.goldPicUrl;
  //           this._clientDocsSvc.saveDocsModel.docSeq = 18;
  //           this._clientDocsSvc.saveDocsModel.entySeq = this.crdtApp;
  //           this.modal1 = this._clientDocsSvc.saveDocsModel;
  //           this.uploadMsg = "Gold Image Upload"

  //           this.onSubmitDoc();
  //         }
  //         if (url == "shroff") {
  //           this.shroffPicUrl = result;
  //           this._clientDocsSvc.saveDocsModel.docImg = this.shroffPicUrl;
  //           this._clientDocsSvc.saveDocsModel.docSeq = 19;
  //           this._clientDocsSvc.saveDocsModel.entySeq = this.crdtApp;
  //           this.modal2 = this._clientDocsSvc.saveDocsModel;
  //           this.uploadMsg = "Shroff Image Upload"

  //           this.onSubmitDoc();
  //         }


  //         this.imgResultAfterCompress = result;
  //         // console.warn(
  //         //   'Size in bytes is now:',
  //         //   this.imageCompress.byteCount(result)
  //         // );
  //         this.formatBytesAfter(this.imageCompress.byteCount(result));
  //       });
  //   });
  // }

  // formatBytes(bytes, decimals = 2) {
  //   if (bytes === 0) return '0 Bytes';

  //   const k = 1024;
  //   const dm = decimals < 0 ? 0 : decimals;
  //   const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  //   const i = Math.floor(Math.log(bytes) / Math.log(k));
  //   this.imgSizeBefore = parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]

  //   // console.warn(
  //   //   'Size was:',
  //   //   parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  //   // );

  //   return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  // }

  // formatBytesAfter(bytes, decimals = 2) {
  //   if (bytes === 0) return '0 Bytes';

  //   const k = 1024;
  //   const dm = decimals < 0 ? 0 : decimals;
  //   const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  //   const i = Math.floor(Math.log(bytes) / Math.log(k));
  //   this.imgSizeAfter = parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]

  //   // console.warn(
  //   //   'Size was:',
  //   //   parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  //   // );

  //   return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  // }

  onSubmitDoc(): void {
    // if (this.rUtilityBill && this.bUtilityBill && this.fAgreement1 && this.fAgreement2) {
    // } else {
    //   this._sharedSvc.error("Please upload all images");
    //   this.invalid = true;
    //   return;
    // }

    if (this._clientDocsSvc.getDoc?.length > 0) {
      for (let i = 0; i < this._clientDocsSvc.getDoc.length; i++) {
        if (this._clientDocsSvc.getDoc[i].docSeq == 18) {
          this.modal1.clntDocSeq = this._clientDocsSvc.getDoc[i]?.clntDocSeq;

        }

        if (this._clientDocsSvc.getDoc[i].docSeq == 19) {
          this.modal2.clntDocSeq = this._clientDocsSvc.getDoc[i]?.clntDocSeq;


        }
      }
    }
    // for (let i = 0; i < 4; i++) {
    //   if (i == 0) {
    //     if (this.modal1) {
    //       this._clientDocsSvc.saveDocsModel = this.modal1;
    //     }
    //   }
    //   if (i == 1) {
    //     if (this.modal2) {
    //       this._clientDocsSvc.saveDocsModel = this.modal2;
    //     }
    //   }
    //   if (i == 2) {
    //     if (this.modal3) {
    //       this._clientDocsSvc.saveDocsModel = this.modal3;
    //     }
    //   }
    //   if (i == 3) {
    //     if (this.modal4) {
    //       this._clientDocsSvc.saveDocsModel = this.modal4;

    //     }
    //   }
    this._sharedSvc.showSpinner3();
    this._clientDocsSvc.SaveDocs().subscribe({
      next: response => {
        if (response) {
          this.modalService.dismissAll();
          this.imgPath = true;
          // let msg = "Upload Img Successfully"
          // if (this._clientDocsSvc.saveDocsModel.clntDocSeq != 0) {
          //   msg = "Update Img Successfully"
          // }
          this._sharedSvc.success(this.uploadMsg);
          this._sharedSvc.hideSpinner3();
          // if (i == 3) {

          // }
        }
      },
      error: e => {
        this._sharedSvc.hideSpinner3();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    });
    // }
    this._sharedSvc.hideSpinner();
  }

  InItGetAllClientDocs(clnt_seq: any): void {
    this._sharedSvc.showSpinner2();
    this._clientDocsSvc.getAllClientDocs(clnt_seq, 4).subscribe({
      next: response => {
        if (response) {
          this._clientDocsSvc.getDoc = response;
          this.setD_V3();
          for (let i = 0; i < this._clientDocsSvc.getDoc.length; i++) {
            if (this._clientDocsSvc.getDoc[i].docSeq == 18) {
              this.goldPicUrl = this._clientDocsSvc.getDoc[i]?.docImg;
              this._clientDocsSvc.saveDocsModel.docSeq = 18;
              this._clientDocsSvc.saveDocsModel.docImg = this.goldPicUrl;
              this._clientDocsSvc.saveDocsModel.clntDocSeq = this._clientDocsSvc.getDoc[i]?.clntDocSeq;
              this.modal1 = this._clientDocsSvc.saveDocsModel;
              this.goldPicUrl = "data:image/png;base64," + this.goldPicUrl
            }
            if (this._clientDocsSvc.getDoc[i].docSeq == 19) {
              this.shroffPicUrl = this._clientDocsSvc.getDoc[i]?.docImg;
              this._clientDocsSvc.saveDocsModel.docImg = this.shroffPicUrl;
              this._clientDocsSvc.saveDocsModel.docSeq = 19;
              this._clientDocsSvc.saveDocsModel.clntDocSeq = this._clientDocsSvc.getDoc[i]?.clntDocSeq;
              this.modal2 = this._clientDocsSvc.saveDocsModel;
              this.shroffPicUrl = "data:image/png;base64," + this.shroffPicUrl

            }

          }
          this.imgPath = true;
          this._sharedSvc.hideSpinner2();
        }
      },
      error: e => {
        this._sharedSvc.hideSpinner2();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => {
      //   this._sharedSvc.hideSpinner();
      // }
    });
  }

  validationForm() {
    this.CollateralHDRForm = new FormGroup({
      shrffId: new FormControl(this.CollateralHDR.shrffId, [
        Validators.required,
      ]),
      shrffBgNum: new FormControl(this.CollateralHDR.shrffBgNum, [
        Validators.required,
      ]),
      brnchBgNum: new FormControl(this.CollateralHDR.brnchBgNum, [
        Validators.required,
      ]),
      effctvCrdtAmnt: new FormControl(this.CollateralHDR.effctvCrdtAmnt, [
        // Validators.required,
      ]),
      crdtUpto: new FormControl(this.CollateralHDR.crdtUpto, [
        Validators.required,
        Validators.min(localStorage.getItem('crdtUpto') ? JSON.parse(localStorage.getItem('crdtUpto')) : null),
        Validators.max(localStorage.getItem('crdtUpto') ? JSON.parse(localStorage.getItem('crdtUpto')) : null),
      ]),
    });

    this.CollateralForm = new FormGroup({
      dscr: new FormControl(this.Collateral.dscr, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100),
      ]),
      grossWghtIncl: new FormControl(this.Collateral.grossWghtIncl, [
        Validators.required,
        Validators.min(1),
        Validators.max(500000),
      ]),
      grossWghtExcl: new FormControl(this.Collateral.grossWghtExcl, [
        Validators.required,
        Validators.min(1),
        Validators.max(500000),
      ]),
      gldQltyKrt: new FormControl(this.Collateral.gldQltyKrt, [
        Validators.required,
        Validators.min(1),
        Validators.max(24),
      ]),
      mrktByngRt: new FormControl(this.Collateral.mrktByngRt, [
        Validators.required,
      ]),

    });
  }

  setValueToHDRModel() {
    this._creditCollateralSvc.saveCollateralHDRModel.shrffId = this.shrffId.value;
    this._creditCollateralSvc.saveCollateralHDRModel.effctvCrdtAmnt = this.effctvCrdtAmnt.value;
    this._creditCollateralSvc.saveCollateralHDRModel.crdtUpto = this.crdtUpto.value;
    this._creditCollateralSvc.saveCollateralHDRModel.shrffBgNum = this.shrffBgNum.value;
    this._creditCollateralSvc.saveCollateralHDRModel.brnchBgNum = this.brnchBgNum.value;
  }
  setValueToHDRForm() {

    this.shrffId.setValue(this._creditCollateralSvc.saveCollateralHDRModel.shrffId);
    //this.effctvCrdtAmnt.setValue(this._creditCollateralSvc.saveCollateralHDRModel.effctvCrdtAmnt ? this._creditCollateralSvc.saveCollateralHDRModel.effctvCrdtAmnt : localStorage.getItem('effctvCrdtAmnt') ? JSON.parse(localStorage.getItem('effctvCrdtAmnt')) : 0);
    this.effctvCrdtAmnt.setValue(this._creditCollateralSvc.saveCollateralHDRModel.effctvCrdtAmnt);
    // this.crdtUpto.setValue(this._creditCollateralSvc.saveCollateralHDRModel.crdtUpto ? this._creditCollateralSvc.saveCollateralHDRModel.crdtUpto : localStorage.getItem('crdtUpto') ? JSON.parse(localStorage.getItem('crdtUpto')) : 0);
    this.crdtUpto.setValue(this._creditCollateralSvc.saveCollateralHDRModel.crdtUpto);
    this.shrffBgNum.setValue(this._creditCollateralSvc.saveCollateralHDRModel.shrffBgNum);
    this.brnchBgNum.setValue(this._creditCollateralSvc.saveCollateralHDRModel.brnchBgNum);
  }

  setValueToCollateralModel() {
    this._creditCollateralSvc.collateralModel.dscr = this.dscr.value;
    this._creditCollateralSvc.collateralModel.grossWghtIncl = this.grossWghtIncl.value;
    this._creditCollateralSvc.collateralModel.grossWghtExcl = this.grossWghtExcl.value;
    this._creditCollateralSvc.collateralModel.gldQltyKrt = this.gldQltyKrt.value;
    this._creditCollateralSvc.collateralModel.mrktByngRt = this.mrktByngRt.value;
  }
  setValueToCollateralForm() {
    this.dscr.setValue(this._creditCollateralSvc.collateralModel.dscr);
    this.grossWghtIncl.setValue(this._creditCollateralSvc.collateralModel.grossWghtIncl);
    this.grossWghtExcl.setValue(this._creditCollateralSvc.collateralModel.grossWghtExcl);
    this.gldQltyKrt.setValue(this._creditCollateralSvc.collateralModel.gldQltyKrt);
    this.mrktByngRt.setValue(this._creditCollateralSvc.collateralModel.mrktByngRt);
    if (this.modelHeading === 'Update Collateral') {
      if (localStorage.getItem('netWeight')) {
        this.netWeight = JSON.parse(localStorage.getItem('netWeight'))
      }
      if (localStorage.getItem('pureGold')) {
        this.pureGold = JSON.parse(localStorage.getItem('pureGold'))
      }
    }
  }

  getCurrentGoldRate(goldSeq: any) {
    for (let i = 0; i < this._creditCollateralSvc.getLatestGoldRate?.length; i++) {
      if (Number(this._creditCollateralSvc.getLatestGoldRate[i].goldRateSeq) === Number(goldSeq)) {
        return this._creditCollateralSvc.getLatestGoldRate[i].mrktRate;
      }

    }
  }

  get dscr() {
    return this.CollateralForm.get('dscr')!;
  }
  get grossWghtIncl() {
    return this.CollateralForm.get('grossWghtIncl')!;
  }
  get grossWghtExcl() {
    return this.CollateralForm.get('grossWghtExcl')!;
  }
  get gldQltyKrt() {
    return this.CollateralForm.get('gldQltyKrt')!;
  }
  get mrktByngRt() {
    return this.CollateralForm.get('mrktByngRt')!;
  }
  get netWeightField() {
    return this.CollateralForm.get('netWeightField')!;
  }




  get shrffId() {
    return this.CollateralHDRForm.get('shrffId')!;
  }
  get effctvCrdtAmnt() {
    return this.CollateralHDRForm.get('effctvCrdtAmnt')!;
  }
  get crdtUpto() {
    return this.CollateralHDRForm.get('crdtUpto')!;
  }
  get shrffBgNum() {
    return this.CollateralHDRForm.get('shrffBgNum')!;
  }
  get brnchBgNum() {
    return this.CollateralHDRForm.get('brnchBgNum')!;
  }



  InItGetCollateralHDRLocal(crdtAppSeq: any) {
    this._creditCollateralSvc.getCollateralHDR(crdtAppSeq).subscribe({
      next: (response: any) => {
        if (response) {
          
          this._creditCollateralSvc.saveCollateralHDRModel = response;
          this.setValueToHDRForm();
          this._creditCollateralSvc.saveCollateralHDRModel.shrffId = null;
          this._creditCollateralSvc.InItGetAllCollateralDTL(response.crdtCltrlHdrSeq);
          localStorage.setItem('crdtCltrlHdrSeq', response.crdtCltrlHdrSeq)
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    })
  }


}
