<section>
  <div class="sidebar" [class.sidebar-close]="!_sharedSvc.openSidebar">
    <app-sidebar></app-sidebar>
  </div>
  <div class="home-section">
    <div class="home-main" [class.sidebar-close_home-main]="!_sharedSvc.openSidebar">
      <app-navbar></app-navbar>
      <div class="home-view" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
        [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
        <section>
          <div class="row mb-3">
            <div class="col-sm-8 col-md-8 col-lg-8">
              <h4>{{ "sideBarMenu.setup.fileUpload" | translate }}</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="dashboard-card loan-table mt-3">
                <form>
                  <div class="col-12 mb-3">
                    <div class="form-floating">
                      <select class="form-control form-select input-radius">
                        <option>{{ "common.type" | translate }}</option>
                        <option></option>
                        <option></option>
                      </select>
                      <label>{{ "fileUpload.fileType" | translate }}</label>
                    </div>
                  </div>
                  <div class="col-12 mb-3">
                    <div class="form-floating">
                      <input type="text" style="text-transform: capitalize" autocomplete="off"
                        class="form-control search-input" />
                      <label>{{ "fileUpload.filePath" | translate }}</label>
                    </div>
                  </div>
                  <div class="button_div">
                    <button class="btn login-button-class">
                      {{ "fileUpload.process" | translate }}
                    </button>
                    <button class="btn login-button-class cancelBtn" type="reset">
                      {{ "common.cancel" | translate }}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>