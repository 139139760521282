<section>
    <div class="sidebar" [class.sidebar-close]="!_sharedSvc.openSidebar">
        <app-sidebar></app-sidebar>
    </div>
    <div class="home-section">
        <div class="home-main" [class.sidebar-close_home-main]="!_sharedSvc.openSidebar">
            <app-navbar></app-navbar>
            <div class="home-view" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
                [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
                <section>
                    <div class="row mb-3">
                        <div class="col-sm-8 col-md-8 col-lg-8">
                            <h4>{{'common.community' | translate}}</h4>
                        </div>
                        <div class="col-sm-4 col-md-4 col-lg-4">
                            <div class="display-flex float-right">
                                <div class="back-btn me-2" (click)="portfolioFn()">
                                    <i class="fa fa-reply"></i>
                                </div>
                                <div class="add-button" (click)="openAddEditCommunityModal(addCommunityModal,false)"><i
                                        class="fa fa-plus"></i></div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <div class="dashboard-card loan-table">
                                <div class="row mb-3">
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                        <div class="display-flex">
                                            <b class="me-3 fix-me-3">
                                                <span class="me-2">{{'common.communityId' | translate}}: </span><span
                                                    class="clr186ed1">{{branchID}}</span>
                                            </b>
                                            <b>
                                                <span class="me-2">{{'common.communityName' | translate}}: </span><span
                                                    class="clr186ed1">{{branchName}}</span>
                                            </b>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-sm-7 col-md-7 col-lg-8">
                                        <ng-container *ngIf="_sharedSvc.pageSize >= 10">
                                            <span>{{'common.show' | translate}}</span>
                                            <input type="number" class="m-1 text-center" style="width: 45px;">
                                            <span>{{'common.entries' | translate}}</span>
                                        </ng-container>
                                    </div>
                                    <div class="col-sm-5 col-md-5 col-lg-4">
                                        <div class="inside-icon">
                                            <i class="fa fa-search"></i>
                                            <input type="search" class="form-control search-input"
                                                placeholder="{{'common.search' | translate}}">
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                        <div class="table-responsive">
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <td>{{'common.communityId' | translate}}</td>
                                                        <td>{{'common.communityName' | translate}}</td>
                                                        <td>{{'common.description' | translate}}</td>
                                                        <td>{{'common.status' | translate}}</td>
                                                        <td class="text-center">{{'common.action' | translate}}</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <ng-container
                                                        *ngIf="!_SetupService.portCommunityList?.error && _SetupService.portCommunityList?.length > 0">
                                                        <tr *ngFor="let list of _SetupService.portCommunityList">

                                                            <td>{{list.cmntySeq}}</td>
                                                            <td>{{list.cmntyNm}}</td>
                                                            <td>{{list.cmntyCmnt}}</td>
                                                            <td>{{list.cmntyStsKey == "2132" ?
                                                                this.translate.instant('common.inActive') :
                                                                this.translate.instant('common.active')}}
                                                            </td>
                                                            <td class="text-center">
                                                                <div class="action-inner">
                                                                    <i class="fa fa-pencil"
                                                                        (click)="editCommunity(addCommunityModal,list.cmntySeq , true)"></i>
                                                                    <i class="fa fa-trash"
                                                                        (click)="deleteRecord(list.cmntySeq)"></i>

                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </ng-container>
                                                    <ng-container *ngIf="_SetupService.portCommunityList?.error">
                                                        <tr>
                                                            <td colspan="6" class="text-center">{{
                                                                _SetupService.portCommunityList.error }}</td>
                                                        </tr>
                                                    </ng-container>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</section>

<ng-template id="display-modal" #addCommunityModal let-c="close" let-d="dismiss">
    <div class="modal-body" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
        [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
        <div class="p-3 pb-0">
            <div class="row mb-3">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="text-center mb-3">
                        <h4>{{isEdit === true ? this.translate.instant('common.edit') :
                            this.translate.instant('common.add')}} {{'common.community' |
                            translate}}</h4>
                    </div>
                </div>
            </div>
            <form [formGroup]="addCommunityformdata" (ngSubmit)="submitCommunity(addCommunityformdata)">
                <div class="row mb-3">

                    <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                        <div class="form-floating">
                            <input type="text" style="text-transform: capitalize;" formControlName="cmntyNm"
                                autocomplete="off" class="form-control input-radius"
                                [class.is-invalid]="cmntyNm.invalid && (cmntyNm.dirty || cmntyNm.touched)">
                            <div *ngIf="cmntyNm.invalid && (cmntyNm.dirty || cmntyNm.touched)" class="invalid-feedback">
                                <div *ngIf="cmntyNm.errors?.['required']">
                                    {{'common.communityNameRequire' | translate}}
                                </div>

                            </div>
                            <label>{{'common.communityName' | translate}}</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                        <div class="form-floating">
                            <select name="cmntyStsKey" id="cmntyStsKey" class="form-control input-radius form-select"
                                formControlName="cmntyStsKey"
                                [class.is-invalid]="cmntyStsKey.invalid && (cmntyStsKey.dirty || cmntyStsKey.touched)">
                                <option *ngFor="let item of _picklistService.SHROFF_STATUS_Data; let i = index"
                                    [value]="item?.refCdSeq">
                                    {{ item.refCdDscr }}
                                </option>
                            </select>

                            <div *ngIf="cmntyStsKey.invalid && (cmntyStsKey.dirty || cmntyStsKey.touched)"
                                class="invalid-feedback">
                                <div *ngIf="cmntyStsKey.errors?.['required']">
                                    {{'common.statusRequire' | translate}}
                                </div>

                            </div>
                            <label> {{'common.communityStatus' | translate}}</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                        <div class="form-floating">
                            <input type="text" style="text-transform: capitalize;" formControlName="cmntyCmnt"
                                autocomplete="off" class="form-control input-radius"
                                [class.is-invalid]="cmntyCmnt.invalid && (cmntyCmnt.dirty || cmntyCmnt.touched)">
                            <div *ngIf="cmntyCmnt.invalid && (cmntyCmnt.dirty || cmntyCmnt.touched)"
                                class="invalid-feedback">
                                <div *ngIf="cmntyCmnt.errors?.['required']">
                                    {{'common.descriptionRequire' | translate}}
                                </div>

                            </div>
                            <label> {{'common.communityDescription' | translate}}</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="button_div">
                            <button class="btn login-button-class" [class.pointerNone]="_sharedSvc.loading"
                                [disabled]="_sharedSvc.loading">{{'common.ok' | translate}}</button>
                            <button class="btn login-button-class cancelBtn" (click)="d('Cross click')"
                                type="reset">{{'common.cancel' | translate}}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>