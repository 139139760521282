import { Component } from '@angular/core';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { SetupService } from '../../../shared/service/setup.service';
import { SharedService } from 'src/app/components/shared/service/shared.service';

@Component({
  selector: 'app-document-assignment',
  templateUrl: './document-assignment.component.html',
  styleUrls: ['./document-assignment.component.css']
})
export class DocumentAssignmentComponent {
  constructor(
    public _sharedSvc: SharedService,
    private nav: NgbNav,
  ) { }

  moveToNextTab() {
    if (this.nav) {
      this._sharedSvc.showSpinner3();
      const currentTab = this.nav.activeId || 1;
      const nextTab = currentTab < this.nav.items.length ? currentTab + 1 : 1;
      this.nav.select(nextTab);
      this._sharedSvc.hideSpinner3();
    }
  }
}
