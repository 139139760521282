<section>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12">
      <div class="mt-3 loan-table">
        <div class="row mb-3">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="btnCenter otherOutstanding">
              <h3>
                {{
                  "clientOnboarding.businessApplTab.businessAppraisal"
                    | translate
                }}
              </h3>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
            <div class="btnCenter otherOutstanding">
              <h6>
                {{ "common.clintName" | translate }}:
                <b>{{ saveCnicData?.frstNm | titlecase }}</b> /
                {{ "common.clintId" | translate }}:
                <b>{{ saveCnicData?.clntId }}</b>
              </h6>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="btnCenter" (click)="_conditionsService.toggle()">
              <div
                class="d-inline"
                (click)="_sharedSvc.disTabForAddresstoggle()"
              >
                <button
                  class="btn infoBtn"
                  (click)="showBtn(true, false, false, false, false)"
                  [class.infoBtn_bg]="basicInformation"
                  [disabled]="
                    _conditionsService.savePartiallyExpData ||
                    _conditionsService.savePartiallyINCData ||
                    _sharedSvc.disableTabsForAddress
                  "
                >
                  {{ "common.basicInformation" | translate }}
                </button>
              </div>
              <div class="d-inline">
                <button
                  class="btn infoBtn"
                  (click)="showBtn(false, true, false, false, false)"
                  [class.infoBtn_bg]="businessAddress"
                  [disabled]="
                    _conditionsService.savePartiallyExpData ||
                    _conditionsService.savePartiallyINCData ||
                    _sharedSvc.disableTabsForAddress
                  "
                >
                  {{ "common.businessAddress" | translate }}
                </button>
              </div>
              <div
                class="d-inline"
                (click)="_sharedSvc.disTabForAddresstoggle()"
              >
                <button
                  class="btn infoBtn"
                  (click)="showBtn(false, false, true, false, false)"
                  [class.infoBtn_bg]="additionalInformation"
                  [disabled]="
                    _conditionsService.savePartiallyExpData ||
                    _conditionsService.savePartiallyINCData ||
                    _sharedSvc.disableTabsForAddress
                  "
                >
                  {{
                    "clientOnboarding.businessApplTab.additionalInformation"
                      | translate
                  }}
                </button>
              </div>
              <div
                class="d-inline"
                (click)="_sharedSvc.disTabForAddresstoggle()"
              >
                <button
                  class="btn infoBtn"
                  (click)="showBtn(false, false, false, true, false, 3)"
                  [class.infoBtn_bg]="businessInc"
                  [disabled]="
                    _conditionsService.savePartiallyExpData ||
                    _conditionsService.savePartiallyINCData ||
                    _sharedSvc.disableTabsForAddress
                  "
                >
                  {{ "common.businessIncome" | translate }}
                </button>
              </div>
              <div
                class="d-inline"
                (click)="_sharedSvc.disTabForAddresstoggle()"
              >
                <button
                  class="btn infoBtn"
                  (click)="showBtn(false, false, false, false, true)"
                  [class.infoBtn_bg]="businessExpenses"
                  [disabled]="
                    _conditionsService.savePartiallyExpData ||
                    _conditionsService.savePartiallyINCData ||
                    _sharedSvc.disableTabsForAddress
                  "
                >
                  {{
                    "clientOnboarding.businessApplTab.businessExpenses"
                      | translate
                  }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="basicInformation">
          <form [formGroup]="basicInfoForm">
            <div
              class="row mb-3"
              [class.disableForm]="_conditionsService.readOnlyView_Client"
            >
              <div class="col-sm-12 col-md-6 col-lg-3 mb-3">
                <div class="form-floating">
                  <select
                    id="sectKey"
                    class="form-control input-radius form-select"
                    formControlName="sectKey"
                    (change)="_picklistService.InItBusinessActivity($event)"
                    [class.is-invalid]="
                      sectKey.invalid && (sectKey.dirty || sectKey.touched)
                    "
                  >
                    <option
                      *ngFor="
                        let item of _picklistService.BUSINESSSECTOR_data;
                        let i = index
                      "
                      [value]="item?.bizSectSeq"
                    >
                      {{ item.bizSectNm }}
                    </option>
                  </select>
                  <div
                    *ngIf="
                      sectKey.invalid && (sectKey.dirty || sectKey.touched)
                    "
                    class="invalid-feedback"
                  >
                    <div *ngIf="sectKey.errors?.['required']">
                      {{
                        "clientOnboarding.businessApplTab.require.businessSector"
                          | translate
                      }}
                    </div>
                  </div>
                  <label for="sectKey">{{
                    "common.businessSector" | translate
                  }}</label>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-3 mb-3">
                <div class="form-floating">
                  <select
                    id="actyKey"
                    class="form-control input-radius form-select"
                    formControlName="actyKey"
                    [class.is-invalid]="
                      actyKey.invalid && (actyKey.dirty || actyKey.touched)
                    "
                  >
                    <option
                      *ngFor="
                        let item of _picklistService.BUSINESSACTIVITY_data;
                        let i = index
                      "
                      [value]="item?.bizActySeq"
                    >
                      {{ item.bizActyNm }}
                    </option>
                  </select>
                  <div
                    *ngIf="
                      actyKey.invalid && (actyKey.dirty || actyKey.touched)
                    "
                    class="invalid-feedback"
                  >
                    <div *ngIf="actyKey.errors?.['required']">
                      {{
                        "clientOnboarding.businessApplTab.require.selectBusiness"
                          | translate
                      }}.
                    </div>
                  </div>
                  <label for="actyKey">{{
                    "common.businessActivity" | translate
                  }}</label>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
                <div class="form-floating">
                  <input
                    id="bizDtlStr"
                    type="text"
                    style="text-transform: capitalize"
                    autocomplete="off"
                    class="form-control input-radius"
                    formControlName="bizDtlStr"
                    minlength="3"
                    maxLength="100"
                    [class.is-invalid]="
                      bizDtlStr.invalid &&
                      (bizDtlStr.dirty || bizDtlStr.touched)
                    "
                    required
                  />
                  <div
                    *ngIf="
                      bizDtlStr.invalid &&
                      (bizDtlStr.dirty || bizDtlStr.touched)
                    "
                    class="invalid-feedback"
                  >
                    <div *ngIf="bizDtlStr.errors?.['required']">
                      {{
                        "clientOnboarding.businessApplTab.businessDetailRequire"
                          | translate
                      }}
                    </div>
                    <div *ngIf="bizDtlStr.errors?.['minlength']">
                      {{ "common.fieldMustHaveLeastThreeLetters" | translate }}
                    </div>
                    <div *ngIf="bizDtlStr.errors?.['maxlength']">
                      {{
                        "common.fieldMustHaveAtMostHundredLetters" | translate
                      }}
                    </div>
                  </div>
                  <label for="bizDtlStr">
                    {{
                      "clientOnboarding.businessApplTab.businessDetail"
                        | translate
                    }}</label
                  >
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-3 mb-3">
                <div class="form-floating">
                  <input
                    id="bizPhNum"
                    type="text"
                    style="text-transform: capitalize"
                    autocomplete="off"
                    class="form-control input-radius"
                    phoneMask="9999-9999999"
                    formControlName="bizPhNum"
                    (keypress)="numberOnly($event)"
                    [class.is-invalid]="
                      bizPhNum.invalid && (bizPhNum.dirty || bizPhNum.touched)
                    "
                    required
                  />
                  <div
                    *ngIf="
                      bizPhNum.invalid && (bizPhNum.dirty || bizPhNum.touched)
                    "
                    class="invalid-feedback"
                  >
                    <div *ngIf="bizPhNum.errors?.['required']">
                      {{ "common.mobileNumberRequire" | translate }}
                    </div>
                  </div>
                  <label for="bizPhNum">
                    {{
                      "clientOnboarding.businessApplTab.businessPhone"
                        | translate
                    }}</label
                  >
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-3 mb-3">
                <div class="form-floating">
                  <input
                    id="NoOfYears"
                    type="number"
                    class="form-control input-radius"
                    formControlName="NoOfYears"
                    (input)="calNoOfYears($event)"
                    [class.is-invalid]="
                      mnthInBiz.invalid &&
                      (mnthInBiz.dirty || mnthInBiz.touched)
                    "
                  />
                  <label for="NoOfYears"
                    >{{
                      "clientOnboarding.businessApplTab.noOfYears" | translate
                    }}
                  </label>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-3 mb-3">
                <div class="form-floating">
                  <input
                    id="noOfMonth"
                    type="number"
                    class="form-control input-radius"
                    formControlName="noOfMonth"
                    (input)="calNoOfMonth($event)"
                    (input)="limitInputToTwoDigits($event)"
                    maxLength="2"
                    [class.is-invalid]="
                      mnthInBiz.invalid &&
                      (mnthInBiz.dirty || mnthInBiz.touched)
                    "
                    required
                  />
                  <label for="noOfMonth">
                    {{
                      "clientOnboarding.businessApplTab.noOfMonths" | translate
                    }}</label
                  >
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-3 mb-3">
                <div class="form-floating">
                  <input
                    id="mnthInBiz"
                    type="number"
                    class="form-control input-radius readonly-field"
                    formControlName="mnthInBiz"
                    min="1"
                    maxLength="100"
                    [class.is-invalid]="
                      mnthInBiz.invalid &&
                      (mnthInBiz.dirty || mnthInBiz.touched)
                    "
                    required
                    readonly
                  />
                  <div
                    *ngIf="
                      mnthInBiz.invalid &&
                      (mnthInBiz.dirty || mnthInBiz.touched)
                    "
                    class="invalid-feedback"
                  >
                    <div *ngIf="mnthInBiz.errors?.['required']">
                      Business Duration is required.
                    </div>
                    <div *ngIf="mnthInBiz.errors?.['min']">
                      {{ "common.field1DigitRequire" | translate }}
                    </div>
                    <div *ngIf="mnthInBiz.errors?.['max']">
                      {{ "common.field100DigitRequire" | translate }}
                    </div>
                  </div>
                  <label for="mnthInBiz">
                    {{
                      "clientOnboarding.businessApplTab.businessDurationMonth"
                        | translate
                    }}</label
                  >
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-12 col-md-12 col-lg-12">
                <div class="button_div">
                  <button
                    class="btn login-button-class"
                    (click)="showBtn(false, true, false, false, false)"
                  >
                    {{ "common.continue" | translate }}
                  </button>
                  <button
                    class="btn login-button-class secondaryBtn"
                    type="button"
                    *ngIf="!_conditionsService.readOnlyView_Client"
                    [class.pointerNone]="_sharedSvc.loading"
                    [disabled]="!basicInfoForm.valid || _sharedSvc.loading"
                    (click)="onSubmitBasicInfo()"
                  >
                    {{ "common.save" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </ng-container>
        <ng-container *ngIf="businessAddress">
          <form [formGroup]="addressForm">
            <div
              class="row mb-3"
              [class.disableForm]="_conditionsService.readOnlyView_Client"
            >
              <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                <div class="btnCenter">
                  <div class="display-flex">
                    <label for="isHomeAddress1">
                      {{
                        "clientOnboarding.businessApplTab.businessAddressLabel"
                          | translate
                      }}</label
                    >
                    <input
                      id="isHomeAddress1"
                      type="radio"
                      class="m-1"
                      checked="checked"
                      *ngIf="_clintOnboardingSvc.isHomeAddress == true"
                    />
                    <input
                      id="isHomeAddress2"
                      type="radio"
                      class="m-1"
                      (click)="isHomeAdd(true)"
                      *ngIf="_clintOnboardingSvc.isHomeAddress == false"
                    />
                    <label for="isHomeAddress1">{{
                      "common.yes" | translate
                    }}</label>
                    <input
                      id="isHomeAddress1"
                      type="radio"
                      class="m-1"
                      checked="checked"
                      *ngIf="_clintOnboardingSvc.isHomeAddress == false"
                    />
                    <input
                      id="isHomeAddress2"
                      type="radio"
                      class="m-1"
                      (click)="isHomeAdd(false)"
                      *ngIf="_clintOnboardingSvc.isHomeAddress == true"
                    />
                    <label for="isHomeAddress2">
                      {{ "common.no" | translate }}</label
                    >
                  </div>
                </div>
              </div>
              <ng-container *ngIf="_clintOnboardingSvc.isHomeAddress == false">
                <div class="col-sm-6 col-md-6 col-lg-2 mb-3">
                  <div class="form-floating">
                    <input
                      id="hseNum"
                      type="text"
                      style="text-transform: capitalize"
                      autocomplete="new-hseNum"
                      class="form-control input-radius"
                      formControlName="hseNum"
                      minlength="1"
                      maxLength="40"
                      [class.is-invalid]="
                        hseNum.invalid && (hseNum.dirty || hseNum.touched)
                      "
                      required
                    />
                    <div
                      *ngIf="hseNum.invalid && (hseNum.dirty || hseNum.touched)"
                      class="invalid-feedback"
                    >
                      <div *ngIf="hseNum.errors?.['required']">
                        House No. is required.
                      </div>
                      <div *ngIf="hseNum.errors?.['minlength']">
                        This field must have at least 1 character.
                      </div>
                      <div *ngIf="hseNum.errors?.['maxlength']">
                        This field must have at most 40 characters.
                      </div>
                    </div>
                    <label for="hseNum">{{
                      "common.houseNo" | translate
                    }}</label>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-10 mb-3">
                  <div class="form-floating">
                    <input
                      id="strt"
                      type="text"
                      style="text-transform: capitalize"
                      autocomplete="new-strt"
                      class="form-control input-radius"
                      placeholder="Enter strt"
                      formControlName="strt"
                      minlength="1"
                      maxLength="40"
                      [class.is-invalid]="
                        strt.invalid && (strt.dirty || strt.touched)
                      "
                      required
                    />
                    <div
                      *ngIf="strt.invalid && (strt.dirty || strt.touched)"
                      class="invalid-feedback"
                    >
                      <div *ngIf="strt.errors?.['required']">
                        Street / Area is required.
                      </div>
                      <div *ngIf="strt.errors?.['minlength']">
                        This field must have at least 1 character.
                      </div>
                      <div *ngIf="strt.errors?.['maxlength']">
                        This field must have at most 40 characters.
                      </div>
                    </div>
                    <label for="strt">{{
                      "common.streetArea" | translate
                    }}</label>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                  <div class="form-floating">
                    <select
                      id="cmntySeq"
                      class="form-control input-radius form-select"
                      formControlName="cmntySeq"
                      [class.is-invalid]="
                        cmntySeq.invalid && (cmntySeq.dirty || cmntySeq.touched)
                      "
                    >
                      <option
                        *ngFor="
                          let item of _picklistService.COMMUNITY_TYPE_Data;
                          let i = index
                        "
                        [value]="item?.cmntySeq"
                      >
                        {{ item.cmntyNm }}
                      </option>
                    </select>
                    <div
                      *ngIf="
                        cmntySeq.invalid && (cmntySeq.dirty || cmntySeq.touched)
                      "
                      class="invalid-feedback"
                    >
                      <div *ngIf="cmntySeq.errors?.['required']">
                        Community is required.
                      </div>
                    </div>
                    <label for="cmntySeq">{{
                      "common.community" | translate
                    }}</label>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                  <div class="form-floating">
                    <input
                      id="vlg"
                      type="text"
                      style="text-transform: capitalize"
                      autocomplete="off"
                      class="form-control input-radius"
                      placeholder="Enter vlg"
                      formControlName="vlg"
                      minlength="3"
                      maxLength="100"
                      [class.is-invalid]="
                        vlg.invalid && (vlg.dirty || vlg.touched)
                      "
                      required
                    />
                    <div
                      *ngIf="vlg.invalid && (vlg.dirty || vlg.touched)"
                      class="invalid-feedback"
                    >
                      <div *ngIf="vlg.errors?.['required']">
                        Village is required.
                      </div>
                      <div *ngIf="vlg.errors?.['minlength']">
                        {{
                          "common.fieldMustHaveLeastThreeLetters" | translate
                        }}
                      </div>
                      <div *ngIf="vlg.errors?.['maxlength']">
                        {{
                          "common.fieldMustHaveAtMostHundredLetters" | translate
                        }}
                      </div>
                    </div>
                    <label for="vlg">{{ "common.village" | translate }}</label>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6 mb-3">
                  <div class="form-floating">
                    <input
                      id="othDtl"
                      type="text"
                      style="text-transform: capitalize"
                      autocomplete="off"
                      class="form-control input-radius"
                      placeholder="Enter othDtl"
                      formControlName="othDtl"
                      minlength="3"
                      maxLength="500"
                      [class.is-invalid]="
                        othDtl.invalid && (othDtl.dirty || othDtl.touched)
                      "
                      required
                    />
                    <div
                      *ngIf="othDtl.invalid && (othDtl.dirty || othDtl.touched)"
                      class="invalid-feedback"
                    >
                      <div *ngIf="othDtl.errors?.['required']">
                        Other Details is required.
                      </div>
                      <div *ngIf="othDtl.errors?.['minlength']">
                        {{
                          "common.fieldMustHaveLeastThreeLetters" | translate
                        }}
                      </div>
                      <div *ngIf="othDtl.errors?.['maxlength']">
                        This field must have at most 500 characters.
                      </div>
                    </div>
                    <label for="othDtl">{{
                      "common.otherDetails" | translate
                    }}</label>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                  <div
                    class="gray-Bg-icon"
                    (click)="openMod(area, 'Selected Area')"
                  >
                    <i class="fa fa-ellipsis-h"></i>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                  <div class="form-floating">
                    <input
                      id="districtName"
                      type="text"
                      style="text-transform: capitalize"
                      autocomplete="off"
                      class="form-control input-radius readonly-field"
                      formControlName="districtName"
                      required
                      readonly
                    />
                    <label for="districtName">{{
                      "common.district" | translate
                    }}</label>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                  <div class="form-floating">
                    <input
                      id="thslName"
                      type="text"
                      style="text-transform: capitalize"
                      autocomplete="off"
                      class="form-control input-radius readonly-field"
                      formControlName="thslName"
                      required
                      readonly
                    />
                    <label for="thslName">{{
                      "common.tehsil" | translate
                    }}</label>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                  <div class="form-floating">
                    <input
                      id="cityName"
                      type="text"
                      style="text-transform: capitalize"
                      autocomplete="off"
                      class="form-control input-radius readonly-field"
                      formControlName="cityName"
                      required
                      readonly
                    />
                    <label for="cityName">{{
                      "common.city" | translate
                    }}</label>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                  <div class="form-floating">
                    <input
                      id="ucName"
                      type="text"
                      style="text-transform: capitalize"
                      autocomplete="off"
                      class="form-control input-radius readonly-field"
                      formControlName="ucName"
                      required
                      readonly
                    />
                    <label for="ucName">{{ "common.uc" | translate }}</label>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                  <div class="form-floating">
                    <input
                      id="stateName"
                      type="text"
                      style="text-transform: capitalize"
                      autocomplete="off"
                      class="form-control input-radius readonly-field"
                      formControlName="stateName"
                      required
                      readonly
                    />
                    <label for="stateName">{{
                      "common.province" | translate
                    }}</label>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="row mb-3">
              <div class="col-sm-12 col-md-12 col-lg-12">
                <div class="button_div">
                  <button
                    class="btn login-button-class"
                    type="button"
                    (click)="showBtn(false, false, true, false, false)"
                    [disabled]="_sharedSvc.disableTabsForAddress"
                  >
                    {{ "common.continue" | translate }}
                  </button>
                  <button
                    class="btn login-button-class secondaryBtn"
                    type="button"
                    [class.pointerNone]="_sharedSvc.loading"
                    *ngIf="!_conditionsService.readOnlyView_Client"
                    [disabled]="
                      _sharedSvc.loading ||
                      _clintOnboardingSvc.areaFieldsInvalid
                    "
                    (click)="onSaveClick()"
                  >
                    {{ "common.save" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </ng-container>
        <ng-container *ngIf="additionalInformation">
          <form [formGroup]="additionalInfoForm">
            <div
              class="row mb-3"
              [class.disableForm]="_conditionsService.readOnlyView_Client"
            >
              <div
                *ngFor="
                  let item of _picklistService?.getAllQstn_data;
                  let i = index
                "
              >
                <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                  <div class="form-floating">
                    <select
                      [value]="_businessAppraisalSvc.quesArr[i]"
                      class="form-control input-radius form-select"
                      (change)="setAddInfoVal($event, i)"
                      [ngClass]="{
                        'is-invalid':
                          _businessAppraisalSvc.quesArr[i] == null && submitted
                      }"
                    >
                      <option hidden value="null"></option>
                      <option
                        *ngFor="let subItem of item?.answers; let j = index"
                        [value]="subItem?.answrSeq"
                      >
                        {{ subItem.answrStr }}
                      </option>
                    </select>
                    <label>{{ item?.qstStr }}</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-12 col-md-12 col-lg-12">
                <div class="button_div">
                  <button
                    class="btn login-button-class"
                    type="button"
                    (click)="showBtn(false, false, false, true, false)"
                  >
                    {{ "common.continue" | translate }}
                  </button>
                  <button
                    class="btn login-button-class secondaryBtn"
                    type="button"
                    *ngIf="!_conditionsService.readOnlyView_Client"
                    [class.pointerNone]="_sharedSvc.loading"
                    [disabled]="_sharedSvc.loading"
                    (click)="onSubmitAdditionalInfoFn()"
                  >
                    {{ "common.save" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </ng-container>
        <ng-container *ngIf="businessInc">
          <div class="row mb-3">
            <div class="col-sm-6 col-md-6 col-lg-6">
              <button class="btn infoBtn financialBtn1 mt-0">
                {{
                  "clientOnboarding.businessApplTab.totalBusinessIncome"
                    | translate
                }}
                :
                <span>{{
                  _finiancialInfoSvc?.totalIncmAmt | number : "1.0-0"
                }}</span>
              </button>
            </div>
            <div
              class="col-sm-6 col-md-6 col-lg-6"
              *ngIf="!_conditionsService.readOnlyView_Client"
            >
              <div
                class="add-button"
                (click)="
                  openModal(
                    businessIncome,
                    this.translate.instant(
                      'clientOnboarding.businessApplTab.addBusinessIncome'
                    )
                  )
                "
              >
                <i class="fa fa-plus"></i>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <td>
                        {{
                          "clientOnboarding.businessApplTab.businessIncomeType"
                            | translate
                        }}
                      </td>
                      <td>{{ "common.frequency" | translate }}</td>
                      <td>{{ "common.qty" | translate }}</td>
                      <td>
                        {{ "common.incomeAmount" | translate }}({{
                          "common.rs" | translate
                        }})
                      </td>
                      <td>
                        {{ "common.averageMonthlyAmount" | translate }}({{
                          "common.rs" | translate
                        }})
                      </td>
                      <td
                        class="text-center"
                        *ngIf="!_conditionsService.readOnlyView_Client"
                      >
                        {{ "common.action" | translate }}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let data of _finiancialInfoSvc.getClntAllIncmDtlSeqPG;
                        let i = index
                      "
                    >
                      <ng-container *ngIf="!data.delFlg">
                        <ng-container
                          *ngFor="
                            let item of _picklistService.BUSINESS_INCOME_TYPE_Data
                          "
                        >
                          <td *ngIf="item?.refCdSeq == data?.incmTypKey">
                            {{ item?.refCdDscr }}
                          </td>
                        </ng-container>
                        <ng-container
                          *ngFor="
                            let item of _picklistService.BUSINESS_FREQUENCY_Data
                          "
                        >
                          <td *ngIf="item?.refCdSeq == data?.frqncy">
                            {{ item?.refCdDscr }}
                          </td>
                        </ng-container>
                        <td>{{ data?.qnty | number : "1.0-0" }}</td>
                        <td>{{ data?.incmAmt | number : "1.0-0" }}</td>
                        <td>{{ data?.avgMnthlyIncm | number : "1.0-0" }}</td>
                        <td
                          class="text-center"
                          *ngIf="!_conditionsService.readOnlyView_Client"
                        >
                          <div class="action-inner" *ngIf="data.incmAmt">
                            <i
                              class="fa fa-pencil"
                              (click)="
                                edit(
                                  businessIncome,
                                  data,
                                  'Update Business Income',
                                  i
                                )
                              "
                            ></i>
                            <i
                              class="fa fa-trash"
                              (click)="DeletePopup_Income(data, i)"
                            ></i>
                          </div>
                        </td>
                      </ng-container>
                    </tr>
                  </tbody>
                </table>
                <div class="row g-0" *ngIf="_sharedSvc.pageSize > 10">
                  <div class="col-sm-12 col-md-12 col-lg-6">
                    <span class="clr-9d9a9a"
                      >{{ "common.showing" | translate }}
                      {{ _finiancialInfoSvc.getClntAllIncmDtlSeqPG?.length }}
                      {{ "common.of" | translate }}
                      {{ _sharedSvc.collectionSize }}
                      {{ "common.entries" | translate }}</span
                    >
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-6">
                    <div class="float-right">
                      <ngb-pagination
                        [(page)]="_sharedSvc.page"
                        [pageSize]="_sharedSvc.pageSize"
                        [maxSize]="10"
                        ngDefaultControl
                        [collectionSize]="_sharedSvc.collectionSize"
                        [rotate]="true"
                        (pageChange)="_businessAppraisalSvc.pageChange()"
                      >
                        <ng-template ngbPaginationPrevious>
                          {{ "common.prev" | translate }}</ng-template
                        >
                        <ng-template ngbPaginationNext>
                          {{ "common.next" | translate }}</ng-template
                        >
                      </ngb-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="button_div">
                <button
                  class="btn login-button-class"
                  type="button"
                  [disabled]="_conditionsService.savePartiallyINCData"
                  (click)="showBtn(false, false, false, false, true)"
                >
                  {{ "common.continue" | translate }}
                </button>
                <button
                  class="btn login-button-class secondaryBtn"
                  type="button"
                  *ngIf="!_conditionsService.readOnlyView_Client"
                  [disabled]="!_conditionsService.savePartiallyINCData"
                  [class.pointerNone]="_sharedSvc.loading"
                  (click)="saveClntIncmDtl()"
                >
                  {{ "common.save" | translate }}
                </button>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="businessExpenses">
          <div class="row mb-3">
            <div class="col-sm-6 col-md-6 col-lg-6">
              <button class="btn infoBtn financialBtn1 mt-0">
                {{
                  "clientOnboarding.businessApplTab.totalBusinessExpenses"
                    | translate
                }}
                <span>{{
                  _finiancialInfoSvc?.totalExpAmt | number : "1.0-0"
                }}</span>
              </button>
            </div>
            <div
              class="col-sm-6 col-md-6 col-lg-6"
              *ngIf="!_conditionsService.readOnlyView_Client"
            >
              <div
                class="add-button"
                (click)="
                  openModal(businessExpense, 'Add Business Expense', 'biz')
                "
              >
                <i class="fa fa-plus"></i>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <td>
                        {{
                          "clientOnboarding.businessApplTab.businessExpensesType"
                            | translate
                        }}
                      </td>
                      <td>
                        {{
                          "clientOnboarding.businessApplTab.expensesAmount"
                            | translate
                        }}&nbsp;({{ "common.rs" | translate }})
                      </td>
                      <td
                        class="text-center"
                        *ngIf="!_conditionsService.readOnlyView_Client"
                      >
                        {{ "common.action" | translate }}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let data of _finiancialInfoSvc.getClntAllExpnsDtlSeqPG;
                        let i = index
                      "
                    >
                      <ng-container *ngIf="!data.delFlg">
                        <ng-container
                          *ngFor="
                            let item of _picklistService.BUSINESS_EXPENSES_Data
                          "
                        >
                          <td *ngIf="item?.refCdSeq == data?.expTypKey">
                            {{ item?.refCdDscr }}
                          </td>
                        </ng-container>
                        <td>{{ data?.expAmt | number : "1.0-0" }}</td>
                        <td
                          class="text-center"
                          *ngIf="!_conditionsService.readOnlyView_Client"
                        >
                          <div class="action-inner">
                            <i
                              class="fa fa-pencil"
                              (click)="
                                onEditBE(
                                  businessExpense,
                                  data,
                                  'Update Business Expense',
                                  i
                                )
                              "
                            ></i>
                            <i
                              class="fa fa-trash"
                              (click)="DeletePopup_BE(data, i)"
                            ></i>
                          </div>
                        </td>
                      </ng-container>
                    </tr>
                  </tbody>
                </table>
                <div class="row g-0" *ngIf="_sharedSvc.pageSize > 10">
                  <div class="col-sm-12 col-md-12 col-lg-6">
                    <span class="clr-9d9a9a"
                      >{{ "common.showing" | translate }}
                      {{ _finiancialInfoSvc.getClntAllExpnsDtlSeqPG?.length }}
                      {{ "common.of" | translate }}
                      {{ _finiancialInfoSvc.getClntAllExpnsDtlSeqPG?.length }}
                      {{ "common.entries" | translate }}</span
                    >
                  </div>
                  <!-- <div class="col-sm-12 col-md-12 col-lg-6">
                    <div class="float-right">
                      <ngb-pagination [(page)]="_sharedSvc.page" [pageSize]="_sharedSvc.pageSize" [maxSize]="10"
                        ngDefaultControl [collectionSize]="_sharedSvc.collectionSize" [rotate]="true"
                        (pageChange)="_businessAppraisalSvc.pageChange()">
                        <ng-template ngbPaginationPrevious>Prev</ng-template>
                        <ng-template ngbPaginationNext>Next</ng-template>
                      </ngb-pagination>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="button_div">
                <button
                  class="btn login-button-class"
                  type="button"
                  [disabled]="_conditionsService.savePartiallyExpData"
                  (click)="_sharedSvc.active = 7"
                >
                  {{ "common.continue" | translate }}
                </button>
                <button
                  class="btn login-button-class secondaryBtn"
                  type="button"
                  *ngIf="!_conditionsService.readOnlyView_Client"
                  [disabled]="!_conditionsService.savePartiallyExpData"
                  [class.pointerNone]="_sharedSvc.loading"
                  (click)="saveClntExpnsDtl()"
                >
                  {{ "common.save" | translate }}
                </button>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</section>
<ng-template id="display-modal" #area let-c="close" let-d="dismiss">
  <div class="modal-body">
    <div class="p-3 pb-0">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center">
            <h4>{{ "common.selectedArea" | translate }}</h4>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="inside-icon">
            <i class="fa fa-search popupSearchi"></i>
            <input
              id="search"
              type="Search here"
              class="form-control input-radius search-input box-shadow"
              placeholder="{{ 'common.search' | translate }}"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="table-responsive loan-table">
          <table class="table table-bordered box-shadow">
            <thead>
              <tr>
                <td>{{ "common.uc" | translate }}</td>
                <td>{{ "common.tehsil" | translate }}</td>
                <td>{{ "common.city" | translate }}</td>
                <td>{{ "common.district" | translate }}</td>
                <td>{{ "common.province" | translate }}</td>
                <td class="text-center">{{ "common.action" | translate }}</td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of _picklistService.locationData">
                <td>{{ data?.ucName }}</td>
                <td>{{ data?.thslName }}</td>
                <td>{{ data?.cityName }}</td>
                <td>{{ data?.districtName }}</td>
                <td>{{ data?.stateName }}</td>
                <td class="text-center">
                  <i
                    class="fa-solid fa-share-from-square"
                    (click)="onSelectionArea(data)"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="button_div">
                        <button class="btn login-button-class" [class.pointerNone]="_sharedSvc.loading" [disabled]="_sharedSvc.loading">OK</button>
                        <button class="btn login-button-class cancelBtn" (click)="d('Cross click')" type="reset">CANCEL</button>
                    </div>
                </div>
            </div> -->
    </div>
  </div>
</ng-template>
<ng-template id="display-modal" #businessIncome let-c="close" let-d="dismiss">
  <div class="modal-body">
    <div class="p-3 pb-0">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center">
            <h4>{{ modelHeading }}</h4>
          </div>
        </div>
      </div>
      <form [formGroup]="businessIncomeForm">
        <div class="row mb-3">
          <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div class="form-floating">
              <select
                id="incmTypKey"
                class="form-control input-radius form-select"
                formControlName="incmTypKey"
                [class.is-invalid]="
                  incmTypKey.invalid && (incmTypKey.dirty || incmTypKey.touched)
                "
              >
                <option
                  *ngFor="
                    let item of _picklistService.BUSINESS_INCOME_TYPE_Data;
                    let i = index
                  "
                  [value]="item?.refCdSeq"
                >
                  {{ item.refCdDscr }}
                </option>
              </select>
              <div
                *ngIf="
                  incmTypKey.invalid && (incmTypKey.dirty || incmTypKey.touched)
                "
                class="invalid-feedback"
              >
                <div *ngIf="incmTypKey.errors?.['required']">
                  Income&nbsp;Type is required.
                </div>
              </div>
              <label for="incmTypKey">{{
                "common.incomeType" | translate
              }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div class="form-floating">
              <select
                id="frqncy"
                class="form-control input-radius form-select"
                [ngClass]="frqncy.invalid ? 'requiredInput' : ''"
                formControlName="frqncy"
                (change)="calAvgAmount($event, 'frqncy')"
                [class.is-invalid]="
                  frqncy.invalid && (frqncy.dirty || frqncy.touched)
                "
              >
                <option
                  *ngFor="
                    let item of _picklistService.BUSINESS_FREQUENCY_Data;
                    let i = index
                  "
                  [value]="item?.refCdSeq"
                >
                  {{ item.refCdDscr }}
                </option>
              </select>
              <div
                *ngIf="frqncy.invalid && (frqncy.dirty || frqncy.touched)"
                class="invalid-feedback"
              >
                <div *ngIf="frqncy.errors?.['required']">
                  {{ "common.frequencyRequire" | translate }}.
                </div>
              </div>
              <label for="frqncy">{{ "common.frequency" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div class="form-floating">
              <input
                id="qnty"
                type="number"
                class="form-control input-radius"
                [ngClass]="qnty.invalid ? 'requiredInput' : ''"
                formControlName="qnty"
                min="1"
                max="100"
                (input)="calAvgAmount($event, 'qnty')"
                [class.is-invalid]="
                  qnty.invalid && (qnty.dirty || qnty.touched)
                "
              />
              <div
                *ngIf="qnty.invalid && (qnty.dirty || qnty.touched)"
                class="invalid-feedback"
              >
                <div *ngIf="qnty.errors?.['required']">
                  {{ "common.quantityRequire" | translate }}
                </div>
                <div *ngIf="qnty.errors?.['min']">
                  {{ "common.field1DigitRequire" | translate }}
                </div>
                <div *ngIf="qnty.errors?.['max']">
                  {{ "common.field100DigitRequire" | translate }}
                </div>
              </div>
              <label for="qnty">{{ "common.quantity" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div class="form-floating">
              <input
                id="incmAmt"
                type="number"
                class="form-control input-radius"
                [ngClass]="incmAmt.invalid ? 'requiredInput' : ''"
                formControlName="incmAmt"
                min="1"
                maxLength="100000000"
                (input)="calAvgAmount($event, 'incmAmt')"
                [class.is-invalid]="
                  incmAmt.invalid && (incmAmt.dirty || incmAmt.touched)
                "
              />
              <div
                *ngIf="incmAmt.invalid && (incmAmt.dirty || incmAmt.touched)"
                class="invalid-feedback"
              >
                <div *ngIf="incmAmt.errors?.['required']">
                  Income Amount is required.
                </div>
                <div *ngIf="incmAmt.errors?.['min']">
                  {{ "common.field1DigitRequire" | translate }}
                </div>
                <div *ngIf="incmAmt.errors?.['max']">
                  This field must have at most 1,000,000 digit.
                </div>
              </div>
              <label for="incmAmt">{{
                "common.incomeAmount" | translate
              }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
            <div class="form-floating">
              <input
                id="avgMnthlyIncm"
                type="text"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius readonly-field"
                formControlName="avgMnthlyIncm"
                readonly
              />
              <label for="avgMnthlyIncm">{{
                "common.averageMonthlyAmount" | translate
              }}</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="button_div">
              <button
                class="btn login-button-class"
                *ngIf="!_businessAppraisalSvc.edit"
                [class.pointerNone]="_sharedSvc.loading"
                [disabled]="_sharedSvc.loading"
                type="button"
                (click)="onSubmitBusinessInc()"
              >
                {{ "common.save" | translate }}
              </button>
              <button
                class="btn login-button-class"
                *ngIf="_businessAppraisalSvc.edit"
                [class.pointerNone]="_sharedSvc.loading"
                [disabled]="_sharedSvc.loading"
                type="button"
                (click)="onUpdateBusinessIncome()"
              >
                {{ "common.update" | translate }}
              </button>
              <button
                class="btn login-button-class cancelBtn"
                type="reset"
                (click)="d('Cross click')"
                type="reset"
              >
                {{ "common.cancel" | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<ng-template id="display-modal" #businessExpense let-c="close" let-d="dismiss">
  <div class="modal-body">
    <div class="p-3 pb-0">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center">
            <h4>{{ modelHeading }}</h4>
          </div>
        </div>
      </div>
      <form [formGroup]="businessExpenseForm">
        <div class="row mb-3">
          <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div class="form-floating">
              <select
                id="expTypKey"
                class="form-control input-radius form-select"
                [ngClass]="expTypKey.invalid ? 'requiredInput' : ''"
                formControlName="expTypKey"
                [class.is-invalid]="
                  expTypKey.invalid && (expTypKey.dirty || expTypKey.touched)
                "
              >
                <option
                  *ngFor="
                    let item of _picklistService.BUSINESS_EXPENSES_DataClone;
                    let i = index
                  "
                  [value]="item?.refCdSeq"
                >
                  {{ item.refCdDscr }}
                </option>
              </select>
              <div
                *ngIf="
                  expTypKey.invalid && (expTypKey.dirty || expTypKey.touched)
                "
                class="invalid-feedback"
              >
                <div *ngIf="expTypKey.errors?.['required']">
                  Expense&nbsp;Type is required.
                </div>
              </div>
              <label for="expTypKey">{{
                "sideBarMenu.setup.expenseType" | translate
              }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div class="form-floating">
              <input
                id="expAmt"
                type="number"
                autocomplete="off"
                class="form-control input-radius"
                [ngClass]="expAmt.invalid ? 'requiredInput' : ''"
                formControlName="expAmt"
                min="1"
                maxLength="100000000"
                [class.is-invalid]="
                  expAmt.invalid && (expAmt.dirty || expAmt.touched)
                "
              />
              <div
                *ngIf="expAmt.invalid && (expAmt.dirty || expAmt.touched)"
                class="invalid-feedback"
              >
                <div *ngIf="expAmt.errors?.['required']">
                  Expense Amount is required.
                </div>
                <div *ngIf="expAmt.errors?.['min']">
                  {{ "common.field1DigitRequire" | translate }}
                </div>
                <div *ngIf="expAmt.errors?.['max']">
                  This field must have at most 1,000,000 digit.
                </div>
              </div>
              <label for="expAmt">{{
                "common.expenseAmount" | translate
              }}</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="button_div">
              <button
                class="btn login-button-class"
                *ngIf="!_businessAppraisalSvc.edit"
                [class.pointerNone]="_sharedSvc.loading"
                [disabled]="_sharedSvc.loading"
                type="button"
                (click)="onSubmitBusinessExp()"
              >
                {{ "common.save" | translate }}
              </button>
              <button
                class="btn login-button-class"
                *ngIf="_businessAppraisalSvc.edit"
                [class.pointerNone]="_sharedSvc.loading"
                [disabled]="_sharedSvc.loading"
                type="button"
                (click)="onUpdateBusinessExpense()"
              >
                {{ "common.update" | translate }}
              </button>
              <button
                class="btn login-button-class cancelBtn"
                type="reset"
                (click)="d('Cross click')"
                type="reset"
              >
                {{ "common.cancel" | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
