import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from '../../shared/service/shared.service';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';

import { ClintOnboardingService } from '../../clint-onboarding/shared/service/clint-onboarding.service';
import { CreditAppService } from '../../Individual-Group-Loan/add-individual-credit-app/client-credit-info/shared/service/credit-app.service';
import { DisbustmentCredit } from '../disbursement-form/shared/model/disbust-credit';
import { DisbursementFormService } from '../disbursement-form/shared/service/disbursement-form.service';
import { DisbustDetail } from '../disbursement-form/shared/model/disbustDetail';
import { SharedPopupService } from '../../shared/service/shared-popup.service';
import { PicklistService } from '../../shared/pickList/picklist.service';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'app-disbursement-listing',
  templateUrl: './disbursement-listing.component.html',
  styleUrls: ['./disbursement-listing.component.css']
})
export class DisbursementListingComponent implements OnInit {


  loadingIndicator: boolean = false;
  rows: any;

  page: number = 5;
  pageSize: number = 5;
  collectionSize: number = 5;

  loginForm!: FormGroup;
  submitted = false;
  // currentDate: any = new Date().toISOString().split("T")[0];
  onBoardingTablePG: any;
  maskedCNIC: string;
  saveCnicData: any;
  disbustmentList: DisbustmentCredit[];

  constructor(
    private modalService: NgbModal,
    public router: Router,
    public _sharedSvc: SharedService,
    public _clintOnboardingSvc: ClintOnboardingService,
    private formBuilder: FormBuilder,
    private dismtService: DisbursementFormService,
    public _sharedPopupSvc: SharedPopupService,
    public _picklistService: PicklistService,
    public translate: TranslateService,
  ) {

    this.dismtService.resetAll();
    this.dismtService.getAllCaApproList((res: DisbustmentCredit[]) => {
      // console.log(res);
      this.disbustmentList = res.filter(item => item.caStatus.toLowerCase() == "approved");
      //  let a= new DisbustmentCredit();
      //   a.aprvdAmount=40000;a.areaNm="LAHORE-03";a.bizAdr="House #: c Street: c ,ccc, LAHORE";a.bizExpns=1000;
      //   a.bizIncm=15000;a.bizOwn="Corporation";a.bizPrft=14000;a.bizPropOwn="Owned";a.bizSectNm="FOOD";a.brnchNm="ICHRA";
      //   a.caBdoName=null;a.caStatus="Approved";a.clntAdr="House #: c Street: c ,ccc, LAHORE";a.clntId="2ece91ccdf0";
      //   a.clntNm="new test";a.clntSeq=8765095065959;a.cmpnyAddr=null;a.cnicNum="3216546516464";a.crdtAppDate="2023-08-25 20:16:02.316827 +0:00";
      //   a.crdtAppId=null;a.crdtAppSeq=8765095065959440;a.crtdBy="devusr";a.cycleNumber=0;a.dbr1=8400;a.dbr2=12000;a.dsgntn=null;
      //   a.effLoanSize=100800;a.effMnthlyPymt=8400;a.empSal=null;a.emplNm=null;a.emplPhnNum=null;a.emplStartDate=null;
      //   a.hshldExpns=2000;a.instNumber=1;a.mnthInBiz="14";a.mnthsRes=14;a.mrtlStus="Single";a.ndi=13500;a.numOfChldrn=0;
      //   a.occupation="Self Employed";a.prevCrdtAmount=null;a.prmryIncm=1000;a.productName="Gold Fin Product";a.rcmndAmount=1;
      //   a.regNm="LAHORE REGION";a.rolSeq=4;a.rqstdAmount=10000;a.rsdnc="Owned";a.scndIncm=500;a.srvcChargeAmount=9200;
      //   a.totIncm=15500;a.totPrmryIncm=15000;a.userId="admUsr";
      //   this.disbustmentList.push(a);
      // console.log(this.disbustmentList );

    });
  }

  ngOnInit(): void {
    this._picklistService.APPLICATION_STATUS();
  }

  pageChange() {
    this.collectionSize = this._clintOnboardingSvc.onBoardingTable.length;
    this.onBoardingTablePG = this._clintOnboardingSvc.onBoardingTable.slice(
      (this.page - 1) * this.pageSize,
      (this.page - 1) * this.pageSize + this.pageSize
    );
    this.onBoardingTablePG;
  }

  navigate(i:number) {
    
    // localStorage.setItem('disbustment', JSON.stringify(this.disbustmentList[i]));
    this.dismtService.getCADetailById(this.disbustmentList[i].crdtAppSeq, (it: DisbustDetail) => {
      // console.log(it);
      if (it) {
        localStorage.setItem('disbustment', JSON.stringify(it));
        this.router.navigate(['/disbursement-form']);
      }
    });

  }
  navigateBackward(i: number) {
    this.router.navigate(['/individual-group-info']);
  }

  DeletePopup(data: any, index: any) {
    this._sharedPopupSvc.popup("", this.translate.instant('common.areSelectRecordDelete'), "", this.translate.instant('common.delete'), this.translate.instant('common.cancelSmall'), null, this.translate.instant('common.clientName') + " : " + data?.clntNm).then((confirmed: any) => {
      if (confirmed) {
        // Delete function call
        // console.log("Delete Success");
      }
      else {
        //in case of cancel
        return;
      }
    })
      .catch(() => {
        // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)');
        return;
      });
  }
}