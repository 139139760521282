<section>
    <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="mt-3 loan-table">
                <div class="row mb-3">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="btnCenter otherOutstanding">
                            <h3>PSC</h3>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12  mb-3">
                        <div class="btnCenter otherOutstanding">
                            <h6>{{'common.clintName' | translate}}: <b>Murtaza</b> / {{'common.clintId' | translate}}:
                                <b>001</b>
                            </h6>
                        </div>
                        <div class="btnCenter">
                            <span class="clr-9d9a9a">Aggregated Poverty Score: 52</span>
                        </div>
                    </div>
                </div>

                <ng-container>
                    <form>
                        <div class="row mb-3">

                            <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                                <div class="form-floating">
                                    <select class="form-control form-select input-radius">
                                        <option>{{'common.select' | translate}}</option>
                                        <option>{{'common.select' | translate}}</option>
                                        <option>{{'common.select' | translate}}</option>
                                    </select>
                                    <label>Family members of age < 18 and> 65 Years</label>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                                <div class="button_div">
                                    <button class="btn login-button-class">{{'common.continue' | translate}}</button>
                                    <button class=" btn login-button-class secondaryBtn">{{'common.save' |
                                        translate}}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </ng-container>
            </div>
        </div>
    </div>
</section>