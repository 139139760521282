import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CookieService } from 'ngx-cookie-service';
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { environment } from "src/app/global";
// import {  } from "src/app/components/shared/toast/toast.service";
import { DatePipe } from "@angular/common";
import { CustomCrudToastrService } from '../../shared/service/custom-crud-toastr.service';
import { SharedPopupService } from "./shared-popup.service";

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  openSidebar: boolean = true;
  minDueDate: any;
  maxDueDate: any;
  minDateofBirth: any;
  maxDateofBirth: any;
  currentDate: any;
  adminSubMenu: boolean = false;
  setupSubMenu: boolean = false;
  readOnlyView: any = false;
  saveForm: any = false;
  titleAr = '';
  toasterMessage = '';
  lanauge = 'en';
  placeholderTitle = 'Enter toastr title';
  placeholderMsg = 'Enter message';
  dobYear: any;
  calculatedDOB: any;
  currentDate_ = new Date();
  minDOB = { day: this.currentDate_.getDate(), month: this.currentDate_.getMonth() + 1, year: this.currentDate_.getFullYear() - 118 };
  maxDOB = { day: this.currentDate_.getDate(), month: this.currentDate_.getMonth() + 1, year: this.currentDate_.getFullYear() - 18 };
  cnicIssueDate = { day: this.currentDate_.getDate(), month: this.currentDate_.getMonth() + 1, year: this.currentDate_.getFullYear() - 10 };
  todayDate = { day: this.currentDate_.getDate(), month: this.currentDate_.getMonth() + 1, year: this.currentDate_.getFullYear() };
  threeMonthAft = { day: this.currentDate_.getDate(), month: this.currentDate_.getMonth() + 3, year: this.currentDate_.getFullYear() };
  cnicExpiryDate = { day: this.currentDate_.getDate(), month: this.currentDate_.getMonth() + 1, year: this.currentDate_.getFullYear() + 10 };

  loading: boolean = false;
  loading2: boolean = false;
  loading3: boolean = false;
  threeMonthAfter: any;

  page: number = 1;
  pageSize: number = 10;
  collectionSize: number = 0;

  msg: any = "Record added";
  updateMsg = "Record updated";
  deleteMsg = "Record deleted";
  authMsg = "Authorization Expired";

  active: any = 1;
  activeCredit: any = 1;

  // Variables For Show / hide forms

  personalInfo_FS: boolean = true;
  clientDoc_FS: boolean = false
  otherOutstanding_FS: boolean = false
  businessAppraisal_FS: boolean = false
  EmployerInfo_FS: boolean = false
  FinancialInfo_FS: boolean = false
  summary_FS: boolean = false;
  creditInfo_FS: boolean = false;

  requestOptions: any;

  disableTabsForAddress: any;

  constructor(
    public router: Router,
    private _http: HttpClient,
    private _cookieSvc: CookieService,
    public customCrudToaster: CustomCrudToastrService,
    public _sharedPopupSvc: SharedPopupService,
    // 
  ) { }

  tokenHeader() {
    this.requestOptions = null;
    let auth_token = sessionStorage.getItem('Token');
    if (auth_token) { }
    else { this.logout() }

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${auth_token}`
    });
    this.requestOptions = { headers: headers };
  }

  calculateDates() {
    const datePipe = new DatePipe('en-Us');
    this.currentDate = datePipe.transform(new Date(), 'yyyy-MM-dd');

    // let yearAgo10 = currentYear - 10;
    // this.minDueDate = yearAgo10 + "-01-01"
    // let yearAfter10 = currentYear + 10
    // this.maxDueDate = yearAfter10 + "-12-31"

    const yearAgo10 = new Date();
    yearAgo10.setFullYear(yearAgo10.getFullYear() - 10);
    this.minDueDate = datePipe.transform(yearAgo10, 'yyyy-MM-dd');

    const yearAfter10 = new Date();
    yearAfter10.setFullYear(yearAfter10.getFullYear() + 10);
    this.maxDueDate = datePipe.transform(yearAfter10, 'yyyy-MM-dd');

    const threeMonthAfter = new Date();
    threeMonthAfter.setMonth(threeMonthAfter.getMonth() + 3);
    this.threeMonthAfter = datePipe.transform(threeMonthAfter, 'yyyy-MM-dd');

    const yearAgo18 = new Date();
    yearAgo18.setFullYear(yearAgo18.getFullYear() - 18);
    this.maxDateofBirth = datePipe.transform(yearAgo18, 'yyyy-MM-dd');

    const date64YearsAgo = new Date();
    date64YearsAgo.setFullYear(date64YearsAgo.getFullYear() - 64);
    date64YearsAgo.setMonth(date64YearsAgo.getMonth() - 11);
    this.minDateofBirth = datePipe.transform(date64YearsAgo, 'yyyy-MM-dd');
  }

  calculateDOB(dob: any, callback?: any) {
    if (dob?.year) {
      dob = dob.year + '-' + dob.month + '-' + dob.day
    }

    if (dob) {
      const convertAge = new Date(dob);
      const timeDiff = Math.abs(Date.now() - convertAge.getTime());
      this.calculatedDOB = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
      if (callback) {
        return callback(this.calculatedDOB);
      }
    }
  }

  // Menu Conditions Start
  admin_subMenu() {
    this.setupSubMenu = false;
    this.adminSubMenu = !this.adminSubMenu;
  }
  setup_subMenu() {
    this.adminSubMenu = false;
    this.setupSubMenu = !this.setupSubMenu;
  }

  // Spinner Start
  showSpinner() {
    this.loading = true;
  }
  hideSpinner() {
    this.loading = false;
  }
  showSpinner2() {
    this.loading2 = true;
  }
  hideSpinner2() {
    this.loading2 = false;
  }
  showSpinner3() {
    this.loading3 = true;
  }
  hideSpinner3() {
    this.loading3 = false;
  }
  // Spinner END

  // Menu Conditions End

  // Toaster Conditions Start

  show(titleToaster: any) {
    this.customCrudToaster.lanauge = this.lanauge;
    this.customCrudToaster.show(
      titleToaster,
      this.titleAr,
      this.toasterMessage,
      this.toasterMessage,
      {
        closeButton: true,
      }
    );
  }
  success(titleToaster: any) {
    this.customCrudToaster.lanauge = this.lanauge;
    this.customCrudToaster.success(
      titleToaster,
      this.titleAr,
      this.toasterMessage,
      this.toasterMessage,
      {
        closeButton: true,
        // disableTimeOut: true,
        // autoDismiss: false,
        // preventDuplicates: true,
        // positionClass: 'toast-bottom-left'
      }
    );
  }
  error(titleToaster: any) {
    this.customCrudToaster.lanauge = this.lanauge;
    this.customCrudToaster.error(
      titleToaster,
      this.titleAr,
      this.toasterMessage,
      this.toasterMessage,
      {
        closeButton: true,
        // disableTimeOut: true,
        // autoDismiss: false,
        // preventDuplicates: true,
        // positionClass: 'toast-bottom-left'
      }
    );
  }
  info(titleToaster: any) {
    this.customCrudToaster.lanauge = this.lanauge;
    this.customCrudToaster.info(
      titleToaster,
      this.titleAr,
      this.toasterMessage,
      this.toasterMessage,
      {
        closeButton: true,
      }
    );
  }
  warning(titleToaster: any) {
    this.customCrudToaster.lanauge = this.lanauge;
    this.customCrudToaster.warning(
      titleToaster,
      this.titleAr,
      this.toasterMessage,
      this.toasterMessage,
      {
        closeButton: true,
      }
    );
  }
  successLong(titleToaster: any) {
    this.customCrudToaster.lanauge = this.lanauge;
    this.customCrudToaster.successLong(
      titleToaster,
      this.titleAr,
      this.toasterMessage,
      this.toasterMessage,
      {
        closeButton: true,
        disableTimeOut: true,
        autoDismiss: false,
        preventDuplicates: true,
        positionClass: 'toast-bottom-left'
      }
    );
  }
  changeLangue() {
    this.placeholderTitle =
      this.lanauge === 'en' ? 'Enter toaster title' : 'أدخل عنوان';
    this.placeholderMsg =
      this.lanauge === 'en' ? 'Enter message' : 'أدخل رسالة';
  }
  // Toaster Conditions End

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['/login']);
  }

  disTabForAddresstoggle() {
    if (this.disableTabsForAddress) {
      this._sharedPopupSvc.alert("", "Address not Saved !", "", "Ok", "Discard", null, "Please Save Business Address").then((confirmed: any) => {
        if (confirmed) {
          //in case of OK
        }
      })
        .catch(() => {
          // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)');
          return;
        });
    }
  }

}
