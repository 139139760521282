<section class="p-3">
  <div class="row mb-4">
    <div class="col-sm-8 col-md-8 col-lg-8">
      <h4>{{ "addProduct.associateProduct" | translate }}</h4>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6 col-md-6 col-lg-3">
      <div class="checkbox-wrapper custom-checkbox mb-4">
        <input id="demo" type="checkbox" class="form-check-input pointer" />
        <label for="demo" class="h6 ms-2 cursor-pointer">{{
          "common.demo" | translate
        }}</label>
      </div>
    </div>
  </div>
</section>

<div class="row mb-3">
  <div class="col-sm-12 col-md-12 col-lg-12">
    <div class="button_div mt-3">
      <button
        class="btn login-button-class secondaryBtn"
        [disabled]="_sharedSvc.loading"
        [class.pointerNone]="_sharedSvc.loading"
      >
        {{ "common.save" | translate }}
      </button>
    </div>
  </div>
</div>
