<section>
    <div class="sidebar" [class.sidebar-close]="!_sharedSvc.openSidebar">
        <app-sidebar></app-sidebar>
    </div>
    <div class="home-section">
        <div class="home-main" [class.sidebar-close_home-main]="!_sharedSvc.openSidebar">
            <app-navbar></app-navbar>

            <div class="home-view" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
                [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
                <section>
                    <div class="row mb-3">
                        <div class="col-sm-8 col-md-8 col-lg-8">
                            <h4>{{'sideBarMenu.admin.incomingBags' | translate}}</h4>
                        </div>
                        <div class="col-sm-4 col-md-4 col-lg-4">
                            <!-- <div class="add-button" (click)="openModal(addBag,null,null)"><i class="fa fa-plus"></i></div> -->
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <div class="dashboard-card loan-table">
                                <div class="row mb-3">
                                    <div class="col-sm-7 col-md-7 col-lg-8">
                                        <ng-container *ngIf="_sharedSvc.pageSize >= 10">
                                            <span>{{'common.show' | translate}}</span>
                                            <input type="number" class="m-1 text-center" style="width: 45px;">
                                            <span>{{'common.entries' | translate}}</span>
                                        </ng-container>
                                    </div>
                                    <div class="col-sm-5 col-md-5 col-lg-4">
                                        <div class="inside-icon">
                                            <i class="fa fa-search"></i>
                                            <input type="search" class="form-control search-input" [(ngModel)]="AppSeq"
                                                placeholder="{{'common.search' | translate}}">
                                        </div>
                                    </div>
                                </div>
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <td>{{'common.sr' | translate}}&nbsp;#.</td>
                                                <td>{{'incomingBag.disbursementDate' | translate}}</td>                                                
                                                <td>{{'incomingBag.creditID' | translate}}</td>
                                                <td>{{'common.status' | translate}}</td>
                                                <td>{{'common.customerName' | translate}}</td>
                                                <td>{{'incomingBag.collateralWeightValue' | translate}}</td>
                                                <td>{{'incomingBag.shroffID' | translate}}</td>
                                                <td>{{'common.bag' | translate}}&nbsp;#</td>
                                                <td>{{'common.collateralDetail' | translate}}</td>
                                                <td>{{'common.branch' | translate}}</td>
                                                <td>{{'incomingBag.vaultID' | translate}}</td>
                                                <td>{{'incomingBag.safe' | translate}}&nbsp;#</td>
                                                <td>{{'common.status' | translate}}&nbsp;</td>
                                                <td class="text-center">{{'common.action' | translate}}</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let b of bags | tableFilter: AppSeq; let i =index;">

                                                <td>{{i+1}}</td>
                                                <td>{{b.dsbmtDate!=null ? b.dsbmtDate.split("T")[0]:''}}</td>                                                
                                                <td>{{b.crdtAppSeq}}</td>
                                                <td>{{b.crdtAppStsKey}}</td>
                                                <td>{{b.clntName}}</td>
                                                <td>{{b.cltrlValue}}</td>
                                                <td>{{b.shrffId}}</td>
                                                <td>{{b.shrffBGNum}}</td>
                                                <td>{{b.cltrlDetails}}</td>
                                                <td>{{b.brnchName}}</td>
                                                <td>{{b.vaultId}}</td>
                                                <td>{{b.safeId}}</td>
                                                <td>{{b.cltrlLctnStsKey !== null?(b.cltrlLctnStsKey === 2541 ? 'Placed' :
                                                      (b.cltrlLctnStsKey === 2540 ? 'Open' : 'Complete'))
                                                    : ''}}</td>
                                                <td class="text-center">
                                                    <div class="action-inner">
                                                        <!-- <i class="fa-solid fa-pencil" ></i> -->
                                                        <!-- [ngClass]="{'disabled': hasSafe(i)}" -->
                                                        <!-- [ngClass]="{'disabled': !hasSafe(i)}" -->
                                                        <i class="fa-solid fa-vault"
                                                            [style.color]="b.cltrlLctnStsKey === 2541 ? 'red' : 'green'"
                                                            (click)="openModal(bagPlace, b.crdtAppSeq, 'add')">
                                                        </i>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</section>




<ng-template id="display-modal" #bagPlace let-c="close" let-d="dismiss">
    <div class="modal-body">
        <div class="p-3 pb-0">
            <div class="row mb-3">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="text-center">
                        <h4>Place Bag</h4>
                    </div>
                </div>
            </div>
            <form [formGroup]="BagPlaceForm" (ngSubmit)="submitBagPlaceForm()">                
                <div class="row mb-3">
                    <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                        <div class="form-floating">
                            <input type="text" class="form-control input-radius" formControlName="crdtAppSeq">
                            <label>Credit App ID</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                        <div class="form-floating">
                            <input type="text" class="form-control input-radius" formControlName="safeSeq">
                            <label>Safe ID</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                        <div class="form-floating">
                            <select class="form-control form-select input-radius" formControlName="statusKey"
                                [class.is-invalid]="statusKey.invalid && (statusKey.dirty || statusKey.touched)">
                                <option *ngFor="let item of _picklistService.BAG_PLACE_STATUS_LIST; let i = index"
                                    [value]="item?.refCdSeq">
                                    {{ item.refCdDscr | translate }}
                                </option>
                            </select>
                            <label>Status</label>
                        </div>
                    </div>
                </div>               
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="button_div">
                            <button class="btn login-button-class" [class.pointerNone]="_sharedSvc.loading"
                                [disabled]="_sharedSvc.loading">{{'common.save' | translate}}</button>
                            <button class="btn login-button-class cancelBtn" (click)="d('Cross click')"
                                type="reset">{{'common.cancel' | translate}}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>