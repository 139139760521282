
export class SavePayment {
    paymentTxnSeq: number;
    pymtTxnId: number;
    paymentDate: any;
    paymentAmount: any;
    parentPaymentReference: string;
    instrumentNumber: any;
    pymtTypeSequence: number;
    paymentStatusKey: any;
    deleteFlag: boolean;
    paymentReferenceSequence: number;
    paymentReferenceTypeKey: any;
    changeReasonKey: string;
    changeReasonComment: string;
    postFlag: boolean;
    depositSleepDate: any;
}

export class SaveExcsPayment {
    excsPymntSeq: number;
    clntSeq: number;
    excsAmount: any;
    rcvDate: any;
    epMethodKey: string;
    statusKey: number;
    statusDt: any;
    aprvdById: string;
    trxReference: string;
    crtdBy: string;
    crtdDt: any;
    lastUpdBy: string;
    lastUpdDt: any;
    delFlg: boolean
}
