// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// import { AuthConfig } from "angular-oauth2-oidc";

export const environment = {
  production: false,
  keycloakConfig : {
   url: 'http://65.109.51.132:8080',
    // url: 'http://65.109.51.132:8080/auth',
    // url: 'http://localhost:6656:8080/auth',
    // url:'http://65.109.51.132:8080/auth',
    realm: 'loanly',
    clientId: 'GoldFinWeb',
  }
};

// export const authConfig:AuthConfig= {
 
//    // issuer: 'http://65.109.51.132:8080/auth',
//     issuer: 'http://65.109.51.132:8080/auth',
//     // issuer: 'http://localhost:6656:8080/auth',
//     // issuer:'http://65.109.51.132:8080/auth',
//     redirectUri:window.location.origin,
//     responseType:'code',
//     strictDiscoveryDocumentValidation:true,
//     clientId: 'demo-pkce-client',
//     // scope:'openid profile email offline_access',
//    requireHttps:false
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.