export class Setup {

}

export class SetupTypes {
    typSeq: number
    typStr: string
    typStsKey: number
    typeId: string
    typCtgryKey: number
    perdFlg: boolean
    glAcctNum: string
    dfrdAcctNum: string
    brnchSeq: number
    bddtAcctNum: string
    crtdBy: string
    lastUpdDt: string
    crtdDt: string
    lastUpdBy: string
    delFlg: boolean
}
export class UserRole {
    userRoleSeq: 0
    userRoleName: string
    userRoleId: string
    userRoleComment: string
    delFlg: boolean 
    closingEffect: 1
    userRolePriority: 0
}
export class AppAccess {
  appAuthSeq: number
  userRoleSeq: number
  subModuleSeq: number
  readPermissionFlag: string
  writePermissionFlag: string
  deletePermissionFlag: string
  delFlg: boolean
  afterClosing: string
}

export class SetupReferance {
    refCdSeq: number
    grpSeq: number
    refCd: string
    refCdDscr: string
    sortOrder: number
    refCdActvFlg: boolean
    crtdBy: string
    crtdDt: string
    lastUpdBy: string
    lastUpdDt: string
    delFlg: boolean
}

export class SetupGroup {
    grpSeq: number
    grpId: string
    grpName: string
    grpDscr: string
    grpActvFlg: boolean
    crtdBy: string
    crtdDt: string
    lastUpdBy: string
    lastUpdDt: string
    delFlg: boolean
}
export class SetupBiz {
    tntKey: number
    bizSectSeq: number
    bizSectId: string
    bizSectNm: string
    bizSectStsKey: number
    bizSectSortOrder: number
    crtdBy: string
    crtdDt: string
    lastUpdBy: string
    lastUpdDt: string
    delFlg: boolean
}

export class SetupBizActivity {
    tntKey: number
    bizSectSeq: number
    bizActySeq: number
    bizActyId: string
    bizActyNm: string
    bizActySortOrder: string
    bizActyStsKey: number
    crtdBy: string
    crtdDt: string
    lastUpdBy: string
    lastUpdDt: string
    delFlg: boolean
}

export class SetupRiskTag {
    tagsSeq: number
    tagNm: string
    tagDscr: string
    blockFlag: boolean
    delFlg: boolean
    crtdDt: string
    crtdBy: string
    lastUpdDt: string
    lastUpdBy: string
}


export class SetupCity {
    citySequence: number
    cityCode: String
    cityDescription: String
    cityName: String
    cityStatusKey: string
    createdBy: string
    createdDate: string
    deletedFlag: boolean
    lastUpdatedBy: string
    lastUpdatedDate: string
    tenantKey: string

}

export class SetupCityUc {
    locationSequence: number
    citySequence: number
    unionCouncilSequence: number
    createdBy: string
    createdDate: string
    lastUpdatedBy: string
    lastUpdatedDate: string
    deletedFlag: boolean
    tenantKey: string
}

export class SetupShrof {
    shrfSeq: number
    shrfId: string
    firstName: string
    lastName: string
    cnicNum: number
    cnicExpryDt: string
    cnicIssueDt: string
    dob: string
    gndrKey: number
    shrofAddr: string
    phNum: string
    bizName: string
    bizPhonNumber: string
    bizAddress: string
    cmnt: string
    crtdBy: string
    crtdDt: string
    lastUpdBy: string
    lastUpdDt: string
    delFlg: boolean
    tenetKey: number
    stsKey: number

}
export class GetTypesOfSetup {
    typSeq: number
    typStr: string
    typStsKey: number
    typId: string
    typCtgryKey: number
    perdFlg: boolean
    glAcctNum: string
    dfrdAcctNum: string
    brnchSeq: number
    bddtAcctNum: string
    crtdBy: string
    lastUpdDt: string
    crtdDt: string
    lastUpdBy: string
    delFlg: boolean
}
export class GetRiskTagListofSetup {
    tagsSeq: number
    tagNm: string
    tagDscr: string
    blockFlag: boolean
    delFlg: boolean
    crtdDt: string
    crtdBy: string
    lastUpdDt: string
    lastUpdBy: string
}
export class GetCityUcListOfSetup {
    locationSequence: number
    citySequence: number
    unionCouncilSequence: number
    createdBy: string
    createdDate: string
    lastUpdatedBy: string
    lastUpdatedDate: string
    deletedFlag: boolean
    tenantKey: string
}
export class GetCityListOfSetup {
    citySequence: number
    cityCode: string
    cityDescription: string
    cityName: string
    cityStatusKey: string
    createdBy: string
    createdDate: string
    deletedFlag: boolean
    lastUpdatedBy: string
    lastUpdatedDate: string
    tenantKey: string
}
export class SetupVault {
    vaultSeq: number
    vaultId: string
    description: string
    locationKey: number
    saveCount: number
    crtdBy: string
    crtdDt: string
    lastUpdBy: string
    lastUpdDt: string
    delFlg: boolean
}


export class saveRegionModel {
    regionCode: string
    regionSequence: number
    regionName: string
    regionDescription: string
    regionStatusKey: number
    delFlg: boolean
}
export class saveAreaModel {
    areaSequence: number
    areaName: string
    areaDescription: string
    areaStatusKey: number
    delFlg: boolean
    regionSequence: number
    areaCode: string
}
export class saveBranchModel {
    branchSequence: number
    branchTypeKey: string
    branchStatusKey: string
    branchName: string
    delFlg: boolean
    branchDescription: string
    branchCode: string
    areaSequence: number
    applicationVersionCode: string
}
export class saveBranchAccountModel {
    branchAccountSetSequence: number
    onlineFlag: boolean
    delFlg: boolean
    branchSequence: number
    bankName: string
    bankBranch: string
    accountNumber: string
    accountName: string
    internationalBankAccountNumber: string
}
export class saveBranchProductModel {
    branchProductRelSequence: number
    productSequence: number
    branchSequence: number
    delFlg: boolean
}
export class saveBranchLocationModel {
    branchLocationRelSequence: number
    locationSequence: number
    branchSequence: number
    delFlg: boolean
}
export class savePortfolioModel {
    portSequence: number
    portCode: string
    branchSequence: number
    delFlg: boolean
    portDescription: string
    portName: string
    portStatusKey: string
}
export class saveCommunityModel {
    cmntyCd: string
    cmntyNm: string
    cmntyCmnt: string
    cmntyStsKey: number
    portSeq: number
    delFlg: boolean
}

export class saveGeoCountryModel {
    tntKey: string
    cntryNm: string
    cntryDesc: string
    cntryStsKey: string
    cntryCd: string
    cntrySeq: number
    delFlg: boolean
}
export class saveGeoStateModel {
    tenantKey: string
    stateName: string
    stateDescription: string
    stateStatusKey: string
    stateCode: string
    stateSequence: number
    countrySequence: number
    delFlg: boolean
}
export class saveGeoDistModel {
    tenantKey: string
    districtName: string
    districtDescription: string
    districtStatusKey: string
    districtCode: string
    districtSequence: number
    stateSequence: number
    delFlg: boolean
}
export class saveGeotehsilModel {
    tenantKey: string
    tehsilName: string
    tehsilDescription: string
    tehsilStatusKey: string
    tehsilCode: string
    tehsilSequence: number
    districtSequence: number
    delFlg: boolean
}
export class saveGeoUCModel {
    tenantKey: string
    ucName: string
    ucDescription: string
    ucStatusKey: string
    ucCode: string
    ucSequence: number
    tehsilSequence: number
    delFlg: boolean
}
export class saveQuestionnaireModel {
    qstnrSeq: number
    qstnrNm: string
    qstnrId: string
    qstnrStsKey: number
    qstnrLvlKey: number
    qstnrCtgryKey: number
    qstnrRul: string
    delFlg: boolean
}
export class saveQuestionModel {
    qstSeq: number
    qstStr: string
    qstCtgryKey: number
    qstSortOrdr: number
    qstStsKey: number
    qstTypKey: number
    qstnrSeq: number
    delFlg: boolean
}
export class saveAnswerModel {
    answrSeq: number
    answrId: string
    answrScore: number
    answrStr: string
    answrStsKey: string
    qstSeq: number
    tntKey: number
    delFlg: boolean
}

export class saveRegionEMPModel {
    regEmpSequence: number
    regionSequence: number
    coveringEmpSequence: number
    employeeSequence: number
    delFlg: boolean
}
export class saveAreaEMPModel {
    areaEmployeeSequence: number
    areaSequence: number
    employeeSequence: number
    coveringEmployeeSequence: number
    delFlg: boolean
}
export class saveBranchEMPModel {
    branchEmployeeSequence: number
    employeeSequence: number
    coveringEmployeeSequence: number
    coveringEmployeeToDate: string
    coveringEmployeeFromDate: string
    delFlg: boolean
    branchSequence: number
    rolSeq: number
}
export class savePortEMPModel {
    portEmpSequence: number
    employeeSequence: number
    coveringEmpSequence: number
    coveringEmpToDate: string
    coveringEmpFromDate: string
    delFlg: boolean
    portSequence: number
}

export class saveBagModel {
    bagSeq: number
    bagNum: number
    bagTypKey: number
    bagStsKey: number
    brnchSeq: number
    delFlg: boolean
}


//products page Models
export class saveProductModel{
    prdSeq: number
    fndByKey: number
    irrFlg: number
    irrVal: number
    mltCrdtFlg: number
    pdcNum: number
    prdCmnt: string
    prdGrpSeq: number
    prdId: string
    prdNm: string
    prdTypKey: number
    rndngAdj: number
    rndngScl: number
    delFlg: boolean
}

export class saveProductSgrtInstModel{
    prdSgrntInstSeq: number
    delFlg: boolean
    entyTypStr: string
    instNum: number
    sgrntEntySeq: number
}
export class saveProductRulModel{
    prdAdvRulSeq: number
    delFlg: boolean
    prdSeq: number
    rulSeq: number
}
export class saveProductPalModel{
    prdPpalLmtSeq: number
    delFlg: boolean
    maxAmt: number
    minAmt: number
    prdSeq: number
    rulSeq: number
    sgrtInstNum: number
}
export class saveProductGrpModel{
    prdGrpSeq: number
    prdGrpId: string
    prdGrpNm: string
    prdGrpStsKey: number
    tntKey: number
    prdGrpSts: number
    delFlg: boolean
}
export class saveProductChrgModel{
    prdChrgSeq: number
    prdSeq: number
    chrgTypSeq: number
    rulSeq: number
    calcTypKey: number
    calcOfChrg: number
    calcOrdr: number
    chrgPymntTypKey: number
    chrgVal: number
    adjRndngFlg: number
    sgrtInstNum: number
    delFlg: boolean
    adjustRoundingFlg: boolean
    chrgCalcTypKey: number
    chrgTypFlg: number
}
export class saveProductChrgTrmModel{
    prdTrmSeq: number
    delFlg: boolean
    prdSeq: number
    pymtFreqKey: number
    rulSeq: number
    trmKey: number
}
export class saveProductChrgSlbModel{
    prdChrgSlbSeq: number
    delFlg: boolean
    endLmt: number
    prdChrgSeq: number
    startLmt: number
    val: string
}








// export class GetShrofList {
//     shrfSeq: number
//     shrfId: string
//     firstName: string
//     lastName: string
//     cnicNum: string
//     cnicExpryDt: string
//     cnicIssueDt: string
//     dob: string
//     gndrKey: string
//     shrofAddr: string
//     phNum: string
//     bizName: string
//     bizPhonNumber: string
//     bizAddress: string
//     cmnt: string
//     crtdBy: string
//     crtdDt: string
//     lastUpdBy: string
//     lastUpdDt: string
//     delFlg: boolean
//     tenetKey: number
//     stsKey: number

// }









