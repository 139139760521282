<div class="modal-header">
    <h4> {{'common.delete' | translate}}</h4>
    <div class="header_cross" (click)="close()">
        <h2>&times;</h2>
    </div>
</div>

<div class="modal-body">
    <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="text-center">
                <h6>{{message}}</h6>
                <p *ngIf="contractNametoDelete">{{contractNametoDelete}}</p>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="button_div mt-3">
                <button class="btn login-button-class" (click)="deleteContract()">{{btnOkText}}</button>
                <button class="btn login-button-class cancelBtn" (click)="close()">{{btnCancelText}}</button>
            </div>
        </div>
    </div>

</div>