import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { SharedService } from 'src/app/components/shared/service/shared.service';
import { CreditAppService } from 'src/app/components/Individual-Group-Loan/add-individual-credit-app/client-credit-info/shared/service/credit-app.service';
import { DisbursementVoucher } from '../shared/model/disbust-vocher';
import { DisbursementFormService } from '../shared/service/disbursement-form.service';
import { ClintOnboardingService } from 'src/app/components/clint-onboarding/shared/service/clint-onboarding.service';
import { DisbustDetail } from '../shared/model/disbustDetail';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-disbursement-voucher',
  templateUrl: './disbursement-voucher.component.html',
  styleUrls: ['./disbursement-voucher.component.css']
})
export class DisbursementVoucherComponent implements OnInit {

  voucher_form: FormGroup;
  tableData2: any
  disbustmentVocher: DisbursementVoucher[];
  disbut: DisbustDetail;
  totalAmount = 0;
  selectedItem = -1;
  currentDate: any = new Date().toISOString().split("T")[0];
  constructor(
    private modalService: NgbModal,
    public router: Router,
    private formBuilder: FormBuilder,
    private _creditAppSvc: CreditAppService,
    private dismtService: DisbursementFormService,
    public _clintOnboardingSvc: ClintOnboardingService,
    public _sharedSvc: SharedService,
    public translate: TranslateService
  ) {
    // this.voucher_form=new FormGroup({
    //   amount: new FormControl(null, [Validators.minLength(3), Validators.required]),
    //   instrNum: new FormControl(null, [Validators.required, Validators.minLength(12),Validators.maxLength(12) ]),
    //   pymtType: new FormControl (null, [Validators.required])
    // });

    this.disbustmentVocher = this.dismtService.disbustmentVocher;
    /* exrtact disbustment object*/
    let c = localStorage.getItem('disbustment');
    if (c)
      this.disbut = JSON.parse(c);


    /*calculate total disbutment && update flags*/
    this.disbustmentVocher.forEach(item => this.totalAmount + item.dsbmtAmount);
    if (this.totalAmount == this.disbut.aprvdAmount) { this.dismtService.disContinueButton = false; this.dismtService.vocherValidated = true; }

    /*get disbumentSeq */
    this.dismtService.getDbmtInfo(this.disbut.crdtAppSeq, (item) => {

      /* populate FirstIntallment date*/
      this.dismtService.disbustment.firstInstallmentDate = item.firstInstDate?.split("T")[0];

      /*getVoucherList */
      if (item.dsbmtSeq) { } else { return; }
      this.dismtService.getDbsmtVocherList(item.dsbmtSeq, (result: DisbursementVoucher[]) => {
        if (result) {
          /* if have result reset values */
          this.dismtService.disbustmentVocher.length = 0; this.totalAmount = 0;

          /* re-populate list by server,recalcute amount,button check*/
          result.forEach(r => {
            this.dismtService.disbustmentVocher.push(r);
            this.totalAmount += r.dsbmtAmount; this.activeCountiue();
          });
        }
      })
    })
  }

  ngOnInit(): void {


  }


  openModal(content: any) {
    this.selectedItem = -1;
    localStorage.removeItem("selectedOrderData");
    this.voucher_form = this.getFormVocher();
    this.modalService.open(content, { windowClass: "popupWidth800", centered: true });
  }

  onSubmiVocherForm(): void {
    /*validate form */
    if (!this.voucher_form.valid) {
      this.voucher_form.markAllAsTouched();
      return;
    }

    /*add new vocher */
    let v = new DisbursementVoucher();
    /*update object */
    if (this.selectedItem >= 0) v = this.disbustmentVocher[this.selectedItem];

    /*setting values */
    v.dsbmtAmount = this.amount.value;
    this.totalAmount = this.totalAmount + parseFloat(v.dsbmtAmount + "")
    v.instrNum = this.instrNum.value;
    v.pymtType = this.pymtType.value
    v.pymtTypSeq = 1;
    v.changed = true;

    /* replace if edit else add*/
    if (this.selectedItem >= 0) this.disbustmentVocher[this.selectedItem] = v;
    else this.disbustmentVocher.push(v);
    /*flag to check update request or new */
    this.selectedItem = -1;

    /*verify Total disbutment amount & buttonenable */
    if (this.totalAmount == this.disbut.aprvdAmount) {
      // this.dismtService.disContinueButton=false;
      this.dismtService.vocherValidated = true;
      this.activeCountiue();
      this.activeSave();
      // let changed= this.disbustmentVocher.filter(v=>v.changed==true )
      // if(changed && changed.length>0) this.dismtService.saveButton=false;
    } else { this.dismtService.saveButton = true; this.dismtService.disContinueButton = true }
    this.modalService.dismissAll('Save click');

  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57)) {
      return false;
    }
    // this.validateAmount();
    let ph = this.instrNum.value;
    if (ph && ph.length == 1 && ph.substring(0) != '0')
      this.instrNum.setValue("0");
    if (ph && ph.length == 2 && ph.substring(0, 1) != '03')
      this.instrNum.setValue("03");

    return true;
  }

  get amount() { return this.voucher_form.get("amount") }
  get instrNum() { return this.voucher_form.get("instrNum") }
  get pymtType() { return this.voucher_form.get("pymtType") }

  /*create form controller */
  getFormVocher() {
    return new FormGroup({
      amount: new FormControl(null, [Validators.minLength(3), Validators.required]),
      instrNum: new FormControl(null, [Validators.required, Validators.minLength(12), Validators.maxLength(12)]),
      pymtType: new FormControl(null, [Validators.required])
    });
  }

  /*validate amount for model*/
  validateAmount() {
    // console.log(this.selectedItem)
    if (this.selectedItem < 0)
      if (this.totalAmount + parseFloat(this.amount.value) > this.disbut.aprvdAmount)
        this.amount.setValue(this.disbut.aprvdAmount - this.totalAmount)
  }

  /*edit Model*/
  editModal(content: any, i: number) {
    this.selectedItem = i;
    localStorage.removeItem("selectedOrderData");
    this.voucher_form = this.editFormVocher(i);
    this.modalService.open(content, {
      windowClass: "popupWidth800",
      centered: true
    });
  }

  /*create formContorller for edit */
  editFormVocher(i: number) {
    let v = this.disbustmentVocher[i];
    this.totalAmount = this.totalAmount - v.dsbmtAmount;
    return new FormGroup({
       amount: new FormControl(v.dsbmtAmount, [Validators.minLength(3), Validators.required]),
       instrNum: new FormControl(v.instrNum.trim(), [Validators.required, Validators.minLength(11),Validators.maxLength(12) ]),
       pymtType: new FormControl (v.pymtType, [Validators.required])
     });
   }

  /*disable add button*/
  disableAdd() { return this.totalAmount >= this.disbut.aprvdAmount; }

  /*soft delete row */
  markAsDelete(i) {
    let v = this.disbustmentVocher[i];
    v.delFlg = true;
    v.changed = true;
    this.totalAmount -= v.dsbmtAmount;
    this.dismtService.saveButton = false;
    this.dismtService.disbustmentVocher[i] = v;
    this.activeCountiue();
  }

  disableAction(i) { return this.disbustmentVocher[i].delFlg; }

  activeCountiue() {
    if (this.totalAmount == this.disbut.aprvdAmount) this.dismtService.disContinueButton = false;
    else this.dismtService.disContinueButton = true;
  }

  activeSave() {
    if (this.totalAmount == this.disbut.aprvdAmount) {
      let changed = this.disbustmentVocher.filter(v => v.changed == true)
      if (changed && changed.length > 0) this.dismtService.saveButton = false;
    }
  }
}