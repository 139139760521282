export class ClientStatus {
  clntSeq: number;
  status: number;
}
export class CNICValidation {
  cnicNum: any;
  issueDate: any; // = new Date().toISOString().split("T")[0];
  expiryDate: any; //= new Date().toISOString().split("T")[0];
  typ: string = "0";
}
export class SaveClient {
  clntSeq: number;
  tenetKey: number;
  clntId: string;
  cnicNum: any;
  cnicExpryDt: any;
  cnicIssueDt: any;
  frstNm: string;
  lastNm: string;
  nickNm: string;
  mthrMadnNm: string;
  phNum: any;
  dob: any;
  gndrKey: number;
  mrtlStsKey: number;
  eduLvlKey: number;
  natrOfDisKey: number | null;
  occKey: number;
  clntStsKey: number;
  resTypKey: number;
  mnthsRes: number;
  fthrSpzFrstNm: string;
  fthrSpzLastNm: string;
  portKey: number;
  householdMembers: any;
  numberOfDependents: any;
  numberOfChildren: any;
  selfPdc: number;
  walletNumber: any;
  customerTypeKey: number;
  prmAddr: string;
  delFlg: boolean;
}

export class Address {
  clntSeq: number;
  addrSeq: number;
  addrRelSeq: number;
  delFlg: boolean;
  locSeq: number;
  hseNum: string;
  strt: string;
  cmntySeq: number;
  vlg: string;
  othDtl: string;
  latitude: number;
  longitude: number;
  mnthsOfResidence: any;
  isPermAddress: boolean;
  entySeq: any;
  entyType: any;
  yearsOfResidence?: any;
}
