<section>
    <div class="sidebar" [class.sidebar-close]="!_sharedSvc.openSidebar">
        <app-sidebar></app-sidebar>
    </div>
    <div class="home-section">
        <div class="home-main" [class.sidebar-close_home-main]="!_sharedSvc.openSidebar">
            <app-navbar></app-navbar>
            <div class="home-view">
                <section class="roll-over-section">
                    <div class="row mb-3">
                        <div class="col-sm-8 col-md-8 col-lg-8">
                            <h4>Roll Over</h4>
                        </div>

                    </div>
                    <div class="row ">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <div class="dashboard-card loan-table">
                                <div class="client-info-section">
                                    <div>
                                        <h5 class="mb-2">Client Info</h5>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-sm-12 col-md-12 col-lg-12">
                                            <div class="table-responsive">
                                                <table class="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <td>{{'common.clientId' | translate}}</td>
                                                            <td>Name</td>
                                                            <td>CNIC</td>
                                                            <td>{{'common.address' | translate}}</td>
                                                            <td>DOB</td>
                                                            <td>Picture</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>1</td>
                                                            <td>John</td>
                                                            <td>35201-5695681-3</td>
                                                            <td>Address will be place here</td>
                                                            <td>31/12/20233</td>
                                                            <td>Pic </td>

                                                        </tr>
                                                    </tbody>
                                                </table>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="credit-info-section ">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <h5 class="mb-2">Credit Info</h5>
                                        <div class="bluebtns">
                                            <span class="secondaryBtn">Current Market Rate: 5000</span>
                                            <span class="secondaryBtn">Current Collateral Value: 5000</span>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-sm-12 col-md-12 col-lg-12">
                                            <div class="table-responsive">
                                                <table class="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <td>Credit ID</td>
                                                            <td>Disbursment Date</td>
                                                            <td>Disbursed Amount</td>
                                                            <td>Due Date</td>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>1</td>
                                                            <td>13/09/2023</td>
                                                            <td>100,000</td>
                                                            <td>13/09/2023</td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="collateral-info-section ">
                                    <div>
                                        <h5 class="mb-2">Collateral Info</h5>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-sm-12 col-md-12 col-lg-12">
                                            <div class="table-responsive">
                                                <table class="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <td>Location</td>
                                                            <td>Vault and Safe</td>
                                                            <td>{{'common.quantity' | translate}}</td>
                                                            <td>{{'common.rate' | translate}}</td>
                                                            <td>Value</td>
                                                            <td>Bag #</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Location will be place here</td>
                                                            <td>Vault and Safe</td>
                                                            <td>2</td>
                                                            <td>2</td>
                                                            <td>Value </td>
                                                            <td>Bag Number</td>

                                                        </tr>
                                                    </tbody>
                                                </table>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="pyamentSection">
                                    <div class="row">
                                        <div class="col-6">
                                            <h5 class="mb-2">New Credit Info</h5>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-floating">
                                                        <input type="number" style="text-transform: capitalize;"
                                                            autocomplete="off" class="form-control input-radius">

                                                        <label>Requested Amount</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-floating">
                                                        <input type="number" style="text-transform: capitalize;"
                                                            autocomplete="off" class="form-control input-radius">

                                                        <label>Recommended Amount</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <h5 class="mb-2">Payment Info</h5>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-floating">
                                                        <input type="number" style="text-transform: capitalize;"
                                                            autocomplete="off" class="form-control input-radius">

                                                        <label>Service Charges</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-floating">
                                                        <input type="number" style="text-transform: capitalize;"
                                                            autocomplete="off" class="form-control input-radius">

                                                        <label>Processing Fee</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="text-center mb-3">
                                        <button class="btn login-button-class me-3">PAYMENT</button>
                                        <button type="button" class="btn login-button-class secondaryBtn">
                                            {{'common.submit' | translate}}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</section>

<ng-template id="display-modal" #branch let-c="close" let-d="dismiss">
    <div class="modal-body">
        <div class="p-3 pb-0">
            <div class="row mb-3">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="text-center">
                        <h4>Popup</h4>
                    </div>
                </div>
            </div>
            <form>
                <div class="row mb-3">
                    <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
                        <div class="form-floating">
                            <select class="form-control form-select input-radius">
                                <option>{{'common.select' | translate}}</option>
                                <option>{{'common.select' | translate}}</option>
                                <option>{{'common.select' | translate}}</option>
                            </select>
                            <label>{{'common.select' | translate}}</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
                        <div class="form-floating">
                            <input type="text" style="text-transform: capitalize;" autocomplete="off"
                                class="form-control input-radius">
                            <label>{{'common.select' | translate}}</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="button_div">
                            <button class="btn login-button-class" [class.pointerNone]="_sharedSvc.loading"
                                [disabled]="_sharedSvc.loading">{{'common.ok' | translate}}</button>
                            <button class="btn login-button-class cancelBtn" (click)="d('Cross click')"
                                type="reset">{{'common.cancel' | translate}}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>