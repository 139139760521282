import { Component } from '@angular/core';
import { SharedService } from '../../shared/service/shared.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent {
  constructor(
    public _sharedSvc: SharedService,
    public translate: TranslateService
  ) { }
}
