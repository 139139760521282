import { Component, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SharedService } from "../../shared/service/shared.service";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
  NgForm,
} from "@angular/forms";

import { ClintOnboardingService } from "../../clint-onboarding/shared/service/clint-onboarding.service";
import { SetupService } from "../shared/service/setup.service";
import { PicklistService } from "src/app/components/shared/pickList/picklist.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-questionnair",
  templateUrl: "./questionnair.component.html",
  styleUrls: ["./questionnair.component.css"],
})
export class QuestionnairComponent implements OnInit {
  page: number = 5;
  pageSize: number = 5;
  collectionSize: number = 5;

  loginForm!: FormGroup;
  submitted = false;
  // currentDate: any = new Date().toISOString().split("T")[0];
  onBoardingTablePG: any;
  maskedCNIC: string;
  userValue: any;

  singleRecord: any;
  isEdit: boolean = false;
  qstnrFormdata: any;

  constructor(
    private modalService: NgbModal,
    public router: Router,
    public _sharedSvc: SharedService,
    public _clintOnboardingSvc: ClintOnboardingService,
    public _SetupService: SetupService,
    private formBuilder: FormBuilder,
    public _picklistService: PicklistService,
    public translate: TranslateService
  ) {
    this.userValue = this.formBuilder.group({});
  }

  ngOnInit(): void {
    this.qstnrFormdata = this.formBuilder.group({
      qstnrNm: ["", Validators.required],
      //qstnrId: ['', Validators.required],
      qstnrStsKey: ["", Validators.required],
      qstnrLvlKey: ["", Validators.required],
      qstnrCtgryKey: ["", Validators.required],
      qstnrRul: ["", Validators.required],
    });

    this._SetupService.get_qstnr_list();
    this._picklistService.SHROFF_STATUS();
  }
  get qstnrNm() {
    return this.qstnrFormdata.get("qstnrNm");
  }
  //get qstnrId() { return this.qstnrId.get('qstnrId') }
  get qstnrStsKey() {
    return this.qstnrFormdata.get("qstnrStsKey");
  }
  get qstnrLvlKey() {
    return this.qstnrFormdata.get("qstnrLvlKey");
  }
  get qstnrCtgryKey() {
    return this.qstnrFormdata.get("qstnrCtgryKey");
  }
  get qstnrRul() {
    return this.qstnrFormdata.get("qstnrRul");
  }

  openQstnrModal(content: any, edit: boolean) {
    localStorage.removeItem("selectedOrderData");
    if (!edit) {
      this.qstnrFormdata.reset();
    }
    this.isEdit = edit;
    this.modalService.open(content, {
      // backdrop: "static",
      // keyboard: false,
      windowClass: "popupWidth800",
      // backdropClass: 'light-blue-backdrop',
      centered: true,
    });
  }

  submitQstnr(val: any) {
    if (this.qstnrFormdata.invalid) {
      for (const control of Object.keys(this.qstnrFormdata.controls)) {
        this.qstnrFormdata.controls[control].markAsTouched();
      }
      return;
    }
    let data = {
      ...this.qstnrFormdata.value,
      qstnrSeq: null,
      qstnrId: null,
      qstnrStsKey: Number(this.qstnrFormdata.value.qstnrStsKey),
      qstnrLvlKey: Number(this.qstnrFormdata.value.qstnrLvlKey),
      qstnrCtgryKey: Number(this.qstnrFormdata.value.qstnrCtgryKey),
      delFlg: 0,
    };
    if (this.isEdit) {
      data.qstnrSeq = this.singleRecord.qstnrSeq;
    }
    this._SetupService.setupQuestionnaireModel = data;
    this._sharedSvc.showSpinner3();
    this._SetupService.saveQuestionnaire().subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.success(
            this.translate.instant("toast.questionnaire") +
              (this.isEdit === true
                ? this.translate.instant("toast.updated")
                : this.translate.instant("toast.added"))
          );
          this.qstnrFormdata.reset();
          this._sharedSvc.hideSpinner3();
          this.modalService.dismissAll();
          this._SetupService.get_qstnr_list();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner3();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(
            ([key, value]: any, index: number) => {
              this._sharedSvc.error(value);
            }
          );
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    });
  }

  editQstnr(content: any, id: any, edit: any) {
    
    let list = this._SetupService.questionnaireList;
    let record = list.find((x) => x.qstnrSeq === id);
    this.singleRecord = record;
    if (record) {
      let data = {
        qstnrNm: record.qstnrNm,
        qstnrStsKey: Number(record.qstnrStsKey),
        qstnrLvlKey: Number(record.qstnrLvlKey),
        qstnrCtgryKey: Number(record.qstnrCtgryKey),
        qstnrRul: record.qstnrRul,
        delFlg: 0,
      };
      this.qstnrFormdata.patchValue(data);
      this.openQstnrModal(content, edit);
    }
  }

  deleteQstnr(id: any) {
    let list = this._SetupService.questionnaireList;
    let record = list.find((x) => x.qstnrSeq === id);
    this.singleRecord = record;

    if (record) {
      let data = {
        ...record,
        delFlg: 1,
      };
      this._sharedSvc.showSpinner3();
      this._SetupService.setupQuestionnaireModel = data;
      this._SetupService.saveQuestionnaire().subscribe({
        next: (response) => {
          if (response) {
            this._sharedSvc.success(
              this.translate.instant("toast.questionnaireDeleted")
            );
            this.qstnrFormdata.reset();
            this._sharedSvc.hideSpinner3();
            this.modalService.dismissAll();
            this._SetupService.get_qstnr_list();
          }
        },
        error: (e) => {
          this._sharedSvc.hideSpinner3();
          if (e.status == 401) {
            this._sharedSvc.error(this._sharedSvc.authMsg);
            this._sharedSvc.logout();
            return;
          }
          if (e?.error) {
            Object.entries(e?.error).forEach(
              ([key, value]: any, index: number) => {
                this._sharedSvc.error(value);
              }
            );
          }
          if (e?.error?.errorMessage) {
            this._sharedSvc.error(e?.error?.error);
          }
        },
      });
    }
  }

  next(key: any) {
    sessionStorage.setItem("qstnr_key", key);
    this.router.navigate(["/setup-question"]);
  }

  getStatus(orderIndex: any) {
    for (let i = 0; i < this._picklistService.SHROFF_STATUS_Data?.length; i++) {
      if (
        Number(this._picklistService.SHROFF_STATUS_Data[i].refCd) === orderIndex
      ) {
        return this._picklistService.SHROFF_STATUS_Data[i].refCdDscr;
      }
    }
  }

  pageChange() {
    this.collectionSize = this._clintOnboardingSvc.onBoardingTable.length;
    this.onBoardingTablePG = this._clintOnboardingSvc.onBoardingTable.slice(
      (this.page - 1) * this.pageSize,
      (this.page - 1) * this.pageSize + this.pageSize
    );
    this.onBoardingTablePG;
  }
}
