import { Component, OnInit, DestroyRef, inject } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SharedService } from "src/app/components/shared/service/shared.service";
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
} from "@angular/router";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  NgForm,
} from "@angular/forms";
import { ClientDocsService } from "src/app/components/clint-onboarding/client-docs/shared/service/client-docs.service";
import { SharedPopupService } from "src/app/components/shared/service/shared-popup.service";
import { PicklistService } from "src/app/components/shared/pickList/picklist.service";
import {
  DataUrl,
  DOC_ORIENTATION,
  NgxImageCompressService,
  UploadResponse,
} from "ngx-image-compress";
import { RouteInfo } from "src/app/auth/model/route-info";
import { RoutingConditionsService } from "src/app/components/clint-onboarding/shared/service/routing-conditions.service";
import { TranslateService } from "@ngx-translate/core";
interface IDoc {
  clntDocSeq: number;
  clntSeq: number;
  entySeq: number;
  entyFlag: number;
  docSeq: number;
  docImg: string;
  delFlg: boolean;
}
@Component({
  selector: "app-client-credit-doc",
  templateUrl: "./client-credit-doc.component.html",
  styleUrls: ["./client-credit-doc.component.css"],
})
export class ClientCreditDocComponent implements OnInit {
  allowedRoutes: RouteInfo[];

  docForm: FormGroup;
  doc: IDoc;
  saveCnicData: any;
  crdtApp: any;
  invalid: boolean = false;

  modal1: any;
  modal2: any;
  modal3: any;
  modal4: any;
  modal5: any;
  modal6: any;
  modal7: any;

  rUtilityBill: any = "";
  bUtilityBill: any = "";
  fAgreement1: any = "";
  fAgreement2: any = "";
  facilityLetter: any = "";
  businessApprove: any = "";
  salaryApprove: any = "";

  src: any;
  imgSizeAfter: any;
  imgSizeBefore: any;
  imgResultBeforeCompress: any;
  imgResultAfterCompress: any;
  uploadMsg: any;
  imgPath: boolean = false;
  modelHeading: any;

  #destroyRef = inject(DestroyRef);

  constructor(
    public router: Router,
    public _sharedSvc: SharedService,
    public _clientDocsSvc: ClientDocsService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    public _sharedPopupSvc: SharedPopupService,
    public _picklistService: PicklistService,
    public route: ActivatedRoute,
    private imageCompress: NgxImageCompressService,
    public _conditionsService: RoutingConditionsService,
    public translate: TranslateService
  ) {
    this.allowedRoutes = JSON.parse(sessionStorage.getItem("routeInfo"));

    this.#destroyRef.onDestroy(() => {
      sessionStorage.setItem(
        "CDD",
        JSON.stringify({
          model1: this.modal1,
          model2: this.modal2,
          model3: this.modal3,
          model4: this.modal4,
          model5: this.modal5,
          model6: this.modal6,
          model7: this.modal7,
        })
      );
    });
  }

  ngOnInit(): void {
    this.saveCnicData = localStorage.getItem("SaveCnicData");
    this.saveCnicData = JSON.parse(this.saveCnicData);
    this.crdtApp = localStorage.getItem("crdtApp");
    this.crdtApp = JSON.parse(this.crdtApp);
    this.conditionsCheck();
    this.InItGetAllClientDocs(this.saveCnicData?.clntSeq);
  }

  openModal(content: any, h4: any) {
    this.modelHeading = h4;
    this.modalService.open(content, {
      // backdrop: "static",
      // keyboard: false,
      windowClass: "popupWidth1k",
      backdropClass: "light-blue-backdrop",
      centered: true,
    });
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  setD_V() {
    this._clientDocsSvc.saveDocsModel = {
      clntDocSeq: 0,
      clntSeq: this.saveCnicData.clntSeq,
      entySeq: this.crdtApp,
      entyFlag: 3,
      docSeq: 0,
      docImg: "",
      delFlg: false,
    };
  }

  compressFile(url: any) {
    this.setD_V();
    return this.imageCompress
      .uploadFile()
      .then(({ image, orientation, fileName }: UploadResponse) => {
        this.imgResultBeforeCompress = image;
        // console.warn('File Name:', fileName);
        // console.warn(
        //   `Original: ${image.substring(0, 50)}... (${image.length} characters)`
        // );

        if (url == "rUtilityBill") {
          this.rUtilityBill = image;
        }
        if (url == "bUtilityBill") {
          this.bUtilityBill = image;
        }
        if (url == "fAgreement1") {
          this.fAgreement1 = image;
        }
        if (url == "fAgreement2") {
          this.fAgreement2 = image;
        }
        if (url == "facilityLetter") {
          this.facilityLetter = image;
        }
        if (url == "businessApprove") {
          this.businessApprove = image;
        }
        if (url == "salaryApprove") {
          this.salaryApprove = image;
        }
        // if (image.length > 500000) { this.s1 = 50; } // 0.5Mb
        // if (image.length > 1000000) { this.s1 = 30; } // 1MB
        // if (image.length > 5000000) { this.s1 = 10; } // 5MB
        // if (image.length > 10000000) { this.s1 = 1; } // 10MB
        // if (image.length > 50000000) { this.s1 = 0.3; } // 50MB
        // console.warn('Size in bytes was:', this.imageCompress.byteCount(image));
        this.imageCompress
          .compressFile(image, orientation, 20, 70)
          .then((result: DataUrl) => {
            this.imgResultAfterCompress = result;
            // console.warn(
            //   `Compressed: ${result.substring(0, 50)}... (${result.length
            //   } characters)`
            // );
            // console.warn(
            //   'Size in bytes is now:',
            //   this.imageCompress.byteCount(result)
            // );
            if (url == "rUtilityBill") {
              this.rUtilityBill = result;
              this._clientDocsSvc.saveDocsModel.docImg = this.rUtilityBill;
              this._clientDocsSvc.saveDocsModel.docSeq = 11;
              this.modal1 = this._clientDocsSvc.saveDocsModel;
              this.uploadMsg = " Residence Utility Bill Image Upload";

              this.onSubmit();
            }
            if (url == "bUtilityBill") {
              this.bUtilityBill = result;
              this._clientDocsSvc.saveDocsModel.docImg = this.bUtilityBill;
              this._clientDocsSvc.saveDocsModel.docSeq = 12;
              this.modal2 = this._clientDocsSvc.saveDocsModel;
              this.uploadMsg = "Business utility bill Image Upload";

              this.onSubmit();
            }
            if (url == "fAgreement1") {
              this.fAgreement1 = result;
              this._clientDocsSvc.saveDocsModel.docImg = this.fAgreement1;
              this._clientDocsSvc.saveDocsModel.docSeq = 13;
              this.modal3 = this._clientDocsSvc.saveDocsModel;
              this.uploadMsg = "Finance Agreement 1 Image Upload";

              this.onSubmit();
            }
            if (url == "fAgreement2") {
              this.fAgreement2 = result;
              this._clientDocsSvc.saveDocsModel.docImg = this.fAgreement2;
              this._clientDocsSvc.saveDocsModel.docSeq = 14;
              this.modal4 = this._clientDocsSvc.saveDocsModel;
              this.uploadMsg = "Finance Agreement 2 Image Upload";

              this.onSubmit();
            }
            if (url == "facilityLetter") {
              this.facilityLetter = result;
              this._clientDocsSvc.saveDocsModel.docImg = this.facilityLetter;
              this._clientDocsSvc.saveDocsModel.docSeq = 15;
              this.modal5 = this._clientDocsSvc.saveDocsModel;
              this.uploadMsg = "Facility Letter Image Upload";

              this.onSubmit();
            }
            if (url == "businessApprove") {
              this.businessApprove = result;
              this._clientDocsSvc.saveDocsModel.docImg = this.businessApprove;
              this._clientDocsSvc.saveDocsModel.docSeq = 16;
              this.modal5 = this._clientDocsSvc.saveDocsModel;
              this.uploadMsg = "Business Approve Image Upload";

              this.onSubmit();
            }
            if (url == "salaryApprove") {
              this.salaryApprove = result;
              this._clientDocsSvc.saveDocsModel.docImg = this.salaryApprove;
              this._clientDocsSvc.saveDocsModel.docSeq = 17;
              this.modal5 = this._clientDocsSvc.saveDocsModel;
              this.uploadMsg = "Salary Approve Image Upload";

              this.onSubmit();
            }
          });
      });
  }

  public delete(url: any) {
    if (url == "rUtilityBill") {
      this.rUtilityBill = "";
    }
    if (url == "bUtilityBill") {
      this.bUtilityBill = "";
    }
    if (url == "fAgreement1") {
      this.fAgreement1 = "";
    }
    if (url == "fAgreement2") {
      this.fAgreement2 = "";
    }
    if (url == "facilityLetter") {
      this.facilityLetter = "";
    }
  }

  onSubmit(): void {
    // if (this.rUtilityBill && this.bUtilityBill && this.fAgreement1 && this.fAgreement2) {
    // } else {
    //   this._sharedSvc.error("Please upload all images");
    //   this.invalid = true;
    //   return;
    // }

    if (this._clientDocsSvc.getDoc?.length > 0) {
      for (let i = 0; i < this._clientDocsSvc.getDoc.length; i++) {
        if (this._clientDocsSvc.getDoc[i].docSeq == 11) {
          this.modal1.clntDocSeq = this._clientDocsSvc.getDoc[i]?.clntDocSeq;
        }
        if (this._clientDocsSvc.getDoc[i].docSeq == 12) {
          this.modal2.clntDocSeq = this._clientDocsSvc.getDoc[i]?.clntDocSeq;
        }
        if (this._clientDocsSvc.getDoc[i].docSeq == 13) {
          this.modal3.clntDocSeq = this._clientDocsSvc.getDoc[i]?.clntDocSeq;
        }
        if (this._clientDocsSvc.getDoc[i].docSeq == 14) {
          this.modal4.clntDocSeq = this._clientDocsSvc.getDoc[i]?.clntDocSeq;
        }
        if (this._clientDocsSvc.getDoc[i].docSeq == 15) {
          this.modal5.clntDocSeq = this._clientDocsSvc.getDoc[i]?.clntDocSeq;
        }
        if (this._clientDocsSvc.getDoc[i].docSeq == 15) {
          this.modal6.clntDocSeq = this._clientDocsSvc.getDoc[i]?.clntDocSeq;
        }
        if (this._clientDocsSvc.getDoc[i].docSeq == 15) {
          this.modal7.clntDocSeq = this._clientDocsSvc.getDoc[i]?.clntDocSeq;
        }
      }
      // sessionStorage.setItem("CDD" , JSON.stringify([{...this.modal1},{...this.modal2},{...this.modal3},{...this.modal4},{...this.modal5},{...this.modal6},{...this.modal7}]))
    }
    // for (let i = 0; i < 4; i++) {
    //   if (i == 0) {
    //     if (this.modal1) {
    //       this._clientDocsSvc.saveDocsModel = this.modal1;
    //     }
    //   }
    //   if (i == 1) {
    //     if (this.modal2) {
    //       this._clientDocsSvc.saveDocsModel = this.modal2;
    //     }
    //   }
    //   if (i == 2) {
    //     if (this.modal3) {
    //       this._clientDocsSvc.saveDocsModel = this.modal3;
    //     }
    //   }
    //   if (i == 3) {
    //     if (this.modal4) {
    //       this._clientDocsSvc.saveDocsModel = this.modal4;

    //     }
    //   }
    this._sharedSvc.showSpinner3();
    this._clientDocsSvc.SaveDocs().subscribe({
      next: (response) => {
        if (response) {
          this.modalService.dismissAll();
          this.imgPath = true;
          this._sharedSvc.success(this.uploadMsg);
          this._sharedSvc.hideSpinner3();
          // if (i == 3) {
          if (
            this.rUtilityBill &&
            this.bUtilityBill &&
            this.fAgreement1 &&
            this.fAgreement2 &&
            this.facilityLetter
          ) {
            // this.InItGetAllClientDocs(this.saveCnicData?.clntSeq);
          }
          // }
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner3();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(
            ([key, value]: any, index: number) => {
              this._sharedSvc.error(value);
            }
          );
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    });
    // }
    this._sharedSvc.hideSpinner();
  }

  InItGetAllClientDocs(clnt_seq: any): void {
    this._sharedSvc.showSpinner2();
    this._clientDocsSvc.getAllClientDocs(clnt_seq, 3).subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.hideSpinner2();
          this.imgPath = true;
          this._clientDocsSvc.getDoc = response;
          this.setD_V();
          let modalarr = [];
          for (let i = 0; i < this._clientDocsSvc.getDoc.length; i++) {
            if (this._clientDocsSvc.getDoc[i].docSeq == 11) {
              this.rUtilityBill = this._clientDocsSvc.getDoc[i]?.docImg;
              this._clientDocsSvc.saveDocsModel.docSeq = 11;
              this._clientDocsSvc.saveDocsModel.docImg = this.rUtilityBill;
              this._clientDocsSvc.saveDocsModel.clntDocSeq =
                this._clientDocsSvc.getDoc[i]?.clntDocSeq;
              this.modal1 = this._clientDocsSvc.saveDocsModel;
              this.rUtilityBill = "data:image/png;base64," + this.rUtilityBill;
              modalarr.push(this.modal1);
            }
            if (this._clientDocsSvc.getDoc[i].docSeq == 12) {
              this.bUtilityBill = this._clientDocsSvc.getDoc[i]?.docImg;
              this._clientDocsSvc.saveDocsModel.docImg = this.bUtilityBill;
              this._clientDocsSvc.saveDocsModel.docSeq = 12;
              this._clientDocsSvc.saveDocsModel.clntDocSeq =
                this._clientDocsSvc.getDoc[i]?.clntDocSeq;
              this.modal2 = this._clientDocsSvc.saveDocsModel;
              this.bUtilityBill = "data:image/png;base64," + this.bUtilityBill;
              modalarr.push(this.modal2);
            }
            if (this._clientDocsSvc.getDoc[i].docSeq == 13) {
              this.fAgreement1 = this._clientDocsSvc.getDoc[i]?.docImg;
              this._clientDocsSvc.saveDocsModel.docImg = this.fAgreement1;
              this._clientDocsSvc.saveDocsModel.docSeq = 13;
              this._clientDocsSvc.saveDocsModel.clntDocSeq =
                this._clientDocsSvc.getDoc[i]?.clntDocSeq;
              this.modal3 = this._clientDocsSvc.saveDocsModel;
              this.fAgreement1 = "data:image/png;base64," + this.fAgreement1;
              modalarr.push(this.modal3);
            }
            if (this._clientDocsSvc.getDoc[i].docSeq == 14) {
              this.fAgreement2 = this._clientDocsSvc.getDoc[i]?.docImg;
              this._clientDocsSvc.saveDocsModel.docImg = this.fAgreement2;
              this._clientDocsSvc.saveDocsModel.docSeq = 14;
              this._clientDocsSvc.saveDocsModel.clntDocSeq =
                this._clientDocsSvc.getDoc[i]?.clntDocSeq;
              this.modal4 = this._clientDocsSvc.saveDocsModel;
              this.fAgreement2 = "data:image/png;base64," + this.fAgreement2;
              modalarr.push(this.modal4);
            }
            if (this._clientDocsSvc.getDoc[i].docSeq == 15) {
              this.facilityLetter = this._clientDocsSvc.getDoc[i]?.docImg;
              this._clientDocsSvc.saveDocsModel.docImg = this.facilityLetter;
              this._clientDocsSvc.saveDocsModel.docSeq = 15;
              this._clientDocsSvc.saveDocsModel.clntDocSeq =
                this._clientDocsSvc.getDoc[i]?.clntDocSeq;
              this.modal5 = this._clientDocsSvc.saveDocsModel;
              this.facilityLetter =
                "data:image/png;base64," + this.facilityLetter;
              modalarr.push(this.modal5);
            }
            if (this._clientDocsSvc.getDoc[i].docSeq == 16) {
              this.businessApprove = this._clientDocsSvc.getDoc[i]?.docImg;
              this._clientDocsSvc.saveDocsModel.docImg = this.businessApprove;
              this._clientDocsSvc.saveDocsModel.docSeq = 16;
              this._clientDocsSvc.saveDocsModel.clntDocSeq =
                this._clientDocsSvc.getDoc[i]?.clntDocSeq;
              this.modal6 = this._clientDocsSvc.saveDocsModel;
              this.businessApprove =
                "data:image/png;base64," + this.businessApprove;
              modalarr.push(this.modal6);
            }
            if (this._clientDocsSvc.getDoc[i].docSeq == 17) {
              this.salaryApprove = this._clientDocsSvc.getDoc[i]?.docImg;
              this._clientDocsSvc.saveDocsModel.docImg = this.salaryApprove;
              this._clientDocsSvc.saveDocsModel.docSeq = 17;
              this._clientDocsSvc.saveDocsModel.clntDocSeq =
                this._clientDocsSvc.getDoc[i]?.clntDocSeq;
              this.modal7 = this._clientDocsSvc.saveDocsModel;
              this.salaryApprove =
                "data:image/png;base64," + this.salaryApprove;
              modalarr.push(this.modal7);
            }
          }
          // sessionStorage.setItem("CDD" , JSON.stringify(modalarr));
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner2();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(
            ([key, value]: any, index: number) => {
              this._sharedSvc.error(value);
            }
          );
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => {
      //   this._sharedSvc.hideSpinner();
      // }
    });
  }

  continue() {
    if (
      this.rUtilityBill &&
      this.bUtilityBill &&
      this.fAgreement1 &&
      this.fAgreement2 &&
      this.facilityLetter
    ) {
    } else {
      this._sharedSvc.error("Please upload all images");
      this.invalid = true;
      return;
    }
    if(this.allowedRoutes.length > 0 && this.allowedRoutes[0].rolId !== 'CSO'){
      this._sharedSvc.activeCredit = 3;      
    }else{
      this._sharedSvc.activeCredit = 5;      
    }
  }

  conditionsCheck() {
    if (
      (this.saveCnicData.occKey == 39 || this.saveCnicData.occKey == 42) &&
      this.saveCnicData.customerTypeKey == 120
    ) {
      this._sharedSvc.businessAppraisal_FS = true;
      this._sharedSvc.EmployerInfo_FS = false;
    } else {
      this._sharedSvc.businessAppraisal_FS = false;
      this._sharedSvc.EmployerInfo_FS = true;
    }
  }
}
