import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-client-relatives',
  templateUrl: './client-relatives.component.html',
  styleUrls: ['./client-relatives.component.css']
})
export class ClientRelativesComponent implements OnInit {


  constructor() { }

  ngOnInit(): void {}

}
