<!-- <div class="add-button" (click)="generatePDF()"><i class="fa fa-file-text-o"></i></div> -->
<div class="add-button" (click)="openQstnrModal(qstnrModal)"><i class="fa fa-file-text-o"></i></div>

<ng-template id="display-modal" #qstnrModal let-c="close" let-d="dismiss">
    <div class="modal-body" style="padding: 20px 50px;">
        <div class="d-flex align-items-center justify-content-between mb-4">
            <a class="btn login-button-class f-16px" (click)="generatePDF()"><i class="fa fa-file-pdf-o"></i>
                Report</a>
            <a class="btn login-button-class cancelBtn f-16px" (click)="d('Cross click')">{{'common.cancel' |
                translate}}</a>
        </div>
        <div>
            <div class="header mb-4">
                <div class="text-center w-100">
                    <div class="d-inline w-24 text-left">
                        <img src="/assets/images/Tasdeeq_logo-2.png" class="w-80" />
                    </div>
                    <div class="d-inline w-50 text-center">
                        <p class="header-title"><b>CONSUMER CREDIT INFORMATION REPORT</b></p>
                    </div>
                    <div class="d-inline w-24">
                        <div class="header-right">
                            <p class="header-title"><b>CONFIDENTIAL</b></p>
                            <p class="m-0 f-12px">Date Time: {{JSONdata.data.reportDate}} {{JSONdata.data.reportTime}}
                            </p>
                            <p class="m-0 f-12px">Report Ref No: {{JSONdata.data.refNo}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="pdf-content" #pdfContent>
            <div class="pdf-page">
                <div class="pdf-content">
                    <div class="page-section-with-border">
                        <div class="blue-heading">
                            <p><b>PERSONAL INFORMATION</b></p>
                        </div>
                        <div class="box-wrapper pt-10px pl-10px">
                            <div class="d-inline w-25 text-left">
                                <p class="m-0 mb-5px f-12px">
                                    <span><b>Name: </b></span>
                                    <span>{{JSONdata.data.personalInformation.NAME}}</span>
                                </p>
                                <p class="m-0 mb-5px f-12px">
                                    <span><b>Gender: </b></span>
                                    <span>{{JSONdata.data.personalInformation.GENDER}}</span>
                                </p>
                                <p class="m-0 mb-5px f-12px">
                                    <span><b>Date of Birth: </b></span>
                                    <span>{{JSONdata.data.personalInformation.DOB}}</span>
                                </p>
                                <p class="m-0 mb-5px f-12px">
                                    <span><b>Nationality: </b></span>
                                    <span>{{JSONdata.data.personalInformation.NATIONALITY}}</span>
                                </p>
                            </div>
                            <div class="d-inline w-30 text-left">
                                <p class="m-0 mb-5px f-12px">
                                    <span><b>CNIC: </b></span>
                                    <span>{{JSONdata.data.personalInformation.CNIC}}</span>
                                </p>
                                <p class="m-0 mb-5px f-12px">
                                    <span><b>NIC: </b></span>
                                    <span>{{JSONdata.data.personalInformation.NIC}}</span>
                                </p>
                                <p class="m-0 mb-5px f-12px">
                                    <span><b>NTN: </b></span>
                                    <span>{{JSONdata.data.personalInformation.NTN}}</span>
                                </p>
                            </div>
                            <div class="d-inline w-39 text-left">
                                <p class="m-0 mb-5px f-12px">
                                    <span><b>Father Name: </b></span>
                                    <span>{{JSONdata.data.personalInformation.FATHER_OR_HUSBAND_NAME}}</span>
                                </p>
                                <p class="m-0 mb-5px f-12px">
                                    <span><b>Passport No: </b></span>
                                    <span>{{JSONdata.data.personalInformation.PASSPORT}}</span>
                                </p>
                                <p class="m-0 mb-5px f-12px">
                                    <span><b>Business/Profession: </b></span>
                                    <span>{{JSONdata.data.personalInformation.BUSINESS_OR_PROFESSION}}</span>
                                </p>
                                <p class="m-0 mb-5px f-12px">
                                    <span><b>Borrower Type: </b></span>
                                    <span>{{JSONdata.data.personalInformation.BORROWER_TYPE}}</span>
                                </p>
                            </div>
                        </div>
                        <div class="pt-10px pl-10px">
                            <div class="text-left">
                                <p class="m-0 mb-5px f-12px">
                                    <span><b>Current Residential Address: </b></span>
                                    <span>{{JSONdata.data.personalInformation.CURRENT_RESIDENTIAL_ADDRESS}}</span>
                                </p>
                            </div>
                            <div class="text-left">
                                <p class="m-0 mb-5px f-12px">
                                    <span><b>Permanent Address: </b></span>
                                    <span>{{JSONdata.data.personalInformation.PERMANENT_ADDRESS}}</span>
                                </p>
                            </div>
                            <div class="text-left">
                                <p class="m-0 mb-5px f-12px">
                                    <span><b>Previous Residential Address: </b></span>
                                    <span>{{JSONdata.data.personalInformation.PREVIOUS_RESIDENTIAL_ADDRESS}}</span>
                                </p>
                            </div>
                            <div class="text-left">
                                <p class="m-0 mb-5px f-12px">
                                    <span><b>Employer/Business: </b></span>
                                    <span>{{JSONdata.data.personalInformation.EMPLOYER_OR_BUSINESS}}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="page-section-with-border">
                        <div class="blue-heading">
                            <p><b>SUMMARY OF INFORMATION CONTAINED IN THIS REPORT</b></p>
                        </div>
                        <div class="box-wrapper py-10px pl-10px">
                            <div class="d-inline w-32 text-center">
                                <div class="gray-box m-auto">
                                    <p class="m-0 f-18px"><b>{{JSONdata.data.noOfCreditEnquiry}}</b></p>
                                </div>
                                <p class="m-0 mt-15px f-12px">No. of Credit Enquiries (Last 12 Months)</p>
                            </div>
                            <div class="d-inline w-32 text-center">
                                <div class="gray-box m-auto">
                                    <p class="m-0 f-18px"><b>{{JSONdata.data.noOfActiveAccounts}}</b></p>
                                </div>
                                <p class="m-0 mt-15px f-12px">No. of Active Accounts</p>
                            </div>
                            <div class="d-inline w-32 text-center">
                                <div class="gray-box m-auto">
                                    <p class="m-0 f-18px"><b>PKR {{JSONdata.data.totalOutstandingBalance}}</b></p>
                                </div>
                                <p class="m-0 mt-15px f-12px">Total Outstanding Balance</p>
                            </div>
                        </div>

                    </div>
                    <div class="page-section-with-border">
                        <div class="blue-heading">
                            <p><b>OVERDUE SUMMARY OF LOANS FOR LAST 24 MONTHS</b></p>
                        </div>
                        <div class="">
                            <table class="table-with-blue-head">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>30+</th>
                                        <th>60+</th>
                                        <th>90+</th>
                                        <th>120+</th>
                                        <th>150+</th>
                                        <th>180+</th>
                                        <th>MFI Default</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of JSONdata.data.summaryOverdue_24M">
                                        <td>No. of Times</td>
                                        <td class="text-right">{{item.PLUS_30_24M}}</td>
                                        <td class="text-right">{{item.PLUS_60_24M}}</td>
                                        <td class="text-right">{{item.PLUS_90_24M}}</td>
                                        <td class="text-right">{{item.PLUS_120_24M}}</td>
                                        <td class="text-right">{{item.PLUS_150_24M}}</td>
                                        <td class="text-right">{{item.PLUS_180_24M}}</td>
                                        <td class="text-right">{{item.MFI_DEFAULT}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                    <div class="page-section-with-border">
                        <div class="blue-heading">
                            <p><b>DETAILS OF STATUS OF CREDIT APPLICATIONS FOR LAST 24 MONTHS</b></p>
                        </div>
                        <div class="">
                            <table class="table-with-blue-head">
                                <thead>
                                    <tr>
                                        <th>{{'common.product' | translate}}</th>
                                        <th>Financial Institution</th>
                                        <th>Date of Application</th>
                                        <th>Amount of Facility</th>
                                        <th>{{'common.status' | translate}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of JSONdata.data.detailsOfStatusCreditApplication">
                                        <td>{{item.PRODUCT}}</td>
                                        <td class="text-center">{{item.FINANCIAL_INSTITUTION}}</td>
                                        <td class="text-date">{{item.DATE_OF_APPLICATION}}</td>
                                        <td class="text-amount">{{item.AMOUNT_OF_FACILITY}}</td>
                                        <td class="text-center">{{item.STATUS}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                    <div class="page-section-with-border">
                        <div class="blue-heading">
                            <p><b>DETAILS OF SETTLEMENT OF LOANS FOR LAST FIVE YEARS</b></p>
                        </div>
                        <div class="">
                            <table class="table-with-blue-head">
                                <thead>
                                    <tr>
                                        <th>{{'common.product' | translate}}</th>
                                        <th>Total Limit</th>
                                        <th>{{'disbursement.approvalDate' | translate}}</th>
                                        <th>Relationship Date</th>
                                        <th>Maturity Date</th>
                                        <th>Date of Settlement</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of JSONdata.data.detailsOfLoansSettlement">
                                        <td class="text-Left">{{item.PRODUCT}}</td>
                                        <td class="text-amount">
                                            {{item.TOTAL_LIMIT}}
                                        </td>
                                        <td class="text-date">
                                            {{item.APPROVAL_DATE}}
                                        </td>
                                        <td class="text-date">
                                            {{item.RELATIONSHIP_DATE}}
                                        </td>
                                        <td class="text-date">
                                            {{item.MATURITY_DATE}}
                                        </td>
                                        <td class="text-date">
                                            {{item.DATE_OF_SETTLEMENT}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                    <div class="page-section-with-border">
                        <div class="blue-heading">
                            <!-- <p><b>DETAILS OF STATUS OF CREDIT APPLICATIONS FOR LAST 24 MONTHS</b></p> -->
                            <p class="text-uperCase"><b>Personal Guarantees</b></p>
                        </div>
                        <div class="">
                            <table class="table-with-blue-head">
                                <thead>
                                    <tr>
                                        <th class="same-color-bottom"></th>
                                        <th colspan="2">Principal Borrower</th>
                                        <th class="same-color-bottom"></th>
                                        <th colspan="2">Guarantee</th>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="same-color-top">{{'common.product' | translate}}</th>
                                        <th>Name</th>
                                        <th>CNIC</th>
                                        <th rowspan="2" class="ssame-color-top">Date Of Invocation</th>
                                        <th>Date</th>
                                        <th>{{'common.amount' | translate}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of JSONdata.data.personalGuarantees">
                                        <td>{{item.PRODUCT}}</td>
                                        <td class="text-center">{{item.PRINCIPAL_BORROWER_NAME}}</td>
                                        <td class="text-date">{{item.PRINCIPAL_BORROWER_CNIC}}</td>
                                        <td class="text-amount">{{item.DATE_OF_INVOCATION}}</td>
                                        <td class="text-center">{{item.GUARANTEE_DATE}}</td>
                                        <td class="text-center">{{item.GUARANTEE_AMOUNT}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                    <div class="page-section">
                        <div class="box-wrapper">
                            <div class="w-48">
                                <div class="blue-heading">
                                    <p class="text-uperCase"><b>Co Borrower Detail</b></p>
                                </div>
                                <div>
                                    <table class="table-with-blue-head">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>CNIC</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of JSONdata.data.coborrowerDetail">
                                                <td class="text-center">{{item.NAME}}</td>
                                                <td class="text-center">{{item.CNIC}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="w-48">
                                <div class="blue-heading">
                                    <p class="text-uperCase"><b>DETAILS OF SETTLEMENT OF Bankruptcy Cases</b></p>
                                </div>
                                <div>
                                    <table class="table-with-blue-head">
                                        <thead>
                                            <tr>
                                                <th>Court Name</th>
                                                <th>Declaration Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of JSONdata.data.detailsOfBankruptcyCases">
                                                <td class="text-center">{{item.COURT_NAME}}</td>
                                                <td class="text-center">{{item.DECLARATION_DATE}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="page-section">
                        <div class="blue-heading">
                            <p><b>DETAILS OF Credit Enquiry</b></p>
                        </div>
                        <div>
                            <table class="table-with-blue-head">
                                <thead>
                                    <tr>
                                        <th>Sr No.</th>
                                        <th>Name</th>
                                        <th>CNIC</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of JSONdata.data.creditEnquiry">
                                        <td class="text-center">{{item.SR_NO}}</td>
                                        <td class="text-center">{{item.FI_TYPE}}</td>
                                        <td class="text-center">{{item.DATE_OF_ENQUIRY}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- <div class="box-wrapper">
                            <div class="w-48">
                                <div>
                                    <table class="table-with-blue-head">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>CNIC</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="text-center">*</td>
                                                <td class="text-center">
                                                    *
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="w-48">
                                <div>
                                    <table class="table-with-blue-head">
                                        <thead>
                                            <tr>
                                                <th>Product</th>
                                                <th>Total Limit</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="text-center">*</td>
                                                <td class="text-center">
                                                    *
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div> -->
                    </div>
                    <div *ngFor="let item of JSONdata.data.loanDetails; let i=index"
                        class="page-section-with-blue-border">
                        <div class="blue-heading">
                            <p><b>{{i+1}} - MFB - MICRO CREDIT -OTHER</b></p>
                        </div>
                        <div class="px-10px">
                            <div class="box-wrapper pt-10px">
                                <div class="d-inline w-33 text-left">
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Account Status:&ensp;</b></span>
                                        <span>{{item.LOAN_ACCOUNT_STATUS}}</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Loan Limit:&ensp;</b></span>
                                        <span>{{item.LOAN_LIMIT}}</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Outstanding Balance:&ensp;</b></span>
                                        <span>{{item.OUTSTANDING_BALANCE}}</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Min Amount Due:&ensp;</b></span>
                                        <span>{{item.MINIMUM_AMOUNT_DUE}}</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Collateral Amount:&ensp;</b></span>
                                        <span>{{item.COLLATERAL_AMOUNT}}</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Litigation Amount:&ensp;</b></span>
                                        <span>{{item.LITIGATION_AMOUNT}}</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Secured/Unsecured:&ensp;</b></span>
                                        <span>{{item.SECURED_UNSECURED}}</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Write Off Type:&ensp;</b></span>
                                        <span>{{item.WRITEOFF_TYPE}}</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Recovery Amount:&ensp;</b></span>
                                        <span>{{item.RECOVERY_AMOUNT}}</span>
                                    </p>
                                </div>
                                <div class="d-inline w-33 text-left">
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Loan Type:&ensp;</b></span>
                                        <span>{{item.LOAN_TYPE}}</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Date of Last Payment Made:&ensp;</b></span>
                                        <span>{{item.DATE_OF_LAST_PAYMENT_MADE}}</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Facility Date:&ensp;</b></span>
                                        <span>{{item.FACILITY_DATE}}</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Maturity Date:&ensp;</b></span>
                                        <span>{{item.MATURITY_DATE}}</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Bounced Repayment Cheques:&ensp;</b></span>
                                        <span>{{item.BOUNCED_REPAYMENT_CHEQUES}}</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Security/Collateral:&ensp;</b></span>
                                        <span>{{item.SECURITY_COLLATERAL}}</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Write Off Amount:&ensp;</b></span>
                                        <span>{{item.WRITE_OFF_AMOUNT}}</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Recovery Date:&ensp;</b></span>
                                        <span>{{item.RECOVERY_DATE}}</span>
                                    </p>
                                </div>
                                <div class="d-inline w-33 text-left">
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Position as of:&ensp;</b></span>
                                        <span>{{item.POSITION_AS_OF}}</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Repayment Frequency:&ensp;</b></span>
                                        <span>{{item.REPAYMENT_FREQUENCY}}</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Classification Type:&ensp;</b></span>
                                        <span>{{item.CLASSIFICATION_TYPE}}</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Classification Nature:&ensp;</b></span>
                                        <span>{{item.CLASSIFICATION_NATURE}}</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Restructuring Date:&ensp;</b></span>
                                        <span>{{item.RESTRUCTURING_DATE}}</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Restructuring Amount:&ensp;</b></span>
                                        <span>{{item.RESTRUCTURING_AMOUNT}}</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Write Off Date:&ensp;</b></span>
                                        <span>{{item.WRITEOFF_DATE}}</span>
                                    </p>
                                </div>
                            </div>
                            <div class="page-section-with-border">
                                <div class="blue-heading">
                                    <p><b>SUMMARY OF OVERDUES AND LATE PAYMENTS FOR LAST 24 MONTHS</b></p>
                                </div>
                                <div class="">
                                    <table class="table-with-blue-head">
                                        <thead>
                                            <tr>
                                                <th>{{'common.days' | translate}}</th>
                                                <th class="text-center">30+</th>
                                                <th class="text-center">60+</th>
                                                <th class="text-center">90+</th>
                                                <th class="text-center">120+</th>
                                                <th class="text-center">150+</th>
                                                <th class="text-center">180+</th>
                                                <th class="text-center">MFI Default</th>
                                                <th class="text-center">1 - 15</th>
                                                <th class="text-center">16 - 20</th>
                                                <th class="text-center">21 - 29</th>
                                                <th class="text-center">30</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><b>Times</b></td>
                                                <td class="text-center">{{item.PLUS_30}}</td>
                                                <td class="text-center">{{item.PLUS_60}}</td>
                                                <td class="text-center">{{item.PLUS_90}}</td>
                                                <td class="text-center">{{item.PLUS_120}}</td>
                                                <td class="text-center">{{item.PLUS_150}}</td>
                                                <td class="text-center">{{item.PLUS_180}}</td>
                                                <td class="text-center">{{item.MFI_DEFAULT}}</td>
                                                <td class="text-center">{{item.LATE_PAYMENT_1TO15}}</td>
                                                <td class="text-center">{{item.LATE_PAYMENT_16TO20}}</td>
                                                <td class="text-center">{{item.LATE_PAYMENT_21TO29}}</td>
                                                <td class="text-center">{{item.LATE_PAYMENT_30}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                            <ng-container *ngIf="getCreditHistoryLength(item.LOAN_SERIAL_NUMBER) > 0">
                                <div class="page-section-with-border">
                                    <div class="blue-heading">
                                        <p><b>DETAILS OF CREDIT HISTORY FOR LAST 24 MONTHS</b></p>
                                    </div>
                                    <div class="">
                                        <div class="box-wrapper">
                                            <div class="w-50">
                                                <table class="month-table">
                                                    <thead>
                                                        <tr>
                                                            <th class="month-rospan-fix-bottom"><span>Month</span>
                                                            </th>
                                                            <th colspan="6">Amount Overdue </th>
                                                            <th class="white-rowspan-fix-bottom"><span>MFI
                                                                    Default</span>
                                                            </th>
                                                            <th class="white-rowspan-fix-bottom"><span>Late PMT
                                                                    {{'common.days' | translate}}</span>
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th class="month-rospan-fix-top">
                                                            </th>
                                                            <th>30+</th>
                                                            <th>60+</th>
                                                            <th>90+</th>
                                                            <th>120+</th>
                                                            <th>150+</th>
                                                            <th>180+</th>
                                                            <th class="white-rowspan-fix-top">
                                                            </th>
                                                            <th class="white-rowspan-fix-top">
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let item of CurrentCreditHistoryFirstHalf">
                                                            <td>{{item.MONTH_NAME}}</td>
                                                            <td>{{item.PLUS_30}}</td>
                                                            <td>{{item.PLUS_60}}</td>
                                                            <td>{{item.PLUS_90}}</td>
                                                            <td>{{item.PLUS_120}}</td>
                                                            <td>{{item.PLUS_150}}</td>
                                                            <td>{{item.PLUS_180}}</td>
                                                            <td>{{item.MFI_DEFAULT}}</td>
                                                            <td>{{item.LATE_PMT_DAYS}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="w-50">
                                                <table class="month-table">
                                                    <thead>
                                                        <tr>
                                                            <th class="month-rospan-fix-bottom"><span>Month</span>
                                                            </th>
                                                            <th colspan="6">Amount Overdue </th>
                                                            <th class="white-rowspan-fix-bottom"><span>MFI
                                                                    Default</span>
                                                            </th>
                                                            <th class="white-rowspan-fix-bottom"><span>Late PMT
                                                                    {{'common.days' | translate}}</span>
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th class="month-rospan-fix-top">
                                                            </th>
                                                            <th>30+</th>
                                                            <th>60+</th>
                                                            <th>90+</th>
                                                            <th>120+</th>
                                                            <th>150+</th>
                                                            <th>180+</th>
                                                            <th class="white-rowspan-fix-top">
                                                            </th>
                                                            <th class="white-rowspan-fix-top">
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let item of CurrentCreditHistorySecondHalf">
                                                            <td>{{item.MONTH_NAME}}</td>
                                                            <td>{{item.PLUS_30}}</td>
                                                            <td>{{item.PLUS_60}}</td>
                                                            <td>{{item.PLUS_90}}</td>
                                                            <td>{{item.PLUS_120}}</td>
                                                            <td>{{item.PLUS_150}}</td>
                                                            <td>{{item.PLUS_180}}</td>
                                                            <td>{{item.MFI_DEFAULT}}</td>
                                                            <td>{{item.LATE_PMT_DAYS}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <!-- <div class="page-section-with-blue-border">
                        <div class="blue-heading">
                            <p><b>2 - MFB - MICRO CREDIT -OTHER</b></p>
                        </div>
                        <div class="px-10px">
                            <div class="box-wrapper pt-10px">
                                <div class="d-inline w-33 text-left">
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Account Status:</b></span>
                                        <span>CLOSE</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Loan Limit:</b></span>
                                        <span>200</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Outstanding Balance:</b></span>
                                        <span>0</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Min Amount Due:</b></span>
                                        <span>0</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Collateral Amount:</b></span>
                                        <span></span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Litigation Amount:</b></span>
                                        <span>*</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Secured/Unsecured:</b></span>
                                        <span>U</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Write Off Type:</b></span>
                                        <span></span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Recovery Amount</b></span>
                                        <span>0</span>
                                    </p>
                                </div>
                                <div class="d-inline w-33 text-left">
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Loan Type</b></span>
                                        <span>Term Loan</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Date of Last Payment Made</b></span>
                                        <span></span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Facility Date:</b></span>
                                        <span>14/02/2023</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Maturity Date</b></span>
                                        <span>15/03/2023</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Bounced Repayment Cheques</b></span>
                                        <span>0</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Security/Collateral:</b></span>
                                        <span></span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Write Off Amount:</b></span>
                                        <span>0</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Recovery Date:</b></span>
                                        <span></span>
                                    </p>
                                </div>
                                <div class="d-inline w-33 text-left">
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Position as of:</b></span>
                                        <span>28/02/2023</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Repayment Frequency:</b></span>
                                        <span></span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Classification Type:</b></span>
                                        <span>Regular</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Classification Nature:</b></span>
                                        <span></span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Restructuring Date:</b></span>
                                        <span></span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Restructuring Amount:</b></span>
                                        <span>*</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Write Off Date:</b></span>
                                        <span></span>
                                    </p>
                                </div>
                            </div>
                            <div class="page-section-with-border">
                                <div class="blue-heading">
                                    <p><b>SUMMARY OF OVERDUES AND LATE PAYMENTS FOR LAST 24 MONTHS</b></p>
                                </div>
                                <div class="">
                                    <table class="table-with-blue-head">
                                        <thead>
                                            <tr>
                                                <th>Days</th>
                                                <th class="text-center">30+</th>
                                                <th class="text-center">60+</th>
                                                <th class="text-center">90+</th>
                                                <th class="text-center">120+</th>
                                                <th class="text-center">150+</th>
                                                <th class="text-center">180+</th>
                                                <th class="text-center">MFI Default</th>
                                                <th class="text-center">1 - 15</th>
                                                <th class="text-center">16 - 20</th>
                                                <th class="text-center">21 - 29</th>
                                                <th class="text-center">30</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><b>Times</b></td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">1</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">0</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                            <div class="page-section-with-border">
                                <div class="blue-heading">
                                    <p><b>DETAILS OF CREDIT HISTORY FOR LAST 24 MONTHS</b></p>
                                </div>
                                <div class="">
                                    <div class="box-wrapper">
                                        <div class="w-50">
                                            <table class="month-table">
                                                <thead>
                                                    <tr>
                                                        <th class="month-rospan-fix-bottom"><span>Month</span>
                                                        </th>
                                                        <th colspan="6">Amount Overdue </th>
                                                        <th class="white-rowspan-fix-bottom"><span>MFI Default</span>
                                                        </th>
                                                        <th class="white-rowspan-fix-bottom"><span>Late PMT Days</span>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th class="month-rospan-fix-top">
                                                        </th>
                                                        <th>30+</th>
                                                        <th>60+</th>
                                                        <th>90+</th>
                                                        <th>120+</th>
                                                        <th>150+</th>
                                                        <th>180+</th>
                                                        <th class="white-rowspan-fix-top">
                                                        </th>
                                                        <th class="white-rowspan-fix-top">
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>FEB 23</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                    </tr>
                                                    <tr>
                                                        <td>JAN 23</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                    </tr>
                                                    <tr>
                                                        <td>FEB 23</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                    </tr>
                                                    <tr>
                                                        <td>FEB 23</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="w-50">
                                            <table class="month-table">
                                                <thead>
                                                    <tr>
                                                        <th class="month-rospan-fix-bottom"><span>Month</span>
                                                        </th>
                                                        <th colspan="6">Amount Overdue </th>
                                                        <th class="white-rowspan-fix-bottom"><span>MFI Default</span>
                                                        </th>
                                                        <th class="white-rowspan-fix-bottom"><span>Late PMT Days</span>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th class="month-rospan-fix-top">
                                                        </th>
                                                        <th>30+</th>
                                                        <th>60+</th>
                                                        <th>90+</th>
                                                        <th>120+</th>
                                                        <th>150+</th>
                                                        <th>180+</th>
                                                        <th class="white-rowspan-fix-top">
                                                        </th>
                                                        <th class="white-rowspan-fix-top">
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>FEB 23</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                    </tr>
                                                    <tr>
                                                        <td>JAN 23</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                    </tr>
                                                    <tr>
                                                        <td>FEB 23</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                    </tr>
                                                    <tr>
                                                        <td>FEB 23</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="page-section-with-blue-border">
                        <div class="blue-heading">
                            <p><b>3 - NBFC - MICRO CREDIT -OTHER</b></p>
                        </div>
                        <div class="px-10px">
                            <div class="box-wrapper pt-10px">
                                <div class="d-inline w-33 text-left">
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Account Status:</b></span>
                                        <span>CLOSE</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Loan Limit:</b></span>
                                        <span>50,000</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Outstanding Balance:</b></span>
                                        <span>0</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Min Amount Due:</b></span>
                                        <span>0</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Collateral Amount:</b></span>
                                        <span></span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Litigation Amount:</b></span>
                                        <span>*</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Secured/Unsecured:</b></span>
                                        <span>U</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Write Off Type:</b></span>
                                        <span></span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Recovery Amount</b></span>
                                        <span>0</span>
                                    </p>
                                </div>
                                <div class="d-inline w-33 text-left">
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Loan Type</b></span>
                                        <span>Term Loan</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Date of Last Payment Made</b></span>
                                        <span></span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Facility Date:</b></span>
                                        <span>14/02/2023</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Maturity Date</b></span>
                                        <span>15/03/2023</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Bounced Repayment Cheques</b></span>
                                        <span>0</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Security/Collateral:</b></span>
                                        <span></span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Write Off Amount:</b></span>
                                        <span>0</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Recovery Date:</b></span>
                                        <span></span>
                                    </p>
                                </div>
                                <div class="d-inline w-33 text-left">
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Position as of:</b></span>
                                        <span>28/02/2023</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Repayment Frequency:</b></span>
                                        <span></span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Classification Type:</b></span>
                                        <span>Regular</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Classification Nature:</b></span>
                                        <span></span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Restructuring Date:</b></span>
                                        <span></span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Restructuring Amount:</b></span>
                                        <span>*</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Write Off Date:</b></span>
                                        <span></span>
                                    </p>
                                </div>
                            </div>
                            <div class="page-section-with-border">
                                <div class="blue-heading">
                                    <p><b>SUMMARY OF OVERDUES AND LATE PAYMENTS FOR LAST 24 MONTHS</b></p>
                                </div>
                                <div class="">
                                    <table class="table-with-blue-head">
                                        <thead>
                                            <tr>
                                                <th>Days</th>
                                                <th class="text-center">30+</th>
                                                <th class="text-center">60+</th>
                                                <th class="text-center">90+</th>
                                                <th class="text-center">120+</th>
                                                <th class="text-center">150+</th>
                                                <th class="text-center">180+</th>
                                                <th class="text-center">MFI Default</th>
                                                <th class="text-center">1 - 15</th>
                                                <th class="text-center">16 - 20</th>
                                                <th class="text-center">21 - 29</th>
                                                <th class="text-center">30</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><b>Times</b></td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">1</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">0</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                            <div class="page-section-with-border">
                                <div class="blue-heading">
                                    <p><b>DETAILS OF CREDIT HISTORY FOR LAST 24 MONTHS</b></p>
                                </div>
                                <div class="">
                                    <div class="box-wrapper">
                                        <div class="w-50">
                                            <table class="month-table">
                                                <thead>
                                                    <tr>
                                                        <th class="month-rospan-fix-bottom"><span>Month</span>
                                                        </th>
                                                        <th colspan="6">Amount Overdue </th>
                                                        <th class="white-rowspan-fix-bottom"><span>MFI Default</span>
                                                        </th>
                                                        <th class="white-rowspan-fix-bottom"><span>Late PMT Days</span>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th class="month-rospan-fix-top">
                                                        </th>
                                                        <th>30+</th>
                                                        <th>60+</th>
                                                        <th>90+</th>
                                                        <th>120+</th>
                                                        <th>150+</th>
                                                        <th>180+</th>
                                                        <th class="white-rowspan-fix-top">
                                                        </th>
                                                        <th class="white-rowspan-fix-top">
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>FEB 23</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                    </tr>
                                                    <tr>
                                                        <td>JAN 23</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                    </tr>
                                                    <tr>
                                                        <td>FEB 23</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                    </tr>
                                                    <tr>
                                                        <td>FEB 23</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="w-50">
                                            <table class="month-table">
                                                <thead>
                                                    <tr>
                                                        <th class="month-rospan-fix-bottom"><span>Month</span>
                                                        </th>
                                                        <th colspan="6">Amount Overdue </th>
                                                        <th class="white-rowspan-fix-bottom"><span>MFI Default</span>
                                                        </th>
                                                        <th class="white-rowspan-fix-bottom"><span>Late PMT Days</span>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th class="month-rospan-fix-top">
                                                        </th>
                                                        <th>30+</th>
                                                        <th>60+</th>
                                                        <th>90+</th>
                                                        <th>120+</th>
                                                        <th>150+</th>
                                                        <th>180+</th>
                                                        <th class="white-rowspan-fix-top">
                                                        </th>
                                                        <th class="white-rowspan-fix-top">
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>FEB 23</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                    </tr>
                                                    <tr>
                                                        <td>JAN 23</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                    </tr>
                                                    <tr>
                                                        <td>FEB 23</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                    </tr>
                                                    <tr>
                                                        <td>FEB 23</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="page-section-with-blue-border">
                        <div class="blue-heading">
                            <p><b>4 - NBFC - MICRO CREDIT -OTHER
                                </b></p>
                        </div>
                        <div class="px-10px">
                            <div class="box-wrapper pt-10px">
                                <div class="d-inline w-33 text-left">
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Account Status:</b></span>
                                        <span>CLOSE</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Loan Limit:</b></span>
                                        <span>50,000</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Outstanding Balance:</b></span>
                                        <span>0</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Min Amount Due:</b></span>
                                        <span>0</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Collateral Amount:</b></span>
                                        <span></span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Litigation Amount:</b></span>
                                        <span>*</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Secured/Unsecured:</b></span>
                                        <span>U</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Write Off Type:</b></span>
                                        <span></span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Recovery Amount</b></span>
                                        <span>0</span>
                                    </p>
                                </div>
                                <div class="d-inline w-33 text-left">
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Loan Type</b></span>
                                        <span>Term Loan</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Date of Last Payment Made</b></span>
                                        <span></span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Facility Date:</b></span>
                                        <span>14/02/2023</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Maturity Date</b></span>
                                        <span>15/03/2023</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Bounced Repayment Cheques</b></span>
                                        <span>0</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Security/Collateral:</b></span>
                                        <span></span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Write Off Amount:</b></span>
                                        <span>0</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Recovery Date:</b></span>
                                        <span></span>
                                    </p>
                                </div>
                                <div class="d-inline w-33 text-left">
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Position as of:</b></span>
                                        <span>28/02/2023</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Repayment Frequency:</b></span>
                                        <span></span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Classification Type:</b></span>
                                        <span>Regular</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Classification Nature:</b></span>
                                        <span></span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Restructuring Date:</b></span>
                                        <span></span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Restructuring Amount:</b></span>
                                        <span>*</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Write Off Date:</b></span>
                                        <span></span>
                                    </p>
                                </div>
                            </div>
                            <div class="page-section-with-border">
                                <div class="blue-heading">
                                    <p><b>SUMMARY OF OVERDUES AND LATE PAYMENTS FOR LAST 24 MONTHS</b></p>
                                </div>
                                <div class="">
                                    <table class="table-with-blue-head">
                                        <thead>
                                            <tr>
                                                <th>Days</th>
                                                <th class="text-center">30+</th>
                                                <th class="text-center">60+</th>
                                                <th class="text-center">90+</th>
                                                <th class="text-center">120+</th>
                                                <th class="text-center">150+</th>
                                                <th class="text-center">180+</th>
                                                <th class="text-center">MFI Default</th>
                                                <th class="text-center">1 - 15</th>
                                                <th class="text-center">16 - 20</th>
                                                <th class="text-center">21 - 29</th>
                                                <th class="text-center">30</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><b>Times</b></td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">1</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">0</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                            <div class="page-section-with-border">
                                <div class="blue-heading">
                                    <p><b>DETAILS OF CREDIT HISTORY FOR LAST 24 MONTHS</b></p>
                                </div>
                                <div class="">
                                    <div class="box-wrapper">
                                        <div class="w-50">
                                            <table class="month-table">
                                                <thead>
                                                    <tr>
                                                        <th class="month-rospan-fix-bottom"><span>Month</span>
                                                        </th>
                                                        <th colspan="6">Amount Overdue </th>
                                                        <th class="white-rowspan-fix-bottom"><span>MFI Default</span>
                                                        </th>
                                                        <th class="white-rowspan-fix-bottom"><span>Late PMT Days</span>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th class="month-rospan-fix-top">
                                                        </th>
                                                        <th>30+</th>
                                                        <th>60+</th>
                                                        <th>90+</th>
                                                        <th>120+</th>
                                                        <th>150+</th>
                                                        <th>180+</th>
                                                        <th class="white-rowspan-fix-top">
                                                        </th>
                                                        <th class="white-rowspan-fix-top">
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>FEB 23</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                    </tr>
                                                    <tr>
                                                        <td>JAN 23</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                    </tr>
                                                    <tr>
                                                        <td>FEB 23</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                    </tr>
                                                    <tr>
                                                        <td>FEB 23</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="w-50">
                                            <table class="month-table">
                                                <thead>
                                                    <tr>
                                                        <th class="month-rospan-fix-bottom"><span>Month</span>
                                                        </th>
                                                        <th colspan="6">Amount Overdue </th>
                                                        <th class="white-rowspan-fix-bottom"><span>MFI Default</span>
                                                        </th>
                                                        <th class="white-rowspan-fix-bottom"><span>Late PMT Days</span>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th class="month-rospan-fix-top">
                                                        </th>
                                                        <th>30+</th>
                                                        <th>60+</th>
                                                        <th>90+</th>
                                                        <th>120+</th>
                                                        <th>150+</th>
                                                        <th>180+</th>
                                                        <th class="white-rowspan-fix-top">
                                                        </th>
                                                        <th class="white-rowspan-fix-top">
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>FEB 23</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                    </tr>
                                                    <tr>
                                                        <td>JAN 23</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                    </tr>
                                                    <tr>
                                                        <td>FEB 23</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                    </tr>
                                                    <tr>
                                                        <td>FEB 23</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="page-section-with-blue-border">
                        <div class="blue-heading">
                            <p><b>5 - NBFC - MICRO CREDIT -OTHER
                                </b></p>
                        </div>
                        <div class="px-10px">
                            <div class="box-wrapper pt-10px">
                                <div class="d-inline w-33 text-left">
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Account Status:</b></span>
                                        <span>CLOSE</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Loan Limit:</b></span>
                                        <span>50,000</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Outstanding Balance:</b></span>
                                        <span>0</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Min Amount Due:</b></span>
                                        <span>0</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Collateral Amount:</b></span>
                                        <span></span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Litigation Amount:</b></span>
                                        <span>*</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Secured/Unsecured:</b></span>
                                        <span>U</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Write Off Type:</b></span>
                                        <span></span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Recovery Amount</b></span>
                                        <span>0</span>
                                    </p>
                                </div>
                                <div class="d-inline w-33 text-left">
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Loan Type</b></span>
                                        <span>Term Loan</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Date of Last Payment Made</b></span>
                                        <span></span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Facility Date:</b></span>
                                        <span>14/02/2023</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Maturity Date</b></span>
                                        <span>15/03/2023</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Bounced Repayment Cheques</b></span>
                                        <span>0</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Security/Collateral:</b></span>
                                        <span></span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Write Off Amount:</b></span>
                                        <span>0</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Recovery Date:</b></span>
                                        <span></span>
                                    </p>
                                </div>
                                <div class="d-inline w-33 text-left">
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Position as of:</b></span>
                                        <span>28/02/2023</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Repayment Frequency:</b></span>
                                        <span></span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Classification Type:</b></span>
                                        <span>Regular</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Classification Nature:</b></span>
                                        <span></span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Restructuring Date:</b></span>
                                        <span></span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Restructuring Amount:</b></span>
                                        <span>*</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Write Off Date:</b></span>
                                        <span></span>
                                    </p>
                                </div>
                            </div>
                            <div class="page-section-with-border">
                                <div class="blue-heading">
                                    <p><b>SUMMARY OF OVERDUES AND LATE PAYMENTS FOR LAST 24 MONTHS</b></p>
                                </div>
                                <div class="">
                                    <table class="table-with-blue-head">
                                        <thead>
                                            <tr>
                                                <th>Days</th>
                                                <th class="text-center">30+</th>
                                                <th class="text-center">60+</th>
                                                <th class="text-center">90+</th>
                                                <th class="text-center">120+</th>
                                                <th class="text-center">150+</th>
                                                <th class="text-center">180+</th>
                                                <th class="text-center">MFI Default</th>
                                                <th class="text-center">1 - 15</th>
                                                <th class="text-center">16 - 20</th>
                                                <th class="text-center">21 - 29</th>
                                                <th class="text-center">30</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><b>Times</b></td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">1</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">0</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                            <div class="page-section-with-border">
                                <div class="blue-heading">
                                    <p><b>DETAILS OF CREDIT HISTORY FOR LAST 24 MONTHS</b></p>
                                </div>
                                <div class="">
                                    <div class="box-wrapper">
                                        <div class="w-50">
                                            <table class="month-table">
                                                <thead>
                                                    <tr>
                                                        <th class="month-rospan-fix-bottom"><span>Month</span>
                                                        </th>
                                                        <th colspan="6">Amount Overdue </th>
                                                        <th class="white-rowspan-fix-bottom"><span>MFI Default</span>
                                                        </th>
                                                        <th class="white-rowspan-fix-bottom"><span>Late PMT Days</span>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th class="month-rospan-fix-top">
                                                        </th>
                                                        <th>30+</th>
                                                        <th>60+</th>
                                                        <th>90+</th>
                                                        <th>120+</th>
                                                        <th>150+</th>
                                                        <th>180+</th>
                                                        <th class="white-rowspan-fix-top">
                                                        </th>
                                                        <th class="white-rowspan-fix-top">
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>FEB 23</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                    </tr>
                                                    <tr>
                                                        <td>JAN 23</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                    </tr>
                                                    <tr>
                                                        <td>FEB 23</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                    </tr>
                                                    <tr>
                                                        <td>FEB 23</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="w-50">
                                            <table class="month-table">
                                                <thead>
                                                    <tr>
                                                        <th class="month-rospan-fix-bottom"><span>Month</span>
                                                        </th>
                                                        <th colspan="6">Amount Overdue </th>
                                                        <th class="white-rowspan-fix-bottom"><span>MFI Default</span>
                                                        </th>
                                                        <th class="white-rowspan-fix-bottom"><span>Late PMT Days</span>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th class="month-rospan-fix-top">
                                                        </th>
                                                        <th>30+</th>
                                                        <th>60+</th>
                                                        <th>90+</th>
                                                        <th>120+</th>
                                                        <th>150+</th>
                                                        <th>180+</th>
                                                        <th class="white-rowspan-fix-top">
                                                        </th>
                                                        <th class="white-rowspan-fix-top">
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>FEB 23</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                    </tr>
                                                    <tr>
                                                        <td>JAN 23</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                    </tr>
                                                    <tr>
                                                        <td>FEB 23</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                    </tr>
                                                    <tr>
                                                        <td>FEB 23</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="page-section-with-blue-border">
                        <div class="blue-heading">
                            <p><b>6 - NBFC - MICRO CREDIT -OTHER
                                </b></p>
                        </div>
                        <div class="px-10px">
                            <div class="box-wrapper pt-10px">
                                <div class="d-inline w-33 text-left">
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Account Status:</b></span>
                                        <span>CLOSE</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Loan Limit:</b></span>
                                        <span>50,000</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Outstanding Balance:</b></span>
                                        <span>0</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Min Amount Due:</b></span>
                                        <span>0</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Collateral Amount:</b></span>
                                        <span></span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Litigation Amount:</b></span>
                                        <span>*</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Secured/Unsecured:</b></span>
                                        <span>U</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Write Off Type:</b></span>
                                        <span></span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Recovery Amount</b></span>
                                        <span>0</span>
                                    </p>
                                </div>
                                <div class="d-inline w-33 text-left">
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Loan Type</b></span>
                                        <span>Term Loan</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Date of Last Payment Made</b></span>
                                        <span></span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Facility Date:</b></span>
                                        <span>14/02/2023</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Maturity Date</b></span>
                                        <span>15/03/2023</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Bounced Repayment Cheques</b></span>
                                        <span>0</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Security/Collateral:</b></span>
                                        <span></span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Write Off Amount:</b></span>
                                        <span>0</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Recovery Date:</b></span>
                                        <span></span>
                                    </p>
                                </div>
                                <div class="d-inline w-33 text-left">
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Position as of:</b></span>
                                        <span>28/02/2023</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Repayment Frequency:</b></span>
                                        <span></span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Classification Type:</b></span>
                                        <span>Regular</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Classification Nature:</b></span>
                                        <span></span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Restructuring Date:</b></span>
                                        <span></span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Restructuring Amount:</b></span>
                                        <span>*</span>
                                    </p>
                                    <p class="m-0 mb-5px f-12px">
                                        <span><b>Write Off Date:</b></span>
                                        <span></span>
                                    </p>
                                </div>
                            </div>
                            <div class="page-section-with-border">
                                <div class="blue-heading">
                                    <p><b>SUMMARY OF OVERDUES AND LATE PAYMENTS FOR LAST 24 MONTHS</b></p>
                                </div>
                                <div class="">
                                    <table class="table-with-blue-head">
                                        <thead>
                                            <tr>
                                                <th>Days</th>
                                                <th class="text-center">30+</th>
                                                <th class="text-center">60+</th>
                                                <th class="text-center">90+</th>
                                                <th class="text-center">120+</th>
                                                <th class="text-center">150+</th>
                                                <th class="text-center">180+</th>
                                                <th class="text-center">MFI Default</th>
                                                <th class="text-center">1 - 15</th>
                                                <th class="text-center">16 - 20</th>
                                                <th class="text-center">21 - 29</th>
                                                <th class="text-center">30</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><b>Times</b></td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">1</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">0</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                            <div class="page-section-with-border">
                                <div class="blue-heading">
                                    <p><b>DETAILS OF CREDIT HISTORY FOR LAST 24 MONTHS</b></p>
                                </div>
                                <div class="">
                                    <div class="box-wrapper">
                                        <div class="w-50">
                                            <table class="month-table">
                                                <thead>
                                                    <tr>
                                                        <th class="month-rospan-fix-bottom"><span>Month</span>
                                                        </th>
                                                        <th colspan="6">Amount Overdue </th>
                                                        <th class="white-rowspan-fix-bottom"><span>MFI Default</span>
                                                        </th>
                                                        <th class="white-rowspan-fix-bottom"><span>Late PMT Days</span>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th class="month-rospan-fix-top">
                                                        </th>
                                                        <th>30+</th>
                                                        <th>60+</th>
                                                        <th>90+</th>
                                                        <th>120+</th>
                                                        <th>150+</th>
                                                        <th>180+</th>
                                                        <th class="white-rowspan-fix-top">
                                                        </th>
                                                        <th class="white-rowspan-fix-top">
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>FEB 23</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                    </tr>
                                                    <tr>
                                                        <td>JAN 23</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                    </tr>
                                                    <tr>
                                                        <td>FEB 23</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                    </tr>
                                                    <tr>
                                                        <td>FEB 23</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="w-50">
                                            <table class="month-table">
                                                <thead>
                                                    <tr>
                                                        <th class="month-rospan-fix-bottom"><span>Month</span>
                                                        </th>
                                                        <th colspan="6">Amount Overdue </th>
                                                        <th class="white-rowspan-fix-bottom"><span>MFI Default</span>
                                                        </th>
                                                        <th class="white-rowspan-fix-bottom"><span>Late PMT Days</span>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th class="month-rospan-fix-top">
                                                        </th>
                                                        <th>30+</th>
                                                        <th>60+</th>
                                                        <th>90+</th>
                                                        <th>120+</th>
                                                        <th>150+</th>
                                                        <th>180+</th>
                                                        <th class="white-rowspan-fix-top">
                                                        </th>
                                                        <th class="white-rowspan-fix-top">
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>FEB 23</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                    </tr>
                                                    <tr>
                                                        <td>JAN 23</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                    </tr>
                                                    <tr>
                                                        <td>FEB 23</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                    </tr>
                                                    <tr>
                                                        <td>FEB 23</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                        <td>*</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div class="page-section-remarks">
                        <div class="px-10px">
                            <p class="mb-0">
                                <span class="remark-title"><b>Remarks:&ensp;</b></span>
                                <span>{{JSONdata.data.remarks}}</span>
                            </p>
                        </div>
                    </div>
                    <div class="page-section">
                        <div>
                            <p class="end-report-titile m-0"><b>--END OF REPORT--</b></p>
                        </div>
                        <div class="end-report-detail-section">
                            <p class="m-0 f-12px text-black">Please see below the terms/abbreviations used in this
                                report and
                                their definitions:</p>
                            <p class="m-0 f-12px text-black">*Fields and values displayed as blank or with a single
                                asterisk (*)
                                are those for which no data has been reported by any Financial Institution</p>
                            <p class="m-0 f-12px text-gray">**{{JSONdata.data.disclaimerText}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="footer">
                <div class="footer-wrapper">
                    <div class="d-inline w-32 text-left f-14px">
                        <p class="m-0">Report Ref No: 202307-46076439</p>
                    </div>
                    <div class="d-inline w-32 text-center f-14px">
                        <p class="m-0">**Disclaimer</p>
                    </div>
                    <div class="d-inline w-32 text-right f-14px">
                        <!-- <p class="m-0">Page 1 of 1</p> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>