<section>
    <div class="sidebar" [class.sidebar-close]="!_sharedSvc.openSidebar">
        <app-sidebar></app-sidebar>
    </div>
    <div class="home-section">
        <div class="home-main" [class.sidebar-close_home-main]="!_sharedSvc.openSidebar">
            <app-navbar></app-navbar>
            <div class="home-view">
                <section [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
                    [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
                    <div class="row mb-3">
                        <div class="col-sm-8 col-md-8 col-lg-8">
                            <h4> {{'indivGroupLoanForm.individualCreditApplication' | translate}}</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <div class="dashboard-card loan-table">
                                <form [formGroup]="searchPaymentForm" class="mb-3">
                                    <div class="row">
                                        <div class="col-sm-10 col-md-10 col-lg-10">
                                            <div class="row">
                                                <div class="col-sm-6 col-md-6 col-lg-4 mb-3 ">
                                                    <div class="form-floating">
                                                        <input type="text" style="text-transform: capitalize;"
                                                            autocomplete="off" formControlName="clntId"
                                                            class="form-control search-input ">
                                                        <label>{{'common.clientId' | translate}}:</label>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 col-md-6 col-lg-4 mb-3 ">
                                                    <div class="form-floating">
                                                        <input type="text" style="text-transform: capitalize;"
                                                            autocomplete="off" formControlName="cnic"
                                                            class="form-control search-input ">
                                                        <label>CNIC #:</label>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 col-md-6 col-lg-4 mb-3 ">
                                                    <div class="form-floating">
                                                        <input type="text" style="text-transform: capitalize;"
                                                            phoneMask="9999-9999999" autocomplete="off"
                                                            formControlName="phone" class="form-control search-input ">
                                                        <label>{{'common.phone' | translate}} #:</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-2 col-md-2 col-lg-2">
                                            <div class="button_div rec_searchBtn">
                                                <button class="btn login-button-class secondaryBtn marginAuto"
                                                    (click)="searchPaymentSchedule()">
                                                    {{'common.search' | translate}} <span> <i
                                                            class="fa fa-search"></i></span>
                                                </button>
                                            </div>
                                        </div>
                                        <!-- [value]="_recoveryService._recoverydata[0].clntName" -->
                                        <div class="col-sm-6 col-md-6 col-lg-4 mb-3 ">
                                            <div class="form-floating">
                                                <input style="text-transform: capitalize;" autocomplete="off"
                                                    class="form-control search-input readonly-field"
                                                    value="{{_recoverydata_0?.clntName}}"
                                                    [value]="_recoverydata_0?.clntNm">
                                                <label>{{'common.clientName' | translate}}:</label>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 col-md-6 col-lg-8 mb-3 ">
                                            <div class="form-floating">
                                                <input style="text-transform: capitalize;" autocomplete="off"
                                                    class="form-control search-input readonly-field"
                                                    [value]="_recoverydata_0?.clntAdr">
                                                <label>{{'common.address' | translate}}:</label>
                                            </div>
                                        </div>

                                    </div>
                                </form>
                                <div class="row mb-3" *ngIf="_recoveryService._recoverydata?.length > 0">
                                    <div class="col-sm-8 col-md-8 col-lg-8">
                                        <h4>{{'common.listing' | translate}}</h4>
                                    </div>
                                    <div class="col-sm-4 col-md-4 col-lg-4" *ngIf="showBtn">
                                        <div class="add-button" (click)="openModal()"><i class="fa fa-plus"></i></div>
                                    </div>
                                </div>
                                <div class="table-responsive" *ngIf="_recoveryService._recoverydata?.length > 0">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <!-- <td>Name</td> -->
                                                <td>{{'common.clientName'| translate}}</td>
                                                <td>{{'common.productName' | translate}}e</td>
                                                <td>{{'clientCreditCollateral.recommendedAmount' | translate}}</td>
                                                <td>{{'clientCreditCollateral.approvedAmount' | translate}}</td>
                                                <td>{{'common.status' | translate}}</td>
                                                <td class="text-center">{{'common.action' | translate}}</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let data of _recoveryService._recoverydata">
                                                 <td>{{data?.clntNm | titlecase}}</td>                                                
                                                <td>{{data?.productName | titlecase }}</td>
                                                <td>{{!!data?.rqstdAmount ? (data?.rqstdAmount | number) : "" }}</td>
                                                <td>{{!!data?.aprvdAmount ? (data?.aprvdAmount | number) : "" }}</td>
                                                <td>{{data?.caStatus | titlecase }}</td>
                                                <td class="text-center"
                                                    *ngIf="data?.caStatus?.toLowerCase() === 'draft' || (data?.caStatus === 'In Process' && allowedRoutes[0].rolId === 'CMO')">
                                                    <div class="action-inner">
                                                        <i class="fa fa-pencil" (click)="Edit(data)"></i>
                                                        <i class="fa fa-trash" (click)="DeletePopup(data)"></i>
                                                    </div>
                                                </td>
                                            </tr>
                                            <ng-container *ngIf="_recoveryService._recoverydata?.length === 0">
                                                <tr>
                                                    <td colspan="6" class="text-center"> {{'toast.selectAreaFirst' |
                                                        translate}}</td>
                                                </tr>
                                            </ng-container>
                                        </tbody>
                                    </table>
                                    <div class="row g-0" *ngIf="_recoveryService._recoverydata?.length > 0">
                                        <div class="col-sm-12 col-md-12 col-lg-6">
                                            <span class="clr-9d9a9a">{{'common.showing' | translate}}
                                                {{_recoveryService._recoverydata?.length}} {{'common.of' | translate}}
                                                {{_recoveryService._recoverydata?.length}} {{'common.entries' |
                                                translate}}</span>
                                        </div>
                                        <div class="col-sm-12 col-md-12 col-lg-6">
                                            <div class="float-right">
                                                <ngb-pagination [(page)]="_sharedSvc.page"
                                                    [pageSize]="_sharedSvc.pageSize" [maxSize]="10" ngDefaultControl
                                                    [collectionSize]="_sharedSvc.collectionSize" [rotate]="true"
                                                    (pageChange)="_creditAppSvc.pageChangeCAList()">
                                                    <ng-template ngbPaginationPrevious>{{'common.prev' |
                                                        translate}}</ng-template>
                                                    <ng-template ngbPaginationNext>{{'common.next' |
                                                        translate}}</ng-template>
                                                </ngb-pagination>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</section>