import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CookieService } from 'ngx-cookie-service';
import { Observable } from "rxjs";
import { CNICValidation } from "../../../shared/model/clintOnboarding";
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { environment } from "src/app/global";
import { SharedService } from "src/app/components/shared/service/shared.service";
import { SubmitCredit, SubmitSummary } from "../model/submit";
import { Endpoint } from "src/app/components/shared/serviceEndPoints";

@Injectable({
  providedIn: 'root'
})
export class SummaryService {

  getSummary: any;
  cnicNumFormate: any;
  mnthsResYears: any;
  mnthsResMonths: any;
  _phNum: any = null;
  mnthInBizYears: any;
  mnthInBizMonths: any;
  submitSummaryModel = new SubmitSummary()
  submitCreditModel = new SubmitCredit()
  clientStatusModel: ClientStatusVo = new ClientStatusVo();
  constructor(
    public router: Router,
    private _http: HttpClient,
    private _cookieSvc: CookieService,
    public _sharedSvc: SharedService,
  ) { }
  // APIS Summary Start 

  getClientSummary(clntSeq: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL + Endpoint.Summary.get_clnt_summary + clntSeq, this._sharedSvc.requestOptions);
  }
  getCASummary(crdtAppSeq: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL + Endpoint.Summary.get_ca_summary + crdtAppSeq, this._sharedSvc.requestOptions);
  }

  submitSummary() {
    
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL + Endpoint.Summary.submit_client, this.submitSummaryModel, this._sharedSvc.requestOptions);
  }

  submitCASummary() {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL + Endpoint.Summary.submit_credit, this.submitCreditModel, this._sharedSvc.requestOptions);
  }
  submitClientStatus() {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL + Endpoint.OnBoarding.UpdateClientStatus, this.clientStatusModel, this._sharedSvc.requestOptions);
  }
  // APIS Summary End 

  async InItGetClientSummary(clntSeq: any,callback:any) {
    this._sharedSvc.showSpinner()
    this.getClientSummary(clntSeq).subscribe({
      next: (response: any) => {
        if (response) {
          this.getSummary = response;
          // Calculation
          if (this.getSummary?.cnicNum) {
            var CNIC1 = this.getSummary?.cnicNum?.substring(0, 5);
            var CNIC2 = this.getSummary?.cnicNum?.substring(5, 12);
            var CNIC3 = this.getSummary?.cnicNum?.substring(12, 13);
            this.cnicNumFormate = CNIC1 + "-" + CNIC2 + "-" + CNIC3;
          }
          if (this.getSummary?.emplPhnNum) {
            var p1 = this.getSummary?.emplPhnNum?.substring(0, 4);
            var p2 = this.getSummary?.emplPhnNum?.substring(4, 11);
            this._phNum = p1 + "-" + p2;
          }
          let mnthsResYears = Math.floor(this.getSummary?.mnthsRes / 12);
          let mnthsResMonths = this.getSummary?.mnthsRes % 12;

          let year = "";
          let month = "";
          if (mnthsResYears > 0) {
            year = mnthsResYears + " Year ";
          }
          if (mnthsResMonths > 0) {
            month = mnthsResMonths + " Month ";
          }
          this.mnthsResYears = year + month

          let mnthInBizYears = Math.floor(this.getSummary?.mnthInBiz / 12);
          let mnthInBizMonths = this.getSummary?.mnthInBiz % 12;

          let year2 = "";
          let month2 = "";
          if (mnthInBizYears > 0) {
            year2 = mnthInBizYears + " Year ";
          }
          if (mnthInBizMonths > 0) {
            month2 = mnthInBizMonths + " Month ";
          }
          this.mnthInBizYears = year2 + month2
          // this.mnthInBizYears = mnthInBizYears + " Year, " + mnthInBizMonths + " Month "


          this._sharedSvc.hideSpinner();
          return callback(response);
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }

  async InItGetCASummary(crdtAppSeq: any,callback:any) {
    this._sharedSvc.showSpinner()
    this.getCASummary(crdtAppSeq).subscribe({
      next: (response: any) => {
        if (response) {
          this.getSummary = response;
          // Calculation
          if (this.getSummary?.cnicNum) {
            var CNIC1 = this.getSummary?.cnicNum?.substring(0, 5);
            var CNIC2 = this.getSummary?.cnicNum?.substring(5, 12);
            var CNIC3 = this.getSummary?.cnicNum?.substring(12, 13);
            this.cnicNumFormate = CNIC1 + "-" + CNIC2 + "-" + CNIC3;
          }
          if (this.getSummary?.emplPhnNum) {
            var p1 = this.getSummary?.emplPhnNum?.substring(0, 4);
            var p2 = this.getSummary?.emplPhnNum?.substring(4, 11);
            this._phNum = p1 + "-" + p2;
          }
          let mnthsResYears = Math.floor(this.getSummary?.mnthsRes / 12);
          let mnthsResMonths = this.getSummary?.mnthsRes % 12;
          this.mnthsResYears = mnthsResYears + " Year, " + mnthsResMonths + " Month "

          let mnthInBizYears = Math.floor(this.getSummary?.mnthInBiz / 12);
          let mnthInBizMonths = this.getSummary?.mnthInBiz % 12;
          this.mnthInBizYears = mnthInBizYears + " Year, " + mnthInBizMonths + " Month "


          this._sharedSvc.hideSpinner();
          return callback(response)
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }

}
export class ClientStatusVo {
  clntSeq: number;
  status: number;
  comment:string;
}
