import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'src/app/components/shared/service/shared.service';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { ClintOnboardingService } from 'src/app/components/clint-onboarding/shared/service/clint-onboarding.service';
import { SetupService } from '../shared/service/setup.service';
import { AdminService } from '../../admin/shared/service/admin.service';
import { AuthService } from 'src/app/auth/service/auth.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-usermanagement',
  templateUrl: './usermanagement.component.html',
  styleUrls: ['./usermanagement.component.css']
})
export class UsermanagementComponent implements OnInit {
  UserRoleList:any;
  ModesList:any;
  SubModesList:any;
  SelectedUserRoleId:number=11;
  SelectedUserRole:any;
  userAuthList:any;
  addRoleForm:any;
  singleRecord:any;
  isEdit:boolean;
  constructor(
    private modalService: NgbModal,
    public router: Router,
    public _sharedSvc: SharedService,
    public _clintOnboardingSvc: ClintOnboardingService,
    public _setupService: SetupService,
    private formBuilder: FormBuilder,
    private _adminService:AdminService,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.GetData();    
    this.createRoleForm();
  }
  createRoleForm() {
    this.addRoleForm = this.formBuilder.group({
      userRoleName: ["", Validators.required],
      userRoleComment: ["", Validators.required],
    });
  }
  get userRoleName() {
    return this.addRoleForm.get("userRoleName");
  }
  get userRoleComment() {
    return this.addRoleForm.get("userRoleComment");
  }
  GetData(){
    this._adminService.getRoleList((result)=>{
      if(result){
        this.UserRoleList= result;
      }
    });
    this._adminService.getModesList((result)=>{
      if(result){
        this.ModesList= result;
      }
    });
    this._adminService.getSubModesList((result)=>{
      if(result){
        this.SubModesList= result;
      }
    });
    this.getAuthListForRole(this.SelectedUserRoleId);
  }
   getAuthListForRole(id:number){
    this.SelectedUserRoleId=id;
    this._adminService.getAuthListForRole(id,(result)=>{
      if(result){
        this.userAuthList= result;
        this.SelectedUserRole=this.UserRoleList?.find(item=>item.userRoleSeq === this.SelectedUserRoleId);
      }
    });
    
   }
  showHideDiv(index: number) {
    this.ModesList[index].expanded = !this.ModesList[index].expanded;
  }

  getSubItems(id:string) {
    return this.SubModesList?.filter(subItem => subItem.modSeq === parseInt(id));
  }

  isModuleChecked(id: string): boolean {
    return this.userAuthList.some(item => item.sbModSeq === parseInt(id));
  }
  addModuleToUserAuthList(obj:any) {
    let data = {
      appAuthSeq:null,
      userRoleSeq: this.SelectedUserRole.userRoleSeq,
      subModuleSeq: obj.sbModSeq,
      readPermissionFlag: '1',
      writePermissionFlag: '1',
      deletePermissionFlag: '1',
      delFlg: false,
      afterClosing: '1'
    };
    this._adminService.AppAccessModel = data;
    this._sharedSvc.showSpinner3();
    this._adminService.callSaveAppAccess().subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.success(
            "Permission Added"
          );
          this._sharedSvc.hideSpinner3();
          this.modalService.dismissAll();
          this.isModuleChecked(obj.sbModSeq);
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner3();
        this.isModuleChecked(obj.sbModSeq);
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(
            ([key, value]: any, index: number) => {
              this._sharedSvc.error(value);
            }
          );
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    });
  }
  removeModuleFromUserAuthList(obj: any) {
    const authItem=this.userAuthList?.find(item=>item.sbModSeq === obj.sbModSeq);
    let data = {
      appAuthSeq: authItem?.authSeq,
      userRoleSeq: this.SelectedUserRole.userRoleSeq,
      subModuleSeq: obj.sbModSeq,
      readPermissionFlag: '1',
      writePermissionFlag: '1',
      deletePermissionFlag: '1',
      delFlg: true,
      afterClosing: '1'
     };
    this._adminService.AppAccessModel = data;
    this._sharedSvc.showSpinner3();
    this._adminService.callSaveAppAccess().subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.success(
            "Permission Deleted"
          );
          this._sharedSvc.hideSpinner3();
          this.modalService.dismissAll();

        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner3();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(
            ([key, value]: any, index: number) => {
              this._sharedSvc.error(value);
            }
          );
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    });
  }
  
  handleCheckboxChange(item:any, event: Event) {
    const target = event.target as HTMLInputElement;
  
    if (target.checked) {
      this.addModuleToUserAuthList(item);
    } else {
      this.removeModuleFromUserAuthList(item);
    }
    console.log(this.userAuthList)
  } 

  submitRoleForm(val) {
    if (this.addRoleForm.invalid) {
      for (const control of Object.keys(this.addRoleForm.controls)) {
        this.addRoleForm.controls[control].markAsTouched();
      }
      return;
    }
    let data = {
      ...this.addRoleForm.value,
      userRoleId:this.addRoleForm.get("userRoleName").value,
      userRoleSeq: null,
      delFlg: false,
    };
    if (this.isEdit) {
      data.userRoleSeq = this.singleRecord.userRoleSeq;
    }
    this._adminService.UserRoleModel = data;
    this._sharedSvc.showSpinner3();
    this._adminService.callSaveUserRole().subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.success(
            "Role" + (this.isEdit === true ? " Updated" : " Added")
          );
          this._sharedSvc.hideSpinner3();
          this.modalService.dismissAll();
          this.addRoleForm.reset();
          this._adminService.getRoleList((result)=>{
            if(result){
              this.UserRoleList= result;
            }
          });
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner3();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(
            ([key, value]: any, index: number) => {
              this._sharedSvc.error(value);
            }
          );
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    });
  }
  editRole(content: any, id: any, edit: any) {
    let record = this.UserRoleList?.find((x) => x.userRoleSeq === id);
    this.singleRecord = record;
    if (record) {
      let data = {
        userRoleName: record.userRoleName,
        userRoleComment: record.userRoleComment,
      };
      this.addRoleForm.patchValue(data);
      this.openModal(content, edit);
    }
  }
  deleteRecord(id) {
    let record = this.UserRoleList?.find((x) => x.userRoleSeq === id);
    if (record) {
      let data = {
        ...record,
        delFlg: 1,
      };
      this._adminService.UserRoleModel = data;
      this._sharedSvc.showSpinner3();
      this._adminService.callSaveUserRole().subscribe({
        next: (response) => {
          if (response) {
            this._sharedSvc.success(
              "Role Deleted"
            );
            this._sharedSvc.hideSpinner3();
            this.getAuthListForRole(this.SelectedUserRoleId);
            this._adminService.getRoleList((result)=>{
              if(result){
                this.UserRoleList= result;
              }
            });
          }
        },
        error: (e) => {
          this._sharedSvc.hideSpinner3();
          if (e.status == 401) {
            this._sharedSvc.error(this._sharedSvc.authMsg);
            this._sharedSvc.logout();
            return;
          }
          if (e?.error) {
            Object.entries(e?.error).forEach(
              ([key, value]: any, index: number) => {
                this._sharedSvc.error(value);
              }
            );
          }
          if (e?.error?.errorMessage) {
            this._sharedSvc.error(e?.error?.error);
          }
        },
      });
    }
  }
  openModal(content: any, edit: boolean) {
    this.isEdit = edit;
    this.modalService.open(content, {
      windowClass: "popupWidth800",
      centered: true,
    });
  }
}