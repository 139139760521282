import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CookieService } from 'ngx-cookie-service';
import { Observable } from "rxjs";
import { CNICValidation } from "../../../shared/model/clintOnboarding";
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { environment } from "src/app/global";
import { SharedService } from "src/app/components/shared/service/shared.service";
import { FinancialInfo, SaveClntExpnsDtl, SaveClntIncmDtl } from "../model/financial-info";
import { PicklistService } from "src/app/components/shared/pickList/picklist.service";
import { Endpoint } from "src/app/components/shared/serviceEndPoints";

@Injectable({
  providedIn: 'root'
})

export class FiniancialInfoService {

  saveClntIncmDtlModel = new SaveClntIncmDtl();
  saveClntExpnsDtlModel = new SaveClntExpnsDtl();
  getAllIncome: any = [];
  getAllPriIncome: any;
  getAllSecIncome: any;
  getClntAllSPriIncmDtlSeqPG: any;
  getClntAllSIncmDtlSeqPG: any;
  getClntAllIncmDtlSeqPG: any;
  getAllExpense: any = [];
  getClntAllExpnsDtlSeqPG: any;
  edit: boolean = false;
  tempIncAmt: any;
  totalPrimaryAmt: any = 0;
  totalSecondaryAmt: any = 0;
  totalIncmAmt: any = 0;
  totalExpAmt: any = 0;
  totalHouseHold: any = 0;
  totalSalaryAmt: any = 0;
  TBP: any = 0;
  NDI: any = 0;
  DBR1: any = 0;
  DBR2: any = 0;
  EMR: any = 0;
  lSize: any = 0;
  IIE: any = 0;
  employerCalculation: boolean = false;
  saveBusinessIncModel: any = [];
  saveBusinessExpModel: any = [];
  savePrimaryDataModel: any = [];
  saveSecondaryDataModel: any = [];
  saveHouseHldModel: any = [];
  saveSalaryDataModel: any = [];
  salaryDetail: any;
  businessIncome: any;
  businessExpenses: any;
  householdExpenses: any;



  constructor(
    public router: Router,
    private _http: HttpClient,
    private _cookieSvc: CookieService,
    public _sharedSvc: SharedService,
    public _picklistService: PicklistService,
  ) { }

  // APIS FinancialInfo Start 
  saveClntIncmDtl() {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL + Endpoint.FinancialInfo.save_clnt_incm_dtl, this.saveClntIncmDtlModel, this._sharedSvc.requestOptions);
  }

  getClntIncmDtl(incmDtlSeq: any, incmCtgryKey: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL + Endpoint.FinancialInfo.get_clnt_incm_dtl + incmDtlSeq + "/" + incmCtgryKey, this._sharedSvc.requestOptions);
  }

  getClntIncmDtlSeq(clnt_seq: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL + Endpoint.FinancialInfo.get_clnt_incm_dtl_seq + clnt_seq, this._sharedSvc.requestOptions);
  }

  getClntAllIncmDtlSeq(clnt_seq: any, incmCtgryKey: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL + Endpoint.FinancialInfo.get_clnt_all_incm_dtl + clnt_seq + "/" + incmCtgryKey, this._sharedSvc.requestOptions);
  }

  saveClntExpnsDtl() {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL + Endpoint.FinancialInfo.save_clnt_expns_dtl, this.saveClntExpnsDtlModel, this._sharedSvc.requestOptions);
  }

  getClntAllExpnsDtlSeq(clnt_seq: any, expnsCtgryKey: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL + Endpoint.FinancialInfo.get_all_clnt_expns_dtl + clnt_seq + "/" + expnsCtgryKey, this._sharedSvc.requestOptions);
  }
  // APIS FinancialInfo End 


  InItGetClntIncmDtl(incmDtlSeq: any, incmCtgryKey: any): void {
    this.getClntIncmDtl(incmDtlSeq, incmCtgryKey).subscribe({
      next: (response: any) => {
        if (response) {
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }

  InItGetClntIncmDtlSeq(clnt_seq: any): void {
    this.getClntIncmDtlSeq(clnt_seq).subscribe({
      next: (response: any) => {
        if (response) {
          this.saveClntIncmDtlModel = Object.assign({}, response);
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }

  InItGetClntAllIncmDtlSeq(clnt_seq: any, incmCtgryKey: any): void {
    // try {
    //   this._sharedSvc.showSpinner();
    //   const data = await this.getClntAllIncmDtlSeq(clnt_seq, incmCtgryKey).toPromise();
    //   if (data) {
    //     this._sharedSvc.hideSpinner();
    //     this.calculationOfIncomeAmounts(incmCtgryKey, data);
    //     // this.calculationTExp(incmCtgryKey);
    //     this.formulasCal();
    //   }

    // } catch (error) {
    //   this._sharedSvc.hideSpinner();
    //   Object.entries(error).forEach(([key, value]: any, index: number) => {
    //     this._sharedSvc.error(value);
    //   });
    //   console.error('Error fetching safe list:', error);
    //   throw error;
    // }


    this._sharedSvc.showSpinner();
    this.getClntAllIncmDtlSeq(clnt_seq, incmCtgryKey).subscribe({
      next: (response: any) => {
        if (response) {
          this._sharedSvc.hideSpinner();
          if (incmCtgryKey === 3) {
            localStorage.setItem('bizAprsIncome', JSON.stringify(response))
          }
          this.calculationOfIncomeAmounts(incmCtgryKey, response);
          // this.calculationTExp(incmCtgryKey);
          this.formulasCal();
        }

        if (response.length == 0) {
          this.calculationOfIncomeAmounts(incmCtgryKey, response);
          // this.calculationTExp(incmCtgryKey);
          this.formulasCal();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }

  calculationOfIncomeAmounts(incmCtgryKey: any, response: any) {
    this._sharedSvc.hideSpinner();
    if (incmCtgryKey == 1) {
      this.getAllPriIncome = response;
      localStorage.setItem('savePrimaryDataModel', JSON.stringify(this.getAllPriIncome))

    }
    if (incmCtgryKey == 2) {
      this.getAllSecIncome = response;
      localStorage.setItem('saveSecondaryDataModel', JSON.stringify(this.getAllSecIncome))

    }
    if (incmCtgryKey == 3 || incmCtgryKey == 4) {
      this.getAllIncome = response;
      localStorage.setItem('saveSalaryDataModel', JSON.stringify(this.getAllIncome))
    }
    this.pageChange(incmCtgryKey);
    this.totalIncmAmt = 0;
    this.tempIncAmt = 0;

    if (incmCtgryKey == 1) {
      for (let i = 0; i < this.getAllPriIncome?.length; i++) {
        let incmAmt = this.getAllPriIncome[i].incmAmt;
        this.totalIncmAmt = this.totalIncmAmt + incmAmt;
        localStorage.setItem('totalPrimaryAmt', this.totalIncmAmt)
      }
      if (this.getAllPriIncome?.length == 0) {
        localStorage.removeItem('totalPrimaryAmt');
      }
    }
    if (incmCtgryKey == 2) {
      for (let i = 0; i < this.getAllSecIncome?.length; i++) {
        let incmAmt = this.getAllSecIncome[i].incmAmt;
        this.totalIncmAmt = this.totalIncmAmt + incmAmt;
        localStorage.setItem('totalSecondaryAmt', this.totalIncmAmt)

      }
      if (this.getAllSecIncome?.length == 0) {
        localStorage.removeItem('totalSecondaryAmt');
      }
    }
    if (incmCtgryKey == 3) {
      for (let i = 0; i < this.getAllIncome?.length; i++) {
        let incmAmt = this.getAllIncome[i].avgMnthlyIncm;
        this.totalIncmAmt = this.totalIncmAmt + incmAmt;
        localStorage.setItem('totalIncmAmt', this.totalIncmAmt);
      }
      if (this.getAllIncome?.length == 0) {
        localStorage.removeItem('totalIncmAmt');
      }
    }

    if (incmCtgryKey == 4) {
      this.totalSalaryAmt = 0;
      for (let i = 0; i < this.getAllIncome?.length; i++) {
        if (this.getAllIncome[i].delFlg == false) {
          let avgMnthlyIncm = this.getAllIncome[i].avgMnthlyIncm;
          this.totalSalaryAmt = this.totalSalaryAmt + avgMnthlyIncm;
          localStorage.setItem('totalSalaryAmt', this.totalSalaryAmt);
        }
      }
      if (this.getAllIncome?.length == 0) {
        localStorage.removeItem('totalSalaryAmt');
      }
    }
    // this.calculationTExp(incmCtgryKey);
    this.formulasCal();

  }

  async getEmpSalary(clnt_seq: any, incmCtgryKey: any): Promise<any> {
    try {
      this._sharedSvc.showSpinner3();
      const response = await this.getClntAllIncmDtlSeq(clnt_seq, incmCtgryKey).toPromise();
      if (incmCtgryKey === 4) {
        localStorage.setItem('empIncome', JSON.stringify(response))
      }
      this._sharedSvc.hideSpinner3();
    }
    catch (error) {
      console.error('Error fetching employee salary:', error);
      throw error;
    }
  }

  pageChange(incmCtgryKey?: any) {
    if (incmCtgryKey == 1) {
      // Primary Income
      this._sharedSvc.collectionSize = this.getAllPriIncome?.length;
      this.getClntAllSPriIncmDtlSeqPG = this.getAllPriIncome.slice((this._sharedSvc.page - 1) * this._sharedSvc.pageSize, (this._sharedSvc.page - 1) * this._sharedSvc.pageSize + this._sharedSvc.pageSize);
    }
    if (incmCtgryKey == 2) {
      //Secondary Income
      this._sharedSvc.collectionSize = this.getAllSecIncome?.length;
      this.getClntAllSIncmDtlSeqPG = this.getAllSecIncome.slice((this._sharedSvc.page - 1) * this._sharedSvc.pageSize, (this._sharedSvc.page - 1) * this._sharedSvc.pageSize + this._sharedSvc.pageSize);
    }
    if (incmCtgryKey == 3) {

      this._sharedSvc.collectionSize = this.getAllIncome?.length;
      this.getClntAllIncmDtlSeqPG = this.getAllIncome.slice((this._sharedSvc.page - 1) * this._sharedSvc.pageSize, (this._sharedSvc.page - 1) * this._sharedSvc.pageSize + this._sharedSvc.pageSize);
    }
    if (incmCtgryKey == 4) {

      this._sharedSvc.collectionSize = this.getAllIncome?.length;
      this.getClntAllIncmDtlSeqPG = this.getAllIncome.slice((this._sharedSvc.page - 1) * this._sharedSvc.pageSize, (this._sharedSvc.page - 1) * this._sharedSvc.pageSize + this._sharedSvc.pageSize);
    }

  }

  InItGetClntAllExpnsDtlSeq(clnt_seq: any, expnsCtgryKey: any): void {

    // try {
    //   this._sharedSvc.showSpinner();
    //   const data = await this.getClntAllExpnsDtlSeq(clnt_seq, expnsCtgryKey).toPromise();
    //   if (data) {
    //     this.getAllExpense = data;
    //     localStorage.setItem('saveBusinessExpModel', JSON.stringify(this.getAllExpense))
    //     this.getClntAllExpnsDtlSeqPG = this.getAllExpense
    //     this.calculationTExp(expnsCtgryKey);
    //     this.formulasCal();
    //     this._sharedSvc.hideSpinner();
    //   }

    // } catch (error) {
    //   this._sharedSvc.hideSpinner();
    //   console.error('Error fetching safe list:', error);
    //   Object.entries(error).forEach(([key, value]: any, index: number) => {
    //     this._sharedSvc.error(value);
    //   });
    //   throw error;
    // }


    this._sharedSvc.showSpinner();
    this.getClntAllExpnsDtlSeq(clnt_seq, expnsCtgryKey).subscribe({
      next: (response: any) => {
        if (response) {
          
          this.getAllExpense = response;
          localStorage.setItem('saveBusinessExpModel', JSON.stringify(this.getAllExpense))
          if (expnsCtgryKey === 1) {
            localStorage.setItem('bizAprsExpense', JSON.stringify(response));
          }
          if (expnsCtgryKey === 2) {
            localStorage.setItem('hozHoldExpense', JSON.stringify(response));
          }
          this.getClntAllExpnsDtlSeqPG = this.getAllExpense
          this.calculationTExp(expnsCtgryKey);
          this.formulasCal();
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }

  calculationTExp(expnsCtgryKey: any) {
    this.totalExpAmt = 0;
    this.totalHouseHold = 0;
    this.IIE = localStorage.getItem('IIE');
    this.IIE = JSON.parse(this.IIE);
    this.totalHouseHold = this.IIE;
    for (let i = 0; i < this.getAllExpense?.length; i++) {

      if (this.getAllExpense[i].delFlg == false) {
        let expAmt = this.getAllExpense[i].expAmt;
        this.totalExpAmt = this.totalExpAmt + expAmt;
      }


    }
    if (expnsCtgryKey == 2) {
      localStorage.setItem('totalHouseHold', this.totalExpAmt);
      this.IIE = localStorage.getItem('IIE');
      this.IIE = JSON.parse(this.IIE);
      this.totalHouseHold = this.totalExpAmt + this.totalHouseHold;
    }
    if (expnsCtgryKey == 1) {
      this.totalExpAmt = (this.totalExpAmt);
      localStorage.setItem('totalExpAmt', this.totalExpAmt);
    }
    this.formulasCal();
  }

  pageChangeEx() {
    // this._sharedSvc.collectionSize = this.getAllExpense?.length;
    // this.getClntAllExpnsDtlSeqPG = this.getAllExpense?.slice((this._sharedSvc.page - 1) * this._sharedSvc.pageSize, (this._sharedSvc.page - 1) * this._sharedSvc.pageSize + this._sharedSvc.pageSize);
  }

  dropdownsDef(data?: any, incmCtgryKey?: any) {

    if (incmCtgryKey == 1) {
      this.getAllIncome = this.getAllPriIncome;
    }
    if (incmCtgryKey == 2) {
      this.getAllIncome = this.getAllSecIncome;
    }
    if (this.getAllIncome && this._picklistService.INCOME_TYPE_Data) {
      let array = this.getAllIncome;
      let dropD = [...this._picklistService.INCOME_TYPE_Data];
      for (let i = 0; i < array?.length; i++) {
        for (let j = 0; j < dropD?.length; j++) {
          if (dropD[j].refCdSeq == array[i].incmTypKey && array[i].incmTypKey != data?.incmTypKey) {
            dropD.splice(j, 1);
          }
        }
      }
      this._picklistService.INCOME_TYPE_DataClone = [...dropD];
    }
    if (this.getAllIncome && this._picklistService.BUSINESS_RUNNER_Data) {
      let array = this.getAllIncome;
      let dropD = [...this._picklistService.BUSINESS_RUNNER_Data];
      for (let i = 0; i < array?.length; i++) {
        for (let j = 0; j < dropD?.length; j++) {
          if (dropD[j].refCdSeq == array[i].incmTypKey && array[i].incmTypKey != data?.incmTypKey) {
            dropD.splice(j, 1);
          }
        }
      }
      this._picklistService.BUSINESS_RUNNER_DataClone = [...dropD];
    }
    if (this.getAllExpense && this._picklistService.HOUSEHOLD_EXPENSES_Data) {
      let array2 = this.getAllExpense;
      let dropD2 = [...this._picklistService.HOUSEHOLD_EXPENSES_Data];
      for (let i = 0; i < array2?.length; i++) {
        for (let j = 0; j < dropD2?.length; j++) {
          if (dropD2[j].refCdSeq == array2[i].expTypKey && array2[i].expTypKey != data?.expTypKey) {
            dropD2.splice(j, 1);
          }
        }
      }
      this._picklistService.HOUSEHOLD_EXPENSES_DataClone = [...dropD2];
    }
    if (this.getAllExpense && this._picklistService.BUSINESS_EXPENSES_Data) {
      let array3 = this.getAllExpense;
      let dropD3 = [...this._picklistService.BUSINESS_EXPENSES_Data];
      for (let i = 0; i < array3?.length; i++) {
        for (let j = 0; j < dropD3?.length; j++) {
          if (dropD3[j].refCdSeq == array3[i].expTypKey && array3[i].expTypKey != data?.expTypKey) {
            dropD3.splice(j, 1);
          }
        }
      }
      this._picklistService.BUSINESS_EXPENSES_DataClone = [...dropD3];
    }
  }

  dropdowns(incmCtgryKey?: any) {
    if (incmCtgryKey == 1) {
      this.getAllIncome = this.getAllPriIncome;
    }
    if (incmCtgryKey == 2) {
      this.getAllIncome = this.getAllSecIncome;
    }
    if (this.getAllIncome && this._picklistService.INCOME_TYPE_Data) {
      let array = this.getAllIncome;
      let dropD = [...this._picklistService.INCOME_TYPE_Data];
      for (let i = 0; i < array?.length; i++) {
        for (let j = 0; j < dropD?.length; j++) {
          if (dropD[j].refCdSeq == array[i].incmTypKey && array[i].delFlg == false) {
            dropD.splice(j, 1);
          }
        }
      }
      this._picklistService.INCOME_TYPE_DataClone = [...dropD];
    }
    if (this.getAllIncome && this._picklistService.BUSINESS_RUNNER_Data) {
      let array = this.getAllIncome;
      let dropD = [...this._picklistService.BUSINESS_RUNNER_Data];
      for (let i = 0; i < array?.length; i++) {
        for (let j = 0; j < dropD?.length; j++) {
          if (dropD[j].refCdSeq == array[i].incmTypKey && array[i].delFlg == false) {
            dropD.splice(j, 1);
          }
        }
      }
      this._picklistService.BUSINESS_RUNNER_DataClone = [...dropD];
    }
    if (this.getAllExpense && this._picklistService.HOUSEHOLD_EXPENSES_Data) {
      let array2 = this.getAllExpense;
      let dropD2 = [...this._picklistService.HOUSEHOLD_EXPENSES_Data];
      for (let i = 0; i < array2?.length; i++) {
        for (let j = 0; j < dropD2?.length; j++) {
          if (dropD2[j].refCdSeq == array2[i].expTypKey) {
            dropD2.splice(j, 1);
          }
        }
      }
      this._picklistService.HOUSEHOLD_EXPENSES_DataClone = [...dropD2];
    }
    if (this.getAllExpense && this._picklistService.BUSINESS_EXPENSES_Data) {
      let array3 = this.getAllExpense;
      let dropD3 = [...this._picklistService.BUSINESS_EXPENSES_Data];
      for (let i = 0; i < array3?.length; i++) {
        for (let j = 0; j < dropD3?.length; j++) {
          if (dropD3[j].refCdSeq == array3[i].expTypKey && array3[i].delFlg == false) {
            dropD3.splice(j, 1);
          }
        }
      }
      this._picklistService.BUSINESS_EXPENSES_DataClone = [...dropD3];
    }
  }

  formulasCal() {
    if (localStorage.getItem('totalPrimaryAmt')) {
      this.totalPrimaryAmt = localStorage.getItem('totalPrimaryAmt');
      this.totalPrimaryAmt = JSON.parse(this.totalPrimaryAmt);
      this.totalPrimaryAmt = Math.round(this.totalPrimaryAmt);
    }
    if (localStorage.getItem('totalSecondaryAmt')) {
      this.totalSecondaryAmt = localStorage.getItem('totalSecondaryAmt');
      this.totalSecondaryAmt = JSON.parse(this.totalSecondaryAmt);
      this.totalSecondaryAmt = Math.round(this.totalSecondaryAmt);
    }
    if (localStorage.getItem('totalIncmAmt')) {
      this.totalIncmAmt = localStorage.getItem('totalIncmAmt');
      this.totalIncmAmt = JSON.parse(this.totalIncmAmt);
      this.totalIncmAmt = Math.round(this.totalIncmAmt);
    }
    if (localStorage.getItem('totalExpAmt')) {
      this.totalExpAmt = localStorage.getItem('totalExpAmt');
      this.totalExpAmt = JSON.parse(this.totalExpAmt);
      this.totalExpAmt = Math.round(this.totalExpAmt);
    }
    if (localStorage.getItem('totalHouseHold')) {
      this.totalHouseHold = localStorage.getItem('totalHouseHold');
      this.totalHouseHold = JSON.parse(this.totalHouseHold);
      this.totalHouseHold = Math.round(this.totalHouseHold);
      this.IIE = localStorage.getItem('IIE');
      this.IIE = JSON.parse(this.IIE);
      this.IIE = Math.round(this.IIE);
      this.totalHouseHold = this.totalHouseHold + this.IIE;
    }
    if (localStorage.getItem('totalSalaryAmt')) {
      this.totalSalaryAmt = localStorage.getItem('totalSalaryAmt');
      this.totalSalaryAmt = JSON.parse(this.totalSalaryAmt);
      this.totalSalaryAmt = Math.round(this.totalSalaryAmt);
    }
    if (!this._sharedSvc.EmployerInfo_FS) {
      this.TBP = this.totalIncmAmt - this.totalExpAmt;
      this.NDI = (this.totalIncmAmt - this.totalExpAmt) + this.totalPrimaryAmt + this.totalSecondaryAmt - this.totalHouseHold;
      this.DBR1 = ((this.totalIncmAmt - this.totalExpAmt) / 100) * 60;
      this.DBR2 = (this.totalIncmAmt - this.totalExpAmt) - this.totalHouseHold;
      if (this.DBR1 > this.DBR2) {
        this.EMR = this.DBR2;
      }
      if (this.DBR1 < this.DBR2) {
        this.EMR = this.DBR1;
      }
      this.lSize = this.EMR * 12;
    }
    if (this._sharedSvc.EmployerInfo_FS) {
      this.TBP = this.totalSalaryAmt;
      this.NDI = this.totalSalaryAmt + this.totalPrimaryAmt + this.totalSecondaryAmt - this.totalHouseHold;
      this.DBR1 = (this.totalSalaryAmt / 100) * 60;
      this.DBR2 = this.totalSalaryAmt - this.totalHouseHold;
      if (this.DBR1 > this.DBR2) {
        this.EMR = this.DBR2;
      }
      if (this.DBR1 < this.DBR2) {
        this.EMR = this.DBR1;
      }
      this.lSize = this.EMR * 12;
    }
  }

  InItGetClntAllIncmDtlSeq2(clnt_seq: any, incmCtgryKey: any): void {
    this._sharedSvc.showSpinner();
    this.getClntAllIncmDtlSeq(clnt_seq, incmCtgryKey).subscribe({
      next: (response: any) => {
        if (response) {
          this._sharedSvc.hideSpinner();
          this.calculationOfIncomeAmounts(incmCtgryKey, response);
          // this.calculationTExp(incmCtgryKey);
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    })
  }

  InItGetClntAllIncmDtlSeq3(clnt_seq: any, incmCtgryKey: any): void {
    this._sharedSvc.showSpinner();
    this.getClntAllIncmDtlSeq(clnt_seq, incmCtgryKey).subscribe({
      next: (response: any) => {
        if (response) {
          this._sharedSvc.hideSpinner();
          this.calculationOfIncomeAmounts(incmCtgryKey, response);
          // this.calculationTExp(incmCtgryKey);
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    })
  }

  InItGetClntAllIncmDtlSeq4(clnt_seq: any, incmCtgryKey: any): void {
    this._sharedSvc.showSpinner();
    this.getClntAllIncmDtlSeq(clnt_seq, incmCtgryKey).subscribe({
      next: (response: any) => {
        if (response) {
          this._sharedSvc.hideSpinner();
          this.calculationOfIncomeAmounts(incmCtgryKey, response);
          // this.calculationTExp(incmCtgryKey);
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    })
  }
}
