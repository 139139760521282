<section>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12">
      <div class="mt-3">
        <div class="row mb-3">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="btnCenter otherOutstanding mb-3">
              <h3>
                {{
                  "clientOnboarding.clientDocTab.clientDocuments" | translate
                }}
              </h3>
            </div>
            <div class="btnCenter otherOutstanding">
              <h6>
                {{ "common.clintName" | translate }}:
                <b>{{ saveCnicData?.frstNm | titlecase }}</b> /
                {{ "common.clintId" | translate }}:
                <b>{{ saveCnicData?.clntId }}</b>
              </h6>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <form>
              <div class="row mt-3 mb-3">
                <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                  <div class="cnic_box dashboard-card dashboard-model">
                    <div
                      class="dashes"
                      [class.disableForm]="
                        _conditionsService.readOnlyView_Client
                      "
                    >
                      <button (click)="compressFile('cnicFUrl')">
                        <span class="hoverable cnic_box">
                          <div class="vertical-center" *ngIf="!cnicFUrl">
                            <i class="fa fa-picture-o mb-3"></i>
                            <p (click)="compressFile('cnicFUrl')">
                              {{
                                "clientOnboarding.clientDocTab.attachCnicFront"
                                  | translate
                              }}
                            </p>
                          </div>
                          <div class="vertical-center-img" *ngIf="cnicFUrl">
                            <img
                              name="cnicFUrl"
                              [src]="cnicFUrl ? cnicFUrl : ''"
                            />
                          </div>
                          <div class="hover-text">
                            {{ "common.chooseFile" | translate }}
                          </div>
                        </span>
                      </button>
                    </div>
                    <div *ngIf="cnicFUrl">
                      <div class="text-center pointer mt-2">
                        <span
                          (click)="
                            openModal(
                              cnicFUrlModel,
                              'CNIC ' + this.translate.instant('common.front')
                            )
                          "
                        >
                          {{ "common.showImage" | translate
                          }}<i class="fa fa-angle-double-down"></i
                        ></span>
                      </div>
                    </div>
                    <div class="btnCenter"></div>
                  </div>
                  <br />
                  <div class="btnCenter">
                    <p>CNIC {{ "common.front" | translate }}</p>
                  </div>
                  <div class="btnCenter"></div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                  <div class="cnic_box dashboard-card dashboard-model">
                    <div
                      class="dashes"
                      [class.disableForm]="
                        _conditionsService.readOnlyView_Client
                      "
                    >
                      <button (click)="compressFile('cnicBUrl')">
                        <span class="hoverable cnic_box">
                          <div class="vertical-center" *ngIf="!cnicBUrl">
                            <i class="fa fa-picture-o mb-3"></i>
                            <p>
                              {{
                                "clientOnboarding.clientDocTab.attachCnicBack"
                                  | translate
                              }}
                            </p>
                          </div>
                          <div class="vertical-center-img" *ngIf="cnicBUrl">
                            <img
                              name="cnicBUrl"
                              [src]="cnicBUrl ? cnicBUrl : ''"
                            />
                          </div>
                          <div class="hover-text">
                            {{ "common.chooseFile" | translate }}
                          </div>
                        </span>
                      </button>
                    </div>
                    <div *ngIf="cnicBUrl">
                      <div class="text-center pointer mt-2">
                        <span
                          (click)="
                            openModal(
                              cnicBUrlModel,
                              'CNIC ' + this.translate.instant('common.back')
                            )
                          "
                          >{{ "common.showImage" | translate }}
                          <i class="fa fa-angle-double-down"></i
                        ></span>
                      </div>
                    </div>
                    <div class="btnCenter"></div>
                  </div>
                  <br />
                  <div class="btnCenter">
                    <p>CNIC {{ "common.back" | translate }}</p>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                  <div class="cnic_box dashboard-card dashboard-model">
                    <div
                      class="dashes"
                      [class.disableForm]="
                        _conditionsService.readOnlyView_Client
                      "
                    >
                      <button (click)="compressFile('userUrl')">
                        <span class="hoverable cnic_box">
                          <div class="vertical-center" *ngIf="!userUrl">
                            <i class="fa fa-user-o mb-3"></i>
                            <p>
                              {{
                                "clientOnboarding.clientDocTab.attachClientPicture"
                                  | translate
                              }}
                            </p>
                          </div>
                          <div class="vertical-center-img" *ngIf="userUrl">
                            <img
                              name="userUrl"
                              [src]="userUrl ? userUrl : ''"
                            />
                          </div>
                          <div class="hover-text">
                            {{ "common.chooseFile" | translate }}
                          </div>
                        </span>
                      </button>
                    </div>
                    <div *ngIf="userUrl">
                      <div class="text-center pointer mt-2">
                        <span
                          (click)="
                            openModal(
                              userUrlModel,
                              this.translate.instant(
                                'clientOnboarding.clientDocTab.clientPicture'
                              )
                            )
                          "
                          >{{ "common.showImage" | translate }}
                          <i class="fa fa-angle-double-down"></i
                        ></span>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="btnCenter">
                    <p>
                      {{
                        "clientOnboarding.clientDocTab.clientPicture"
                          | translate
                      }}
                    </p>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                  <div class="cnic_box dashboard-card dashboard-model">
                    <div
                      class="dashes"
                      [class.disableForm]="
                        _conditionsService.readOnlyView_Client
                      "
                    >
                      <button (click)="compressFile('repUrl')">
                        <span class="hoverable cnic_box">
                          <div class="vertical-center" *ngIf="!repUrl">
                            <i class="fa fa-file-o mb-3"></i>
                            <p>
                              {{
                                "clientOnboarding.clientDocTab.tasdeeqConsentForm"
                                  | translate
                              }}
                            </p>
                          </div>
                          <div class="vertical-center-img" *ngIf="repUrl">
                            <img name="repUrl" [src]="repUrl ? repUrl : ''" />
                          </div>
                          <div class="hover-text">
                            {{ "common.chooseFile" | translate }}
                          </div>
                        </span>
                      </button>
                    </div>
                    <div *ngIf="repUrl">
                      <div class="text-center pointer mt-2">
                        <span
                          (click)="
                            openModal(
                              repUrlModel,
                              this.translate.instant(
                                'clientOnboarding.clientDocTab.tasdeeqConsentForm'
                              )
                            )
                          "
                          >{{ "common.showImage" | translate }}
                          <i class="fa fa-angle-double-down"></i
                        ></span>
                      </div>
                    </div>
                    <div class="btnCenter"></div>
                  </div>
                  <br />
                  <div class="btnCenter">
                    <p>
                      {{
                        "clientOnboarding.clientDocTab.tasdeeqConsentForm"
                          | translate
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </form>
            <div class="row mb-3">
              <div class="col-sm-12 col-md-12 col-lg-12">
                <div class="button_div">
                  <button
                    class="btn login-button-class"
                    (click)="continue()"
                    (click)="continueForCheck()"
                  >
                    {{ "common.continue" | translate }}
                  </button>
                  <button
                    class="btn login-button-class secondaryBtn"
                    [class.pointerNone]="_sharedSvc.loading"
                    *ngIf="!_conditionsService.readOnlyView_Client"
                    [disabled]="_sharedSvc.loading"
                    (click)="continue()"
                  >
                    {{ "common.save" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
    </div>
  </div>
</section>
<ng-template id="display-modal" #cnicFUrlModel let-c="close" let-d="dismiss">
  <div class="p-3 pb-0">
    <div class="modal-header">
      <h4>{{ modelHeading }}</h4>
      <div class="header_cross" (click)="d('Cross click')">
        <h2>&times;</h2>
      </div>
    </div>
    <div class="modal-body">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center credit_pic_siz">
            <img name="cnicFUrl" [src]="cnicFUrl ? cnicFUrl : ''" />
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template id="display-modal" #cnicBUrlModel let-c="close" let-d="dismiss">
  <div class="p-3 pb-0">
    <div class="modal-header">
      <h4>{{ modelHeading }}</h4>
      <div class="header_cross" (click)="d('Cross click')">
        <h2>&times;</h2>
      </div>
    </div>
    <div class="modal-body">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center">
            <h4>{{ modelHeading }}</h4>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div>
            <img name="cnicBUrl" [src]="cnicBUrl ? cnicBUrl : ''" />
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template id="display-modal" #userUrlModel let-c="close" let-d="dismiss">
  <div class="p-3 pb-0">
    <div class="modal-header">
      <h4>{{ modelHeading }}</h4>
      <div class="header_cross" (click)="d('Cross click')">
        <h2>&times;</h2>
      </div>
    </div>
    <div class="modal-body">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center credit_pic_siz">
            <img name="userUrl" [src]="userUrl ? userUrl : ''" />
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template id="display-modal" #repUrlModel let-c="close" let-d="dismiss">
  <div class="p-3 pb-0">
    <div class="modal-header">
      <h4>{{ modelHeading }}</h4>
      <div class="header_cross" (click)="d('Cross click')">
        <h2>&times;</h2>
      </div>
    </div>
    <div class="modal-body">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center">
            <h4>{{ modelHeading }}</h4>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div>
            <img name="repUrl" [src]="repUrl ? repUrl : ''" />
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
