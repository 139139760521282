import { Component, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SharedService } from "src/app/components/shared/service/shared.service";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
  NgForm,
} from "@angular/forms";

import { ClintOnboardingService } from "src/app/components/clint-onboarding/shared/service/clint-onboarding.service";
import { SetupService } from "../../shared/service/setup.service";
import { PicklistService } from "src/app/components/shared/pickList/picklist.service";
import { formatDate } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { SortbyPipePipe } from "src/app/components/shared/service/sortby-pipe.pipe";

@Component({
  selector: "app-branch",
  templateUrl: "./branch.component.html",
  styleUrls: ["./branch.component.css"],
})
export class BranchComponent implements OnInit {
  loadingIndicator: boolean = false;
  rows: any;

  page: number = 1;
  pageSize: number = 10;
  collectionSize: number = 0;

  loginForm!: FormGroup;
  submitted = false;
  // currentDate: any = new Date().toISOString().split("T")[0];
  onBoardingTablePG: any;
  maskedCNIC: string;
  areaID: any;
  addBranchformdata: any;
  addBranchBagformdata: any;
  singleRecord: any;
  isEdit: boolean = false;
  isEditBag: boolean = false;

  assignTabletformdata: any;
  branchAccountformdata: any;
  branchProductformdata: any;

  currentBranchID: any;
  currentBranchName: any;

  addEmployeeAssignment: any;

  coveringEMPLIST: any = [];

  isNewCase: boolean = true;

  selectedEmp: any;

  branchBagsList: any;

  singleRecordBag: any;
  branchBagsListTablePG: any;
  bagNumber:any;
  constructor(
    private modalService: NgbModal,
    public router: Router,
    public _sharedSvc: SharedService,
    public _clintOnboardingSvc: ClintOnboardingService,
    public _SetupService: SetupService,
    private formBuilder: FormBuilder,
    public _picklistService: PicklistService,
    public translate: TranslateService,
    private sortPipe: SortbyPipePipe
  ) { }

  ngOnInit(): void {
    this.addBranchformdata = this.formBuilder.group({
      areaSequence: ["", Validators.required],
      branchDescription: ["", Validators.required],
      branchName: ["", Validators.required],
      branchStatusKey: ["", Validators.required],
      branchTypeKey: ["", Validators.required],
    });

    this.addEmployeeAssignment = this.formBuilder.group({
      coveringEmployeeSequence: [""],
      employeeSequence: ["", Validators.required],
      coveringEmployeeFromDate: [""],
      coveringEmployeeToDate: [""],
    });

    this.addBranchBagformdata = this.formBuilder.group({
      bagNum: ["", Validators.required],
      bagTypKey: ["", Validators.required],
      bagStsKey: ["", Validators.required],
    });

    this.addEmployeeAssignment.controls[
      "employeeSequence"
    ].valueChanges.subscribe((value) => {
      if (value) {
        this.coveringEMPLIST = this._SetupService.allEMPLIST.filter(
          (x) => x.empSeq != value
        );
        this.addEmployeeAssignment
          .get("employeeSequence")
          .disable({ emitEvent: false });
      }
    });
    this.addEmployeeAssignment.controls[
      "coveringEmployeeSequence"
    ].valueChanges.subscribe((value) => {
      if (value) {
        this.addEmployeeAssignment
          .get("coveringEmployeeSequence")
          .disable({ emitEvent: false });
      }
    });

    this.branchAccount();
    this.branchProduct();

    this.areaID = sessionStorage.getItem("singleAreaID");
    this._SetupService.get_branch_list(sessionStorage.getItem("singleAreaID"));
    this._SetupService.get_areas_list(sessionStorage.getItem("singleRegionID"));
    this._picklistService.BRANCH_TYPE();
    this._picklistService.SHROFF_STATUS();
    this._picklistService.INSTITUTE();
    this._picklistService.InItGetALlProduct();
    this._picklistService.InItGetLocations();
    this._SetupService.get_all_emp_list();
    this._picklistService.BAG_STATUS();
    this._picklistService.BAG_TYPE();
  }

  findItem() {
    if(this.bagNumber){
      const numberToFind = parseInt(this.bagNumber);
      // Using find() to search for the item
       const data= this.branchBagsList?.filter(item => item.bagNum === numberToFind);
       this.branchBagsList=data?data:this.branchBagsList;
      this.pageChange();
    }else{
      this.get_branch_bag_list(this.currentBranchID);
    }
  }

  get AreaSeqID() {
    return this.addBranchformdata.get("areaSequence");
  }
  get branchDescription() {
    return this.addBranchformdata.get("branchDescription");
  }
  get branchName() {
    return this.addBranchformdata.get("branchName");
  }
  get branchStatusKey() {
    return this.addBranchformdata.get("branchStatusKey");
  }
  get branchTypeKey() {
    return this.addBranchformdata.get("branchTypeKey");
  }

  get bagNum() {
    return this.addBranchBagformdata.get("bagNum");
  }
  get bagStsKey() {
    return this.addBranchBagformdata.get("bagStsKey");
  }
  get bagTypKey() {
    return this.addBranchBagformdata.get("bagTypKey");
  }

  get employeeSequence() {
    return this.addEmployeeAssignment.get("employeeSequence");
  }

  submittBranch(val) {
    if (this.addBranchformdata.invalid) {
      for (const control of Object.keys(this.addBranchformdata.controls)) {
        this.addBranchformdata.controls[control].markAsTouched();
      }
      return;
    }
    let data = {
      ...this.addBranchformdata.value,
      applicationVersionCode: "0",
      branchCode: "0",
      branchSequence: null,
      delFlg: 0,
    };
    if (this.isEdit) {
      data.branchSequence = this.singleRecord.branchSequence;
    }
    this._SetupService.setupBranchModel = data;
    this._SetupService.saveBranch().subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.success(
            this.translate.instant("toast.branch") +
            (this.isEdit === true
              ? this.translate.instant("toast.updated")
              : this.translate.instant("toast.added"))
          );
          this._sharedSvc.hideSpinner3();
          this.modalService.dismissAll();
          this._SetupService.get_branch_list(
            sessionStorage.getItem("singleAreaID")
          );
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner3();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(
            ([key, value]: any, index: number) => {
              this._sharedSvc.error(value);
            }
          );
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    });
  }

  editBranch(content: any, id: any, edit: any) {
    let list = this._SetupService.allBranchList;
    let record = list.find((x) => x.branchSequence === id);
    this.singleRecord = record;

    if (record) {
      let data = {
        areaSequence: record.areaSequence,
        branchDescription: record.branchDescription,
        branchName: record.branchName,
        branchStatusKey: record.branchStatusKey,
        branchTypeKey: record.branchTypeKey,
      };
      this.addBranchformdata.patchValue(data);
      this.openModal(content, edit);
    }
  }

  deleteRecord(id) {
    
    let list = this._SetupService.allBranchList;
    let record = list.find((x) => x.branchSequence === id);
    this.singleRecord = record;

    if (record) {
      let data = {
        ...record,
        delFlg: 1,
      };
      this._SetupService.setupBranchModel = data;
      this._SetupService.saveBranch().subscribe({
        next: (response) => {
          if (response) {
            this._sharedSvc.success(
              this.translate.instant("toast.branchDeleted")
            );
            this._sharedSvc.hideSpinner3();
            this.modalService.dismissAll();
            this._SetupService.get_branch_list(this.areaID);
            console.log(this.areaID);
          }
        },
        error: (e) => {
          this._sharedSvc.hideSpinner3();
          if (e.status == 401) {
            this._sharedSvc.error(this._sharedSvc.authMsg);
            this._sharedSvc.logout();
            return;
          }
          if (e?.error) {
            Object.entries(e?.error).forEach(
              ([key, value]: any, index: number) => {
                this._sharedSvc.error(value);
              }
            );
          }
          if (e?.error?.errorMessage) {
            this._sharedSvc.error(e?.error?.error);
          }
        },
      });
    }
  }

  findbranchTYPE(val) {
    let record = this._picklistService.BRANCH_TYPE_Data?.find(
      (x) => x.refCdSeq === Number(val)
    );

    if (record) {
      return record.refCdDscr;
    }
    return "";
  }

  pageChange() {
    this.collectionSize = this.branchBagsList?.length;
    this.branchBagsListTablePG = this.branchBagsList?.slice(
      (this.page - 1) * this.pageSize,
      (this.page - 1) * this.pageSize + this.pageSize
    );
    this.branchBagsListTablePG;
  }

  openModal(content: any, edit: boolean) {
    this.isEdit = edit;
    this.modalService.open(content, {
      // backdrop: "static",
      // keyboard: false,
      windowClass: "popupWidth800",
      // backdropClass: 'light-blue-backdrop',
      centered: true,
    });
  }
  openModalTabletAssignment(content: any) {
    this.modalService.open(content, {
      // backdrop: "static",
      // keyboard: false,
      windowClass: "popupWidth800",
      // backdropClass: 'light-blue-backdrop',
      centered: true,
    });
  }

  openBranchModalBagList(content: any, id: any) {
    this.currentBranchID = id;
    this.get_branch_bag_list(id);

    this.modalService.open(content, {
      // backdrop: "static",
      // keyboard: false,
      windowClass: "popupWidth800",
      // backdropClass: 'light-blue-backdrop',
      centered: true,
    });
  }
  openModalAddBag(content: any, isedit: boolean) {
    this.isEditBag = isedit;
    this.modalService.open(content, {
      // backdrop: "static",
      // keyboard: false,
      windowClass: "popupWidth800",
      // backdropClass: 'light-blue-backdrop',
      centered: true,
    });
  }
  editModalBag(content: any, id: any, isedit: boolean) {
    this.isEditBag = isedit;
    let list = this.branchBagsList;
    let record = list.find((x) => x.bagSeq === id);
    this.singleRecordBag = record;

    if (record) {
      let data = {
        bagNum: record.bagNum,
        bagStsKey: record.bagStsKey,
        bagTypKey: record.bagTypKey,
      };
      this.addBranchBagformdata.patchValue(data);
      this.openModal(content, isedit);
    }
  }
  openModalProductAssignment(content: any, id: any) {
    this.currentBranchID = id;
    this._SetupService.get_branch_product_list(id);

    this.modalService
      .open(content, {
        // backdrop: "static",
        // keyboard: false,
        windowClass: "popupWidth800",
        // backdropClass: 'light-blue-backdrop',
        centered: true,
      })
      .result.then(
        (result) => {
          // Your logic when modal is closed
          this._SetupService.branchProductsList = [];
        },
        (reason) => {
          // Your logic when modal is dismissed
          this._SetupService.branchProductsList = [];
        }
      );
  }
  openModalAccountSetup(content: any, branchid: any, bName: string) {
    this.currentBranchName = bName;
    this.currentBranchID = branchid;
    this.modalService.open(content, {
      // backdrop: "static",
      // keyboard: false,
      windowClass: "popupWidth800",
      // backdropClass: 'light-blue-backdrop',
      centered: true,
    });
  }
  openModalAssignLocation(content: any, id: any) {
    this.currentBranchID = id;
    this._SetupService.get_branch_location_list(id);
    this.modalService
      .open(content, {
        // backdrop: "static",
        // keyboard: false,
        windowClass: "popupWidth800",
        // backdropClass: 'light-blue-backdrop',
        centered: true,
      })
      .result.then(
        (result) => {
          // Your logic when modal is closed
          this._SetupService.branchLocationsList = [];
        },
        (reason) => {
          // Your logic when modal is dismissed
          this._SetupService.branchLocationsList = [];
        }
      );
  }
  openModalEmployeeAssignment(content: any, branchSequence: any) {
    this.get_branch_covering_emp_list(branchSequence);
    this.currentBranchID = branchSequence;
    this.modalService
      .open(content, {
        // backdrop: "static",
        // keyboard: false,
        windowClass: "popupWidth800",
        // backdropClass: 'light-blue-backdrop',
        centered: true,
      })
      .result.then(
        (result) => {
          // Your logic when modal is closed
          this.coveringEMPLIST = [];
          this.addEmployeeAssignment
            .get("employeeSequence")
            .enable({ emitEvent: false });
          this.addEmployeeAssignment
            .get("coveringEmployeeSequence")
            .enable({ emitEvent: false });
        },
        (reason) => {
          // Your logic when modal is dismissed
          this.coveringEMPLIST = [];
          this.addEmployeeAssignment
            .get("employeeSequence")
            .enable({ emitEvent: false });
          this.addEmployeeAssignment
            .get("coveringEmployeeSequence")
            .enable({ emitEvent: false });
        }
      );
  }

  areaFn() {
    this.router.navigate(["/setup-area"]);
  }

  portfolio(branch) {
    sessionStorage.setItem("singlebranchID", branch.branchSequence);
    sessionStorage.setItem("singlebranchName", branch.branchName);
    this.router.navigate(["/setup-portfolio"]);
  }

  assignTablet() {
    this.assignTabletformdata = this.formBuilder.group({
      uID: ["", Validators.required],
      identifier: ["", Validators.required],
    });
  }
  branchAccount() {
    this.branchAccountformdata = this.formBuilder.group({
      bankName: ["", Validators.required],
      bankBranch: ["", Validators.required],
      accountNumber: ["", Validators.required],
      accountName: ["", Validators.required],
      internationalBankAccountNumber: ["", Validators.required],
    });
  }
  branchProduct() {
    this.branchProductformdata = this.formBuilder.group({
      productSequence: [false, Validators.required],
    });
  }

  submitBranchAccount(val: any) {
    if (this.branchAccountformdata.invalid) {
      for (const control of Object.keys(this.branchAccountformdata.controls)) {
        this.branchAccountformdata.controls[control].markAsTouched();
      }
      return;
    }
    let data = {
      ...this.branchAccountformdata.value,
      branchSequence: this.currentBranchID,
      onlineFlag: true,
      tenantKey: "1",
      branchAccountSetSequence: 0,
      delFlg: 0,
    };

    this._SetupService.setupBranchAccountModel = data;
    this._SetupService.saveBranchAccount().subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.success(this.translate.instant('toast.branchAccountAdded'));
          this._sharedSvc.hideSpinner3();
          this.modalService.dismissAll();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner3();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(
            ([key, value]: any, index: number) => {
              this._sharedSvc.error(value);
            }
          );
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    });
  }

  get bankName() {
    return this.branchAccountformdata.get("bankName");
  }
  get bankBranch() {
    return this.branchAccountformdata.get("bankBranch");
  }
  get accountNumber() {
    return this.branchAccountformdata.get("accountNumber");
  }
  get accountName() {
    return this.branchAccountformdata.get("accountName");
  }
  get internationalBankAccountNumber() {
    return this.branchAccountformdata.get("internationalBankAccountNumber");
  }

  submitBranchProduct(){
    

    this._SetupService.saveBranchProducts().subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.success(this.translate.instant('toast.productAssignSuccessfully'));
          this._sharedSvc.hideSpinner3();
          this.modalService.dismissAll();
          this._SetupService.branchProductsList = [];
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner3();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(
            ([key, value]: any, index: number) => {
              this._sharedSvc.error(value);
            }
          );
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    });
  }

  submitLocation(){   

    this._SetupService.saveBranchLocations().subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.success(this.translate.instant('toast.locationsAssignSuccessfully'));
          this._sharedSvc.hideSpinner3();
          this.modalService.dismissAll();
          this._SetupService.branchLocationsList = [];
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner3();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(
            ([key, value]: any, index: number) => {
              this._sharedSvc.error(value);
            }
          );
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    });
  }

  pushLocationInList(e:any){
    
    let data = {
      branchLocationRelSequence:
        e.target.checked === true
          ? 0
          : this.isbranchLocationSeqPresent(e.target.value),
      branchSequence: this.currentBranchID,
      locationSequence: Number(e.target.value),
      delFlg: e.target.checked === true ? 0 : 1,
    };

    // this._SetupService.setupBranchLocationModel = data;
    this._SetupService.branchLocationsList.push(data);
  }

  pushProductInList(e:any){
    
    let data = {
      branchProductRelSequence:
        e.target.checked === true
          ? 0
          : this.isbranchProdSeqPresent(e.target.value),
      branchSequence: this.currentBranchID,
      productSequence: Number(e.target.value),
      delFlg: e.target.checked === true ? 0 : 1,
    };

    // this._SetupService.setupBranchLocationModel = data;
    this._SetupService.branchProductsList.push(data);
  }

  isbranchLocationSeqPresent(locationSeq: any) {
    let result = this._SetupService?.branchLocationList?.find(
      (x) => x?.locationSequence === Number(locationSeq)
    );
    if (result) {
      return result.branchLocationRelSequence;
    }
  }
  isbranchProdSeqPresent(productSeq: any) {
    let result = this._SetupService?.branchProductList?.find(
      (x) => x?.productSequence === Number(productSeq)
    );
    if (result) {
      return result.branchProductRelSequence;
    }
  }
  isLocationSeqPresent(locationSeq: any) {
    return this._SetupService?.branchLocationList?.some(
      (x) => x?.locationSequence === locationSeq
    );
  }
  isProductSeqPresent(productSeq: any) {
    return this._SetupService?.branchProductList?.some(
      (x) => x?.productSequence === productSeq
    );
  }

  get_branch_covering_emp_list(key: any) {
    this._sharedSvc.showSpinner();
    this._SetupService.getCoveringEmpListofBranch(key).subscribe((res) => {
      this._sharedSvc.hideSpinner();
      //@ts-ignore
      if (res.error) {
        this.isNewCase = true;

        return;
      }
      console.log(res);
      this.addEmployeeAssignment.patchValue(res[0]);
      this.selectedEmp = res[0];
      this.addEmployeeAssignment.controls["coveringEmployeeFromDate"].setValue(
        formatDate(res[0].coveringEmployeeFromDate, "yyyy-MM-dd", "en")
      );
      this.addEmployeeAssignment.controls["coveringEmployeeToDate"].setValue(
        formatDate(res[0].coveringEmployeeToDate, "yyyy-MM-dd", "en")
      );
      this.isNewCase = false;
    });
  }
  get_branch_bag_list(key: any) {
    this._sharedSvc.showSpinner();
    this._SetupService.getBranchBagList(key).subscribe((res) => {
      this._sharedSvc.hideSpinner();
      //@ts-ignore
      if (res.error) {
        return;
      }
      console.log(res);
      this.branchBagsList = this.sortPipe.transform(res, 'bagStsKey');;
      this.pageChange()
    });
  }

  submitEmpAssign(value) {
    if (this.addEmployeeAssignment.invalid) {
      for (const control of Object.keys(this.addEmployeeAssignment.controls)) {
        this.addEmployeeAssignment.controls[control].markAsTouched();
      }
      return;
    }

    
    
    let data = {
      ...this.addEmployeeAssignment.value,
      branchSequence: Number(this.currentBranchID),
      branchEmployeeSequence: null,
      coveringEmployeeSequence: Number(
        this.addEmployeeAssignment.controls["coveringEmployeeSequence"].value
      ),
      employeeSequence: Number(
        this.addEmployeeAssignment.controls["employeeSequence"].value
      ),
      coveringEmployeeFromDate: new Date(
        this.addEmployeeAssignment.controls["coveringEmployeeFromDate"].value
      ).toISOString(),
      coveringEmployeeToDate: new Date(
        this.addEmployeeAssignment.controls["coveringEmployeeToDate"].value
      ).toISOString(),
      rolSeq: 0,
      delFlg: 0,
    };

    this._SetupService.setupBranchEMPModel = data;
    this._SetupService.saveBranchEMP().subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.success(this.translate.instant('toast.branchEmployeeAssign'));
          this._sharedSvc.hideSpinner3();
          this.modalService.dismissAll();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner3();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(
            ([key, value]: any, index: number) => {
              this._sharedSvc.error(value);
            }
          );
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    });
  }
  removeBranchAreaManager(name){
    if(this.addEmployeeAssignment.controls['employeeSequence'].value && this.addEmployeeAssignment.controls['coveringEmployeeSequence'].value && this.isNewCase === false){
      let record = this._SetupService.allEMPLIST.find(x=> x.empSeq === Number(this.addEmployeeAssignment.controls['employeeSequence'].value));
      
      if(record){
        let data = {
          branchSequence: this.selectedEmp.branchSequence,
          branchEmployeeSequence: this.selectedEmp.branchEmployeeSequence,
          coveringEmployeeSequence: this.selectedEmp.coveringEmployeeSequence,
          employeeSequence: this.selectedEmp.employeeSequence,
          coveringEmployeeFromDate: this.selectedEmp.coveringEmployeeFromDate,
          coveringEmployeeToDate: this.selectedEmp.coveringEmployeeToDate,
          rolSeq: 0,
          delFlg: true,
        };

        this._SetupService.setupBranchEMPModel = data;
        this._SetupService.saveBranchEMP().subscribe({
          next: (response) => {
            if (response) {
              this._sharedSvc.success(this.translate.instant('toast.employeeRemoved'));
              this._sharedSvc.hideSpinner3();
              this.addEmployeeAssignment.controls["employeeSequence"].setValue(
                ""
              );
              this.addEmployeeAssignment.controls[
                "coveringEmployeeSequence"
              ].setValue("");
              this.addEmployeeAssignment.controls[
                "coveringEmployeeSequence"
              ].enable();
              this.addEmployeeAssignment.controls["employeeSequence"].enable();
            }
          },
          error: (e) => {
            this._sharedSvc.hideSpinner3();
            if (e.status == 401) {
              this._sharedSvc.error(this._sharedSvc.authMsg);
              this._sharedSvc.logout();
              return;
            }
            if (e?.error) {
              Object.entries(e?.error).forEach(
                ([key, value]: any, index: number) => {
                  this._sharedSvc.error(value);
                }
              );
            }
            if (e?.error?.errorMessage) {
              this._sharedSvc.error(e?.error?.error);
            }
          },
        });
      }
    } else {
      this.addEmployeeAssignment.controls[name].setValue("");
      this.addEmployeeAssignment.controls[name].enable();
    }
  }

  submitBag(value) {
    if (this.addBranchBagformdata.invalid) {
      for (const control of Object.keys(this.addBranchBagformdata.controls)) {
        this.addBranchBagformdata.controls[control].markAsTouched();
      }
      return;
    }

    
    
    let data = {
      ...this.addBranchBagformdata.value,
      brnchSeq: Number(this.currentBranchID),
      bagSeq: null,
      delFlg: 0,
    };
    if (this.isEdit) {
      data.bagSeq = this.singleRecordBag.bagSeq;
    }

    this._SetupService.setupBranchBagModel = data;
    this._sharedSvc.showSpinner3();
    this._SetupService.saveBranchBag().subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.success(
            this.translate.instant('toast.branchBag') +
            (this.isEditBag === true
              ? this.translate.instant("toast.updated")
              : this.translate.instant("toast.added"))
          );

          this._sharedSvc.hideSpinner3();
          this.addBranchBagformdata.reset();
          this.modalService.dismissAll();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner3();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(
            ([key, value]: any, index: number) => {
              this._sharedSvc.error(value);
            }
          );
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    });
  }
  RemoveBag(value) {
    let list = this.branchBagsList;
    let record = list.find((x) => x.bagSeq === value);
    this.singleRecordBag = record;
    
    let data = {
      ...record,
      delFlg: 1,
    };

    this._SetupService.setupBranchBagModel = data;
    this._sharedSvc.showSpinner3();

    this._SetupService.saveBranchBag().subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.success(this.translate.instant('toast.branchBagDeleted'));
          this._sharedSvc.hideSpinner3();
          this.modalService.dismissAll();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner3();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(
            ([key, value]: any, index: number) => {
              this._sharedSvc.error(value);
            }
          );
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    });
  }
}
