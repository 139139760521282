import { Component } from '@angular/core';

@Component({
  selector: 'app-search-individual-recovery',
  templateUrl: './search-individual-recovery.component.html',
  styleUrls: ['./search-individual-recovery.component.css']
})
export class SearchIndividualRecoveryComponent {

}
