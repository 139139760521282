import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { ClintOnboardingComponent } from './components/clint-onboarding/clint-onboarding.component';
import { PersonalInfoComponent } from './components/clint-onboarding/personal-info/personal-info.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DisbursementListingComponent } from './components/disbursement/disbursement-listing/disbursement-listing.component';
import { DisbursementVoucherComponent } from './components/disbursement/disbursement-form/disbursement-voucher/disbursement-voucher.component';
import { AddIndividualCreditAppComponent } from './components/Individual-Group-Loan/add-individual-credit-app/add-individual-credit-app.component';
import { IndividualCreditAppComponent } from './components/Individual-Group-Loan/individual-credit-app/individual-credit-app.component';
import { LoginComponent } from './components/login/login.component';
import { DisbursementFormComponent } from './components/disbursement/disbursement-form/disbursement-form.component';
import { RecoveryListingComponent } from './components/recovery/recovery-listing/recovery-listing.component';
import { ClosingTypeComponent } from './components/admin/closing-type/closing-type.component';
import { CnicExpiryComponent } from './components/cnic-expiry/cnic-expiry/cnic-expiry.component';
import { JournelVoucherComponent } from './components/admin/journel-voucher/journel-voucher.component';
import { IncomingBagsComponent } from './components/admin/incoming-bags/incoming-bags.component';
import { ExcessRecoveryComponent } from './components/admin/excess-recovery/excess-recovery.component';
import { CommanCodesComponent } from './components/setup/comman-codes/comman-codes.component';
import { DistrictComponent } from './components/setup/geography/district/district.component';
import { AreaComponent } from './components/setup/organization/area/area.component';
import { SetupProductListingComponent } from './components/setup/products/setup-product-listing/setup-product-listing.component';
import { BranchAccountingComponent } from './components/branchAccounting/branch-accounting/branch-accounting.component';
import { SetupAddProductComponent } from './components/setup/products/setup-add-product/setup-add-product.component';
import { BusinessSectorComponent } from './components/setup/business-sector/business-sector.component';
import { ChargesTypesComponent } from './components/setup/charges-types/charges-types.component';
import { CityComponent } from './components/setup/city/city.component';
import { ExpenseTypesComponent } from './components/setup/expense-types/expense-types.component';
import { FileUploadComponent } from './components/setup/file-upload/file-upload.component';
import { HealthPlanComponent } from './components/setup/health-plan/health-plan.component';
import { PaymentTypesComponent } from './components/setup/payment-types/payment-types.component';
import { QuestionnairComponent } from './components/setup/questionnair/questionnair.component';
import { RulesComponent } from './components/setup/rules/rules.component';
import { TagsComponent } from './components/setup/tags/tags.component';
import { UsermanagementComponent } from './components/setup/usermanagement/usermanagement.component';
import { PartnerComponent } from './components/setup/partner/partner.component';
import { ShroffManagementComponent } from './components/setup/shroff-management/shroff-management.component';
import { GoldRateComponent } from './components/admin/gold-rate/gold-rate.component';
import { BranchComponent } from './components/setup/organization/branch/branch.component';
import { CommunityComponent } from './components/setup/organization/community/community.component';
import { PortfolioComponent } from './components/setup/organization/portfolio/portfolio.component';
import { ProvinceComponent } from './components/setup/geography/province/province.component';
import { GeographyComponent } from './components/setup/geography/geography/geography.component';
import { TehseelComponent } from './components/setup/geography/tehseel/tehseel.component';
import { UinioCouncilComponent } from './components/setup/geography/uinio-council/uinio-council.component';
import { AnswerComponent } from './components/setup/questionnair/answer/answer.component';
import { QuestionComponent } from './components/setup/questionnair/question/question.component';
import { BusinessActivityComponent } from './components/setup/business-sector/business-activity/business-activity.component';
import { RegionComponent } from './components/setup/organization/region/region.component';
import { VaultManagementComponent } from './components/setup/vault-management/vault-management.component';
import { RollOverComponent } from "./components/recovery/roll-over/roll-over.component";
import { RefrenceGroupValuesComponent } from './components/setup/comman-codes/refrence-group-values/refrence-group-values.component';
import { SetupProductGroupsComponent } from './components/setup/products/setup-product-groups/setup-product-groups.component';

export const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  // { path: '**', redirectTo: 'login' },
  { path: 'login', component: LoginComponent, canActivate: [AuthGuard] },
  // canActivate: [AuthGuard], // data: {roles: ["user"]}
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },

  { path: 'onboarding', component: ClintOnboardingComponent, canActivate: [AuthGuard] },

  { path: 'onboarding-personal-info', component: PersonalInfoComponent },
  { path: 'onboarding-personal-info/:id', component: PersonalInfoComponent },
  { path: 'view-onboarding/:id', component: PersonalInfoComponent },

  { path: 'individual-group-info', component: IndividualCreditAppComponent },

  { path: 'add-individual-group-info', component: AddIndividualCreditAppComponent },
  { path: 'edit-individual-group-info/:id', component: AddIndividualCreditAppComponent },

  { path: 'disbursement-listing', component: DisbursementListingComponent, canActivate: [AuthGuard] },

  { path: 'disbursement-form', component: DisbursementFormComponent, canActivate: [AuthGuard] },

  { path: 'recovery-listing', component: RecoveryListingComponent, canActivate: [AuthGuard] },

  { path: 'roll-over', component: RollOverComponent, canActivate: [AuthGuard] },

  { path: 'admin-closingType', component: ClosingTypeComponent, canActivate: [AuthGuard] },

  { path: 'admin-journalVoucher', component: JournelVoucherComponent, canActivate: [AuthGuard] },

  { path: 'admin-goldRate', component: GoldRateComponent, canActivate: [AuthGuard] },

  { path: 'admin-IncomingBags', component: IncomingBagsComponent, canActivate: [AuthGuard] },

  { path: 'admin-excessRecovery', component: ExcessRecoveryComponent, canActivate: [AuthGuard] },

  { path: 'common-codes', component: CommanCodesComponent, canActivate: [AuthGuard] },
  { path: 'common-code-values', component: RefrenceGroupValuesComponent },

  { path: 'setup-region', component: RegionComponent, canActivate: [AuthGuard] },

  { path: 'setup-region', component: RegionComponent, canActivate: [AuthGuard] },

  { path: 'setup-area', component: AreaComponent, canActivate: [AuthGuard] },

  { path: 'setup-branch', component: BranchComponent, canActivate: [AuthGuard] },

  { path: 'setup-portfolio', component: PortfolioComponent, canActivate: [AuthGuard] },

  { path: 'setup-community', component: CommunityComponent, canActivate: [AuthGuard] },

  { path: 'setup-geography', component: GeographyComponent, canActivate: [AuthGuard] },

  { path: 'setup-province', component: ProvinceComponent, canActivate: [AuthGuard] },

  { path: 'setup-district', component: DistrictComponent, canActivate: [AuthGuard] },

  { path: 'setup-tehseel', component: TehseelComponent, canActivate: [AuthGuard] },

  { path: 'setup-unionCouncil', component: UinioCouncilComponent, canActivate: [AuthGuard] },

  { path: 'setup-product-groups', component: SetupProductGroupsComponent, canActivate: [AuthGuard] },

  { path: 'setup-products', component: SetupProductListingComponent, canActivate: [AuthGuard] },

  { path: 'setup-add-products', component: SetupAddProductComponent, canActivate: [AuthGuard] },
  { path: 'setup-add-products/:id', component: SetupAddProductComponent, canActivate: [AuthGuard] },

  { path: 'setup-Rules', component: RulesComponent, canActivate: [AuthGuard] },

  { path: 'setup-userManagement', component: UsermanagementComponent, canActivate: [AuthGuard] },

  { path: 'setup-questionnaire', component: QuestionnairComponent, canActivate: [AuthGuard] },

  { path: 'setup-question', component: QuestionComponent, canActivate: [AuthGuard] },

  { path: 'setup-answer', component: AnswerComponent, canActivate: [AuthGuard] },

  { path: 'setup-healthIP', component: HealthPlanComponent, canActivate: [AuthGuard] },

  { path: 'setup-payment-type', component: PaymentTypesComponent, canActivate: [AuthGuard] },

  { path: 'setup-expense-type', component: ExpenseTypesComponent, canActivate: [AuthGuard] },

  { path: 'setup-charges-type', component: ChargesTypesComponent, canActivate: [AuthGuard] },

  { path: 'setup-city', component: CityComponent, canActivate: [AuthGuard] },

  { path: 'setup-business-sector', component: BusinessSectorComponent, canActivate: [AuthGuard] },

  { path: 'setup-business-activity', component: BusinessActivityComponent, canActivate: [AuthGuard] },

  { path: 'setup-tags', component: TagsComponent, canActivate: [AuthGuard] },

  { path: 'setup-file-upload', component: FileUploadComponent, canActivate: [AuthGuard] },

  { path: 'setup-shroff-management', component: ShroffManagementComponent, canActivate: [AuthGuard] },

  { path: 'setup-vault-management', component: VaultManagementComponent, canActivate: [AuthGuard] },

  { path: 'setup-partner', component: PartnerComponent, canActivate: [AuthGuard] },

  { path: 'branch-accounting', component: BranchAccountingComponent, canActivate: [AuthGuard] },

  { path: 'cnic-expiry', component: CnicExpiryComponent, canActivate: [AuthGuard] },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
