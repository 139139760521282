import { Component } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { SharedService } from "src/app/components/shared/service/shared.service";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SetupService } from "../shared/service/setup.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PicklistService } from "../../shared/pickList/picklist.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-vault-management",
  templateUrl: "./vault-management.component.html",
  styleUrls: ["./vault-management.component.css"],
})
export class VaultManagementComponent {
  vaultFormdata: any;
  safeFormdata: any;
  vaultSafeCount: any;
  safeCount: any;
  vaultIndex: any;
  singleVault: any;
  safeIndex: any;
  singleSafe: any;

  isEditSafe: boolean = false;
  isEditVault: boolean = false;
  vaultTotalCoverages: any[] = [];
  branchList: any = [];

  constructor(
    private modalService: NgbModal,
    public router: Router,
    public _sharedSvc: SharedService,
    public _setupService: SetupService,
    private formBuilder: FormBuilder,
    public _picklistService: PicklistService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.vaultFormdata = this.formBuilder.group({
      saveCount: ["", Validators.required],
      locationKey: ["", Validators.required],
      description: ["", Validators.required],
    });

    this.safeFormdata = this.formBuilder.group({
      // manufacturedBy: ['', Validators.required],
      description: ["", Validators.required],
      insrncBy: ["", Validators.required],
      coverageAmount: ["", Validators.required],
      positionKey: ["", Validators.required],
      specColor: ["", Validators.required],
    });

    this.getTotalCoverage();
    this._picklistService.VAULT_POSITION();
    this._picklistService.VAULT_COLOR();
    this.getAllBranchList();
  }

  get getVaultSafeCount() {
    return this.vaultFormdata.get("saveCount");
  }
  get getVaultLocation() {
    return this.vaultFormdata.get("locationKey");
  }
  get getVaultDescription() {
    return this.vaultFormdata.get("description");
  }

  // get getSafeMnufcrBy() { return this.safeFormdata.get("manufacturedBy") }
  get getDescription() {
    return this.safeFormdata.get("description");
  }
  get getInsrncBy() {
    return this.safeFormdata.get("insrncBy");
  }
  get getCoverageAmount() {
    return this.safeFormdata.get("coverageAmount");
  }
  get getVaultPosition() {
    return this.safeFormdata.get("positionKey");
  }
  get getSpecColor() {
    return this.safeFormdata.get("specColor");
  }

  // Vault Functions
  openAddEditVaultModal(content: any, edit: boolean) {
    localStorage.removeItem("selectedOrderData");
    if (!edit) {
      this.vaultFormdata.reset();
    }
    this.isEditVault = edit;
    this.modalService.open(content, {
      // backdrop: "static",
      // keyboard: false,
      windowClass: "popupWidth1k first-modal",
      // backdropClass: 'light-blue-backdrop',
      centered: true,
    });
  }

  submitVaultForm(val: any) {
    if (this.vaultFormdata.invalid) {
      for (const control of Object.keys(this.vaultFormdata.controls)) {
        this.vaultFormdata.controls[control].markAsTouched();
      }
      return;
    }
    console.log(this.vaultFormdata);

    let data = {
      ...this.vaultFormdata.value,
      saveCount: Number(this.vaultFormdata.value.saveCount),
      locationKey: Number(this.vaultFormdata.value.locationKey),
      delFlg: 0,
    };
    if (this.isEditVault) {
      data.vaultSeq = this.singleVault.vaultSeq;
    }
    console.log(data);

    this._sharedSvc.showSpinner3();
    this._setupService.saveVault(data).subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.success(
            this.translate.instant("toast.vault") +
              (this.isEditVault === true
                ? this.translate.instant("toast.updated")
                : this.translate.instant("toast.added"))
          );
          this.vaultFormdata.reset();
          this._sharedSvc.hideSpinner3();
          this.modalService.dismissAll();
          this._setupService.get_vault_list();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner3();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(
            ([key, value]: any, index: number) => {
              this._sharedSvc.error(value);
            }
          );
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    });
  }

  editVault(content: any, id: any, edit: boolean) {
    let list = this._setupService.vaultList;
    let record = list.find((x) => x.vaultSeq === id);
    this.singleVault = record;
    let data = {
      description: record.description,
      saveCount: record.saveCount,
      locationKey: record.locationKey,
      delFlg: 0,
    };
    this.vaultFormdata.patchValue(data);
    this.openAddEditVaultModal(content, edit);
  }

  deleteVault(id: any) {
    let list = this._setupService.vaultList;
    let record = list.find((x) => x.vaultSeq === id);
    this.singleVault = record;

    if (record) {
      let data = {
        ...record,
        delFlg: 1,
      };
      this._sharedSvc.showSpinner3();
      this._setupService.saveVault(data).subscribe({
        next: (response) => {
          if (response) {
            this._sharedSvc.success(
              this.translate.instant("toast.vaultDeleted")
            );
            this._sharedSvc.hideSpinner3();
            this.modalService.dismissAll();
            this._setupService.get_vault_list();
          }
        },
        error: (e) => {
          this._sharedSvc.hideSpinner3();
          if (e.status == 401) {
            this._sharedSvc.error(this._sharedSvc.authMsg);
            this._sharedSvc.logout();
            return;
          }
          if (e?.error) {
            Object.entries(e?.error).forEach(
              ([key, value]: any, index: number) => {
                this._sharedSvc.error(value);
              }
            );
          }
          if (e?.error?.errorMessage) {
            this._sharedSvc.error(e?.error?.error);
          }
        },
      });
    }
  }

  getAllBranchList() {
    this._sharedSvc.showSpinner3();
    this._setupService.getAllBranchList().subscribe({
      next: (response) => {
        if (response) {
          console.log(response);
          this.branchList = response;
          this._sharedSvc.hideSpinner3();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner3();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(
            ([key, value]: any, index: number) => {
              this._sharedSvc.error(value);
            }
          );
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    });
  }

  // Safe Functions
  openSafesListModal(content: any, vault_key: any) {
    ;
    this.vaultIndex = vault_key;

    this._setupService
      .get_safe_list(this.vaultIndex)
      .then((result) => {
        console.log("Safe list retrieved:", result);

        this.vaultSafeCount = this._setupService.vaultList
          ? this._setupService.vaultList.find(
              (x) => x.vaultSeq === this.vaultIndex
            ).saveCount
          : 0;

        this.safeCount = this._setupService.safeList
          ? this._setupService.safeList.length
          : 0;

        console.log("Vault Safe Count", this.vaultSafeCount);
        console.log("Safe Count", this.safeCount);

        localStorage.removeItem("selectedOrderData");
        this.modalService.open(content, {
          windowClass: "popupWidth1k first-modal",
          centered: true,
        });
      })
      .catch((error) => {
        console.error("Error in openSafesListModal:", error);
      });
  }

  openSafeAddEdit(content: any, edit: boolean) {
    localStorage.removeItem("selectedOrderData");
    if (!edit) {
      this.safeFormdata.reset();
    }
    this.isEditSafe = edit;
    this.modalService.open(content, {
      // backdrop: "static",
      // keyboard: false,
      windowClass: "popupWidth1k second-modal",
      // backdropClass: 'light-blue-backdrop',
      centered: true,
    });
  }

  submitSafeVaultForm(val) {
    
    if (this.safeFormdata.invalid) {
      for (const control of Object.keys(this.safeFormdata.controls)) {
        this.safeFormdata.controls[control].markAsTouched();
      }
      return;
    }
    console.log(this.safeFormdata);

    let tempVaultList = this._setupService.vaultList;
    this.singleVault = tempVaultList.find(
      (x) => x.vaultSeq === this.vaultIndex
    );

    let data = {
      ...this.safeFormdata.value,
      safeSeq: null,
      vaultId: this.singleVault.vaultId,
      // description: this.singleVault.description,
      vaultSeq: Number(this.singleVault.vaultSeq),
      coverageAmount: Number(this.safeFormdata.value.coverageAmount),
      positionKey: Number(this.safeFormdata.value.positionKey),
      specColor: Number(this.safeFormdata.value.specColor),
      delFlg: 0,
    };
    if (this.isEditSafe) {
      data.safeSeq = this.singleSafe.safeSeq;
    }

    console.log(data);

    this._sharedSvc.showSpinner3();

    this._setupService.saveSafe(data).subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.success(
            this.translate.instant("toast.safe") +
              (this.isEditSafe === true
                ? this.translate.instant("toast.updated")
                : this.translate.instant("toast.added"))
          );
          this.safeFormdata.reset();
          this._sharedSvc.hideSpinner3();
          this.modalService.dismissAll();
          this._setupService.get_safe_list(this.singleVault.vaultSeq);
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner3();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(
            ([key, value]: any, index: number) => {
              this._sharedSvc.error(value);
            }
          );
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    });
  }

  editVaultSafe(content: any, id: any, edit: boolean) {
    this.safeIndex = id;
    let list = this._setupService.safeList;
    let record = list.find((x) => x.safeSeq === this.safeIndex);
    this.singleSafe = record;

    if (this.singleSafe) {
      let data = {
        vaultId: record.vaultId,
        vaultSeq: Number(record.vaultSeq),
        description: record.description,
        coverageAmount: Number(record.coverageAmount),
        positionKey: Number(record.positionKey),
        specColor: Number(record.specColor),
        insrncBy: record.insrncBy,
        delFlg: 0,
      };
      this.safeFormdata.patchValue(data);
      this.openSafeAddEdit(content, edit);
    }
  }

  deleteSafe(safe_key: any) {
    
    this.safeIndex = safe_key;
    let list = this._setupService.safeList;
    let record = list.find((x) => x.safeSeq === safe_key);
    this.singleSafe = record;

    if (record) {
      let data = {
        ...record,
        delFlg: 1,
      };
      this._sharedSvc.showSpinner3();
      this._setupService.saveSafe(data).subscribe({
        next: (response) => {
          if (response) {
            this._sharedSvc.success(
              this.translate.instant("toast.safeDeleted")
            );
            this._sharedSvc.hideSpinner3();
            this.modalService.dismissAll();
            this._setupService.get_safe_list(this.vaultIndex);
          }
        },
        error: (e) => {
          this._sharedSvc.hideSpinner3();
          if (e.status == 401) {
            this._sharedSvc.error(this._sharedSvc.authMsg);
            this._sharedSvc.logout();
            return;
          }
          if (e?.error) {
            Object.entries(e?.error).forEach(
              ([key, value]: any, index: number) => {
                this._sharedSvc.error(value);
              }
            );
          }
          if (e?.error?.errorMessage) {
            this._sharedSvc.error(e?.error?.error);
          }
        },
      });
    }
  }

  // Other Functions
  convertToMillion(number: number) {
    return (number / 1e6).toFixed(2) + "M";
  }
  getPosition(orderIndex: any) {
    for (let i = 0; i < this._picklistService.VAULT_POSITION_Data.length; i++) {
      if (
        Number(this._picklistService.VAULT_POSITION_Data[i].refCd) ===
        orderIndex
      ) {
        return this._picklistService.VAULT_POSITION_Data[i].refCdDscr;
      }
    }
  }
  getColor(orderIndex: any) {
    for (let i = 0; i < this._picklistService.VAULT_COLOR_Data.length; i++) {
      if (
        Number(this._picklistService.VAULT_COLOR_Data[i].refCd) === orderIndex
      ) {
        return this._picklistService.VAULT_COLOR_Data[i].refCdDscr;
      }
    }
  }
  getTotalCoverage() {
    let tempTotalCoverage = [];
    this._setupService
      .get_vault_list()
      .then((result) => {
        let tempVaultList = this._setupService.vaultList;
        for (let i = 0; i < tempVaultList.length; i++) {
          this._setupService
            .get_safe_list(tempVaultList[i].vaultSeq)
            .then((result) => {
              let tempSafeList = this._setupService.safeList;
              let currentTotalCoverage = 0;
              for (let j = 0; j < tempSafeList.length; j++) {
                currentTotalCoverage =
                  currentTotalCoverage + tempSafeList[j].coverageAmount;
              }
              tempTotalCoverage.push(currentTotalCoverage);
            })
            .catch((error) => {
              console.error("Error in openSafesListModal:", error);
            });
        }
        this.vaultTotalCoverages = tempTotalCoverage;
        console.log(this.vaultTotalCoverages);
      })
      .catch((error) => {
        console.error("Error in Geting Total Coverage:", error);
      });
  }
}
