import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from '../shared/service/shared.service';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { ClintOnboardingService } from './shared/service/clint-onboarding.service';
import { SharedPopupService } from '../shared/service/shared-popup.service';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { PicklistService } from '../shared/pickList/picklist.service';
import { RoutingConditionsService } from './shared/service/routing-conditions.service';
import { RouteInfo } from 'src/app/auth/model/route-info';
import { TranslateService } from '@ngx-translate/core';

// interface IValidation {
//   cnicNum: number;
//   issueDate: string;
//   expiryDate: string;
//   typ: string;
// }
@Component({
  selector: 'app-clint-onboarding',
  templateUrl: './clint-onboarding.component.html',
  styleUrls: ['./clint-onboarding.component.css']
})

export class ClintOnboardingComponent implements OnInit {
  allowedRoutes: RouteInfo[];
  model: NgbDateStruct;
  getAllClientPG: any;
  maskedCNIC: string;
  validationForm: FormGroup;
  // validation: IValidation;
  saveCNICModel: any
  popupEditBtn: boolean = false;
  isShowPlusBtn: boolean = false;
  AppSeq:any;
  constructor(
    public router: Router,
    public _sharedSvc: SharedService,
    public _clintOnboardingSvc: ClintOnboardingService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    public _sharedPopupSvc: SharedPopupService,
    public _picklistService: PicklistService,
    public _conditionsService: RoutingConditionsService,
    public translate: TranslateService,

  ) {
    // this.validation = {} as IValidation;
    this.allowedRoutes = JSON.parse(sessionStorage.getItem("routeInfo"));
    this.isShowPlusBtn = this.allowedRoutes.length > 0 && this.allowedRoutes.some(x => x.sbModUrl === 'addNewClient');
  }

  ngOnInit(): void {
    this._sharedSvc.active = 1;
    localStorage.clear();
    this._clintOnboardingSvc.InItGetAllClients();
    this._picklistService.APPLICATION_STATUS();
  }

  readOnlyView(item: any) {
    
    this._conditionsService.readOnlyView_Client = true;
    localStorage.setItem('readOnlyView_Client', 'true');
    localStorage.setItem('reload', "true");
    localStorage.setItem('SaveCnicData', JSON.stringify(item));
    sessionStorage.setItem('clientOnbrdViewCheck', JSON.stringify(true));
    this.router.navigate(["/view-onboarding/" + item.clntSeq]);
  }

  applyMasking(event: string) {
    this.maskedCNIC = event;
  }

  openModal(content: any) {
    // this.validationForm.reset(this.validationForm.value);
    this.validateForm();
    this.modalService.open(content, {
      windowClass: "customClassName",
      backdropClass: 'light-blue-backdrop',
      centered: true
    });
  }

  dismissModal() {
    // this.validationForm.reset(this.validationForm.value);
    this.modalService.dismissAll();
  }

  DeletePopup(data: any) {
    this._sharedPopupSvc.popup("", this.translate.instant('common.areSureWantDelete') + data?.frstNm + "'?", "", this.translate.instant('common.delete'), this.translate.instant('common.cancelSmall'), null, this.translate.instant('common.clientIdSmall') + " : " + data?.clntId).then((confirmed: any) => {
      if (confirmed) {
        // Delete function call
        this._clintOnboardingSvc.saveClientModel = Object.assign({}, data);
        this._clintOnboardingSvc.saveClientModel.delFlg = true;
        this._sharedSvc.showSpinner();
        this._clintOnboardingSvc.saveClient().subscribe({
          next: (response) => {
            if (response) {
              this._sharedSvc.hideSpinner();
              this._sharedSvc.success(this._sharedSvc.deleteMsg);
              this._clintOnboardingSvc.InItGetAllClients();
            }
          },
          error: (e) => {
            this._sharedSvc.hideSpinner();
            if (e.status == 401) {
              this._sharedSvc.error(this._sharedSvc.authMsg);
              this._sharedSvc.logout();
              return;
            }
            if (e?.error) {
              Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
                this._sharedSvc.error(value);
              });
            }
            if (e?.error?.errorMessage) {
              this._sharedSvc.error(e?.error?.error);
            }
          },
          // complete: () => console.info('complete')
        })
        // console.log("Delete Success");
      }
      else {
        //in case of cancel
        return;
      }
    })
      .catch(() => {
        // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)');
        return;
      });
  }

  cnicValidation(): void {
    if (this.validationForm.invalid) {
      for (const control of Object.keys(this.validationForm.controls)) {
        this.validationForm.controls[control].markAsTouched();
      }
      return;
    }
    this._sharedSvc.showSpinner();
    this.setValuesToModel();
    this.convertNGBDate()
    this._clintOnboardingSvc.CNICValidation().subscribe({
      next: (response: any) => {
        if (response) {
          this._sharedSvc.hideSpinner()
          this._sharedSvc.personalInfo_FS = true;
          if (response.client.canProceed == true) {
            this.modalService.dismissAll();
            localStorage.setItem('saveCNICModel', JSON.stringify(this._clintOnboardingSvc.cnicValidationModel));
            localStorage.setItem('reload', "true");
            this.router.navigate(['/onboarding-personal-info']);
          }
          else {
            if (response.client.reason) {
              this._sharedSvc.error(response.client.reason);
            }
            else if (response.client.error == 'Client already registered') {
              this._sharedSvc.error(response?.client?.error);
              this.modalService.dismissAll();
              // this.popupEditBtn = true;
            }
            else {
              this._sharedSvc.error(response?.client?.error);
            }
          }
          if (response.client.clientInfo) {
            localStorage.setItem('SaveCnicData', JSON.stringify(response.client.clientInfo));
            this._clintOnboardingSvc.edit = true;
          }
          this.convertDateToNGB();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        this.convertDateToNGB();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }

  setValuesToModel() {
    this._clintOnboardingSvc.cnicValidationModel.cnicNum = this.cnicNum.value;
    this._clintOnboardingSvc.cnicValidationModel.issueDate = this.issueDate.value;
    this._clintOnboardingSvc.cnicValidationModel.expiryDate = this.expiryDate.value;
    this._clintOnboardingSvc.cnicValidationModel.typ = this.typ.value;
  }

  convertNGBDate() {
    if (this._clintOnboardingSvc.cnicValidationModel.issueDate.year) {
      this._clintOnboardingSvc.cnicValidationModel.issueDate = this._clintOnboardingSvc.cnicValidationModel.issueDate.year + '-' + this._clintOnboardingSvc.cnicValidationModel.issueDate.month + '-' + this._clintOnboardingSvc.cnicValidationModel.issueDate.day
    }
    this._clintOnboardingSvc.cnicValidationModel.issueDate = new Date(this._clintOnboardingSvc.cnicValidationModel.issueDate);
    if (this._clintOnboardingSvc.cnicValidationModel.expiryDate.year) {
      this._clintOnboardingSvc.cnicValidationModel.expiryDate = this._clintOnboardingSvc.cnicValidationModel.expiryDate.year + '-' + this._clintOnboardingSvc.cnicValidationModel.expiryDate.month + '-' + this._clintOnboardingSvc.cnicValidationModel.expiryDate.day
    }
    this._clintOnboardingSvc.cnicValidationModel.expiryDate = new Date(this._clintOnboardingSvc.cnicValidationModel.expiryDate);
  }

  convertDateToNGB() {
    let tempDepositDate = new Date(this._clintOnboardingSvc.cnicValidationModel.issueDate);
    this._clintOnboardingSvc.cnicValidationModel.issueDate = { year: tempDepositDate.getFullYear(), month: tempDepositDate.getMonth() + 1, day: tempDepositDate.getDate() };
    let tempDepositDate2 = new Date(this._clintOnboardingSvc.cnicValidationModel.expiryDate);
    this._clintOnboardingSvc.cnicValidationModel.expiryDate = { year: tempDepositDate2.getFullYear(), month: tempDepositDate2.getMonth() + 1, day: tempDepositDate2.getDate() };
  }

  onEdit(data?: any, status?: any) {
    localStorage.setItem('clientOnbrdEditCheck', JSON.stringify(true));
    sessionStorage.setItem('clientOnbrdViewCheck', JSON.stringify(false));
    if (data.clntStsKey != 699) {
      this._sharedSvc.error(this.translate.instant('toast.canNotEdit'));
      return;
    }
    this._sharedSvc.active = 1;
    this._sharedSvc.personalInfo_FS = true;
    localStorage.setItem('reload', "true");
    if (data) {
      this._clintOnboardingSvc.edit = true;
      localStorage.setItem('FM', JSON.stringify("FM"));
      localStorage.setItem('SaveCnicData', JSON.stringify(data));
      this.router.navigate(['/onboarding-personal-info/' + data.clntSeq]);
    }
    else {
      this.saveCNICModel = localStorage.getItem('SaveCnicData');
      this.saveCNICModel = JSON.parse(this.saveCNICModel);
      this.router.navigate(['/onboarding-personal-info/' + this.saveCNICModel.clntSeq]);
    }
  }

  validateForm() {
    this.validationForm = new FormGroup({
      cnicNum: new FormControl("", [Validators.required, Validators.minLength(13), Validators.maxLength(13),]),
      issueDate: new FormControl("", [Validators.required]),
      expiryDate: new FormControl("", [Validators.required]),
      typ: new FormControl("0", []),
    });
  }

  getCustomerTypeFromRefCdSeq(refcod: any) {
    for (let i = 0; i < this._picklistService.CUSTOMER_TYPE_Data.length; i++) {
      if (Number(this._picklistService.CUSTOMER_TYPE_Data[i].refCdSeq) === refcod) {
        return this._picklistService.CUSTOMER_TYPE_Data[i].refCdDscr;
      }

    }
  }
  getOccupationTypeFromRefCdSeq(refcod: any) {
    for (let i = 0; i < this._picklistService.OCCUPATION_Data.length; i++) {
      if (Number(this._picklistService.OCCUPATION_Data[i].refCdSeq) === refcod) {
        return this._picklistService.OCCUPATION_Data[i].refCdDscr;
      }

    }
  }
  getAppStatus(refcod: any) {
    for (let i = 0; i < this._picklistService.APPLICATION_STATUS_DATA.length; i++) {
      if (Number(this._picklistService.APPLICATION_STATUS_DATA[i].refCdSeq) === refcod) {
        return this._picklistService.APPLICATION_STATUS_DATA[i].refCdDscr;
      }

    }
  }

  get cnicNum() { return this.validationForm.get('cnicNum') }
  get issueDate() { return this.validationForm.get('issueDate') }
  get expiryDate() { return this.validationForm.get('expiryDate') }
  get typ() { return this.validationForm.get('typ') }

}