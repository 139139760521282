<section>
  <div class="sidebar" [class.sidebar-close]="!_sharedSvc.openSidebar">
    <app-sidebar></app-sidebar>
  </div>
  <div class="home-section">
    <div class="home-main" [class.sidebar-close_home-main]="!_sharedSvc.openSidebar">
      <app-navbar></app-navbar>
      <div class="home-view" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
        [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
        <section>
          <div class="row mb-3">
            <div class="col-sm-8 col-md-8 col-lg-8">
              <h4>{{ "sideBarMenu.setup.tags" | translate }}</h4>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4">
              <div class="add-button" (click)="openModal(addTag, false)">
                <i class="fa fa-plus"></i>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="dashboard-card loan-table">
                <div class="row mb-3">
                  <div class="col-sm-7 col-md-7 col-lg-8">
                    <ng-container *ngIf="_sharedSvc.pageSize >= 10">
                      <span>{{ "common.show" | translate }}</span>
                      <input name="entries" class="m-1 text-center" [value]="_SetupService.riskTagTablePG?.length"
                        readonly style="width: 45px" />
                      <span>{{ "common.entries" | translate }}</span>
                    </ng-container>
                  </div>
                  <div class="col-sm-5 col-md-5 col-lg-4">
                    <div class="inside-icon">
                      <i class="fa fa-search"></i>
                      <input type="search" class="form-control search-input"
                        placeholder="{{ 'common.search' | translate }}" />
                    </div>
                  </div>
                </div>
                <div class="table-responsive">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <td>{{ "tags.tagsId" | translate }}</td>
                        <td>{{ "tags.tagsName" | translate }}</td>
                        <td>{{ "tags.tagsDescription" | translate }}</td>
                        <td>{{ "tags.severityFlag" | translate }}</td>
                        <td class="text-center">
                          {{ "common.action" | translate }}
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let list of _SetupService.riskTagTablePG">
                        <td>{{ list.tagsSeq }}</td>
                        <td>{{ list.tagNm }}</td>
                        <td>{{ list.tagDscr }}</td>
                        <td>{{ list.blockFlag === true ? "Yes" : "No" }}</td>
                        <td class="text-center">
                          <div class="action-inner">
                            <i class="fa fa-pencil" (click)="editSetupTag(addTag, list.tagsSeq, true)"></i>
                            <i class="fa fa-trash" (click)="deleteRecord(list.tagsSeq)"></i>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-6">
                      <span class="clr-9d9a9a">{{ "common.showing" | translate }}
                        {{ _SetupService.riskTagTablePG?.length }}
                        {{ "common.of" | translate }}
                        {{ _SetupService.collectionSizeRiskTag }}
                        {{ "common.entries" | translate }}</span>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6">
                      <div class="float-right">
                        <ngb-pagination [(page)]="_sharedSvc.page" [pageSize]="_sharedSvc.pageSize" [maxSize]="10"
                          ngDefaultControl [collectionSize]="_SetupService.collectionSizeRiskTag" [rotate]="true"
                          (pageChange)="_SetupService.pageChangeRiskTag()">
                          <ng-template ngbPaginationPrevious>{{
                            "common.prev" | translate
                            }}</ng-template>
                          <ng-template ngbPaginationNext>{{
                            "common.next" | translate
                            }}</ng-template>
                        </ngb-pagination>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>

<ng-template id="display-modal" #addTag let-c="close" let-d="dismiss">
  <div class="modal-body" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
    [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
    <div class="p-3 pb-0">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center">
            <h4>{{ "common.tags" | translate }}</h4>
          </div>
        </div>
      </div>
      <form [formGroup]="addSetupTagformData" (ngSubmit)="submitSetupTagForm(addSetupTagformData)">
        <div class="row mb-3">
          <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
            <div class="form-floating">
              <input type="text" style="text-transform: capitalize" autocomplete="off" class="form-control input-radius"
                formControlName="tagNm" [class.is-invalid]="
                  tagNm.invalid && (tagNm.dirty || tagNm.touched)
                " />
              <div *ngIf="tagNm.invalid && (tagNm.dirty || tagNm.touched)" class="invalid-feedback">
                <div *ngIf="tagNm.errors?.['required']">
                  {{ "tags.tagsNameRequire" | translate }}
                </div>
              </div>
              <label>{{ "tags.tagsName" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
            <div class="form-floating">
              <select name="gndrKey" id="gndrKey" class="form-control input-radius form-select"
                formControlName="blockFlag" [class.is-invalid]="
                  blockFlag.invalid && (blockFlag.dirty || blockFlag.touched)
                ">
                <option [value]="true">{{ "common.yes" | translate }}</option>
                <option [value]="false">{{ "common.no" | translate }}</option>
              </select>
              <div *ngIf="
                  blockFlag.invalid && (blockFlag.dirty || blockFlag.touched)
                " class="invalid-feedback">
                <div *ngIf="blockFlag.errors?.['required']">
                  {{ "tags.severityFlagRequire" | translate }}
                </div>
              </div>
              <label>{{ "tags.severityFlag" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
            <div class="form-floating">
              <input type="text" style="text-transform: capitalize" autocomplete="off" class="form-control input-radius"
                formControlName="tagDscr" [class.is-invalid]="
                  tagDscr.invalid && (tagDscr.dirty || tagDscr.touched)
                " />
              <div *ngIf="tagDscr.invalid && (tagDscr.dirty || tagDscr.touched)" class="invalid-feedback">
                <div *ngIf="tagDscr.errors?.['required']">
                  {{ "common.descriptionRequire" | translate }}
                </div>
              </div>
              <label>{{ "common.description" | translate }}</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="button_div">
              <button class="btn login-button-class" [class.pointerNone]="_sharedSvc.loading"
                [disabled]="_sharedSvc.loading">
                {{ "common.ok" | translate }}
              </button>
              <button class="btn login-button-class cancelBtn" (click)="d('Cross click')" type="reset">
                {{ "common.cancel" | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>