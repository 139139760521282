import { Pipe, PipeTransform } from '@angular/core';
import { ClintOnboardingService } from '../../clint-onboarding/shared/service/clint-onboarding.service';

@Pipe({
  name: 'tableFilterOnBoarding'
})
export class TableFilterOnboardingPipe implements PipeTransform {
  /**
   *
   */
  getAllClient:boolean=true;
  constructor(public _clintOnboardingSvc: ClintOnboardingService,) {
    
  }

  transform(list:any[],value: string) {
    if(value){
      this.getAllClient=this._clintOnboardingSvc.getAllClient
      this._clintOnboardingSvc.getAllClient=this._clintOnboardingSvc.getAllClient?.filter(item => item.cnicNum === parseInt(value) || item.frstNm === value);
      this._clintOnboardingSvc.pageChange();
    }else if(this.getAllClient){
      this._clintOnboardingSvc.InItGetAllClients();
      this.getAllClient=false;
    }
    return value ? list?.filter(item => item.cnicNum === parseInt(value) || item.frstNm === value) : list;
  }

}