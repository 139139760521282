import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';
import { RoutingConditionsService } from '../clint-onboarding/shared/service/routing-conditions.service';
import { SharedService } from '../shared/service/shared.service';
import { DashboardService } from './shared/service/dashboard.service';
import { RouteInfo } from 'src/app/auth/model/route-info';
import { ClintOnboardingService } from '../clint-onboarding/shared/service/clint-onboarding.service';
import { RecoveryService } from '../recovery/shared/service/recovery.service';
import { userDataClientCreditService } from '../shared/service/userData-client-credit.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedPopupService } from '../shared/service/shared-popup.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { PopupService } from '@ng-bootstrap/ng-bootstrap/util/popup';

HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, AfterViewInit {
  dashboardView: boolean = true;
  jwtInfo: any;
  allowedRoutes: RouteInfo[];
  entyKey: any
  selectedManager: any
  currentTaskData: any
  dateTxt: any = 'Date';
  dayTxt: any = 'Day';
  amountTxt: any = 'Amount';
  manualTxt: any = 'Manual';
  weekDays: any = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  constructor(
    public _sharedSvc: SharedService,
    public router: Router,
    public _dashboardSvc: DashboardService,
    public _conditionsService: RoutingConditionsService,
    public _clintOnboardingSvc: ClintOnboardingService,
    public _recoveryService: RecoveryService,
    public _userDataClientCreditService: userDataClientCreditService,
    private modalService: NgbModal,
    public translate: TranslateService,
    public _sharedPopupSvc:SharedPopupService



  ) {
    this.allowedRoutes = JSON.parse(sessionStorage.getItem("routeInfo"));
  }

  ngOnInit() {
    this.jwtInfo = sessionStorage.getItem('JWT');
    this.jwtInfo = JSON.parse(this.jwtInfo);
    this._clintOnboardingSvc.InItGetApprovedClients();
    let portSeq = sessionStorage.getItem("JWT");
    let ID = JSON.parse(portSeq)?.port;
    this._dashboardSvc.get_Branch_ID(ID);

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.dateTxt = this.translate.instant('common.date');
      this.dayTxt = this.translate.instant('common.day');
      this.amountTxt = this.translate.instant('common.amount');
      this.manualTxt = this.translate.instant('common.manual');
      this.weekDays[0] = this.translate.instant('dashboard.monday');
      this.weekDays[1] = this.translate.instant('dashboard.Tuesday');
      this.weekDays[2] = this.translate.instant('dashboard.wednesday');
      this.weekDays[3] = this.translate.instant('dashboard.thursday');
      this.weekDays[4] = this.translate.instant('dashboard.friday');
      this.weekDays[5] = this.translate.instant('dashboard.saturday');
      this.weekDays[6] = this.translate.instant('dashboard.sunday');
      this.createChartColumn();
      this.createChartPie1();
      this.createChartPie2();
      this.createChartPie3();
      this.createChartPie4();
    });

  }

  public ngAfterViewInit(): void {
    this.createChartColumn();
    this.createChartPie1();
    this.createChartPie2();
    this.createChartPie3();
    this.createChartPie4();
  }

  private getRandomNumber(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }
  private createChartColumn(): void {
    let date = new Date();
    const data: any[] = [8, 5.4, 2, { y: 4.75, color: 'yellow' }, 1, 1.8, 9];


    // for (let i = 0; i < 10; i++) {
    //   date.setDate(new Date().getDate() + i);
    //   data.push({
    //     name: `${date.getDate()}/${date.getMonth() + 1}`,
    //     y: this.getRandomNumber(0, 50),
    //   });
    // }

    const chart = Highcharts.chart('chart-column' as any, {
      chart: {
        type: 'column',

      },
      title: {
        text: undefined,
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      yAxis: {
        min: 0,
        max: 10,
        title: undefined,
        linWidth: 3

      },
      xAxis: {
        // categories: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
        categories: this.weekDays
      },
      tooltip: {
        headerFormat: `<div>${this.dayTxt}: {point.key}</div>`,
        // pointFormat: `<div>{series.name}: {point.y}</div>`,
        pointFormat: `<div>${this.manualTxt}: {point.y}</div>`,
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
          },
        },
      },
      series: [{
        name: 'Manual',
        data,
      }],
    } as any);

    // setInterval(() => {
    //   date.setDate(date.getDate() + 1);
    //   chart.series[0].addPoint({
    //     name: `${date.getDate()}/${date.getMonth() + 1}`,
    //     y: this.getRandomNumber(0, 50),
    //   }, true, true);
    // }, 1500);
  }
  private createChartLine(): void {
    let date = new Date();
    const data: any[] = [];

    for (let i = 0; i < 10; i++) {
      date.setDate(new Date().getDate() + i);
      data.push([`${date.getDate()}/${date.getMonth() + 1}`, this.getRandomNumber(0, 50)]);
    }

    const chart = Highcharts.chart('chart-line', {
      chart: {
        type: 'line',
      },
      title: {
        text: 'Line Chart',
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      yAxis: {
        title: {
          text: null,
        }
      },
      xAxis: {
        type: 'category',
      },
      tooltip: {
        headerFormat: `<div>Date: {point.key}</div>`,
        pointFormat: `<div>{series.name}: {point.y}</div>`,
        shared: true,
        useHTML: true,
      },
      series: [{
        name: 'Amount',
        data,
      }],
    } as any);

    // setInterval(() => {
    //   date.setDate(date.getDate() + 1);
    //   chart.series[0].addPoint([`${date.getDate()}/${date.getMonth() + 1}`, this.getRandomNumber(0, 50)], true, true);
    // }, 1500);
  }
  private createChartPie1(): void {
    
    let date = new Date();
    const data: any[] = [];

    for (let i = 0; i < 6; i++) {
      date.setDate(new Date().getDate() + i);
      data.push([`${date.getDate()}/${date.getMonth() + 1}`, this.getRandomNumber(0, 50)]);
    }

    const chart = Highcharts.chart('chart-pie-1', {
      chart: {
        type: 'pie',
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: true,
      },
      yAxis: {
        title: {
          text: null,
        }
      },
      xAxis: {
        type: 'category',
      },
      tooltip: {
        // headerFormat: `<div>${this.translate.instant('dateHeader', { date: this.dateTxt })}</div>`,
        headerFormat: `<div>${this.dateTxt}: {point.key}</div>`,
        // pointFormat: `<div>{series.name}: {point.y}</div>`,
        pointFormat: `<div>${this.amountTxt}: {point.y}</div>`,
        shared: false,
        useHTML: false,
      },
      series: [{
        name: 'Amount',
        data,
      }],
    } as any);

    // setInterval(() => {
    //   date.setDate(date.getDate() + 1);
    //   chart.series[0].addPoint([`${date.getDate()}/${date.getMonth() + 1}`, this.getRandomNumber(0, 50)], true, true);
    // }, 1500);
  }
  private createChartPie2(): void {
    let date = new Date();
    const data: any[] = [];

    for (let i = 0; i < 5; i++) {
      date.setDate(new Date().getDate() + i);
      data.push([`${date.getDate()}/${date.getMonth() + 1}`, this.getRandomNumber(0, 50)]);
    }

    const chart = Highcharts.chart('chart-pie-2', {
      chart: {
        type: 'pie',
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      yAxis: {
        title: {
          text: null,
        }
      },
      xAxis: {
        type: 'category',
      },
      tooltip: {
        headerFormat: `<div>${this.dateTxt}: {point.key}</div>`,
        pointFormat: `<div>${this.amountTxt}: {point.y}</div>`,
        shared: true,
        useHTML: true,
      },
      series: [{
        name: 'Amount',
        data,
      }],
    } as any);

    // setInterval(() => {
    //   date.setDate(date.getDate() + 1);
    //   chart.series[0].addPoint([`${date.getDate()}/${date.getMonth() + 1}`, this.getRandomNumber(0, 50)], true, true);
    // }, 1500);
  }
  private createChartPie3(): void {
    let date = new Date();
    const data: any[] = [];

    for (let i = 0; i < 4; i++) {
      date.setDate(new Date().getDate() + i);
      data.push([`${date.getDate()}/${date.getMonth() + 1}`, this.getRandomNumber(0, 50)]);
    }

    const chart = Highcharts.chart('chart-pie-3', {
      chart: {
        type: 'pie',
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      yAxis: {
        title: {
          text: null,
        }
      },
      xAxis: {
        type: 'category',
      },
      tooltip: {
        headerFormat: `<div>${this.dateTxt}: {point.key}</div>`,
        pointFormat: `<div>${this.amountTxt}: {point.y}</div>`,
        shared: true,
        useHTML: true,
      },
      series: [{
        name: 'Amount',
        data,
      }],
    } as any);

    // setInterval(() => {
    //   date.setDate(date.getDate() + 1);
    //   chart.series[0].addPoint([`${date.getDate()}/${date.getMonth() + 1}`, this.getRandomNumber(0, 50)], true, true);
    // }, 1500);
  }
  private createChartPie4(): void {
    let date = new Date();
    const data: any[] = [];

    for (let i = 0; i < 3; i++) {
      date.setDate(new Date().getDate() + i);
      data.push([`${date.getDate()}/${date.getMonth() + 1}`, this.getRandomNumber(0, 50)]);
    }

    const chart = Highcharts.chart('chart-pie-4', {
      chart: {
        type: 'pie',
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      yAxis: {
        title: {
          text: null,
        }
      },
      xAxis: {
        type: 'category',
      },
      tooltip: {
        headerFormat: `<div>${this.dateTxt}: {point.key}</div>`,
        pointFormat: `<div>${this.amountTxt}: {point.y}</div>`,
        shared: true,
        useHTML: true,
      },
      series: [{
        name: 'Amount',
        data,
      }],
    } as any);

    // setInterval(() => {
    //   date.setDate(date.getDate() + 1);
    //   chart.series[0].addPoint([`${date.getDate()}/${date.getMonth() + 1}`, this.getRandomNumber(0, 50)], true, true);
    // }, 1500);
  }

  clickDashboard() {
    this.dashboardView = true;
  }

  clickOther() {
    let id = this.jwtInfo.preferred_username;
    this.dashboardView = false;
    
    this._dashboardSvc.InItGetTaskList(id);
  }

  onSelectManager(event: any) {
    this.selectedManager = event.target.value;
  }
  clearSelectedManager() {
    this.selectedManager = null;
  }

  openClientModal(content: any, data: any) {
    this.currentTaskData = data;
    this.modalService.open(content, {
      // backdrop: "static",
      // keyboard: false,
      windowClass: "popupWidth800",
      // backdropClass: 'light-blue-backdrop',
      centered: true
    });
  }

  assignTaskToUser(): void {
    
    this._sharedSvc.showSpinner();
    let data = {
      taskKey: this.currentTaskData.aprvlTskKey,
      userId: this.selectedManager
    }
    this._dashboardSvc.assignTaskToUser(data).subscribe({
      next: (response: any) => {
        this._sharedSvc.hideSpinner();
        if (response) {
          this.clickOther();
          if (response.error) {
            this._sharedSvc.error(response.error);
            return;
          }
          this._sharedSvc.success(this._sharedSvc.msg);
          this.modalService.dismissAll();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }

  approveTask(data: any): void {
    this._sharedSvc.showSpinner();
    this._dashboardSvc.approveTask(data.aprvlTskKey).subscribe({
      next: (response: any) => {
        this._sharedSvc.hideSpinner();
        if (response) {
          this.clickOther();
          if (response.error) {
            this._sharedSvc.error(response.error);
            return;
          }
          this._sharedSvc.success(this._sharedSvc.msg);
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }

  rejectTask(data: any): void {
    if(data.entyFlg !== 1){   
    this._sharedPopupSvc.popup("", "Do you  want to reject Credit App ?", "", "Declined", "Cancel", null,null).then((confirmed: any) => {
      if (confirmed) {
        // Delete function call        
        this._sharedSvc.showSpinner();  
    this._dashboardSvc.rejectTask(data.aprvlTskKey).subscribe({
      next: (response: any) => {
        if (response) {
          this.clickOther();
          this._sharedSvc.hideSpinner();
          if (response.error) {
            this._sharedSvc.error(response.error);
            return;
          }
          this._sharedSvc.success(this._sharedSvc.msg);
          this._sharedSvc.msg = "Credit App Rejected"; 
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    }) 
          
      }
      else {
        //in case of cancel
        return;
      }
    })
      .catch(() => {
        // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)');
        return;
      });
    }else{  
    this._sharedSvc.showSpinner();  
    this._dashboardSvc.rejectTask(data.aprvlTskKey).subscribe({
      next: (response: any) => {
        if (response) {
          this.clickOther();
          this._sharedSvc.hideSpinner();
          if (response.error) {
            this._sharedSvc.error(response.error);
            return;
          }
          this._sharedSvc.success(this._sharedSvc.msg);
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }
  }

  readOnlyView(item:any){    
    this._conditionsService.readOnlyView_Client = true;
    localStorage.setItem('readOnlyView_Client', 'true')
    if (item.entyFlg == 1) {
      this.router.navigate(["/view-onboarding/" + item.entyKey]);
    } else if (item.entyFlg == 2) {
      this.entyKey = item.entyKey;
      this._conditionsService.readOnlyView_Credit = true;
      this.GetClientDetailsByAppSeq(item.entyKey)

    }

  }

  GetClientDetailsByAppSeq(appseq: any): void {


    this._sharedSvc.showSpinner();
    this._recoveryService.getClientDetailsByAppseq(appseq).subscribe({
      next: (response: any) => {
        if (response) {
          this._sharedSvc.hideSpinner();
          console.log(response);
          this.searchPaymentSchedule(response.clntId);
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }

  searchPaymentSchedule(clntId: any): void {
    this._sharedSvc.showSpinner();
    this._recoveryService.srch_pymt_sched(clntId, undefined, undefined).subscribe({
      next: (response: any) => {
        if (response) {
          this._sharedSvc.hideSpinner();
          console.log(response);
          this._recoveryService._recoverydata = response;
          console.log(response)
          this._userDataClientCreditService.setSharedData(response);
          sessionStorage.setItem("shareUserData", JSON.stringify(response))
          this.router.navigate(["/edit-individual-group-info/" + this.entyKey]);
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }

  showOption(name: string) {
    return this.allowedRoutes?.filter(it => it.sbModUrl.toLowerCase() == name.toLowerCase()
      || name.toLowerCase() == it.sbModUrl.toLowerCase()).length > 0
  }

  hasChild(name: string) {
    return this.allowedRoutes?.filter(it => it.sbModUrl.toLowerCase().includes(name.toLowerCase())
      || name.toLowerCase().includes(it.sbModUrl.toLowerCase())).length > 0
  }

  test() {
    this.dateTxt = this.translate.instant('common.date');
    console.log('Test_2=>', this.dateTxt);

  }



}

