<div class="row mb-3" [ngClass]="{ disabled: disableAdd() }">
  <div class="col-sm-12 col-md-12 col-lg-12">
    <div class="add-button" (click)="openModal(disbursement_voucher)">
      <i class="fa fa-plus"></i>
    </div>
  </div>
</div>

<div class="table-responsive">
  <table class="table table-bordered">
    <thead>
      <tr>
        <td>{{ "common.paymentMode" | translate }}</td>
        <td>
          {{ "common.instrument" | translate }}/{{
          "clientOnboarding.mobileNumber" | translate
          }}.
        </td>
        <td>{{ "common.amount" | translate }}</td>
        <!-- <td>M&nbsp;Vault</td> -->
        <td class="text-center">{{ "common.action" | translate }}</td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let voucher of disbustmentVocher; let i = index">
        <td *ngIf="!disableAction(i)">{{ voucher.pymtTypSeq }}</td>
        <td *ngIf="!disableAction(i)">{{ voucher.instrNum }}</td>
        <td *ngIf="!disableAction(i)">{{ voucher.dsbmtAmount }}</td>
        <!-- <td>12360</td> -->
        <td class="text-center" *ngIf="!disableAction(i)">
          <div class="action-inner">
            <i class="fa fa-pencil" [ngClass]="{ disabled: disableAction(i) }"
              (click)="editModal(disbursement_voucher, i)"></i>
            <i class="fa fa-trash" [ngClass]="{ disabled: disableAction(i) }" (click)="markAsDelete(i)"></i>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <!-- <div class="row g-0">
        <div class="col-sm-12 col-md-12 col-lg-6">
            <span class="clr-9d9a9a">Showing 1 to 3 of 3 entries</span>
        </div>
       <div class="col-sm-12 col-md-12 col-lg-6">
            <div class="float-right">
                <ngb-pagination [(page)]="_sharedSvc.page"
                    [pageSize]="_sharedSvc.pageSize" [maxSize]="10" ngDefaultControl
                    [collectionSize]="_sharedSvc.collectionSize" [rotate]="true"
                    (pageChange)="_clintOnboardingSvc.pageChange()">
                    <ng-template ngbPaginationPrevious>Prev</ng-template>
                    <ng-template ngbPaginationNext>Next</ng-template>
                </ngb-pagination>
            </div>
        </div>
        
    </div> -->
</div>

<ng-template id="display-modal" #disbursement_voucher let-c="close" let-d="dismiss">
  <div class="modal-body" [dir]="
      translate?.currentLang === 'ar' || translate?.currentLang === 'ur'
        ? 'rtl'
        : ''
    " [class.fix-direction]="
      translate?.currentLang === 'ar' || translate?.currentLang === 'ur'
    ">
    <div class="p-3 pb-0">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center">
            <h4>{{ "common.voucher" | translate }}</h4>
          </div>
        </div>
      </div>
      <form [formGroup]="voucher_form">
        <div class="row mb-3">
          <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
            <div class="form-floating">
              <select class="form-control form-select input-radius" formControlName="pymtType">
                <option value="M-Valut" selected> {{'common.mVault' | translate}}</option>
              </select>
              <label>{{ "common.paymentMode" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
            <div class="form-floating">
              <input [ngClass]="{ error_border: amount.errors && amount.touched }" (blur)="validateAmount()" type="text"
                style="text-transform: capitalize" autocomplete="off" formControlName="amount"
                class="form-control input-radius" maxlength="6" minlength="3" (keypress)="numberOnly($event)" />
              <label>{{ "common.amount" | translate }}</label>
            </div>
            <div *ngIf="amount.invalid" class="error_alert">
              <div *ngIf="amount.errors?.['required'] && amount.touched">
                {{ "common.amountRequired" | translate }}
              </div>
              <div *ngIf="amount.errors?.['minlength'] && amount.touched">
                {{ "common.amountMustThreeLetters" | translate }}
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
            <div class="form-floating">
              <input phoneMask="9999-9999999" [ngClass]="{
                  error_border: instrNum.errors && instrNum.touched
                }" type="text" style="text-transform: capitalize" autocomplete="off" class="form-control input-radius"
                formControlName="instrNum" maxlength="12" (keypress)="numberOnly($event)" />
              <label> {{'common.mVault' | translate}}</label>
            </div>
            <div *ngIf="instrNum.invalid" class="error_alert">
              <div *ngIf="instrNum.errors?.['required'] && instrNum.touched">
                {{'common.mVault' | translate}} {{ "common.isRequired" | translate }}
              </div>
              <div *ngIf="instrNum.errors?.['minlength'] && instrNum.touched">
                {{'common.mVault' | translate}} {{ "common.mustBe12Char" | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="button_div">
              <button class="btn login-button-class" (click)="onSubmiVocherForm()">
                {{ "common.ok" | translate }}
              </button>
              <button class="btn login-button-class cancelBtn" (click)="d('Cross click')" type="reset">
                {{ "common.cancel" | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>