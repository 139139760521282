import { Component, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SharedService } from "src/app/components/shared/service/shared.service";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
  NgForm,
} from "@angular/forms";

import { ClintOnboardingService } from "src/app/components/clint-onboarding/shared/service/clint-onboarding.service";
import { SetupService } from "../../shared/service/setup.service";
import { PicklistService } from "src/app/components/shared/pickList/picklist.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-uinio-council",
  templateUrl: "./uinio-council.component.html",
  styleUrls: ["./uinio-council.component.css"],
})
export class UinioCouncilComponent implements OnInit {
  loadingIndicator: boolean = false;
  rows: any;

  page: number = 5;
  pageSize: number = 5;
  collectionSize: number = 5;

  loginForm!: FormGroup;
  submitted = false;
  // currentDate: any = new Date().toISOString().split("T")[0];
  onBoardingTablePG: any;
  maskedCNIC: string;
  singleRecord: any;
  isEdit: boolean = false;
  addUCFormdata: any;
  thslID: any;

  constructor(
    private modalService: NgbModal,
    public router: Router,
    public _sharedSvc: SharedService,
    public _clintOnboardingSvc: ClintOnboardingService,
    public _SetupService: SetupService,
    private formBuilder: FormBuilder,
    public _picklistService: PicklistService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    let thslID = sessionStorage.getItem("tehsilSeqID");
    this.thslID = thslID;
    this._SetupService.get_geo_UC_list(thslID);

    this.addUCFormdata = this.formBuilder.group({
      ucName: ["", Validators.required],
      ucStatusKey: ["", Validators.required],
      ucDescription: ["", Validators.required],
    });
    this._picklistService.SHROFF_STATUS();
  }

  get ucName() {
    return this.addUCFormdata.get("ucName");
  }
  get ucStatusKey() {
    return this.addUCFormdata.get("ucStatusKey");
  }
  get ucDescription() {
    return this.addUCFormdata.get("ucDescription");
  }

  submitUC(val) {
    if (this.addUCFormdata.invalid) {
      for (const control of Object.keys(this.addUCFormdata.controls)) {
        this.addUCFormdata.controls[control].markAsTouched();
      }
      return;
    }
    let data = {
      ...this.addUCFormdata.value,
      tehsilSequence: Number(this.thslID),
      ucCode: "0",
      tenantKey: "0",
      ucSequence: null,
      delFlg: 0,
    };
    if (this.isEdit) {
      data.ucSequence = this.singleRecord.ucSequence;
    }
    this._SetupService.setupGeoUCModel = data;
    this._SetupService.saveGeoUC().subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.success(
            this.translate.instant("toast.uc") +
              (this.isEdit === true
                ? this.translate.instant("toast.updated")
                : this.translate.instant("toast.added"))
          );
          this._sharedSvc.hideSpinner3();
          this.modalService.dismissAll();
          this._SetupService.get_geo_UC_list(this.thslID);
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner3();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(
            ([key, value]: any, index: number) => {
              this._sharedSvc.error(value);
            }
          );
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    });
  }

  editUC(content: any, id: any, edit: any) {
    let list = this._SetupService.geoUCList;
    let record = list.find((x) => x.ucSequence === id);
    this.singleRecord = record;

    if (record) {
      let data = {
        ucName: record.ucName,
        ucStatusKey: record.ucStatusKey,
        ucDescription: record.ucDescription,
      };
      this.addUCFormdata.patchValue(data);
      this.openModal(content, edit);
    }
  }

  deleteRecord(id) {
    
    let list = this._SetupService.geoUCList;
    let record = list.find((x) => x.ucSequence === id);
    this.singleRecord = record;

    if (record) {
      let data = {
        ...record,
        delFlg: 1,
      };
      this._SetupService.setupGeoUCModel = data;
      this._SetupService.saveGeoUC().subscribe({
        next: (response) => {
          if (response) {
            this._sharedSvc.success(this.translate.instant("toast.ucDeleted"));
            this._sharedSvc.hideSpinner3();
            this.modalService.dismissAll();
            this._SetupService.get_geo_UC_list(this.thslID);
          }
        },
        error: (e) => {
          this._sharedSvc.hideSpinner3();
          if (e.status == 401) {
            this._sharedSvc.error(this._sharedSvc.authMsg);
            this._sharedSvc.logout();
            return;
          }
          if (e?.error) {
            Object.entries(e?.error).forEach(
              ([key, value]: any, index: number) => {
                this._sharedSvc.error(value);
              }
            );
          }
          if (e?.error?.errorMessage) {
            this._sharedSvc.error(e?.error?.error);
          }
        },
      });
    }
  }

  pageChange() {
    this.collectionSize = this._clintOnboardingSvc.onBoardingTable.length;
    this.onBoardingTablePG = this._clintOnboardingSvc.onBoardingTable.slice(
      (this.page - 1) * this.pageSize,
      (this.page - 1) * this.pageSize + this.pageSize
    );
    this.onBoardingTablePG;
  }

  openModal(content: any, edit: boolean) {
    localStorage.removeItem("selectedOrderData");
    this.isEdit = edit;

    this.modalService.open(content, {
      // backdrop: "static",
      // keyboard: false,
      windowClass: "popupWidth800",
      // backdropClass: 'light-blue-backdrop',
      centered: true,
    });
  }

  back() {
    this.router.navigate(["/setup-tehseel"]);
  }

  next() {
    this.router.navigate(["/setup-unionCouncil"]);
  }
  submittUnionConcil() {
    this._SetupService.saveCityUc().subscribe((data) => {
      alert("uc added");
      // console.log(data)
    });
  }
}
