<section>
  <div class="sidebar" [class.sidebar-close]="!_sharedSvc.openSidebar">
    <app-sidebar></app-sidebar>
  </div>
  <div class="home-section">
    <div class="home-main" [class.sidebar-close_home-main]="!_sharedSvc.openSidebar">
      <app-navbar></app-navbar>
      <div class="home-view" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
        [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
        <section>
          <div class="row mb-3">
            <div class="col-sm-8 col-md-8 col-lg-8">
              <h4>{{ "questionnaire.question.questionTitle" | translate }}</h4>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4">
              <div class="display-flex float-right">
                <div class="back-btn me-2" (click)="back()">
                  <i class="fa fa-reply"></i>
                </div>
                <div class="add-button" (click)="openModal(questionModal, false)">
                  <i class="fa fa-plus"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="dashboard-card loan-table">
                <div class="row mb-3">
                  <div class="col-sm-7 col-md-7 col-lg-8">
                    <ng-container *ngIf="_sharedSvc.pageSize >= 10">
                      <span>{{ "common.show" | translate }}</span>
                      <input type="number" class="m-1 text-center" style="width: 45px" />
                      <span>{{ "common.entries" | translate }}</span>
                    </ng-container>
                  </div>
                  <div class="col-sm-5 col-md-5 col-lg-4">
                    <div class="inside-icon">
                      <i class="fa fa-search"></i>
                      <input type="search" class="form-control search-input"
                        placeholder="{{ 'common.search' | translate }}" />
                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="table-responsive">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <td>
                              {{
                              "questionnaire.question.questionID" | translate
                              }}
                            </td>
                            <td>
                              {{
                              "questionnaire.question.questionCategory"
                              | translate
                              }}
                            </td>
                            <td>{{ "common.sortOrder" | translate }}</td>
                            <td>
                              {{
                              "questionnaire.question.questionType"
                              | translate
                              }}
                            </td>
                            <td>
                              {{
                              "questionnaire.question.questionTitle"
                              | translate
                              }}
                            </td>
                            <td>{{ "common.answer" | translate }}</td>
                            <td>{{ "common.status" | translate }}</td>
                            <td class="text-center">
                              {{ "common.action" | translate }}
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of _SetupService.questionsList">
                            <td>{{ item.qstSeq }}</td>
                            <td>{{ item.qstCtgryKey }}</td>
                            <td>{{ item.qstSortOrdr }}</td>
                            <td>{{ item.qstTypKey }}</td>
                            <td>{{ item.qstStr }}</td>
                            <!-- <td (click)="next()">{{item.tntKey}}</td> -->
                            <td (click)="next(item.qstSeq)" class="clr186ed1 cursor-pointer">
                              Answers
                            </td>
                            <td>{{ item.qstStsKey }}</td>
                            <td class="text-center">
                              <div class="action-inner">
                                <i class="fa fa-pencil" (click)="
                                    editQuestion(
                                      questionModal,
                                      item.qstSeq,
                                      true
                                    )
                                  "></i>
                                <i class="fa fa-trash" (click)="deleteQuestion(item.qstSeq)"></i>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="row g-0">
                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <span class="clr-9d9a9a">{{ "common.showing" | translate }}
                            {{ "common.1To3Of" | translate }}
                            3 {{ "common.entries" | translate }}</span>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <div class="float-right">
                            <nav aria-label="Page navigation example">
                              <ul class="pagination">
                                <li class="page-item">
                                  <a class="page-link">{{
                                    "common.first" | translate
                                    }}</a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link">{{
                                    "common.previous" | translate
                                    }}</a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link">1</a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link">2</a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link">3</a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link">4</a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link">5</a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link">{{
                                    "common.next" | translate
                                    }}</a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link">{{
                                    "common.last" | translate
                                    }}</a>
                                </li>
                              </ul>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>

<ng-template id="display-modal" #questionModal let-c="close" let-d="dismiss">
  <div class="modal-body" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
    [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
    <div class="p-3 pb-0">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center">
            <h4>
              {{
              isEdit === true
              ? this.translate.instant("common.edit")
              : this.translate.instant("common.add")
              }}
              {{ "questionnaire.question.questionTitle" | translate }}
            </h4>
          </div>
        </div>
      </div>
      <form [formGroup]="questionFormdata" (ngSubmit)="submitQuestion(questionFormdata)">
        <div class="row mb-3">
          <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
            <div class="form-floating">
              <input type="text" style="text-transform: capitalize" formControlName="qstStr" autocomplete="off"
                class="form-control input-radius" [class.is-invalid]="
                  qstStr.invalid && (qstStr.dirty || qstStr.touched)
                " />
              <div *ngIf="qstStr.invalid && (qstStr.dirty || qstStr.touched)" class="invalid-feedback">
                <div *ngIf="qstStr.errors?.['required']">
                  {{ "questionnaire.question.questionTitle" | translate }}
                </div>
              </div>
              <label>{{
                "questionnaire.question.questionTitle" | translate
                }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
            <div class="form-floating">
              <select name="qstCtgryKey" id="qstCtgryKey" class="form-control input-radius form-select"
                formControlName="qstCtgryKey" [class.is-invalid]="
                  qstCtgryKey.invalid &&
                  (qstCtgryKey.dirty || qstCtgryKey.touched)
                ">
                <!-- <option *ngFor="let item of _picklistService.SHROFF_STATUS_Data; let i = index"
                                    [value]="item?.refCdSeq">
                                    {{ item.refCdDscr }}
                                </option> -->
                <option value="null" hidden></option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
              <div *ngIf="
                  qstCtgryKey.invalid &&
                  (qstCtgryKey.dirty || qstCtgryKey.touched)
                " class="invalid-feedback">
                <div *ngIf="qstCtgryKey.errors?.['required']">
                  {{ "common.categoryRequire" | translate }}
                </div>
              </div>
              <label>{{ "common.category" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
            <div class="form-floating">
              <input type="text" style="text-transform: capitalize" formControlName="qstSortOrdr" autocomplete="off"
                class="form-control input-radius" [class.is-invalid]="
                  qstSortOrdr.invalid &&
                  (qstSortOrdr.dirty || qstSortOrdr.touched)
                " />
              <div *ngIf="
                  qstSortOrdr.invalid &&
                  (qstSortOrdr.dirty || qstSortOrdr.touched)
                " class="invalid-feedback">
                <div *ngIf="qstSortOrdr.errors?.['required']">
                  {{ "common.sortOrderRequire" | translate }}
                </div>
                <div *ngIf="qstSortOrdr.errors?.['notANumber']">
                  {{ "common.notANumber" | translate }}
                </div>
              </div>
              <label>{{ "common.sortOrder" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
            <div class="form-floating">
              <select name="qstStsKey" id="qstStsKey" class="form-control input-radius form-select"
                formControlName="qstStsKey" [class.is-invalid]="
                  qstStsKey.invalid && (qstStsKey.dirty || qstStsKey.touched)
                ">
                <!-- <option *ngFor="let item of _picklistService.SHROFF_STATUS_Data; let i = index"
                                    [value]="item?.refCdSeq">
                                    {{ item.refCdDscr }}
                                </option> -->
                <option value="null" hidden></option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
              <div *ngIf="
                  qstStsKey.invalid && (qstStsKey.dirty || qstStsKey.touched)
                " class="invalid-feedback">
                <div *ngIf="qstStsKey.errors?.['required']">
                  {{ "common.statusRequire" | translate }}
                </div>
              </div>
              <label>{{ "common.status" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
            <div class="form-floating">
              <select name="qstTypKey" id="qstTypKey" class="form-control input-radius form-select"
                formControlName="qstTypKey" [class.is-invalid]="
                  qstTypKey.invalid && (qstTypKey.dirty || qstTypKey.touched)
                ">
                <!-- <option *ngFor="let item of _picklistService.SHROFF_STATUS_Data; let i = index"
                                    [value]="item?.refCdSeq">
                                    {{ item.refCdDscr }}
                                </option> -->
                <option value="null" hidden></option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
              <div *ngIf="
                  qstTypKey.invalid && (qstTypKey.dirty || qstTypKey.touched)
                " class="invalid-feedback">
                <div *ngIf="qstTypKey.errors?.['required']">
                  {{ "common.typeRequire" | translate }}
                </div>
              </div>
              <label>{{ "common.type" | translate }}</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="button_div">
              <button class="btn login-button-class" [class.pointerNone]="_sharedSvc.loading"
                [disabled]="_sharedSvc.loading">
                {{ "common.save" | translate }}
              </button>
              <button class="btn login-button-class cancelBtn" (click)="d('Cross click')" type="reset">
                {{ "common.cancel" | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>