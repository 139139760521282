<div class="dashboard-card gradient-purple pointer" (click)="openLg(content1)">
  <div class="row">
    <div class="col-sm-2 col-md-2 col-lg-2">
      <i class="fa fa-clock-o"></i>
    </div>
    <div class="col-sm-10 col-md-10 col-lg-10">
      <h5 class="pointer">{{ "dashboard.overdue" | translate }}</h5>
      <h5>10</h5>
    </div>
  </div>
</div>
<ng-template id="display-modal" #content1 let-c="close" let-d="dismiss">
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12">
        <span class="popup-times" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true" class="pointer">&times;</span>
        </span>
      </div>
    </div>

    <div class="dashboard-card dashboard-model">
      <div class="row m-0">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center">
            <h4>{{ "dashboard.overdue" | translate }}</h4>
          </div>
        </div>
      </div>
      <div class="gray-Bg">
        <div class="row">
          <div class="col-sm-6 col-md-6 col-lg-6">
            <p>{{ "dashboard.clientsinOD" | translate }}</p>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-6">
            <div class="text-end">
              <p class="red-clr">10</p>
            </div>
          </div>
        </div>
      </div>
      <div class="gray-Bg">
        <div class="row">
          <div class="col-sm-6 col-md-6 col-lg-6">
            <p>{{ "dashboard.totalAmountinOD" | translate }}</p>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-6">
            <div class="text-end">
              <p class="red-clr">{{ "common.rs" | translate }} 100,232</p>
            </div>
          </div>
        </div>
      </div>
      <div class="gray-Bg">
        <div class="row">
          <div class="col-sm-6 col-md-6 col-lg-6">
            <p>1-29 {{ "common.days" | translate }}</p>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-6">
            <div class="text-end">
              <p>{{ "common.rs" | translate }} 1,232</p>
            </div>
          </div>
        </div>
      </div>
      <div class="gray-Bg">
        <div class="row">
          <div class="col-sm-6 col-md-6 col-lg-6">
            <p>30 {{ "common.days" | translate }}</p>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-6">
            <div class="text-end">
              <p>{{ "common.rs" | translate }} 6,232</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
