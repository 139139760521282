import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CookieService } from 'ngx-cookie-service';
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { environment } from "src/app/global";
import { SharedService } from "src/app/components/shared/service/shared.service";
import { DatePipe } from "@angular/common";
import { AdditionalInfo, BusinessExpense, BusinessIncome, SaveBizAprsl } from "../model/businessAppraisal";
import { Endpoint } from "src/app/components/shared/serviceEndPoints";
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})

export class BusinessAppraisalService {

  saveBizAprslModel = new SaveBizAprsl();
  businessExpenseModel = new BusinessExpense();
  businessIncomeModel = new BusinessIncome();
  setQuesModel: any = [];
  getAllBusinessExpenses: any;
  getAllBusinessExpensesPG: any;
  phoneInput: boolean = false;
  _phNum: any = null;
  NoOfYears: number;
  edit: boolean = false;
  quesArr: any = [];
  noOfMonth: any = 0;

  constructor(
    public router: Router,
    private _http: HttpClient,
    private _cookieSvc: CookieService,
    public _sharedSvc: SharedService,
    public translate: TranslateService,
  ) { }

  saveBusinessAppraisal() {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL + Endpoint.BizAppraisal.save_biz_aprsl, this.saveBizAprslModel, this._sharedSvc.requestOptions);
  }

  saveBusinessAppraisalAdd() {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL + Endpoint.BizAppraisal.save_biz_aprsl_adtnl_info, this.saveBizAprslModel, this._sharedSvc.requestOptions);
  }

  saveAllBusinessAppraisalAdd() {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL + Endpoint.BizAppraisal.save_all_biz_aprsl_adtnl_info, this.setQuesModel, this._sharedSvc.requestOptions);
  }

  getBusinessAppraisal(clnt_seq: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL + Endpoint.BizAppraisal.get_clnt_biz_aprsl + clnt_seq, this._sharedSvc.requestOptions);
  }

  getBusinessAppraisalAdd(clnt_seq: any, qst_seq: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL + Endpoint.BizAppraisal.get_clnt_biz_aprsl_adtnl_info + clnt_seq + "/" + qst_seq, this._sharedSvc.requestOptions);
  }

  getAllBusinessAppraisalAdd(clnt_seq: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL + Endpoint.BizAppraisal.get_clnt_all_biz_aprsl_adtnl_info + clnt_seq, this._sharedSvc.requestOptions);
  }

  InItGetBusinessAppraisal(clnt_seq: any, callback?: any): void {
    this._sharedSvc.showSpinner()
    this.getBusinessAppraisal(clnt_seq).subscribe({
      next: (response: any) => {
        if (response) {
          this._sharedSvc.hideSpinner();
          if (response.error == this.translate.instant('toast.noRecordFound')) { return }
          this.saveBizAprslModel = response;
          localStorage.setItem('clntBizAprslSeq', JSON.stringify(response.bizAprslSeq));
          if (this.saveBizAprslModel?.bizPhNum.includes("-")) {
            this._phNum = this.saveBizAprslModel?.bizPhNum
          }
          else {
            var p1 = this.saveBizAprslModel?.bizPhNum?.substring(0, 4);
            var p2 = this.saveBizAprslModel?.bizPhNum?.substring(4, 11);
            this._phNum = p1 + "-" + p2;
          }
          this.phoneInput = true;
          this.NoOfYears = this.saveBizAprslModel?.mnthInBiz / 12
          // calculate address month of residence
          // this.addressModel.mnthsOfResidence = this.saveClientModel.mnthsRes
          this.NoOfYears = Math.floor(this.saveBizAprslModel?.mnthInBiz / 12);
          this.noOfMonth = this.saveBizAprslModel?.mnthInBiz % 12;
          if (callback) {
            return callback(this.saveBizAprslModel);
          }
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });

        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }

  InItGetBusinessAppraisalAdd(clnt_seq: any, qst_seq: any): void {
    this._sharedSvc.showSpinner()
    this.getBusinessAppraisalAdd(clnt_seq, qst_seq).subscribe({
      next: (response: any) => {
        if (response) {
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }

  InItGetAllBusinessAppraisalAdd(clnt_seq: any): void {
    // try{
    //   const data = this.getAllBusinessAppraisalAdd(clnt_seq).toPromise();
    //   if(data){
    //     for (let i = 0; i < data.length; i++) {
    //       ;
    //       this.quesArr.push(data[i].answrSeq);
    //     }
    //     this._sharedSvc.hideSpinner();
    //   }
    // }catch(error){}

    this._sharedSvc.showSpinner();
    this.getAllBusinessAppraisalAdd(clnt_seq).subscribe({
      next: (response: any) => {
        if (response) {
          for (let i = 0; i < response.length; i++) {
            ;
            this.quesArr.push(response[i].answrSeq);
            console.log(this.quesArr)
          }
          localStorage.setItem('quesArr', JSON.stringify(this.quesArr));
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }

  pageChange() {
    this._sharedSvc.collectionSize = this.getAllBusinessExpenses?.length;
    this.getAllBusinessExpensesPG = this.getAllBusinessExpenses.slice((this._sharedSvc.page - 1) * this._sharedSvc.pageSize, (this._sharedSvc.page - 1) * this._sharedSvc.pageSize + this._sharedSvc.pageSize);
  }
}
