<section [class.positionFX]="_sharedSvc.loading">
    <div class="row printableDiv">
        <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="mt-3 loan-table">
                <div class="row mb-3">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="d-flex align-items-center justify-content-center">
                            <div></div>
                            <div class="btnCenter otherOutstanding mb-3 flex-grow-1">
                                <h3>{{'collateralInfo.collateralDetail' | translate}}</h3>
                            </div>
                            <div class="" *ngIf="allowedRoutes.length > 0 && allowedRoutes[0].rolId !== 'BS'">
                                <div class="add-button" (click)="printPage()">
                                    <i class="fa fa-print"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <form [formGroup]="CollateralHDRForm" #CollateralHDR_Form="ngForm">
                            <div class="row">
                                <div class="col-sm-4 col-md-4 col-lg-3 mb-3">
                                    <div class="form-floating">
                                        <!-- <select class="form-control input-radius form-select " formControlName="shrffId"
                                            [(ngModel)]="_creditCollateralSvc.saveCollateralHDRModel.shrffId"
                                            (ngModelChange)="setShroffName()"
                                            [class.is-invalid]="shrffId.invalid && (shrffId.dirty || shrffId.touched)"
                                            [class.readonly-field]="_conditionsService.readOnlyView_Credit">
                                            <option *ngFor="let item of _setupService.shoroffList; let i = index ;"
                                                [value]=item?.shrfSeq>
                                                {{item.shrfSeq}}
                                            </option>
                                        </select> -->
                                        <select class="form-control input-radius form-select " formControlName="shrffId"
                                            [class.is-invalid]="shrffId.invalid && (shrffId.dirty || shrffId.touched)"
                                            [class.readonly-field]="_conditionsService.readOnlyView_Credit">
                                            <option *ngFor="let item of _setupService.shoroffList; let i = index ;"
                                                [value]=item?.shrfSeq>
                                                {{item.shrfSeq}}
                                            </option>
                                        </select>
                                        <div *ngIf="shrffId.invalid && (shrffId.dirty || shrffId.touched)"
                                            class="invalid-feedback">
                                            <div *ngIf="shrffId.errors?.['required']">
                                                {{'clientCreditCollateral.shroffIDIsRequired' | translate}}
                                            </div>
                                        </div>
                                        <label>{{'collateralInfo.shroffID' | translate}}</label>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4 col-lg-3 mb-3">
                                    <div class="form-floating">
                                        <input type="text" style="text-transform: capitalize;" autocomplete="off"
                                            [value]="shroffName" class="form-control input-radius readonly-field">
                                        <label>{{'collateralInfo.shroffName' | translate}}</label>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4 col-lg-3 mb-3">
                                    <div class="form-floating">
                                        <!-- <input type="text" style="text-transform: capitalize;" autocomplete="off"
                                            class="form-control input-radius readonly-field"
                                            formControlName="effctvCrdtAmnt" readonly
                                            [(ngModel)]="_creditCollateralSvc.saveCollateralHDRModel.effctvCrdtAmnt"> -->
                                        <input type="text" style="text-transform: capitalize;" autocomplete="off"
                                            class="form-control input-radius readonly-field"
                                            formControlName="effctvCrdtAmnt" readonly>
                                        <label>{{'collateralInfo.effectiveLoanValue' | translate}}</label>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4 col-lg-3 mb-3">
                                    <div class="form-floating">
                                        <!-- <input type="number" autocomplete="off" class="form-control input-radius"
                                            formControlName="crdtUpto"
                                            [max]="_creditCollateralSvc.saveCollateralHDRModel.effctvCrdtAmnt"
                                            [class.is-invalid]="crdtUpto.invalid && (crdtUpto.dirty || crdtUpto.touched)"
                                            [(ngModel)]="_creditCollateralSvc.saveCollateralHDRModel.crdtUpto"
                                            [class.readonly-field]="_conditionsService.readOnlyView_Credit"> -->
                                        <input type="number" autocomplete="off" min="1" max="500000" class="form-control input-radius"
                                            formControlName="crdtUpto"
                                            [max]="_creditCollateralSvc.saveCollateralHDRModel.effctvCrdtAmnt"
                                            [class.is-invalid]="crdtUpto.invalid && (crdtUpto.dirty || crdtUpto.touched)"
                                            [class.readonly-field]="_conditionsService.readOnlyView_Credit">
                                        <div *ngIf="crdtUpto.invalid && (crdtUpto.dirty || crdtUpto.touched)"
                                            class="invalid-feedback">
                                            <div *ngIf="crdtUpto.errors?.['max']">
                                                {{'clientCreditCollateral.notIncreaseEffective' | translate}}.
                                            </div>
                                            <div *ngIf="crdtUpto.errors?.['min']">
                                                {{'clientCreditCollateral.recommendedEffectiveLoan' | translate}}.
                                            </div>
                                        </div>
                                        <label>{{'collateralInfo.recommendedEffectiveLoan' | translate}}</label>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4 col-lg-3 mb-3">
                                    <div class="form-floating">
                                        <!-- <select class="form-control input-radius form-select "
                                            formControlName="shrffBgNum"
                                            [(ngModel)]="_creditCollateralSvc.saveCollateralHDRModel.shrffBgNum"
                                            [class.is-invalid]="shrffBgNum.invalid && (shrffBgNum.dirty || shrffBgNum.touched)"
                                            [class.readonly-field]="_conditionsService.readOnlyView_Credit">
                                            <option
                                                *ngFor="let item of _creditCollateralSvc.ShroffbagsList; let i = index ;"
                                                [value]=item?.bagSeq>
                                                {{item.bagNum}}
                                            </option>
                                        </select> -->
                                        <select class="form-control input-radius form-select "
                                            formControlName="shrffBgNum"
                                            [class.is-invalid]="shrffBgNum.invalid && (shrffBgNum.dirty || shrffBgNum.touched)"
                                            [class.readonly-field]="_conditionsService.readOnlyView_Credit">
                                            <option
                                                *ngFor="let item of _creditCollateralSvc.ShroffbagsList; let i = index ;"
                                                [value]=item?.bagSeq>
                                                {{item.bagNum}}
                                            </option>
                                        </select>
                                        <div *ngIf="shrffBgNum.invalid && (shrffBgNum.dirty || shrffBgNum.touched)"
                                            class="invalid-feedback">
                                            <div *ngIf="shrffBgNum.errors?.['required']">
                                                {{'clientCreditCollateral.shroffBagRequired' | translate}}.
                                            </div>
                                        </div>
                                        <label>{{'collateralInfo.shroffBag' | translate}} #</label>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4 col-lg-3 mb-3">
                                    <div class="form-floating">
                                        <!-- <select class="form-control input-radius form-select "
                                            formControlName="brnchBgNum"
                                            [(ngModel)]="_creditCollateralSvc.saveCollateralHDRModel.brnchBgNum"
                                            [class.is-invalid]="brnchBgNum.invalid && (brnchBgNum.dirty || brnchBgNum.touched)"
                                            [class.readonly-field]="_conditionsService.readOnlyView_Credit">
                                            <option
                                                *ngFor="let item of _creditCollateralSvc.GoldbagsList; let i = index ;"
                                                [value]=item?.bagSeq>
                                                {{item.bagNum}}
                                            </option>
                                        </select> -->
                                        <select class="form-control input-radius form-select "
                                            formControlName="brnchBgNum"
                                            [class.is-invalid]="brnchBgNum.invalid && (brnchBgNum.dirty || brnchBgNum.touched)"
                                            [class.readonly-field]="_conditionsService.readOnlyView_Credit">
                                            <option
                                                *ngFor="let item of _creditCollateralSvc.GoldbagsList; let i = index ;"
                                                [value]=item?.bagSeq>
                                                {{item.bagNum}}
                                            </option>
                                        </select>
                                        <div *ngIf="brnchBgNum.invalid && (brnchBgNum.dirty || brnchBgNum.touched)"
                                            class="invalid-feedback">
                                            <div *ngIf="brnchBgNum.errors?.['required']">
                                                {{'clientCreditCollateral.branchBagRequired' | translate}}.
                                            </div>

                                        </div>
                                        <label>{{'collateralInfo.branchBag' | translate}} #</label>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4 col-lg-3 mb-3 hideinPrint">
                                    <div class="inside-icon pointer"
                                        (click)="_conditionsService.readOnlyView_Credit == false && compressFile('gold')">
                                        <label>{{'common.browse' | translate}}</label>
                                        <input *ngIf="!goldPicUrl" type="search" placeholder="Gold Picture" readonly
                                            class="form-control search-input" style="padding: 20px; font-size: 11px;">
                                        <input *ngIf="goldPicUrl" readonly class="form-control search-input"
                                            style="padding: 20px; font-size: 11px;">
                                        <div class="inside-icon-small">
                                            <img [src]="goldPicUrl ? goldPicUrl : ''" />
                                        </div>
                                    </div>
                                    <div *ngIf="imgPath && goldPicUrl">
                                        <div class="text-center pointer mt-2">
                                            <span (click)="openModal(goldPic, 'Gold Picture')">{{'common.showImage' |
                                                translate}}<i class="fa fa-angle-down"></i></span>
                                            <!-- <span (click)="d('Cross click')" *ngIf="shoImg">Hide Image <i
                                                    class="fa fa-angle-up"></i></span> -->
                                        </div>
                                    </div>

                                </div>
                                <div class="col-sm-4 col-md-4 col-lg-3 mb-3 hideinPrint">
                                    <div class="inside-icon pointer"
                                        (click)="_conditionsService.readOnlyView_Credit == false && compressFile('shroff')">
                                        <label>>{{'common.browse' | translate}}</label>
                                        <input *ngIf="!shroffPicUrl" type="search" placeholder="Shroff Certificate"
                                            readonly class="form-control search-input"
                                            style="padding: 20px; font-size: 11px;">
                                        <input *ngIf="shroffPicUrl" readonly class="form-control search-input"
                                            style="padding: 20px; font-size: 11px;">
                                        <div class="inside-icon-small">
                                            <img [src]="shroffPicUrl ? shroffPicUrl : ''" />
                                        </div>
                                    </div>
                                    <div *ngIf="imgPath && shroffPicUrl">
                                        <div class="text-center pointer mt-2">
                                            <span (click)="openModal(shroffPic, 'Shroff Picture')">{{'common.showImage'
                                                |
                                                translate}} <i class="fa fa-angle-down"></i></span>
                                            <!-- <span (click)="d('Cross click')" *ngIf="shoImg">Hide Image <i
                                                    class="fa fa-angle-up"></i></span> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3 hideinPrint">
                                <div class="col-sm-10 col-md-10 col-lg-11">
                                    <div class="button_div">
                                        <!-- <button class=" btn login-button-class secondaryBtn" type="button"
                                            [disabled]="!_conditionsService.creditCollaterial"
                                            (click)="onSubmitHDR()">SAVE HEADER</button> -->
                                    </div>
                                </div>
                                <div class="col-sm-2 col-md-2 col-lg-1 marginAuto">
                                    <div *ngIf="!_conditionsService.readOnlyView_Credit" class="add-button"
                                        (click)="openModal(creditCollaterialModel, this.translate.instant('clientCreditCollateral.addCollateral')  , 'new')">
                                        <i class="fa fa-plus"></i>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr class="text-center">
                                    <td>#</td>
                                    <td>{{'common.description' | translate}}</td>
                                    <td>{{'collateralInfo.grossWeightIncludingWax' | translate}} (GM)</td>
                                    <td>{{'collateralInfo.grossWeightExcludingWax' | translate}} (GM)</td>
                                    <td>{{'collateralInfo.qualityOfGoldInKarat' | translate}} (B)</td>
                                    <td>{{'collateralInfo.netWeightOfPurityInGrams' | translate}} C=(AxB)/24 (GM)</td>
                                    <td>{{'collateralInfo.marketBuyingRate' | translate}} (D)</td>
                                    <td>{{'collateralInfo.marketValue' | translate}} E = (CxD)</td>
                                    <td class="text-center hideinPrint">{{'common.action' | translate}}</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of _creditCollateralSvc.getAllCollatrialDtl_data; let i = index;">
                                    <ng-container *ngIf="!data.delFlg">
                                        <td>{{i + 1}}</td>
                                        <td>{{data?.dscr | titlecase}}</td>
                                        <td>{{data?.grossWghtIncl | number}}</td>
                                        <td>{{data?.grossWghtExcl | number}}</td>
                                        <td>{{data?.gldQltyKrt | number}}</td>
                                        <td>{{(data?.grossWghtExcl*data?.gldQltyKrt)/24 | number}}</td>
                                        <td>{{getCurrentGoldRate(data?.mrktByngRt) | number}}</td>
                                        <td>{{((data?.grossWghtExcl*data?.gldQltyKrt)/24)*getCurrentGoldRate(data?.mrktByngRt) | number}}
                                        </td>
                                        <td class="text-center hideinPrint">
                                            <div *ngIf="!_conditionsService.readOnlyView_Credit" class="action-inner">
                                                <i class="fa fa-pencil"
                                                    (click)="onEdit(creditCollaterialModel,data,this.translate.instant('clientCreditCollateral.updateCollateral'), i)"></i>
                                                <i class="fa fa-trash" (click)="onDeletePopup(data,i)"></i>
                                            </div>
                                        </td>
                                    </ng-container>
                                </tr>
                                <tr *ngIf="_creditCollateralSvc.getAllCollatrialDtl_data?.length > 0">
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><b>{{_creditCollateralSvc.totalNetWeight | number}}</b></td>
                                    <td></td>
                                    <td><b>{{_creditCollateralSvc.totalMarketVAl | number}}</b></td>
                                    <td class="hideinPrint"></td>
                                <tr>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row mb-3 hideinPrint">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="button_div">
                            <button class="btn login-button-class" type="button"
                                [disabled]="_conditionsService.creditCollaterial"
                                (click)="_sharedSvc.activeCredit = 5">{{'common.continue' | translate}}</button>
                            <button *ngIf="!_conditionsService.readOnlyView_Credit"
                                class=" btn login-button-class secondaryBtn" type="button"
                                [disabled]="!_conditionsService.creditCollaterial"
                                (click)="onSubmitHDR()">{{'common.save' | translate}}</button>
                        </div>
                    </div>
                </div>

                <div class="showDivinPrint">
                    <p>
                        The above-mentioned ornaments / jewelry/ gold as appraised by the Shroff in accordance with his
                        above mentioned "Shroff
                        Certificate No-. will remain in the custody of GoldFin Limited (GoldFin) as the collateral
                        against my loan from the Creditor till the
                        final settlement of the loan facility <br /><br />
                        I do hereby solemnly affirm that I am the absolute owner of ornaments/jewelry/goid mentioned
                        above and no other person has
                        any claim against it / them. I further authorize GoldFin to carry out a reappraisal and
                        revaluation of the collateral pledged by me
                        with the Creditor against the loan facility availed by me.<br /><br />
                        Further, l fully understand and agree that I will safely keep this Acceptance Form with me and
                        will return it back to GoldFin in
                        original condition so as to takeover my collateral after full repayment of my loan to the
                        Creditor. I also understand and agree
                        that the GoldFin will take necessary time to return my collateral in view of the security
                        protocols
                    </p>
                    <div class="showTables row mt-5">
                        <div class="col-6">
                            <table class="table table-bordered">
                                <tbody>
                                    <tr>
                                        <td style="width: 170px;">{{'common.customerName' | translate}}</td>
                                        <td>{{shareUserData[0].clntNm}}</td>
                                    </tr>
                                    <tr class="printtable">
                                        <td style="width: 170px;">CNIC</td>
                                        <td>{{shareUserData[0].cnicNum}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-6">
                            <table class="table table-bordered">
                                <tbody>
                                    <tr>
                                        <td style="width: 140px;">Mobile No</td>
                                        <td>{{shareUserData[0].clntPhonNum}}</td>
                                    </tr>
                                    <tr class="printtable">
                                        <td style="width: 140px;">{{'common.address' | translate}}</td>
                                        <td>{{shareUserData[0].clntAdr}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="thumbPrintsDiv">
                        <div class="row">
                            <div class="col-6">
                                <div class="border border-dark d-flex align-items-end justify-content-center"
                                    style="height: 150px;">
                                    <p class="text-center">Customer's Signature</p>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="border border-dark d-flex align-items-end justify-content-center"
                                    style="height: 150px;">
                                    <p class="text-center">Thumb impression</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <hr />
                    <p>
                        We certify that the above mentioned ornaments / jewelry / gold of above value has been received
                        by GoldFin for safe
                        keeping against the loan from the Creditor. The collateral will be returned to the customer once
                        the loan has been fully settled and confirmed by the Creditor.
                        Consequently, upon receipt of original "Collateral Acceptance" Form from the customer, GoldFin
                        will return the collateral to the customer.
                    </p>
                    <div class="row">
                        <div class="col-4">
                            <p class="border-top border-dark mt-5 text-center">
                                Collateral management Officer
                            </p>
                        </div>
                        <div class="col-4 ms-auto">
                            <p class="border-top border-dark mt-5 text-center">
                                Branch Supervisor
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<ng-template id="display-modal" #creditCollaterialModel let-c="close" let-d="dismiss">
    <div class="modal-body" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
        [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
        <div class="p-3 pb-0">
            <div class="row mb-3">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="text-center">
                        <h4>{{modelHeading}}</h4>
                    </div>
                </div>
            </div>
            <form [formGroup]="CollateralForm" #Collateral_Form="ngForm">
                <div class="row mb-3">
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                        <div class="form-floating">
                            <!-- <input type="test" style="text-transform: capitalize;" class="form-control input-radius "
                                [(ngModel)]="_creditCollateralSvc.collateralModel.dscr" formControlName="dscr"
                                [class.is-invalid]="dscr.invalid && (dscr.dirty || dscr.touched)"> -->
                            <input type="test" style="text-transform: capitalize;" class="form-control input-radius "
                                formControlName="dscr"
                                [class.is-invalid]="dscr.invalid && (dscr.dirty || dscr.touched)">
                            <div *ngIf="dscr.invalid && (dscr.dirty || dscr.touched)" class="invalid-feedback">
                                <div *ngIf="dscr.errors?.['required']">
                                    {{'clientCreditCollateral.DescOfItemsReq' | translate}}
                                </div>
                                <div *ngIf="dscr.errors?.['minlength']">
                                    {{'clientCreditCollateral.mustHave3Char' | translate}}
                                </div>
                                <div *ngIf="dscr.errors?.['maxlength']">
                                    {{'clientCreditCollateral.mustHaveAtMost100Char' | translate}}
                                </div>
                            </div>
                            <label>{{'clientCreditCollateral.DescOfItems' | translate}}</label>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-6 mb-3">
                        <div class="form-floating">
                            <!-- <input type="number" class="form-control input-radius "
                                [(ngModel)]="_creditCollateralSvc.collateralModel.grossWghtIncl"
                                formControlName="grossWghtIncl" min="1" max="500000"
                                [class.is-invalid]="grossWghtIncl.invalid && (grossWghtIncl.dirty || grossWghtIncl.touched)"> -->
                            <input type="number" class="form-control input-radius " formControlName="grossWghtIncl"
                                min="1" max="500000"
                                [class.is-invalid]="grossWghtIncl.invalid && (grossWghtIncl.dirty || grossWghtIncl.touched)">
                            <div *ngIf="grossWghtIncl.invalid && (grossWghtIncl.dirty || grossWghtIncl.touched)"
                                class="invalid-feedback">
                                <div *ngIf="grossWghtIncl.errors?.['required']">
                                    {{'clientCreditCollateral.grossWeightReq' | translate}}
                                </div>
                                <div *ngIf="grossWghtIncl.errors?.['min']">
                                    {{'clientCreditCollateral.least1DigitReq' | translate}}
                                </div>
                                <div *ngIf="grossWghtIncl.errors?.['max']">
                                    {{'clientCreditCollateral.atMost6DigitReq' | translate}}
                                </div>
                            </div>
                            <label> {{'clientCreditCollateral.grossWeightIncludingMax' | translate}} (GM)</label>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-6 mb-3">
                        <div class="form-floating">
                            <!-- <input type="number" class="form-control input-radius "
                                [(ngModel)]="_creditCollateralSvc.collateralModel.grossWghtExcl"
                                formControlName="grossWghtExcl" min="1" max="500000" (ngModelChange)="CalNetWeight()"
                                [class.is-invalid]="grossWghtExcl.invalid && (grossWghtExcl.dirty || grossWghtExcl.touched)"> -->
                            <input type="number" class="form-control input-radius" formControlName="grossWghtExcl"
                                min="1" max="500000" (input)="CalNetWeight()"
                                [class.is-invalid]="grossWghtExcl.invalid && (grossWghtExcl.dirty || grossWghtExcl.touched)">
                            <div *ngIf="grossWghtExcl.invalid && (grossWghtExcl.dirty || grossWghtExcl.touched)"
                                class="invalid-feedback">
                                <div *ngIf="grossWghtExcl.errors?.['required']">
                                    {{'clientCreditCollateral.grossWeightReq' | translate}}
                                </div>
                                <div *ngIf="grossWghtExcl.errors?.['min']">
                                    {{'clientCreditCollateral.least1DigitReq' | translate}}
                                </div>
                                <div *ngIf="grossWghtExcl.errors?.['max']">
                                    {{'clientCreditCollateral.atMost6DigitReq' | translate}}
                                </div>
                            </div>
                            <label>{{'clientCreditCollateral.grossWeightExcludingMax' | translate}} (A) (GM)</label>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-6 mb-3">
                        <div class="form-floating">
                            <!-- <input type="number" class="form-control input-radius "
                                [(ngModel)]="_creditCollateralSvc.collateralModel.gldQltyKrt"
                                formControlName="gldQltyKrt" min="1" max="24" (ngModelChange)="CalNetWeight()"
                                [class.is-invalid]="gldQltyKrt.invalid && (gldQltyKrt.dirty || gldQltyKrt.touched)"> -->
                            <input type="number" class="form-control input-radius " formControlName="gldQltyKrt" min="1"
                                max="24" (input)="CalNetWeight()"
                                [class.is-invalid]="gldQltyKrt.invalid && (gldQltyKrt.dirty || gldQltyKrt.touched)">
                            <div *ngIf="gldQltyKrt.invalid && (gldQltyKrt.dirty || gldQltyKrt.touched)"
                                class="invalid-feedback">
                                <div *ngIf="gldQltyKrt.errors?.['required']">
                                    {{'clientCreditCollateral.qualityOfGoldReq' | translate}}
                                </div>
                                <div *ngIf="gldQltyKrt.errors?.['min']">
                                    {{'clientCreditCollateral.least1DigitReq' | translate}}
                                </div>
                                <div *ngIf="gldQltyKrt.errors?.['max']">
                                    {{'clientCreditCollateral.mustHaveMost24' | translate}}
                                </div>
                            </div>
                            <label>{{'collateralInfo.qualityOfGoldInKarat' | translate}} (B)</label>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-6 mb-3">
                        <div class="form-floating">
                            <!-- <input type="number" class="form-control input-radius readonly-field" readonly
                                name="netWeight" [ngModelOptions]="{ standalone: true }" [(ngModel)]="netWeight"
                                (ngModelChange)="pureGoldFn()"> -->
                            <input type="number" class="form-control input-radius readonly-field" readonly
                                name="netWeight" [value]="netWeight" (input)="pureGoldFn()">
                            <label>{{'collateralInfo.netWeightOfPurityInGrams' | translate}} C=(AxB)24 (GM)</label>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-6 mb-3">
                        <div class="form-floating">
                            <!-- <select class="form-control input-radius form-select " formControlName="mrktByngRt"
                                [(ngModel)]="_creditCollateralSvc.collateralModel.mrktByngRt"
                                (ngModelChange)="pureGoldFn()"
                                [class.is-invalid]="mrktByngRt.invalid && (mrktByngRt.dirty || mrktByngRt.touched)">
                                <option *ngFor="let item of _picklistService.GOLD_RETE_Data; let i = index ;"
                                    [value]=item?.refCdDscr>
                                    {{item.refCdDscr}}
                                </option>
                            </select> -->
                            <!-- <select class="form-control input-radius form-select " formControlName="mrktByngRt"
                                [(ngModel)]="_creditCollateralSvc.collateralModel.mrktByngRt"
                                (ngModelChange)="pureGoldFn()"
                                [class.is-invalid]="mrktByngRt.invalid && (mrktByngRt.dirty || mrktByngRt.touched)">
                                <option *ngFor="let item of _creditCollateralSvc.getLatestGoldRate; let i = index ;"
                                    [value]=item?.goldRateSeq>
                                    {{item.mrktRate}}
                                </option>
                            </select> -->
                            <select class="form-control input-radius form-select " formControlName="mrktByngRt"
                                [class.is-invalid]="mrktByngRt.invalid && (mrktByngRt.dirty || mrktByngRt.touched)"
                                (change)="pureGoldFn($event)">
                                <option *ngFor="let item of _creditCollateralSvc.getLatestGoldRate; let i = index ;"
                                    [value]=item?.goldRateSeq>
                                    {{item.mrktRate}}
                                </option>
                            </select>
                            <div *ngIf="mrktByngRt.invalid && (mrktByngRt.dirty || mrktByngRt.touched)"
                                class="invalid-feedback">
                                <div *ngIf="mrktByngRt.errors?.['required']">
                                    {{'clientCreditCollateral.fieldReq' | translate}}
                                </div>
                            </div>
                            <!-- <input type="number" class="form-control input-radius readonly-field"
                                [(ngModel)]="_creditCollateralSvc.collateralModel.mrktByngRt"
                                formControlName="mrktByngRt" readonly> -->
                            <label>{{'clientCreditCollateral.marketBuyingRate' | translate}} (D)</label>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-6 mb-3">
                        <div class="form-floating">
                            <input type="number" class="form-control input-radius readonly-field" [value]="pureGold"
                                readonly>
                            <label>{{'clientCreditCollateral.marketValueOfPureGold' | translate}} E (CxD)</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="button_div">
                            <button class="btn login-button-class" [class.pointerNone]="_sharedSvc.loading"
                                [disabled]="_sharedSvc.loading" *ngIf="!_creditCollateralSvc.edit"
                                (click)="onSubmit()">{{'common.add' | translate}}</button>
                            <button class="btn login-button-class" [class.pointerNone]="_sharedSvc.loading"
                                [disabled]="_sharedSvc.loading" *ngIf="_creditCollateralSvc.edit"
                                (click)="onUpdate()">{{'common.update' | translate}}</button>
                            <button class="btn login-button-class cancelBtn" (click)="d('Cross click')"
                                type="reset">{{'common.cancel' | translate}}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>

<ng-template id="display-modal" #goldPic let-c="close" let-d="dismiss">
    <div class="p-3 pb-0">
        <div class="modal-header">
            <h4>{{modelHeading}}</h4>
            <div class="header_cross" (click)="d('Cross click')">
                <h2>&times;</h2>
            </div>
        </div>
        <div class="modal-body">

            <div class="row mb-3">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="text-center credit_pic_siz">
                        <img [src]="goldPicUrl ? goldPicUrl : ''" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template id="display-modal" #shroffPic let-c="close" let-d="dismiss">
    <div class="p-3 pb-0">
        <div class="modal-header">
            <h4>{{modelHeading}}</h4>
            <div class="header_cross" (click)="d('Cross click')">
                <h2>&times;</h2>
            </div>
        </div>
        <div class="modal-body">
            <div class="row mb-3">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="text-center">
                        <h4>{{modelHeading}}</h4>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="text-center credit_pic_siz">
                        <img [src]="shroffPicUrl ? shroffPicUrl : ''" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>