<section>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12">
      <div class="mt-3 loan-table">
        <div class="row mb-3">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="btnCenter otherOutstanding mb-3">
              <h3>
                {{
                  "clientOnboarding.employerInfoTab.employerInformation"
                    | translate
                }}
              </h3>
            </div>
            <div class="btnCenter otherOutstanding">
              <h6>
                {{ "common.clintName" | translate }}:
                <b>{{ saveCnicData?.frstNm | titlecase }}</b> /
                {{ "common.clintId" | translate }}:
                <b>{{ saveCnicData?.clntId }}</b>
              </h6>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="btnCenter" (click)="_conditionsService.toggle()">
              <button
                class="btn infoBtn"
                [disabled]="
                  _conditionsService.employerInfoData ||
                  _conditionsService.salaryDetailData
                "
                (click)="showBtn(true, false)"
                [class.infoBtn_bg]="employerInfo"
              >
                {{ "clientOnboarding.employerInfo" | translate }}
              </button>
              <button
                class="btn infoBtn"
                [disabled]="
                  _conditionsService.employerInfoData ||
                  _conditionsService.salaryDetailData
                "
                (click)="showBtn(false, true)"
                [class.infoBtn_bg]="salaryDetail"
              >
                {{
                  "clientOnboarding.employerInfoTab.salaryDetail" | translate
                }}
              </button>
            </div>
          </div>
        </div>
        <ng-container *ngIf="employerInfo">
          <div class="row mb-3">
            <div class="col-sm-6 col-md-6 col-lg-6">
              <!-- <button class="btn infoBtn financialBtn1 mt-0">Total Income:
                                <span>---</span></button> -->
            </div>
            <div
              class="col-sm-6 col-md-6 col-lg-6"
              *ngIf="!_conditionsService.readOnlyView_Client"
            >
              <div
                class="add-button"
                (click)="
                  openModal(
                    empDetail,
                    this.translate.instant('clientOnboarding.employerInfo')
                  )
                "
              >
                <i class="fa fa-plus"></i>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <td>{{ "common.employerName" | translate }}</td>
                      <td>{{ "common.designation" | translate }}</td>
                      <td>
                        {{
                          "clientOnboarding.employerInfoTab.companyPh"
                            | translate
                        }}
                      </td>
                      <td>
                        {{
                          "clientOnboarding.employerInfoTab.companyWebsite"
                            | translate
                        }}
                      </td>
                      <td>
                        {{
                          "clientOnboarding.employerInfoTab.jobStartDate"
                            | translate
                        }}
                      </td>
                      <!-- <td>Job&nbsp;End&nbsp;Date</td> -->
                      <td>
                        {{
                          "clientOnboarding.employerInfoTab.workStatus"
                            | translate
                        }}
                      </td>
                      <td>
                        {{ "clientOnboarding.employerInfoTab.co" | translate }}
                        {{ "common.address" | translate }}
                      </td>
                      <td
                        class="text-center"
                        *ngIf="!_conditionsService.readOnlyView_Client"
                      >
                        {{ "common.action" | translate }}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let data of _employerInfoSvc.getAllEmployerPG;
                        let i = index
                      "
                    >
                      <ng-container *ngIf="!data?.delFlg">
                        <td>{{ data?.emplNm | titlecase }}</td>
                        <td>{{ data?.dsgntn }}</td>
                        <td>{{ data?.phNum }}</td>
                        <td>{{ data?.emplWbstUrl }}</td>
                        <td>{{ data?.strtDt | date : "dd-MM-yyyy" }}</td>
                        <!-- <td>{{ data?.endDt | date : "dd-MM-yyyy" }}</td> -->
                        <ng-container
                          *ngFor="let item of _picklistService.WORK_STATUS_Data"
                        >
                          <td *ngIf="item?.refCdSeq == data?.emplStsKey">
                            {{ item?.refCdDscr }}
                          </td>
                        </ng-container>
                        <td>{{ data?.cmpnyAddr }}</td>
                        <td
                          class="text-center"
                          *ngIf="!_conditionsService.readOnlyView_Client"
                        >
                          <div class="action-inner">
                            <i
                              class="fa fa-pencil"
                              (click)="
                                editEmpDet(
                                  empDetail,
                                  data,
                                  'Update Employer Info',
                                  i
                                )
                              "
                            ></i>
                            <!-- <i class="fa fa-eye"></i> -->
                            <i
                              class="fa fa-trash"
                              (click)="DeletePopup_EmpDtl(data, i)"
                            ></i>
                          </div>
                        </td>
                      </ng-container>
                    </tr>
                  </tbody>
                </table>
                <!-- <div class="row g-0" *ngIf="_sharedSvc.pageSize > 0">
                  <div class="col-sm-12 col-md-12 col-lg-6">
                    <span class="clr-9d9a9a">Showing {{ _employerInfoSvc.getAllEmployerPG?.length }} of {{
                      _sharedSvc.collectionSize }} entries</span>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-6">
                    <div class="float-right">
                      <ngb-pagination [(page)]="_sharedSvc.page" [pageSize]="_sharedSvc.pageSize" [maxSize]="10"
                        ngDefaultControl [collectionSize]="_sharedSvc.collectionSize" [rotate]="true"
                        (pageChange)="_employerInfoSvc.pageChange()">
                        <ng-template ngbPaginationPrevious>Prev</ng-template>
                        <ng-template ngbPaginationNext>Next</ng-template>
                      </ngb-pagination>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="button_div">
                <button
                  class="btn login-button-class"
                  type="button"
                  [disabled]="_conditionsService.employerInfoData"
                  (click)="showBtn(false, true)"
                  (click)="employerInfoContinue()"
                >
                  {{ "common.continue" | translate }}
                </button>
                <button
                  class="btn login-button-class secondaryBtn"
                  type="button"
                  *ngIf="!_conditionsService.readOnlyView_Client"
                  [disabled]="_conditionsService.employerInfoData"
                  (click)="showBtn(false, true)"
                >
                  {{ "common.save" | translate }}
                </button>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="salaryDetail">
          <div class="row mb-3">
            <div class="col-sm-6 col-md-6 col-lg-6">
              <button class="btn infoBtn financialBtn1 mt-0">
                {{
                  "clientOnboarding.employerInfoTab.totalSalaryIncome"
                    | translate
                }}
                :
                <span>{{
                  _finiancialInfoSvc?.totalSalaryAmt | number : "1.0-0"
                }}</span>
              </button>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6">
              <div
                class="add-button"
                (click)="
                  openModal(
                    salaryIncome,
                    this.translate.instant(
                      'clientOnboarding.employerInfoTab.salaryDetail'
                    )
                  )
                "
                *ngIf="!_conditionsService.readOnlyView_Client"
              >
                <i class="fa fa-plus"></i>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <td>{{ "common.salaryIncomeType" | translate }}</td>
                      <td>{{ "common.frequency" | translate }}</td>
                      <td>{{ "common.qty" | translate }}</td>
                      <td>{{ "common.incomeAmount" | translate }}</td>
                      <td>{{ "common.averageMonthlyAmount" | translate }}</td>
                      <td
                        class="text-center"
                        *ngIf="!_conditionsService.readOnlyView_Client"
                      >
                        {{ "common.action" | translate }}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let data of _finiancialInfoSvc.getAllIncome;
                        let i = index
                      "
                    >
                      <ng-container *ngIf="!data.delFlg">
                        <ng-container
                          *ngFor="let item of _picklistService.SALARY_TYPE_Data"
                        >
                          <td *ngIf="item?.refCdSeq == data?.incmTypKey">
                            {{ item?.refCdDscr }}
                          </td>
                        </ng-container>
                        <ng-container
                          *ngFor="
                            let item of _picklistService.BUSINESS_FREQUENCY_Data
                          "
                        >
                          <td *ngIf="item?.refCdSeq == data?.frqncy">
                            {{ item?.refCdDscr }}
                          </td>
                        </ng-container>
                        <td>{{ data?.qnty | number : "1.0-0" }}</td>
                        <td>{{ data?.incmAmt | number : "1.0-0" }}</td>
                        <td>{{ data?.avgMnthlyIncm | number : "1.0-0" }}</td>
                        <!-- <ng-container *ngFor="let item of _picklistService.BUSINESS_FREQUENCY_Data; let i = index">
                        <td *ngIf="item?.refCdSeq == data?.frqncy">
                          <span *ngIf="item?.refCdSeq == 2000">{{ (data?.incmAmt * data?.qnty) / 1 | number : "1.0-0" }}</span>
                          <span *ngIf="item?.refCdSeq == 2001">{{ (data?.incmAmt * data?.qnty) / 3 | number : "1.0-0" }}</span>
                          <span *ngIf="item?.refCdSeq == 2002">{{ (data?.incmAmt * data?.qnty) / 6 | number : "1.0-0" }}</span>
                          <span *ngIf="item?.refCdSeq == 2003">{{ (data?.incmAmt * data?.qnty) / 12 | number : "1.0-0" }}</span>
                        </td>
                      </ng-container> -->
                        <td
                          class="text-center"
                          *ngIf="!_conditionsService.readOnlyView_Client"
                        >
                          <div class="action-inner">
                            <i
                              class="fa fa-pencil"
                              (click)="
                                editSalaryDetail(
                                  salaryIncome,
                                  data,
                                  'Update Salary Detail',
                                  i
                                )
                              "
                            ></i>
                            <i
                              class="fa fa-trash"
                              (click)="DeletePopup_SD(data, i)"
                            ></i>
                          </div>
                        </td>
                      </ng-container>
                    </tr>
                  </tbody>
                </table>
                <div class="row g-0" *ngIf="_sharedSvc.pageSize > 0">
                  <div class="col-sm-12 col-md-12 col-lg-6">
                    <span class="clr-9d9a9a"
                      >{{ "common.showing" | translate }}
                      {{ _finiancialInfoSvc.getAllIncome?.length }}
                      {{ "common.of" | translate }}
                      {{ _finiancialInfoSvc.getAllIncome?.length }}
                      {{ "common.entries" | translate }}</span
                    >
                  </div>
                  <!-- <div class="col-sm-12 col-md-12 col-lg-6">
                    <div class="float-right">
                      <ngb-pagination [(page)]="_sharedSvc.page" [pageSize]="_sharedSvc.pageSize" [maxSize]="10"
                        ngDefaultControl [collectionSize]="_sharedSvc.collectionSize" [rotate]="true"
                        (pageChange)="_finiancialInfoSvc.pageChange(4)">
                        <ng-template ngbPaginationPrevious>Prev</ng-template>
                        <ng-template ngbPaginationNext>Next</ng-template>
                      </ngb-pagination>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="button_div">
                <button
                  class="btn login-button-class"
                  type="button"
                  [disabled]="_conditionsService.salaryDetailData"
                  (click)="_sharedSvc.active = 7"
                  (click)="salaryDetailContinue()"
                >
                  {{ "common.continue" | translate }}
                </button>
                <button
                  class="btn login-button-class secondaryBtn"
                  type="button"
                  [disabled]="!_conditionsService.salaryDetailData"
                  (click)="saveClntIncmDtl()"
                  *ngIf="!_conditionsService.readOnlyView_Client"
                >
                  {{ "common.save" | translate }}
                </button>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</section>
<ng-template id="display-modal" #empDetail let-c="close" let-d="dismiss">
  <div
    class="modal-body"
    [dir]="
      translate?.currentLang === 'ar' || translate?.currentLang === 'ur'
        ? 'rtl'
        : ''
    "
    [class.fix-direction]="
      translate?.currentLang === 'ar' || translate?.currentLang === 'ur'
    "
  >
    <div class="p-3 pb-0">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center">
            <h4>{{ modelHeading }}</h4>
          </div>
        </div>
      </div>
      <form [formGroup]="employerForm">
        <div class="row">
          <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
            <div class="form-floating">
              <input
                type="text"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius"
                formControlName="emplNm"
                minlength="3"
                maxLength="100"
                [class.is-invalid]="
                  emplNm.invalid && (emplNm.dirty || emplNm.touched)
                "
                required
              />
              <div
                *ngIf="emplNm.invalid && (emplNm.dirty || emplNm.touched)"
                class="invalid-feedback"
              >
                <div *ngIf="emplNm.errors?.['required']">
                  {{ "common.employerNameRequire" | translate }}
                </div>
                <div *ngIf="emplNm.errors?.['minlength']">
                  {{ "common.fieldMustHaveLeastThreeLetters" | translate }}
                </div>
                <div *ngIf="emplNm.errors?.['maxlength']">
                  {{ "common.fieldMustHaveAtMostHundredLetters" | translate }}
                </div>
              </div>
              <label> {{ "common.employerName" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
            <div class="form-floating">
              <input
                type="text"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius"
                formControlName="dsgntn"
                minlength="3"
                maxLength="100"
                [class.is-invalid]="
                  dsgntn.invalid && (dsgntn.dirty || dsgntn.touched)
                "
                required
              />
              <div
                *ngIf="dsgntn.invalid && (dsgntn.dirty || dsgntn.touched)"
                class="invalid-feedback"
              >
                <div *ngIf="dsgntn.errors?.['required']">
                  {{ "common.designationRequire" | translate }}
                </div>
                <div *ngIf="dsgntn.errors?.['minlength']">
                  {{ "common.fieldMustHaveLeastThreeLetters" | translate }}
                </div>
                <div *ngIf="dsgntn.errors?.['maxlength']">
                  {{ "common.fieldMustHaveAtMostHundredLetters" | translate }}
                </div>
              </div>
              <label>{{ "common.designation" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
            <div class="form-floating">
              <input
                type="text"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius"
                formControlName="phNum"
                minlength="3"
                maxLength="100"
                (keypress)="numberOnly($event)"
                phoneMask="9999-9999999"
                [class.is-invalid]="
                  phNum.invalid && (phNum.dirty || phNum.touched)
                "
                required
              />
              <div
                *ngIf="phNum.invalid && (phNum.dirty || phNum.touched)"
                class="invalid-feedback"
              >
                <div *ngIf="phNum.errors?.['required']">
                  {{
                    "clientOnboarding.employerInfoTab.companyPhRequire"
                      | translate
                  }}
                </div>
              </div>
              <label>{{
                "clientOnboarding.employerInfoTab.companyPh" | translate
              }}</label>
            </div>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
            <div class="form-floating">
              <input
                type="text"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius"
                formControlName="emplWbstUrl"
                minlength="3"
                maxLength="100"
                [class.is-invalid]="
                  emplWbstUrl.invalid &&
                  (emplWbstUrl.dirty || emplWbstUrl.touched)
                "
                required
              />
              <div
                *ngIf="
                  emplWbstUrl.invalid &&
                  (emplWbstUrl.dirty || emplWbstUrl.touched)
                "
                class="invalid-feedback"
              >
                <div *ngIf="emplWbstUrl.errors?.['required']">
                  {{
                    "clientOnboarding.employerInfoTab.companyWebsiteRequire"
                      | translate
                  }}
                </div>
                <div *ngIf="emplWbstUrl.errors?.['minlength']">
                  {{ "common.fieldMustHaveLeastThreeLetters" | translate }}
                </div>
                <div *ngIf="emplWbstUrl.errors?.['maxlength']">
                  {{ "common.fieldMustHaveAtMostHundredLetters" | translate }}
                </div>
              </div>
              <label>{{
                "clientOnboarding.employerInfoTab.companyWebsite" | translate
              }}</label>
            </div>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
            <div class="form-floating">
              <select
                class="form-control input-radius form-select"
                formControlName="emplStsKey"
                (change)="checkStatus($event)"
                [class.is-invalid]="
                  emplStsKey.invalid && (emplStsKey.dirty || emplStsKey.touched)
                "
              >
                <option
                  *ngFor="
                    let item of _picklistService.WORK_STATUS_Data;
                    let i = index
                  "
                  [value]="item?.refCdSeq"
                >
                  {{ item.refCdDscr }}
                </option>
              </select>
              <div
                *ngIf="
                  emplStsKey.invalid && (emplStsKey.dirty || emplStsKey.touched)
                "
                class="invalid-feedback"
              >
                <div *ngIf="emplStsKey.errors?.['required']">
                  {{
                    "clientOnboarding.employerInfoTab.workStatusRequire"
                      | translate
                  }}
                </div>
              </div>
              <label>
                {{
                  "clientOnboarding.employerInfoTab.workStatus" | translate
                }}</label
              >
            </div>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
            <div class="form-floating">
              <input
                type="date"
                class="form-control input-radius"
                formControlName="strtDt"
                minlength="3"
                maxLength="100"
                [class.is-invalid]="
                  strtDt.invalid && (strtDt.dirty || strtDt.touched)
                "
                required
              />
              <div
                *ngIf="strtDt.invalid && (strtDt.dirty || strtDt.touched)"
                class="invalid-feedback"
              >
                <div *ngIf="strtDt.errors?.['required']">
                  Job&nbsp;Start&nbsp;Date is required.
                </div>
              </div>
              <label>
                {{
                  "clientOnboarding.employerInfoTab.jobStartDate" | translate
                }}</label
              >
            </div>
          </div>
          <!-- <div class="col-sm-6 col-md-6 col-lg-3 mb-3" *ngIf="!endDate">
            <div class="form-floating">
              <input type="date" class="form-control input-radius" formControlName="endDt"
                [class.is-invalid]="endDt.invalid && (endDt.dirty || endDt.touched)" />
              <div *ngIf="endDt.invalid && (endDt.dirty || endDt.touched)" class="invalid-feedback">
                <div *ngIf="endDt.errors?.['required']">Job&nbsp;End&nbsp;Date is required.</div>
              </div>
              <label>Job&nbsp;End&nbsp;Date </label>
            </div>
          </div> -->
          <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
            <div class="form-floating">
              <input
                type="text"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius"
                formControlName="cmpnyAddr"
                minlength="3"
                maxLength="100"
                [class.is-invalid]="
                  cmpnyAddr.invalid && (cmpnyAddr.dirty || cmpnyAddr.touched)
                "
                required
              />
              <div
                *ngIf="
                  cmpnyAddr.invalid && (cmpnyAddr.dirty || cmpnyAddr.touched)
                "
                class="invalid-feedback"
              >
                <div *ngIf="cmpnyAddr.errors?.['required']">
                  {{
                    "clientOnboarding.employerInfoTab.employerAddressRequire"
                      | translate
                  }}
                </div>
                <div *ngIf="cmpnyAddr.errors?.['minlength']">
                  {{ "common.fieldMustHaveLeastThreeLetters" | translate }}
                </div>
                <div *ngIf="cmpnyAddr.errors?.['maxlength']">
                  {{ "common.fieldMustHaveAtMostHundredLetters" | translate }}
                </div>
              </div>
              <label>{{
                "clientOnboarding.employerInfoTab.employerAddress" | translate
              }}</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="button_div">
              <button
                class="btn login-button-class"
                [class.pointerNone]="_sharedSvc.loading"
                [disabled]="_sharedSvc.loading"
                *ngIf="!_employerInfoSvc.edit"
                type="button"
                (click)="onSubmitEmp()"
              >
                {{ "common.save" | translate }}
              </button>
              <button
                class="btn login-button-class"
                [class.pointerNone]="_sharedSvc.loading"
                type="button"
                [disabled]="_sharedSvc.loading"
                *ngIf="_employerInfoSvc.edit"
                (click)="onUpdateEmp()"
              >
                {{ "common.update" | translate }}
              </button>
              <button
                class="btn login-button-class cancelBtn"
                (click)="d('Cross click')"
                type="reset"
              >
                {{ "common.cancel" | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<ng-template id="display-modal" #salaryIncome let-c="close" let-d="dismiss">
  <div
    class="modal-body"
    [dir]="
      translate?.currentLang === 'ar' || translate?.currentLang === 'ur'
        ? 'rtl'
        : ''
    "
    [class.fix-direction]="
      translate?.currentLang === 'ar' || translate?.currentLang === 'ur'
    "
  >
    <div class="p-3 pb-0">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center">
            <h4>{{ modelHeading }}</h4>
          </div>
        </div>
      </div>
      <form [formGroup]="salaryDetailForm">
        <div class="row mb-3">
          <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div class="form-floating">
              <select
                class="form-control input-radius form-select"
                formControlName="incmTypKey"
                [class.is-invalid]="
                  incmTypKey.invalid && (incmTypKey.dirty || incmTypKey.touched)
                "
              >
                <option
                  *ngFor="
                    let item of _picklistService.SALARY_TYPE_Data;
                    let i = index
                  "
                  [value]="item?.refCdSeq"
                >
                  {{ item.refCdDscr }}
                </option>
              </select>
              <div
                *ngIf="
                  incmTypKey.invalid && (incmTypKey.dirty || incmTypKey.touched)
                "
                class="invalid-feedback"
              >
                <div *ngIf="incmTypKey.errors?.['required']">
                  {{ "common.incomeTypeRequire" | translate }}
                </div>
              </div>
              <label>{{ "common.incomeType" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div class="form-floating">
              <select
                class="form-control input-radius form-select"
                formControlName="frqncy"
                (change)="calAvgAmount($event, 'frqncy')"
                [class.is-invalid]="
                  frqncy.invalid && (frqncy.dirty || frqncy.touched)
                "
              >
                <option
                  *ngFor="
                    let item of _picklistService.BUSINESS_FREQUENCY_Data;
                    let i = index
                  "
                  [value]="item?.refCdSeq"
                >
                  {{ item.refCdDscr }}
                </option>
              </select>
              <div
                *ngIf="frqncy.invalid && (frqncy.dirty || frqncy.touched)"
                class="invalid-feedback"
              >
                <div *ngIf="frqncy.errors?.['required']">
                  {{ "common.frequencyRequire" | translate }}.
                </div>
              </div>
              <label>{{ "common.frequency" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div class="form-floating">
              <input
                class="form-control input-radius"
                formControlName="qnty"
                min="1"
                max="100"
                mask="99"
                (input)="calAvgAmount($event, 'qnty')"
                [class.is-invalid]="
                  qnty.invalid && (qnty.dirty || qnty.touched)
                "
              />
              <div
                *ngIf="qnty.invalid && (qnty.dirty || qnty.touched)"
                class="invalid-feedback"
              >
                <div *ngIf="qnty.errors?.['required']">
                  {{ "common.quantityRequire" | translate }}
                </div>
                <div *ngIf="qnty.errors?.['min']">
                  {{ "common.field1DigitRequire" | translate }}
                </div>
                <div *ngIf="qnty.errors?.['max']">
                  {{ "common.field100DigitRequire" | translate }}
                </div>
              </div>
              <label>{{ "common.quantity" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div class="form-floating">
              <input
                type="number"
                class="form-control input-radius"
                formControlName="incmAmt"
                min="1"
                maxLength="100000000"
                (input)="calAvgAmount($event, 'incmAmt')"
                [class.is-invalid]="
                  incmAmt.invalid && (incmAmt.dirty || incmAmt.touched)
                "
              />
              <div
                *ngIf="incmAmt.invalid && (incmAmt.dirty || incmAmt.touched)"
                class="invalid-feedback"
              >
                <div *ngIf="incmAmt.errors?.['required']">
                  {{ "common.incomeAmountRequire" | translate }}
                </div>
                <div *ngIf="incmAmt.errors?.['min']">
                  {{ "common.field1DigitRequire" | translate }}
                </div>
                <div *ngIf="incmAmt.errors?.['max']">
                  {{ "common.fieldMustHaveAtMostMillionDigits" | translate }}
                </div>
              </div>
              <label>{{ "common.incomeAmount" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
            <div class="form-floating">
              <input
                type="text"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius readonly-field"
                formControlName="avgMnthlyIncm"
                readonly
              />
              <label>{{ "common.averageMonthlyAmount" | translate }}</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="button_div">
              <button
                class="btn login-button-class"
                type="button"
                [class.pointerNone]="_sharedSvc.loading"
                [disabled]="_sharedSvc.loading"
                *ngIf="!_employerInfoSvc.edit"
                (click)="onSubmitSalaryDetail()"
              >
                {{ "common.save" | translate }}
              </button>
              <button
                class="btn login-button-class"
                [class.pointerNone]="_sharedSvc.loading"
                [disabled]="_sharedSvc.loading"
                *ngIf="_employerInfoSvc.edit"
                (click)="onUpdateSalaryDetail()"
                type="button"
              >
                {{ "common.update" | translate }}
              </button>
              <button
                class="btn login-button-class cancelBtn"
                (click)="d('Cross click')"
                type="reset"
              >
                {{ "common.cancel" | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
