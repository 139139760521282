import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from '../../shared/service/shared.service';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { SummaryService } from './shared/service/summary.service';
import { RoutingConditionsService } from '../shared/service/routing-conditions.service';
import { CreditCollateralService } from '../../Individual-Group-Loan/add-individual-credit-app/client-credit-collateral-detail/shared/service/credit-collateral.service';
import { RouteInfo } from 'src/app/auth/model/route-info';
import { EmployerInfoService } from '../employer-info/shared/service/employer-info.service';
import { FiniancialInfoService } from '../financial-info/shared/service/finiancial-info.service';
import { ClientDocsService } from '../client-docs/shared/service/client-docs.service';
import { ClintOnboardingService } from '../shared/service/clint-onboarding.service';
import { BusinessAppraisalService } from '../business-appraisal/shared/service/business-appraisal.service';
import { PicklistService } from '../../shared/pickList/picklist.service';
import { TranslateService } from '@ngx-translate/core';
interface ISubmitSummary {
  cmnts: string;
}
@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css']
})
export class SummaryComponent implements OnInit {
  @Input() isICredit: boolean;
  allowedRoutes: RouteInfo[];

  saveCnicData: any;
  EmployerInfo_FS: any;
  crdtApp: any;
  clientSummaryForm: FormGroup;
  submitSum: ISubmitSummary;
  submitCreditForm: FormGroup;
  submitCredit: ISubmitSummary;
  verifyBtnVal: any = "Verify";
  ifVerified: boolean = false;
  clientSummary: boolean = false;
  creditSummaryForm: FormGroup;
  user: any;
  userId: any;

  // personalBasicInfo: any = null;
  // personalAddress: any = null;
  // bizAprsBasicInfo: any = null;
  // clntBizAprslSeq: any;
  // bizAprsAddress: any = null;
  // bizAprsAdditionalInfo: any;
  // bizAprsIncome: any;
  // bizAprsExpense: any;
  // hozHoldExpense: any;
  // empDetail: any;
  // empIncome: any;




  constructor(
    public router: Router,
    public _sharedSvc: SharedService,
    public _summarySvc: SummaryService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    public _conditionsService: RoutingConditionsService,
    public _creditCollateralSvc: CreditCollateralService,
    public _employerInfoSvc: EmployerInfoService,
    public _finiancialInfoSvc: FiniancialInfoService,
    public _clientDocsSvc: ClientDocsService,
    public _clintOnboardingSvc: ClintOnboardingService,
    public route: ActivatedRoute,
    public _businessAppraisalSvc: BusinessAppraisalService,
    public _picklistService: PicklistService,
    public translate: TranslateService

  ) {
    this.submitSum = {} as ISubmitSummary;
    this.allowedRoutes = JSON.parse(sessionStorage.getItem("routeInfo"));

    let jwt = sessionStorage.getItem("JWT");
    this.user = JSON.parse(jwt);
    this.userId = this.user.preferred_username;
  }
  ngOnInit(): void {
    this.clientSummaryForm = new FormGroup({
      cmntsClient: new FormControl(null, [])
    })
    this.creditSummaryForm = new FormGroup({
      cmntsCredit: new FormControl(null, [])
    })
    let id = this.route.snapshot.paramMap.get("id");
    if(id){
      this._summarySvc.InItGetClientSummary(id,(res)=>{
        this.cmntsClient.setValue(this._summarySvc.getSummary?.cmnt);
      });
    }else{
      this.saveCnicData = localStorage.getItem('SaveCnicData');
      if(this.saveCnicData){
        this.saveCnicData = JSON.parse(this.saveCnicData);
        this._summarySvc.InItGetClientSummary(this.saveCnicData?.clntSeq,(res)=>{
          this.cmntsClient.setValue(this._summarySvc.getSummary?.cmnt);
        });
      }
    }

    if (localStorage.getItem("readOnlyView_Client")) {
      
      this._conditionsService.readOnlyView_Client = true;
      let id = this.route.snapshot.paramMap.get("id");
      this._summarySvc.InItGetClientSummary(id,(res)=>{
        this.cmntsClient.setValue(this._summarySvc.getSummary?.cmnt);
      });
      // this._summarySvc.InItGetClientSummary(id);
    }
    else {
      this._conditionsService.readOnlyView_Client = false;
    }
    if (localStorage.getItem('save_ClientModel')) {
      this.saveCnicData = localStorage.getItem('save_ClientModel');
    }
    else {
      if (localStorage.getItem('SaveCnicData')) {
        this.saveCnicData = localStorage.getItem('SaveCnicData');
      }
    }
    this.saveCnicData = JSON.parse(this.saveCnicData);

    if (window.location.href.includes("individual-group-info")) {
      if (localStorage.getItem("crdtApp")) {
        this.crdtApp = localStorage.getItem("crdtApp");
        this.crdtApp = JSON.parse(this.crdtApp);
        this._summarySvc.InItGetCASummary(this.crdtApp,(res)=>{     
          this.cmntsCredit.setValue(this._summarySvc.getSummary?.caCmnt);      
        });
      }
      this.clientSummary = false;
    }
    else {
      // this._summarySvc.InItGetClientSummary(this.saveCnicData?.clntSeq);   
      this._summarySvc.InItGetClientSummary(this.saveCnicData?.clntSeq,(res)=>{
        this.cmntsClient.setValue(this._summarySvc.getSummary?.cmnt);
      });  
      this.clientSummary = true;
    }
    this.conditionsCheck();
    this.conditionsCheck2();
    
  }
  get cmntsClient() { return this.clientSummaryForm.get("cmntsClient") }
  get cmntsCredit() { return this.creditSummaryForm.get("cmntsCredit") }


  conditionsCheck() {

    if ((this.saveCnicData?.occKey == 39 || this.saveCnicData?.occKey == 42) &&
      this.saveCnicData?.customerTypeKey == 120) {
      this._sharedSvc.businessAppraisal_FS = true;
      this._sharedSvc.EmployerInfo_FS = false;
    }
    else {
      this._sharedSvc.businessAppraisal_FS = false;
      this._sharedSvc.EmployerInfo_FS = true;
    }
  }
  conditionsCheck2() {
    // this.EmployerInfo_FS = sessionStorage.getItem('EmployerInfo_FS');
    // if (this.EmployerInfo_FS == 'true') {
    //   this._sharedSvc.EmployerInfo_FS = true;
    // }
    // else {
    //   this._sharedSvc.EmployerInfo_FS = false;
    // }
    if (window.location.href.includes("individual-group-info")) {
      this._sharedSvc.creditInfo_FS = true;
    }
    else {
      this._sharedSvc.creditInfo_FS = false;
    }
  }
  verifyBtn() {
    this.verifyBtnVal = "Verified";
    this.ifVerified = true;
  }
  onSubmit(): void {
    

    const EditCheck = localStorage.getItem('clientOnbrdEditCheck');
    const occupationType = sessionStorage.getItem('occupationType');
    const customerType = sessionStorage.getItem('customerType');

    if (JSON.parse(EditCheck)) {

      if (localStorage.getItem('SaveCnicData')) {
        let data = JSON.parse(localStorage.getItem('SaveCnicData'));
        if (typeof data !== 'object' || Object.keys(data).length <= 0) {
          this._sharedSvc.error(this.translate.instant('toast.requireMissingBasicInforTab'));
          return;
        }
      } else {
        this._sharedSvc.error(this.translate.instant('toast.requireMissingBasicInforTab'));
        return;
      }

      if (localStorage.getItem('personalAddress')) {
        let data = JSON.parse(localStorage.getItem('personalAddress'));
        if (typeof data !== 'object' || Object.keys(data).length <= 0) {
          this._sharedSvc.error(this.translate.instant('toast.requireFieldmissingAddressTab'));
          return;
        }
      }
      else {
        this._sharedSvc.error(this.translate.instant('toast.requireFieldmissingAddressTab'));
        return;
      }

      if (localStorage.getItem('clientAllDocs')) {
        let data = JSON.parse(localStorage.getItem('clientAllDocs'));
        if (data.length < 4) {
          this._sharedSvc.error(this.translate.instant('toast.requireDocumentMissingClientDocs'));
          return
        }
      } else {
        this._sharedSvc.error(this.translate.instant('toast.requireDocumentMissingClientDocs'));
        return
      }


      if (occupationType !== null && customerType !== null) {
        if ((JSON.parse(occupationType) !== 'Self Employed' && JSON.parse(occupationType) !== 'Unemployed') && JSON.parse(customerType) === 'GoldFin') {

          // if (this.empDetail !== null && this.empDetail !== undefined) {
          //   if (typeof this.empDetail !== 'object' || Object.keys(this.empDetail).length <= 0) {
          //     this._sharedSvc.error("At least one employer Info is required");
          //     return;
          //   }
          // } else {
          //   this._sharedSvc.error("At least one employer Info is required");
          //   return;
          // }
          if (localStorage.getItem('saveEmployerModelObj')) {
            let data = JSON.parse(localStorage.getItem('saveEmployerModelObj'));
            if (data?.length) {
              let list = this.countObjectsWithDelFlagFalse(data, 'delFlg', false)
              if (list <= 0 || list < 1) {
                this._sharedSvc.error(this.translate.instant('toast.leastEmployerInfoRequire'));
                return;
              }
            } else {
              this._sharedSvc.error(this.translate.instant('toast.leastEmployerInfoRequire'));
              return;
            }
          } else {
            this._sharedSvc.error(this.translate.instant('toast.leastEmployerInfoRequire'));
            return;
          }
          // if (this.empIncome !== null && this.empIncome !== undefined) {
          //   if (typeof this.empIncome !== 'object' || Object.keys(this.empIncome).length <= 0) {
          //     this._sharedSvc.error("At least one salary detail is required");
          //     return;
          //   }
          // } else {
          //   this._sharedSvc.error("At least one salary detail is required");
          //   return;
          // }
          if (localStorage.getItem('empIncome')) {
            let data = JSON.parse(localStorage.getItem('empIncome'));
            if (data?.length) {
              let list = this.countObjectsWithDelFlagFalse(data, 'delFlg', false)
              if (list <= 0 || list < 1) {
                this._sharedSvc.error(this.translate.instant('toast.OneSalaryDetailRequired'));
                return;
              }
            } else {
              this._sharedSvc.error(this.translate.instant('toast.OneSalaryDetailRequired'));
              return;
            }
          } else {
            this._sharedSvc.error(this.translate.instant('toast.OneSalaryDetailRequired'));
            return;
          }
        }
      }

      if (occupationType !== null && customerType !== null) {
        if ((JSON.parse(occupationType) === 'Self Employed' || JSON.parse(occupationType) === 'Unemployed') && JSON.parse(customerType) === 'GoldFin') {

          if (localStorage.getItem('bizAprsBasicInfo')) {
            let data = JSON.parse(localStorage.getItem('bizAprsBasicInfo'));
            if (typeof data !== 'object' || Object.keys(data).length <= 0) {
              this._sharedSvc.error(this.translate.instant('toast.requireFieldBusinessInfoTab'));
              return;
            }
          } else {
            this._sharedSvc.error(this.translate.instant('toast.requireFieldBusinessInfoTab'));
            return;
          }

          if (localStorage.getItem('bizAprsAddress')) {
            let data = JSON.parse(localStorage.getItem('bizAprsAddress'));
            if (typeof data !== 'object' || Object.keys(data).length <= 0) {
              this._sharedSvc.error(this.translate.instant('toast.businessAddressMissing'));
              return;
            }
          } else {
            this._sharedSvc.error(this.translate.instant('toast.businessAddressMissing'));
            return;
          }

          if (localStorage.getItem('quesArr')) {
            let data = JSON.parse(localStorage.getItem('quesArr'));
            if (data.length < 3) {
              this._sharedSvc.error(this.translate.instant('toast.requireFieldMissingBusinessInfoTab'));
              return;
            }
          } else {
            this._sharedSvc.error(this.translate.instant('toast.requireFieldMissingBusinessInfoTab'));
            return;
          }

          if (localStorage.getItem('bizAprsIncome')) {
            let data = JSON.parse(localStorage.getItem('bizAprsIncome'));
            if (data?.length) {
              let list = this.countObjectsWithDelFlagFalse(data, 'delFlg', false)
              if (list <= 0 || list < 1) {
                this._sharedSvc.error(this.translate.instant('toast.bussinessIncomeRequire'));
                return;
              }
            } else {
              this._sharedSvc.error(this.translate.instant('toast.bussinessIncomeRequire'));
              return;
            }
          } else {
            this._sharedSvc.error(this.translate.instant('toast.bussinessIncomeRequire'));
            return;
          }

          if (localStorage.getItem('bizAprsExpense')) {
            let data = JSON.parse(localStorage.getItem('bizAprsExpense'));
            if (data?.length) {
              let list = this.countObjectsWithDelFlagFalse(data, 'delFlg', false)
              if (list <= 0 || list < 1) {
                this._sharedSvc.error(this.translate.instant('toast.bussinessExpenseRequire'));
                return;
              }
            } else {
              this._sharedSvc.error(this.translate.instant('toast.bussinessExpenseRequire'));
              return;
            }

          } else {
            this._sharedSvc.error(this.translate.instant('toast.bussinessExpenseRequire'));
            return;
          }

        }
      }

      // if (this.hozHoldExpense?.length) {
      //   let list = this.countObjectsWithDelFlagFalse(this.hozHoldExpense, 'delFlg', false)
      //   if (list <= 0 || list < 1) {
      //     this._sharedSvc.error("At least one household expenses is required");
      //     return;
      //   }
      // } else {
      //   this._sharedSvc.error("At least one household expenses is required");
      //   return;
      // }
      if (localStorage.getItem('hozHoldExpense')) {
        let data = JSON.parse(localStorage.getItem('hozHoldExpense'));
        if (data?.length) {
          let list = this.countObjectsWithDelFlagFalse(data, 'delFlg', false)
          if (list <= 0 || list < 1) {
            this._sharedSvc.error(this.translate.instant('toast.householdExpensesRequire'));
            return;
          }
        } else {
          this._sharedSvc.error(this.translate.instant('toast.householdExpensesRequire'));
          return;
        }
      } else {
        this._sharedSvc.error(this.translate.instant('toast.householdExpensesRequire'));
        return;
      }






    } else {
      const basicInfoControl = localStorage.getItem('basicInfoControl');
      //const basicInfoValue = localStorage.getItem('basicInfoValue');

      //const addressControl = localStorage.getItem('addressControl');
      const addressValue = localStorage.getItem('addressValue');

      //const clientDocs = sessionStorage.getItem('clientDocImg');


      const isBizAprslOpen = sessionStorage.getItem('isBizAprslOpen');
      const bizAprslBasicFormValues = sessionStorage.getItem('bizAprslBasicFormValues');

      const isBizAprslAdditionalInfoOpen = sessionStorage.getItem('isBizAprslAdditionalInfoOpen');
      const bizAprslAdditionalFormValue = sessionStorage.getItem('bizAprslAdditionalFormValue');

      const isBizAprslAddressOpen = sessionStorage.getItem('isBizAprslAddressOpen');
      const isBizAprslAddressSaveClicked = sessionStorage.getItem('isBizAprslAddressSaveClicked');
      let clicdocs = localStorage.getItem("clientDocImg");

      this._finiancialInfoSvc.businessIncome = JSON.parse(localStorage.getItem("businessIncome"));
      this._finiancialInfoSvc.businessExpenses = JSON.parse(localStorage.getItem("businessExpenses"));
      this._finiancialInfoSvc.householdExpenses = JSON.parse(localStorage.getItem("householdExpenses"));
      let empINFO = localStorage.getItem("EmloyerInfo");
      let salaryINFO = localStorage.getItem("salaryINCOME");

      if (basicInfoControl !== null) {
        if (JSON.parse(basicInfoControl)?.length > 0) {
          this._sharedSvc.error(this.translate.instant('toast.requireMissingBasicInforTab'));
          return;
        }
      } else {
        this._sharedSvc.error(this.translate.instant('toast.requireMissingBasicInforTab'));
        return;
      }
      // if (basicInfoValue) {
      //   let values = JSON.parse(basicInfoValue);
      //   if (!values.frstNm || !values.lastNm || !values.mthrMadnNm || !values.dob || !values.mrtlStsKey || !values.eduLvlKey || !values.occKey || !values.resTypKey || !values.householdMembers || !values.numberOfDependents || !values.numberOfChildren || !values.phNum || !values.walletNumber || !values.customerTypeKey || !values.fthrSpzFrstNm || !values.fthrSpzLastNm) {
      //     this._sharedSvc.error("Required fields are missing on Basic Information tab");
      //     return;
      //   }
      // } else {
      //   this._sharedSvc.error("Required fields are missing on Basic Information tab");
      //   return;
      // }


      // if (addressControl !== null) {
      //   if (JSON.parse(addressControl)?.length > 0) {
      //     this._sharedSvc.error("Required fields are missing on Address tab");
      //     return;
      //   }
      // }
      if (addressValue) {
        let values = JSON.parse(addressValue);
        if (values) {

          if (!values.hseNum || !values.strt || !values.cmntySeq || !values.vlg || !values.othDtl || !values.districtName || !values.thslName || !values.cityName || !values.ucName || !values.stateName || (values.nosOfResidence === null || values.nosOfResidence === undefined) || (values.resOfResidence === null || values.resOfResidence === undefined) || (values.mnthsOfResidence === null || values.mnthsOfResidence === undefined)) {
            this._sharedSvc.error(this.translate.instant('toast.requireFieldmissingAddressTab'));
            return;
          }
        } else {
          this._sharedSvc.error(this.translate.instant('toast.requireFieldmissingAddressTab'));
          return;
        }

      } else {
        this._sharedSvc.error(this.translate.instant('toast.requireFieldmissingAddressTab'));
        return;
      }

      if (clicdocs !== null) {
        let parseclientDocs = JSON.parse(clicdocs);
        if (Object.keys(parseclientDocs).length < 4) {
          this._sharedSvc.error(this.translate.instant('toast.requireDocumentMissingClientDocs'));
          return
        }
      } else {
        this._sharedSvc.error(this.translate.instant('toast.requireDocumentMissingClientDocs'));
        return
      }

      if (occupationType !== null && customerType !== null) {
        if ((JSON.parse(occupationType) !== 'Self Employed' && JSON.parse(occupationType) !== 'Unemployed') && JSON.parse(customerType) === 'GoldFin') {
          if (empINFO !== null) {
            let parseempINFO = JSON.parse(empINFO);
            if (parseempINFO.length < 1) {
              this._sharedSvc.error(this.translate.instant('toast.leastEmployerInfoRequire'));
              return;
            }
          } else {
            this._sharedSvc.error(this.translate.instant('toast.leastEmployerInfoRequire'));
            return;
          }
          if (salaryINFO !== null) {
            let parsesalaryINFO = JSON.parse(salaryINFO);
            if (parsesalaryINFO.length < 1) {
              this._sharedSvc.error(this.translate.instant('toast.OneSalaryDetailRequired'));
              return;
            }
          } else {
            this._sharedSvc.error(this.translate.instant('toast.OneSalaryDetailRequired'));
            return;
          }
        }
      }

      if (occupationType !== null && customerType !== null) {
        if ((JSON.parse(occupationType) === 'Self Employed' || JSON.parse(occupationType) === 'Unemployed') && JSON.parse(customerType) === 'GoldFin') {

          if (JSON.parse(isBizAprslOpen)) {
            let values = JSON.parse(bizAprslBasicFormValues);
            if (values) {

              if (!values.sectKey || !values.actyKey || !values.bizDtlStr || !values.bizPhNum || !values.NoOfYears) {
                this._sharedSvc.error(this.translate.instant('toast.requireFieldBusinessInfoTab'));
                return;
              }
            } else {
              this._sharedSvc.error(this.translate.instant('toast.requireFieldBusinessInfoTab'));
              return;
            }

          } else {
            this._sharedSvc.error(this.translate.instant('toast.requireFieldBusinessInfoTab'));
            return;
          }


          if (JSON.parse(isBizAprslAddressOpen)) {
            if (!JSON.parse(isBizAprslAddressSaveClicked)) {
              this._sharedSvc.error(this.translate.instant('toast.businessAddressMissing'));
              return;
            }
          } else {
            this._sharedSvc.error(this.translate.instant('toast.businessAddressMissing'));
            return;
          }


          if (JSON.parse(isBizAprslAdditionalInfoOpen)) {
            let values = JSON.parse(bizAprslAdditionalFormValue);

            if (values) {
              if (values.quesArr.length < 3) {
                this._sharedSvc.error(this.translate.instant('toast.requireFieldMissingBusinessInfoTab'));
                return;
              }
            } else {
              this._sharedSvc.error(this.translate.instant('toast.requireFieldMissingBusinessInfoTab'));
              return;
            }

          } else {
            this._sharedSvc.error(this.translate.instant('toast.requireFieldMissingBusinessInfoTab'));
            return;
          }



          if (this._finiancialInfoSvc?.businessIncome?.length) {
            let list = this.countObjectsWithDelFlagFalse(this._finiancialInfoSvc?.businessIncome, 'delFlg', false)
            if (list <= 0 || list < 1) {
              this._sharedSvc.error(this.translate.instant('toast.bussinessIncomeRequire'));
              return;
            }
          } else {
            this._sharedSvc.error(this.translate.instant('toast.bussinessIncomeRequire'));
            return;
          }


          if (this._finiancialInfoSvc?.businessExpenses?.length) {
            let list = this.countObjectsWithDelFlagFalse(this._finiancialInfoSvc?.businessExpenses, 'delFlg', false)
            if (list <= 0 || list < 1) {
              this._sharedSvc.error(this.translate.instant('toast.bussinessExpenseRequire'));
              return;
            }
            // if (this._finiancialInfoSvc?.businessExpenses?.length <= 0 || this._finiancialInfoSvc?.businessExpenses?.length < 1) {
            //   this._sharedSvc.error("At least one Bussiness Expense is required");
            //   return;
            // }
          } else {
            this._sharedSvc.error(this.translate.instant('toast.bussinessExpenseRequire'));
            return;
          }

        }
      }

      if (this._finiancialInfoSvc?.householdExpenses?.length) {
        let list = this.countObjectsWithDelFlagFalse(this._finiancialInfoSvc?.householdExpenses, 'delFlg', false)
        if (list <= 0 || list < 1) {
          this._sharedSvc.error(this.translate.instant('toast.householdExpensesRequire'));
          return;
        }
      } else {
        this._sharedSvc.error(this.translate.instant('toast.householdExpensesRequire'));
        return;
      }
    }



    
    if (this.clientSummaryForm.invalid) {
      for (const control of Object.keys(this.clientSummaryForm.controls)) {
        this.clientSummaryForm.controls[control].markAsTouched();
      }
      return;
    }

    this._summarySvc.submitSummaryModel.clntSeq = this.saveCnicData?.clntSeq;
    this._summarySvc.submitSummaryModel.cmnts = this.cmntsClient.value;
    this._sharedSvc.showSpinner()
    this._summarySvc.submitSummary().subscribe({
      next: (response) => {
        if (response) {
          this.modalService.dismissAll();
          this._sharedSvc.success("Client submitted successfully");
          this.router.navigate(['/onboarding']);
          this._sharedSvc.hideSpinner();

          localStorage.removeItem("basicInfoControl");
          localStorage.removeItem("addressValue");
          localStorage.removeItem("clientDocImg");
          localStorage.removeItem("businessIncome");
          localStorage.removeItem("businessExpenses");
          localStorage.removeItem("householdExpenses");
          localStorage.removeItem("EmloyerInfo");
          localStorage.removeItem("salaryINCOME");
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }
  onCancelSm(): void {
    this.router.navigate(['/onboarding']);
  }
onSubmitStatus(status:number){
  if (this.clientSummaryForm.invalid) {
    for (const control of Object.keys(this.clientSummaryForm.controls)) {
      this.clientSummaryForm.controls[control].markAsTouched();
    }
    return;
  }
  this._summarySvc.clientStatusModel.clntSeq = this.saveCnicData?.clntSeq;
  this._summarySvc.clientStatusModel.comment = this.cmntsClient.value;
  this._summarySvc.clientStatusModel.status=status;
  this._sharedSvc.showSpinner()
  this._summarySvc.submitClientStatus().subscribe({
    next: (response) => {
      if (response) {
        this.modalService.dismissAll();
        this._sharedSvc.success(this.translate.instant('toast.statusUpdated'));
        this.router.navigate(['/onboarding']);
        this._sharedSvc.hideSpinner();
      }
    },
    error: (e) => {
      this._sharedSvc.hideSpinner();
      if (e.status == 401) {
        this._sharedSvc.error(this._sharedSvc.authMsg);
        this._sharedSvc.logout();
        return;
      }
      if (e?.error) {
        Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
          this._sharedSvc.error(value);
        });
      }
      if (e?.error?.errorMessage) {
        this._sharedSvc.error(e?.error?.error);
      }
    },
    // complete: () => console.info('complete')
  })
}

  onSubmitCredit(): void {
    if (this.creditSummaryForm.invalid) {
      for (const control of Object.keys(this.creditSummaryForm.controls)) {
        this.creditSummaryForm.controls[control].markAsTouched();
      }
      return;
    }

    let CCI = sessionStorage.getItem("CCI");
    let CLTD = sessionStorage.getItem("CLTD");
    let CDD = sessionStorage.getItem("CDD");
    if (this.isICredit === true) {
      if (CCI !== null) {
        let parseCCI = JSON.parse(CCI);
        if (!parseCCI.rqstdAmt || !parseCCI.aprvdAmt || !parseCCI.prdSeq) {
          let errorString = "Required fields are missing on Client Credit Information ";
          if (!parseCCI.rqstdAmt) {
            errorString += "Request Amount ";
          }
          if (!parseCCI.aprvdAmt) {
            errorString += "Approved Amount ";
          }
          if (!parseCCI.prdSeq) {
            errorString += "Product ";
          }

          this._sharedSvc.error(errorString);
          return;
        }

      } else {
        this._sharedSvc.error(this.translate.instant('toast.clientCreditInfoMissing'));
        return
      }

      if (CDD !== null) {
        let parseCDD = JSON.parse(CDD);
        if (Object.keys(parseCDD).length <= 0) {
          this._sharedSvc.error(this.translate.instant('toast.uploadAtLeastOneDocument'));
          return
        }
      } else {
        this._sharedSvc.error(this.translate.instant('toast.uploadDocuments'));
        return
      }

      if (this.allowedRoutes.length > 0 && this.allowedRoutes[0].rolId === 'CMO') {


        if (CLTD !== null) {

          let parseDLTD = JSON.parse(CLTD);
          if (!parseDLTD.shrffId || !parseDLTD.shrffBgNum || !parseDLTD.brnchBgNum) {
            let errorString = "Required fields are missing on Collateral Detail Tab";
            if (!parseDLTD.brnchBgNum) {
              errorString += this.translate.instant('toast.branchBag');
            }
            if (!parseDLTD.shrffBgNum) {
              errorString += this.translate.instant('toast.shroffBag');
            }
            if (!parseDLTD.shrffId) {
              errorString += this.translate.instant('toast.shroffId');
            }

            this._sharedSvc.error(errorString);
            return;
          }

          if (this._creditCollateralSvc?.getAllCollatrialDtl_data?.length <= 0) {
            this._sharedSvc.error(this.translate.instant('toast.pleaseAtleastCollateralTab'));
            return
          }

        } else {
          this._sharedSvc.error(this.translate.instant('toast.pleaseCollateralDetails'));
          return
        }
      }
    }
    this._summarySvc.submitCreditModel.crdtAppSeq = this.crdtApp;
    this._summarySvc.submitSummaryModel.cmnts = this.cmntsCredit.value;
    this._sharedSvc.showSpinner()
    this._summarySvc.submitCASummary().subscribe({
      next: (response) => {
        if (response) {
          this.modalService.dismissAll();
          this._sharedSvc.success(this.translate.instant('toast.creditSubmittedSuccessfully'));
          this.router.navigate(['/individual-group-info']);
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }
  onCancelCredit(): void {
    this.router.navigate(['/individual-group-info']);
  }

  // get cmnts() {
  //   return this.submitSumForm.get('cmnts')!;
  // }
  // get cmnts2() {
  //   return this.submitCreditForm.get('cmnts2')!;
  // }


  hasEmptyStringValues(obj: any) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] === "") {
        return true; // Found an empty string value
      }
    }
    return false; // No empty string values found
  }

  countObjectsWithDelFlagFalse(objects: any, key: any, valueToFind: any) {
    let count = 0;
    for (const obj of objects) {
      if (obj.hasOwnProperty(key) && obj[key] === valueToFind) {
        count++;
      }
    }
    return count;
  }

}
