import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CookieService } from 'ngx-cookie-service';
import { Observable } from "rxjs";
import { CNICValidation } from "../../../shared/model/clintOnboarding";
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { environment } from "src/app/global";
import { OtherStanding } from "../model/otherOutstanding";
import { SharedService } from "src/app/components/shared/service/shared.service";
import { DatePipe } from "@angular/common";
import { PicklistService } from "src/app/components/shared/pickList/picklist.service";
import { Endpoint } from "src/app/components/shared/serviceEndPoints";

@Injectable({
  providedIn: 'root'
})
export class OtherOutstandingService {

  otherStandingModel = new OtherStanding();
  getAllOutstanding: any = [];
  getAllOutstandingPG: any;
  edit: boolean = false;
  IIE: any = 0;
  saveOtherStandingModel: any = [];

  constructor(
    public router: Router,
    private _http: HttpClient,
    private _cookieSvc: CookieService,
    public _sharedSvc: SharedService,
    public _picklistService: PicklistService

  ) { }

  // APIS OtherOutstanding Start 
  SaveClientOtherOutstanding() {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_URL + Endpoint.OtherOutstanding.save_clnt_oth_outstanding, this.otherStandingModel, this._sharedSvc.requestOptions);
  }

  getClientOtherOutstanding(clnt_othr_outstanding_seq: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL + Endpoint.OtherOutstanding.get_clnt_oth_outstanding + clnt_othr_outstanding_seq, this._sharedSvc.requestOptions);
  }

  getAllClientOtherOutstanding(clnt_seq: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL + Endpoint.OtherOutstanding.get_all_clnt_oth_outstandings + clnt_seq, this._sharedSvc.requestOptions);
  }
  // APIS OtherOutstanding End 

  InItGetClientOtherOutstanding(clnt_othr_outstanding_seq: any): void {
    this._sharedSvc.showSpinner();
    this.getClientOtherOutstanding(clnt_othr_outstanding_seq).subscribe({
      next: (response: any) => {
        if (response) {
          this.otherStandingModel = Object.assign({}, response);
          const datePipe = new DatePipe('en-Us');
          this.otherStandingModel.cmplDt = datePipe.transform(response.cmplDt, 'yyyy-MM-dd');
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }
  InItGetAllClientOtherOutstanding(clnt_seq: any): void {
    this.getAllClientOtherOutstanding(clnt_seq).subscribe({
      next: (response) => {
        if (response) {
          this.getAllOutstanding = response;
          localStorage.setItem('getAllOutstandingData', JSON.stringify(this.getAllOutstanding))
         this.calculateIEE();
          this.pageChange();

        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }
  calculateIEE(){
  this.IIE = 0;
  for (let i = 0; i < this.getAllOutstanding?.length; i++) {
    if (this.getAllOutstanding[i].mnthExpFlg == true) {
      let IIE = this.getAllOutstanding[i].crntOutsdAmt;
      this.IIE = this.IIE + IIE;
    }
    localStorage.setItem('IIE', this.IIE);

  }
}
  pageChange() {
    this.getAllOutstandingPG = this.getAllOutstanding;
    // this._sharedSvc.collectionSize = this.getAllOutstanding?.length;
    // this.getAllOutstandingPG = this.getAllOutstanding.slice((this._sharedSvc.page - 1) * this._sharedSvc.pageSize, (this._sharedSvc.page - 1) * this._sharedSvc.pageSize + this._sharedSvc.pageSize);
  }

  dropdownsDef(data: any) {
    this._picklistService.INSTITUTE_DataClone = [... this._picklistService.INSTITUTE_Data];
    if (this.getAllOutstanding.length > 0 && this._picklistService.INSTITUTE_DataClone) {
      let array = this.getAllOutstanding;
      let dropD = [...this._picklistService.INSTITUTE_DataClone];

      for (let i = 0; i < array.length; i++) {
        for (let j = 0; j < dropD.length; j++) {
          if (dropD[j].refCdSeq == array[i].instnNm && array[i].instnNm != data?.instnNm) {
            dropD.splice(j, 1);
          }
        }
      }
      this._picklistService.INSTITUTE_DataClone = [...dropD];
    }
  }

  dropdowns() {
    this._picklistService.INSTITUTE_DataClone = [... this._picklistService.INSTITUTE_Data];
    if (this.getAllOutstanding?.length > 0 && this._picklistService.INSTITUTE_DataClone) {
      let array = this.getAllOutstanding;
      let dropD = [...this._picklistService.INSTITUTE_DataClone];
      for (let i = 0; i < array.length; i++) {
        for (let j = 0; j < dropD.length; j++) {
          if (dropD[j].refCdSeq == array[i].instnNm && array[i].delFlg == false) {

            dropD.splice(j, 1);
          }
        }

      }
      this._picklistService.INSTITUTE_DataClone = [...dropD];
    }


  }

}
