import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { SharedService } from '../../shared/service/shared.service';
import { RouteInfo } from 'src/app/auth/model/route-info';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  allowedRoutes: RouteInfo[];

  constructor(
    public router: Router,
    public _sharedSvc: SharedService,
    public translate: TranslateService
  ) {

    this.allowedRoutes = JSON.parse(sessionStorage.getItem("routeInfo"));

  }

  ngOnInit() {

  }

  // menuSidebar = [
  //   {
  //     link_name: "Dashboard",
  //     link: "/dashboard",
  //     icon: "bx bx-grid-alt",
  //     sub_menu: []
  //   }, {
  //     link_name: "Client Onboarding",
  //     link: "/onboarding",
  //     icon: "bx bx-heart",
  //     sub_menu: []
  //   }, {
  //     link_name: "Individual/Group loan Formation",
  //     link: null,
  //     icon: "bx bx-dollar",
  //     sub_menu: []
  //   }, {
  //     link_name: "Disbursement",
  //     link: "/analytics",
  //     icon: "bx bx-pie-chart-alt-2",
  //     sub_menu: [
  //       {
  //         link_name: "Web Design",
  //         link: "/posts/web-design",
  //       }, {
  //         link_name: "Login Form",
  //         link: "/posts/login-form",
  //       }, {
  //         link_name: "Card Design",
  //         link: "/posts/card-design",
  //       }
  //     ]
  //   }
  // ]


  showMenu() {
    this._sharedSvc.openSidebar = !this._sharedSvc.openSidebar;
  }

  showSubmenu(itemEl: HTMLElement) {
    itemEl.classList.toggle("showMenu");
  }

  dashboard() {
    this.router.navigate(['/dashboard']);
  }


  showOption(name: string) {
    return this.allowedRoutes?.filter(it => it.sbModUrl.toLowerCase() == name.toLowerCase()
      || name.toLowerCase() == it.sbModUrl.toLowerCase()).length > 0
  }

  hasChild(name: string) {
    return this.allowedRoutes?.filter(it => it.sbModUrl.toLowerCase().includes(name.toLowerCase())
      || name.toLowerCase().includes(it.sbModUrl.toLowerCase())).length > 0
  }

}



//CSO user can't see Collateral Detail and Summary
//CMOUSR can see this
// shroff ID population with it's endPoint
// shroff Bag population with it's endPoint
// Branch bag population with it's endPoint
//Finance agrrement 2
// download buttons 