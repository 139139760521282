<section>
    <div class="sidebar" [class.sidebar-close]="!_sharedSvc.openSidebar">
        <app-sidebar></app-sidebar>
    </div>
    <div class="home-section">
        <div class="home-main" [class.sidebar-close_home-main]="!_sharedSvc.openSidebar">
            <app-navbar></app-navbar>
            <div class="home-view" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
                [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
                <section>
                    <div class="row mb-3">
                        <div class="col-sm-8 col-md-8 col-lg-8">
                            <h4>{{'common.branch' | translate}} </h4>
                        </div>
                        <div class="col-sm-4 col-md-4 col-lg-4">
                            <div class="display-flex float-right">
                                <div class="back-btn me-2" (click)="areaFn()">
                                    <i class="fa fa-reply"></i>
                                </div>
                                <div class="add-button" (click)="openModal(branch , false)">
                                    <i class="fa fa-plus"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <div class="dashboard-card loan-table">
                                <div class="row mb-3">
                                    <div class="col-sm-7 col-md-7 col-lg-8">
                                        <ng-container *ngIf="_sharedSvc.pageSize >= 10">
                                            <span>{{'common.show' | translate}}</span>
                                            <input type="number" class="m-1 text-center" style="width: 45px;">
                                            <span>{{'common.entries' | translate}}</span>
                                        </ng-container>
                                    </div>
                                    <div class="col-sm-5 col-md-5 col-lg-4">
                                        <div class="inside-icon">
                                            <i class="fa fa-search"></i>
                                            <input type="search" class="form-control search-input"
                                                placeholder="{{'common.search' | translate}}">
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                        <div class="table-responsive">
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <td> {{'region.branch.branchCode' | translate}}</td>
                                                        <td>{{'region.branch.branchName' | translate}}</td>
                                                        <td>{{'region.branch.branchType' | translate}}</td>
                                                        <td>{{'common.description' | translate}}</td>
                                                        <td>{{'common.status' | translate}}</td>
                                                        <td class="text-center">{{'common.action' | translate}}</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <ng-container
                                                        *ngIf="!_SetupService.allBranchList?.error && _SetupService.allBranchList?.length > 0">
                                                        <tr *ngFor="let list of _SetupService.allBranchList">

                                                            <td>{{list.branchCode}}</td>
                                                            <td class="clr186ed1 pointer" (click)="portfolio(list)">
                                                                {{list.branchName}}</td>
                                                            <td>{{findbranchTYPE(list.branchTypeKey)}}</td>
                                                            <td>{{list.branchDescription}}</td>
                                                            <td>{{list.branchStatusKey === "2132" ?
                                                                this.translate.instant('common.inActive') :
                                                                this.translate.instant('common.active')}}</td>
                                                            <td class="text-center">
                                                                <div class="action-inner">
                                                                    <i class="fa fa-tablet-alt"
                                                                        (click)="openModalTabletAssignment(tabletAssignmentModal)"></i>
                                                                    <i class="fa fa-project-diagram clef8ac01"
                                                                        (click)="openModalProductAssignment(productAssignModal,list.branchSequence)"></i>
                                                                    <i class="fa fa-bank"
                                                                        (click)="openModalAccountSetup(accountSetupModal , list.branchSequence , list.branchName)"></i>
                                                                    <i class="fa fa-briefcase"
                                                                        (click)="openBranchModalBagList(bagListing, list.branchSequence)"
                                                                        aria-hidden="true"></i>


                                                                    <i class="fa fa-map-marker-alt"
                                                                        (click)="openModalAssignLocation(assignLocationModal,list.branchSequence)"></i>
                                                                    <i class="fa fa-user-o clr186ed1"
                                                                        (click)="openModalEmployeeAssignment(employeeAssignment , list.branchSequence)"></i>
                                                                    <i class="fa fa-pencil"
                                                                        (click)="editBranch(branch,list.branchSequence , true)"></i>
                                                                    <i class="fa fa-trash"
                                                                        (click)="deleteRecord(list.branchSequence)"></i>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </ng-container>
                                                    <ng-container *ngIf="_SetupService.allBranchList?.error">
                                                        <tr>
                                                            <td colspan="7" class="text-center">{{
                                                                _SetupService.allBranchList.error }}</td>
                                                        </tr>
                                                    </ng-container>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</section>

<ng-template id="display-modal" #branch let-c="close" let-d="dismiss">
    <div class="modal-body" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
        [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
        <div class="p-3 pb-0">
            <div class="row mb-3">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="text-center">
                        <h4>{{isEdit === true ? this.translate.instant('common.edit') :
                            this.translate.instant('common.add')}} {{'common.branch' | translate}}</h4>
                    </div>
                </div>
            </div>
            <form [formGroup]="addBranchformdata" (ngSubmit)="submittBranch(addBranchformdata)">
                <div class="row mb-3">

                    <!-- <div class="col-sm-12 col-md-12 col-lg-4 mb-3">

                        <div class="form-floating">
                            <select name="regionSequence" id="regionSequence"
                                class="form-control input-radius form-select" formControlName="regionSequence"
                                [class.is-invalid]="regionSeqID.invalid && (regionSeqID.dirty || regionSeqID.touched)">
                                <option *ngFor="let item of _SetupService.allRegionList; let i = index"
                                    [value]="item?.regionSequence">
                                    {{ item.regionName }}
                                </option>
                            </select>

                            <label>Select Region</label>
                        </div>
                    </div> -->
                    <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
                        <div class="form-floating">
                            <select class="form-control form-select input-radius" formControlName="areaSequence"
                                [class.is-invalid]="AreaSeqID.invalid && (AreaSeqID.dirty || AreaSeqID.touched)">
                                <option *ngFor="let item of _SetupService.allAreasList; let i = index"
                                    [value]="item?.areaSequence">
                                    {{ item.areaName }}
                                </option>
                            </select>
                            <div *ngIf="AreaSeqID.invalid && (AreaSeqID.dirty || AreaSeqID.touched)"
                                class="invalid-feedback">
                                <div *ngIf="AreaSeqID.errors?.['required']">
                                    {{'region.branch.areaRequire' | translate}}
                                </div>

                            </div>
                            <label> {{'region.branch.selectArea' | translate}} </label>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
                        <div class="form-floating">
                            <input type="text" formControlName="branchName"
                                [class.is-invalid]="branchName.invalid && (branchName.dirty || branchName.touched)"
                                style="text-transform: capitalize;" autocomplete="off"
                                class="form-control input-radius">
                            <div *ngIf="branchName.invalid && (branchName.dirty || branchName.touched)"
                                class="invalid-feedback">
                                <div *ngIf="branchName.errors?.['required']">
                                    {{'region.branch.branchNameRequire' | translate}}
                                </div>

                            </div>
                            <label>{{'region.branch.branchName' | translate}}</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
                        <div class="form-floating">
                            <select formControlName="branchTypeKey"
                                [class.is-invalid]="branchTypeKey.invalid && (branchTypeKey.dirty || branchTypeKey.touched)"
                                class="form-control form-select input-radius">
                                <option *ngFor="let item of _picklistService.BRANCH_TYPE_Data; let i = index"
                                    [value]="item?.refCdSeq">{{item.refCdDscr}}</option>

                            </select>
                            <div *ngIf="branchTypeKey.invalid && (branchTypeKey.dirty || branchTypeKey.touched)"
                                class="invalid-feedback">
                                <div *ngIf="branchTypeKey.errors?.['required']">
                                    {{'region.branch.branchTypeRequire' | translate}}
                                </div>
                            </div>
                            <label> {{'region.branch.branchType' | translate}}</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
                        <div class="form-floating">
                            <select formControlName="branchStatusKey"
                                [class.is-invalid]="branchStatusKey.invalid && (branchStatusKey.dirty || branchStatusKey.touched)"
                                class="form-control form-select input-radius">
                                <option *ngFor="let item of _picklistService.SHROFF_STATUS_Data; let i = index"
                                    [value]="item?.refCdSeq">
                                    {{ item.refCdDscr }}
                                </option>
                            </select>
                            <div *ngIf="branchStatusKey.invalid && (branchStatusKey.dirty || branchStatusKey.touched)"
                                class="invalid-feedback">
                                <div *ngIf="branchStatusKey.errors?.['required']">
                                    {{'common.statusRequire' | translate}}
                                </div>
                            </div>
                            <label>{{'common.status' | translate}}</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                        <div class="form-floating">
                            <input type="text" formControlName="branchDescription"
                                [class.is-invalid]="branchDescription.invalid && (branchDescription.dirty || branchDescription.touched)"
                                style="text-transform: capitalize;" autocomplete="off"
                                class="form-control input-radius">
                            <div *ngIf="branchDescription.invalid && (branchDescription.dirty || branchDescription.touched)"
                                class="invalid-feedback">
                                <div *ngIf="branchDescription.errors?.['required']">
                                    {{'common.descriptionRequire' | translate}}
                                </div>
                            </div>
                            <label>{{'common.description' | translate}}</label>
                        </div>
                    </div>




                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="button_div">
                            <button class="btn login-button-class" [class.pointerNone]="_sharedSvc.loading"
                                [disabled]="_sharedSvc.loading">{{'common.ok' | translate}}</button>
                            <button class="btn login-button-class cancelBtn" (click)="d('Cross click')"
                                type="reset">{{'common.cancel' | translate}}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>

<!-- Assign Product to Branch Modal PopUp Begin -->
<ng-template id="display-modal" #accountSetupModal let-c="close" let-d="dismiss">
    <div class="modal-body" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
        [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
        <div class="p-3 pb-0">
            <div class="row mb-3">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="text-center">
                        <h4> {{'region.branch.accountSetup' | translate}}</h4>
                    </div>
                </div>
            </div>
            <form [formGroup]="branchAccountformdata" (ngSubmit)="submitBranchAccount(branchAccountformdata)">
                <div class="row mb-3">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div>
                            <p class="mb-2"><b>{{'common.branchId' | translate}}:</b><span
                                    class="ms-3 fix-ms-3">{{currentBranchID}}</span></p>
                            <p class="mb-3"><b>{{'common.branchName' | translate}}:</b><span
                                    class="ms-3 fix-ms-3">{{currentBranchName}}</span></p>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6">
                        <div class="form-floating mb-3">
                            <select class="form-control form-select input-radius" formControlName="bankName">
                                <option *ngFor="let list of _picklistService.INSTITUTE_Data" [value]="list.refCdSeq">
                                    {{list.refCdDscr}}</option>
                            </select>
                            <label>{{'common.bankName' | translate}}</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6">
                        <div class="form-floating mb-3">
                            <input type="text" formControlName="bankBranch" style="text-transform: capitalize;"
                                autocomplete="off" class="form-control input-radius">
                            <label>{{'common.branchName' | translate}}</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4">
                        <div class="form-floating mb-3">
                            <input type="text" formControlName="accountName" style="text-transform: capitalize;"
                                autocomplete="off" class="form-control input-radius">
                            <label> {{'region.branch.accountTitle' | translate}}</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4">
                        <div class="form-floating mb-3">
                            <input type="text" formControlName="accountNumber" style="text-transform: capitalize;"
                                autocomplete="off" class="form-control input-radius">
                            <label>{{'common.account' | translate}} #</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4">
                        <div class="form-floating mb-3">
                            <input type="text" formControlName="internationalBankAccountNumber"
                                style="text-transform: capitalize;" autocomplete="off"
                                class="form-control input-radius">
                            <label>IBAN #</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="button_div">
                            <button class="btn login-button-class" [class.pointerNone]="_sharedSvc.loading"
                                [disabled]="_sharedSvc.loading">{{'common.ok' | translate}}</button>
                            <button class="btn login-button-class cancelBtn" (click)="d('Cross click')"
                                type="reset">{{'common.cancel' | translate}}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>
<!-- Assign Product to Branch Modal PopUp End -->

<!-- Assign Product to Branch Modal PopUp Begin -->
<ng-template id="display-modal" #productAssignModal let-c="close" let-d="dismiss">
    <div class="modal-body" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
        [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
        <div class="p-3 pb-0">
            <div class="row mb-3">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="text-center">
                        <h4>{{'region.branch.productAssignBranch' | translate}}</h4>
                    </div>
                </div>
            </div>
            <form [formGroup]="branchProductformdata" (ngSubmit)="submitBranchAccount(branchProductformdata)">
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <td>{{'common.productId' | translate}}</td>
                                <td>{{'common.productName' | translate}}</td>
                                <td>{{'common.productType' | translate}}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container
                                *ngIf="!_picklistService.allProduct_Data?.error && _picklistService.allProduct_Data?.length > 0">
                                <tr *ngFor="let list of _picklistService.allProduct_Data">

                                    <td><input type="checkbox"
                                            class="form-check-input pointer me-3 branch-asgn-check-fix"
                                            (change)="pushProductInList($event)"
                                            [checked]="isProductSeqPresent(list.productSeq)"
                                            formControlName="productSequence" [value]="list.productSeq">
                                        <span>{{list.productID}}</span>
                                    </td>
                                    <td>{{list.productName}}</td>
                                    <td>{{list.prdGroupName}}</td>
                                </tr>
                            </ng-container>
                            <ng-container *ngIf="_picklistService.allProduct_Data?.error">
                                <tr>
                                    <td colspan="3" class="text-center">{{ _picklistService.allProduct_Data.error }}
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="button_div">
                            <button (click)="submitBranchProduct()" class="btn login-button-class"
                                [class.pointerNone]="_sharedSvc.loading" [disabled]="_sharedSvc.loading">{{'common.ok' |
                                translate}}</button>
                            <button class="btn login-button-class cancelBtn" (click)="d('Cross click')"
                                type="reset">{{'common.cancel' | translate}}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>
<!-- Assign Product to Branch Modal PopUp End -->

<!-- Assign Remittance to Branch Modal PopUp Begin -->
<ng-template id="display-modal" #remittance let-c="close" let-d="dismiss">
    <div class="modal-body" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
        [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
        <div class="p-3 pb-0">
            <div class="row mb-3">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="text-center">
                        <h4>Remittance</h4>
                    </div>
                </div>
            </div>
            <form>
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <td>Remittance Type</td>
                                <td>{{'common.select' | translate}} </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1ABC</td>
                                <td><input type="checkbox" class="form-check-input pointer"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="button_div">
                            <button class="btn login-button-class" [class.pointerNone]="_sharedSvc.loading"
                                [disabled]="_sharedSvc.loading">{{'common.ok' | translate}}</button>
                            <button class="btn login-button-class cancelBtn" (click)="d('Cross click')"
                                type="reset">{{'common.cancel' | translate}}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>
<!-- Assign Remittance to Branch Modal PopUp End -->

<!-- Assign Location to Branch Modal PopUp Begin -->
<ng-template id="display-modal" #assignLocationModal let-c="close" let-d="dismiss">
    <div class="modal-body" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
        [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
        <div class="p-3 pb-0">
            <div class="row mb-3">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="text-center">
                        <h4>{{'common.assignLocation' | translate}}</h4>
                    </div>
                </div>
            </div>
            <form>
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <td>{{'common.id' | translate}}</td>
                                <td>{{'common.province' | translate}}</td>
                                <td>{{'common.district' | translate}}</td>
                                <td>{{'common.tehsil' | translate}}</td>
                                <td>{{'common.uc' | translate}}</td>
                                <td>{{'common.city' | translate}}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container
                                *ngIf="!_picklistService.locationData?.error && _picklistService.locationData?.length > 0">
                                <tr *ngFor="let list of _picklistService.locationData">

                                    <td><input type="checkbox" (change)="pushLocationInList($event)"
                                            [checked]="isLocationSeqPresent(list.locationSeq)"
                                            class="form-check-input pointer" [value]="list.locationSeq"> </td>
                                    <td>{{list.stateName}}</td>
                                    <td>{{list.districtName}}</td>
                                    <td>{{list.thslName}}</td>
                                    <td>{{list.ucName}}</td>
                                    <td>{{list.cityName}}</td>
                                </tr>
                            </ng-container>
                            <ng-container *ngIf="_picklistService.locationData?.error">
                                <tr>
                                    <td colspan="6" class="text-center">{{ _picklistService.locationData.error }}</td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="button_div">
                            <button (click)="submitLocation()" class="btn login-button-class"
                                [class.pointerNone]="_sharedSvc.loading" [disabled]="_sharedSvc.loading">{{'common.ok' |
                                translate}}</button>
                            <button class="btn login-button-class cancelBtn" (click)="d('Cross click')"
                                type="reset">{{'common.cancel' | translate}}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>
<!-- Assign Location to Branch Modal PopUp End -->

<!-- Assign Employee to Branch Modal PopUp Begin -->
<ng-template id="display-modal" #employeeAssignment let-c="close" let-d="dismiss">
    <div class="modal-body" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
        [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
        <div class="p-3 pb-0">
            <div class="row mb-3">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="text-center mb-3">
                        <h4>{{'common.employeeAssignment' | translate}}</h4>
                    </div>
                </div>
            </div>
            <form [formGroup]="addEmployeeAssignment" (ngSubmit)="submitEmpAssign(addEmployeeAssignment)">
                <div class="row mb-3">
                    <div class="col-sm-12 col-md-12 col-lg-6">
                        <div class="form-floating mb-3">
                            <select
                                [class.is-invalid]="employeeSequence.invalid && (employeeSequence.dirty || employeeSequence.touched)"
                                formControlName="employeeSequence" class="form-control form-select input-radius">
                                <option *ngFor="let item of _SetupService.allEMPLIST; let i = index"
                                    [value]="item?.empSeq">
                                    {{ item.empNm }}
                                </option>
                            </select>
                            <div (click)="removeBranchAreaManager('employeeSequence')"
                                [ngClass]="{'remove-selection-box':  addEmployeeAssignment.controls['employeeSequence'].value, 'd-none': !addEmployeeAssignment.controls['employeeSequence'].value}">
                                <i class="fa fa-times icon"></i>

                                <div *ngIf="employeeSequence.invalid && (employeeSequence.dirty || employeeSequence.touched)"
                                    class="invalid-feedback">
                                    <div *ngIf="employeeSequence.errors?.['required']">
                                        {{'region.branch.branchManagerRequire' | translate}}
                                    </div>
                                </div>

                            </div>
                            <label> {{'region.branch.branchManager' | translate}}</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6">
                        <div class="form-floating mb-3" [class.disableForm]="coveringEMPLIST.length === 0">
                            <select formControlName='coveringEmployeeSequence'
                                class="form-control form-select input-radius">
                                <option hidden value="null"></option>
                                <option *ngFor="let item of coveringEMPLIST; let i = index" [value]="item?.empSeq">
                                    {{ item.empNm }}
                                </option>

                            </select>
                            <div (click)="removeBranchAreaManager('coveringEmployeeSequence')"
                                [ngClass]="{'remove-selection-box':  addEmployeeAssignment.controls['coveringEmployeeSequence'].value, 'd-none': !addEmployeeAssignment.controls['coveringEmployeeSequence'].value}">
                                <i class="fa fa-times icon"></i>
                            </div>
                            <label>{{'common.coveringEmployee' | translate}}</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6">
                        <div class="form-floating mb-3">
                            <input type="date" formControlName="coveringEmployeeFromDate"
                                class="form-control input-radius">
                            <label>{{'common.formDate' | translate}} </label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6">
                        <div class="form-floating mb-3">
                            <input type="date" formControlName="coveringEmployeeToDate"
                                class="form-control input-radius">
                            <label>{{'common.toDate' | translate}} </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="button_div">
                            <button class="btn login-button-class" [class.pointerNone]="_sharedSvc.loading"
                                [disabled]="_sharedSvc.loading">{{'common.ok' | translate}}</button>
                            <button class="btn login-button-class cancelBtn" (click)="d('Cross click')"
                                type="reset">{{'common.cancel' | translate}}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>
<!-- Assign Employee to Branch Modal PopUp Begin -->

<!-- Assign Device to Branch Modal PopUp Begin -->
<ng-template id="display-modal" #tabletAssignmentModal let-c="close" let-d="dismiss">
    <div class="modal-body" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
        [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
        <div class="p-3 pb-0">
            <div class="row mb-4">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="text-center">
                        <h4>{{'region.branch.assignmentTablet' | translate}} </h4>
                    </div>
                </div>
            </div>
            <form [formGroup]="addBranchformdata" (ngSubmit)="submittBranch(addBranchformdata)">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-floating mb-3">
                            <input type="text" style="text-transform: capitalize;" autocomplete="off"
                                class="form-control input-radius">
                            <label> {{'region.branch.deviceUniqueId' | translate}}</label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-floating mb-3">
                            <input type="text" style="text-transform: capitalize;" autocomplete="off"
                                class="form-control input-radius">
                            <label> {{'common.identifier' | translate}}</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="button_div">
                            <button class="btn login-button-class text-uppercase"
                                [class.pointerNone]="_sharedSvc.loading"
                                [disabled]="_sharedSvc.loading">{{'region.branch.registerDevice' | translate}}</button>
                            <button class="btn login-button-class cancelBtn" (click)="d('Cross click')"
                                type="reset">{{'common.cancel' | translate}}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>
<!-- Assign Device to Branch Modal PopUp End -->
<!-- Assign Device to Branch Modal PopUp Begin -->
<ng-template id="display-modal" #bagListing let-c="close" let-d="dismiss">
    <div class="modal-body" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
        [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
        <div class="p-3 pb-0">
            <div class="row mb-4">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="text-center">
                        <h4> {{'region.branch.allBags' | translate}} </h4>
                    </div>
                    <div class="add-button" (click)="openModalAddBag(addBag,false)">
                        <i class="fa fa-plus"></i>
                    </div>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-sm-12 col-md-12 col-lg-12 loan-table">
                                <div class="row mb-3">
                                    <div class="col-sm-7 col-md-7 col-lg-8">
                                        <ng-container *ngIf="pageSize >= 10">
                                            <span>{{'common.show' | translate}}</span>
                                            <input name="entries" class="m-1 text-center"
                                                [value]="branchBagsList?.length" readonly
                                                style="width: 45px;">
                                            <span>{{'common.entries' | translate}}</span>
                                        </ng-container>
                                    </div>
                                    <div class="col-sm-5 col-md-5 col-lg-4">
                                        <div class="inside-icon">
                                            <i class="fa fa-search"></i>
                                            <input type="search" class="form-control search-input"
                                               [(ngModel)]="bagNumber"
                                               (ngModelChange)="findItem()"                                               
                                               placeholder="{{'common.search' | translate}}">
                                        </div>
                                    </div>
                                </div>
                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <td>{{'region.branch.bagNo' | translate}}</td>
                                    <td>{{'region.branch.bagType' | translate}}</td>
                                    <td>{{'region.branch.bagStatus' | translate}}</td>
                                    <td class="text-center">{{'common.action' | translate}}</td>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="!branchBagsList?.error && branchBagsListTablePG?.length > 0">
                                    <tr *ngFor="let list of branchBagsListTablePG">

                                        <td>{{list.bagNum}}</td>
                                        <td>{{list.bagTypKey === 2503 ? 'Branch Bag' : 'Shroff Bag'}}</td>
                                        <td>{{list.bagStsKey === 2500 ? 'Available' : list.bagStsKey === 2501 ? 'Used' :
                                            'Damaged'}}</td>
                                        <td class="text-center">
                                            <div class="action-inner" *ngIf="list.bagStsKey !== 2501">
                                                <i class="fa fa-pencil"
                                                    (click)="editModalBag(addBag,list.bagSeq , true)"></i>
                                                <i class="fa fa-trash" (click)="RemoveBag(list.bagSeq)"></i>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-container>
                                <ng-container *ngIf="branchBagsList?.error">
                                    <tr>
                                        <td colspan="7" class="text-center">{{ branchBagsList.error }}</td>
                                    </tr>
                                </ng-container>
                                
                            </tbody>
                        </table>
                        <div class="row g-0" *ngIf="pageSize > 0">
                                                <div class="col-sm-12 col-md-12 col-lg-6">
                                                    <span class="clr-9d9a9a">{{'common.showing' | translate}}
                                                        {{branchBagsListTablePG?.length}} {{'common.of' |
                                                        translate}}
                                                        {{collectionSize}} {{'common.entries' |
                                                        translate}}</span>
                                                </div>
                                                <div class="col-sm-12 col-md-12 col-lg-6">
                                                    <div class="float-right">
                                                        <ngb-pagination [(page)]="page"
                                                            [pageSize]="pageSize" [maxSize]="10"
                                                            ngDefaultControl
                                                            [collectionSize]="collectionSize"
                                                            [rotate]="true"
                                                            (pageChange)="pageChange()">
                                                            <ng-template ngbPaginationPrevious> {{'common.prev' |
                                                                translate}}</ng-template>
                                                            <ng-template ngbPaginationNext> {{'common.next' |
                                                                translate}}</ng-template>
                                                        </ngb-pagination>
                                                    </div>
                                                </div>
                                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!-- Assign Device to Branch Modal PopUp End -->




<!-- add Bag to Branch Modal PopUp Begin -->
<ng-template id="display-modal" #addBag let-c="close" let-d="dismiss">
    <div class="modal-body" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
        [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
        <div class="p-3 pb-0">
            <div class="row mb-4">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="text-center">
                        <h4>{{isEditBag === true ? this.translate.instant('common.edit') :
                            this.translate.instant('common.add')}} {{'common.bag' | translate}}</h4>
                    </div>

                </div>

            </div>
            <form [formGroup]="addBranchBagformdata" (ngSubmit)="submitBag(addBranchBagformdata)">
                <div class="row">
                    <div class="col-md-5">
                        <div class="form-floating mb-3">
                            <input formControlName="bagNum" type="number" style="text-transform: capitalize;"
                                autocomplete="off" class="form-control input-radius"
                                [class.is-invalid]="bagNum.invalid && (bagNum.dirty || bagNum.touched)">
                            <div *ngIf="bagNum.invalid && (bagNum.dirty || bagNum.touched)" class="invalid-feedback">
                                <div *ngIf="bagNum.errors?.['required']">
                                    {{'region.branch.bagNoRequire' | translate}}
                                </div>
                            </div>
                            <label> {{'region.branch.bagNo' | translate}} </label>
                        </div>
                    </div>
                    <div class="col-md-7"></div>
                    <div class="col-md-6">
                        <div class="form-floating mb-3">
                            <select class="form-control form-select input-radius" formControlName="bagTypKey"
                                [class.is-invalid]="bagTypKey.invalid && (bagTypKey.dirty || bagTypKey.touched)">
                                <option *ngFor="let item of _picklistService.BAG_TYPE_LIST; let i = index"
                                    [value]="item?.refCdSeq">
                                    {{ item.refCdDscr }}
                                </option>
                            </select>
                            <div *ngIf="bagTypKey.invalid && (bagTypKey.dirty || bagTypKey.touched)"
                                class="invalid-feedback">
                                <div *ngIf="bagTypKey.errors?.['required']">
                                    {{'region.branch.bagTypeRequire' | translate}}
                                </div>
                            </div>
                            <label>{{'region.branch.bagType' | translate}}</label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-floating mb-3">
                            <select class="form-control form-select input-radius" formControlName="bagStsKey"
                                [class.is-invalid]="bagStsKey.invalid && (bagStsKey.dirty || bagStsKey.touched)">
                                <option *ngFor="let item of _picklistService.BAG_STATUS_LIST; let i = index"
                                    [value]="item?.refCdSeq">
                                    {{ item.refCdDscr }}
                                </option>
                            </select>
                            <div *ngIf="bagStsKey.invalid && (bagStsKey.dirty || bagStsKey.touched)"
                                class="invalid-feedback">
                                <div *ngIf="bagStsKey.errors?.['required']">
                                    {{'region.branch.bagStatusRequire' | translate}}
                                </div>
                            </div>
                            <label> {{'region.branch.bagStatus' | translate}} </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="button_div">
                            <button class="btn login-button-class text-uppercase"
                                [class.pointerNone]="_sharedSvc.loading" [disabled]="_sharedSvc.loading">{{'common.save'
                                | translate}}</button>
                            <button class="btn login-button-class cancelBtn" (click)="d('Cross click')"
                                type="reset">{{'common.cancel' | translate}}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>



<!-- add Bag to Branch Modal PopUp End -->