import { Component, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SharedService } from "src/app/components/shared/service/shared.service";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
  NgForm,
} from "@angular/forms";

import { ClintOnboardingService } from "src/app/components/clint-onboarding/shared/service/clint-onboarding.service";
import { SetupService } from "../../shared/service/setup.service";
import { PicklistService } from "src/app/components/shared/pickList/picklist.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-area",
  templateUrl: "./area.component.html",
  styleUrls: ["./area.component.css"],
})
export class AreaComponent implements OnInit {
  loadingIndicator: boolean = false;
  rows: any;

  page: number = 5;
  pageSize: number = 5;
  collectionSize: number = 5;

  loginForm!: FormGroup;
  submitted = false;
  // currentDate: any = new Date().toISOString().split("T")[0];
  onBoardingTablePG: any;
  maskedCNIC: string;

  addAreaformdata: any;

  regionID: any;

  isEdit: boolean = false;
  singleRecord: any;
  selectedArea: any;
  areaData: any;
  isNewSelectedManager: boolean = true;
  selectedEMPFROMAPI: any;

  constructor(
    private modalService: NgbModal,
    public router: Router,
    public _sharedSvc: SharedService,
    public _clintOnboardingSvc: ClintOnboardingService,
    public _SetupService: SetupService,
    private formBuilder: FormBuilder,
    public _picklistService: PicklistService,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.addAreaformdata = this.formBuilder.group({
      areaName: ["", Validators.required],
      areaDescription: ["", Validators.required],
      areaStatusKey: ["", Validators.required],
    });
    this._picklistService.SHROFF_STATUS();
    this.regionID = sessionStorage.getItem("singleRegionID");
    this._SetupService.get_areas_list(sessionStorage.getItem("singleRegionID"));
    this._SetupService.get_all_emp_list();
  }

  get areaName() {
    return this.addAreaformdata.get("areaName");
  }
  get areaDescription() {
    return this.addAreaformdata.get("areaDescription");
  }
  get stsKey() {
    return this.addAreaformdata.get("areaStatusKey");
  }

  submitAreaForm(val) {
    if (this.addAreaformdata.invalid) {
      for (const control of Object.keys(this.addAreaformdata.controls)) {
        this.addAreaformdata.controls[control].markAsTouched();
      }
      return;
    }
    let data = {
      ...this.addAreaformdata.value,
      areaSequence: null,
      regionSequence: this.regionID,
      areaCode: "0",
      delFlg: 0,
    };
    if (this.isEdit) {
      data.areaSequence = this.singleRecord.areaSequence;
    }
    this._SetupService.setupAreaModel = data;
    this._SetupService.saveArea().subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.success(
            this.translate.instant("toast.area") +
            (this.isEdit === true
              ? this.translate.instant("toast.updated")
              : this.translate.instant("toast.added"))
          );
          this._sharedSvc.hideSpinner3();
          this.modalService.dismissAll();
          this._SetupService.get_areas_list(this.regionID);
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner3();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(
            ([key, value]: any, index: number) => {
              this._sharedSvc.error(value);
            }
          );
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    });
  }

  editArea(content: any, id: any, edit: any) {
    let list = this._SetupService.allAreasList;
    let record = list.find((x) => x.areaSequence === id);
    this.singleRecord = record;

    if (record) {
      let data = {
        areaName: record.areaName,
        areaDescription: record.areaDescription,
        areaStatusKey: record.areaStatusKey,
      };
      this.addAreaformdata.patchValue(data);
      this.openModal(content, edit);
    }
  }

  deleteRecord(id) {
    let list = this._SetupService.allAreasList;
    let record = list.find((x) => x.areaSequence === id);
    this.singleRecord = record;

    if (record) {
      let data = {
        ...record,
        delFlg: 1,
      };
      this._SetupService.setupAreaModel = data;
      this._SetupService.saveArea().subscribe({
        next: (response) => {
          if (response) {
            this._sharedSvc.success(
              this.translate.instant("toast.areaDeleted")
            );
            this._sharedSvc.hideSpinner3();
            this.modalService.dismissAll();
            this._SetupService.get_areas_list(this.regionID);
          }
        },
        error: (e) => {
          this._sharedSvc.hideSpinner3();
          if (e.status == 401) {
            this._sharedSvc.error(this._sharedSvc.authMsg);
            this._sharedSvc.logout();
            return;
          }
          if (e?.error) {
            Object.entries(e?.error).forEach(
              ([key, value]: any, index: number) => {
                this._sharedSvc.error(value);
              }
            );
          }
          if (e?.error?.errorMessage) {
            this._sharedSvc.error(e?.error?.error);
          }
        },
      });
    }
  }

  pageChange() {
    this.collectionSize = this._clintOnboardingSvc.onBoardingTable.length;
    this.onBoardingTablePG = this._clintOnboardingSvc.onBoardingTable.slice(
      (this.page - 1) * this.pageSize,
      (this.page - 1) * this.pageSize + this.pageSize
    );
    this.onBoardingTablePG;
  }

  openModal(content: any, edit: boolean) {
    localStorage.removeItem("selectedOrderData");
    this.isEdit = edit;
    this.modalService.open(content, {
      // backdrop: "static",
      // keyboard: false,
      windowClass: "popupWidth800",
      // backdropClass: 'light-blue-backdrop',
      centered: true,
    });
  }

  openModalEmployeeAssignment(content: any, data: any) {
    localStorage.removeItem("selectedOrderData");
    
    this.areaData = data;
    this.get_area_emp_list(data.areaSequence);
    this.modalService
      .open(content, {
        // backdrop: "static",
        // keyboard: false,
        windowClass: "popupWidth800",
        // backdropClass: 'light-blue-backdrop',
        centered: true,
      })
      .result.then(
        (result) => {
          // Your logic when modal is closed
          this.selectedArea = null;
        },
        (reason) => {
          // Your logic when modal is dismissed
          this.selectedArea = null;
        }
      );
  }

  navigate() {
    this.router.navigate(["/disbursement-form"]);
  }

  regionFn() {
    this.router.navigate(["/setup-region"]);
  }

  branchFn(areaSeq) {
    sessionStorage.setItem("singleAreaID", areaSeq.areaSequence);

    this.router.navigate(["/setup-branch"]);
  }
  submittBranch() {
    this._SetupService.saveCity().subscribe((data) => {
      alert("area added");
      // console.log(data)
    });
  }
  onSelectEmployee(event: any) {
    this.selectedArea = event.target.value;
  }
  clearEmployeer() {
    // this.selectedArea = null;
    this.removeAreaManager();
  }

  get_area_emp_list(key: any) {
    this._sharedSvc.showSpinner();
    this._SetupService.getAreaEmpList(key).subscribe((res) => {
      
      this._sharedSvc.hideSpinner();

      //@ts-ignore
      if (res.error) {
        //@ts-ignore
        this._sharedSvc.error(res.error);
        this.isNewSelectedManager = true;

        return;
      }
      //@ts-ignore
      this.selectedArea = res[0].empSeq;
      this.selectedEMPFROMAPI = res[0];
      this.isNewSelectedManager = false;
    });
  }



  SubmitAreaManager(){    
    if(this.selectedArea){
      let record = this._SetupService.allEMPLIST.find(x=> x.empSeq === Number(this.selectedArea));
      if(record){
        let data = {
          areaEmployeeSequence: null,
          areaSequence: this.areaData.areaSequence,
          coveringEmployeeSequence: 0,
          employeeSequence: Number(record.empSeq),
          delFlg: false,
        };
        this._SetupService.setupAreaEMPModel = data;
        this._SetupService.saveAreaEMP().subscribe({
          next: (response) => {
            if (response) {
              this._sharedSvc.success(this.translate.instant('toast.employeeAssign'));
              this._sharedSvc.hideSpinner3();
              this.modalService.dismissAll();
            }
          },
          error: (e) => {
            this._sharedSvc.hideSpinner3();
            if (e.status == 401) {
              this._sharedSvc.error(this._sharedSvc.authMsg);
              this._sharedSvc.logout();
              return;
            }
            if (e?.error) {
              Object.entries(e?.error).forEach(
                ([key, value]: any, index: number) => {
                  this._sharedSvc.error(value);
                }
              );
            }
            if (e?.error?.errorMessage) {
              this._sharedSvc.error(e?.error?.error);
            }
          },
        });
      }
    } else {
      this._sharedSvc.error(this.translate.instant('toast.selectAreaFirst'));
    }
  }
  removeAreaManager(){
    if(this.selectedArea && this.isNewSelectedManager === false){
      let record = this._SetupService.allEMPLIST.find(x=> x.empSeq === Number(this.selectedArea));
      
      if(record){
        let data = {
          areaSequence: this.areaData.areaSequence,
          areaEmployeeSequence: this.selectedEMPFROMAPI.areaEmpSeq,
          coveringEmployeeSequence: null,
          employeeSequence: Number(record.empSeq),
          delFlg: true,
        };
        this._SetupService.setupAreaEMPModel = data;
        this._SetupService.saveAreaEMP().subscribe({
          next: (response) => {
            if (response) {
              this._sharedSvc.success(this.translate.instant('toast.employeeRemoved'));
              this._sharedSvc.hideSpinner3();
              this.modalService.dismissAll();
              this.selectedArea = null;
            }
          },
          error: (e) => {
            this._sharedSvc.hideSpinner3();
            if (e.status == 401) {
              this._sharedSvc.error(this._sharedSvc.authMsg);
              this._sharedSvc.logout();
              return;
            }
            if (e?.error) {
              Object.entries(e?.error).forEach(
                ([key, value]: any, index: number) => {
                  this._sharedSvc.error(value);
                }
              );
            }
            if (e?.error?.errorMessage) {
              this._sharedSvc.error(e?.error?.error);
            }
          },
        });
      }
    } else {
      this.selectedArea = null;
    }
  }
}
