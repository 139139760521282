import { Component, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SharedService } from "../../shared/service/shared.service";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
  NgForm,
} from "@angular/forms";
import { SetupService } from "../shared/service/setup.service";
import { PicklistService } from "../../shared/pickList/picklist.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-comman-codes",
  templateUrl: "./comman-codes.component.html",
  styleUrls: ["./comman-codes.component.css"],
})
export class CommanCodesComponent implements OnInit {
  loadingIndicator: boolean = false;
  rows: any;
  // currentDate: any = new Date().toISOString().split("T")[0];
  refCdGrpTablePG: any;
  maskedCNIC: string;
  addSetupGrpformData: any;
  singleRecord: any;
  isEdit: boolean;

  constructor(
    private modalService: NgbModal,
    public router: Router,
    public _sharedSvc: SharedService,
    public _SetupService: SetupService,
    private formBuilder: FormBuilder,
    public _picklistService: PicklistService,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.createSetupGrpForm();
    this._SetupService.get_ref_cd_grp_list();
    this._picklistService.WORK_STATUS();
  }
  createSetupGrpForm() {
    this.addSetupGrpformData = this.formBuilder.group({
      grpName: ["", Validators.required],
      grpDscr: ["", Validators.required],
      grpActvFlg: ["", Validators.required],
    });
  }
  get grpName() {
    return this.addSetupGrpformData.get("grpName");
  }
  get grpDscr() {
    return this.addSetupGrpformData.get("grpDscr");
  }
  get grpActvFlg() {
    return this.addSetupGrpformData.get("grpActvFlg");
  }

  submitSetupGrpForm(val) {
    if (this.addSetupGrpformData.invalid) {
      for (const control of Object.keys(this.addSetupGrpformData.controls)) {
        this.addSetupGrpformData.controls[control].markAsTouched();
      }
      return;
    }
    let data = {
      tntKey: 1,
      ...this.addSetupGrpformData.value,
      grpSeq: null,
      delFlg: 0,
    };
    data.grpActvFlg = data.grpActvFlg === "true" ? 1 : 0;
    if (this.isEdit) {
      data.grpSeq = this.singleRecord.grpSeq;
      data.grpId = this.singleRecord.grpId;
    }
    this._SetupService.setupGroupModel = data;
    this._sharedSvc.showSpinner3();
    this._SetupService.saveRefCdGroup().subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.success(
            this.translate.instant("toast.sector") +
            (this.isEdit === true
              ? this.translate.instant("toast.updated")
              : this.translate.instant("toast.added"))
          );
          this._sharedSvc.hideSpinner3();
          this.modalService.dismissAll();
          this._SetupService.get_ref_cd_grp_list();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner3();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(
            ([key, value]: any, index: number) => {
              this._sharedSvc.error(value);
            }
          );
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    });
  }

  editSetupGrp(content: any, id: any, edit: any) {
    let list = this._SetupService.refCdGrpList;
    let record = list.find((x) => x.grpSeq === id);
    this.singleRecord = record;
    if (record) {
      let data = {
        grpName: record.grpName,
        grpDscr: record.grpDscr,
        grpActvFlg: record.grpActvFlg === true ? this.translate.instant('toast.true') : this.translate.instant('toast.false'),
      };
      this.addSetupGrpformData.patchValue(data);
      this.openModal(content, edit);
    }
  }

  deleteRecord(id) {
    let list = this._SetupService.refCdGrpList;
    let record = list.find((x) => x.grpSeq === id);
    this.singleRecord = record;

    if (record) {
      let data = {
        ...record,
        delFlg: 1,
      };
      this._sharedSvc.showSpinner3();
      this._SetupService.setupGroupModel = data;
      this._SetupService.saveRefCdGroup().subscribe({
        next: (response) => {
          if (response) {
            this._sharedSvc.success(
              this.translate.instant("commonCodes.groupDeletedAlert")
            );
            this._sharedSvc.hideSpinner3();
            this.modalService.dismissAll();
            this._SetupService.get_ref_cd_grp_list();
          }
        },
        error: (e) => {
          this._sharedSvc.hideSpinner3();
          if (e.status == 401) {
            this._sharedSvc.error(this._sharedSvc.authMsg);
            this._sharedSvc.logout();
            return;
          }
          if (e?.error) {
            Object.entries(e?.error).forEach(
              ([key, value]: any, index: number) => {
                this._sharedSvc.error(value);
              }
            );
          }
          if (e?.error?.errorMessage) {
            this._sharedSvc.error(e?.error?.error);
          }
        },
      });
    }
  }

  openModal(content: any, edit: boolean) {
    this.isEdit = edit;
    this.modalService.open(content, {
      // backdrop: "static",
      // keyboard: false,
      windowClass: "popupWidth800",
      // backdropClass: 'light-blue-backdrop',
      centered: true,
    });
  }

  navToRefCdVal(SingleRec: any) {
    sessionStorage.setItem("singleGrpSeqID", SingleRec.grpSeq);
    this.navigate();
  }
  navigate() {
    this.router.navigate(["/common-code-values"]);
  }
}
