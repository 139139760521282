<div class="navbarDiv">
    <div class="row ">
        <div class="col-sm-6 col-md-6 col-lg-6 ">
            <div class="display-flex">
                <img src="../../../../assets/images/nav-logo.png">
                <h5 class="navBar-head ms-2">{{'welcomeTo' | translate}} <span>{{'goldfin' |
                        translate}}</span></h5>
            </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6">
            <div class="d-flex align-items-center justify-content-end">
                <div class="form-floating w-50 me-2 mt-2">
                    <select class="form-control form-select input-radius" #selectedLang
                        (change)="switchLang(selectedLang.value)" [value]="currentLang">
                        <option value="en">English</option>
                        <option value="ar">Arabic</option>
                        <option value="ur">Urdu</option>
                    </select>
                    <label>Language</label>
                </div>
                <div class="display-flex welcome-logout mt-3">
                    <img src="../assets/images/mrz.png" width="40">
                    <h5 class="ms-2 me-2 pointer" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">{{jwtInfo?.preferred_username | titlecase}}</h5>
                    <i class="fa fa-caret-down marginAuto pointer" id="dropdownMenuButton" data-bs-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false"></i>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item pointer" (click)="authService.logout()"> <i
                                class="fa fa-sign-out me-2"></i> {{'common.logout' | translate}} </a>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>