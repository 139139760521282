<section class="p-3">
  <div class="row mb-3">
    <div class="col-sm-12 col-md-12 col-lg-12">
      <h4 class="text-center">
        {{ "addProduct.documentAssignment" | translate }}
      </h4>
    </div>
  </div>
  <h5 class="mb-4">
    {{ "documentAssignmentTab.clientOnboardingDocs" | translate }}
  </h5>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-5">
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <div class="checkbox-wrapper custom-checkbox mb-4">
            <input
              id="cnic-front"
              type="checkbox"
              class="form-check-input pointer"
            />
            <label for="cnic-front" class="h6 ms-2 cursor-pointer">{{
              "documentAssignmentTab.cnicFront" | translate
            }}</label>
          </div>
        </div>
        <div>
          <div class="checkbox-wrapper custom-checkbox mb-4">
            <input
              id="cnic-front-req"
              type="checkbox"
              class="form-check-input pointer"
            />
            <label for="cnic-front-req" class="h6 ms-2 cursor-pointer"
              >{{ "common.required" | translate }}
              <span class="text-danger">*</span></label
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-5">
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <div class="checkbox-wrapper custom-checkbox mb-4">
            <input
              id="cnic-back"
              type="checkbox"
              class="form-check-input pointer"
            />
            <label for="cnic-back" class="h6 ms-2 cursor-pointer">{{
              "documentAssignmentTab.cnicBack" | translate
            }}</label>
          </div>
        </div>
        <div>
          <div class="checkbox-wrapper custom-checkbox mb-4">
            <input
              id="cnic-back-req"
              type="checkbox"
              class="form-check-input pointer"
            />
            <label for="cnic-back-req" class="h6 ms-2 cursor-pointer"
              >{{ "common.required" | translate }}
              <span class="text-danger">*</span></label
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-5">
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <div class="checkbox-wrapper custom-checkbox mb-4">
            <input
              id="client-picture"
              type="checkbox"
              class="form-check-input pointer"
            />
            <label for="client-picture" class="h6 ms-2 cursor-pointer">{{
              "clientOnboarding.clientDocTab.clientPicture" | translate
            }}</label>
          </div>
        </div>
        <div>
          <div class="checkbox-wrapper custom-checkbox mb-4">
            <input
              id="client-picture-req"
              type="checkbox"
              class="form-check-input pointer"
            />
            <label for="client-picture-req" class="h6 ms-2 cursor-pointer"
              >{{ "common.required" | translate }}
              <span class="text-danger">*</span></label
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-5">
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <div class="checkbox-wrapper custom-checkbox mb-4">
            <input
              id="tasdeeq-consent-form"
              type="checkbox"
              class="form-check-input pointer"
            />
            <label for="tasdeeq-consent-form" class="h6 ms-2 cursor-pointer">{{
              "clientOnboarding.clientDocTab.tasdeeqConsentForm" | translate
            }}</label>
          </div>
        </div>
        <div>
          <div class="checkbox-wrapper custom-checkbox mb-4">
            <input
              id="tasdeeq-consent-form-req"
              type="checkbox"
              class="form-check-input pointer"
            />
            <label for="tasdeeq-consent-form-req" class="h6 ms-2 cursor-pointer"
              >{{ "common.required" | translate }}
              <span class="text-danger">*</span></label
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <h5 class="mb-4">
    {{ "documentAssignmentTab.individualCreditApplicationDocs" | translate }}
  </h5>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-5">
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <div class="checkbox-wrapper custom-checkbox mb-4">
            <input
              id="residence-utility-bill"
              type="checkbox"
              class="form-check-input pointer"
            />
            <label
              for="residence-utility-bill"
              class="h6 ms-2 cursor-pointer"
              >{{ "clientCreditDoc.residenceUtilityBill" | translate }}</label
            >
          </div>
        </div>
        <div>
          <div class="checkbox-wrapper custom-checkbox mb-4">
            <input
              id="residence-utility-bill-req"
              type="checkbox"
              class="form-check-input pointer"
            />
            <label
              for="residence-utility-bill-req"
              class="h6 ms-2 cursor-pointer"
              >{{ "common.required" | translate }}
              <span class="text-danger">*</span></label
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-5">
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <div class="checkbox-wrapper custom-checkbox mb-4">
            <input
              id="business-utility-bill"
              type="checkbox"
              class="form-check-input pointer"
            />
            <label for="business-utility-bill" class="h6 ms-2 cursor-pointer">{{
              "clientCreditDoc.businessUtilityBill" | translate
            }}</label>
          </div>
        </div>
        <div>
          <div class="checkbox-wrapper custom-checkbox mb-4">
            <input
              id="business-utility-bill-req"
              type="checkbox"
              class="form-check-input pointer"
            />
            <label
              for="business-utility-bill-req"
              class="h6 ms-2 cursor-pointer"
              >{{ "common.required" | translate }}
              <span class="text-danger">*</span></label
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-5">
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <div class="checkbox-wrapper custom-checkbox mb-4">
            <input
              id="finance-agreement"
              type="checkbox"
              class="form-check-input pointer"
            />
            <label for="finance-agreement" class="h6 ms-2 cursor-pointer">{{
              "clientCreditDoc.financeAgreement1" | translate
            }}</label>
          </div>
        </div>
        <div>
          <div class="checkbox-wrapper custom-checkbox mb-4">
            <input
              id="finance-agreement-req"
              type="checkbox"
              class="form-check-input pointer"
            />
            <label for="finance-agreement-req" class="h6 ms-2 cursor-pointer"
              >{{ "common.required" | translate }}
              <span class="text-danger">*</span></label
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-5">
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <div class="checkbox-wrapper custom-checkbox mb-4">
            <input
              id="pledge-letter"
              type="checkbox"
              class="form-check-input pointer"
            />
            <label for="pledge-letter" class="h6 ms-2 cursor-pointer">{{
              "clientCreditDoc.pledgeLetter" | translate
            }}</label>
          </div>
        </div>
        <div>
          <div class="checkbox-wrapper custom-checkbox mb-4">
            <input
              id="pledge-letter-req"
              type="checkbox"
              class="form-check-input pointer"
            />
            <label for="pledge-letter-req" class="h6 ms-2 cursor-pointer"
              >{{ "common.required" | translate }}
              <span class="text-danger">*</span></label
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-5">
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <div class="checkbox-wrapper custom-checkbox mb-4">
            <input
              id="facility-letter"
              type="checkbox"
              class="form-check-input pointer"
            />
            <label for="facility-letter" class="h6 ms-2 cursor-pointer">{{
              "clientCreditDoc.facilityLetter" | translate
            }}</label>
          </div>
        </div>
        <div>
          <div class="checkbox-wrapper custom-checkbox mb-4">
            <input
              id="facility-letter-req"
              type="checkbox"
              class="form-check-input pointer"
            />
            <label for="facility-letter-req" class="h6 ms-2 cursor-pointer"
              >{{ "common.required" | translate }}
              <span class="text-danger">*</span></label
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-5">
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <div class="checkbox-wrapper custom-checkbox mb-4">
            <input
              id="business-proof"
              type="checkbox"
              class="form-check-input pointer"
            />
            <label for="business-proof" class="h6 ms-2 cursor-pointer">{{
              "clientCreditDoc.businessProof" | translate
            }}</label>
          </div>
        </div>
        <div>
          <div class="checkbox-wrapper custom-checkbox mb-4">
            <input
              id="business-proof-req"
              type="checkbox"
              class="form-check-input pointer"
            />
            <label for="business-proof-req" class="h6 ms-2 cursor-pointer"
              >{{ "common.required" | translate }}
              <span class="text-danger">*</span></label
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <h5 class="mb-4">{{ "documentAssignmentTab.shroffDocs" | translate }}</h5>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-5">
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <div class="checkbox-wrapper custom-checkbox mb-4">
            <input
              id="shroff-cnic-front"
              type="checkbox"
              class="form-check-input pointer"
            />
            <label for="shroff-cnic-front" class="h6 ms-2 cursor-pointer">{{
              "documentAssignmentTab.cnicFront" | translate
            }}</label>
          </div>
        </div>
        <div>
          <div class="checkbox-wrapper custom-checkbox mb-4">
            <input
              id="shroff-cnic-front-req"
              type="checkbox"
              class="form-check-input pointer"
            />
            <label for="shroff-cnic-front-req" class="h6 ms-2 cursor-pointer"
              >{{ "common.required" | translate }}
              <span class="text-danger">*</span></label
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-5">
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <div class="checkbox-wrapper custom-checkbox mb-4">
            <input
              id="shroff-cnic-back"
              type="checkbox"
              class="form-check-input pointer"
            />
            <label for="shroff-cnic-back" class="h6 ms-2 cursor-pointer">{{
              "documentAssignmentTab.cnicBack" | translate
            }}</label>
          </div>
        </div>
        <div>
          <div class="checkbox-wrapper custom-checkbox mb-4">
            <input
              id="shroff-cnic-back-req"
              type="checkbox"
              class="form-check-input pointer"
            />
            <label for="shroff-cnic-back-req" class="h6 ms-2 cursor-pointer"
              >{{ "common.required" | translate }}
              <span class="text-danger">*</span></label
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-5">
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <div class="checkbox-wrapper custom-checkbox mb-4">
            <input
              id="certificate"
              type="checkbox"
              class="form-check-input pointer"
            />
            <label for="certificate" class="h6 ms-2 cursor-pointer">{{
              "common.certificate" | translate
            }}</label>
          </div>
        </div>
        <div>
          <div class="checkbox-wrapper custom-checkbox mb-4">
            <input
              id="certificate-req"
              type="checkbox"
              class="form-check-input pointer"
            />
            <label for="certificate-req" class="h6 ms-2 cursor-pointer"
              >{{ "common.required" | translate }}
              <span class="text-danger">*</span></label
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-5">
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <div class="checkbox-wrapper custom-checkbox mb-4">
            <input
              id="verfication"
              type="checkbox"
              class="form-check-input pointer"
            />
            <label for="verfication" class="h6 ms-2 cursor-pointer">{{
              "common.verfication" | translate
            }}</label>
          </div>
        </div>
        <div>
          <div class="checkbox-wrapper custom-checkbox mb-4">
            <input
              id="verfication-req"
              type="checkbox"
              class="form-check-input pointer"
            />
            <label for="verfication-req" class="h6 ms-2 cursor-pointer"
              >{{ "common.required" | translate }}
              <span class="text-danger">*</span></label
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-5">
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <div class="checkbox-wrapper custom-checkbox mb-4">
            <input
              id="agreement"
              type="checkbox"
              class="form-check-input pointer"
            />
            <label for="agreement" class="h6 ms-2 cursor-pointer">{{
              "common.agreement" | translate
            }}</label>
          </div>
        </div>
        <div>
          <div class="checkbox-wrapper custom-checkbox mb-4">
            <input
              id="agreement-req"
              type="checkbox"
              class="form-check-input pointer"
            />
            <label for="agreement-req" class="h6 ms-2 cursor-pointer"
              >{{ "common.required" | translate }}
              <span class="text-danger">*</span></label
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-5">
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <div class="checkbox-wrapper custom-checkbox mb-4">
            <input
              id="address"
              type="checkbox"
              class="form-check-input pointer"
            />
            <label for="address" class="h6 ms-2 cursor-pointer">{{
              "common.address" | translate
            }}</label>
          </div>
        </div>
        <div>
          <div class="checkbox-wrapper custom-checkbox mb-4">
            <input
              id="address-req"
              type="checkbox"
              class="form-check-input pointer"
            />
            <label for="address-req" class="h6 ms-2 cursor-pointer"
              >{{ "common.required" | translate }}
              <span class="text-danger">*</span></label
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="row mb-3">
  <div class="col-sm-12 col-md-12 col-lg-12">
    <div class="button_div mt-3">
      <button
        class="btn login-button-class"
        type="button"
        (click)="moveToNextTab()"
      >
        {{ "common.continue" | translate }}
      </button>
      <button
        class="btn login-button-class secondaryBtn"
        [disabled]="_sharedSvc.loading"
        [class.pointerNone]="_sharedSvc.loading"
      >
        {{ "common.save" | translate }}
      </button>
    </div>
  </div>
</div>
