import { Component } from '@angular/core';
import { SetupService } from '../../../shared/service/setup.service';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'src/app/components/shared/service/shared.service';

@Component({
  selector: 'app-assignment-sequence',
  templateUrl: './assignment-sequence.component.html',
  styleUrls: ['./assignment-sequence.component.css']
})
export class AssignmentSequenceComponent {
  serviceChargesOption: any = [{ value: 1, label: '1' }, { value: 2, label: '2' }, { value: 3, label: '3' }, { value: 4, label: '4' }, { value: 5, label: '5' }, { value: 6, label: '6' }];
  principleAmountOption: any = [{ value: 1, label: '1' }, { value: 2, label: '2' }, { value: 3, label: '3' }, { value: 4, label: '4' }, { value: 5, label: '5' }, { value: 6, label: '6' }];
  kszbOption: any = [{ value: 1, label: '1' }, { value: 2, label: '2' }, { value: 3, label: '3' }, { value: 4, label: '4' }, { value: 5, label: '5' }, { value: 6, label: '6' }];
  orderFormData: any

  constructor(
    public _sharedSvc: SharedService,
    private _SetupService: SetupService,
    private formBuilder: FormBuilder,
    private nav: NgbNav,
  ) { }

  ngOnInit(): void {
    this.orderFormData = this.formBuilder.group({
      serviceCharges: ['', Validators.required],
      principleAmount: ['', Validators.required],
      kszb: ['', Validators.required],
    })
  }

  get serviceCharges() { return this.orderFormData.get('serviceCharges') }
  get principleAmount() { return this.orderFormData.get('principleAmount') }
  get kszb() { return this.orderFormData.get('kszb') }


  changeServiceCharges(event: any) {
    let value = event.target.value;
    if (value) {
      this.principleAmountOption = this.principleAmountOption.filter(x => x.value != value);
      this.kszbOption = this.kszbOption.filter(x => x.value != value);
    }
  }
  principleAmountChange(event: any) {
    let value = event.target.value;
    if (value) {
      this.serviceChargesOption = this.serviceChargesOption.filter(x => x.value != value);
      this.kszbOption = this.kszbOption.filter(x => x.value != value);
    }
  }
  kszbChange(event: any) {
    let value = event.target.value;
    if (value) {
      this.serviceChargesOption = this.serviceChargesOption.filter(x => x.value != value);
      this.principleAmountOption = this.principleAmountOption.filter(x => x.value != value);
    }
  }


  submitSequence() {
    this._SetupService.saveRefCdGroup().subscribe(data => {
      alert('seq submitted')
    })
  }

  moveToNextTab() {
    if (this.nav) {
      this._sharedSvc.showSpinner3();
      const currentTab = this.nav.activeId || 1;
      const nextTab = currentTab < this.nav.items.length ? currentTab + 1 : 1;
      this.nav.select(nextTab);
      this._sharedSvc.hideSpinner3();
    }
  }
}
