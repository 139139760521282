import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from '../../shared/service/shared.service';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { FiniancialInfoService } from './shared/service/finiancial-info.service';
import { SharedPopupService } from '../../shared/service/shared-popup.service';
import { PicklistService } from '../../shared/pickList/picklist.service';
import { SaveClntExpnsDtl, SaveClntIncmDtl } from './shared/model/financial-info';
import { OtherOutstandingService } from '../other-outstanding/shared/service/other-outstanding.service';
import { RoutingConditionsService } from '../shared/service/routing-conditions.service';
import { TranslateService } from '@ngx-translate/core';

// interface IIncome {
//   incmDtlSeq: number;
//   clntSeq: number;
//   incmCtgryKey: number;
//   incmAmt: number;
//   incmTypKey: number;
//   qnty: number;
//   frqncy: number;
//   cmnt: string;
//   delFlg: boolean
// }
// interface IExpense {
//   expDtlSeq: number;
//   clntSeq: number;
//   expCtgryKey: number;
//   expAmt: number;
//   expTypKey: number;
//   delFlg: boolean;
// }

@Component({
  selector: 'app-financial-info',
  templateUrl: './financial-info.component.html',
  styleUrls: ['./financial-info.component.css']
})

export class FinancialInfoComponent implements OnInit {

  incomeForm: FormGroup;
  // income: IIncome;
  expenseForm: FormGroup;
  // expense: IExpense;
  primaryInc: boolean = true;
  secondaryInc: boolean = false;
  houseHoldExpenses: boolean = false;
  incmCtgryKey: number = 1;
  saveCnicData: any;
  modelHeading: any;
  // savePrimaryDataModel:any = [];
  saveSecondaryDataData: boolean = false;
  jwt: any
  index: number = 0
  indexHH: number = 0

  constructor(
    public router: Router,
    public _sharedSvc: SharedService,
    public _finiancialInfoSvc: FiniancialInfoService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    public _sharedPopupSvc: SharedPopupService,
    public _picklistService: PicklistService,
    public route: ActivatedRoute,
    public _otherOutstandingSvc: OtherOutstandingService,
    public _conditionsService: RoutingConditionsService,
    public translate: TranslateService
  ) {
    // this.income = {} as IIncome;
    // this.expense = {} as IExpense;
  }

  ngOnInit(): void {
    this._finiancialInfoSvc.totalPrimaryAmt = 0;
    this._finiancialInfoSvc.totalSecondaryAmt = 0;
    if (localStorage.getItem("readOnlyView_Client")) {
      this._conditionsService.readOnlyView_Client = true;
    }
    else {
      this._conditionsService.readOnlyView_Client = false;
    }
    if (localStorage.getItem('save_ClientModel')) {
      this.saveCnicData = localStorage.getItem('save_ClientModel');
    }
    if (localStorage.getItem('SaveCnicData')) {
      this.saveCnicData = localStorage.getItem('SaveCnicData');
    }
    this.saveCnicData = JSON.parse(this.saveCnicData);
    this.validationForm();
    this.pickListFn();
    this.calculationFn();
  }

  pickListFn() {
    this._picklistService.INCOME_TYPE();
    this._picklistService.BUSINESS_RUNNER();
    this._picklistService.HOUSEHOLD_EXPENSES();
  }

  calculationFn() {
    this._finiancialInfoSvc.InItGetClntAllIncmDtlSeq(this.saveCnicData?.clntSeq, 1);
    this._finiancialInfoSvc.InItGetClntAllExpnsDtlSeq(this.saveCnicData?.clntSeq, 2);
    this._otherOutstandingSvc.InItGetAllClientOtherOutstanding(this.saveCnicData?.clntSeq);
  }

  setDefault() {
    this.jwt = sessionStorage.getItem('JWT');
    this.jwt = JSON.parse(this.jwt);
    let date = new Date()
    this._finiancialInfoSvc.saveClntIncmDtlModel = {
      clntSeq: this.saveCnicData.clntSeq,
      incmDtlSeq: null,
      incmCtgryKey: this.incmCtgryKey,
      incmAmt: null,
      incmTypKey: null,
      qnty: null,
      frqncy: null,
      avgMnthlyIncm: 0,
      cmnt: '',
      crtdBy: this.jwt?.preferred_username,
      crtdDt: date,
      lastUpdBy: this.jwt?.preferred_username,
      lastUpdDt: date,
      delFlg: false
    };
  }

  openModal(content: any, h4: any, incmCtgryKey: any) {
    // this._finiancialInfoSvc.saveClntIncmDtlModel = new SaveClntIncmDtl();
    // this.income = new SaveClntIncmDtl();
    // this._finiancialInfoSvc.saveClntExpnsDtlModel = new SaveClntExpnsDtl();
    // this.expense = new SaveClntExpnsDtl();
    this.validationForm();
    this.setDefault();
    this.setExpenseValues();
    this.modelHeading = h4;
    this._finiancialInfoSvc.edit = false;
    this._sharedSvc.msg = "Record added";
    this.incmCtgryKey = incmCtgryKey;
    // this.incomeForm.reset(this.incomeForm.value);
    // this.expenseForm.reset(this.expenseForm.value);
    this.modalService.open(content, {
      windowClass: "popupWidth800",
      backdropClass: 'light-blue-backdrop',
      centered: true
    });
    this._finiancialInfoSvc.dropdowns(incmCtgryKey);
  }

  dismissModal() {
    // this.incomeForm?.reset(this.incomeForm.value);
    this.modalService.dismissAll();
    // if (this.incmCtgryKey) {
    //   this._finiancialInfoSvc.InItGetClntAllIncmDtlSeq(this.saveCnicData.clntSeq, this.incmCtgryKey);
    // }
    // this._finiancialInfoSvc.InItGetClntAllExpnsDtlSeq(this.saveCnicData.clntSeq, 2);
  }

  showBtn(B1: any, B2: any, B3: any, incmCtgryKey?: any) {
    this.incmCtgryKey = incmCtgryKey;
    this._finiancialInfoSvc.totalIncmAmt = 0;
    this.primaryInc = B1;
    this.secondaryInc = B2;
    this.houseHoldExpenses = B3;
    if (B1) {
      // this._finiancialInfoSvc.saveClntIncmDtlModel = new SaveClntIncmDtl();
      // this.incomeForm?.reset(this.incomeForm.value);
      this.validationForm();
      this.setDefault();
      this._finiancialInfoSvc.InItGetClntAllIncmDtlSeq(this.saveCnicData.clntSeq, 1);
    }
    if (B2) {
      // this._finiancialInfoSvc.saveClntIncmDtlModel = new SaveClntIncmDtl();
      // this.incomeForm?.reset(this.incomeForm.value);
      this.validationForm();
      this.setDefault();
      this._finiancialInfoSvc.InItGetClntAllIncmDtlSeq(this.saveCnicData.clntSeq, 2);
    }
    if (B3) {
      // this._finiancialInfoSvc.saveClntExpnsDtlModel = new SaveClntExpnsDtl();
      // this.expenseForm?.reset(this.expenseForm.value);
      this.validationForm();
      this.setExpenseValues();
      // this._otherOutstandingSvc.InItGetAllClientOtherOutstanding(this.saveCnicData.clntSeq);
      this._finiancialInfoSvc.InItGetClntAllExpnsDtlSeq(this.saveCnicData.clntSeq, 2);
    }
  }

  editIncome(content: any, data: any, name: any, h4: any, index: any) {
    this.index = index;
    this.modelHeading = h4;
    this._finiancialInfoSvc.dropdownsDef(data, this.incmCtgryKey);
    this._finiancialInfoSvc.edit = true;
    this._sharedSvc.msg = this.translate.instant('toast.recordUpdated');
    this.modalService.open(content, {
      windowClass: "popupWidth1k",
      backdropClass: 'light-blue-backdrop',
      centered: true
    });
    if (name == 'primary') {
      this._finiancialInfoSvc.saveClntIncmDtlModel = data;
      this.setValueToForm1();
      // this._conditionsService.savePrimaryDataData = true;
      // this._conditionsService.savePrimaryDataDataFn();
    }
    if (name == 'secondary') {
      this._finiancialInfoSvc.saveClntIncmDtlModel = data;
      this.setValueToForm1();
      // this._conditionsService.saveSecondaryData = true;
      // this._conditionsService.saveSecondaryDataFn();
    }
  }

  onUpdatePrimaryData() {
    this.setValueToModel1();
    this._finiancialInfoSvc.getClntAllSPriIncmDtlSeqPG[this.index] = this._finiancialInfoSvc.saveClntIncmDtlModel;
    this._conditionsService.savePrimaryDataData = true;
    this._conditionsService.savePrimaryDataDataFn();
    this.calTAmount(1);
    this.dismissModal();
  }

  onUpdateSecondaryData() {
    this.setValueToModel1();
    this._finiancialInfoSvc.getClntAllSIncmDtlSeqPG[this.index] = this._finiancialInfoSvc.saveClntIncmDtlModel;
    this._conditionsService.saveSecondaryData = true;
    this._conditionsService.saveSecondaryDataFn();
    this.calTAmount(2);
    this.dismissModal();
  }

  DeletePopup_Inc(data: any, name: any, index: any) {

    this._sharedPopupSvc.popup("", this.translate.instant('common.areSelectRecordDelete'), "", this.translate.instant('common.delete'), this.translate.instant('common.cancelSmall'), null, this.translate.instant('common.incomeAmount') + " : " + data?.incmAmt).then((confirmed: any) => {
      if (confirmed) {
        // Delete function call

        this._sharedSvc.msg = "Record deleted";
        if (name == 'primary') {
          this._finiancialInfoSvc.getClntAllSPriIncmDtlSeqPG[index].delFlg = true;
          if (data.incmDtlSeq == null) {
            this._finiancialInfoSvc.getClntAllSPriIncmDtlSeqPG.splice(index, 1);
          }
          if (this._finiancialInfoSvc.getClntAllSPriIncmDtlSeqPG?.length == 0) {
            this._conditionsService.savePrimaryDataData = false;
            this._conditionsService.savePrimaryDataDataFn();
            this._finiancialInfoSvc.totalPrimaryAmt = 0;
            localStorage.setItem('totalPrimaryAmt', this._finiancialInfoSvc.totalPrimaryAmt)

            return;
          }
          this._finiancialInfoSvc.getAllPriIncome = this._finiancialInfoSvc.getClntAllSPriIncmDtlSeqPG;
          this._conditionsService.savePrimaryDataData = true;
          this._conditionsService.savePrimaryDataDataFn();
          // this.calTAmount(1);
          this._finiancialInfoSvc.calculationOfIncomeAmounts(1, this._finiancialInfoSvc.getAllPriIncome);
        }
        if (name == 'secondary') {
          this._finiancialInfoSvc.getClntAllSIncmDtlSeqPG[index].delFlg = true;
          if (data.incmDtlSeq == null) {
            this._finiancialInfoSvc.getClntAllSIncmDtlSeqPG.splice(index, 1);
          }
          if (this._finiancialInfoSvc.getClntAllSIncmDtlSeqPG?.length == 0) {
            this._conditionsService.saveSecondaryData = false;
            this._conditionsService.saveSecondaryDataFn();
            this._finiancialInfoSvc.totalSecondaryAmt = 0;
            localStorage.setItem('totalSecondaryAmt', this._finiancialInfoSvc.totalSecondaryAmt)

            return;
          }
          this._finiancialInfoSvc.getAllSecIncome = this._finiancialInfoSvc.getClntAllSIncmDtlSeqPG;
          this._conditionsService.saveSecondaryData = true;
          this._conditionsService.saveSecondaryDataFn();
          // this.calTAmount(2);
          this._finiancialInfoSvc.calculationOfIncomeAmounts(2, this._finiancialInfoSvc.getAllSecIncome);
        }
        // console.log("Delete Success");
      }
      else {
        //in case of cancel
        return;
      }
    })
      .catch(() => {
        // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)');
        return;
      });
  }

  savePrimaryData(): void {
    this.setValueToModel1();
    if (this.incomeForm.invalid) {
      for (const control of Object.keys(this.incomeForm.controls)) {
        this.incomeForm.controls[control].markAsTouched();
      }
      return;
    }
    this._sharedSvc.showSpinner()
    this._finiancialInfoSvc.savePrimaryDataModel.push(this._finiancialInfoSvc.saveClntIncmDtlModel);
    //   let addRow = {
    //     "clntSeq": this._finiancialInfoSvc.saveClntIncmDtlModel.clntSeq,
    //     "incmDtlSeq": this._finiancialInfoSvc.saveClntIncmDtlModel.incmDtlSeq,
    //     "incmCtgryKey": this._finiancialInfoSvc.saveClntIncmDtlModel.incmCtgryKey,
    //     "incmAmt": this._finiancialInfoSvc.saveClntIncmDtlModel.incmAmt,
    //     "incmTypKey": this._finiancialInfoSvc.saveClntIncmDtlModel.incmTypKey,
    //     "qnty": this._finiancialInfoSvc.saveClntIncmDtlModel.qnty,
    //     "frqncy": this._finiancialInfoSvc.saveClntIncmDtlModel.frqncy,
    //     "avgMnthlyIncm": this._finiancialInfoSvc.saveClntIncmDtlModel.avgMnthlyIncm,
    //     "cmnt": this._finiancialInfoSvc.saveClntIncmDtlModel.cmnt,
    //     "crtdBy": this._finiancialInfoSvc.saveClntIncmDtlModel.crtdBy,
    //     "crtdDt": this._finiancialInfoSvc.saveClntIncmDtlModel.crtdDt,
    //     "lastUpdBy": this._finiancialInfoSvc.saveClntIncmDtlModel.lastUpdBy,
    //     "lastUpdDt": this._finiancialInfoSvc.saveClntIncmDtlModel.lastUpdDt,
    //     "delFlg": this._finiancialInfoSvc.saveClntIncmDtlModel.delFlg,
    // }
    this._finiancialInfoSvc.getAllPriIncome.push(this._finiancialInfoSvc.saveClntIncmDtlModel);
    this._conditionsService.savePrimaryDataData = true;
    this._conditionsService.savePrimaryDataDataFn();
    this._sharedSvc.collectionSize = this._finiancialInfoSvc.getAllPriIncome?.length;
    this._finiancialInfoSvc.getClntAllSPriIncmDtlSeqPG = this._finiancialInfoSvc.getAllPriIncome.slice((this._sharedSvc.page - 1) * this._sharedSvc.pageSize, (this._sharedSvc.page - 1) * this._sharedSvc.pageSize + this._sharedSvc.pageSize);
    this.modalService.dismissAll();
    // this.calTAmount(1)
    this._finiancialInfoSvc.calculationOfIncomeAmounts(1, this._finiancialInfoSvc.getAllPriIncome);
    this._sharedSvc.hideSpinner();
    this._sharedSvc.success(this._sharedSvc.msg);
  }

  saveSecondaryDataFun(): void {
    this.setValueToModel1();
    if (this.incomeForm.invalid) {
      for (const control of Object.keys(this.incomeForm.controls)) {
        this.incomeForm.controls[control].markAsTouched();
      }
      return;
    }
    this._sharedSvc.showSpinner()
    this._finiancialInfoSvc.saveSecondaryDataModel.push(this._finiancialInfoSvc.saveClntIncmDtlModel);
    // let addRow = {
    //   "clntSeq": this._finiancialInfoSvc.saveClntIncmDtlModel.clntSeq,
    //   "incmDtlSeq": this._finiancialInfoSvc.saveClntIncmDtlModel.incmDtlSeq,
    //   "incmCtgryKey": this._finiancialInfoSvc.saveClntIncmDtlModel.incmCtgryKey,
    //   "incmAmt": this._finiancialInfoSvc.saveClntIncmDtlModel.incmAmt,
    //   "incmTypKey": this._finiancialInfoSvc.saveClntIncmDtlModel.incmTypKey,
    //   "qnty": this._finiancialInfoSvc.saveClntIncmDtlModel.qnty,
    //   "frqncy": this._finiancialInfoSvc.saveClntIncmDtlModel.frqncy,
    //   "avgMnthlyIncm": this._finiancialInfoSvc.saveClntIncmDtlModel.avgMnthlyIncm,
    //   "cmnt": this._finiancialInfoSvc.saveClntIncmDtlModel.cmnt,
    //   "crtdBy": this._finiancialInfoSvc.saveClntIncmDtlModel.crtdBy,
    //   "crtdDt": this._finiancialInfoSvc.saveClntIncmDtlModel.crtdDt,
    //   "lastUpdBy": this._finiancialInfoSvc.saveClntIncmDtlModel.lastUpdBy,
    //   "lastUpdDt": this._finiancialInfoSvc.saveClntIncmDtlModel.lastUpdDt,
    //   "delFlg": this._finiancialInfoSvc.saveClntIncmDtlModel.delFlg,
    // }
    this._finiancialInfoSvc.getAllSecIncome.push(this._finiancialInfoSvc.saveClntIncmDtlModel);
    this._sharedSvc.collectionSize = this._finiancialInfoSvc.getAllSecIncome?.length;
    this._conditionsService.saveSecondaryData = true;
    this._conditionsService.saveSecondaryDataFn();
    this._finiancialInfoSvc.getClntAllSIncmDtlSeqPG = this._finiancialInfoSvc.getAllSecIncome.slice((this._sharedSvc.page - 1) * this._sharedSvc.pageSize, (this._sharedSvc.page - 1) * this._sharedSvc.pageSize + this._sharedSvc.pageSize);
    this.modalService.dismissAll();
    this._sharedSvc.hideSpinner();
    // this.calTAmount(2)
    this._finiancialInfoSvc.calculationOfIncomeAmounts(2, this._finiancialInfoSvc.getAllSecIncome);
    this._sharedSvc.success(this._sharedSvc.msg);
  }

  saveClntIncmDtl(b: any) {
    if (b == 1) {
      this._finiancialInfoSvc.saveClntIncmDtlModel = this._finiancialInfoSvc.getClntAllSPriIncmDtlSeqPG;
    }
    if (b == 2) {
      this._finiancialInfoSvc.saveClntIncmDtlModel = this._finiancialInfoSvc.getClntAllSIncmDtlSeqPG;
    }
    this._sharedSvc.showSpinner();

    this._finiancialInfoSvc.saveClntIncmDtl().subscribe({
      next: (response) => {
        if (response) {
          if (b == 1) {
            this._finiancialInfoSvc.getClntAllSPriIncmDtlSeqPG = response;
            this._conditionsService.savePrimaryDataData = false;
            this._conditionsService.savePrimaryDataDataFn();
            if (this.route.snapshot.paramMap.get("id")) { }
            else {
              localStorage.setItem('savePrimaryDataDataValues', JSON.stringify(this._finiancialInfoSvc.getClntAllSPriIncmDtlSeqPG));
            }
          }
          if (b == 2) {
            this._finiancialInfoSvc.getClntAllSIncmDtlSeqPG = response;
            this._conditionsService.saveSecondaryData = false;
            this._conditionsService.saveSecondaryDataFn();
            if (this.route.snapshot.paramMap.get("id")) { }
            else {
              localStorage.setItem('saveSecondaryDataValues', JSON.stringify(this._finiancialInfoSvc.getClntAllSIncmDtlSeqPG));
            }
          }
          this.calTAmount(b);
          this._sharedSvc.hideSpinner()
          this.modalService.dismissAll();
          this._sharedSvc.success(this._sharedSvc.msg);
          // this._finiancialInfoSvc.InItGetClntAllIncmDtlSeq(this.saveCnicData.clntSeq, this.incmCtgryKey)
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }

  calTAmount(incmCtgryKey: any) {
    this._finiancialInfoSvc.totalIncmAmt = 0;
    this._finiancialInfoSvc.tempIncAmt = 0;
    if (incmCtgryKey == 1) {
      for (let i = 0; i < this._finiancialInfoSvc.getAllPriIncome.length; i++) {
        if (this._finiancialInfoSvc.getAllPriIncome[i].delFlg == false) {
          let incmAmt = this._finiancialInfoSvc.getAllPriIncome[i].incmAmt;
          this._finiancialInfoSvc.totalIncmAmt = this._finiancialInfoSvc.totalIncmAmt + incmAmt;
          localStorage.setItem('totalPrimaryAmt', this._finiancialInfoSvc.totalIncmAmt)
        }
      }
    }
    if (incmCtgryKey == 2) {
      for (let i = 0; i < this._finiancialInfoSvc.getAllSecIncome.length; i++) {
        if (this._finiancialInfoSvc.getAllSecIncome[i].delFlg == false) {
          let incmAmt = this._finiancialInfoSvc.getAllSecIncome[i].incmAmt;
          this._finiancialInfoSvc.totalIncmAmt = this._finiancialInfoSvc.totalIncmAmt + incmAmt;
          localStorage.setItem('totalSecondaryAmt', this._finiancialInfoSvc.totalIncmAmt)
        }
      }
    }
    this._finiancialInfoSvc.formulasCal();
  }

  // Expense
  setExpenseValues() {
    this.jwt = sessionStorage.getItem('JWT');
    this.jwt = JSON.parse(this.jwt);
    let date = new Date();
    this._finiancialInfoSvc.saveClntExpnsDtlModel = {
      clntSeq: this.saveCnicData.clntSeq,
      expDtlSeq: null,
      expCtgryKey: 2,
      expTypKey: null,
      expAmt: null,
      crtdBy: this.jwt?.preferred_username,
      crtdDt: date,
      lastUpdBy: this.jwt?.preferred_username,
      lastUpdDt: date,
      delFlg: false
    }
  }

  editHousehold(content: any, data: any, h4: any, index: any) {
    this.indexHH = index;
    this.modelHeading = h4;
    this._finiancialInfoSvc.dropdownsDef(data);
    this._finiancialInfoSvc.edit = true;
    this._sharedSvc.msg = this.translate.instant('toast.recordUpdated');
    this.modalService.open(content, {
      // backdrop: "static",
      // keyboard: false,
      windowClass: "popupWidth1k",
      backdropClass: 'light-blue-backdrop',
      centered: true
    });
    this._finiancialInfoSvc.saveClntExpnsDtlModel = data;
    this.setValueToForm2();
    // this._conditionsService.saveHouseHldData = true;
    // this._conditionsService.saveHouseHldDataFn();
  }

  onUpdateExp() {
    this.setValueToModel2();
    this._finiancialInfoSvc.getClntAllExpnsDtlSeqPG[this.indexHH] = this._finiancialInfoSvc.saveClntExpnsDtlModel;
    this._conditionsService.saveHouseHldData = true;
    this._conditionsService.saveHouseHldDataFn();
    this._finiancialInfoSvc.getAllExpense = this._finiancialInfoSvc.getClntAllExpnsDtlSeqPG;
    this._finiancialInfoSvc.calculationTExp(2);
    this._finiancialInfoSvc.formulasCal();
    this.dismissModal();
  }

  DeletePopup_Exp(data: any, index: any) {
    this._sharedPopupSvc.popup("", this.translate.instant('common.areSelectRecordDelete'), "", this.translate.instant('common.delete'), this.translate.instant('common.cancelSmall'), null, this.translate.instant('sideBarMenu.clientOnboarding')).then((confirmed: any) => {
      if (confirmed) {
        // Delete function call
        this._sharedSvc.msg = "Record deleted";

        this._finiancialInfoSvc.getClntAllExpnsDtlSeqPG[index].delFlg = true;
        if (data.expDtlSeq == null) {
          this._finiancialInfoSvc.getClntAllExpnsDtlSeqPG.splice(index, 1);
        }
        if (this._finiancialInfoSvc.getClntAllExpnsDtlSeqPG?.length == 0) {
          this._conditionsService.saveHouseHldData = false;
          this._conditionsService.saveHouseHldDataFn();
          this._finiancialInfoSvc.getAllExpense = this._finiancialInfoSvc.getClntAllExpnsDtlSeqPG;
          this._conditionsService.saveHouseHldData = true;
          this._conditionsService.saveHouseHldDataFn();
          this._finiancialInfoSvc.calculationTExp(2);
          this._finiancialInfoSvc.formulasCal();
          return;
        }
        this._finiancialInfoSvc.getAllExpense = this._finiancialInfoSvc.getClntAllExpnsDtlSeqPG;
        this._conditionsService.saveHouseHldData = true;
        this._conditionsService.saveHouseHldDataFn();
        this._finiancialInfoSvc.calculationTExp(2);
        this._finiancialInfoSvc.formulasCal();
        // console.log("Delete Success");
      }
      else {
        //in case of cancel
        return;
      }
    })
      .catch(() => {
        // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)');
        return;
      });
  }

  onSubmitExp(): void {
    this.setValueToModel2();
    if (this.expenseForm.invalid) {
      for (const control of Object.keys(this.expenseForm.controls)) {
        this.expenseForm.controls[control].markAsTouched();
      }
      return;
    }
    this._finiancialInfoSvc.saveHouseHldModel.push(this._finiancialInfoSvc.saveClntExpnsDtlModel);
    this._conditionsService.saveHouseHldData = true;
    this._conditionsService.saveHouseHldDataFn();
    this._finiancialInfoSvc.getAllExpense.push(this._finiancialInfoSvc.saveClntExpnsDtlModel);
    this._sharedSvc.collectionSize = this._finiancialInfoSvc.getAllExpense?.length;
    this._finiancialInfoSvc.getClntAllExpnsDtlSeqPG = this._finiancialInfoSvc.getAllExpense.slice((this._sharedSvc.page - 1) * this._sharedSvc.pageSize, (this._sharedSvc.page - 1) * this._sharedSvc.pageSize + this._sharedSvc.pageSize);
    localStorage.setItem("householdExpenses", JSON.stringify(this._finiancialInfoSvc.getClntAllExpnsDtlSeqPG));
    this.modalService.dismissAll();
    this._sharedSvc.success(this._sharedSvc.msg);
    this._finiancialInfoSvc.getAllExpense = this._finiancialInfoSvc.getClntAllExpnsDtlSeqPG;
    this._finiancialInfoSvc.calculationTExp(2);
    this._finiancialInfoSvc.formulasCal();
  }

  saveClntExpnsDtl() {
    this._finiancialInfoSvc.saveClntExpnsDtlModel = this._finiancialInfoSvc.getClntAllExpnsDtlSeqPG;
    this._sharedSvc.showSpinner()
    this._finiancialInfoSvc.saveClntExpnsDtl().subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.hideSpinner();
          this._finiancialInfoSvc.getClntAllExpnsDtlSeqPG = response;
          this._conditionsService.saveHouseHldData = false;
          this._conditionsService.saveHouseHldDataFn();
          this._finiancialInfoSvc.getAllExpense = this._finiancialInfoSvc.getClntAllExpnsDtlSeqPG;
          this._finiancialInfoSvc.calculationTExp(2);
          this._finiancialInfoSvc.formulasCal();
          this._finiancialInfoSvc.saveHouseHldModel = [];
          localStorage.removeItem('saveHouseHldModel');
          this._sharedSvc.success(this._sharedSvc.msg);
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => {
      //   console.info('complete')
      // }
    })
  }

  // incomeForm
  setValueToModel1() {
    this._finiancialInfoSvc.saveClntIncmDtlModel.incmTypKey = this.incmTypKey.value
    this._finiancialInfoSvc.saveClntIncmDtlModel.incmAmt = this.incmAmt.value
  }

  setValueToForm1() {
    this.incmTypKey.setValue(this._finiancialInfoSvc.saveClntIncmDtlModel.incmTypKey);
    this.incmAmt.setValue(this._finiancialInfoSvc.saveClntIncmDtlModel.incmAmt);
  }

  // expenseForm
  setValueToModel2() {
    this._finiancialInfoSvc.saveClntExpnsDtlModel.expTypKey = this.expTypKey.value
    this._finiancialInfoSvc.saveClntExpnsDtlModel.expAmt = this.expAmt.value
  }

  setValueToForm2() {
    this.expTypKey.setValue(this._finiancialInfoSvc.saveClntExpnsDtlModel.expTypKey);
    this.expAmt.setValue(this._finiancialInfoSvc.saveClntExpnsDtlModel.expAmt);
  }

  validationForm() {
    this.incomeForm = new FormGroup({
      incmTypKey: new FormControl(null, [Validators.required,]),
      incmAmt: new FormControl(null, [Validators.required, Validators.min(1), Validators.max(100000),]),
    });
    this.expenseForm = new FormGroup({
      expTypKey: new FormControl(null, [Validators.required,]),
      expAmt: new FormControl(null, [Validators.required, Validators.min(1), Validators.max(100000),]),
    });
  }

  // incomeForm
  get incmTypKey() { return this.incomeForm.get('incmTypKey') }
  get incmAmt() { return this.incomeForm.get('incmAmt') }
  // expenseForm
  get expTypKey() { return this.expenseForm.get('expTypKey') }
  get expAmt() { return this.expenseForm.get('expAmt') }


  continueCheckClick() {
    let temp = localStorage.getItem("SaveCnicData");
    if (temp) {
      let clnt_seq = JSON.parse(temp).clntSeq;

      // this._finiancialInfoSvc.InItGetClntAllExpnsDtlSeq(this.saveCnicData.clntSeq, 2).then((result) => {
      //   sessionStorage.setItem('householdExpenses', JSON.stringify(!!this._finiancialInfoSvc.getClntAllExpnsDtlSeqPG ? this._finiancialInfoSvc.getClntAllExpnsDtlSeqPG : 0));
      // }).catch((error) => {
      //   console.error('Error:', error);
      // });
      // console.log('Household Expenses:', JSON.parse(sessionStorage.getItem('householdExpenses')));
    }
  }



}
