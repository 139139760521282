export const Endpoint = {
    // Client APIS
    OnBoarding: {
        CNICValidation: '/on-boarding/tags/cnic-validation',
        UpdateClientStatus: '/on-boarding/client/update-clnt-status',
        SaveClient: '/on-boarding/client/save-clnt',
        GetClientBasicInfo: '/on-boarding/client/get-clnt-basic_info/',
        GetAllClients: '/on-boarding/client/get-all-clnts',
        GetAllApprovClients: '/on-boarding/client/get-all-aprvd-clnts',
        SaveAddress: '/on-boarding/addr/save-addr',
        GetAddress: '/on-boarding/addr/get-address/',
    },
    ClientDoc: {
        SaveDocs: '/on-boarding/docs/save-docs',
        GetClientDoc: '/on-boarding/docs/get-clnt-doc/',
        GetClientDocByFlag: '/on-boarding/docs/get-clnt-doc-by-flg/',
        GetClientDocByEntetyKeyFlag: '/on-boarding/docs/get-clnt-doc-by-enty-key-and-flg/',
    },
    OtherOutstanding: {
        save_clnt_oth_outstanding: '/on-boarding/othr-outstandings/save-clnt-all-oth-outstanding',
        get_clnt_oth_outstanding: '/on-boarding/othr-outstandings/get-clnt-oth-outstanding/',
        get_all_clnt_oth_outstandings: '/on-boarding/othr-outstandings/get-all-clnt-oth-outstandings/',
    },
    BizAppraisal: {
        save_biz_aprsl: '/on-boarding/business-appraisal/basic-info/save-biz-aprsl',
        save_biz_aprsl_adtnl_info: '/on-boarding/business-appraisal/basic-info/save-biz-aprsl-adtnl-info',
        save_all_biz_aprsl_adtnl_info: '/on-boarding/business-appraisal/basic-info/save-all-biz-aprsl-adtnl-info',
        get_clnt_biz_aprsl: '/on-boarding/business-appraisal/basic-info/get-clnt-biz-aprsl/',
        get_clnt_biz_aprsl_adtnl_info: '/on-boarding/business-appraisal/basic-info/get-clnt-biz-aprsl-adtnl-info/',
        get_clnt_all_biz_aprsl_adtnl_info: '/on-boarding/business-appraisal/basic-info/get-clnt-all-biz-aprsl-adtnl-info/',
    },
    Employer: {
        save_emplyr_dtl: '/on-boarding/employer/save-emplyr-dtl',
        get_emplyr_dtl: '/on-boarding/employer/get-emplyr-dtl/',
        get_all_emplyr_dtl: '/on-boarding/employer/get-all-emplyr-dtl/',
    },
    FinancialInfo: {
        save_clnt_incm_dtl: '/on-boarding/business-details/income/save-all-clnt-incm-dtl',
        get_clnt_incm_dtl: '/on-boarding/business-details/income/get-clnt-incm-dtl/',
        get_clnt_incm_dtl_seq: '/on-boarding/business-details/income/get-clnt-incm-dtl/', //ROLA
        get_clnt_all_incm_dtl: '/on-boarding/business-details/income/get-clnt-all-incm-dtl/',
        save_clnt_expns_dtl: '/on-boarding/business-details/expense/save-all-clnt-expns-dtl',
        get_all_clnt_expns_dtl: '/on-boarding/business-details/expense/get-all-clnt-expns-dtl/',
    },
    Summary: {
        get_clnt_summary: '/on-boarding/client/get-clnt-summary/',
        get_ca_summary: '/credit-app/get-ca-summary/',
        submit_client: '/on-boarding/client/submit-client',
        submit_credit: '/credit-app/submit-credit-app',
    },
    // Credit APIs
    creditApp: {
        get_ca_list: '/credit-app/get-ca-list',
        get_client_detail_by_appseq: '/on-boarding/client/get-clnt-dtl',
        save_credit_app: '/credit-app/save-credit-app',
        get_latest_credit_app: '/credit-app/get-latest-credit-app/',
        get_credit_app: '/credit-app/get-credit-app/',
        get_clnt_basic_info: '/on-boarding/client/get-clnt-basic_info/',
        get_ca_aprvd_list: "/credit-app/get-ca-aprvd-list"
        //        get_latest_credit_app: '/credit-app/get-latest-credit-app/',
        //        get_credit_app: '/credit-app/get-credit-app/',
        //        get_clnt_basic_info: '/on-boarding/client/get-clnt-basic_info/',
    },
    creditCollaterial: {
        save_collateral_dtl: '/credit-app/collateral/save-all-collateral_dtl',
        save_collateral_hdr: '/credit-app/collateral/save-collateral_hdr',
        get_collateral_hdr: '/credit-app/collateral/get-collateral-hdr/',
        get_collateral_dtl: '/credit-app/collateral/get-collateral-dtl',
        get_all_collateral_dtl: '/credit-app/collateral/get-all-collateral-dtl',
        get_all_bags_collateral: '/setup/get-bag_list-avlbl/',
    },
    // DisbustmentCredit APIs
    DisbustmentCredit: {
        get_disbustment_detail: '/dsbmt/get-dsbmt-dtls/',
        create_paymrnt_schedule: '/dsbmt/creat-pymt-schd/',
        get_payment_schedule_info: "/dsbmt/get-pymt-sched-info/",
        get_upfront_payment_info: "/dsbmt/get-upfrnt-pymt-info/",
        get_cltrl_info: "/dsbmt/get-cltrl-info/",
        save_all_dsbmt_dtl: "/dsbmt/save-all-dsbmt-dtl",
        get_dsbmt: "/dsbmt/get-dsbmt/",
        save_dsbmt: "/dsbmt/save-dsbmt",
        srch_pymt_sched: "/dsbmt/srch-pymt-sched/",
        update_credit_app_sts: "/credit-app/update-credit-app-sts",
    },
    // Task APIs
    Task: {
        rjct_my_task: '/on-boarding/aproval-task/rjct-my-task/',
        aprv_my_task: '/on-boarding/aproval-task/aprv-my-task/',
        get_my_tasks: "/on-boarding/aproval-task/get-my-tasks/",
        execute_task: "/on-boarding/aproval-task/execute-task/",
        assign_task: "/on-boarding/aproval-task/assign-task",
    },

    // Recovery APIs
    Recovery: {
        save_pymnt_txn: '/pymt/save-pymnt-txn',
        save_excs_pymnt: '/pymt/save-excs-pymnt',
        get_excs_pymnt_lst: "/pymt/get-excs-pymnt-lst",
        get_by_excs_pymnt_seq: "/pymt/get-by-excs-pymnt-seq/{excsPymntSeq}",
    },
    Admin: {
        save_gold_rate: '/admin/save-gold_rate',
        save_user_role: '/admin/save-role',
        save_app_access: '/admin/save-app-access',
        remove_bag_lctn: '/admin/remove-bag-lctn',
        add_bag_lctn: '/admin/add-bag-lctn',
        get_bag_lctn: '/admin/get-bag-lctn/',
        get_bag_lctn_app: '/admin/get-bag-lctn-for-app/',
        get_gold_rate: '/admin/get-gold-rate/',
        get_excs_pymnt_lst: '/admin/get-excs-pymnt-lst',
        get_cltrl_info_lst: '/admin/get-cltrl-info-lst',
        get_gold_rate_lst: '/admin/get-gold-rate-lst',
        get_auth_list: '/admin/get-auth_list/',
        get_auth_list_for_role: '/admin/get-auth_list-for_rol/',
        get_role_list:'/admin/get-all_roles',
        get_modes_list:'/admin/get-all_modes',
        get_sub_modes_list:'/admin/get-all_sub-modes',
        get_jrnl_vchr_list:'/admin/get-jrnl_vchr-lst/',
        get_jrnl_vchr_detail_list:'/admin/get-jrnl_vchr-dtl-lst/',
    },
    Setup: {
        save_types: '/setup/save-typs',
        save_ref_cd_val: '/setup/save-ref-cd-val',
        save_ref_cd_grap: '/setup/save-ref-cd-grp',
        save_biz_sect: '/setup/save-biz-sect',
        save_biz_acty: '/setup/save-biz-acty',
        save_risk_tag: '/setup/save-risk-tag',
        save_city: '/setup/save-city',
        save_city_uc: '/setup/save-city-uc',
        save_shrof: '/setup/save-shrof',
        save_vault: '/setup/save-vault',
        save_safe: '/setup/save-safe',
        save_region: "/setup/org/save-region",
        save_area: "/setup/org/save-area",
        save_branch: "/setup/org/save-brnch",
        save_portfolio: "/setup/org/save-port",
        save_branch_acct: "/setup/org/save-brnch-acct",
        save_branch_prod: "/setup/org/save-brnch-prod",
        save_branch_lctn: "/setup/org/save-brnch-lctn",
        save_port_lctn: "/setup/org/save-port-lctn",
        save_port_cmnty: "/setup/save-cmnty",
        get_types: '/setup/get-typs/',
        get_risk_tag_list: '/setup/get-risk-tag-list',
        get_branch_list: '/setup/org/get-branch-list/',
        get_branch_list2: '/setup/org/get-branch-list',
        get_city_uc_list: '/setup/get-city-uc-list',
        get_city_list: '/setup/get-city-list',
        get_shroff_list: '/setup/get-shrof-list',
        get_vault_list: '/setup/get-valt-list',
        get_safe_list: '/setup/get-safe-list/',
        get_region_list: '/setup/org/get-region-list',
        get_region_empl_list: '/setup/org/get-region-empl-list/',
        get_area_list: '/setup/org/get-area-list/',
        get_area_empl_list: '/setup/org/get-area-emp-list/',
        get_branch_prd_list: '/setup/org/get-branch-prd-list/',
        get_branch_lctn_list: '/setup/org/get-branch-lctn/',
        get_questionnaire_list: '/setup/qstnr/get-qstnr-list',
        save_questionnaire: '/setup/qstnr/save-qstnr',
        get_question_list: '/setup/qstnr/get-question-list/',
        save_question: '/setup/qstnr/save-question',
        get_answer_list: '/setup/qstnr/get-answr-list/',
        save_answer: '/setup/qstnr/save-answr',
        get_port_list: '/setup/org/get-port-list/',
        get_port_lctn_list: '/setup/org/get-port-lctn-list/',
        get_port_cmnty_list: '/setup/pick-list/get-all-geo-cmnty/',
        // get_port_cmnty_list: '/setup/pick-list/get-geo-cmnty/',
        get_biz_sect_list: '/setup/pick-list/get-biz-sec',
        get_biz_acty_list: '/setup/pick-list/get-biz-acty/',
        get_ref_grps: '/setup/ref-grps',
        get_ref_grp_vals: '/setup/pick-list/refCdValsForGrp/',
        get_acc_typ_list:'/setup/get-acnt_list',
        get_branch_covering_emp: '/setup/org/get-branch-emp-list/',
        get_geo_country_list: '/setup/geo/get-country-list',
        get_geo_state_list: '/setup/geo/get-state-list/',
        get_geo_dist_list: '/setup/geo/get-dst-list/',
        get_geo_thsl_list: '/setup/geo/get-thsl-list/',
        get_geo_uc_list: '/setup/geo/get-uc-list/',
        save_geo_country: '/setup/geo/save-country',
        save_geo_state: '/setup/geo/save-state',
        save_geo_district: '/setup/geo/save-District',
        save_geo_tehsil: '/setup/geo/save-tehsil',
        save_geo_uc: '/setup/geo/save-uc',
        save_region_emp: '/setup/org/save-region-emp',
        save_area_emp: '/setup/org/save-area-emp',
        save_branch_emp: '/setup/org/save-brnch-emp',
        save_port_emp: '/setup/org/save-port-emp',
        get_branch_emp_list_by_rol: '/setup/org/get-branch-emp-list-by-rol/',
        get_branch_emp_by_port: '/setup/org/get-branch-emp/',
        get_all_emp_list: "/setup/org/get-emp-list",
        get_area_emp_list: '/setup/org/get-area-emp-list/',
        get_port_emp_list: '/setup/org/get-port-emp-list/',
        save_branch_bag: '/setup/save-bag',
        get_branch_bag_list: '/setup/get-bag_list/',
        get_rul_list:"/setup/pick-list/rul-list"
    },
    product:{
        save_product : '/setup/product/save-prd',
        save_product_sgrt_inst: '/setup/product/save-prd-sgrt-inst',
        save_product_rul:'/setup/product/save-prd-rul',
        save_product_pal:'/setup/product/save-prd-pal',
        save_product_grp : '/setup/product/save-prd-grp',
        save_product_chrg: '/setup/product/save-prd-chrg',
        save_product_chrg_trm:'/setup/product/save-prd-chrg-trm',
        save_product_chrg_slb:'/setup/product/save-prd-chrg-slb',
        
        get_product_details:"/setup/product/get-product-details/{prd_seq}",
        get_product_rul:"/setup/product/get-prd_rul/",
        get_product_grp:'/setup/product/get-prd_grp/{prdGrpSeq}',
        get_product_chrg:'/setup/product/get-prd_chrg/{prdSeq}',
        get_product_chrg_trm:'/setup/product/get-prd_chrg-trm/{prdSeq}',
        get_product_chrg_slb:'/setup/product/get-prd_chrg-slb/{chrgSeq}',
        get_product:'/setup/product/get-prd/',
        get_porduct_sgrt_inst:'/setup/product/get-prd-sgrt-inst/{prdSeq}',
        get_product_sgrt_inst_list:"/setup/product/get-prd-sgrt-inst-list",
        get_product_rul_list:'/setup/product/get-prd-rul-list',
        get_product_pal:'/setup/product/get-prd-pal/{prdSeq}',
        get_product_pal_list:'/setup/product/get-prd-pal-list',
        get_product_list:'/setup/product/get-prd-list',
        get_product_grp_list:'/setup/product/get-prd-grp-list',
        get_product_chrg_trm_list:'/setup/product/get-prd-chrg-trm-list',
        get_product_chrg_slb_list:'/setup/product/get-prd-chrg-trm-list',
        get_product_chrg_list:'/setup/product/get-prd-chrg-list'
    }

};