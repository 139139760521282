<section>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12">
      <div class="mt-3 loan-table">
        <div class="row mb-3">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="btnCenter otherOutstanding mb-3">
              <h3>{{ "clientOnboarding.financialInfo" | translate }}</h3>
            </div>
            <div class="btnCenter otherOutstanding">
              <h6>
                {{ "common.clintName" | translate }}:
                <b>{{ saveCnicData?.frstNm | titlecase }}</b> /
                {{ "common.clintId" | translate }}:
                <b>{{ saveCnicData?.clntId }}</b>
              </h6>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="btnCenter" (click)="_conditionsService.toggle()">
              <button
                class="btn infoBtn"
                (click)="showBtn(true, false, false, 1)"
                [class.infoBtn_bg]="primaryInc"
                [disabled]="
                  _conditionsService.savePrimaryDataData ||
                  _conditionsService.saveSecondaryData ||
                  _conditionsService.saveHouseHldData
                "
              >
                {{
                  "clientOnboarding.financialInfoTab.primaryIncome" | translate
                }}
              </button>
              <button
                class="btn infoBtn"
                (click)="showBtn(false, true, false, 2)"
                [class.infoBtn_bg]="secondaryInc"
                [disabled]="
                  _conditionsService.savePrimaryDataData ||
                  _conditionsService.saveSecondaryData ||
                  _conditionsService.saveHouseHldData
                "
              >
                {{ "common.secondaryIncome" | translate }}
              </button>
              <button
                class="btn infoBtn"
                (click)="showBtn(false, false, true)"
                [class.infoBtn_bg]="houseHoldExpenses"
                [disabled]="
                  _conditionsService.savePrimaryDataData ||
                  _conditionsService.saveSecondaryData ||
                  _conditionsService.saveHouseHldData
                "
              >
                {{
                  "clientOnboarding.financialInfoTab.householdExpenses"
                    | translate
                }}
              </button>
            </div>
          </div>
        </div>
        <ng-container *ngIf="primaryInc">
          <div class="row mb-3">
            <div class="col-sm-6 col-md-6 col-lg-6">
              <button class="btn infoBtn financialBtn1 mt-0">
                {{ "common.totalPrimaryIncome" | translate }}:
                <span>{{
                  _finiancialInfoSvc?.totalPrimaryAmt | number : "1.0-0"
                }}</span>
              </button>
            </div>
            <div
              class="col-sm-6 col-md-6 col-lg-6"
              *ngIf="!_conditionsService.readOnlyView_Client"
            >
              <div
                class="add-button"
                (click)="
                  openModal(
                    modelPrimary,
                    this.translate.instant(
                      'clientOnboarding.financialInfoTab.addPrimaryIncome'
                    ),
                    1
                  )
                "
              >
                <i class="fa fa-plus"></i>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <td>
                        {{
                          "clientOnboarding.financialInfoTab.primaryIncomeType"
                            | translate
                        }}
                      </td>
                      <td>
                        {{ "common.incomeAmount" | translate }}({{
                          "common.rs" | translate
                        }})
                      </td>
                      <td
                        class="text-center"
                        *ngIf="!_conditionsService.readOnlyView_Client"
                      >
                        {{ "common.action" | translate }}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let data of _finiancialInfoSvc.getClntAllSPriIncmDtlSeqPG;
                        let i = index
                      "
                    >
                      <ng-container *ngIf="!data.delFlg">
                        <ng-container
                          *ngFor="let item of _picklistService.INCOME_TYPE_Data"
                        >
                          <td *ngIf="item?.refCdSeq == data?.incmTypKey">
                            {{ item?.refCdDscr }}
                          </td>
                        </ng-container>
                        <td>{{ data?.incmAmt | number : "1.0-0" }}</td>

                        <td
                          class="text-center"
                          *ngIf="!_conditionsService.readOnlyView_Client"
                        >
                          <div class="action-inner">
                            <i
                              class="fa fa-pencil"
                              (click)="
                                editIncome(
                                  modelPrimary,
                                  data,
                                  'primary',
                                  'Update Primary Income',
                                  i
                                )
                              "
                            ></i>
                            <i
                              class="fa fa-trash"
                              (click)="DeletePopup_Inc(data, 'primary', i)"
                            ></i>
                          </div>
                        </td>
                      </ng-container>
                    </tr>
                  </tbody>
                </table>
                <!-- <div class="row g-0" *ngIf="_sharedSvc.pageSize > 0">
                                    <div class="col-sm-12 col-md-12 col-lg-6">
                                        <span class="clr-9d9a9a">Showing
                                            {{_finiancialInfoSvc.getClntAllSPriIncmDtlSeqPG?.length}} of
                                            {{_sharedSvc.collectionSize}} entries</span>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-6">
                                        <div class="float-right">
                                            <ngb-pagination [(page)]="_sharedSvc.page" [pageSize]="_sharedSvc.pageSize"
                                                [maxSize]="10" ngDefaultControl
                                                [collectionSize]="_sharedSvc.collectionSize" [rotate]="true"
                                                (pageChange)="_finiancialInfoSvc.pageChange()">
                                                <ng-template ngbPaginationPrevious>Prev</ng-template>
                                                <ng-template ngbPaginationNext>Next</ng-template>
                                            </ngb-pagination>
                                        </div>
                                    </div>
                                </div> -->
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="button_div">
                <button
                  class="btn login-button-class"
                  type="button"
                  [disabled]="_conditionsService.savePrimaryDataData"
                  (click)="showBtn(false, true, false, 2)"
                >
                  {{ "common.continue" | translate }}
                </button>
                <button
                  class="btn login-button-class secondaryBtn"
                  type="button"
                  [class.pointerNone]="_sharedSvc.loading"
                  *ngIf="!_conditionsService.readOnlyView_Client"
                  [disabled]="
                    !_conditionsService.savePrimaryDataData ||
                    _sharedSvc.loading
                  "
                  (click)="saveClntIncmDtl(1)"
                >
                  {{ "common.save" | translate }}
                </button>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="secondaryInc">
          <div class="row mb-3">
            <div class="col-sm-6 col-md-6 col-lg-6">
              <button class="btn infoBtn financialBtn1 mt-0">
                {{
                  "clientOnboarding.financialInfoTab.totalSecondaryIncome"
                    | translate
                }}
                :
                <span>{{
                  _finiancialInfoSvc?.totalSecondaryAmt | number : "1.0-0"
                }}</span>
              </button>
            </div>
            <div
              class="col-sm-6 col-md-6 col-lg-6"
              *ngIf="!_conditionsService.readOnlyView_Client"
            >
              <div
                class="add-button"
                (click)="
                  openModal(
                    modelSecondary,
                    this.translate.instant(
                      'clientOnboarding.financialInfoTab.addSecondaryIncome'
                    ),
                    2
                  )
                "
              >
                <i class="fa fa-plus"></i>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <td>
                        {{
                          "clientOnboarding.financialInfoTab.secondaryIncomeType"
                            | translate
                        }}
                      </td>
                      <td>
                        {{ "common.incomeAmount" | translate }}({{
                          "common.rs" | translate
                        }})
                      </td>
                      <td
                        class="text-center"
                        *ngIf="!_conditionsService.readOnlyView_Client"
                      >
                        {{ "common.action" | translate }}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let data of _finiancialInfoSvc.getClntAllSIncmDtlSeqPG;
                        let i = index
                      "
                    >
                      <ng-container *ngIf="!data.delFlg">
                        <ng-container
                          *ngFor="
                            let item of _picklistService.BUSINESS_RUNNER_Data
                          "
                        >
                          <td *ngIf="item?.refCdSeq == data?.incmTypKey">
                            {{ item?.refCdDscr }}
                          </td>
                        </ng-container>
                        <td>{{ data?.incmAmt | number : "1.0-0" }}</td>

                        <td
                          class="text-center"
                          *ngIf="!_conditionsService.readOnlyView_Client"
                        >
                          <div class="action-inner">
                            <i
                              class="fa fa-pencil"
                              (click)="
                                editIncome(
                                  modelSecondary,
                                  data,
                                  'secondary',
                                  'Update Secondary Income',
                                  i
                                )
                              "
                            ></i>
                            <i
                              class="fa fa-trash"
                              (click)="DeletePopup_Inc(data, 'secondary', i)"
                            ></i>
                          </div>
                        </td>
                      </ng-container>
                    </tr>
                  </tbody>
                </table>
                <!-- <div class="row g-0" *ngIf="_sharedSvc.pageSize > 0">
                                    <div class="col-sm-12 col-md-12 col-lg-6">
                                        <span class="clr-9d9a9a">Showing
                                            {{_finiancialInfoSvc.getClntAllIncmDtlSeqPG?.length}} of
                                            {{_sharedSvc.collectionSize}} entries</span>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-6">
                                        <div class="float-right">
                                            <ngb-pagination [(page)]="_sharedSvc.page" [pageSize]="_sharedSvc.pageSize"
                                                [maxSize]="10" ngDefaultControl
                                                [collectionSize]="_sharedSvc.collectionSize" [rotate]="true"
                                                (pageChange)="_finiancialInfoSvc.pageChange()">
                                                <ng-template ngbPaginationPrevious>Prev</ng-template>
                                                <ng-template ngbPaginationNext>Next</ng-template>
                                            </ngb-pagination>
                                        </div>
                                    </div>
                                </div> -->
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="button_div">
                <button
                  class="btn login-button-class"
                  type="button"
                  [disabled]="_conditionsService.saveSecondaryData"
                  (click)="showBtn(false, false, true)"
                >
                  {{ "common.continue" | translate }}
                </button>
                <button
                  class="btn login-button-class secondaryBtn"
                  type="button"
                  [class.pointerNone]="_sharedSvc.loading"
                  *ngIf="!_conditionsService.readOnlyView_Client"
                  [disabled]="
                    !_conditionsService.saveSecondaryData || _sharedSvc.loading
                  "
                  (click)="saveClntIncmDtl(2)"
                >
                  {{ "common.save" | translate }}
                </button>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="houseHoldExpenses">
          <div class="row mb-3">
            <div class="col-sm-10 col-md-10 col-lg-11">
              <button class="btn infoBtn financialBtn1 me-2 mt-0 mb-3">
                {{
                  "clientOnboarding.financialInfoTab.totalHouseholdExpenses"
                    | translate
                }}
                :
                <span
                  [ngClass]="
                    _finiancialInfoSvc.totalHouseHold < 0 ? 'negativeValue' : ''
                  "
                  >{{
                    _finiancialInfoSvc.totalHouseHold | number : "1.0-0"
                  }}</span
                >
              </button>
              <button class="btn infoBtn financialBtn2 me-2 mt-0 mb-3">
                {{
                  "clientOnboarding.financialInfoTab.totalBusinessProfit"
                    | translate
                }}
                :
                <span
                  [ngClass]="_finiancialInfoSvc.TBP < 0 ? 'negativeValue' : ''"
                  >{{ _finiancialInfoSvc.TBP | number : "1.0-0" }}</span
                >
              </button>
              <button class="btn infoBtn financialBtn3 me-2 mt-0 mb-3">
                {{
                  "clientOnboarding.financialInfoTab.netDisposalIncome"
                    | translate
                }}
                :
                <span
                  [ngClass]="_finiancialInfoSvc.NDI < 0 ? 'negativeValue' : ''"
                  >{{ _finiancialInfoSvc.NDI | number : "1.0-0" }}</span
                >
              </button>
              <button class="btn infoBtn financialBtn4 me-2 mt-0 mb-3">
                DBR&nbsp;1 :
                <span
                  [ngClass]="_finiancialInfoSvc.DBR1 < 0 ? 'negativeValue' : ''"
                  >{{ _finiancialInfoSvc.DBR1 | number : "1.0-0" }}</span
                >
              </button>
              <button class="btn infoBtn financialBtn5 me-2 mt-0 mb-3">
                DBR&nbsp;2 :
                <span
                  [ngClass]="_finiancialInfoSvc.DBR2 < 0 ? 'negativeValue' : ''"
                  >{{ _finiancialInfoSvc.DBR2 | number : "1.0-0" }}</span
                >
              </button>
              <button class="btn infoBtn financialBtn1 me-2 mt-0 mb-3">
                {{ "common.effectiveMonthlyRepayment" | translate }} :
                <span
                  [ngClass]="_finiancialInfoSvc.EMR < 0 ? 'negativeValue' : ''"
                  >{{ _finiancialInfoSvc.EMR | number : "1.0-0" }}</span
                >
              </button>
              <button class="btn infoBtn financialBtn2 me-2 mt-0 mb-3">
                {{ "common.loanSize" | translate }} :
                <span
                  [ngClass]="_finiancialInfoSvc.EMR < 0 ? 'negativeValue' : ''"
                  >{{ _finiancialInfoSvc.lSize | number : "1.0-0" }}</span
                >
              </button>
            </div>
            <div
              class="col-sm-2 col-md-2 col-lg-1 mt-1"
              *ngIf="!_conditionsService.readOnlyView_Client"
            >
              <div
                class="add-button"
                (click)="
                  openModal(
                    modelExp,
                    this.translate.instant(
                      'clientOnboarding.financialInfoTab.addHouseholdExpense'
                    ),
                    5
                  )
                "
              >
                <i class="fa fa-plus"></i>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <td>
                        {{
                          "clientOnboarding.financialInfoTab.householdExpenseType"
                            | translate
                        }}
                      </td>
                      <td>
                        {{
                          "clientOnboarding.financialInfoTab.expenseAmount"
                            | translate
                        }}&nbsp;({{ "common.rs" | translate }})
                      </td>
                      <td
                        class="text-center"
                        *ngIf="!_conditionsService.readOnlyView_Client"
                      >
                        {{ "common.action" | translate }}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let data of _finiancialInfoSvc.getClntAllExpnsDtlSeqPG;
                        let i = index
                      "
                    >
                      <ng-container *ngIf="!data.delFlg">
                        <ng-container
                          *ngFor="
                            let item of _picklistService.HOUSEHOLD_EXPENSES_Data
                          "
                        >
                          <td *ngIf="item?.refCdSeq == data?.expTypKey">
                            {{ item?.refCdDscr }}
                          </td>
                        </ng-container>
                        <td>{{ data?.expAmt | number : "1.0-0" }}</td>

                        <td
                          class="text-center"
                          *ngIf="!_conditionsService.readOnlyView_Client"
                        >
                          <div class="action-inner">
                            <i
                              class="fa fa-pencil"
                              (click)="
                                editHousehold(
                                  modelExp,
                                  data,
                                  'Update Household Expense',
                                  i
                                )
                              "
                            ></i>
                            <i
                              class="fa fa-trash"
                              (click)="DeletePopup_Exp(data, i)"
                            ></i>
                          </div>
                        </td>
                      </ng-container>
                    </tr>
                  </tbody>
                </table>
                <div
                  class="row g-0"
                  *ngIf="
                    _finiancialInfoSvc.getClntAllExpnsDtlSeqPG?.length > 10
                  "
                >
                  <div class="col-sm-12 col-md-12 col-lg-6">
                    <span class="clr-9d9a9a"
                      >{{ "common.showing" | translate }}
                      {{ _finiancialInfoSvc.getClntAllExpnsDtlSeqPG?.length }}
                      {{ "common.of" | translate }}
                      {{ _finiancialInfoSvc.getClntAllExpnsDtlSeqPG?.length }}
                      {{ "common.entries" | translate }}</span
                    >
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-6">
                    <div class="float-right">
                      <ngb-pagination
                        [(page)]="_sharedSvc.page"
                        [pageSize]="_sharedSvc.pageSize"
                        [maxSize]="10"
                        ngDefaultControl
                        [collectionSize]="_sharedSvc.collectionSize"
                        [rotate]="true"
                        (pageChange)="_finiancialInfoSvc.pageChange()"
                      >
                        <ng-template ngbPaginationPrevious>
                          {{ "common.prev" | translate }}</ng-template
                        >
                        <ng-template ngbPaginationNext>
                          {{ "common.next" | translate }}</ng-template
                        >
                      </ngb-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="button_div">
                <button
                  class="btn login-button-class"
                  type="button"
                  [disabled]="_conditionsService.saveHouseHldData"
                  (click)="_sharedSvc.active = 9"
                  (click)="continueCheckClick()"
                >
                  {{ "common.continue" | translate }}
                </button>
                <button
                  class="btn login-button-class secondaryBtn"
                  type="button"
                  [class.pointerNone]="_sharedSvc.loading"
                  [disabled]="
                    !_conditionsService.saveHouseHldData || _sharedSvc.loading
                  "
                  (click)="saveClntExpnsDtl()"
                  *ngIf="!_conditionsService.readOnlyView_Client"
                >
                  {{ "common.save" | translate }}
                </button>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</section>
<ng-template id="display-modal" #modelPrimary let-c="close" let-d="dismiss">
  <div
    class="modal-body"
    [dir]="
      translate?.currentLang === 'ar' || translate?.currentLang === 'ur'
        ? 'rtl'
        : ''
    "
    [class.fix-direction]="
      translate?.currentLang === 'ar' || translate?.currentLang === 'ur'
    "
  >
    <div class="p-3 pb-0">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center">
            <h4>{{ modelHeading }}</h4>
          </div>
        </div>
      </div>
      <form [formGroup]="incomeForm">
        <div class="row mb-3">
          <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div class="form-floating">
              <select
                id="incmTypKey"
                class="form-control input-radius form-select"
                [ngClass]="incmTypKey.invalid ? 'requiredInput' : ''"
                formControlName="incmTypKey"
                [class.is-invalid]="
                  incmTypKey.invalid && (incmTypKey.dirty || incmTypKey.touched)
                "
              >
                <option
                  *ngFor="
                    let item of _picklistService.INCOME_TYPE_DataClone;
                    let i = index
                  "
                  [value]="item?.refCdSeq"
                >
                  {{ item.refCdDscr }}
                </option>
              </select>
              <div
                *ngIf="
                  incmTypKey.invalid && (incmTypKey.dirty || incmTypKey.touched)
                "
                class="invalid-feedback"
              >
                <div *ngIf="incmTypKey.errors?.['required']">
                  {{ "common.incomeTypeRequire" | translate }}
                </div>
              </div>
              <label for="incmTypKey">{{
                "common.incomeType" | translate
              }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div class="form-floating">
              <input
                id="incmAmt"
                type="number"
                autocomplete="off"
                class="form-control input-radius"
                [ngClass]="incmAmt.invalid ? 'requiredInput' : ''"
                formControlName="incmAmt"
                min="1"
                maxLength="100000"
                [class.is-invalid]="
                  incmAmt.invalid && (incmAmt.dirty || incmAmt.touched)
                "
              />
              <div
                *ngIf="incmAmt.invalid && (incmAmt.dirty || incmAmt.touched)"
                class="invalid-feedback"
              >
                <div *ngIf="incmAmt.errors?.['required']">
                  {{ "common.incomeAmountRequire" | translate }}
                </div>
                <div *ngIf="incmAmt.errors?.['min']">
                  {{ "common.field1DigitRequire" | translate }}
                </div>
                <div *ngIf="incmAmt.errors?.['max']">
                  {{ "common.fieldMustHaveAtMostTenKDigits" | translate }}
                </div>
              </div>
              <label for="incmAmt">{{
                "common.incomeAmount" | translate
              }}</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="button_div">
              <button
                class="btn login-button-class"
                [class.pointerNone]="_sharedSvc.loading"
                [disabled]="_sharedSvc.loading"
                type="button"
                *ngIf="!_finiancialInfoSvc.edit"
                (click)="savePrimaryData()"
              >
                {{ "common.save" | translate }}
              </button>
              <button
                class="btn login-button-class"
                [class.pointerNone]="_sharedSvc.loading"
                [disabled]="_sharedSvc.loading"
                type="button"
                *ngIf="_finiancialInfoSvc.edit"
                (click)="onUpdatePrimaryData()"
              >
                {{ "common.update" | translate }}
              </button>
              <button
                class="btn login-button-class cancelBtn"
                type="reset"
                (click)="d('Cross click')"
                type="reset"
              >
                {{ "common.cancel" | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<ng-template id="display-modal" #modelSecondary let-c="close" let-d="dismiss">
  <div
    class="modal-body"
    [dir]="
      translate?.currentLang === 'ar' || translate?.currentLang === 'ur'
        ? 'rtl'
        : ''
    "
    [class.fix-direction]="
      translate?.currentLang === 'ar' || translate?.currentLang === 'ur'
    "
  >
    <div class="p-3 pb-0">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center">
            <h4>{{ modelHeading }}</h4>
          </div>
        </div>
      </div>
      <form [formGroup]="incomeForm">
        <div class="row mb-3">
          <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div class="form-floating">
              <select
                id="incmTypKey"
                class="form-control input-radius form-select"
                [ngClass]="incmTypKey.invalid ? 'requiredInput' : ''"
                formControlName="incmTypKey"
                [class.is-invalid]="
                  incmTypKey.invalid && (incmTypKey.dirty || incmTypKey.touched)
                "
              >
                <option
                  *ngFor="
                    let item of _picklistService.BUSINESS_RUNNER_DataClone;
                    let i = index
                  "
                  [value]="item?.refCdSeq"
                >
                  {{ item.refCdDscr }}
                </option>
              </select>
              <div
                *ngIf="
                  incmTypKey.invalid && (incmTypKey.dirty || incmTypKey.touched)
                "
                class="invalid-feedback"
              >
                <div *ngIf="incmTypKey.errors?.['required']">
                  {{ "common.incomeTypeRequire" | translate }}
                </div>
              </div>
              <label for="incmTypKey">{{
                "common.incomeType" | translate
              }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div class="form-floating">
              <input
                id="incmAmt"
                type="number"
                autocomplete="off"
                class="form-control input-radius"
                [ngClass]="incmAmt.invalid ? 'requiredInput' : ''"
                formControlName="incmAmt"
                min="1"
                maxLength="100000"
                [class.is-invalid]="
                  incmAmt.invalid && (incmAmt.dirty || incmAmt.touched)
                "
              />
              <div
                *ngIf="incmAmt.invalid && (incmAmt.dirty || incmAmt.touched)"
                class="invalid-feedback"
              >
                <div *ngIf="incmAmt.errors?.['required']">
                  {{ "common.incomeAmountRequire" | translate }}
                </div>
                <div *ngIf="incmAmt.errors?.['min']">
                  {{ "common.field1DigitRequire" | translate }}
                </div>
                <div *ngIf="incmAmt.errors?.['max']">
                  {{ "common.fieldMustHaveAtMostTenKDigits" | translate }}
                </div>
              </div>
              <label for="incmAmt">{{
                "common.incomeAmount" | translate
              }}</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="button_div">
              <button
                class="btn login-button-class"
                [class.pointerNone]="_sharedSvc.loading"
                [disabled]="_sharedSvc.loading"
                type="button"
                *ngIf="!_finiancialInfoSvc.edit"
                (click)="saveSecondaryDataFun()"
              >
                {{ "common.save" | translate }}
              </button>
              <button
                class="btn login-button-class"
                [class.pointerNone]="_sharedSvc.loading"
                [disabled]="_sharedSvc.loading"
                type="button"
                *ngIf="_finiancialInfoSvc.edit"
                (click)="onUpdateSecondaryData()"
              >
                {{ "common.update" | translate }}
              </button>
              <button
                class="btn login-button-class cancelBtn"
                type="reset"
                (click)="d('Cross click')"
                type="reset"
              >
                {{ "common.cancel" | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<ng-template id="display-modal" #modelExp let-c="close" let-d="dismiss">
  <div
    class="modal-body"
    [dir]="
      translate?.currentLang === 'ar' || translate?.currentLang === 'ur'
        ? 'rtl'
        : ''
    "
    [class.fix-direction]="
      translate?.currentLang === 'ar' || translate?.currentLang === 'ur'
    "
  >
    <div class="p-3 pb-0">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center">
            <h4>{{ modelHeading }}</h4>
          </div>
        </div>
      </div>
      <form [formGroup]="expenseForm">
        <div class="row mb-3">
          <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div class="form-floating">
              <select
                id="expTypKey"
                class="form-control input-radius form-select"
                [ngClass]="expTypKey.invalid ? 'requiredInput' : ''"
                formControlName="expTypKey"
                [class.is-invalid]="
                  expTypKey.invalid && (expTypKey.dirty || expTypKey.touched)
                "
              >
                <option
                  *ngFor="
                    let item of _picklistService.HOUSEHOLD_EXPENSES_DataClone;
                    let i = index
                  "
                  [value]="item?.refCdSeq"
                >
                  {{ item.refCdDscr }}
                </option>
              </select>
              <div
                *ngIf="
                  expTypKey.invalid && (expTypKey.dirty || expTypKey.touched)
                "
                class="invalid-feedback"
              >
                <div *ngIf="expTypKey.errors?.['required']">
                  {{ "sideBarMenu.setup.expenseTypeRequire" | translate }}
                </div>
              </div>
              <label for="expTypKey">{{
                "sideBarMenu.setup.expenseType" | translate
              }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div class="form-floating">
              <input
                id="expAmt"
                type="number"
                class="form-control input-radius"
                [ngClass]="expAmt.invalid ? 'requiredInput' : ''"
                formControlName="expAmt"
                min="1"
                max="100000"
                [class.is-invalid]="
                  expAmt.invalid && (expAmt.dirty || expAmt.touched)
                "
              />
              <div
                *ngIf="expAmt.invalid && (expAmt.dirty || expAmt.touched)"
                class="invalid-feedback"
              >
                <div *ngIf="expAmt.errors?.['required']">
                  {{ "common.expenseAmountRequire" | translate }}
                </div>
                <div *ngIf="expAmt.errors?.['min']">
                  {{ "common.field1DigitRequire" | translate }}
                </div>
                <div *ngIf="expAmt.errors?.['max']">
                  {{ "common.fieldMustHaveAtMostTenKDigits" | translate }}
                </div>
              </div>
              <label for="expAmt">{{
                "common.expenseAmount" | translate
              }}</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="button_div">
              <button
                class="btn login-button-class"
                [class.pointerNone]="_sharedSvc.loading"
                [disabled]="_sharedSvc.loading"
                type="button"
                *ngIf="!_finiancialInfoSvc.edit"
                (click)="onSubmitExp()"
              >
                {{ "common.save" | translate }}
              </button>
              <button
                class="btn login-button-class"
                [class.pointerNone]="_sharedSvc.loading"
                [disabled]="_sharedSvc.loading"
                type="button"
                *ngIf="_finiancialInfoSvc.edit"
                (click)="onUpdateExp()"
              >
                {{ "common.update" | translate }}
              </button>
              <button
                class="btn login-button-class cancelBtn"
                type="reset"
                (click)="d('Cross click')"
                type="reset"
              >
                {{ "common.cancel" | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
