<section>
    <div class="sidebar" [class.sidebar-close]="!_sharedSvc.openSidebar">
        <app-sidebar></app-sidebar>
    </div>
    <div class="home-section">
        <div class="home-main" [class.sidebar-close_home-main]="!_sharedSvc.openSidebar">
            <app-navbar></app-navbar>
            <div class="home-view" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
                [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
                <section>
                    <div class="row mb-3">
                        <div class="col-sm-8 col-md-8 col-lg-8">
                            <h4>{{'common.region' | translate}} </h4>
                        </div>
                        <div class="col-sm-4 col-md-4 col-lg-4">
                            <div class="add-button" (click)="openModal(area , false)"><i class="fa fa-plus"></i></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <div class="dashboard-card loan-table">
                                <div class="row mb-3">
                                    <div class="col-sm-7 col-md-7 col-lg-8">
                                        <ng-container *ngIf="_sharedSvc.pageSize >= 10">
                                            <span>{{'common.show' | translate}}</span>
                                            <input type="number" class="m-1 text-center" style="width: 45px;">
                                            <span>{{'common.entries' | translate}}</span>
                                        </ng-container>
                                    </div>
                                    <div class="col-sm-5 col-md-5 col-lg-4">
                                        <div class="inside-icon">
                                            <i class="fa fa-search"></i>
                                            <input type="search" class="form-control search-input"
                                                placeholder="{{'common.search' | translate}}">
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                        <div class="table-responsive">
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <td>{{'region.regionId' | translate}}</td>
                                                        <td>{{'region.regionName' | translate}}</td>
                                                        <td>{{'common.description' | translate}}</td>
                                                        <td>{{'common.status' | translate}}</td>
                                                        <td class="text-center">{{'common.action' | translate}}</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let list of _SetupService.allRegionList">
                                                        <td>{{list.regionSequence}}</td>
                                                        <td class="clr186ed1 pointer" (click)="areaFn(list)">
                                                            {{list.regionName}}</td>
                                                        <td>{{list.regionDescription}}</td>
                                                        <td>{{list.regionStatusKey === "2132" ?
                                                            this.translate.instant('common.inActive') :
                                                            this.translate.instant('common.active')}}
                                                        </td>
                                                        <td class="text-center">
                                                            <div class="action-inner">
                                                                <i class="fa fa-user-o clr186ed1"
                                                                    (click)="openModalEmployeeAssignment(empAssignment, list)"></i>

                                                                <i class="fa fa-pencil"
                                                                    (click)="editArea(area ,list.regionSequence , true)"></i>
                                                                <i class="fa fa-trash"
                                                                    (click)="deleteRecord(list.regionSequence)"></i>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</section>


<ng-template id="display-modal" #area let-c="close" let-d="dismiss">
    <div class="modal-body" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
        [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
        <div class="p-3 pb-0">
            <div class="row mb-3">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="text-center">
                        <h4>{{isEdit === true ? this.translate.instant('common.edit') :
                            this.translate.instant('common.add' | translate)}}
                            {{'common.region' | translate}}
                        </h4>
                    </div>
                </div>
            </div>
            <form [formGroup]="addRegionformData" (ngSubmit)="submitRegionForm(addRegionformData)">
                <div class="row mb-3">
                    <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                        <div class="form-floating">
                            <input type="text" style="text-transform: capitalize;" formControlName="regionName"
                                autocomplete="off" class="form-control input-radius"
                                [class.is-invalid]="regionName.invalid && (regionName.dirty || regionName.touched)">
                            <div *ngIf="regionName.invalid && (regionName.dirty || regionName.touched)"
                                class="invalid-feedback">
                                <div *ngIf="regionName.errors?.['required']">
                                    {{'region.regionNameRequire' | translate}}
                                </div>

                            </div>
                            <label> {{'region.regionName' | translate}}</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                        <div class="form-floating">
                            <select name="gndrKey" id="gndrKey" class="form-control input-radius form-select"
                                formControlName="regionStatusKey"
                                [class.is-invalid]="stsKey.invalid && (stsKey.dirty || stsKey.touched)">
                                <option *ngFor="let item of _picklistService.SHROFF_STATUS_Data; let i = index"
                                    [value]="item?.refCdSeq">
                                    {{ item.refCdDscr }}
                                </option>
                            </select>

                            <div *ngIf="stsKey.invalid && (stsKey.dirty || stsKey.touched)" class="invalid-feedback">
                                <div *ngIf="stsKey.errors?.['required']">
                                    {{'common.statusRequire' | translate}}
                                </div>

                            </div>
                            <label>{{'common.status' | translate}}</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                        <div class="form-floating">
                            <input type="text" style="text-transform: capitalize;" formControlName="regionDescription"
                                autocomplete="off" class="form-control input-radius"
                                [class.is-invalid]="regionDescription.invalid && (regionDescription.dirty || regionDescription.touched)">
                            <div *ngIf="regionDescription.invalid && (regionDescription.dirty || regionDescription.touched)"
                                class="invalid-feedback">
                                <div *ngIf="regionDescription.errors?.['required']">
                                    {{'region.regionDescriptionRequire' | translate}}
                                </div>

                            </div>
                            <label> {{'common.description' | translate}}</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="button_div">
                            <button class="btn login-button-class" [class.pointerNone]="_sharedSvc.loading"
                                [disabled]="_sharedSvc.loading">{{'common.ok' | translate}}</button>
                            <button class="btn login-button-class cancelBtn" (click)="d('Cross click')"
                                type="reset">{{'common.cancel' | translate}}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>

<ng-template id="display-modal" #empAssignment let-c="close" let-d="dismiss">
    <div class="modal-body" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
        [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
        <div class="p-3 pb-0">
            <div class="row mb-4">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="text-center">
                        <h4>{{'common.employeeAssignment' | translate}}</h4>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-floating position-relative mb-3">
                        <select (change)="onSelectManager($event)" [(ngModel)]="selectedManager"
                            [disabled]="!!selectedManager" name="gndrKey" id="gndrKey"
                            class="form-control input-radius form-select">
                            <option *ngFor="let item of _SetupService.allEMPLIST; let i = index" [value]="item?.empSeq">
                                {{ item.empNm }}
                            </option>
                            <!-- <option value=""></option>
                            <option value="1">Option 1</option>
                            <option value="2">Option 2</option>
                            <option value="3">Option 3</option> -->
                        </select>
                        <div (click)="clearSelectedManager()"
                            [ngClass]="{'remove-selection-box': selectedManager, 'd-none': !selectedManager}">
                            <i class="fa fa-times icon"></i>
                        </div>
                        <!-- <div *ngIf="stsKey.invalid && (stsKey.dirty || stsKey.touched)" class="invalid-feedback">
                            <div *ngIf="stsKey.errors?.['required']">
                                Status is required.
                            </div>

                        </div> -->
                        <label> {{'region.regionManager' | translate}}</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="button_div">
                        <button (click)="SubmitRegionManager()" class="btn login-button-class"
                            [class.pointerNone]="_sharedSvc.loading" [disabled]="_sharedSvc.loading">{{'common.ok' |
                            translate}}</button>
                        <button class="btn login-button-class cancelBtn" (click)="d('Cross click')"
                            type="reset">{{'common.cancel' | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>