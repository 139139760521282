export class GoldRate{
    goldRateSeq:number;
    mrktRate:number;
    rateDt:string;
    rateStatusKey:number;
    statusDt:string;
    aprvdById:string;
    crtdBy:string;
    crtdDt:string;
    lastUpdBy:string;
    lastUpdDt:string;
    delFlg:boolean;
}