import { Component, OnInit, DestroyRef, inject } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from '../../shared/service/shared.service';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { OtherOutstandingService } from '../../clint-onboarding/other-outstanding/shared/service/other-outstanding.service';
import { SharedPopupService } from '../../shared/service/shared-popup.service';
import { PicklistService } from '../../shared/pickList/picklist.service';
import { DatePipe } from "@angular/common";
import { CreditAppService } from '../add-individual-credit-app/client-credit-info/shared/service/credit-app.service';
import { RecoveryService } from '../../recovery/shared/service/recovery.service';
import { userDataClientCreditService } from '../../shared/service/userData-client-credit.service';
import { ClintOnboardingService } from '../../clint-onboarding/shared/service/clint-onboarding.service';
import { RouteInfo } from 'src/app/auth/model/route-info';
import { RoutingConditionsService } from '../../clint-onboarding/shared/service/routing-conditions.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-individual-credit-app',
  templateUrl: './individual-credit-app.component.html',
  styleUrls: ['./individual-credit-app.component.css']
})
export class IndividualCreditAppComponent implements OnInit {
  allowedRoutes: RouteInfo[];

  getAllOutstandingPG: any;
  maskedCNIC: string;
  saveCNICModel: any;
  saveCnicData: any;
  modelHeading: any;
  searchPaymentForm: FormGroup;
  _recoverydata_0: any;
  _userData: any;
  #destroyRef = inject(DestroyRef);

  showBtn: boolean = false


  constructor(
    public router: Router,
    public _sharedSvc: SharedService,
    public _creditAppSvc: CreditAppService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    public _sharedPopupSvc: SharedPopupService,
    public _picklistService: PicklistService,
    public route: ActivatedRoute,
    public _recoveryService: RecoveryService,
    public _userDataClientCreditService: userDataClientCreditService,
    public _clintOnboardingSvc: ClintOnboardingService,
    public _conditionsService: RoutingConditionsService,
    public translate: TranslateService,

  ) {
    this.#destroyRef.onDestroy(() => {

      sessionStorage.removeItem("CCI")
      sessionStorage.removeItem("CLTD")
      sessionStorage.removeItem("CDD")
    });
    console.log("contructor called")
    this._recoveryService._recoverydata = [];
    this.allowedRoutes = JSON.parse(sessionStorage.getItem("routeInfo"));

    this.showBtn = this.allowedRoutes.length > 0 && this.allowedRoutes.some(x => x.sbModUrl === 'addNewCreditAPP');
    console.log(this.showBtn)
  }

  ngOnInit(): void {
    this.searchPaymentForm = this.ValidationSearchPayment();
    // this._creditAppSvc.InItGetCAList();
    this._clintOnboardingSvc.InItGetApprovedClients();
    if (localStorage.getItem('SaveCnicData')) {
      this.saveCnicData = localStorage.getItem('SaveCnicData');
      this.saveCnicData = JSON.parse(this.saveCnicData);
    }

    this._picklistService.INSTITUTE();
    // this._creditAppSvc.InItGetAllClientOtherOutstanding(this.saveCnicData?.clntSeq);


    if (this._conditionsService.readOnlyView_Credit == true) {
      this._userDataClientCreditService.setSharedData(this._recoveryService._recoverydata);
      this._recoverydata_0 = this._recoveryService._recoverydata[0];
    }
    localStorage.setItem('reload', "true");
    localStorage.removeItem('SaveCnicData');
    localStorage.removeItem('crdtApp');
    localStorage.removeItem('crdtCltrlHdrSeq');
    localStorage.removeItem('creditData')

  }

  ValidationSearchPayment() {
    return new FormGroup({
      clntId: new FormControl(null, []),
      // cnic: new FormControl(3465451615646, []),
      cnic: new FormControl("", []),
      phone: new FormControl(null, []),
    });
  }


  searchPaymentSchedule(): void {
    let clntId = this.clntId.value;
    let cnic = this.cnic.value;
    let phone = this.phone.value;
    this._sharedSvc.showSpinner();
    this._recoveryService.srch_pymt_sched(clntId, cnic, phone).subscribe({
      next: (response: any) => {
        if (response) {
          this._sharedSvc.hideSpinner();
          console.log(response);
          this._recoveryService._recoverydata = response;
          if (this.allowedRoutes.length > 0 && this.allowedRoutes.some(x => x.sbModUrl === 'addNewCreditAPP')) {
            this.showBtn = response[0]?.caStatus === 'Completed' || response[0]?.caStatus === 'Active' ? false : response[0]?.caStatus === null || response[0]?.caStatus === 'Rejected' ? true : false;
          }
          console.log(response)
          this._userDataClientCreditService.setSharedData(response);
          sessionStorage.setItem("shareUserData", JSON.stringify(response))
          this._recoverydata_0 = this._recoveryService._recoverydata[0];

        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }

  DeletePopup(data: any) {

    this._sharedPopupSvc.popup("", this.translate.instant('common.areSelectRecordDelete'), "", this.translate.instant('common.delete'), this.translate.instant('common.cancelSmall'), null, this.translate.instant('common.clientSequenceId') + " : " + data?.crdtAppSeq).then((confirmed: any) => {
      if (confirmed) {
        // Delete function call
        
        this._creditAppSvc.creditAppModel = Object.assign({}, data);
        this._creditAppSvc.creditAppModel.delFlg = true;
        this._creditAppSvc.creditAppModel.aprvdAmt = data.aprvdAmount;
        this._creditAppSvc.creditAppModel.portSeq = data.portId;
        this._creditAppSvc.creditAppModel.prdSeq = data.productID;
        this._creditAppSvc.creditAppModel.rcmndAmt = data.rcmndAmount;
        this._creditAppSvc.creditAppModel.rqstdAmt = data.rqstdAmount;

        this._sharedSvc.showSpinner();
        this._creditAppSvc.SaveCreditApp().subscribe({
          next: (response) => {
            if (response) {
              this._sharedSvc.hideSpinner();
              this._sharedSvc.success(this._sharedSvc.deleteMsg);
              this._creditAppSvc.InItGetCAList();
              this.searchPaymentSchedule()
            }
          },
          error: (e) => {
            this._sharedSvc.hideSpinner();
            if (e.status == 401) {
              this._sharedSvc.error(this._sharedSvc.authMsg);
              this._sharedSvc.logout();
              return;
            }
            if (e?.error) {
              Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
                this._sharedSvc.error(value);
              });
            }
            if (e?.error?.errorMessage) {
              this._sharedSvc.error(e?.error?.error);
            }
          },
          // complete: () => console.info('complete')
        })
        // console.log("Delete Success");
      }
      else {
        //in case of cancel
        return;
      }
    })
      .catch(() => {
        // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)');
        return;
      });
  }

  Edit(data: any) {

    if (data?.caStatus === 'In Process' && this.allowedRoutes[0].rolId === 'CMO') {

      localStorage.setItem('creditData', JSON.stringify(data));
      this.router.navigate(['/edit-individual-group-info/' + data.crdtAppSeq]);

    } else if (data.caStatus == "Draft") {
      localStorage.setItem('creditData', JSON.stringify(data));
      this.router.navigate(['/edit-individual-group-info/' + data.crdtAppSeq]);
    }
    else {
      this._sharedSvc.error(this.translate.instant('toast.updateError'))
      return;
    }


  }

  openModal() {
    this.router.navigate(['/add-individual-group-info']);
  }

  get clntId() { return this.searchPaymentForm.get("clntId") }
  get cnic() { return this.searchPaymentForm.get("cnic") }
  get phone() { return this.searchPaymentForm.get("phone") }

}
