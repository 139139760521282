import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from '../../shared/service/shared.service';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, Validators , NgForm} from '@angular/forms';
import { ClintOnboardingService } from '../../clint-onboarding/shared/service/clint-onboarding.service';
import { AdminService } from '../shared/service/admin.service';
import { IncomingBag } from '../shared/model/incoming-bag';
import { Safe } from '../shared/model/safe-body';
import { TranslateService } from '@ngx-translate/core';
import { PicklistService } from '../../shared/pickList/picklist.service';
import { SetupService } from '../../setup/shared/service/setup.service';
import { CreditCollateralService } from '../../Individual-Group-Loan/add-individual-credit-app/client-credit-collateral-detail/shared/service/credit-collateral.service';


@Component({
  selector: 'app-incoming-bags',
  templateUrl: './incoming-bags.component.html',
  styleUrls: ['./incoming-bags.component.css']
})
export class IncomingBagsComponent  implements OnInit {

  
  loadingIndicator: boolean = false;
  rows: any;

  page: number = 5;
  pageSize: number = 5;
  collectionSize: number = 5;

  BagPlaceForm!: FormGroup;
  BagIncomingForm!: FormGroup;
  submitted = false;
  // currentDate: any = new Date().toISOString().split("T")[0];
  onBoardingTablePG: any;
  maskedCNIC: string;
  bags:IncomingBag[];
  actionType:number=1;//1 for add,2 for remove
  userId: any;
  vaultList: any;
  selectedVaultId: any;
  branchSeq:any;
  AppSeq:any;
  bagOnLct: any;
  constructor(
    private modalService: NgbModal,
    public router: Router,
    public _sharedSvc: SharedService,
    public _clintOnboardingSvc: ClintOnboardingService,
    public _creditCollateralSvc: CreditCollateralService,
    public adminService:AdminService,
    private formBuilder: FormBuilder,
    public _picklistService: PicklistService,
    public _setupService:SetupService,
    public translate: TranslateService

  ) { 
    this.adminService.getIncomingBags( (res)=>{// console.log(res);
      if(res) this.bags=res; });

      let jwt=sessionStorage.getItem("JWT");
      const user=JSON.parse(jwt);   
      this.userId=user.preferred_username;
      let branchid = localStorage.getItem("BranchSeqIDByPort");
      this.branchSeq = JSON.parse(branchid)?.brnchSeq;
    }
    
    ngOnInit(): void {   
      this.createBagPlaceForm();
      // this.createBagIncomingForm();
      this._picklistService.BAG_PLACE_STATUS();   
      this._setupService.get_vault_list(); 
      this._setupService.get_branch_bag_list(this.branchSeq);
      this._creditCollateralSvc.getAllShroffBags(this.branchSeq, 2504);     
    }
   
  pageChange() {
    this.collectionSize = this._clintOnboardingSvc.onBoardingTable.length;
    this.onBoardingTablePG = this._clintOnboardingSvc.onBoardingTable.slice(
      (this.page - 1) * this.pageSize,
      (this.page - 1) * this.pageSize + this.pageSize
    );
    this.onBoardingTablePG;
  }

  createBagPlaceForm() {
    this.BagPlaceForm = this.formBuilder.group({
      crdtAppSeq: ["", [Validators.required]],
      safeSeq: ["", Validators.required],
      statusKey: ["", Validators.required],
    });
  }
  get crdtAppSeq() {
    return this.BagPlaceForm.get("crdtAppSeq");
  }
  get safeSeq() {
    return this.BagPlaceForm.get("safeSeq");
  }
  get statusKey() {
    return this.BagPlaceForm.get("statusKey");
  }

  // createBagIncomingForm() {
  //   this.BagIncomingForm = this.formBuilder.group({
  //     vaultId: ["", [Validators.required]],
  //     safeId: ["", Validators.required],
  //     brnchBGNum: ["", Validators.required],
  //     shrffBGNum: ["", Validators.required],
  //     clntName: ["", Validators.required],
  //     cltrlValue: ["", Validators.required],
  //     // status: ["", Validators.required],
  //     cltrlDetails: ["", Validators.required],
  //   });
  // }
  // get vaultId() {
  //   return this.BagIncomingForm.get("vaultId");
  // }
  // get safeId() {
  //   return this.BagIncomingForm.get("safeId");
  // }
  // get brnchBGNum() {
  //   return this.BagIncomingForm.get("brnchBGNum");
  // }
  // get shrffBGNum() {
  //   return this.BagIncomingForm.get("shrffBGNum");
  // }
  // get clntName() {
  //   return this.BagIncomingForm.get("clntName");
  // }
  // get cltrlValue() {
  //   return this.BagIncomingForm.get("cltrlValue");
  // }
  // // get status() {
  //   //   return this.BagPlaceForm.get("status");
  //   // }
  // get cltrlDetails() {
  //     return this.BagIncomingForm.get("cltrlDetails");
  //   }

  openModal(content: any,data:any,type:any) {
    localStorage.removeItem("selectedOrderData");
    this.actionType=type==='add'?1:2;
    if(data !== null){
     let item= this.bags.find(item=>item.crdtAppSeq === data);
     this.BagPlaceForm.get("crdtAppSeq").setValue(item.crdtAppSeq);
     this.BagPlaceForm.get("safeSeq").setValue(item.safeId);
     this.BagPlaceForm.get("statusKey").setValue(item.cltrlLctnStsKey);
    }
    this._setupService.safeList=[];
    this.modalService.open(content, {
      // backdrop: "static",
      // keyboard: false,
      windowClass: "popupWidth1k",
      // backdropClass: 'light-blue-backdrop',
      centered: true
    });
  }
  onVaultIdChange(event: any): void {
    this.selectedVaultId = (event.target as HTMLSelectElement).value;;
    this._setupService.get_safe_list(this.selectedVaultId); 
    this.BagIncomingForm.get('safeId')?.enable();
  }

  navigate() {
    this.router.navigate(['/disbursement-form']);
  }

  // submitBagIncomingForm(){
  //    let model ={
  //     cltrlLctnSeq:null,
  //     ...this.BagPlaceForm.value,
  //     delFlg:this.actionType===1?false:true,      
  //    }

  //    this.adminService.addBagLctn(model, (res)=>{ // console.log(res);
  //     if(res){
        
  //     }
  //   });
  //   this.modalService.dismissAll();
  // }
  submitBagPlaceForm(){
    this.adminService.getBagLctn(this.crdtAppSeq.value,(res)=>{// console.log(res);
      if(res) this.bagOnLct=res; });
let existedData=this.bagOnLct?.find(item => item.safeSeq ===parseInt(this.safeSeq.value) && item.statusKey === parseInt(this.statusKey.value))


     let model ={
      cltrlLctnSeq:existedData?existedData.cltrlLctnSeq:null,
      ...this.BagPlaceForm.value,
      delFlg:this.actionType===1?false:true,      
     }
     this.adminService.addBagLctn(model, (res)=>{ // console.log(res);
      if(res){
        this.adminService.getIncomingBags( (res)=>{// console.log(res);
          if(res) this.bags=res;
          this._sharedSvc.msg = "Bag Status updated";  
        }); 
      }
    });
    this.modalService.dismissAll();
  }

  addToSafe(i){
    let item= this.bags[i];
    let b=new Safe();
    b.aprvdById=this.userId;  b.crdtAppSeq=item.crdtAppSeq; b.crtdBy=this.userId; b.crtdDt=new Date().toISOString();
    b.delFlg=false; b.safeSeq=0; b.statusDt=new Date().toISOString(); b.statusKey=0;
  }

  removeFromSafe(i){
    let item= this.bags[i];
    let b=new Safe();
    b.aprvdById=this.userId;  b.crdtAppSeq=item.crdtAppSeq;  b.crtdBy=this.userId; b.crtdDt=new Date().toISOString();
    b.delFlg=false; b.safeSeq=0; b.statusDt=new Date().toISOString(); b.statusKey=0;
  }

  hasSafe(i){ let item= this.bags[i]; return (item.safeId!=null || parseInt(item.safeId)>0);}
}