<section>
  <div class="sidebar" [class.sidebar-close]="!_sharedSvc.openSidebar">
    <app-sidebar></app-sidebar>
  </div>
  <div class="home-section">
    <div
      class="home-main"
      [class.sidebar-close_home-main]="!_sharedSvc.openSidebar"
    >
      <app-navbar></app-navbar>
      <div
        class="home-view"
        [dir]="
          translate?.currentLang === 'ar' || translate?.currentLang === 'ur'
            ? 'rtl'
            : ''
        "
        [class.fix-direction]="
          translate?.currentLang === 'ar' || translate?.currentLang === 'ur'
        "
      >
        <section>
          <div class="row mb-3">
            <div class="col-sm-8 col-md-8 col-lg-8">
              <h4>{{ "common.portfolio" | translate }}</h4>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4">
              <div class="display-flex float-right">
                <div class="back-btn me-2" (click)="branchFn()">
                  <i class="fa fa-reply"></i>
                </div>
                <div
                  class="add-button"
                  (click)="openModalAddEditPortfolio(addPortfolioModal, false)"
                >
                  <i class="fa fa-plus"></i>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="dashboard-card loan-table">
                <div class="row mb-3">
                  <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="display-flex">
                      <b class="me-3 fix-me-3">
                        <span class="me-2"
                          >{{ "common.branchId" | translate }}: </span
                        ><span class="clr186ed1">{{ branchID }}</span>
                      </b>
                      <b>
                        <span class="me-2"
                          >{{ "common.branchName" | translate }}: </span
                        ><span class="clr186ed1">{{ branchName }}</span>
                      </b>
                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-sm-7 col-md-7 col-lg-8">
                    <ng-container *ngIf="_sharedSvc.pageSize >= 10">
                      <span>{{ "common.show" | translate }}</span>
                      <input
                        type="number"
                        class="m-1 text-center"
                        style="width: 45px"
                      />
                      <span>{{ "common.entries" | translate }}</span>
                    </ng-container>
                  </div>
                  <div class="col-sm-5 col-md-5 col-lg-4">
                    <div class="inside-icon">
                      <i class="fa fa-search"></i>
                      <input
                        type="search"
                        class="form-control search-input"
                        placeholder="{{ 'common.search' | translate }}"
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="table-responsive">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <td>{{ "common.portfolioId" | translate }}</td>
                            <td>{{ "common.portfolioName" | translate }}</td>
                            <td>{{ "common.description" | translate }}</td>
                            <td>{{ "common.status" | translate }}</td>
                            <td class="text-center">
                              {{ "common.action" | translate }}
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <ng-container
                            *ngIf="
                              !_SetupService.allbranchPortList?.error &&
                              _SetupService.allbranchPortList?.length > 0
                            "
                          >
                            <tr
                              *ngFor="
                                let list of _SetupService.allbranchPortList
                              "
                            >
                              <td>{{ list.portSequence }}</td>
                              <td>{{ list.portName }}</td>
                              <td>{{ list.portDescription }}</td>
                              <td>
                                {{
                                  list.portStatusKey === "2132"
                                    ? this.translate.instant("common.inActive")
                                    : this.translate.instant("common.active")
                                }}
                              </td>
                              <td class="text-center">
                                <div class="action-inner">
                                  <i
                                    class="fa fa-tablet-alt"
                                    (click)="
                                      openModalTabletAssignment(
                                        tabletAssignmentModal
                                      )
                                    "
                                  ></i>
                                  <i
                                    class="fa fa-map-marker-alt"
                                    (click)="
                                      openModalAssignLocation(
                                        assignLocationModal,
                                        list.portSequence
                                      )
                                    "
                                  ></i>
                                  <i
                                    class="fa fa-user-o clr186ed1"
                                    (click)="
                                      openModalEmployeeAssignment(
                                        employeeAssignment,
                                        list.portSequence
                                      )
                                    "
                                  ></i>
                                  <i
                                    class="fa fa-users"
                                    aria-hidden="true"
                                    (click)="community(list.portSequence)"
                                  ></i>
                                  <i
                                    class="fa fa-pencil"
                                    (click)="
                                      editportfolio(
                                        addPortfolioModal,
                                        list.portSequence,
                                        true
                                      )
                                    "
                                  ></i>
                                  <i
                                    class="fa fa-trash"
                                    (click)="deleteRecord(list.portSequence)"
                                  ></i>
                                </div>
                              </td>
                            </tr>
                          </ng-container>
                          <ng-container
                            *ngIf="_SetupService.allbranchPortList?.error"
                          >
                            <tr>
                              <td colspan="7" class="text-center">
                                {{ _SetupService.allbranchPortList.error }}
                              </td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>

<!-- Add Portfolio Modal PopUp Begin -->
<ng-template
  id="display-modal"
  #addPortfolioModal
  let-c="close"
  let-d="dismiss"
>
  <div
    class="modal-body"
    [dir]="
      translate?.currentLang === 'ar' || translate?.currentLang === 'ur'
        ? 'rtl'
        : ''
    "
    [class.fix-direction]="
      translate?.currentLang === 'ar' || translate?.currentLang === 'ur'
    "
  >
    <div class="p-3 pb-0">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center mb-3">
            <h4>
              {{
                isEdit === true
                  ? this.translate.instant("common.edit")
                  : this.translate.instant("common.add")
              }}
              {{ "common.portfolio" | translate }}
            </h4>
          </div>
        </div>
      </div>
      <form
        [formGroup]="addBPortfolioformdata"
        (ngSubmit)="submitportfolio(addBPortfolioformdata)"
      >
        <div class="row mb-3">
          <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
            <div class="form-floating">
              <input
                type="text"
                style="text-transform: capitalize"
                formControlName="portName"
                autocomplete="off"
                class="form-control input-radius"
                [class.is-invalid]="
                  portName.invalid && (portName.dirty || portName.touched)
                "
              />
              <div
                *ngIf="portName.invalid && (portName.dirty || portName.touched)"
                class="invalid-feedback"
              >
                <div *ngIf="portName.errors?.['required']">
                  {{ "common.portfolioNameRequire" | translate }}
                </div>
              </div>
              <label> {{ "common.portfolioName" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
            <div class="form-floating">
              <select
                name="gndrKey"
                id="gndrKey"
                class="form-control input-radius form-select"
                formControlName="portStatusKey"
                [class.is-invalid]="
                  portStatusKey.invalid &&
                  (portStatusKey.dirty || portStatusKey.touched)
                "
              >
                <option
                  *ngFor="
                    let item of _picklistService.SHROFF_STATUS_Data;
                    let i = index
                  "
                  [value]="item?.refCdSeq"
                >
                  {{ item.refCdDscr }}
                </option>
              </select>

              <div
                *ngIf="
                  portStatusKey.invalid &&
                  (portStatusKey.dirty || portStatusKey.touched)
                "
                class="invalid-feedback"
              >
                <div *ngIf="portStatusKey.errors?.['required']">
                  {{ "common.statusRequire" | translate }}
                </div>
              </div>
              <label>{{ "region.branch.portfolioStatus" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
            <div class="form-floating">
              <input
                type="text"
                style="text-transform: capitalize"
                formControlName="portDescription"
                autocomplete="off"
                class="form-control input-radius"
                [class.is-invalid]="
                  portDescription.invalid &&
                  (portDescription.dirty || portDescription.touched)
                "
              />
              <div
                *ngIf="
                  portDescription.invalid &&
                  (portDescription.dirty || portDescription.touched)
                "
                class="invalid-feedback"
              >
                <div *ngIf="portDescription.errors?.['required']">
                  {{ "common.descriptionRequire" | translate }}
                </div>
              </div>
              <label>{{
                "region.branch.portfolioDescription" | translate
              }}</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="button_div">
              <button
                class="btn login-button-class"
                [class.pointerNone]="_sharedSvc.loading"
                [disabled]="_sharedSvc.loading"
              >
                {{ "common.ok" | translate }}
              </button>
              <button
                class="btn login-button-class cancelBtn"
                (click)="d('Cross click')"
                type="reset"
              >
                {{ "common.cancel" | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<!-- Add Portfolio Modal PopUp Begin -->

<!-- Assign Device to Branch Modal PopUp Begin -->
<ng-template
  id="display-modal"
  #tabletAssignmentModal
  let-c="close"
  let-d="dismiss"
>
  <div
    class="modal-body"
    [dir]="
      translate?.currentLang === 'ar' || translate?.currentLang === 'ur'
        ? 'rtl'
        : ''
    "
    [class.fix-direction]="
      translate?.currentLang === 'ar' || translate?.currentLang === 'ur'
    "
  >
    <div class="p-3 pb-0">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center">
            <h4>{{ "region.branch.assignedTablet" | translate }}</h4>
          </div>
        </div>
      </div>
      <form>
        <div class="row mb-3">
          <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
            <div class="form-floating">
              <select class="form-control form-select input-radius">
                <option>{{ "common.select" | translate }}</option>
                <option>{{ "common.select" | translate }}</option>
                <option>{{ "common.select" | translate }}</option>
              </select>
              <label> {{ "region.branch.deviceUniqueId" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
            <div class="form-floating">
              <input type="date" class="form-control input-radius" />
              <label>{{ "common.identifier" | translate }}</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="button_div">
              <button
                class="btn login-button-class"
                [class.pointerNone]="_sharedSvc.loading"
                [disabled]="_sharedSvc.loading"
              >
                {{ "region.branch.registerDevice" | translate }}
              </button>
              <button
                class="btn login-button-class cancelBtn"
                (click)="d('Cross click')"
                type="reset"
              >
                {{ "common.cancel" | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<!-- Assign Device to Branch Modal PopUp Begin -->

<!-- Assign Location Modal PopUp Begin -->
<ng-template
  id="display-modal"
  #assignLocationModal
  let-c="close"
  let-d="dismiss"
>
  <div
    class="modal-body"
    [dir]="
      translate?.currentLang === 'ar' || translate?.currentLang === 'ur'
        ? 'rtl'
        : ''
    "
    [class.fix-direction]="
      translate?.currentLang === 'ar' || translate?.currentLang === 'ur'
    "
  >
    <div class="p-3 pb-0">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center">
            <h4>{{ "common.assignLocation" | translate }}</h4>
          </div>
        </div>
      </div>
      <form>
        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
              <tr>
                <td>&nbsp;</td>
                <td>{{ "common.province" | translate }}</td>
                <td>{{ "common.district" | translate }}</td>
                <td>{{ "common.tehsil" | translate }}</td>
                <td>{{ "common.uc" | translate }}</td>
                <td>{{ "common.city" | translate }}</td>
              </tr>
            </thead>
            <tbody>
              <ng-container
                *ngIf="
                  !_picklistService.locationData?.error &&
                  _picklistService.locationData?.length > 0
                "
              >
                <tr *ngFor="let list of _picklistService.locationData">
                  <td>
                    <input
                      type="checkbox"
                      (change)="pushLocationInList($event)"
                      [checked]="isLocationSeqPresent(list.locationSeq)"
                      class="form-check-input pointer"
                      [value]="list.locationSeq"
                    />
                  </td>
                  <td>{{ list.stateName }}</td>
                  <td>{{ list.districtName }}</td>
                  <td>{{ list.thslName }}</td>
                  <td>{{ list.ucName }}</td>
                  <td>{{ list.cityName }}</td>
                </tr>
              </ng-container>
              <ng-container *ngIf="_picklistService.locationData?.error">
                <tr>
                  <td colspan="6" class="text-center">
                    {{ _picklistService.locationData.error }}
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="button_div">
              <button
                (click)="submitLocation()"
                class="btn login-button-class"
                [class.pointerNone]="_sharedSvc.loading"
                [disabled]="_sharedSvc.loading"
              >
                {{ "common.ok" | translate }}
              </button>
              <button
                class="btn login-button-class cancelBtn"
                (click)="d('Cross click')"
                type="reset"
              >
                {{ "common.cancel" | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<!-- Assign Location Modal PopUp End -->
<!-- Assign Location Modal PopUp Begin -->
<ng-template
  id="display-modal"
  #assignComunityModal
  let-c="close"
  let-d="dismiss"
>
  <div
    class="modal-body"
    [dir]="
      translate?.currentLang === 'ar' || translate?.currentLang === 'ur'
        ? 'rtl'
        : ''
    "
    [class.fix-direction]="
      translate?.currentLang === 'ar' || translate?.currentLang === 'ur'
    "
  >
    <div class="p-3 pb-0">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center">
            <h4>{{ "common.assignCommunity" | translate }}</h4>
          </div>
        </div>
      </div>
      <form>
        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
              <tr>
                <td>&nbsp;</td>
                <td>{{ "common.communityId" | translate }}</td>
                <td>{{ "common.communityName" | translate }}</td>
                <td>{{ "common.communityComment" | translate }}</td>
              </tr>
            </thead>
            <tbody>
              <ng-container
                *ngIf="
                  !_SetupService.portCommunityList?.error &&
                  _SetupService.portCommunityList?.length > 0
                "
              >
                <tr *ngFor="let list of _SetupService.portCommunityList">
                  <td>
                    <input
                      type="checkbox"
                      (change)="pushLocationInList($event)"
                      [checked]="isLocationSeqPresent(list.locationSeq)"
                      class="form-check-input pointer"
                      [value]="list.locationSeq"
                    />
                  </td>
                  <td>{{ list.cmntyCd }}</td>
                  <td>{{ list.cmntyNm }}</td>
                  <td>{{ list.cmntyCmnt }}</td>
                </tr>
              </ng-container>
              <ng-container *ngIf="_SetupService.portCommunityList?.error">
                <tr>
                  <td colspan="6" class="text-center">
                    {{ _SetupService.portCommunityList.error }}
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="button_div">
              <button
                (click)="submitLocation()"
                class="btn login-button-class"
                [class.pointerNone]="_sharedSvc.loading"
                [disabled]="_sharedSvc.loading"
              >
                {{ "common.ok" | translate }}
              </button>
              <button
                class="btn login-button-class cancelBtn"
                (click)="d('Cross click')"
                type="reset"
              >
                {{ "common.cancel" | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<!-- Assign Location Modal PopUp End -->

<!-- Assign Employee to Branch Modal PopUp Begin -->
<ng-template
  id="display-modal"
  #employeeAssignment
  let-c="close"
  let-d="dismiss"
>
  <div
    class="modal-body"
    [dir]="
      translate?.currentLang === 'ar' || translate?.currentLang === 'ur'
        ? 'rtl'
        : ''
    "
    [class.fix-direction]="
      translate?.currentLang === 'ar' || translate?.currentLang === 'ur'
    "
  >
    <div class="p-3 pb-0">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center mb-3">
            <h4>{{ "common.employeeAssignment" | translate }}</h4>
          </div>
        </div>
      </div>
      <form
        [formGroup]="addEmployeeAssignment"
        (ngSubmit)="submitEmpAssign(addEmployeeAssignment)"
      >
        <div class="row mb-3">
          <div class="col-sm-12 col-md-12 col-lg-6">
            <div class="form-floating mb-3">
              <select
                [class.is-invalid]="
                  employeeSequence.invalid &&
                  (employeeSequence.dirty || employeeSequence.touched)
                "
                formControlName="employeeSequence"
                class="form-control form-select input-radius"
              >
                <option
                  *ngFor="let item of _SetupService.allEMPLIST; let i = index"
                  [value]="item?.empSeq"
                >
                  {{ item.empNm }}
                </option>
              </select>
              <div
                (click)="removePortAreaManager('employeeSequence')"
                [ngClass]="{
                  'remove-selection-box':
                    addEmployeeAssignment.controls['employeeSequence'].value,
                  'd-none':
                    !addEmployeeAssignment.controls['employeeSequence'].value
                }"
              >
                <i class="fa fa-times icon"></i>
              </div>
              <div
                *ngIf="
                  employeeSequence.invalid &&
                  (employeeSequence.dirty || employeeSequence.touched)
                "
                class="invalid-feedback"
              >
                <div *ngIf="employeeSequence.errors?.['required']">
                  {{ "region.branch.branchOfficerRequire" | translate }}
                </div>
              </div>
              <label> {{ "region.branch.branchOfficer" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-6">
            <div
              class="form-floating mb-3"
              [class.disableForm]="coveringEMPLIST.length === 0"
            >
              <select
                formControlName="coveringEmpSequence"
                class="form-control form-select input-radius"
              >
                <option hidden value="null"></option>
                <option
                  *ngFor="let item of coveringEMPLIST; let i = index"
                  [value]="item?.empSeq"
                >
                  {{ item.empNm }}
                </option>
              </select>
              <div
                (click)="removePortAreaManager('coveringEmpSequence')"
                [ngClass]="{
                  'remove-selection-box':
                    addEmployeeAssignment.controls['coveringEmpSequence'].value,
                  'd-none':
                    !addEmployeeAssignment.controls['coveringEmpSequence'].value
                }"
              >
                <i class="fa fa-times icon"></i>
              </div>
              <label>{{ "common.coveringEmployee" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-6">
            <div class="form-floating mb-3">
              <input
                type="date"
                formControlName="coveringEmpFromDate"
                class="form-control input-radius"
              />
              <label>{{ "common.formDate" | translate }} </label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-6">
            <div class="form-floating mb-3">
              <input
                type="date"
                formControlName="coveringEmpToDate"
                class="form-control input-radius"
              />
              <label>{{ "common.toDate" | translate }} </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="button_div">
              <button
                class="btn login-button-class"
                [class.pointerNone]="_sharedSvc.loading"
                [disabled]="_sharedSvc.loading"
              >
                {{ "common.ok" | translate }}
              </button>
              <button
                class="btn login-button-class cancelBtn"
                (click)="d('Cross click')"
                type="reset"
              >
                {{ "common.cancel" | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<!-- Assign Employee to Branch Modal PopUp Begin -->
