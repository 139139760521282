<section class="disbursement-form">
  <div class="sidebar" [class.sidebar-close]="!_sharedSvc.openSidebar">
    <app-sidebar></app-sidebar>
  </div>
  <div class="home-section">
    <div
      class="home-main"
      [class.sidebar-close_home-main]="!_sharedSvc.openSidebar"
    >
      <app-navbar></app-navbar>

      <div
        class="home-view"
        [dir]="
          translate?.currentLang === 'ar' || translate?.currentLang === 'ur'
            ? 'rtl'
            : ''
        "
        [class.fix-direction]="
          translate?.currentLang === 'ar' || translate?.currentLang === 'ur'
        "
      >
        <section>
          <div class="row mb-3">
            <div class="col-sm-12 col-md-12 col-lg-6 marginAuto">
              <h4>{{ "sideBarMenu.disbursement" | translate }}</h4>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6">
              <div class="dispersement-btn">
                <button
                  class="btn infoBtn infoBtn_bg mt-0 me-2"
                  [class.infoBtn_bg]="Print"
                >
                  {{ "disbursement.printChalanInfo" | translate }}
                </button>
                <button
                  class="btn infoBtn infoBtn_bg mt-0 me-2"
                  [class.infoBtn_bg]="Print"
                >
                  {{ "disbursement.printRepaymentSchedule" | translate }}
                </button>
                <button
                  class="btn infoBtn infoBtn_bg mt-0 me-2"
                  [class.infoBtn_bg]="Print"
                >
                  {{ "disbursement.printUndertaking" | translate }}
                </button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="dashboard-card loan-table">
                <div class="row mb-3">
                  <div class="col-sm-12 col-md-12 col-lg-12">
                    <h4>Ahmad Ali</h4>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-sm-12 col-md-12 col-lg-6">
                    <div class="row">
                      <div class="col-sm-6 col-md-6 col-lg-3 mb-3 pad_0_5">
                        <div class="form-floating">
                          <input
                            type="text"
                            style="text-transform: capitalize"
                            autocomplete="off"
                            class="form-control search-input readonly-field"
                            value="{{ disbustment.clntId }}"
                          />
                          <label>{{ "common.clientId" | translate }}</label>
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-6 col-lg-3 mb-3 pad_0_5">
                        <div class="form-floating">
                          <input
                            type="text"
                            style="text-transform: capitalize"
                            autocomplete="off"
                            class="form-control search-input readonly-field"
                            value="{{ disbustment?.clntNm }}"
                          />
                          <label>{{ "common.clientName" | translate }}</label>
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-6 col-lg-3 mb-3 pad_0_5">
                        <div class="form-floating">
                          <input
                            type="text"
                            style="text-transform: capitalize"
                            autocomplete="off"
                            class="form-control search-input readonly-field"
                            value="{{ disbustment?.brnchId }}"
                          />
                          <label>{{ "common.branchId" | translate }}</label>
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-6 col-lg-3 mb-3 pad_0_5">
                        <div class="form-floating">
                          <input
                            type="text"
                            style="text-transform: capitalize"
                            autocomplete="off"
                            class="form-control search-input readonly-field"
                            value="{{ disbustment.brnchNm }}"
                          />
                          <label>{{ "common.branchName" | translate }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-6">
                    <div class="row">
                      <div class="col-sm-6 col-md-6 col-lg-3 mb-3 pad_0_5">
                        <div class="form-floating">
                          <input
                            type="text"
                            style="text-transform: capitalize"
                            autocomplete="off"
                            class="form-control search-input readonly-field"
                            value="{{ disbustment.portId }}"
                          />
                          <label>{{ "common.portfolioId" | translate }}</label>
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-6 col-lg-3 mb-3 pad_0_5">
                        <div class="form-floating">
                          <input
                            type="text"
                            style="text-transform: capitalize"
                            autocomplete="off"
                            class="form-control search-input readonly-field"
                            value="{{ disbustment.portNm }}"
                          />
                          <label>{{
                            "common.portfolioName" | translate
                          }}</label>
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-6 col-lg-3 mb-3 pad_0_5">
                        <div class="form-floating">
                          <input
                            type="text"
                            style="text-transform: capitalize"
                            autocomplete="off"
                            class="form-control search-input readonly-field"
                            value="{{ disbustment.caBdoName }}"
                          />
                          <label>BDO {{ "common.name" | translate }}</label>
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-6 col-lg-3 mb-3 pad_0_5">
                        <div class="form-floating">
                          <input
                            type="text"
                            style="text-transform: capitalize"
                            autocomplete="off"
                            class="form-control search-input readonly-field"
                            value="{{ disbustment.crdtAppId }}"
                          />
                          <label>{{
                            "disbursement.applicationId" | translate
                          }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-6">
                    <div class="row">
                      <div class="col-sm-6 col-md-6 col-lg-3 mb-3 pad_0_5">
                        <div class="form-floating">
                          <input
                            type="text"
                            style="text-transform: capitalize"
                            autocomplete="off"
                            class="form-control search-input readonly-field"
                            value="{{ disbustment.productID }}"
                          />
                          <label>{{ "common.productId" | translate }}</label>
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-6 col-lg-3 mb-3 pad_0_5">
                        <div class="form-floating">
                          <input
                            type="text"
                            style="text-transform: capitalize"
                            autocomplete="off"
                            class="form-control search-input readonly-field"
                            value="{{ disbustment.productName }}"
                          />
                          <label>{{ "common.productName" | translate }}</label>
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-6 col-lg-3 mb-3 pad_0_5">
                        <div class="form-floating">
                          <input
                            type="text"
                            style="text-transform: capitalize"
                            autocomplete="off"
                            class="form-control search-input readonly-field"
                            value="{{ disbustment.aprvdAmount }}"
                          />
                          <label style="font-size: 10px">{{
                            "disbursement.disbursementAmount" | translate
                          }}</label>
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-6 col-lg-3 mb-3 pad_0_5">
                        <div class="form-floating">
                          <input
                            type="text"
                            style="text-transform: capitalize"
                            autocomplete="off"
                            class="form-control search-input readonly-field"
                            value="{{ disbustment.totalReceivableAmount }}"
                          />
                          <label style="font-size: 10px"
                            >{{
                              "disbursement.totalReceivableAmount" | translate
                            }}t</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-6">
                    <div class="row">
                      <div class="col-sm-6 col-md-6 col-lg-3 mb-3 pad_0_5">
                        <div class="form-floating">
                          <input
                            type="text"
                            style="text-transform: capitalize"
                            autocomplete="off"
                            class="form-control search-input readonly-field"
                            value="{{ disbustment.firstInstallmentDate }}"
                          />
                          <label>{{
                            "disbursement.firstInstallmentDate" | translate
                          }}</label>
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-6 col-lg-3 mb-3 pad_0_5">
                        <div class="form-floating">
                          <input
                            type="text"
                            style="text-transform: capitalize"
                            autocomplete="off"
                            class="form-control search-input readonly-field"
                            value="{{ disbustment.cycleNumber }}"
                          />
                          <label>{{
                            "disbursement.creditCycle" | translate
                          }}</label>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-6 mb-3 pad_0_5">
                        <div class="form-floating">
                          <input
                            type="text"
                            style="text-transform: capitalize"
                            autocomplete="off"
                            class="form-control search-input readonly-field"
                            value="{{ disbustment.occupation }}"
                          />
                          <label
                            >{{ "common.businessActivity" | translate }}/{{
                              "disbursement.businessDetail" | translate
                            }}</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-12 pad_0_5">
                    <div class="form-floating">
                      <input
                        type="text"
                        style="text-transform: capitalize"
                        autocomplete="off"
                        class="form-control search-input readonly-field"
                      />
                      <label
                        >{{ "common.previous" | translate }}
                        {{ "common.businessActivity" | translate }}/{{
                          "disbursement.businessDetail" | translate
                        }}</label
                      >
                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-sm-4 col-md-4 col-lg-12 marginAuto">
                    <div
                      class="float-right pointer"
                      routerLink="/disbursement-listing"
                    >
                      <p>
                        <span><i class="fa fa-angle-left"></i></span>
                        {{ "common.back" | translate }}
                      </p>
                    </div>
                  </div>
                  <div class="personal-info">
                    <ul ngbNav #nav="ngbNav" [(activeId)]="currentTab">
                      <li [ngbNavItem]="1">
                        <a ngbNavLink>{{
                          "disbursement.disbursementVoucher" | translate
                        }}</a>
                        <ng-template ngbNavContent>
                          <app-disbursement-voucher></app-disbursement-voucher>
                        </ng-template>
                      </li>
                      <li
                        [ngbNavItem]="2"
                        [ngClass]="{ disabled: vocherValidated() }"
                      >
                        <a ngbNavLink>{{
                          "disbursement.RepaymentSchedule" | translate
                        }}</a>
                        <ng-template ngbNavContent>
                          <app-disbursement-repayment-schedule></app-disbursement-repayment-schedule>
                        </ng-template>
                      </li>
                      <li
                        [ngbNavItem]="3"
                        [ngClass]="{ disabled: schedulePayment.length < 1 }"
                      >
                        <a ngbNavLink>{{
                          "common.collateralDetail" | translate
                        }}</a>
                        <ng-template ngbNavContent>
                          <app-collateral-information></app-collateral-information>
                        </ng-template>
                      </li>
                      <li
                        [ngbNavItem]="4"
                        [ngClass]="{ disabled: currentTab < 3 }"
                      >
                        <a ngbNavLink>{{
                          "disbursement.upfrontRecovery" | translate
                        }}</a>
                        <ng-template ngbNavContent>
                          <app-disbursement-up-front-recovery></app-disbursement-up-front-recovery>
                        </ng-template>
                      </li>
                    </ul>
                    <div [ngbNavOutlet]="nav" class="mt-2"></div>
                  </div>
                </div>
                <div class="row" *ngIf="this.currentTab < 4">
                  <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="button_div">
                      <button
                        class="btn login-button-class"
                        [disabled]="continueButton()"
                        (click)="changeTab()"
                      >
                        <span>{{ "common.continue" | translate }}</span>
                        <!-- <span  >Update</span> -->
                      </button>
                      <!-- <button class="btn login-button-class cancelBtn" type="reset"  type="reset">CANCEL</button> -->
                      <button
                        class="btn login-button-class secondaryBtn"
                        *ngIf="this.currentTab == 1"
                        [disabled]="saveButton()"
                        (click)="saveVouchers()"
                        type="button"
                      >
                        <span>{{ "common.save" | translate }}</span>
                        <!-- <span  >Update</span> -->
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>
