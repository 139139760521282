<section>
    <div class="sidebar" [class.sidebar-close]="!_sharedSvc.openSidebar">
        <app-sidebar></app-sidebar>
    </div>
    <div class="home-section">
        <div class="home-main" [class.sidebar-close_home-main]="!_sharedSvc.openSidebar">
            <app-navbar></app-navbar>
            <div class="home-view">
                <section [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
                    [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
                    <div>
                        <div class="row mb-3">
                            <div class="col-sm-8 col-md-8 col-lg-8">
                                <h4> {{'indivGroupLoanForm.individualCreditApplication' | translate}}</h4>
                            </div>
                            <div class="col-sm-4 col-md-4 col-lg-4 marginAuto">
                                <div class="float-right pointer" routerLink="/individual-group-info">
                                    <p>
                                        <span><i class="fa fa-angle-left"></i></span> Back
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="dashboard-card">
                                    <div class="personal-info">
                                        <ul ngbNav #nav="ngbNav" [(activeId)]="_sharedSvc.activeCredit"
                                            class="nav-tabs">
                                            <li [ngbNavItem]="1" (click)="_conditionsService.toggleCredit()">
                                                <ng-container [ngbNavItem]="1"
                                                    [disabled]="_conditionsService.creditInfo">
                                                    <a ngbNavLink>{{'clientCreditCollateral.clientCreditInformation' |
                                                        translate}}</a>
                                                    <ng-template ngbNavContent>
                                                        <app-client-credit-info></app-client-credit-info>
                                                    </ng-template>
                                                </ng-container>
                                            </li>
                                            <ng-container *ngIf="_creditAppSvc.creditApp">
                                                <li [ngbNavItem]="2" (click)="_conditionsService.toggleCredit()">
                                                    <ng-container [ngbNavItem]="2"
                                                        [disabled]="_conditionsService.creditDoc">
                                                        <a ngbNavLink>{{'common.documents' | translate}}</a>
                                                        <ng-template ngbNavContent>
                                                            <app-client-credit-doc></app-client-credit-doc>
                                                        </ng-template>
                                                    </ng-container>
                                                </li>
                                                <li [ngbNavItem]="3"
                                                    *ngIf="allowedRoutes.length > 0 && allowedRoutes[0].rolId !== 'CSO'"
                                                    (click)="_conditionsService.toggleCredit()">
                                                    <ng-container [ngbNavItem]="3"
                                                        [disabled]="_conditionsService.CreditCollateral">
                                                        <a ngbNavLink>{{'common.collateralDetail' | translate}}</a>
                                                        <ng-template ngbNavContent>
                                                            <app-client-credit-collateral-detail></app-client-credit-collateral-detail>
                                                        </ng-template>
                                                    </ng-container>
                                                </li>
                                            </ng-container>
                                            <!-- <li [ngbNavItem]="4">
                                                <a ngbNavLink>Utilization Plan</a>
                                                <ng-template ngbNavContent>
                                                    <app-client-credit-utilization-plan></app-client-credit-utilization-plan>
                                                </ng-template>
                                            </li> -->
                                            <!-- <li [ngbNavItem]="5" *ngIf="allowedRoutes.length > 0 && allowedRoutes[0].rolId !== 'CSO'" (click)="_conditionsService.toggleCredit()"> -->
                                            <li [ngbNavItem]="5" (click)="_conditionsService.toggleCredit()">
                                                <ng-container [ngbNavItem]="5"
                                                    [disabled]="_conditionsService.creditSummary">
                                                    <a ngbNavLink> {{'common.summary' | translate}}</a>
                                                    <ng-template ngbNavContent>
                                                        <app-summary [isICredit]="true"></app-summary>
                                                    </ng-template>
                                                </ng-container>
                                            </li>

                                        </ul>
                                        <div [ngbNavOutlet]="nav" class="mt-2"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</section>