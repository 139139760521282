<section>
  <div class="sidebar" [class.sidebar-close]="!_sharedSvc.openSidebar">
    <app-sidebar></app-sidebar>
  </div>
  <div class="home-section">
    <div class="home-main" [class.sidebar-close_home-main]="!_sharedSvc.openSidebar">
      <app-navbar></app-navbar>
      <div class="home-view">
        <section [dir]="
            translate?.currentLang === 'ar' || translate?.currentLang === 'ur'
              ? 'rtl'
              : ''
          " [class.fix-direction]="
            translate?.currentLang === 'ar' || translate?.currentLang === 'ur'
          ">
          <div class="row mb-3">
            <div class="col-sm-8 col-md-8 col-lg-8">
              <h4>{{ "sideBarMenu.clientOnboarding" | translate }}</h4>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4 marginAuto">
              <div class="float-right pointer" *ngIf="!_conditionsService.readOnlyView_Client">
                <p routerLink="/onboarding">
                  <span><i class="fa fa-angle-left"></i></span>
                  {{ "common.back" | translate }}
                </p>
              </div>
              <div class="float-right pointer" *ngIf="_conditionsService.readOnlyView_Client" [class.float-start]="
                  translate?.currentLang === 'ar' ||
                  translate?.currentLang === 'ur'
                ">
                <p routerLink="/dashboard">
                  <span><i class="fa fa-angle-left"></i></span>
                  {{ "common.back" | translate }}
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="dashboard-card">
                <div class="personal-info">
                  <ul ngbNav #nav="ngbNav" [(activeId)]="_sharedSvc.active" class="nav-tabs">
                    <ng-container *ngIf="_sharedSvc.personalInfo_FS">
                      <li [ngbNavItem]="1" (click)="_conditionsService.toggle()" (click)="onTabClick()"
                        (click)="_sharedSvc.disTabForAddresstoggle()">
                        <ng-container [ngbNavItem]="1" [disabled]="_conditionsService.personalInfo">
                          <a ngbNavLink [ngClass]="{
                              disabled: _sharedSvc.disableTabsForAddress
                            }">
                            {{ "clientOnboarding.personalInfo" | translate }}</a>
                          <ng-template ngbNavContent>
                            <div class="p-3 pb-0">
                              <div class="row mb-4">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                  <div class="btnCenter">
                                    <button class="btn infoBtn" [class.infoBtn_bg]="basicInfoDiv"
                                      (click)="showBtn(true)">
                                      {{
                                      "common.basicInformation" | translate
                                      }}
                                    </button>
                                    <button class="btn infoBtn" [class.infoBtn_bg]="!basicInfoDiv"
                                      (click)="showBtn(false)">
                                      {{ "common.address" | translate }}
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <ng-container *ngIf="basicInfoDiv">
                                <form [formGroup]="basicInfoForm">
                                  <div class="row mb-3" [class.disableForm]="
                                      _conditionsService.readOnlyView_Client
                                    ">
                                    <div class="col-sm-6 col-md-6 col-lg-2 mb-3">
                                      <div class="form-floating">
                                        <input name="cnicNum" id="cnicNum" style="text-transform: capitalize"
                                          class="form-control input-radius readonly-field" formControlName="cnicNum"
                                          phoneMask="99999-9999999-9" readonly />
                                        <label for="cnicNum">
                                          {{
                                          "clientOnboarding.cnicNo"
                                          | translate
                                          }}.</label>
                                      </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-2 mb-3">
                                      <div class="form-floating">
                                        <input name="cnicIssueDt" id="cnicIssueDt"
                                          class="form-control input-radius readonly-field" formControlName="cnicIssueDt"
                                          ngbDatepicker #d2="ngbDatepicker" readonly />
                                        <label for="cnicIssueDt">{{
                                          "common.issueDate" | translate
                                          }}</label>
                                      </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-2 mb-3">
                                      <div class="form-floating">
                                        <input name="cnicExpryDt" id="cnicExpryDt"
                                          class="form-control input-radius readonly-field" formControlName="cnicExpryDt"
                                          ngbDatepicker #d2="ngbDatepicker" readonly />
                                        <label for="cnicExpryDt">
                                          {{
                                          "common.expiryDate" | translate
                                          }}</label>
                                      </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                                      <div class="form-floating">
                                        <input name="frstNm" id="frstNm" type="text" style="text-transform: capitalize"
                                          autocomplete="new-frstNm" formControlName="frstNm"
                                          class="form-control input-radius" [ngClass]="
                                            frstNm.invalid
                                              ? 'requiredInput'
                                              : ''
                                          " minlength="3" maxlength="100" pattern="^[a-zA-Z ]*$" [class.is-invalid]="
                                            frstNm.invalid &&
                                            (frstNm.dirty || frstNm.touched)
                                          " />
                                        <div *ngIf="
                                            frstNm.invalid &&
                                            (frstNm.dirty || frstNm.touched)
                                          " class="invalid-feedback">
                                          <div *ngIf="frstNm.errors?.['required']">
                                            {{'clientOnboarding.personalInfoTab.firstNameRequired' | translate}}
                                          </div>
                                          <div *ngIf="frstNm.errors?.['minlength']">
                                            {{'common.fieldMustHaveLeastThreeLetters' | translate}}
                                          </div>
                                          <div *ngIf="frstNm.errors?.['maxlength']">
                                            {{'common.fieldMustHaveAtMostHundredLetters' | translate}}
                                          </div>
                                          <div *ngIf="frstNm.errors?.['pattern']">
                                            {{'clientOnboarding.personalInfoTab.charactersOnly' | translate}}
                                          </div>
                                        </div>
                                        <label for="frstNm">
                                          {{
                                          "common.firstName" | translate
                                          }}</label>
                                      </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                                      <div class="form-floating">
                                        <input name="lastNm" id="lastNm" type="text" style="text-transform: capitalize"
                                          autocomplete="new-lastNm" formControlName="lastNm" [ngClass]="
                                            lastNm.invalid
                                              ? 'requiredInput'
                                              : ''
                                          " class="form-control input-radius" minlength="3" maxlength="100"
                                          pattern="^[a-zA-Z ]*$" [class.is-invalid]="
                                            lastNm.invalid &&
                                            (lastNm.dirty || lastNm.touched)
                                          " required />
                                        <div *ngIf="
                                            lastNm.invalid &&
                                            (lastNm.dirty || lastNm.touched)
                                          " class="invalid-feedback">
                                          <div *ngIf="lastNm.errors?.['required']">
                                            {{'clientOnboarding.personalInfoTab.lastNameRequired' | translate}}
                                          </div>
                                          <div *ngIf="lastNm.errors?.['minlength']">
                                            {{'common.fieldMustHaveLeastThreeLetters' | translate}}
                                          </div>
                                          <div *ngIf="lastNm.errors?.['maxlength']">
                                            {{'common.fieldMustHaveAtMostHundredLetters' | translate}}
                                          </div>
                                          <div *ngIf="lastNm.errors?.['pattern']">
                                            {{'clientOnboarding.personalInfoTab.charactersOnly' | translate}}
                                          </div>
                                        </div>
                                        <label for="lastNm">{{
                                          "common.lastName" | translate
                                          }}</label>
                                      </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                                      <div class="form-floating">
                                        <input name="nickNm" id="nickNm" type="text" style="text-transform: capitalize"
                                          autocomplete="off" class="form-control input-radius" formControlName="nickNm"
                                          [class.nickNm]="frstNm.invalid" minlength="3" maxlength="100"
                                          pattern="^[a-zA-Z ]*$" [class.is-invalid]="
                                            nickNm.invalid &&
                                            (nickNm.dirty || nickNm.touched)
                                          " />
                                        <div *ngIf="
                                            nickNm.invalid &&
                                            (nickNm.dirty || nickNm.touched)
                                          " class="invalid-feedback">
                                          <div *ngIf="nickNm.errors?.['minlength']">
                                            {{'common.fieldMustHaveLeastThreeLetters' | translate}}
                                          </div>
                                          <div *ngIf="nickNm.errors?.['maxlength']">
                                            {{'common.fieldMustHaveAtMostHundredLetters' | translate}}
                                          </div>
                                          <div *ngIf="nickNm.errors?.['pattern']">
                                            {{'clientOnboarding.personalInfoTab.charactersOnly' | translate}}
                                          </div>
                                        </div>
                                        <label for="nickNm">
                                          {{
                                          "common.nickName" | translate
                                          }}</label>
                                      </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                                      <div class="form-floating">
                                        <input name="mthrMadnNm" id="mthrMadnNm" type="text"
                                          style="text-transform: capitalize" autocomplete="off"
                                          class="form-control input-radius" formControlName="mthrMadnNm" [ngClass]="
                                            mthrMadnNm.invalid
                                              ? 'requiredInput'
                                              : ''
                                          " minlength="3" maxLength="100" pattern="^[a-zA-Z ]*$" [class.is-invalid]="
                                            mthrMadnNm.invalid &&
                                            (mthrMadnNm.dirty ||
                                              mthrMadnNm.touched)
                                          " required />
                                        <div *ngIf="
                                            mthrMadnNm.invalid &&
                                            (mthrMadnNm.dirty ||
                                              mthrMadnNm.touched)
                                          " class="invalid-feedback">
                                          <div *ngIf="mthrMadnNm.errors?.['required']">
                                            {{'clientOnboarding.personalInfoTab.mothersMaidenNameRequired' | translate}}
                                          </div>
                                          <div *ngIf="mthrMadnNm.errors?.['minlength']">
                                            {{
                                            "common.fieldMustHaveLeastThreeLetters"
                                            | translate
                                            }}
                                          </div>
                                          <div *ngIf="mthrMadnNm.errors?.['maxlength']">
                                            {{'common.fieldMustHaveAtMostHundredLetters' | translate}}
                                          </div>
                                          <div *ngIf="mthrMadnNm.errors?.['pattern']">
                                            {{'clientOnboarding.personalInfoTab.charactersOnly' | translate}}
                                          </div>
                                        </div>
                                        <label for="mthrMadnNm">
                                          {{
                                          "clientOnboarding.mothersName"
                                          | translate
                                          }}</label>
                                      </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                                      <div class="form-floating">
                                        <input name="dob" id="dob" class="form-control input-radius readonly-field"
                                          [ngClass]="
                                            dob.invalid ? 'requiredInput' : ''
                                          " formControlName="dob" ngbDatepicker #d="ngbDatepicker"
                                          [minDate]="_sharedSvc.minDOB" [maxDate]="_sharedSvc.maxDOB"
                                          [class.is-invalid]="
                                            dob.invalid &&
                                            (dob.dirty || dob.touched)
                                          " readonly />
                                        <i class="fa fa-calendar nbgCalender" (click)="d.toggle()" *ngIf="
                                            !_conditionsService.readOnlyView_Client
                                          "></i>
                                        <div *ngIf="
                                            dob.invalid &&
                                            (dob.dirty || dob.touched)
                                          " class="invalid-feedback">
                                          <div *ngIf="dob.errors?.['required']">
                                            {{'clientOnboarding.personalInfoTab.dobRequired' | translate}}
                                          </div>
                                        </div>
                                        <label for="dob">{{
                                          "common.dob" | translate
                                          }}</label>
                                      </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                                      <div class="form-floating">
                                        <input name="clintAge" id="clintAge" type="text"
                                          style="text-transform: capitalize" autocomplete="off"
                                          class="form-control input-radius readonly-field" formControlName="clintAge"
                                          readonly />
                                        <label for="clintAge">
                                          {{
                                          "clientOnboarding.clientAge"
                                          | translate
                                          }}</label>
                                      </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                                      <div class="form-floating">
                                        <select name="gndrKey" id="gndrKey"
                                          class="form-control input-radius form-select" formControlName="gndrKey">
                                          <option *ngFor="
                                              let item of _picklistService.GENDER_Data;
                                              let i = index
                                            " [value]="item?.refCdSeq">
                                            {{ item.refCdDscr }}
                                          </option>
                                        </select>
                                        <label for="gndrKey">{{
                                          "common.clientGender" | translate
                                          }}</label>
                                      </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                                      <div class="form-floating">
                                        <select name="mrtlStsKey" id="mrtlStsKey"
                                          class="form-control input-radius form-select" formControlName="mrtlStsKey"
                                          [ngClass]="
                                            mrtlStsKey.invalid
                                              ? 'requiredInput'
                                              : ''
                                          " (change)="materialStatusCheck($event)" [class.is-invalid]="
                                            mrtlStsKey.invalid &&
                                            (mrtlStsKey.dirty ||
                                              mrtlStsKey.touched)
                                          ">
                                          <option *ngFor="
                                              let item of _picklistService.MARITAL_STATUS_Data;
                                              let i = index
                                            " [value]="item?.refCdSeq" [class.is-invalid]="
                                              mrtlStsKey.invalid &&
                                              (mrtlStsKey.dirty ||
                                                mrtlStsKey.touched)
                                            ">
                                            {{ item.refCdDscr }}
                                          </option>
                                        </select>
                                        <div *ngIf="
                                            mrtlStsKey.invalid &&
                                            (mrtlStsKey.dirty ||
                                              mrtlStsKey.touched)
                                          " class="invalid-feedback">
                                          <div *ngIf="mrtlStsKey.errors?.['required']">
                                            {{'clientOnboarding.personalInfoTab.maritalStatusRequired' | translate}}
                                          </div>
                                        </div>
                                        <label for="mrtlStsKey">{{
                                          "clientOnboarding.maritalStatus"
                                          | translate
                                          }}</label>
                                      </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                                      <div class="form-floating">
                                        <select name="eduLvlKey" id="eduLvlKey"
                                          class="form-control input-radius form-select" formControlName="eduLvlKey"
                                          [ngClass]="
                                            eduLvlKey.invalid
                                              ? 'requiredInput'
                                              : ''
                                          " [class.is-invalid]="
                                            eduLvlKey.invalid &&
                                            (eduLvlKey.dirty ||
                                              eduLvlKey.touched)
                                          ">
                                          <option *ngFor="
                                              let item of _picklistService.EDUCATION_Data;
                                              let i = index
                                            " [value]="item?.refCdSeq">
                                            {{ item.refCdDscr }}
                                          </option>
                                        </select>
                                        <div *ngIf="
                                            eduLvlKey.invalid &&
                                            (eduLvlKey.dirty ||
                                              eduLvlKey.touched)
                                          " class="invalid-feedback">
                                          <div *ngIf="eduLvlKey.errors?.['required']">
                                            {{'clientOnboarding.personalInfoTab.educationLevelRequired' | translate}}
                                          </div>
                                        </div>
                                        <label for="eduLvlKey">{{
                                          "clientOnboarding.educationLevel"
                                          | translate
                                          }}</label>
                                      </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                                      <div class="form-floating">
                                        <select name="occKey" id="occKey" class="form-control input-radius form-select"
                                          formControlName="occKey" [ngClass]="
                                            occKey.invalid
                                              ? 'requiredInput'
                                              : ''
                                          " [class.readonly-field]="FM" (change)="occupationFn($event)"
                                          [class.is-invalid]="
                                            occKey.invalid &&
                                            (occKey.dirty || occKey.touched)
                                          ">
                                          <option *ngFor="
                                              let item of _picklistService.OCCUPATION_Data;
                                              let i = index
                                            " [value]="item?.refCdSeq">
                                            {{ item.refCdDscr }}
                                          </option>
                                        </select>
                                        <div *ngIf="
                                            occKey.invalid &&
                                            (occKey.dirty || occKey.touched)
                                          " class="invalid-feedback">
                                          <div *ngIf="occKey.errors?.['required']">
                                            {{'clientOnboarding.personalInfoTab.occupationRequired' | translate}}
                                          </div>
                                        </div>
                                        <label for="occKey">
                                          {{
                                          "common.occupation" | translate
                                          }}</label>
                                      </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                                      <div class="form-floating">
                                        <select name="disabilityVar_" id="disabilityVar_"
                                          class="form-control input-radius form-select" formControlName="disabilityVar_"
                                          (change)="disabilityCheck($event)">
                                          <option value="yes">{{'common.yes' | translate}}</option>
                                          <option value="no">{{'common.no' | translate}}</option>
                                        </select>
                                        <label for="disabilityVar_">
                                          {{
                                          "clientOnboarding.disability"
                                          | translate
                                          }}</label>
                                      </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                                      <div class="form-floating">
                                        <select name="natrOfDisKey" id="natrOfDisKey"
                                          class="form-control input-radius form-select" formControlName="natrOfDisKey"
                                          [class.readonly-field]="
                                            disabilityVar == 'no'
                                          ">
                                          <ng-container *ngIf="
                                              !disNatrOfDisKey ||
                                              _clintOnboardingSvc
                                                .saveClientModel.natrOfDisKey !=
                                                null
                                            ">
                                            <option *ngFor="
                                                let item of _picklistService.NATURE_OF_DISABILITY_Data;
                                                let i = index
                                              " [value]="item?.refCdSeq">
                                              {{ item.refCdDscr }}
                                            </option>
                                          </ng-container>
                                        </select>
                                        <label for="natrOfDisKey">
                                          {{
                                          "clientOnboarding.natureDisability"
                                          | translate
                                          }}</label>
                                      </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                                      <div class="form-floating">
                                        <select name="resTypKey" id="resTypKey"
                                          class="form-control input-radius form-select" formControlName="resTypKey"
                                          [ngClass]="
                                            resTypKey.invalid
                                              ? 'requiredInput'
                                              : ''
                                          " [class.is-invalid]="
                                            resTypKey.invalid &&
                                            (resTypKey.dirty ||
                                              resTypKey.touched)
                                          ">
                                          <option *ngFor="
                                              let item of _picklistService.RESIDENCE_Data;
                                              let i = index
                                            " [value]="item?.refCdSeq">
                                            {{ item.refCdDscr }}
                                          </option>
                                        </select>
                                        <div *ngIf="
                                            resTypKey.invalid &&
                                            (resTypKey.dirty ||
                                              resTypKey.touched)
                                          " class="invalid-feedback">
                                          <div *ngIf="resTypKey.errors?.['required']">
                                            {{'clientOnboarding.personalInfoTab.residenceRequired' | translate}}
                                          </div>
                                        </div>
                                        <label for="resTypKey">
                                          {{
                                          "clientOnboarding.residence"
                                          | translate
                                          }}</label>
                                      </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                                      <div class="form-floating">
                                        <input name="householdMembers" id="householdMembers" type="text"
                                          style="text-transform: capitalize" class="form-control input-radius" mask="99"
                                          autocomplete="new-householdMembers" [ngClass]="
                                            householdMembers.invalid
                                              ? 'requiredInput'
                                              : ''
                                          " (input)="
                                            maxNOD($event, 'householdMembers')
                                          " min="1" formControlName="householdMembers" [class.is-invalid]="
                                            householdMembers.invalid &&
                                            (householdMembers.dirty ||
                                              householdMembers.touched)
                                          " required />
                                        <div *ngIf="
                                            householdMembers.invalid &&
                                            (householdMembers.dirty ||
                                              householdMembers.touched)
                                          " class="invalid-feedback">
                                          <div *ngIf="householdMembers.errors?.['required']">
                                            {{'clientOnboarding.personalInfoTab.houseHoldMembersRequired' | translate}}
                                          </div>
                                          <div *ngIf="householdMembers.errors?.['min']">
                                            {{'common.field1DigitRequire' | translate}}
                                          </div>
                                          <!-- <div *ngIf="householdMembers.errors?.['max']">This field must have at most 2
                                          digit.</div> -->
                                        </div>
                                        <label for="householdMembers">
                                          {{
                                          "clientOnboarding.houseHoldMembers"
                                          | translate
                                          }}</label>
                                      </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                                      <div class="form-floating">
                                        <input name="numberOfDependents" id="numberOfDependents" type="text"
                                          style="text-transform: capitalize" class="form-control input-radius" mask="99"
                                          autocomplete="new-numberOfDependents" [ngClass]="
                                            numberOfDependents.invalid ||
                                            numberOfDependentsInvalid
                                              ? 'requiredInput'
                                              : ''
                                          " (input)="
                                            maxNOD($event, 'numberOfDependents')
                                          " formControlName="numberOfDependents" [max]="
                                            _clintOnboardingSvc.saveClientModel
                                              .householdMembers
                                          " [class.is-invalid]="
                                            numberOfDependents.invalid &&
                                            (numberOfDependents.dirty ||
                                              numberOfDependents.touched)
                                          " required />
                                        <div *ngIf="
                                            numberOfDependents.invalid &&
                                            (numberOfDependents.dirty ||
                                              numberOfDependents.touched ||
                                              numberOfDependentsInvalid)
                                          " class="invalid-feedback">
                                          <div *ngIf="numberOfDependents.errors?.['required']">
                                            {{'clientOnboarding.personalInfoTab.dependantsRequired' | translate}}
                                          </div>
                                          <div *ngIf="numberOfDependents.errors?.['minLength']">
                                            {{'clientOnboarding.personalInfoTab.zeroDigitRequirement' | translate}}
                                          </div>
                                          <div *ngIf="numberOfDependentsInvalid">
                                            {{'clientOnboarding.personalInfoTab.houseHoldMembersLimit' | translate}}
                                          </div>
                                        </div>
                                        <label for="numberOfDependents">
                                          {{
                                          "clientOnboarding.noOfDependant"
                                          | translate
                                          }}</label>
                                      </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                                      <div class="form-floating">
                                        <input name="numberOfChildren" id="numberOfChildren" type="text"
                                          style="text-transform: capitalize" class="form-control input-radius" mask="99"
                                          autocomplete="new-numberOfChildren" [ngClass]="
                                            numberOfChildren.invalid ||
                                            numberOfChildrenInvalid
                                              ? 'requiredInput'
                                              : ''
                                          " [class.readonly-field]="
                                            _clintOnboardingSvc.saveClientModel
                                              .mrtlStsKey == 21
                                          " (input)="maxNOC($event)" formControlName="numberOfChildren"
                                          [class.is-invalid]="
                                            numberOfChildren.invalid &&
                                            (numberOfChildren.dirty ||
                                              numberOfChildren.touched)
                                          " />
                                        <div *ngIf="
                                            numberOfChildren.invalid &&
                                            (numberOfChildren.dirty ||
                                              numberOfChildren.touched)
                                          " class="invalid-feedback">
                                          <div *ngIf="numberOfChildren.errors?.['required']">
                                            {{'clientOnboarding.personalInfoTab.noChildrenRequired' | translate}}
                                          </div>
                                          <div *ngIf="numberOfChildren.errors?.['minLength']">
                                            {{'clientOnboarding.personalInfoTab.zeroDigitRequirement' | translate}}
                                          </div>
                                          <div *ngIf="numberOfChildren.errors?.['max']">
                                            {{'clientOnboarding.personalInfoTab.houseHoldMembersLimit' | translate}}
                                          </div>
                                        </div>
                                        <label for="numberOfChildren">
                                          {{
                                          "clientOnboarding.noOfChildren"
                                          | translate
                                          }}</label>
                                      </div>
                                    </div>
                                    <!-- <div class="col-sm-6 col-md-12 col-lg-3 mb-3">
                                    <div class="form-floating">
                                      <select name="clintAge" id="clintAge" class="form-control input-radius form-select" formControlName="selfPdc"
                                        [ngClass]="selfPdc.invalid ? 'requiredInput' : ''" 
                                        [class.is-invalid]="selfPdc.invalid && (selfPdc.dirty || selfPdc.touched)">
                                        <option *ngFor="let item of _picklistService.PDC_Data; let i = index"
                                          [value]="item?.refCdSeq">
                                          {{ item.refCdDscr }}
                                        </option>
                                      </select>
                                      <div *ngIf="selfPdc.invalid && (selfPdc.dirty || selfPdc.touched)"
                                        class="invalid-feedback">
                                        <div *ngIf="selfPdc.errors?.['required']">Self PDC is required.</div>
                                      </div>
                                      <label >Self PDC</label>
                                    </div>
                                  </div> -->
                                    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                                      <div class="form-floating">
                                        <input name="phNum" id="phNum" type="text" style="text-transform: capitalize"
                                          autocomplete="new-phNum" [ngClass]="
                                            phNum.invalid ? 'requiredInput' : ''
                                          " class="form-control input-radius" phoneMask="9999-9999999"
                                          formControlName="phNum" (change)="checkNumberField($event)"
                                          (keypress)="numberOnly($event)" [class.is-invalid]="
                                            (phNum.invalid &&
                                              (phNum.dirty || phNum.touched)) ||
                                            phoneField
                                          " required />
                                        <div *ngIf="
                                            phNum.invalid &&
                                            (phNum.dirty || phNum.touched)
                                          " class="invalid-feedback">
                                          <div *ngIf="phNum.errors?.['required']">
                                            {{'common.mobileNumberRequire' | translate}}
                                          </div>
                                          <div *ngIf="phNum.errors?.['minLength']">
                                            {{'clientOnboarding.personalInfoTab.elevenDigitMinRequirement' | translate}}
                                          </div>
                                          <div *ngIf="phNum.errors?.['maxLength']">
                                            {{'clientOnboarding.personalInfoTab.elevenDigitMaxRequirement' | translate}}
                                          </div>
                                        </div>
                                        <div *ngIf="phoneField" class="invalid-feedback">
                                          <div>{{'clientOnboarding.personalInfoTab.invalidMobileNumber' | translate}}</div>
                                        </div>
                                        <label for="phNum">{{
                                          "clientOnboarding.mobileNumber"
                                          | translate
                                          }}</label>
                                      </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                                      <div class="form-floating">
                                        <input name="walletNumber" id="walletNumber" type="text"
                                          style="text-transform: capitalize" autocomplete="new-walletNumber" [ngClass]="
                                            walletNumber.invalid
                                              ? 'requiredInput'
                                              : ''
                                          " class="form-control input-radius" phoneMask="9999-9999999"
                                          formControlName="walletNumber" (change)="checkWalletField($event)"
                                          (keypress)="numberOnlyMV($event)" [class.is-invalid]="
                                            (walletNumber.invalid &&
                                              (walletNumber.dirty ||
                                                walletNumber.touched)) ||
                                            walletField
                                          " required />
                                        <div *ngIf="
                                            walletNumber.invalid &&
                                            (walletNumber.dirty ||
                                              walletNumber.touched)
                                          " class="invalid-feedback">
                                          <div *ngIf="walletNumber.errors?.['required']">
                                            {{'clientOnboarding.personalInfoTab.mWalletNumberRequired' | translate}}
                                          </div>
                                          <div *ngIf="walletNumber.errors?.['minLength']">
                                            {{'clientOnboarding.personalInfoTab.elevenDigitMinRequirement' | translate}}
                                          </div>
                                          <div *ngIf="walletNumber.errors?.['maxLength']">
                                            {{'clientOnboarding.personalInfoTab.elevenDigitMaxRequirement' | translate}}
                                          </div>
                                        </div>
                                        <div *ngIf="walletField" class="invalid-feedback">
                                          <div>{{'clientOnboarding.personalInfoTab.invalidMWalletNumber' | translate}}</div>
                                        </div>
                                        <label for="walletNumber">
                                          {{
                                          "clientOnboarding.walletNumber"
                                          | translate
                                          }}
                                        </label>
                                      </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                                      <div class="form-floating">
                                        <select name="customerTypeKey" id="customerTypeKey"
                                          class="form-control input-radius form-select"
                                          formControlName="customerTypeKey" [class.readonly-field]="FM" [ngClass]="
                                            customerTypeKey.invalid
                                              ? 'requiredInput'
                                              : ''
                                          " (change)="
                                            occupationFn($event, 'customer')
                                          " [class.is-invalid]="
                                            customerTypeKey.invalid &&
                                            (customerTypeKey.dirty ||
                                              customerTypeKey.touched)
                                          ">
                                          <option *ngFor="
                                              let item of _picklistService.CUSTOMER_TYPE_Data;
                                              let i = index
                                            " [value]="item?.refCdSeq">
                                            {{ item.refCdDscr }}
                                          </option>
                                        </select>
                                        <div *ngIf="
                                            customerTypeKey.invalid &&
                                            (customerTypeKey.dirty ||
                                              customerTypeKey.touched)
                                          " class="invalid-feedback">
                                          <div *ngIf="customerTypeKey.errors?.['required']">
                                            {{'clientOnboarding.personalInfoTab.customerTypeRequired' | translate}}
                                          </div>
                                        </div>
                                        <label for="customerTypeKey">
                                          {{
                                          "clientOnboarding.customerType"
                                          | translate
                                          }}</label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row mb-3" [class.disableForm]="
                                      _conditionsService.readOnlyView_Client
                                    ">
                                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                                      <div class="otherOutstanding">
                                        <h5>
                                          {{
                                          "clientOnboarding.fatherInformation"
                                          | translate
                                          }}
                                        </h5>
                                      </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                                      <div class="form-floating">
                                        <input name="fthrSpzFrstNm" id="fthrSpzFrstNm" type="text"
                                          style="text-transform: capitalize" autocomplete="off" [ngClass]="
                                            fthrSpzFrstNm.invalid
                                              ? 'requiredInput'
                                              : ''
                                          " class="form-control input-radius" pattern="^[a-zA-Z ]*$"
                                          formControlName="fthrSpzFrstNm" minlength="3" maxlength="100"
                                          [class.is-invalid]="
                                            fthrSpzFrstNm.invalid &&
                                            (fthrSpzFrstNm.dirty ||
                                              fthrSpzFrstNm.touched)
                                          " required />
                                        <div *ngIf="
                                            fthrSpzFrstNm.invalid &&
                                            (fthrSpzFrstNm.dirty ||
                                              fthrSpzFrstNm.touched)
                                          " class="invalid-feedback">
                                          <div *ngIf="fthrSpzFrstNm.errors?.['required']">
                                            {{'clientOnboarding.personalInfoTab.fatherHusbandFirstNameRequired' | translate}}
                                          </div>
                                          <div *ngIf="fthrSpzFrstNm.errors?.['minlength']">
                                            {{'common.fieldMustHaveLeastThreeLetters' | translate}}
                                          </div>
                                          <div *ngIf="fthrSpzFrstNm.errors?.['maxlength']">
                                            {{'common.fieldMustHaveAtMostHundredLetters' | translate}}
                                          </div>
                                          <div *ngIf="fthrSpzFrstNm.errors?.['pattern']">
                                            {{'clientOnboarding.personalInfoTab.charactersOnly' | translate}}
                                          </div>
                                        </div>
                                        <label for="fthrSpzFrstNm">
                                          {{
                                          "clientOnboarding.fatherFirstName"
                                          | translate
                                          }}
                                        </label>
                                      </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                                      <div class="form-floating">
                                        <input name="fthrSpzLastNm" id="fthrSpzLastNm" type="text"
                                          style="text-transform: capitalize" autocomplete="off" [ngClass]="
                                            fthrSpzLastNm.invalid
                                              ? 'requiredInput'
                                              : ''
                                          " class="form-control input-radius" pattern="^[a-zA-Z ]*$"
                                          formControlName="fthrSpzLastNm" minlength="3" maxlength="100"
                                          [class.is-invalid]="
                                            fthrSpzLastNm.invalid &&
                                            (fthrSpzLastNm.dirty ||
                                              fthrSpzLastNm.touched)
                                          " required />
                                        <div *ngIf="
                                            fthrSpzLastNm.invalid &&
                                            (fthrSpzLastNm.dirty ||
                                              fthrSpzLastNm.touched)
                                          " class="invalid-feedback">
                                          <div *ngIf="fthrSpzLastNm.errors?.['required']">
                                            {{'clientOnboarding.personalInfoTab.fatherHusbandLastNameRequired' | translate}}
                                          </div>
                                          <div *ngIf="fthrSpzLastNm.errors?.['minlength']">
                                            {{'common.fieldMustHaveLeastThreeLetters' | translate}}
                                          </div>
                                          <div *ngIf="fthrSpzLastNm.errors?.['maxlength']">
                                            {{'common.fieldMustHaveAtMostHundredLetters' | translate}}
                                          </div>
                                          <div *ngIf="fthrSpzLastNm.errors?.['pattern']">
                                            {{'clientOnboarding.personalInfoTab.charactersOnly' | translate}}
                                          </div>
                                        </div>
                                        <label for="fthrSpzLastNm">
                                          {{
                                          "clientOnboarding.fatherLastName"
                                          | translate
                                          }}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row mb-3">
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                      <div class="button_div">
                                        <button class="btn login-button-class" type="button" [disabled]="
                                            _conditionsService.personalInfoBtn
                                          " (click)="showBtn(false)">
                                          {{ "common.continue" | translate }}
                                        </button>
                                        <button class="btn login-button-class secondaryBtn" type="button" *ngIf="
                                            !_conditionsService.readOnlyView_Client
                                          " [class.pointerNone]="
                                            _sharedSvc.loading
                                          " (click)="onSubmitBasicInfo()" [disabled]="
                                            !basicInfoForm.valid ||
                                            _sharedSvc.loading ||
                                            numberOfDependentsInvalid ||
                                            numberOfChildrenInvalid
                                          ">
                                          {{ "common.save" | translate }}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </ng-container>
                              <ng-container *ngIf="!basicInfoDiv">
                                <form [formGroup]="addressForm" #address_Form="ngForm">
                                  <div class="row mb-3" [class.disableForm]="
                                      _conditionsService.readOnlyView_Client
                                    ">
                                    <!-- <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                                      <div class="float-right">
                                        <button class="btn geoBtn" type="button">
                                          <i class="fa fa-map-marker"></i>Geo&nbsp;Tag</button>
                                      </div>
                                    </div> -->
                                    <div class="col-sm-6 col-md-6 col-lg-2 mb-3">
                                      <div class="form-floating">
                                        <input name="hseNum" id="hseNum" type="text" style="text-transform: capitalize"
                                          autocomplete="new-hseNum" [ngClass]="
                                            hseNum.invalid
                                              ? 'requiredInput'
                                              : ''
                                          " class="form-control input-radius" formControlName="hseNum" minlength="1"
                                          maxLength="40" [class.is-invalid]="
                                            hseNum.invalid &&
                                            (hseNum.dirty || hseNum.touched)
                                          " required />
                                        <div *ngIf="
                                            hseNum.invalid &&
                                            (hseNum.dirty || hseNum.touched)
                                          " class="invalid-feedback">
                                          <div *ngIf="hseNum.errors?.['required']">
                                            {{'clientOnboarding.personalInfoTab.houseNoRequired' | translate}}
                                          </div>
                                          <div *ngIf="hseNum.errors?.['minlength']">
                                            {{'clientOnboarding.personalInfoTab.oneCharMinRequirement' | translate}}
                                          </div>
                                          <div *ngIf="hseNum.errors?.['maxlength']">
                                            {{'clientOnboarding.personalInfoTab.fortyCharMaxRequirement' | translate}}
                                          </div>
                                        </div>
                                        <label for="hseNum">{{
                                          "common.houseNo" | translate
                                          }}</label>
                                      </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-10 mb-3">
                                      <div class="form-floating">
                                        <input name="strt" id="strt" type="text" style="text-transform: capitalize"
                                          autocomplete="new-strt" [ngClass]="
                                            strt.invalid ? 'requiredInput' : ''
                                          " class="form-control input-radius" formControlName="strt" minlength="1"
                                          maxLength="40" [class.is-invalid]="
                                            strt.invalid &&
                                            (strt.dirty || strt.touched)
                                          " required />
                                        <div *ngIf="
                                            strt.invalid &&
                                            (strt.dirty || strt.touched)
                                          " class="invalid-feedback">
                                          <div *ngIf="strt.errors?.['required']">
                                            {{'clientOnboarding.personalInfoTab.streetAreaRequired' | translate}}
                                          </div>
                                          <div *ngIf="strt.errors?.['minlength']">
                                            {{'clientOnboarding.personalInfoTab.oneCharMinRequirement' | translate}}
                                          </div>
                                          <div *ngIf="strt.errors?.['maxlength']">
                                            {{'clientOnboarding.personalInfoTab.fortyCharMaxRequirement' | translate}}
                                          </div>
                                        </div>
                                        <label for="strt">{{
                                          "common.streetArea" | translate
                                          }}</label>
                                      </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                                      <div class="form-floating">
                                        <select name="cmntySeq" id="cmntySeq"
                                          class="form-control input-radius form-select" formControlName="cmntySeq"
                                          [ngClass]="
                                            cmntySeq.invalid
                                              ? 'requiredInput'
                                              : ''
                                          " [class.is-invalid]="
                                            cmntySeq.invalid &&
                                            (cmntySeq.dirty || cmntySeq.touched)
                                          ">
                                          <option *ngFor="
                                              let item of _picklistService.COMMUNITY_TYPE_Data;
                                              let i = index
                                            " [value]="item?.cmntySeq">
                                            {{ item.cmntyNm }}
                                          </option>
                                        </select>
                                        <div *ngIf="
                                            cmntySeq.invalid &&
                                            (cmntySeq.dirty || cmntySeq.touched)
                                          " class="invalid-feedback">
                                          <div *ngIf="cmntySeq.errors?.['required']">
                                            {{'clientOnboarding.personalInfoTab.coummunityRequired' | translate}}
                                          </div>
                                        </div>
                                        <label for="cmntySeq">{{
                                          "common.community" | translate
                                          }}</label>
                                      </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                                      <div class="form-floating">
                                        <input name="vlg" id="vlg" type="text" style="text-transform: capitalize"
                                          autocomplete="new-vlg" [ngClass]="
                                            vlg.invalid ? 'requiredInput' : ''
                                          " class="form-control input-radius" formControlName="vlg" minlength="3"
                                          maxLength="100" [class.is-invalid]="
                                            vlg.invalid &&
                                            (vlg.dirty || vlg.touched)
                                          " required />
                                        <div *ngIf="
                                            vlg.invalid &&
                                            (vlg.dirty || vlg.touched)
                                          " class="invalid-feedback">
                                          <div *ngIf="vlg.errors?.['required']">
                                            {{'clientOnboarding.personalInfoTab.villageRequired' | translate}}
                                          </div>
                                          <div *ngIf="vlg.errors?.['minlength']">
                                            {{'common.fieldMustHaveLeastThreeLetters' | translate}}
                                          </div>
                                          <div *ngIf="vlg.errors?.['maxlength']">
                                            {{'common.fieldMustHaveAtMostHundredLetters' | translate}}
                                          </div>
                                        </div>
                                        <label for="vlg">{{
                                          "common.village" | translate
                                          }}</label>
                                      </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-6 mb-3">
                                      <div class="form-floating">
                                        <input name="othDtl" id="othDtl" type="text" style="text-transform: capitalize"
                                          autocomplete="new-othDtl" [ngClass]="
                                            othDtl.invalid
                                              ? 'requiredInput'
                                              : ''
                                          " class="form-control input-radius" formControlName="othDtl" minlength="3"
                                          maxLength="500" [class.is-invalid]="
                                            othDtl.invalid &&
                                            (othDtl.dirty || othDtl.touched)
                                          " required />
                                        <div *ngIf="
                                            othDtl.invalid &&
                                            (othDtl.dirty || othDtl.touched)
                                          " class="invalid-feedback">
                                          <div *ngIf="othDtl.errors?.['required']">
                                            {{'clientOnboarding.personalInfoTab.otherDetailsRequired' | translate}}
                                          </div>
                                          <div *ngIf="othDtl.errors?.['minlength']">
                                            {{'common.fieldMustHaveLeastThreeLetters' | translate}}
                                          </div>
                                          <div *ngIf="othDtl.errors?.['maxlength']">
                                            {{'clientOnboarding.personalInfoTab.fiveHundredCharsMaxRequirement' | translate}}
                                          </div>
                                        </div>
                                        <label for="othDtl">{{
                                          "common.otherDetails" | translate
                                          }}</label>
                                      </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3" *ngIf="
                                        !_conditionsService.readOnlyView_Client
                                      ">
                                      <div class="gray-Bg-icon" (click)="openModel(area)">
                                        <i class="fa fa-ellipsis-h"></i>
                                      </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                                      <div class="form-floating">
                                        <input name="districtName" id="districtName" type="text"
                                          style="text-transform: capitalize" autocomplete="new-districtName"
                                          class="form-control input-radius readonly-field"
                                          formControlName="districtName" [class.is-invalid]="
                                            districtName.invalid &&
                                            (districtName.dirty ||
                                              districtName.touched)
                                          " required readonly />
                                        <label for="districtName">{{
                                          "common.district" | translate
                                          }}</label>
                                      </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                                      <div class="form-floating">
                                        <input name="thslName" id="thslName" type="text"
                                          style="text-transform: capitalize" autocomplete="off"
                                          class="form-control input-radius readonly-field" formControlName="thslName"
                                          [class.is-invalid]="
                                            thslName.invalid &&
                                            (thslName.dirty || thslName.touched)
                                          " required readonly />
                                        <label for="thslName">{{
                                          "common.tehsil" | translate
                                          }}</label>
                                      </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                                      <div class="form-floating">
                                        <input name="cityName" id="cityName" type="text"
                                          style="text-transform: capitalize" autocomplete="off"
                                          class="form-control input-radius readonly-field" formControlName="cityName"
                                          [class.is-invalid]="
                                            cityName.invalid &&
                                            (cityName.dirty || cityName.touched)
                                          " required readonly />
                                        <label for="cityName">{{
                                          "common.city" | translate
                                          }}</label>
                                      </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                                      <div class="form-floating">
                                        <input name="ucName" id="ucName" type="text" style="text-transform: capitalize"
                                          autocomplete="off" class="form-control input-radius readonly-field"
                                          formControlName="ucName" [class.is-invalid]="
                                            ucName.invalid &&
                                            (ucName.dirty || ucName.touched)
                                          " required readonly />
                                        <label for="ucName">{{
                                          "common.uc" | translate
                                          }}</label>
                                      </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                                      <div class="form-floating">
                                        <input name="stateName" id="stateName" type="text"
                                          style="text-transform: capitalize" autocomplete="off"
                                          class="form-control input-radius readonly-field" formControlName="stateName"
                                          [class.is-invalid]="
                                            stateName.invalid &&
                                            (stateName.dirty ||
                                              stateName.touched)
                                          " required readonly />
                                        <label for="stateName">{{ "common.province" | translate }}
                                        </label>
                                      </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                                      <div class="form-floating">
                                        <input name="nosOfResidence" id="nosOfResidence" type="number"
                                          class="form-control input-radius" [ngClass]="
                                            mnthsOfResidence.invalid
                                              ? 'requiredInput'
                                              : ''
                                          " formControlName="nosOfResidence" [class.is-invalid]="
                                            mnthsOfResidence.invalid &&
                                            (mnthsOfResidence.dirty ||
                                              mnthsOfResidence.touched)
                                          " (input)="calNosOfResidence()" />
                                        <label for="nosOfResidence">
                                          {{
                                          "clientOnboarding.noYearResidence"
                                          | translate
                                          }}</label>
                                      </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                                      <div class="form-floating">
                                        <input name="resOfResidence" id="resOfResidence" type="number"
                                          class="form-control input-radius" [ngClass]="
                                            mnthsOfResidence.invalid
                                              ? 'requiredInput'
                                              : ''
                                          " formControlName="resOfResidence" [class.is-invalid]="
                                            mnthsOfResidence.invalid &&
                                            (mnthsOfResidence.dirty ||
                                              mnthsOfResidence.touched)
                                          " (input)="
                                            limitInputToTwoDigits($event)
                                          " (input)="calMnthsOfResidence()" required />
                                        <label for="resOfResidence">
                                          {{
                                          "clientOnboarding.noMonthsResidence"
                                          | translate
                                          }}</label>
                                      </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
                                      <div class="form-floating">
                                        <input name="mnthsOfResidence" id="mnthsOfResidence" type="number"
                                          class="form-control input-radius readonly-field"
                                          formControlName="mnthsOfResidence" [class.is-invalid]="
                                            mnthsOfResidence.invalid &&
                                            (mnthsOfResidence.dirty ||
                                              mnthsOfResidence.touched)
                                          " required readonly />
                                        <div *ngIf="
                                            mnthsOfResidence.invalid &&
                                            (mnthsOfResidence.dirty ||
                                              mnthsOfResidence.touched)
                                          " class="invalid-feedback">
                                          <div *ngIf="mnthsOfResidence.errors?.['required']">
                                            {{'clientOnboarding.personalInfoTab.fieldRequired' | translate}}
                                          </div>
                                          <div *ngIf="mnthsOfResidence.errors?.['minlength']">
                                            {{'clientOnboarding.personalInfoTab.twoDigitMinRequirement' | translate}}
                                          </div>
                                          <div *ngIf="mnthsOfResidence.errors?.['maxlength']">
                                            {{'clientOnboarding.personalInfoTab.ninetyNineDigitMaxRequirement' | translate}}
                                          </div>
                                        </div>
                                        <label for="mnthsOfResidence">
                                          {{
                                          "clientOnboarding.residenceTenureMonths"
                                          | translate
                                          }}</label>
                                      </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                                      <div class="display-flex">
                                        <label for="i1">
                                          {{
                                          "clientOnboarding.ispermanentAddressLabel"
                                          | translate
                                          }}</label>
                                        <input name="i1" id="i1" type="radio" class="m-1" checked="checked" *ngIf="
                                            _clintOnboardingSvc.isPermAddress ==
                                            true
                                          " />
                                        <input name="i2" id="i2" type="radio" class="m-1" (click)="isPermanentAdd(true)"
                                          *ngIf="
                                            _clintOnboardingSvc.isPermAddress ==
                                            false
                                          " />
                                        <label for="i2">{{
                                          "common.yes" | translate
                                          }}</label>
                                        <input name="i1" id="i1" type="radio" class="m-1" checked="checked" *ngIf="
                                            _clintOnboardingSvc.isPermAddress ==
                                            false
                                          " />
                                        <input name="i2" id="i2" type="radio" class="m-1"
                                          (click)="isPermanentAdd(false)" *ngIf="
                                            _clintOnboardingSvc.isPermAddress ==
                                            true
                                          " />
                                        <label for="i1">
                                          {{ "common.no" | translate }}</label>
                                      </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3" *ngIf="
                                        _clintOnboardingSvc.isPermAddress ==
                                        false
                                      ">
                                      <div class="form-floating">
                                        <input name="prmAddr" id="prmAddr" type="text"
                                          style="text-transform: capitalize" formControlName="prmAddr"
                                          autocomplete="off" class="form-control input-radius" />
                                        <label for="prmAddr">
                                          {{
                                          "clientOnboarding.permanentAddress"
                                          | translate
                                          }}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row mb-3">
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                      <div class="button_div">
                                        <button class="btn login-button-class" type="button" [disabled]="
                                            _conditionsService.personalInfoBtn ||
                                            !_sharedSvc.clientDoc_FS
                                          " (click)="_sharedSvc.active = 2">
                                          {{ "common.continue" | translate }}
                                        </button>
                                        <button class="btn login-button-class secondaryBtn" (click)="onSubmitAddress()"
                                          *ngIf="
                                            !_conditionsService.readOnlyView_Client
                                          " [disabled]="
                                            _sharedSvc.loading ||
                                            _clintOnboardingSvc.areaFieldsInvalid
                                          ">
                                          {{ "common.save" | translate }}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </ng-container>
                            </div>
                          </ng-template>
                        </ng-container>
                      </li>
                    </ng-container>
                    <ng-container *ngIf="_sharedSvc.clientDoc_FS">
                      <li [ngbNavItem]="2" (click)="_conditionsService.toggle()" (click)="onTabClick()"
                        (click)="_sharedSvc.disTabForAddresstoggle()">
                        <ng-container [ngbNavItem]="2" [disabled]="_conditionsService.clientDoc">
                          <a ngbNavLink [ngClass]="{
                              disabled: _sharedSvc.disableTabsForAddress
                            }">
                            {{
                            "clientOnboarding.clientDocTab.clientDocs"
                            | translate
                            }}</a>
                          <ng-template ngbNavContent>
                            <app-client-docs></app-client-docs>
                          </ng-template>
                        </ng-container>
                      </li>
                    </ng-container>
                    <!-- <ng-container *ngIf="_sharedSvc">
                      <li [ngbNavItem]="3">
                        <a ngbNavLink>Client Relatives</a>
                        <ng-template ngbNavContent>
                          <app-client-relatives></app-client-relatives>
                        </ng-template>
                      </li>
                    </ng-container> -->
                    <ng-container *ngIf="_sharedSvc.otherOutstanding_FS">
                      <li [ngbNavItem]="4" (click)="_conditionsService.toggle()" (click)="onTabClick()"
                        (click)="_sharedSvc.disTabForAddresstoggle()">
                        <ng-container [ngbNavItem]="4" [disabled]="_conditionsService.othOutStand">
                          <a ngbNavLink [ngClass]="{
                              disabled: _sharedSvc.disableTabsForAddress
                            }">{{
                            "clientOnboarding.otherOutstandings" | translate
                            }}</a>
                          <ng-template ngbNavContent>
                            <app-other-outstanding></app-other-outstanding>
                          </ng-template>
                        </ng-container>
                      </li>
                    </ng-container>
                    <ng-container *ngIf="_sharedSvc.businessAppraisal_FS">
                      <li [ngbNavItem]="5" (click)="_conditionsService.toggle()"
                        (click)="onTabClick('businessAppraisal')" (click)="_sharedSvc.disTabForAddresstoggle()">
                        <ng-container [ngbNavItem]="5" [disabled]="_conditionsService.bizApprsl">
                          <a ngbNavLink [ngClass]="{
                              disabled: _sharedSvc.disableTabsForAddress
                            }">{{
                            "clientOnboarding.businessApplTab.businessAppraisal"
                            | translate
                            }}</a>
                          <ng-template ngbNavContent>
                            <app-business-appraisal></app-business-appraisal>
                          </ng-template>
                        </ng-container>
                      </li>
                    </ng-container>
                    <ng-container *ngIf="_sharedSvc.EmployerInfo_FS">
                      <li [ngbNavItem]="6" (click)="_conditionsService.toggle()" (click)="onTabClick()"
                        (click)="_sharedSvc.disTabForAddresstoggle()">
                        <ng-container [ngbNavItem]="6" [disabled]="_conditionsService.empInfo">
                          <a ngbNavLink [ngClass]="{
                              disabled: _sharedSvc.disableTabsForAddress
                            }">{{
                            "clientOnboarding.employerInfo" | translate
                            }}</a>
                          <ng-template ngbNavContent>
                            <app-employer-info></app-employer-info>
                          </ng-template>
                        </ng-container>
                      </li>
                    </ng-container>
                    <ng-container *ngIf="_sharedSvc.FinancialInfo_FS">
                      <li [ngbNavItem]="7" (click)="_conditionsService.toggle()" (click)="onTabClick()"
                        (click)="_sharedSvc.disTabForAddresstoggle()">
                        <ng-container [ngbNavItem]="7" [disabled]="_conditionsService.finInfo">
                          <a ngbNavLink [ngClass]="{
                              disabled: _sharedSvc.disableTabsForAddress
                            }">{{
                            "clientOnboarding.financialInfo" | translate
                            }}</a>
                          <ng-template ngbNavContent>
                            <app-financial-info></app-financial-info>
                          </ng-template>
                        </ng-container>
                      </li>
                    </ng-container>
                    <!-- <ng-container *ngIf="_sharedSvc">
                      <li [ngbNavItem]="8">
                        <a ngbNavLink>PSC</a>
                        <ng-template ngbNavContent>
                          <app-psc></app-psc>
                        </ng-template>
                      </li>
                    </ng-container> -->
                    <ng-container *ngIf="_sharedSvc.summary_FS">
                      <li [ngbNavItem]="9" (click)="_conditionsService.toggle()" (click)="onTabClick('summary')"
                        (click)="_sharedSvc.disTabForAddresstoggle()">
                        <ng-container [ngbNavItem]="9" [disabled]="_conditionsService.summary">
                          <a ngbNavLink [ngClass]="{
                              disabled: _sharedSvc.disableTabsForAddress
                            }">
                            {{ "common.summary" | translate }}</a>
                          <ng-template ngbNavContent>
                            <app-summary></app-summary>
                          </ng-template>
                        </ng-container>
                      </li>
                    </ng-container>
                  </ul>
                  <div [ngbNavOutlet]="nav" class="mt-2"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>
<ng-template id="display-modal" #area let-c="close" let-d="dismiss">
  <div class="modal-body" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
    [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
    <div class="p-3 pb-0">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center">
            <h4>{{ "common.selectedArea" | translate }}</h4>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="inside-icon">
            <i class="fa fa-search popupSearchi"></i>
            <input type="Search here" class="form-control input-radius search-input box-shadow"
              placeholder="{{ 'common.search' | translate }}" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="table-responsive loan-table">
          <table class="table table-bordered box-shadow">
            <thead>
              <tr>
                <td>{{ "common.uc" | translate }}</td>
                <td>{{ "common.tehsil" | translate }}</td>
                <td>{{ "common.city" | translate }}</td>
                <td>{{ "common.district" | translate }}</td>
                <td>{{ "common.province" | translate }}</td>
                <td class="text-center">{{ "common.action" | translate }}</td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of _picklistService.locationData">
                <td>{{ data?.ucName }}</td>
                <td>{{ data?.thslName }}</td>
                <td>{{ data?.cityName }}</td>
                <td>{{ data?.districtName }}</td>
                <td>{{ data?.stateName }}</td>
                <td class="text-center">
                  <i class="fa-solid fa-share-from-square" (click)="onSelectionArea(data)"></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="button_div">
                        <button class="btn login-button-class" [class.pointerNone]="_sharedSvc.loading" [disabled]="_sharedSvc.loading">OK</button>
                        <button class="btn login-button-class cancelBtn" (click)="d('Cross click')" type="reset">CANCEL</button>
                    </div>
                </div>
            </div> -->
    </div>
  </div>
</ng-template>