import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CookieService } from 'ngx-cookie-service';
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { environment } from "src/app/global";
import { SharedService } from "src/app/components/shared/service/shared.service";
import { DatePipe } from "@angular/common";
import { Endpoint } from "src/app/components/shared/serviceEndPoints";
import { SavePayment, SaveExcsPayment } from "../model/recovery";

@Injectable({
  providedIn: 'root'
})
export class RecoveryService {

  savePaymentModel = new SavePayment();
  saveExcsPaymentModel = new SaveExcsPayment();
  _recoverydata: any;
  constructor(
    public router: Router,
    private _http: HttpClient,
    private _cookieSvc: CookieService,
    public _sharedSvc: SharedService,
    private modalService: NgbModal,
  ) { }

  // APIS Recovery Start 
  save_pymnt_txn() {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_Recovery_URL + Endpoint.Recovery.save_pymnt_txn, this.savePaymentModel, this._sharedSvc.requestOptions);
  }
  save_excs_pymnt() {
    this._sharedSvc.tokenHeader();
    return this._http.post<any>(environment.config.BASE_API_Recovery_URL + Endpoint.Recovery.save_excs_pymnt, this.saveExcsPaymentModel, this._sharedSvc.requestOptions);

  }
  get_excs_pymnt_lst() {

    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_Recovery_URL + Endpoint.Recovery.get_excs_pymnt_lst, this._sharedSvc.requestOptions);
  }
  get_by_excs_pymnt_seq(excsPymntSeq: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_Recovery_URL +
      Endpoint.Recovery.get_by_excs_pymnt_seq + '?excsPymntSeq=' + excsPymntSeq,
      this._sharedSvc.requestOptions);
  }
  srch_pymt_sched(clntId: any, cnic: any, phone: any) {
    this._sharedSvc.tokenHeader();
    if(clntId){}else{clntId = 0}
    if(cnic){}else{cnic = 0}
    if(phone){}else{phone = 0}
    // return this._http.get<any>(environment.config.BASE_API_DSBMT_URL +
    //   Endpoint.DisbustmentCredit.srch_pymt_sched + clntId + '/' + cnic + '/' + phone,
    //   this._sharedSvc.requestOptions);
    return this._http.get<any>(environment.config.BASE_API_URL +
      Endpoint.creditApp.get_ca_list + '/' + clntId + '/' + cnic + '/' + phone,
      this._sharedSvc.requestOptions);
  }
  srch_pymt_sched_dsbmt(clntId: any, cnic: any, phone: any) {
    this._sharedSvc.tokenHeader();
    if(clntId){}else{clntId = 0}
    if(cnic){}else{cnic = 0}
    if(phone){}else{phone = 0}
    return this._http.get<any>(environment.config.BASE_API_DSBMT_URL +
      Endpoint.DisbustmentCredit.srch_pymt_sched + clntId + '/' + cnic + '/' + phone,
      this._sharedSvc.requestOptions);
   
  }
  getClientDetailsByAppseq(appseq: any) {
    this._sharedSvc.tokenHeader();
    if(appseq){}else{appseq = 0}

    
    return this._http.get<any>(environment.config.BASE_API_URL +
      Endpoint.creditApp.get_client_detail_by_appseq + '/' + appseq,
      this._sharedSvc.requestOptions);
  }

  // APIS Recovery End 


  InItGetExcsPymntLst(): void {
    this._sharedSvc.showSpinner()
    this.get_excs_pymnt_lst().subscribe({
      next: (response: any) => {
        if (response) {
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }


  // pageChange() {
  //   this._sharedSvc.collectionSize = this.getAllClient?.length;
  //   this.getAllClientPG = this.getAllClient.slice((this._sharedSvc.page - 1) * this._sharedSvc.pageSize, (this._sharedSvc.page - 1) * this._sharedSvc.pageSize + this._sharedSvc.pageSize);
  // }


}