export class FinancialInfo {
    incomeType: string;
    frequency: string;
    quantity: string;
    incomeAmount: string;
    averageMonthlyAmount: string;
}
export class SaveClntIncmDtl {
    clntSeq: number;
    incmDtlSeq: number;
    incmCtgryKey: number;
    incmAmt: number;
    incmTypKey: any;
    qnty: number;
    frqncy: number;
    avgMnthlyIncm: number;
    cmnt: string;
    crtdBy: string;
    crtdDt: any;
    lastUpdBy: string;
    lastUpdDt: any;
    delFlg: boolean
}

export class SaveClntExpnsDtl {
    clntSeq:  number;
    expDtlSeq:  number;
    expCtgryKey:  number;
    expTypKey:  number;
    expAmt:  number;
    crtdBy: string;
    crtdDt: any;
    lastUpdBy: string;
    lastUpdDt: any;
    delFlg: boolean
}