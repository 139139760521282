import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CookieService } from 'ngx-cookie-service';
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { environment } from "src/app/global";
import { SharedService } from "src/app/components/shared/service/shared.service";
import { DatePipe } from "@angular/common";
import { PickList } from "./picklist";
import { ClintOnboardingService } from "../../clint-onboarding/shared/service/clint-onboarding.service";
import { BusinessAppraisalService } from "../../clint-onboarding/business-appraisal/shared/service/business-appraisal.service";
@Injectable({
  providedIn: 'root'
})

export class PicklistService {
  pickListModel = new PickList();
  GENDER_Data: any;
  SHROFF_STATUS_Data: any;
  MARITAL_STATUS_Data: any;
  EDUCATION_Data: any;
  OCCUPATION_Data: any;
  NATURE_OF_DISABILITY_Data: any;
  RESIDENCE_Data: any;
  PDC_Data: any;
  CUSTOMER_TYPE_Data: any;
  INCOME_TYPE_Data: any;
  INCOME_TYPE_DataClone: any;
  BUSINESS_RUNNER_Data: any;
  BUSINESS_RUNNER_DataClone: any;
  COMMUNITY_TYPE_Data: any;
  locationData: any;
  BUSINESS_FREQUENCY_Data: any;
  BUSINESS_EXPENSES_Data: any;
  BUSINESS_EXPENSES_DataClone: any;
  HOUSEHOLD_EXPENSES_Data: any;
  HOUSEHOLD_EXPENSES_DataClone: any;
  INSTITUTE_Data: any;
  INSTITUTE_DataClone: any;
  BUSINESSSECTOR_data: any;
  BUSINESSACTIVITY_data: any;
  BUSINESS_INCOME_TYPE_Data: any;
  SALARY_TYPE_Data: any;
  WORK_STATUS_Data: any;
  getAllQstn_data: any;
  allProductByGrpSeq_Data: any;
  allProductByGrp_Data: any;
  SaveCnicData: any;
  SHROFF_ID_Data: any;
  SHROFF_BAG_Data: any;
  BRANCH_BAG_Data: any;
  GOLD_RETE_Data: any;
  allProduct_Data: any;
  APPLICATION_STATUS_DATA: any;
  paymentTypeList_data: any;
  VAULT_POSITION_Data: any;
  VAULT_COLOR_Data: any;
  BRANCH_TYPE_Data: any;

  Ref_Code_Grp_Data: any;

  BAG_TYPE_LIST:any
  BAG_STATUS_LIST:any
  BAG_PLACE_STATUS_LIST:any
  QST_CTGRY_LIST: any
  QST_TYPE_LIST: any



  constructor(
    public router: Router,
    private _http: HttpClient,
    private _cookieSvc: CookieService,
    public _sharedSvc: SharedService,
    public _clintOnboardingSvc: ClintOnboardingService,
    public _businessAppraisalSvc: BusinessAppraisalService,
    public route: ActivatedRoute,
  ) { }

  pickListAPI(key: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + '/setup/pick-list/refCdValsForGrp/' + key, this._sharedSvc.requestOptions);
  }

  APPLICATION_STATUS(): void {
    this._sharedSvc.showSpinner()
    this.pickListAPI(this.pickListModel.APPLICATION_STATUS).subscribe({
      next: (response) => {
        if (response) {
          this.APPLICATION_STATUS_DATA = response;

          this.allProduct_Data = response;
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }

  callAllApis() {
    if (localStorage.getItem("SaveCnicData")) {
      this.SaveCnicData = localStorage.getItem("SaveCnicData");
      this.SaveCnicData = JSON.parse(this.SaveCnicData);
      this._clintOnboardingSvc.edit = true;
      this._clintOnboardingSvc.saveClientModel = Object.assign({}, this.SaveCnicData);
    }

    this.GENDER();
    this.MARITAL_STATUS();
    this.EDUCATION();
    this.OCCUPATION();
    this.NATURE_OF_DISABILITY();
    this.RESIDENCE();
    this.PDC();
    this.CUSTOMER_TYPE();
    this.InItGetLocations();
    this.InItGetGoCommunity();
    this.VAULT_POSITION();
    this.VAULT_COLOR();
    this.SHROFF_STATUS();
    this.BRANCH_TYPE();
  }
  QST_TYPE(): void {
    this._sharedSvc.showSpinner()
    this.pickListAPI(this.pickListModel.QST_TYP_KEY).subscribe({
      next: (response) => {
        if (response) {
          this.QST_TYPE_LIST = response;
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }
  QST_CTGRY(): void {
    this._sharedSvc.showSpinner()
    this.pickListAPI(this.pickListModel.QST_CTGRY_KEY).subscribe({
      next: (response) => {
        if (response) {
          this.QST_CTGRY_LIST = response;
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }
  BAG_TYPE(): void {
    this._sharedSvc.showSpinner()
    this.pickListAPI(this.pickListModel.BAG_TYPE).subscribe({
      next: (response) => {
        if (response) {
          this.BAG_TYPE_LIST = response;
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }
  BAG_STATUS(): void {
    this._sharedSvc.showSpinner()
    this.pickListAPI(this.pickListModel.BAG_STATUS).subscribe({
      next: (response) => {
        if (response) {
          this.BAG_STATUS_LIST = response;
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }
  BAG_PLACE_STATUS(): void {
    this._sharedSvc.showSpinner()
    this.pickListAPI(this.pickListModel.BAG_PLACE_STATUS).subscribe({
      next: (response) => {
        if (response) {
          this.BAG_PLACE_STATUS_LIST = response;
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }
  GENDER(): void {
    this._sharedSvc.showSpinner()
    this.pickListAPI(this.pickListModel.GENDER).subscribe({
      next: (response) => {
        if (response) {
          this.GENDER_Data = response;
          this._clintOnboardingSvc.saveClientModel.gndrKey = this._clintOnboardingSvc.saveClientModel.gndrKey;
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }
  BRANCH_TYPE(): void {
    this._sharedSvc.showSpinner()
    this.pickListAPI(this.pickListModel.BRANCH_TYPE).subscribe({
      next: (response) => {
        if (response) {
          this.BRANCH_TYPE_Data = response;
          // this._clintOnboardingSvc.saveClientModel.gndrKey = this._clintOnboardingSvc.saveClientModel.gndrKey;
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }
  SHROFF_STATUS(): void {
    this._sharedSvc.showSpinner()
    this.pickListAPI(this.pickListModel.SHROFF_STATUS).subscribe({
      next: (response) => {
        if (response) {
          this.SHROFF_STATUS_Data = response;
          // this._clintOnboardingSvc.saveClientModel.gndrKey = this._clintOnboardingSvc.saveClientModel.gndrKey;
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }
  MARITAL_STATUS(): void {
    this._sharedSvc.showSpinner()
    this.pickListAPI(this.pickListModel.MARITAL_STATUS).subscribe({
      next: (response) => {
        if (response) {
          this.MARITAL_STATUS_Data = response;
          this._clintOnboardingSvc.saveClientModel.mrtlStsKey = this._clintOnboardingSvc.saveClientModel.mrtlStsKey;
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }
  EDUCATION(): void {
    this._sharedSvc.showSpinner()
    this.pickListAPI(this.pickListModel.EDUCATION).subscribe({
      next: (response) => {
        if (response) {
          this.EDUCATION_Data = response;
          this._clintOnboardingSvc.saveClientModel.eduLvlKey = this._clintOnboardingSvc.saveClientModel.eduLvlKey;
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }
  OCCUPATION(): void {
    this._sharedSvc.showSpinner()
    this.pickListAPI(this.pickListModel.OCCUPATION).subscribe({
      next: (response) => {
        if (response) {
          this.OCCUPATION_Data = response;
          //this._clintOnboardingSvc.saveClientModel.occKey = this._clintOnboardingSvc.saveClientModel.occKey;
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }
  NATURE_OF_DISABILITY(): void {
    this._sharedSvc.showSpinner()
    this.pickListAPI(this.pickListModel.NATURE_OF_DISABILITY).subscribe({
      next: (response) => {
        if (response) {
          this.NATURE_OF_DISABILITY_Data = response;
          this._clintOnboardingSvc.saveClientModel.natrOfDisKey = this._clintOnboardingSvc.saveClientModel.natrOfDisKey;
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }
  RESIDENCE(): void {
    this._sharedSvc.showSpinner()
    this.pickListAPI(this.pickListModel.RESIDENCE).subscribe({
      next: (response) => {
        if (response) {
          this.RESIDENCE_Data = response;
          this._clintOnboardingSvc.saveClientModel.resTypKey = this._clintOnboardingSvc.saveClientModel.resTypKey;
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }
  PDC(): void {
    this._sharedSvc.showSpinner()
    this.pickListAPI(this.pickListModel.PDC).subscribe({
      next: (response) => {
        if (response) {
          this.PDC_Data = response;
          this._clintOnboardingSvc.saveClientModel.selfPdc = this._clintOnboardingSvc.saveClientModel.selfPdc;
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }

  CUSTOMER_TYPE(): void {
    this._sharedSvc.showSpinner()
    this.pickListAPI(this.pickListModel.CUSTOMER_TYPE).subscribe({
      next: (response) => {
        if (response) {
          this.CUSTOMER_TYPE_Data = response;
          // this._clintOnboardingSvc.saveClientModel.resTypKey = response[0]?.refCdSeq;
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }
  INCOME_TYPE(): void {
    this._sharedSvc.showSpinner()
    this.pickListAPI(this.pickListModel.INCOME_TYPE).subscribe({
      next: (response) => {
        if (response) {
          this.INCOME_TYPE_Data = [...<any>response];
          this.INCOME_TYPE_DataClone = [...<any>response];
          // this._clintOnboardingSvc.saveClientModel.resTypKey = response[0]?.refCdSeq;
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }
  BUSINESS_RUNNER(): void {
    this._sharedSvc.showSpinner()
    this.pickListAPI(this.pickListModel.BUSINESS_RUNNER).subscribe({
      next: (response) => {
        if (response) {
          this.BUSINESS_RUNNER_Data = [...<any>response];
          this.BUSINESS_RUNNER_DataClone = [...<any>response];
          // this._clintOnboardingSvc.saveClientModel.resTypKey = response[0]?.refCdSeq;
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }
  BUSINESS_INCOME_TYPE(): void {
    this._sharedSvc.showSpinner()
    this.pickListAPI(this.pickListModel.BUSINESS_INCOME_TYPE).subscribe({
      next: (response) => {
        if (response) {
          this.BUSINESS_INCOME_TYPE_Data = response;
          // this._clintOnboardingSvc.saveClientModel.resTypKey = response[0]?.refCdSeq;
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }
  SALARY_TYPE(): void {
    this._sharedSvc.showSpinner()
    this.pickListAPI(this.pickListModel.SALARY_TYPE).subscribe({
      next: (response) => {
        if (response) {
          this.SALARY_TYPE_Data = response;
          // this._clintOnboardingSvc.saveClientModel.resTypKey = response[0]?.refCdSeq;
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }
  BUSINESS_FREQUENCY(): void {
    this._sharedSvc.showSpinner()
    this.pickListAPI(this.pickListModel.BUSINESS_FREQUENCY).subscribe({
      next: (response) => {
        if (response) {
          this.BUSINESS_FREQUENCY_Data = response;
          // this._clintOnboardingSvc.saveClientModel.resTypKey = response[0]?.refCdSeq;
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }
  HOUSEHOLD_EXPENSES(): void {
    this._sharedSvc.showSpinner()
    this.pickListAPI(this.pickListModel.HOUSEHOLD_EXPENSES).subscribe({
      next: (response) => {
        if (response) {

          this.HOUSEHOLD_EXPENSES_Data = [...<any>response];
          this.HOUSEHOLD_EXPENSES_DataClone = [...<any>response];
          // this._clintOnboardingSvc.saveClientModel.resTypKey = response[0]?.refCdSeq;
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }
  BUSINESS_EXPENSES(): void {
    this._sharedSvc.showSpinner()
    this.pickListAPI(this.pickListModel.BUSINESS_EXPENSES).subscribe({
      next: (response) => {
        if (response) {
          this.BUSINESS_EXPENSES_Data = [...<any>response];
          this.BUSINESS_EXPENSES_DataClone = [...<any>response];
          // this._clintOnboardingSvc.saveClientModel.resTypKey = response[0]?.refCdSeq;
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }
  INSTITUTE(): void {
    this._sharedSvc.showSpinner()
    this.pickListAPI(this.pickListModel.INSTITUTE).subscribe({
      next: (response) => {
        if (response) {
          this.INSTITUTE_Data = [...<any>response];
          this.INSTITUTE_DataClone = [...<any>response];
          console.log(response)
          // this._clintOnboardingSvc.saveClientModel.resTypKey = response[0]?.refCdSeq;
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }
  WORK_STATUS(): void {
    this._sharedSvc.showSpinner()
    this.pickListAPI(this.pickListModel.WORK_STATUS).subscribe({
      next: (response) => {
        if (response) {
          this.WORK_STATUS_Data = response;
          // this._clintOnboardingSvc.saveClientModel.resTypKey = response[0]?.refCdSeq;
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }
  Ref_Code_Grp(): void {
    this._sharedSvc.showSpinner()
    this.pickListAPI(this.pickListModel.Ref_Code_Grp).subscribe({
      next: (response) => {
        if (response) {
          this.Ref_Code_Grp_Data = response;
          // this._clintOnboardingSvc.saveClientModel.resTypKey = response[0]?.refCdSeq;
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }
  // Business Appraisal
  businessSector() {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + '/setup/pick-list/get-biz-sec', this._sharedSvc.requestOptions);
  }
  InItBusinessSector(): void {
    this._sharedSvc.showSpinner()
    this.businessSector().subscribe({
      next: (response) => {
        if (response) {
          this.BUSINESSSECTOR_data = response;
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }
  businessActivity(sect_seq: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + '/setup/pick-list/get-biz-acty/' + sect_seq, this._sharedSvc.requestOptions);
  }
  tempSect_seq: any = null;
  InItBusinessActivity(event: any, callback?: any): void {
    if (event?.target?.value) {
      this.tempSect_seq = event?.target?.value;
    }
    else {
      this.tempSect_seq = event;
    }
    if (!this.tempSect_seq) { return; }
    this._sharedSvc.showSpinner()
    this.businessActivity(this.tempSect_seq).subscribe({
      next: (response) => {
        if (response) {
          this.BUSINESSACTIVITY_data = response;
          this._businessAppraisalSvc.saveBizAprslModel.actyKey = this._businessAppraisalSvc.saveBizAprslModel.actyKey;
          this._sharedSvc.hideSpinner();
          if (callback) {
            return callback(response);
          }
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }
  getAllQstn(qstnr_seq: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + '/setup/qstnr/get-all-qstn/' + qstnr_seq, this._sharedSvc.requestOptions);
  }
  InItGetAllQstn(): void {
    this._sharedSvc.showSpinner()
    this.getAllQstn(50).subscribe({
      next: (response: any) => {
        if (response) {
          this.getAllQstn_data = response?.questions;
          // this._clintOnboardingSvc.saveClientModel.resTypKey = response[0]?.refCdSeq;
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }

  // Location
  getGoCommunity(port_seq: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + '/setup/pick-list/get-geo-cmnty/' + port_seq, this._sharedSvc.requestOptions);
  }
  InItGetGoCommunity(): void {
    this._sharedSvc.showSpinner()
    this.getGoCommunity(16).subscribe({
      next: (response) => {
        if (response) {

          this.COMMUNITY_TYPE_Data = response;
          // this._clintOnboardingSvc.saveClientModel.resTypKey = response[0]?.refCdSeq;
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          this._sharedSvc.hideSpinner();
          return;
        }
        console.error(e);
        this._sharedSvc.error(e?.error?.error);
        this._sharedSvc.hideSpinner();
      }
      // complete: () => console.info('complete')
    })
  }
  getLocations() {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + '/setup/pick-list/get-locations', this._sharedSvc.requestOptions);
  }
  InItGetLocations(): void {
    this._sharedSvc.showSpinner()
    this.getLocations().subscribe({
      next: (response) => {
        if (response) {

          this.locationData = response;
          console.log(response)
          localStorage.setItem('locationData', JSON.stringify(response))
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }

  // CreditApp
  allProductByGrpSeq(key: any) {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + '/setup/pick-list/get-all-products-by-group-seq/' + key, this._sharedSvc.requestOptions);
  }
  InItAllProductByGrpSeq(key: any): void {
    if (key) { } else { return };
    this._sharedSvc.showSpinner()
    this.allProductByGrpSeq(key).subscribe({
      next: (response) => {
        if (response) {
          this.allProductByGrpSeq_Data = response;
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }
  allProductByGrp() {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + '/setup/pick-list/get-all-product-groups', this._sharedSvc.requestOptions);
  }
  InItAllProductByGrp(): void {
    this._sharedSvc.showSpinner()
    this.allProductByGrp().subscribe({
      next: (response) => {
        if (response) {
          this.allProductByGrp_Data = response;
          // this._clintOnboardingSvc.saveClientModel.resTypKey = response[0]?.refCdSeq;
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }

  // credit COLLATERAL
  callApisCOLLATERAL() {
    // this.SHROFF_ID();
    this.SHROFF_BAG();
    this.BRANCH_BAG();
    this.GOLD_RETE();
  }
  SHROFF_ID(): void {
    this._sharedSvc.showSpinner()
    this.pickListAPI(this.pickListModel.SHROFF_ID).subscribe({
      next: (response) => {
        if (response) {
          this.SHROFF_ID_Data = response;
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }
  SHROFF_BAG(): void {
    this._sharedSvc.showSpinner()
    this.pickListAPI(this.pickListModel.SHROFF_BAG).subscribe({
      next: (response) => {
        if (response) {
          this.SHROFF_BAG_Data = response;
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }
  BRANCH_BAG(): void {
    this._sharedSvc.showSpinner()
    this.pickListAPI(this.pickListModel.BRANCH_BAG).subscribe({
      next: (response) => {
        if (response) {
          this.BRANCH_BAG_Data = response;
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }
  GOLD_RETE(): void {
    this._sharedSvc.showSpinner()
    this.pickListAPI(this.pickListModel.GOLD_RETE).subscribe({
      next: (response) => {
        if (response) {
          this.GOLD_RETE_Data = response;
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }

  // Product Group
  getALlProduct() {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + '/setup/pick-list/get-all-products', this._sharedSvc.requestOptions);
  }
  InItGetALlProduct(): void {
    this._sharedSvc.showSpinner()
    this.getALlProduct().subscribe({
      next: (response) => {
        if (response) {
          this.allProduct_Data = response;
          console.log(response)
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }

  // Payment List
  pymt_typ_list() {
    this._sharedSvc.tokenHeader();
    return this._http.get<any>(environment.config.BASE_API_URL_pickList + '/setup/pick-list/pymt-typ-list', this._sharedSvc.requestOptions);
  }
  InItPaymentTypeList(): void {
    this._sharedSvc.showSpinner()
    this.pymt_typ_list().subscribe({
      next: (response) => {
        if (response) {
          this.paymentTypeList_data = response;
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }

  VAULT_POSITION(): void {
    this._sharedSvc.showSpinner()
    this.pickListAPI(this.pickListModel.VAULT_POSITION).subscribe({
      next: (response) => {
        if (response) {
          this.VAULT_POSITION_Data = response;
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }
  VAULT_COLOR(): void {
    this._sharedSvc.showSpinner()
    this.pickListAPI(this.pickListModel.VAULT_COLOR).subscribe({
      next: (response) => {
        if (response) {
          this.VAULT_COLOR_Data = response;
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }

}
