
export class DisbursementVoucher{
    crdtAppSeq: any;
    dsbmtSeq: any;
    dsbmtId: any;
    vchrTyp:any;
    firstInstDate: any;
    dsbmtHdrSeq: number;
    dsbmtDtlSeq: number;
    instrNum: string;
    dsbmtAmount: number;
    pymtTypSeq: number;
    pymtType:string;
    crtdBy: string;
    crtdDt: string;
    lastUpdBy: string;
    lastUpdDt: string;
    delFlg= false;
    changed=false;
    dsbmtStsKey:number;
    dsbmtDt:string;
  }
  