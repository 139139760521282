<section>
    <div class="sidebar" [class.sidebar-close]="!_sharedSvc.openSidebar">
        <app-sidebar></app-sidebar>
    </div>
    <div class="home-section">
        <div class="home-main" [class.sidebar-close_home-main]="!_sharedSvc.openSidebar">
            <app-navbar></app-navbar>

            <div class="home-view" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
                [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
                <section>
                    <div class="row mb-3">
                        <div class="col-sm-8 col-md-8 col-lg-8">
                            <h4>{{'sideBarMenu.admin.excessRecovery' | translate}}</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <div class="dashboard-card loan-table">
                                <div class="row mb-3">
                                    <div class="col-sm-7 col-md-7 col-lg-8">
                                        <ng-container *ngIf="_sharedSvc.pageSize >= 10">
                                            <span>{{'common.show' | translate}}</span>
                                            <input type="number" class="m-1 text-center" style="width: 45px;">
                                            <span>{{'common.entries' | translate}}</span>
                                        </ng-container>
                                    </div>
                                    <div class="col-sm-5 col-md-5 col-lg-4">
                                        <div class="inside-icon">
                                            <i class="fa fa-search"></i>
                                            <input type="search" class="form-control search-input"
                                                placeholder="{{'common.search' | translate}}">
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                        <div class="table-responsive">
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <td>{{'excessRecovery.transaction' | translate}} #.</td>
                                                        <!-- <td>Name</td> -->
                                                        <td>{{'common.clientId' | translate}}</td>
                                                        <td>{{'common.clientName' | translate}}</td>
                                                        <td>{{'excessRecovery.excessAmount' | translate}}</td>
                                                        <td>{{'excessRecovery.paymentDate' | translate}}</td>
                                                        <td>{{'excessRecovery.agent' | translate}}</td>
                                                        <!-- <td>Collateral Detail</td> -->
                                                        <td class="text-center">{{'common.action' | translate}}</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let recovery of recoveries">

                                                        <td>{{recovery.excsPymntSeq}}</td>
                                                        <!-- <td>1ABC</td> -->
                                                        <td>{{recovery.clntSeq}}</td>
                                                        <!-- <td>1ABC</td> -->
                                                        <td>1ABC</td>
                                                        <td>{{recovery.excsAmount}}</td>
                                                        <td>{{recovery.rcvDate.split("T")[0]}}</td>
                                                        <td>{{recovery.aprvdById}}</td>
                                                        <td class="text-center">
                                                            <div class="action-inner">
                                                                <i class="fa fa-dollar"></i>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-sm-8 col-md-8 col-lg-8">
                                        <h4>{{'common.total' | translate}} {{'excessRecovery.excessAmount' |
                                            translate}}: {{total}}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</section>