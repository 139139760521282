import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from '../../shared/service/shared.service';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { ClintOnboardingService } from '../../clint-onboarding/shared/service/clint-onboarding.service';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'app-cnic-expiry',
  templateUrl: './cnic-expiry.component.html',
  styleUrls: ['./cnic-expiry.component.css']
})
export class CnicExpiryComponent implements OnInit {


  loadingIndicator: boolean = false;
  rows: any;

  page: number = 5;
  pageSize: number = 5;
  collectionSize: number = 5;

  loginForm!: FormGroup;
  submitted = false;
  // currentDate: any = new Date().toISOString().split("T")[0];
  onBoardingTablePG: any;
  maskedCNIC: string;


  constructor(
    private modalService: NgbModal,
    public router: Router,
    public _sharedSvc: SharedService,
    public _clintOnboardingSvc: ClintOnboardingService,
    public translate: TranslateService,
    private formBuilder: FormBuilder,

  ) { }

  ngOnInit(): void {
  }

  pageChange() {
    this.collectionSize = this._clintOnboardingSvc.onBoardingTable.length;
    this.onBoardingTablePG = this._clintOnboardingSvc.onBoardingTable.slice(
      (this.page - 1) * this.pageSize,
      (this.page - 1) * this.pageSize + this.pageSize
    );
    this.onBoardingTablePG;
  }

  navigate() {
    this.router.navigate(['/disbursement-form']);
  }
}