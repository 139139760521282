import { Component, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SharedService } from "src/app/components/shared/service/shared.service";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
  NgForm,
} from "@angular/forms";

import { ClintOnboardingService } from "src/app/components/clint-onboarding/shared/service/clint-onboarding.service";
import { SetupService } from "../../shared/service/setup.service";
import { PicklistService } from "src/app/components/shared/pickList/picklist.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-region",
  templateUrl: "./region.component.html",
  styleUrls: ["./region.component.css"],
})
export class RegionComponent implements OnInit {
  loadingIndicator: boolean = false;
  rows: any;

  page: number = 5;
  pageSize: number = 5;
  collectionSize: number = 5;

  loginForm!: FormGroup;
  submitted = false;
  // currentDate: any = new Date().toISOString().split("T")[0];
  onBoardingTablePG: any;
  maskedCNIC: string;
  addRegionformData: any;
  singleRecord: any;

  isEdit: boolean;
  addRegEmpformData: any;
  selectedManager: any;
  regionData: any;
  isNewSelectedManager: boolean = true;
  selectedEMPFROMAPI: any;

  constructor(
    private modalService: NgbModal,
    public router: Router,
    public _sharedSvc: SharedService,
    public _clintOnboardingSvc: ClintOnboardingService,
    public _SetupService: SetupService,
    private formBuilder: FormBuilder,
    public _picklistService: PicklistService,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.addRegionformData = this.formBuilder.group({
      regionName: ["", Validators.required],
      regionStatusKey: ["", Validators.required],
      regionDescription: ["", Validators.required],
    });
    this.addRegEmpformData = this.formBuilder.group({});
    this._SetupService.get_region_list();
    this._picklistService.SHROFF_STATUS();
    this._SetupService.get_all_emp_list();
  }

  submitRegionForm(val) {
    if (this.addRegionformData.invalid) {
      for (const control of Object.keys(this.addRegionformData.controls)) {
        this.addRegionformData.controls[control].markAsTouched();
      }
      return;
    }
    let data = {
      ...this.addRegionformData.value,
      regionSequence: null,
      regionCode: "0",
      delFlg: 0,
    };
    if (this.isEdit) {
      data.regionSequence = this.singleRecord.regionSequence;
    }
    this._SetupService.setupRegionModel = data;
    this._sharedSvc.showSpinner3();
    this._SetupService.saveRegion().subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.success(
            this.translate.instant("toast.region") +
            (this.isEdit === true
              ? this.translate.instant("toast.updated")
              : this.translate.instant("toast.added"))
          );
          this._sharedSvc.hideSpinner3();
          this.modalService.dismissAll();
          this._SetupService.get_region_list();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner3();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(
            ([key, value]: any, index: number) => {
              this._sharedSvc.error(value);
            }
          );
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    });
  }

  editArea(content: any, id: any, edit: any) {
    let list = this._SetupService.allRegionList;
    let record = list.find((x) => x.regionSequence === id);
    this.singleRecord = record;

    if (record) {
      let data = {
        regionName: record.regionName,
        regionStatusKey: record.regionStatusKey,
        regionDescription: record.regionDescription,
      };
      this.addRegionformData.patchValue(data);
      this.openModal(content, edit);
    }
  }

  get stsKey() {
    return this.addRegionformData.get("regionStatusKey");
  }
  get regionName() {
    return this.addRegionformData.get("regionName");
  }
  get regionDescription() {
    return this.addRegionformData.get("regionDescription");
  }

  deleteRecord(id) {
    let list = this._SetupService.allRegionList;
    let record = list.find((x) => x.regionSequence === id);
    this.singleRecord = record;

    if (record) {
      let data = {
        ...record,
        delFlg: 1,
      };
      this._sharedSvc.showSpinner3();
      this._SetupService.setupRegionModel = data;
      this._SetupService.saveRegion().subscribe({
        next: (response) => {
          if (response) {
            this._sharedSvc.success(
              this.translate.instant("toast.regionDeleted")
            );
            this._sharedSvc.hideSpinner3();
            this.modalService.dismissAll();
            this._SetupService.get_region_list();
          }
        },
        error: (e) => {
          this._sharedSvc.hideSpinner3();
          if (e.status == 401) {
            this._sharedSvc.error(this._sharedSvc.authMsg);
            this._sharedSvc.logout();
            return;
          }
          if (e?.error) {
            Object.entries(e?.error).forEach(
              ([key, value]: any, index: number) => {
                this._sharedSvc.error(value);
              }
            );
          }
          if (e?.error?.errorMessage) {
            this._sharedSvc.error(e?.error?.error);
          }
        },
      });
    }
  }

  pageChange() {
    this.collectionSize = this._clintOnboardingSvc.onBoardingTable.length;
    this.onBoardingTablePG = this._clintOnboardingSvc.onBoardingTable.slice(
      (this.page - 1) * this.pageSize,
      (this.page - 1) * this.pageSize + this.pageSize
    );
    this.onBoardingTablePG;
  }

  openModal(content: any, edit: boolean) {
    localStorage.removeItem("selectedOrderData");
    this.isEdit = edit;
    this.modalService.open(content, {
      // backdrop: "static",
      // keyboard: false,
      windowClass: "popupWidth800",
      // backdropClass: 'light-blue-backdrop',
      centered: true,
    });
  }

  openModalEmployeeAssignment(content: any, data: any) {
    localStorage.removeItem("selectedOrderData");
    this.regionData = data;
    this.get_region_emp_list(data.regionSequence);
    this.modalService
      .open(content, {
        // backdrop: "static",
        // keyboard: false,
        windowClass: "popupWidth800",
        // backdropClass: 'light-blue-backdrop',
        centered: true,
      })
      .result.then(
        (result) => {
          // Your logic when modal is closed
          this.selectedManager = null;
        },
        (reason) => {
          // Your logic when modal is dismissed
          this.selectedManager = null;
        }
      );
  }

  get_region_emp_list(key: any) {
    
    this._sharedSvc.showSpinner()
    this._SetupService.getRegionEmpList(key).subscribe(res => {
      this._sharedSvc.hideSpinner();
      //@ts-ignore
      if (res.error) {
        //@ts-ignore
        this._sharedSvc.error(res.error);
        this.isNewSelectedManager = true;
        return;
      }
      //@ts-ignore
      this.selectedManager = res[0].empSeq;
      this.selectedEMPFROMAPI = res[0];
      this.isNewSelectedManager = false;
    });
  }

  navigate() {
    this.router.navigate(["/disbursement-form"]);
  }

  areaFn(region) {
    sessionStorage.setItem("singleRegionID", region.regionSequence);
    this.router.navigate(["/setup-area"]);
  }
  submitArea() {
    this._SetupService.saveCity().subscribe((data) => {
      alert("area added");
      // console.log(data)
    });
  }
  onSelectManager(event: any) {
    this.selectedManager = event.target.value;
  }
  clearSelectedManager() {
    this.removeRegionManager();
  }

  SubmitRegionManager() {
    if (this.selectedManager) {
      let record = this._SetupService.allEMPLIST.find(
        (x) => x.empSeq === Number(this.selectedManager)
      );
      if (record) {
        let data = {
          regEmpSequence: null,
          regionSequence: this.regionData.regionSequence,
          coveringEmpSequence: null,
          employeeSequence: Number(record.empSeq),
          delFlg: false,
        };
        this._SetupService.setupRegionEMPModel = data;
        this._SetupService.saveRegionEMP().subscribe({
          next: (response) => {
            if (response) {
              this._sharedSvc.success(this.translate.instant('toast.employeeAssign'));
              this._sharedSvc.hideSpinner3();
              this.modalService.dismissAll();
            }
          },
          error: (e) => {
            this._sharedSvc.hideSpinner3();
            if (e.status == 401) {
              this._sharedSvc.error(this._sharedSvc.authMsg);
              this._sharedSvc.logout();
              return;
            }
            if (e?.error) {
              Object.entries(e?.error).forEach(
                ([key, value]: any, index: number) => {
                  this._sharedSvc.error(value);
                }
              );
            }
            if (e?.error?.errorMessage) {
              this._sharedSvc.error(e?.error?.error);
            }
          },
        });
      }
    } else {
      this._sharedSvc.error(this.translate.instant('toast.selectRegionManagerFirst'));
    }
  }

  removeRegionManager() {
    if (this.selectedManager && this.isNewSelectedManager === false) {
      let record = this._SetupService.allEMPLIST.find(
        (x) => x.empSeq === Number(this.selectedManager)
      );
      if (record) {
        let data = {
          regionSequence: this.regionData.regionSequence,
          regEmpSequence: this.selectedEMPFROMAPI.regEmpSeq,
          coveringEmpSequence: null,
          employeeSequence: Number(record.empSeq),
          delFlg: true,
        };
        this._SetupService.setupRegionEMPModel = data;
        this._SetupService.saveRegionEMP().subscribe({
          next: (response) => {
            if (response) {
              this._sharedSvc.success(this.translate.instant('toast.employeeRemoved'));
              this._sharedSvc.hideSpinner3();
              this.modalService.dismissAll();
              this.selectedManager = null;
            }
          },
          error: (e) => {
            this._sharedSvc.hideSpinner3();
            if (e.status == 401) {
              this._sharedSvc.error(this._sharedSvc.authMsg);
              this._sharedSvc.logout();
              return;
            }
            if (e?.error) {
              Object.entries(e?.error).forEach(
                ([key, value]: any, index: number) => {
                  this._sharedSvc.error(value);
                }
              );
            }
            if (e?.error?.errorMessage) {
              this._sharedSvc.error(e?.error?.error);
            }
          },
        });
      }
    } else {
      this.selectedManager = null;
    }
  }
}
