import { Component, OnInit, DestroyRef, inject } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from '../../shared/service/shared.service';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { ClintOnboardingService } from '../../clint-onboarding/shared/service/clint-onboarding.service';
import { AdminService } from '../shared/service/admin.service';
import { SetupService } from '../../setup/shared/service/setup.service';
import { RouteInfo } from 'src/app/auth/model/route-info';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-journel-voucher',
  templateUrl: './journel-voucher.component.html',
  styleUrls: ['./journel-voucher.component.css']
})
export class JournelVoucherComponent implements OnInit {

  allowedRoutes: RouteInfo[];
  loadingIndicator: boolean = false;
  rows: any;

  page: number = 5;
  pageSize: number = 5;
  collectionSize: number = 5;

  loginForm!: FormGroup;
  submitted = false;
  // currentDate: any = new Date().toISOString().split("T")[0];
  onBoardingTablePG: any;
  maskedCNIC: string;

  voucherList: any = [];
  branchList: any = [];

  checkForDownList: any = []
  #destroyRef = inject(DestroyRef);


  constructor(
    private modalService: NgbModal,
    public router: Router,
    public _sharedSvc: SharedService,
    public _clintOnboardingSvc: ClintOnboardingService,
    public _adminSvc: AdminService,
    public _setupSvc: SetupService,
    private formBuilder: FormBuilder,
    public translate: TranslateService,

  ) {
    this.allowedRoutes = JSON.parse(sessionStorage.getItem("routeInfo"));
    this.#destroyRef.onDestroy(() => {
      document.body.classList.remove("printFormvoucher")
    });
  }

  ngOnInit(): void {
    document.body.classList.add("printFormvoucher")

    let branchid = localStorage.getItem("BranchSeqIDByPort");
    let parsed = JSON.parse(branchid);
    this.getvoucherList(parsed?.brnchSeq);
    this.getAllBranchList();
  }

  pageChange() {
    this.collectionSize = this._clintOnboardingSvc.onBoardingTable.length;
    this.onBoardingTablePG = this._clintOnboardingSvc.onBoardingTable.slice(
      (this.page - 1) * this.pageSize,
      (this.page - 1) * this.pageSize + this.pageSize
    );
    this.onBoardingTablePG;
  }

  navigate() {
    this.router.navigate(['/disbursement-form']);
  }
  printPage() {
    window.print();
  }

  getAllBranchList() {
    this._sharedSvc.showSpinner3();
    this._setupSvc.getAllBranchList().subscribe({
      next: (response) => {
        if (response) {
          console.log(response)
          this.branchList = response;
          this._sharedSvc.hideSpinner3();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner3();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(
            ([key, value]: any, index: number) => {
              this._sharedSvc.error(value);
            }
          );
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    });
  }

  changeVouchers(e: any) {
    console.log(e.target.value);

    this.getvoucherList(e.target.value)
  }

  getvoucherList(id: number) {
    this._sharedSvc.showSpinner3();
    this._adminSvc.journalVoucher(id).subscribe({
      next: (response) => {
        if (response) {
          console.log(response)
          if (response) {
            //@ts-ignore
            this.voucherList = response.map(x => {
              return { ...x, isCollapsed: false, details: [] }

            });
            console.log(this.voucherList)
          }
          this._sharedSvc.hideSpinner3();
          this.modalService.dismissAll();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner3();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(
            ([key, value]: any, index: number) => {
              this._sharedSvc.error(value);
            }
          );
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    });

  }
  getvoucherDetailList(id: number, index: number) {
    this.checkForDownList.push(index)
    this._sharedSvc.showSpinner3();
    this._adminSvc.journalVoucherdetail(id).subscribe({
      next: (response) => {
        if (response) {
          console.log(response)
          this.voucherList[index].details = response;
          this._sharedSvc.hideSpinner3();
          this.modalService.dismissAll();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner3();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(
            ([key, value]: any, index: number) => {
              this._sharedSvc.error(value);
            }
          );
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    });
  }

  checkforIndex(index) {
    return this.checkForDownList.some(x => x === index);
  }
  collapseDown(id, index) {
    if (this.voucherList[index].details.length === 0) {
      this.getvoucherDetailList(id, index);
    }
    let allList = this.voucherList.map(x => {
      return { ...x, isCollapsed: false }
    });
    allList[index].isCollapsed = !allList[index].isCollapsed;
    this.voucherList = allList;
  }

  getSumOfDbAmt(index) {
    let sum = 0;
    for (let i = 0; i < this.voucherList[index].details.length; i++) {
      sum += this.voucherList[index].details[i].dbAmt;
    }
    return sum;
  }
  getSumOfcrAmt(index) {
    let sum = 0;
    for (let i = 0; i < this.voucherList[index].details.length; i++) {
      sum += this.voucherList[index].details[i].crAmt;
    }
    return sum;
  }
}