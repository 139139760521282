<section>
    <div class="sidebar" [class.sidebar-close]="!_sharedSvc.openSidebar">
        <app-sidebar></app-sidebar>
    </div>
    <div class="home-section">
        <div class="home-main" [class.sidebar-close_home-main]="!_sharedSvc.openSidebar">
            <app-navbar></app-navbar>

            <div class="home-view" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
                [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
                <section>
                    <div class="row mb-3">
                        <div class="col-sm-8 col-md-8 col-lg-8">
                            <h4>{{'branchAccounting.branchAccounting' | translate}}</h4>
                        </div>
                        <div class="col-sm-4 col-md-4 col-lg-4">
                            <div class="add-button" (click)="openModal(addExpence)"><i class="fa fa-plus"></i></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <div class="dashboard-card loan-table">
                                <div class="row mb-3">
                                    <div class="col-sm-7 col-md-7 col-lg-8">
                                        <ng-container *ngIf="_sharedSvc.pageSize >= 10">
                                            <span>{{'common.show' | translate}}</span>
                                            <input type="number" class="m-1 text-center" style="width: 45px;">
                                            <span>{{'common.entries' | translate}}</span>
                                        </ng-container>
                                    </div>
                                    <div class="col-sm-5 col-md-5 col-lg-4">
                                        <div class="inside-icon">
                                            <i class="fa fa-search"></i>
                                            <input type="search" class="form-control search-input"
                                                placeholder="{{'common.search' | translate}}">
                                        </div>
                                    </div>
                                </div>
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <td>{{'branchAccounting.referenceCode' | translate}}</td>
                                                <td>{{'common.description' | translate}}</td>
                                                <td>{{'common.category' | translate}}</td>
                                                <td>{{'common.amount' | translate}}</td>
                                                <td>{{'common.instrument' | translate}}</td>
                                                <td>{{'common.mode' | translate}}</td>
                                                <td>{{'common.date' | translate}}</td>
                                                <td>{{'common.type' | translate}}</td>
                                                <td class="text-center">{{'common.action' | translate}}</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>

                                                <td>1ABC</td>
                                                <td>1ABC</td>
                                                <td>1ABC</td>
                                                <td>1ABC</td>
                                                <td>1ABC</td>
                                                <td>1ABC</td>
                                                <td>1ABC</td>
                                                <td>1ABC</td>
                                                <td class="text-center">
                                                    <div class="action-inner">
                                                        <i class="fa fa-print"></i>

                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12 col-lg-6">
                                            <span class="clr-9d9a9a">{{'common.showing' | translate}} 1 {{'common.to' |
                                                translate}} 3 {{'common.of' | translate}} 3
                                                {{'common.entries' | translate}}</span>
                                        </div>
                                        <div class="col-sm-12 col-md-12 col-lg-6">
                                            <div class="float-right">
                                                <nav aria-label="Page navigation example">
                                                    <ul class="pagination">
                                                        <li class="page-item"><a class="page-link">{{'common.first' |
                                                                translate}}</a></li>
                                                        <li class="page-item"><a class="page-link">{{'common.previous' |
                                                                translate}}</a></li>
                                                        <li class="page-item"><a class="page-link">1</a></li>
                                                        <li class="page-item"><a class="page-link">2</a></li>
                                                        <li class="page-item"><a class="page-link">3</a></li>
                                                        <li class="page-item"><a class="page-link">4</a></li>
                                                        <li class="page-item"><a class="page-link">5</a></li>
                                                        <li class="page-item"><a class="page-link">{{'common.next' |
                                                                translate}}</a></li>
                                                        <li class="page-item"><a class="page-link">{{'common.last' |
                                                                translate}}</a></li>
                                                    </ul>
                                                </nav>


                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>

    </div>
</section>



<ng-template id="display-modal" #addExpence let-c="close" let-d="dismiss">
    <div class="modal-body" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
        [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
        <div class="p-3 pb-0">
            <div class="row mb-3">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="text-center">
                        <h4>{{'branchAccounting.addExpense' | translate}}</h4>
                    </div>
                </div>
            </div>
            <form>
                <div class="row mb-3">
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                        <div class="display-flex">
                            <h6 class="m-1"><b>{{'common.type' | translate}}: </b></h6>
                            <input type="radio" class="ms-2"> <span class="ms-2">{{'common.payment' | translate}}</span>
                            <input type="radio" class="ms-2"> <span class="ms-2">{{'common.receipt' | translate}}</span>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                        <div class="form-floating">
                            <input type="text" style="text-transform: capitalize;" autocomplete="off"
                                class="form-control input-radius">
                            <label>{{'common.description' | translate}}</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                        <div class="form-floating">
                            <select class="form-control form-select input-radius">
                                <option>{{'common.select' | translate}}</option>
                                <option>{{'common.select' | translate}}</option>
                                <option>{{'common.select' | translate}}</option>
                            </select>
                            <label>{{'common.category' | translate}}</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                        <div class="form-floating">
                            <input type="text" style="text-transform: capitalize;" autocomplete="off"
                                class="form-control input-radius">
                            <label>{{'common.amount' | translate}}</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                        <div class="form-floating">
                            <select class="form-control form-select input-radius">
                                <option>{{'common.select' | translate}}</option>
                                <option>{{'common.select' | translate}}</option>
                                <option>{{'common.select' | translate}}</option>
                            </select>
                            <label>{{'common.mode' | translate}}</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                        <div class="form-floating">
                            <input type="text" style="text-transform: capitalize;" autocomplete="off"
                                class="form-control input-radius">
                            <label>{{'branchAccounting.instrumentNo' | translate}}.</label>
                        </div>
                    </div>




                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="button_div">
                            <button class="btn login-button-class" [class.pointerNone]="_sharedSvc.loading"
                                [disabled]="_sharedSvc.loading">{{'common.ok' | translate}}</button>
                            <button class="btn login-button-class cancelBtn" (click)="d('Cross click')"
                                type="reset">{{'common.cancel' | translate}}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>