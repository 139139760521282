import { Injectable } from '@angular/core';
import { GlobalConfig, ToastrService } from 'ngx-toastr';
import { DeleteToastr } from './toastr.abstract';
import { EditToastr } from './toastr.abstract';
import { AddToastr } from './toastr.abstract';

@Injectable({
  providedIn: 'root'
})
export class CustomCrudToastrService {
  lanauge = 'en';
  constructor(private toaster: ToastrService) {}

  successLong(
    titleEn: string,
    titleAr: string,
    msgEn: string,
    msgAr: string,
    config: Partial<GlobalConfig>
  ) {
    const addToaster = new AddToastr(
      titleEn,
      titleAr,
      this.lanauge,
      msgEn,
      msgAr
    );
  // console.log(titleEn, titleAr, msgEn, msgAr);
    this.toaster.success(addToaster.getMsg(), addToaster.getTitle(), {
      ...config
    });
  }
  
  show(
    titleEn: string,
    titleAr: string,
    msgEn: string,
    msgAr: string,
    config: Partial<GlobalConfig>
  ) {
    const editToaster = new EditToastr(
      titleEn,
      titleAr,
      this.lanauge,
      msgEn,
      msgAr
    );
    this.toaster.show(editToaster.getMsg(), editToaster.getTitle(), {
      ...config
    });
  }

  success(
    titleEn: string,
    titleAr: string,
    msgEn: string,
    msgAr: string,
    config: Partial<GlobalConfig>
  ) {
    const editToaster = new EditToastr(
      titleEn,
      titleAr,
      this.lanauge,
      msgEn,
      msgAr
    );
    this.toaster.success(editToaster.getMsg(), editToaster.getTitle(), {
      ...config
    });
  }

  error(
    titleEn: string,
    titleAr: string,
    msgEn: string,
    msgAr: string,
    config: Partial<GlobalConfig>
  ) {
    const deleteToaster = new DeleteToastr(
      titleEn,
      titleAr,
      this.lanauge,
      msgEn,
      msgAr
    );
    this.toaster.error(deleteToaster.getMsg(), deleteToaster.getTitle(), {
      ...config
    });
  }

  info(
    titleEn: string,
    titleAr: string,
    msgEn: string,
    msgAr: string,
    config: Partial<GlobalConfig>
  ) {
    const deleteToaster = new DeleteToastr(
      titleEn,
      titleAr,
      this.lanauge,
      msgEn,
      msgAr
    );
    this.toaster.info(deleteToaster.getMsg(), deleteToaster.getTitle(), {
      ...config
    });
  }

  warning(
    titleEn: string,
    titleAr: string,
    msgEn: string,
    msgAr: string,
    config: Partial<GlobalConfig>
  ) {
    const deleteToaster = new DeleteToastr(
      titleEn,
      titleAr,
      this.lanauge,
      msgEn,
      msgAr
    );
    this.toaster.warning(deleteToaster.getMsg(), deleteToaster.getTitle(), {
      ...config
    });
  }
  
}
