import { Component, OnInit, DestroyRef, inject } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from "@angular/router";
import { AbstractControl, FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import { SharedService } from "../../shared/service/shared.service";
import { ClintOnboardingService } from "../shared/service/clint-onboarding.service";
import { DatePipe } from "@angular/common";
import { PicklistService } from "../../shared/pickList/picklist.service";
import { Address, SaveClient } from "../shared/model/clintOnboarding";
import { FiniancialInfoService } from "../financial-info/shared/service/finiancial-info.service";
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { RoutingConditionsService } from "../shared/service/routing-conditions.service";
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { ClientDocsService } from "../client-docs/shared/service/client-docs.service";
import { EmployerInfoService } from "../employer-info/shared/service/employer-info.service";
import { BusinessAppraisalService } from "../business-appraisal/shared/service/business-appraisal.service";
import { SharedPopupService } from "../../shared/service/shared-popup.service";
import { TranslateService } from '@ngx-translate/core';


// interface IBasicInfoForm {
//   clntSeq: number;
//   tenetKey: number;
//   clntId: string;
//   cnicNum: any;
//   cnicExpryDt: any;
//   cnicIssueDt: any;
//   frstNm: string;
//   lastNm: string;
//   nickNm: string;
//   mthrMadnNm: string;
//   phNum: any;
//   dob: any;
//   gndrKey: number;
//   mrtlStsKey: number;
//   eduLvlKey: number;
//   natrOfDisKey: number;
//   occKey: number;
//   clntStsKey: number;
//   resTypKey: number;
//   mnthsRes: number;
//   fthrSpzFrstNm: string;
//   fthrSpzLastNm: string;
//   portKey: number;
//   householdMembers: number;
//   numberOfDependents: number;
//   numberOfChildren: number;
//   selfPdc: number;
//   walletNumber: any;
//   customerTypeKey: number;
//   prmAddr: string;
//   delFlg: boolean;
// }
// interface IAddress {
//   clntSeq: number;
//   addrSeq: number;
//   addrRelSeq: number;
//   delFlg: boolean;
//   locSeq: number;
//   hseNum: string;
//   strt: string;
//   cmntySeq: number;
//   vlg: string;
//   othDtl: string;
//   latitude: number;
//   longitude: number;
//   mnthsOfResidence: number;
//   isPermAddress: boolean;
//   entySeq: number;
//   entyType: string;
// }
@Component({
  selector: "app-personal-info",
  templateUrl: "./personal-info.component.html",
  styleUrls: ["./personal-info.component.css"],
})

export class PersonalInfoComponent implements OnInit {

  disabled = true;
  model: NgbDateStruct;
  basicInfoForm: FormGroup;
  // basicInfo: IBasicInfoForm;
  basicInfoFormValue: SaveClient[];
  basicInfoDiv: boolean = true;
  addressForm: FormGroup;
  // addressModel: Address;
  currentDate: any = new Date().toISOString().split("T")[0];
  maskedCNIC: string;
  saveCNICModel: any;
  SaveCnicData: any;
  p_add_fld: boolean = false;
  disabilityVar: any = "no";
  clntSeq: any;
  FM: boolean = false;
  disNatrOfDisKey: boolean = true;
  numberOfDependentsInvalid: boolean = false;
  numberOfChildrenInvalid: boolean = false;
  phoneField: boolean = false;
  walletField: boolean = false;
  C_model: any;
  A_model: any;
  saveCnicData: any;
  invalidBasicInfoControls: any;
  invalidAddressControls: any;
  #destroyRef = inject(DestroyRef);


  constructor(
    private modalService: NgbModal,
    public router: Router,
    private formBuilder: FormBuilder,
    public _sharedSvc: SharedService,
    public _clintOnboardingSvc: ClintOnboardingService,
    public _picklistService: PicklistService,
    public route: ActivatedRoute,
    public _finiancialInfoSvc: FiniancialInfoService,
    public _conditionsService: RoutingConditionsService,
    public _clientDocsSvc: ClientDocsService,
    public _employerInfoSvc: EmployerInfoService,
    public _businessAppraisalSvc: BusinessAppraisalService,
    public _sharedPopupSvc: SharedPopupService,
    public translate: TranslateService
  ) {
    this.#destroyRef.onDestroy(() => {
      localStorage.setItem('clientOnbrdEditCheck', JSON.stringify(false));
    });
  }



  ngOnInit(): void {
    this.validateForm();
    this._picklistService.callAllApis();
    this._picklistService.InItGetLocations();
    this._picklistService.InItGetGoCommunity();

    if (localStorage.getItem("readOnlyView_Client")) {
      this._conditionsService.readOnlyView_Client = true;
      let id = this.route.snapshot.paramMap.get("id");
      this._clintOnboardingSvc.InItClientBasicInfo(id);
    }
    else {
      this._conditionsService.readOnlyView_Client = false;
    }
    if (localStorage.getItem("reload")) {
      localStorage.removeItem("reload");
      window.location.reload();
    }
    if (this.route.snapshot.paramMap.get("id")) {
      this._clintOnboardingSvc.edit = true;
      this._sharedSvc.msg = this.translate.instant('toast.recordUpdated');
      this.onEdit();
    }
    else {
      this.basicInfoForm.reset(this.basicInfoForm.value);
      this._sharedSvc.msg = "Record added";
      this.setCNICModel();
    }
    if (localStorage.getItem("FM")) {
      this.FM = true;
      this.basicInfoForm.get("occKey").disable();
      this.basicInfoForm.get("customerTypeKey").disable();
    }
    else {
      this.FM = false
      this.basicInfoForm.get("occKey").enable();
      this.basicInfoForm.get("customerTypeKey").enable();
    }
    this.checkClient()

    this.basicInfoForm.get("dob").valueChanges.subscribe(x => {
      this.calculateDOB(x);
    })
    this.showBtn(false);
    this.showBtn(true);



    let id = this.route.snapshot.paramMap.get("id");
    if (id) {
      this._sharedSvc.showSpinner3();

      this._clintOnboardingSvc.InItClientBasicInfo(id);

      this._clintOnboardingSvc.InItGetAddress(id);

      this._clientDocsSvc.getClientAllDocs(id, 0);

      this._employerInfoSvc.InItGetAllEmployerDetail(id);

      this._finiancialInfoSvc.getEmpSalary(id, 4);

      this._businessAppraisalSvc.InItGetBusinessAppraisal(id, (result: any) => {
        if (result) {
          localStorage.setItem('bizAprsBasicInfo', JSON.stringify(result));
          this._clintOnboardingSvc.InItGetAddress(result.bizAprslSeq, (response: any) => {
            if (response) {
              localStorage.setItem('bizAprsAddress', JSON.stringify(response));
            }
          });
        }
      });

      this._businessAppraisalSvc.InItGetAllBusinessAppraisalAdd(id);

      this._finiancialInfoSvc.InItGetClntAllIncmDtlSeq(id, 3);

      this._finiancialInfoSvc.InItGetClntAllExpnsDtlSeq(id, 1);

      this._finiancialInfoSvc.InItGetClntAllExpnsDtlSeq(id, 2);
      //this.hozHoldExpense = this._finiancialInfoSvc.getAllExpense;

      this._sharedSvc.hideSpinner3();
    }


  }

  showBtn(B1: any) {
    this.basicInfoDiv = B1;
    if (B1) {
      if (localStorage.getItem('save_ClientModel')) {
        this.C_model = localStorage.getItem('save_ClientModel');
        this.C_model = JSON.parse(this.C_model);
        this._clintOnboardingSvc.saveClientModel = this.C_model;
        this.setValueToForm();
      }
      else {
        if (!this.route.snapshot.paramMap.get("id")) {
          this._clintOnboardingSvc.saveClientModel = new SaveClient();
          this.setCNICModel();
        }
      }

    }
    if (!B1) {
      this.addressForm.reset(this.addressForm.value);
      // this._picklistService.InItGetLocations();
      // this._picklistService.InItGetGoCommunity();


      if (localStorage.getItem('save_addressModel')) {
        this.A_model = localStorage.getItem('save_addressModel');
        this.A_model = JSON.parse(this.A_model);
        this._clintOnboardingSvc.addressModel = this.A_model;
        // this.setValueToForm2();
        this._clintOnboardingSvc.locationData = localStorage.getItem('locationData');
        this._clintOnboardingSvc.locationData = JSON.parse(this._clintOnboardingSvc.locationData);
        for (let i = 0; i < this._clintOnboardingSvc.locationData?.length; i++) {
          if (this._clintOnboardingSvc.locationData[i].locationSeq == this._clintOnboardingSvc.addressModel.locSeq) {
            this.onSelectionArea(this._clintOnboardingSvc.locationData[i]);
          }
        }
        this.setValueToForm2();
      }
      if (localStorage.getItem("readOnlyView_Client")) {
        let id = this.route.snapshot.paramMap.get("id");
        this._clintOnboardingSvc.InItGetAddress(id);
        this._clintOnboardingSvc.allAddressData;

      }
      if (!this.route.snapshot.paramMap.get("id")) {
        this._clintOnboardingSvc.addressModel = new Address();
        this.addressForm.reset(this.addressForm.value);
        this._clintOnboardingSvc.addressModel.isPermAddress = true;
        // if (localStorage.getItem('clntSeq')) {
        // this.clntSeq = localStorage.getItem('clntSeq');
        // this.clntSeq = JSON.parse(this.clntSeq);

        // this._clintOnboardingSvc.InItGetAddress(this.clntSeq);
        // }
      }
      if (this.route.snapshot.paramMap.get("id")) {
        this.clntSeq = this.route.snapshot.paramMap.get("id");
        this._clintOnboardingSvc.InItGetAddress(this.clntSeq, (result: Address) => {
          if (result) {
            this._clintOnboardingSvc.addressModel = result;

            this._clintOnboardingSvc.locationData = localStorage.getItem('locationData');
            this._clintOnboardingSvc.locationData = JSON.parse(this._clintOnboardingSvc.locationData);
            for (let i = 0; i < this._clintOnboardingSvc.locationData?.length; i++) {
              if (this._clintOnboardingSvc.locationData[i].locationSeq == this._clintOnboardingSvc.addressModel.locSeq) {
                this.onSelectionArea(this._clintOnboardingSvc.locationData[i]);
              }
            }
            this.setValueToForm2();
          }
        });
      }
    }
    if (this.basicInfoForm.controls['customerTypeKey'].value) {
      let customerType = this.getCustomerTypeFromRefCdSeq(this.basicInfoForm.controls['customerTypeKey'].value);
      sessionStorage.setItem('customerType', JSON.stringify(customerType));
    }
    if (this.basicInfoForm.controls['occKey'].value) {
      let occupationType = this.getOccupationTypeFromRefCdSeq(this.basicInfoForm.controls['occKey'].value);
      sessionStorage.setItem('occupationType', JSON.stringify(occupationType));
    }

  }

  setValueToModel() {
    this._clintOnboardingSvc.saveClientModel.cnicNum = this.cnicNum.value
    this._clintOnboardingSvc.saveClientModel.cnicIssueDt = this.cnicIssueDt.value
    this._clintOnboardingSvc.saveClientModel.cnicExpryDt = this.cnicExpryDt.value
    this._clintOnboardingSvc.saveClientModel.frstNm = this.frstNm.value
    this._clintOnboardingSvc.saveClientModel.lastNm = this.lastNm.value
    this._clintOnboardingSvc.saveClientModel.nickNm = this.nickNm.value
    this._clintOnboardingSvc.saveClientModel.mthrMadnNm = this.mthrMadnNm.value
    this._clintOnboardingSvc.saveClientModel.dob = this.dob.value
    this._sharedSvc.calculatedDOB //= this.calculatedDOB.value
    this._clintOnboardingSvc.saveClientModel.gndrKey = this.gndrKey.value
    this._clintOnboardingSvc.saveClientModel.mrtlStsKey = this.mrtlStsKey.value
    this._clintOnboardingSvc.saveClientModel.eduLvlKey = this.eduLvlKey.value
    this._clintOnboardingSvc.saveClientModel.occKey = this.occKey.value
    // this.disabilityVar = this.disabilityVar_.value
    this._clintOnboardingSvc.saveClientModel.natrOfDisKey = this.natrOfDisKey.value
    this._clintOnboardingSvc.saveClientModel.resTypKey = this.resTypKey.value
    this._clintOnboardingSvc.saveClientModel.householdMembers = this.householdMembers.value
    this._clintOnboardingSvc.saveClientModel.numberOfDependents = this.numberOfDependents.value
    this._clintOnboardingSvc.saveClientModel.numberOfChildren = this.numberOfChildren.value
    this._clintOnboardingSvc.saveClientModel.phNum = this.phNum.value
    this._clintOnboardingSvc.saveClientModel.walletNumber = this.walletNumber.value
    this._clintOnboardingSvc.saveClientModel.customerTypeKey = this.customerTypeKey.value
    this._clintOnboardingSvc.saveClientModel.fthrSpzFrstNm = this.fthrSpzFrstNm.value
    this._clintOnboardingSvc.saveClientModel.fthrSpzLastNm = this.fthrSpzLastNm.value
    // this._clintOnboardingSvc.addressModel.hseNum = this.hseNum.value
    this._clintOnboardingSvc.saveClientModel.mnthsRes = this.mnthsRes.value
    this._clintOnboardingSvc.saveClientModel.selfPdc = this.selfPdc.value
    this._clintOnboardingSvc.saveClientModel.prmAddr = this.prmAddr.value

  }

  setValueToForm() {
    this.cnicNum.setValue(this._clintOnboardingSvc.saveClientModel.cnicNum);
    this.cnicIssueDt.setValue(this._clintOnboardingSvc.saveClientModel.cnicIssueDt);
    this.cnicExpryDt.setValue(this._clintOnboardingSvc.saveClientModel.cnicExpryDt);
    this.frstNm.setValue(this._clintOnboardingSvc.saveClientModel.frstNm);
    this.lastNm.setValue(this._clintOnboardingSvc.saveClientModel.lastNm);
    this.nickNm.setValue(this._clintOnboardingSvc.saveClientModel.nickNm);
    this.mthrMadnNm.setValue(this._clintOnboardingSvc.saveClientModel.mthrMadnNm);
    this.dob.setValue(this._clintOnboardingSvc.saveClientModel.dob);
    this.gndrKey.setValue(this._clintOnboardingSvc.saveClientModel.gndrKey);
    this.mrtlStsKey.setValue(this._clintOnboardingSvc.saveClientModel.mrtlStsKey);
    this.eduLvlKey.setValue(this._clintOnboardingSvc.saveClientModel.eduLvlKey);
    this.occKey.setValue(this._clintOnboardingSvc.saveClientModel.occKey);
    this.natrOfDisKey.setValue(this._clintOnboardingSvc.saveClientModel.natrOfDisKey);
    this.resTypKey.setValue(this._clintOnboardingSvc.saveClientModel.resTypKey);
    this.householdMembers.setValue(this._clintOnboardingSvc.saveClientModel.householdMembers);
    this.numberOfDependents.setValue(this._clintOnboardingSvc.saveClientModel.numberOfDependents);
    this.numberOfChildren.setValue(this._clintOnboardingSvc.saveClientModel.numberOfChildren)
    this.phNum.setValue(this._clintOnboardingSvc.saveClientModel.phNum);
    this.walletNumber.setValue(this._clintOnboardingSvc.saveClientModel.walletNumber);
    this.customerTypeKey.setValue(this._clintOnboardingSvc.saveClientModel.customerTypeKey);
    this.fthrSpzFrstNm.setValue(this._clintOnboardingSvc.saveClientModel.fthrSpzFrstNm);
    this.fthrSpzLastNm.setValue(this._clintOnboardingSvc.saveClientModel.fthrSpzLastNm)
    this.selfPdc.setValue(this._clintOnboardingSvc.saveClientModel.selfPdc);
    this.mnthsRes.setValue(this._clintOnboardingSvc.saveClientModel.mnthsRes);
    this.prmAddr.setValue(this._clintOnboardingSvc.saveClientModel.prmAddr);

    if (this._clintOnboardingSvc.saveClientModel.natrOfDisKey) {
      this.disabilityVar = "yes";
      this.disabilityVar_.setValue('yes')
      this.basicInfoForm.get("natrOfDisKey").enable();
    }
    else {
      this.disabilityVar = "no";
      this.disabilityVar_.setValue('no')

      this._clintOnboardingSvc.saveClientModel.natrOfDisKey = null;
      this.basicInfoForm.get("natrOfDisKey").disable();
    }
    this.calculateDOB(this._clintOnboardingSvc.saveClientModel.dob);

    this.occupationFn(this._clintOnboardingSvc.saveClientModel.occKey);
  }

  checkClient() {

    if (localStorage.getItem('save_ClientModel')) {
      this.saveCnicData = localStorage.getItem('save_ClientModel');
      this.saveCnicData = JSON.parse(this.saveCnicData);
      if (this.saveCnicData.clntSeq > 0 && this.saveCnicData.mnthsRes > 0) {
        this._conditionsService.oldClient();
      } else {
        this._conditionsService.newClient();
      }
    }
    else {
      if (localStorage.getItem('SaveCnicData')) {
        this.saveCnicData = localStorage.getItem('SaveCnicData');
        this.saveCnicData = JSON.parse(this.saveCnicData);
        if (this.saveCnicData.clntSeq > 0 && this.saveCnicData.mnthsRes > 0) {
          this._conditionsService.oldClient();
        } else {
          this._conditionsService.newClient();
        }
      }
      else {
        this._conditionsService.newClient();
      }
    }
  }

  calculateDOB(value: any) {

    this._clintOnboardingSvc.saveClientModel.dob = value;
    this._sharedSvc.calculateDOB(this._clintOnboardingSvc.saveClientModel.dob, (result: Address) => {
      if (result) {
        this.clintAge.setValue(result);
      }
    });
  }

  materialStatusCheck(event: any) {
    this._clintOnboardingSvc.saveClientModel.mrtlStsKey = event.target.value;
    if (this._clintOnboardingSvc.saveClientModel.mrtlStsKey == 21) {
      this._clintOnboardingSvc.saveClientModel.numberOfChildren = 0;
      this.basicInfoForm.value.numberOfChildren = 0;
      this.basicInfoForm.get("numberOfChildren").disable();
      this.numberOfChildren.setValue(0);
    } else {
      this.basicInfoForm.get("numberOfChildren").enable();
    }
  }

  occupationFn(event?: any, name?: any) {
    if (name) {
      if (event?.target?.value) {
        this._clintOnboardingSvc.saveClientModel.customerTypeKey = event.target.value;
      }
      else {
        this._clintOnboardingSvc.saveClientModel.customerTypeKey = event;
      }
    }
    else {
      if (event?.target?.value) {
        this._clintOnboardingSvc.saveClientModel.occKey = event.target.value;
      }
      else {
        this._clintOnboardingSvc.saveClientModel.occKey = event;
      }
    }


    if ((this._clintOnboardingSvc.saveClientModel.occKey == 39 || this._clintOnboardingSvc.saveClientModel.occKey == 42) &&
      this._clintOnboardingSvc.saveClientModel.customerTypeKey == 120) {
      this._sharedSvc.personalInfo_FS = true;
      this._sharedSvc.clientDoc_FS = true;
      this._sharedSvc.otherOutstanding_FS = true;
      this._sharedSvc.businessAppraisal_FS = true;
      this._sharedSvc.EmployerInfo_FS = false;
      sessionStorage.setItem('EmployerInfo_FS', 'false')
      this._sharedSvc.FinancialInfo_FS = true;
      this._sharedSvc.summary_FS = true;
    } else {
      this._sharedSvc.personalInfo_FS = true;
      this._sharedSvc.clientDoc_FS = true;
      this._sharedSvc.otherOutstanding_FS = true;
      this._sharedSvc.businessAppraisal_FS = false;
      this._sharedSvc.EmployerInfo_FS = true;
      sessionStorage.setItem('EmployerInfo_FS', 'true')
      this._sharedSvc.FinancialInfo_FS = true;
      this._sharedSvc.summary_FS = true;
    }
    if (this._clintOnboardingSvc.saveClientModel.customerTypeKey != 120) {
      this._sharedSvc.personalInfo_FS = true;
      this._sharedSvc.clientDoc_FS = false;
      this._sharedSvc.otherOutstanding_FS = false;
      this._sharedSvc.businessAppraisal_FS = false;
      this._sharedSvc.EmployerInfo_FS = false;
      this._sharedSvc.FinancialInfo_FS = false;
      this._sharedSvc.summary_FS = false;
    }
    this.checkClient();
  }

  disabilityCheck(event: any) {
    this.disabilityVar = event.target.value;
    this.disabilityVar_.setValue(event.target.value);
    if (event.target.value == "yes") {
      this.basicInfoForm.get("natrOfDisKey").enable();
      this.disNatrOfDisKey = false;
      this._clintOnboardingSvc.saveClientModel.natrOfDisKey = this._picklistService.NATURE_OF_DISABILITY_Data[0]?.refCdSeq;
    } else {
      this._clintOnboardingSvc.saveClientModel.natrOfDisKey = null;
      this.basicInfoForm.get("natrOfDisKey").disable();
      this.disNatrOfDisKey = true;
    }
  }

  maxNOD(event: any, name?: any) {

    if (name == 'householdMembers') {
      this._clintOnboardingSvc.saveClientModel.householdMembers = event.target.value;
      this._clintOnboardingSvc.saveClientModel.householdMembers = JSON.parse(this._clintOnboardingSvc.saveClientModel.householdMembers);
      if (this._clintOnboardingSvc.saveClientModel.householdMembers <= 0) {
        this._sharedSvc.error(this.translate.instant('common.field1DigitRequire'));
        this.householdMembers.setValue("1");
        this._clintOnboardingSvc.saveClientModel.householdMembers = 1;
      }
    }
    if (name == 'numberOfDependents') {
      this._clintOnboardingSvc.saveClientModel.numberOfDependents = event.target.value;
      this._clintOnboardingSvc.saveClientModel.numberOfDependents = JSON.parse(this._clintOnboardingSvc.saveClientModel.numberOfDependents);
    }
    if (this._clintOnboardingSvc.saveClientModel.numberOfDependents > this._clintOnboardingSvc.saveClientModel.householdMembers) {
      this._sharedSvc.error(this.translate.instant('toast.fieldGreaterHouseMembers'));
      this.numberOfDependentsInvalid = true;
    }
    else {
      this.numberOfDependentsInvalid = false;
    }
    // this.maxNOC();
  }

  maxNOC(event: any) {

    this._clintOnboardingSvc.saveClientModel.numberOfChildren = event.target.value
    // this._clintOnboardingSvc.saveClientModel.householdMembers = JSON.parse(this._clintOnboardingSvc.saveClientModel.householdMembers);
    if (this._clintOnboardingSvc.saveClientModel.numberOfChildren > this._clintOnboardingSvc.saveClientModel.householdMembers) {
      this._sharedSvc.error(this.translate.instant('toast.fieldGreaterHouseMembers'));
      this.numberOfChildrenInvalid = true;
    }
    else {
      this.numberOfChildrenInvalid = false;
    }
  }

  checkNumberField(event: any) {
    this._clintOnboardingSvc.saveClientModel.phNum = event.target.value
    if (this._clintOnboardingSvc.saveClientModel.phNum?.length == 12) {
      this._clintOnboardingSvc.saveClientModel.phNum = this._clintOnboardingSvc.saveClientModel.phNum.replace(/\D/g, "");
    }
    if (this._clintOnboardingSvc.saveClientModel.phNum?.length < 11) {
      this.phoneField = true;
      return;
    }
    else {
      this.phoneField = false;
    }
  }

  numberOnly(event: any): boolean {
    let ph = this.phNum.value;
    if (ph && ph.length == 1 && ph.substring(0) != '0')
      this.phNum.setValue("0");
    if (ph && ph.length == 2 && ph.substring(0, 1) != '03')
      this.phNum.setValue("03");
    return true;
  }

  checkWalletField(event: any) {
    if (this._clintOnboardingSvc.saveClientModel.walletNumber?.length == 12) {
      this._clintOnboardingSvc.saveClientModel.walletNumber = this._clintOnboardingSvc.saveClientModel.walletNumber.replace(/\D/g, "");
    }
    if (this._clintOnboardingSvc.saveClientModel.walletNumber?.length < 11) {
      this.walletField = true;
      return;
    }
    else {
      this.walletField = false;
    }
  }

  numberOnlyMV(event: any): boolean {
    let mv = this.walletNumber.value;
    if (mv && mv.length == 1 && mv.substring(0) != '0')
      this.walletNumber.setValue("0");
    if (mv && mv.length == 2 && mv.substring(0, 1) != '03')
      this.walletNumber.setValue("03");
    return true;
  }

  setCNICModel() {
    if (localStorage.getItem("saveCNICModel")) {
      this.saveCNICModel = localStorage.getItem("saveCNICModel");
      this.saveCNICModel = JSON.parse(this.saveCNICModel);
      this._clintOnboardingSvc.saveClientModel.cnicNum = this.saveCNICModel?.cnicNum;
      var CNIC3 = this.saveCNICModel?.cnicNum?.substring(12, 13);
      let tempDepositDate = new Date(this.saveCNICModel?.issueDate);
      this._clintOnboardingSvc.saveClientModel.cnicIssueDt = { year: tempDepositDate.getFullYear(), month: tempDepositDate.getMonth() + 1, day: tempDepositDate.getDate() };
      let tempDepositDate2 = new Date(this.saveCNICModel?.expiryDate);
      this._clintOnboardingSvc.saveClientModel.cnicExpryDt = { year: tempDepositDate2.getFullYear(), month: tempDepositDate2.getMonth() + 1, day: tempDepositDate2.getDate() };
      this.cnicNum.setValue(this._clintOnboardingSvc.saveClientModel.cnicNum);
      this.cnicIssueDt.setValue(this._clintOnboardingSvc.saveClientModel.cnicIssueDt);
      this.cnicExpryDt.setValue(this._clintOnboardingSvc.saveClientModel.cnicExpryDt);
      if (CNIC3) {
        if (CNIC3 % 2 == 0) {
          this._clintOnboardingSvc.saveClientModel.gndrKey = 19;
        } else {
          this._clintOnboardingSvc.saveClientModel.gndrKey = 18;
        }
        this.gndrKey.setValue(this._clintOnboardingSvc.saveClientModel.gndrKey);

      }

    }
  }

  openModel(content: any) {
    localStorage.removeItem("selectedOrderData");
    this.modalService.open(content, {
      windowClass: "popupWidth1k",
      centered: true,
    });
  }

  onEdit() {
    if (this.route.snapshot.paramMap.get("id") || this._clintOnboardingSvc.edit == true) {
      if (localStorage.getItem("SaveCnicData")) {
        this.SaveCnicData = localStorage.getItem("SaveCnicData");
        this.SaveCnicData = JSON.parse(this.SaveCnicData);
        this._clintOnboardingSvc.saveClientModel = Object.assign({}, this.SaveCnicData);
        this.convertDates();
        this.setValueToForm();
        this.basicInfoForm.get("occKey").valueChanges.subscribe(x => {
          this.occupationFn(x);
        })

        // if (this._clintOnboardingSvc.saveClientModel.natrOfDisKey) {
        //   this.disabilityVar = "yes";
        //   this.disabilityVar_.setValue('yes')
        //   this.basicInfoForm.get("natrOfDisKey").enable();
        // }
        // else {
        //   this.disabilityVar = "no";
        //   this.disabilityVar_.setValue('no')

        //   this._clintOnboardingSvc.saveClientModel.natrOfDisKey = null;
        //   this.basicInfoForm.get("natrOfDisKey").disable();
        // }
      }
    }
    else {
      this.basicInfoForm.get("natrOfDisKey").disable();
    }
    let clntSeq = this.route.snapshot.paramMap.get("id")
    if (clntSeq) {
      this._finiancialInfoSvc.InItGetClntAllIncmDtlSeq(clntSeq, 1);
      this._finiancialInfoSvc.InItGetClntAllIncmDtlSeq2(clntSeq, 2);
      this._finiancialInfoSvc.InItGetClntAllIncmDtlSeq3(clntSeq, 3);
      this._finiancialInfoSvc.InItGetClntAllIncmDtlSeq4(clntSeq, 4);
      this._finiancialInfoSvc.InItGetClntAllExpnsDtlSeq(clntSeq, 1);
    }
  }

  onSubmitBasicInfo(): void {
    this.setValueToModel();
    this.checkBasicFields();
    if (this.basicInfoForm.invalid || this.numberOfDependentsInvalid || this.numberOfChildrenInvalid || this.phoneField || this.walletField) {
      for (const control of Object.keys(this.basicInfoForm.controls)) {
        this.basicInfoForm.controls[control].markAsTouched();
      }
      return;
    }
    this.FM = true;
    this.basicInfoForm.get("occKey").disable();
    this.basicInfoForm.get("customerTypeKey").disable();
    if (!this.route.snapshot.paramMap.get("id")) {
      // this._clintOnboardingSvc.saveClientModel = Object.assign({}, this.basicInfo);
      this._clintOnboardingSvc.saveClientModel.clntSeq = 0;
      this._clintOnboardingSvc.saveClientModel.tenetKey = 0;
      this._clintOnboardingSvc.saveClientModel.clntId = "";
      this._clintOnboardingSvc.saveClientModel.clntStsKey = 699;
      this._clintOnboardingSvc.saveClientModel.portKey = 0;
      this._clintOnboardingSvc.saveClientModel.delFlg = false;
      // localStorage.removeItem("saveCNICModel");
    }
    this._clintOnboardingSvc.saveClientModel.walletNumber = this._clintOnboardingSvc.saveClientModel.walletNumber.replace(/\D/g, "");
    this._clintOnboardingSvc.saveClientModel.phNum = this._clintOnboardingSvc.saveClientModel.phNum.replace(/\D/g, "");
    if (this._clintOnboardingSvc.saveClientModel.cnicIssueDt.year) {
      this._clintOnboardingSvc.saveClientModel.cnicIssueDt = this._clintOnboardingSvc.saveClientModel.cnicIssueDt.year + '-' + this._clintOnboardingSvc.saveClientModel.cnicIssueDt.month + '-' + this._clintOnboardingSvc.saveClientModel.cnicIssueDt.day
      this._clintOnboardingSvc.saveClientModel.cnicIssueDt = new Date(this._clintOnboardingSvc.saveClientModel.cnicIssueDt);
    }
    if (this._clintOnboardingSvc.saveClientModel.cnicExpryDt.year) {
      this._clintOnboardingSvc.saveClientModel.cnicExpryDt = this._clintOnboardingSvc.saveClientModel.cnicExpryDt.year + '-' + this._clintOnboardingSvc.saveClientModel.cnicExpryDt.month + '-' + this._clintOnboardingSvc.saveClientModel.cnicExpryDt.day
      this._clintOnboardingSvc.saveClientModel.cnicExpryDt = new Date(this._clintOnboardingSvc.saveClientModel.cnicExpryDt);
    }
    if (this._clintOnboardingSvc.saveClientModel.dob.year) {
      this._clintOnboardingSvc.saveClientModel.dob = this._clintOnboardingSvc.saveClientModel.dob.year + '-' + this._clintOnboardingSvc.saveClientModel.dob.month + '-' + this._clintOnboardingSvc.saveClientModel.dob.day
      this._clintOnboardingSvc.saveClientModel.dob = new Date(this._clintOnboardingSvc.saveClientModel.dob);
    }
    this._sharedSvc.showSpinner();
    this._clintOnboardingSvc.saveClient().subscribe({
      next: (response: any) => {
        if (response) {
          this._sharedSvc.hideSpinner();
          this.checkClient();
          this._sharedSvc.success(this._sharedSvc.msg);
          this.clntSeq = response.clntSeq;
          this._clintOnboardingSvc.saveClientModel.clntSeq = this.clntSeq;
          this._clintOnboardingSvc.saveClientModel.clntId = response.ClntID;
          localStorage.setItem('clntSeq', JSON.stringify(response.clntSeq));
          localStorage.removeItem("saveCNICModel");
          localStorage.setItem('FM', JSON.stringify("FM"));
          this.convertDates();
          localStorage.setItem('save_ClientModel', JSON.stringify(this._clintOnboardingSvc.saveClientModel));
          // localStorage.setItem('reload', "true");
          // this._clintOnboardingSvc.InItClientBasicInfo(this.clntSeq);
          // this.onEdit();
          // this.router.navigate(["/onboarding-personal-info/" + this.clntSeq]);
          //this.showBtn(false);
        }
      },
      error: e => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
        this.convertDates();
      },
      complete: () => {
        // this.router.navigate(['/onboarding-personal-info/' + this.clntSeq]);
        // window.location.reload();
      },
    });

  }

  convertDates() {
    let tempDepositDate = new Date(this._clintOnboardingSvc.saveClientModel.cnicIssueDt);
    this._clintOnboardingSvc.saveClientModel.cnicIssueDt = { year: tempDepositDate.getFullYear(), month: tempDepositDate.getMonth() + 1, day: tempDepositDate.getDate() };
    let tempDepositDate2 = new Date(this._clintOnboardingSvc.saveClientModel.cnicExpryDt);
    this._clintOnboardingSvc.saveClientModel.cnicExpryDt = { year: tempDepositDate2.getFullYear(), month: tempDepositDate2.getMonth() + 1, day: tempDepositDate2.getDate() };
    let tempDepositDate3 = new Date(this._clintOnboardingSvc.saveClientModel.dob);
    this._clintOnboardingSvc.saveClientModel.dob = { year: tempDepositDate3.getFullYear(), month: tempDepositDate3.getMonth() + 1, day: tempDepositDate3.getDate() };
  }

  // Address
  onSelectionArea(area: any) {
    // this._clintOnboardingSvc.onSelectionArea(area);
    this._clintOnboardingSvc.cityName = area?.cityName;
    this._clintOnboardingSvc.districtName = area?.districtName;
    this._clintOnboardingSvc.stateName = area?.stateName;
    this._clintOnboardingSvc.thslName = area?.thslName;
    this._clintOnboardingSvc.ucName = area?.ucName;
    this._clintOnboardingSvc.locSeq = area.locationSeq;
    this.setAreaValueToForm2();
    if (this._clintOnboardingSvc.districtName == undefined && this._clintOnboardingSvc.thslName == undefined && this._clintOnboardingSvc.cityName == undefined && this._clintOnboardingSvc.ucName == undefined && this._clintOnboardingSvc.stateName == undefined) {
      this._clintOnboardingSvc.areaFieldsInvalid = true;
      return
    }
    else {
      this._clintOnboardingSvc.areaFieldsInvalid = false;
    }
    this.modalService.dismissAll();
  }

  setAreaValueToForm2() {
    this.districtName.setValue(this._clintOnboardingSvc.districtName);
    this.thslName.setValue(this._clintOnboardingSvc.thslName);
    this.cityName.setValue(this._clintOnboardingSvc.cityName);
    this.ucName.setValue(this._clintOnboardingSvc.ucName);
    this.stateName.setValue(this._clintOnboardingSvc.stateName);
  }

  isPermanentAdd(value: any) {
    this._clintOnboardingSvc.isPermAddress = value;
  }

  calNosOfResidence() {
    this._clintOnboardingSvc.nosOfResidence = this.nosOfResidence.value;
    if (this.resOfResidence.value == null) {
      this._clintOnboardingSvc.resOfResidence = 0;
      this.resOfResidence.setValue(this._clintOnboardingSvc.resOfResidence);
    }
    const yearsToAdd = this._clintOnboardingSvc.resOfResidence > 0 ? Math.floor(this._clintOnboardingSvc.resOfResidence / 12) : 0;
    this._clintOnboardingSvc.addressModel.mnthsOfResidence += yearsToAdd;
    this._clintOnboardingSvc.addressModel.yearsOfResidence = this._clintOnboardingSvc.nosOfResidence;
    const remainingMonths = this._clintOnboardingSvc.resOfResidence > 0 ? this._clintOnboardingSvc.resOfResidence % 12 : 0;
    this._clintOnboardingSvc.addressModel.mnthsOfResidence = this._clintOnboardingSvc.nosOfResidence * 12 + remainingMonths;
    this._clintOnboardingSvc.saveClientModel.mnthsRes = this._clintOnboardingSvc.addressModel.mnthsOfResidence;
    this.mnthsOfResidence.setValue(this._clintOnboardingSvc.addressModel.mnthsOfResidence);
  }

  calMnthsOfResidence() {
    this._clintOnboardingSvc.resOfResidence = this.resOfResidence.value;
    if (this.nosOfResidence.value == null) {
      this._clintOnboardingSvc.nosOfResidence = 0;
      this.nosOfResidence.setValue(this._clintOnboardingSvc.nosOfResidence);
    }
    if (String(this._clintOnboardingSvc.resOfResidence).length < 3 || this._clintOnboardingSvc.resOfResidence == undefined) {
      if (this._clintOnboardingSvc.resOfResidence >= 12) {
        const additionalYears = Math.floor(this._clintOnboardingSvc.resOfResidence / 12);
        const remainingMonths = this._clintOnboardingSvc.resOfResidence % 12;

        this._clintOnboardingSvc.addressModel.yearsOfResidence =
          (this._clintOnboardingSvc.addressModel.yearsOfResidence || 0) + additionalYears;

        this._clintOnboardingSvc.addressModel.mnthsOfResidence =
          (this._clintOnboardingSvc.addressModel.yearsOfResidence || 0) * 12 + remainingMonths;

        this._clintOnboardingSvc.nosOfResidence = this._clintOnboardingSvc.nosOfResidence + additionalYears;
        this._clintOnboardingSvc.resOfResidence = remainingMonths;
        this._clintOnboardingSvc.saveClientModel.mnthsRes = this._clintOnboardingSvc.addressModel.mnthsOfResidence;

        this.nosOfResidence.setValue(this._clintOnboardingSvc.nosOfResidence);
        this.resOfResidence.setValue(this._clintOnboardingSvc.resOfResidence);
        this.mnthsOfResidence.setValue(this._clintOnboardingSvc.addressModel.mnthsOfResidence);


      } else {
        // Calculate mnthInBiz based on the updated NoOfYears and noOfMonth
        this._clintOnboardingSvc.addressModel.mnthsOfResidence =
          (this._clintOnboardingSvc.addressModel.yearsOfResidence || 0) * 12 + this._clintOnboardingSvc.resOfResidence;
        this._clintOnboardingSvc.saveClientModel.mnthsRes = this._clintOnboardingSvc.addressModel.mnthsOfResidence;
        this.mnthsOfResidence.setValue(this._clintOnboardingSvc.addressModel.mnthsOfResidence);
      }
    }
    ;
  }

  limitInputToTwoDigits(event: any) {
    const input = event.target;
    if (input.value.length > 2) {
      input.value = input.value.slice(0, 2);
      this._clintOnboardingSvc.resOfResidence = input.value;
      this.resOfResidence.setValue(input.value);

    }
    // if (input.value > 11) {
    // }
  }

  setValueToModel2() {
    this._clintOnboardingSvc.addressModel.hseNum = this.hseNum.value;
    this._clintOnboardingSvc.addressModel.strt = this.strt.value;
    this._clintOnboardingSvc.addressModel.cmntySeq = this.cmntySeq.value;
    this._clintOnboardingSvc.addressModel.vlg = this.vlg.value;
    this._clintOnboardingSvc.addressModel.othDtl = this.othDtl.value;
    this._clintOnboardingSvc.districtName = this.districtName.value;
    this._clintOnboardingSvc.thslName = this.thslName.value;
    this._clintOnboardingSvc.cityName = this.cityName.value;
    this._clintOnboardingSvc.ucName = this.ucName.value;
    this._clintOnboardingSvc.stateName = this.stateName.value;
    this._clintOnboardingSvc.nosOfResidence = this.nosOfResidence.value;
    this._clintOnboardingSvc.addressModel.mnthsOfResidence = this.mnthsOfResidence.value;
    this._clintOnboardingSvc.saveClientModel.mnthsRes = this._clintOnboardingSvc.addressModel.mnthsOfResidence;
    this._clintOnboardingSvc.saveClientModel.prmAddr = this.prmAddr.value;
  }

  setValueToForm2() {
    if (this._clintOnboardingSvc.addressModel.mnthsOfResidence) { }
    else {
      this._clintOnboardingSvc.addressModel.mnthsOfResidence = this._clintOnboardingSvc.saveClientModel.mnthsRes;
    }
    this.hseNum.setValue(this._clintOnboardingSvc.addressModel.hseNum);
    this.strt.setValue(this._clintOnboardingSvc.addressModel.strt);
    this.cmntySeq.setValue(this._clintOnboardingSvc.addressModel.cmntySeq);
    this.vlg.setValue(this._clintOnboardingSvc.addressModel.vlg);
    this.othDtl.setValue(this._clintOnboardingSvc.addressModel.othDtl);
    this.districtName.setValue(this._clintOnboardingSvc.districtName);
    this.thslName.setValue(this._clintOnboardingSvc.thslName);
    this.cityName.setValue(this._clintOnboardingSvc.cityName);
    this.ucName.setValue(this._clintOnboardingSvc.ucName);
    this.stateName.setValue(this._clintOnboardingSvc.stateName);
    this.nosOfResidence.setValue(this._clintOnboardingSvc.addressModel.yearsOfResidence);
    this.resOfResidence.setValue(this._clintOnboardingSvc.addressModel.mnthsOfResidence - (this._clintOnboardingSvc.addressModel.yearsOfResidence * 12));
    this.mnthsOfResidence.setValue(this._clintOnboardingSvc.addressModel.mnthsOfResidence);
    this.prmAddr.setValue(this._clintOnboardingSvc.saveClientModel.prmAddr);

    if (this._clintOnboardingSvc.saveClientModel.prmAddr) {
      this._clintOnboardingSvc.isPermAddress = false;
    }
    this._clintOnboardingSvc.nosOfResidence = Math.floor(this._clintOnboardingSvc.addressModel.mnthsOfResidence / 12);
    this._clintOnboardingSvc.resOfResidence = this._clintOnboardingSvc.addressModel.mnthsOfResidence % 12;
    this.nosOfResidence.setValue(this._clintOnboardingSvc.nosOfResidence);
    this.resOfResidence.setValue(this._clintOnboardingSvc.resOfResidence);

  }

  onSubmitAddress(): void {
    this.setValueToModel2();
    this.checkAddressFields();
    if (this.addressForm.invalid) {
      for (const control of Object.keys(this.addressForm.controls)) {
        this.addressForm.controls[control].markAsTouched();
      }
      return;
    }
    this._clintOnboardingSvc.saveClientModel.mnthsRes = this._clintOnboardingSvc.addressModel.mnthsOfResidence;
    this.mnthsRes.setValue(this._clintOnboardingSvc.saveClientModel.mnthsRes);
    this.onSubmitBasicInfo();
    if (localStorage.getItem('clntSeq')) {
      this.clntSeq = localStorage.getItem('clntSeq');
      this.clntSeq = JSON.parse(this.clntSeq);
    }
    this._clintOnboardingSvc.addressModel.isPermAddress = true;
    if (this.route.snapshot.paramMap.get("id")) {
      this.clntSeq = this.route.snapshot.paramMap.get("id");
    }
    this._clintOnboardingSvc.addressModel.clntSeq = this.clntSeq;
    this._clintOnboardingSvc.addressModel.addrSeq = null;
    this._clintOnboardingSvc.addressModel.addrRelSeq = null;
    this._clintOnboardingSvc.addressModel.delFlg = false;
    this._clintOnboardingSvc.addressModel.locSeq = this._clintOnboardingSvc.locSeq;
    this._clintOnboardingSvc.addressModel.latitude = 0;
    this._clintOnboardingSvc.addressModel.longitude = 0;
    this._clintOnboardingSvc.addressModel.entySeq = this.clntSeq;
    this._clintOnboardingSvc.addressModel.entyType = 1;
    this._sharedSvc.showSpinner();
    this._clintOnboardingSvc.saveAddress().subscribe({
      next: response => {
        if (response) {
          this._sharedSvc.hideSpinner();
          this.checkClient();
          this._sharedSvc.success(this.translate.instant('toast.addressAdded'));
          localStorage.setItem('save_addressModel', JSON.stringify(this._clintOnboardingSvc.addressModel));
          // this._clintOnboardingSvc.InItGetAddress(this.clntSeq);
        }
      },
      error: e => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    });


  }

  validateForm() {
    this.basicInfoForm = new FormGroup({
      clntSeqM: new FormControl(null, []),
      tenetKey: new FormControl(null, []),
      clntId: new FormControl(null, []),
      cnicNum: new FormControl(null, []),
      cnicExpryDt: new FormControl(null, []),
      cnicIssueDt: new FormControl(null, []),
      frstNm: new FormControl(null, [Validators.required, Validators.minLength(3), Validators.maxLength(100)]),
      lastNm: new FormControl(null, [Validators.required, Validators.minLength(3), Validators.maxLength(100)]),
      nickNm: new FormControl("", [Validators.minLength(3), Validators.maxLength(100)]),
      mthrMadnNm: new FormControl(null, [Validators.required, Validators.minLength(3), Validators.maxLength(100)]),
      phNum: new FormControl(null, [Validators.required]),
      dob: new FormControl(null, [Validators.required]),
      gndrKey: new FormControl(null, [Validators.required]),
      mrtlStsKey: new FormControl(null, [Validators.required]),
      eduLvlKey: new FormControl(null, [Validators.required]),
      natrOfDisKey: new FormControl(0, []),
      occKey: new FormControl(null, [Validators.required]),
      clntStsKey: new FormControl(null, []),
      resTypKey: new FormControl(null, [Validators.required]),
      mnthsRes: new FormControl(0, []),
      fthrSpzFrstNm: new FormControl(null, [Validators.required, Validators.minLength(3), Validators.maxLength(100)]),
      fthrSpzLastNm: new FormControl(null, [Validators.required, Validators.minLength(3), Validators.maxLength(100)]),
      portKey: new FormControl(null, []),
      householdMembers: new FormControl(null, [Validators.required, Validators.minLength(1), Validators.maxLength(2)]),
      numberOfDependents: new FormControl(null, [Validators.required, Validators.minLength(1), Validators.maxLength(2)]),
      numberOfChildren: new FormControl(null, [Validators.required, Validators.minLength(1), Validators.maxLength(2)]),
      selfPdc: new FormControl(0, []),
      walletNumber: new FormControl(null, [Validators.required]),
      customerTypeKey: new FormControl(null, [Validators.required]),
      prmAddr: new FormControl("", []),
      delFlg: new FormControl(null, []),

      disabilityVar_: new FormControl('no', []),
      clintAge: new FormControl(null, []),

    });
    this.addressForm = new FormGroup({
      hseNum: new FormControl(null, [Validators.required, Validators.minLength(1), Validators.maxLength(40)]),
      strt: new FormControl(null, [Validators.required, Validators.minLength(1), Validators.maxLength(40)]),
      cmntySeq: new FormControl(null, [Validators.required]),
      vlg: new FormControl(null, [Validators.required, Validators.minLength(3), Validators.maxLength(100)]),
      othDtl: new FormControl(null, [Validators.required, Validators.minLength(3), Validators.maxLength(500)]),
      districtName: new FormControl(null, [Validators.required]),
      thslName: new FormControl(null, [Validators.required]),
      cityName: new FormControl(null, [Validators.required]),
      ucName: new FormControl(null, [Validators.required]),
      stateName: new FormControl(null, [Validators.required]),
      nosOfResidence: new FormControl(null, []),
      resOfResidence: new FormControl(null, []),
      mnthsOfResidence: new FormControl(null, [Validators.required, Validators.min(1)]),
      prmAddr: new FormControl("", []),
    });
  }

  // basicInfoForm: FormGroup;
  get clntSeqM() { return this.basicInfoForm.get("clntSeqM") }
  get tenetKey() { return this.basicInfoForm.get("tenetKey") }
  get clntId() { return this.basicInfoForm.get("clntId") }
  get cnicNum() { return this.basicInfoForm.get("cnicNum") }
  get cnicExpryDt() { return this.basicInfoForm.get("cnicExpryDt") }
  get cnicIssueDt() { return this.basicInfoForm.get("cnicIssueDt") }
  get frstNm() { return this.basicInfoForm.get("frstNm") }
  get lastNm() { return this.basicInfoForm.get("lastNm") }
  get nickNm() { return this.basicInfoForm.get("nickNm") }
  get mthrMadnNm() { return this.basicInfoForm.get("mthrMadnNm") }
  get phNum() { return this.basicInfoForm.get("phNum") }
  get dob() { return this.basicInfoForm.get("dob") }
  get gndrKey() { return this.basicInfoForm.get("gndrKey") }
  get mrtlStsKey() { return this.basicInfoForm.get("mrtlStsKey") }
  get eduLvlKey() { return this.basicInfoForm.get("eduLvlKey") }
  get natrOfDisKey() { return this.basicInfoForm.get("natrOfDisKey") }
  get occKey() { return this.basicInfoForm.get("occKey") }
  get clntStsKey() { return this.basicInfoForm.get("clntStsKey") }
  get resTypKey() { return this.basicInfoForm.get("resTypKey") }
  get mnthsRes() { return this.basicInfoForm.get("mnthsRes") }
  get fthrSpzFrstNm() { return this.basicInfoForm.get("fthrSpzFrstNm") }
  get fthrSpzLastNm() { return this.basicInfoForm.get("fthrSpzLastNm") }
  get portKey() { return this.basicInfoForm.get("portKey") }
  get householdMembers() { return this.basicInfoForm.get("householdMembers") }
  get numberOfDependents() { return this.basicInfoForm.get("numberOfDependents") }
  get numberOfChildren() { return this.basicInfoForm.get("numberOfChildren") }
  get selfPdc() { return this.basicInfoForm.get("selfPdc") }
  get walletNumber() { return this.basicInfoForm.get("walletNumber") }
  get customerTypeKey() { return this.basicInfoForm.get("customerTypeKey") }
  // get prmAddr() { return this.basicInfoForm.get("prmAddr") }
  get delFlg() { return this.basicInfoForm.get("delFlg") }

  get disabilityVar_() { return this.basicInfoForm.get("disabilityVar_") }
  get clintAge() { return this.basicInfoForm.get("clintAge") }

  //  addressForm: FormGroup;
  get hseNum() { return this.addressForm.get("hseNum") }
  get strt() { return this.addressForm.get("strt") }
  get cmntySeq() { return this.addressForm.get("cmntySeq") }
  get vlg() { return this.addressForm.get("vlg") }
  get othDtl() { return this.addressForm.get("othDtl") }
  get districtName() { return this.addressForm.get("districtName") }
  get thslName() { return this.addressForm.get("thslName") }
  get cityName() { return this.addressForm.get("cityName") }
  get ucName() { return this.addressForm.get("ucName") }
  get stateName() { return this.addressForm.get("stateName") }
  get nosOfResidence() { return this.addressForm.get("nosOfResidence") }
  get resOfResidence() { return this.addressForm.get("resOfResidence") }
  get mnthsOfResidence() { return this.addressForm.get("mnthsOfResidence") }
  get prmAddr() { return this.addressForm.get("prmAddr") }

  // get isPermAddress() { return this.addressForm.get("isPermAddress") }

  onTabClick(text?: any) {
    let customerType = this.getCustomerTypeFromRefCdSeq(this.basicInfoForm.controls['customerTypeKey'].value);
    sessionStorage.setItem('customerType', JSON.stringify(customerType));

    let occupationType = this.getOccupationTypeFromRefCdSeq(this.basicInfoForm.controls['occKey'].value);
    sessionStorage.setItem('occupationType', JSON.stringify(occupationType));
  }

  getCustomerTypeFromRefCdSeq(refcod: any) {
    for (let i = 0; i < this._picklistService.CUSTOMER_TYPE_Data?.length; i++) {
      if (Number(this._picklistService.CUSTOMER_TYPE_Data[i].refCdSeq) === Number(refcod)) {
        return this._picklistService.CUSTOMER_TYPE_Data[i].refCdDscr;
      }

    }
  }
  getOccupationTypeFromRefCdSeq(refcod: any) {
    for (let i = 0; i < this._picklistService.OCCUPATION_Data?.length; i++) {
      if (Number(this._picklistService.OCCUPATION_Data[i].refCdSeq) === Number(refcod)) {
        return this._picklistService.OCCUPATION_Data[i].refCdDscr;
      }

    }
  }

  checkBasicFields() {
    this.invalidBasicInfoControls = Object.keys(this.basicInfoForm.controls).filter(controlName =>
      this.basicInfoForm.get(controlName).invalid
    );
    sessionStorage.setItem('basicInfoControl', JSON.stringify(this.invalidBasicInfoControls))
    sessionStorage.setItem('basicInfoValue', JSON.stringify(this.basicInfoForm.value))

    localStorage.setItem('basicInfoControl', JSON.stringify(this.invalidBasicInfoControls))
    localStorage.setItem('basicInfoValue', JSON.stringify(this.basicInfoForm.value))
  }

  checkAddressFields() {
    this.invalidAddressControls = Object.keys(this.addressForm.controls).filter(controlName =>
      this.addressForm.get(controlName).invalid
    );
    sessionStorage.setItem('addressControl', JSON.stringify(this.invalidAddressControls))
    sessionStorage.setItem('addressValue', JSON.stringify(this.addressForm.value))

    localStorage.setItem('addressControl', JSON.stringify(this.invalidAddressControls))
    localStorage.setItem('addressValue', JSON.stringify(this.addressForm.value))
  }

}

