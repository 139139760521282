import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms';
import { SharedService } from 'src/app/components/shared/service/shared.service';
import { RecoveryService } from '../shared/service/recovery.service';
import { PicklistService } from '../../shared/pickList/picklist.service';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from "@angular/common";
@Component({
  selector: 'app-recovery-listing',
  templateUrl: './recovery-listing.component.html',
  styleUrls: ['./recovery-listing.component.css']
})
export class RecoveryListingComponent implements OnInit {

  searchPaymentForm: FormGroup;
  savePaymentTxnForm: FormGroup;
  _recoverydata_0: any;
  currentDate: any = new Date().toISOString().split("T")[0];
  selectedDate: any;
  jwt:any;
  rolloverAllowed:boolean=false;
  totalPaymentAmount:number;

  constructor(
    private modalService: NgbModal,
    public router: Router,
    private formBuilder: FormBuilder,
    public _sharedSvc: SharedService,
    public _recoveryService: RecoveryService,
    public _picklistService: PicklistService,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.ValidationTXN();
    this.searchPaymentForm = this.ValidationSearchPayment();
    this._picklistService.InItPaymentTypeList();
    this._recoveryService._recoverydata = [];
    this.getRollOverPermission();
  }
  getRollOverPermission(){
    const allowedRoutes= JSON.parse(sessionStorage.getItem("routeInfo"));
    let index=allowedRoutes.findIndex(item=> item.sbModUrl === 'roll-over');
    this.rolloverAllowed=index != -1 ? true : false;
  }
  ValidationSearchPayment() {
    return new FormGroup({
      clntId: new FormControl(null, []),
      // cnic: new FormControl(3465451615646, []),
      cnic: new FormControl("", []),
      phone: new FormControl(null, []),
    });
  }

  searchPaymentSchedule(): void {
    let clntId = this.clntId.value;
    let cnic = this.cnic.value;
    let phone = this.phone.value;
    this._sharedSvc.showSpinner();
    this._recoveryService.srch_pymt_sched_dsbmt(clntId, cnic, phone).subscribe({
      next: (response: any) => {
        if (response) {
          this._sharedSvc.hideSpinner();
          this._recoveryService._recoverydata = response;
          this._recoverydata_0 = this._recoveryService._recoverydata[0];
          this.selectedDate=this._recoverydata_0?.dueDt;   
          this.totalPaymentAmount=(this._recoverydata_0?.prnAmt +
          this._recoverydata_0?.othChrgAmt +
          this._recoverydata_0?.srvcChrgAmt -
          this._recoverydata_0?.paidAmount )    
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }
  openModal(content: any) {
    this.savePaymentTxnForm = this.ValidationTXN();
    this.selectedDate=this._recoverydata_0?.dueDt; 
    this.setTxnModel();
    this.setEXCSModel();
    this.modalService.open(content, {
      windowClass: "popupWidth1k",
      // backdropClass: 'light-blue-backdrop',
      centered: true
    });
  }

  paymentMod(data:any){
    if(this.pymtMode){
      if(this.pymtMode.value == "2"){
        this.savePaymentTxnForm.get("instrumentNumber").disable();
        this.instrumentNumber.reset();
      }
      else {
        this.savePaymentTxnForm.get("instrumentNumber").enable();
      }
    }
   



  }

  setTxnModel() {
    this._recoveryService.savePaymentModel = {
      paymentTxnSeq: null,
      pymtTxnId: null,
      paymentDate: new Date(),
      paymentAmount: null,
      parentPaymentReference: null,
      instrumentNumber: null,
      pymtTypeSequence: null,
      paymentStatusKey: 0,
      deleteFlag: false,
      paymentReferenceSequence: null,
      paymentReferenceTypeKey: 1,
      changeReasonKey: "",
      changeReasonComment: "",
      postFlag: true,
      depositSleepDate: new Date(),
    }
    // this.slipDate = new Date();
  }

  savePaymentTXN(): void {

    this._recoveryService.savePaymentModel.paymentReferenceSequence = this._recoverydata_0.clntSeq;
    this._recoveryService.savePaymentModel.paymentAmount = this.paymentAmount.value;
    this._recoveryService.savePaymentModel.instrumentNumber = this.instrumentNumber.value;
    this._recoveryService.savePaymentModel.pymtTypeSequence = this.pymtMode.value;
    if (this.savePaymentTxnForm.invalid) {
      for (const control of Object.keys(this.savePaymentTxnForm.controls)) {
        this.savePaymentTxnForm.controls[control].markAsTouched();
      }
      return;
    }
    this._sharedSvc.showSpinner();
    this._recoveryService.save_pymnt_txn().subscribe({
      next: (response: any) => {
        if (response) {
          this._sharedSvc.success(response.response)
          this._sharedSvc.hideSpinner();
        }
        this.modalService.dismissAll();
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }

  setEXCSModel() {
    this.jwt = sessionStorage.getItem('JWT');
    this.jwt = JSON.parse(this.jwt);
    let date = new Date();

    this._recoveryService.saveExcsPaymentModel = {
      excsPymntSeq: null,
      clntSeq: null,
      excsAmount: null,
      rcvDate: date,
      epMethodKey: "",
      statusKey: 0,
      statusDt: date,
      aprvdById: "",
      trxReference: null,
      crtdBy: this.jwt?.preferred_username,
      crtdDt: date,
      lastUpdBy: this.jwt?.preferred_username,
      lastUpdDt: date,
      delFlg: false
    }
    // this.slipDate = new Date();
  }

  saveEXCSPayment(): void {
    this._recoveryService.saveExcsPaymentModel.clntSeq = this._recoverydata_0.clntSeq;
    this._recoveryService.saveExcsPaymentModel.excsAmount = this.paymentAmount.value;
    this._recoveryService.saveExcsPaymentModel.epMethodKey = this.pymtMode.value;
    if (this.savePaymentTxnForm.invalid) {
      for (const control of Object.keys(this.savePaymentTxnForm.controls)) {
        this.savePaymentTxnForm.controls[control].markAsTouched();
      }
      return;
    }
    this._sharedSvc.showSpinner();

    this._recoveryService.save_excs_pymnt().subscribe({
      next: (response: any) => {
        if (response) {
          this._sharedSvc.hideSpinner()
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }

  ValidationTXN() {
    return this.formBuilder.group({
      pymtMode: [null, [Validators.required]],
      instrumentNumber: [null, [ Validators.minLength(1),Validators.maxLength(15)]],
      slipDate: [this._recoverydata_0?.dueDt ? this.convertDateFormat(this._recoverydata_0.dueDt) : null, []],
      paymentAmount: [this.totalPaymentAmount, [Validators.required, Validators.min(1)]],
    });
  }
  convertDateFormat(dateString: string): string {
    const parts = dateString.split('-'); // Split the date string by '-'
    if (parts.length === 3) {
      // Reformat the date string to 'yyyy-mm-dd' format
      return `${parts[2]}-${parts[1]}-${parts[0]}`;
    }
    return ''; // Return an empty string for invalid input or if the format is unexpected
  }
  
  onDateSelected(event:any){
   const slipDate = new Date(this.slipDate.value)
   const selectedDate=new Date(this.convertDateFormat(this.selectedDate));
   const differenceInTime = slipDate.getTime()- selectedDate.getTime(); // Difference in milliseconds
   const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24)); 
   const dailyRate=Math.ceil(this._recoverydata_0?.srvcChrgAmt/365);
   if(differenceInDays > 0){
    const totalAmount =this.totalPaymentAmount + (dailyRate*differenceInDays)
    this.paymentAmount.setValue(Math.ceil(totalAmount));
  }else{    
    const totalAmount =this.totalPaymentAmount - (dailyRate*Math.abs(differenceInDays));
    this.paymentAmount.setValue(Math.ceil(totalAmount));
   }
  }

  ValidationEXCS() {
    return new FormGroup({
      ghi: new FormControl(null, [Validators.required]),
      abc: new FormControl(null, [Validators.minLength(3), Validators.required]),
      def: new FormControl(null, [Validators.required, Validators.minLength(12), Validators.maxLength(12)]),
    });
  }


  // savePaymentTxnForm
  get clntId() { return this.searchPaymentForm.get("clntId") }
  get cnic() { return this.searchPaymentForm.get("cnic") }
  get phone() { return this.searchPaymentForm.get("phone") }

  // savePaymentTxnForm
  get pymtMode() { return this.savePaymentTxnForm.get("pymtMode") }
  get instrumentNumber() { return this.savePaymentTxnForm.get("instrumentNumber") }
  get slipDate() { return this.savePaymentTxnForm.get("slipDate") }
  get paymentAmount() { return this.savePaymentTxnForm.get("paymentAmount") }

}