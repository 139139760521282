import { KeycloakOptions, KeycloakService } from 'keycloak-angular';
import { environment } from '../../environments/environment';


export function initializer(keycloak: KeycloakService): () => Promise<boolean> {

    const options: KeycloakOptions = {
      config : environment.keycloakConfig,
      loadUserProfileAtStartUp: true,
      initOptions: {
          // onLoad: 'check-sso',
          onLoad: 'login-required',
          checkLoginIframe: false
      },
      bearerExcludedUrls: []
    };

    return () => keycloak.init(options);
}

// export function initializer(keycloak: KeycloakService) {
//   return () => {
//     return new Promise<void>(async (resolve, reject) => {
//       try {
//         const options: KeycloakOptions = {
//                 config : environment.keycloakConfig,
//                 loadUserProfileAtStartUp: true,
//                 initOptions: {
//                     // onLoad: 'check-sso',
//                     onLoad: 'login-required',
//                     checkLoginIframe: false
//                 },
//                 bearerExcludedUrls: []
//               };

//         await keycloak.init(options);

//         /** */
//         const keycloakAuth = keycloak.getKeycloakInstance();

//         const updateToken = async (): Promise < string > => {
//          return keycloakAuth.updateToken(20).then(
//           (success)=>{
//             if(success)
//             return Promise.resolve(keycloakAuth.token);
//             return Promise.reject();
//           }
//           );
//         }

//         const login = async (): Promise < void > => {
//           return keycloakAuth.login()
//           // const {success,error} = keycloakAuth.login();
//           // return new Promise < void > ((res2, rej2) => {
//           //   success(res2);
//           //   error(rej2);
//           // });
//         }


//         keycloakAuth.onTokenExpired = () => {
//           if (keycloakAuth.refreshToken) {
//             updateToken();
//           } else {
//             login();
//           }
//         }

//         resolve();
//       } catch (error) {
//         reject(error);
//       }
//     });
//   };
// }