import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from '../../shared/service/shared.service';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, Validators , NgForm, FormControl} from '@angular/forms';
import { ClintOnboardingService } from '../../clint-onboarding/shared/service/clint-onboarding.service';
import { AdminService } from '../shared/service/admin.service';
import { GoldRate } from '../shared/model/gold-rate';
import { DecimalPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-gold-rate',
  templateUrl: './gold-rate.component.html',
  styleUrls: ['./gold-rate.component.css']
})
export class GoldRateComponent implements OnInit {

  
  loadingIndicator: boolean = false;
  rows: any;

  page: number = 5;
  pageSize: number = 5;
  collectionSize: number = 5;
  submitted = false;
  // currentDate: any = new Date().toISOString().split("T")[0];
  onBoardingTablePG: any;
  maskedCNIC: string;
  goldRates:GoldRate[];
  gold_rate_form: FormGroup;
  userId: any;
  selectedItem=-1;

  constructor(
    private modalService: NgbModal,
    public router: Router,
    public _sharedSvc: SharedService,
    public _clintOnboardingSvc: ClintOnboardingService,
    private adminService:AdminService,
    private decimalPipe: DecimalPipe,
    public translate: TranslateService

  ) { 
    this.adminService.getGoldRates( (res)=>{
      // console.log(res);
      if(res){this.goldRates=res; this.sortRecords();}
    });
    let jwt=sessionStorage.getItem("JWT");
    const user=JSON.parse(jwt);
    this.userId=user.preferred_username;
    
  }

  ngOnInit(): void {}

  pageChange() {
    this.collectionSize = this._clintOnboardingSvc.onBoardingTable.length;
    this.onBoardingTablePG = this._clintOnboardingSvc.onBoardingTable.slice(
      (this.page - 1) * this.pageSize,
      (this.page - 1) * this.pageSize + this.pageSize
    );
    this.onBoardingTablePG;
  }

  openModal(content: any) {
    this.gold_rate_form=this.getFormRate();
    this.modalService.open(content, { windowClass: "popupWidth800",centered: true});
  }

  onSubmitForm(): void {
    /*validate form */
    if (!this.gold_rate_form.valid) {
      this.gold_rate_form.markAllAsTouched();
      return;
    }
   
    let date=new Date(this.sDate.value);
      // let da=this.sDate.value.split("-")[2]+"-"+
      // date.toLocaleString('default', { month: 'short' }).toLowerCase().substring(0,3)+"-"+
      // this.sDate.value.split("-")[0];
    let body:any;
    if(this.selectedItem>-1)
    {
      body=this.goldRates[this.selectedItem]; body.rateDt=date.toISOString(); body.mrktRate= parseFloat( this.goldrate.value);
      this.goldRates[this.selectedItem]=body;
      // console.log(body);
    }
    else{body=new GoldRate();
      body.goldRateSeq=0;  body.mrktRate= this.goldrate.value;  body.rateDt=date.toISOString();  body.rateStatusKey= 0;
    body.statusDt= new Date().toISOString(); body.aprvdById=this.userId; body.crtdBy=this.userId;
    body.crtdDt=new Date().toISOString(); body.lastUpdBy=this.userId; body.lastUpdDt=new Date().toISOString();}
    body.delFlg= false;

    // if(this.selectedItem==-1)
    this.adminService.saveGolRate(body, (res)=>{ // console.log(res);
      if(res){
        if(this.selectedItem>-1){ this.goldRates[this.selectedItem]=res}
        else
        this.goldRates.push(res); 
        this.sortRecords();
        this.selectedItem=-1;
      }
    });
    this.modalService.dismissAll('Save click');
  }

  navigate() {    this.router.navigate(['/disbursement-form']);}

   /*create form controller */
   getFormRate() {
    return new FormGroup({
      sDate: new FormControl(null, [Validators.required]),
      goldrate: new FormControl(null, [Validators.required, Validators.minLength(4)]),
    });
  }

  disableDate() { return false;}

  get sDate(){ return this.gold_rate_form.get("sDate");}
  get goldrate(){ return this.gold_rate_form.get("goldrate");}


  // numberOnly(event: any): boolean {
  //   const charCode = (event.which) ? event.which : event.keyCode;
  //   if ((charCode < 48 || charCode > 57)) {
  //     return false;
  //   }
  //   // this.validateAmount();
  //   let ph = this.goldrate.value;
  //   if (ph && ph.length > 1 )
  //   this.goldrate.setValue(ph+".00");

  //   return true;
  // }

  transformDecimal() {
    this.goldrate.setValue(this.decimalPipe.transform(this.goldrate.value, '1.2-2')) ;
  }

  /*edit Model*/
  editModal(content: any, i: number) {
    this.selectedItem = i;
    this.gold_rate_form = this.editForm(i);
    this.modalService.open(content, {
      windowClass: "popupWidth800",
      centered: true
    });
  }

   /*create formContorller for edit */
   editForm(i: number) {
    let v = this.goldRates[i];
    return new FormGroup({
      sDate: new FormControl(v.rateDt.split('T')[0], [Validators.required]),
      goldrate: new FormControl(v.mrktRate, [Validators.required, Validators.minLength(4)]),
     });
   }


   sortRecords(){this.goldRates.sort((v1, v2)=> {return v2.rateDt.localeCompare(v1.rateDt); } );}
   
   diasbleByIndex(i){ return i>0}
   helight(i){return i==0;}
}