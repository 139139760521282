export class CreditApp {
    crdtAppSeq: number;
    appId: number;
    crdtCyclNum: number;
    rqstdAmt: number;
    aprvdAmt: number;
    rcmndAmt: number;
    delFlg: boolean;
    crdtAppStsKey: number;
    cmnt: string;
    reasonCd: number;
    clntSeq: number;
    prdSeq: number;
    portSeq: number;
    crdtAppStsDt: string;
    tblScrn: boolean;
    prntCrdtAppSeq: number;
    prdRul: number;
    crdtBnd: string;
    isMulticrdt: boolean;
    multicrdt: boolean
  }