import { Component, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SharedService } from "src/app/components/shared/service/shared.service";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
  NgForm,
  ValidatorFn,
  ValidationErrors,
} from "@angular/forms";

import { ClintOnboardingService } from "src/app/components/clint-onboarding/shared/service/clint-onboarding.service";
import { SetupService } from "../shared/service/setup.service";
import { PicklistService } from "../../shared/pickList/picklist.service";
import { TranslateService } from "@ngx-translate/core";
@Component({
  selector: "app-business-sector",
  templateUrl: "./business-sector.component.html",
  styleUrls: ["./business-sector.component.css"],
})
export class BusinessSectorComponent implements OnInit {
  loadingIndicator: boolean = false;
  rows: any;

  loginForm!: FormGroup;
  submitted = false;
  // currentDate: any = new Date().toISOString().split("T")[0];
  maskedCNIC: string;
  addBizSecformData: any;
  isEdit: boolean;
  singleRecord: any;

  constructor(
    private modalService: NgbModal,
    public router: Router,
    public _sharedSvc: SharedService,
    public _SetupService: SetupService,
    public _clintOnboardingSvc: ClintOnboardingService,
    private formBuilder: FormBuilder,
    public _picklistService: PicklistService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.createBizSecForm();
    this._SetupService.get_biz_sect_list();
    this._picklistService.Ref_Code_Grp();
  }

  createBizSecForm() {
    this.addBizSecformData = this.formBuilder.group({
      bizSectNm: ["", Validators.required],
      bizSectSortOrder: ["", [Validators.required, this.isSortOrderExist()]],
      bizSectStsKey: ["", Validators.required],
    });
  }
  get bizSectNm() {
    return this.addBizSecformData.get("bizSectNm");
  }
  get bizSectSortOrder() {
    return this.addBizSecformData.get("bizSectSortOrder");
  }
  get bizSectStsKey() {
    return this.addBizSecformData.get("bizSectStsKey");
  }

  isSortOrderExist(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = parseInt(control.value);
      if (!value || value == this.singleRecord?.bizSectSortOrder) {
        return null;
      }
      let list = this._SetupService.businessSectList;
      let record = list.find((x) => x.bizSectSortOrder === value);
      return record ? { isSortOrderExist: true } : null;
    };
  }

  submitBizSecForm(val) {
    if (this.addBizSecformData.invalid) {
      for (const control of Object.keys(this.addBizSecformData.controls)) {
        this.addBizSecformData.controls[control].markAsTouched();
      }
      return;
    }
    let data = {
      tntKey: 1,
      ...this.addBizSecformData.value,
      bizSectSeq: null,
      delFlg: 0,
    };
    if (this.isEdit) {
      data.bizSectSeq = this.singleRecord.bizSectSeq;
    }
    this._SetupService.setupBizModel = data;
    this._sharedSvc.showSpinner3();
    this._SetupService.saveBizSec().subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.success(
            this.translate.instant("toast.sector") +
              (this.isEdit === true
                ? this.translate.instant("toast.updated")
                : this.translate.instant("toast.added"))
          );
          this._sharedSvc.hideSpinner3();
          this.modalService.dismissAll();
          this.addBizSecformData.reset();
          this._SetupService.get_biz_sect_list();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner3();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(
            ([key, value]: any, index: number) => {
              this._sharedSvc.error(value);
            }
          );
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    });
  }

  editBizSec(content: any, id: any, edit: any) {
    let list = this._SetupService.businessSectList;
    let record = list.find((x) => x.bizSectSeq === id);
    this.singleRecord = record;
    if (record) {
      let data = {
        bizSectNm: record.bizSectNm,
        bizSectSortOrder: record.bizSectSortOrder,
        bizSectStsKey: record.bizSectStsKey,
      };
      this.addBizSecformData.patchValue(data);
      this.openModal(content, edit);
    }
  }

  deleteRecord(id) {
    let list = this._SetupService.businessSectList;
    let record = list.find((x) => x.bizSectSeq === id);
    this.singleRecord = record;

    if (record) {
      let data = {
        ...record,
        delFlg: 1,
      };
      this._sharedSvc.showSpinner3();
      this._SetupService.setupBizModel = data;
      this._SetupService.saveBizSec().subscribe({
        next: (response) => {
          if (response) {
            this._sharedSvc.success(
              this.translate.instant("toast.sectorDeleted")
            );
            this._sharedSvc.hideSpinner3();
            this.modalService.dismissAll();
            this._SetupService.get_biz_sect_list();
          }
        },
        error: (e) => {
          this._sharedSvc.hideSpinner3();
          if (e.status == 401) {
            this._sharedSvc.error(this._sharedSvc.authMsg);
            this._sharedSvc.logout();
            return;
          }
          if (e?.error) {
            Object.entries(e?.error).forEach(
              ([key, value]: any, index: number) => {
                this._sharedSvc.error(value);
              }
            );
          }
          if (e?.error?.errorMessage) {
            this._sharedSvc.error(e?.error?.error);
          }
        },
      });
    }
  }

  // pageChange() {
  //   
  //   this.collectionSize = this._SetupService.businessSectList.length;
  //   this.businessSectTablePG =this._SetupService.businessSectTable.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  //   this.businessSectTablePG;
  // }

  openModal(content: any, edit: boolean) {
    localStorage.removeItem("selectedOrderData");
    this.isEdit = edit;
    this.modalService.open(content, {
      // backdrop: "static",
      // keyboard: false,
      windowClass: "popupWidth800",
      // backdropClass: 'light-blue-backdrop',
      centered: true,
    });
  }
  navToBizAct(bizSec: any) {
    sessionStorage.setItem("singleBizSecID", bizSec.bizSectSeq);
    this.navigate();
  }

  navigate() {
    this.router.navigate(["/setup-business-activity"]);
  }
}
