export class PickList {
    INCOME_TYPE: any = "005";
    GENDER: any = "006";
    SHROFF_STATUS: any = "513";
    MARITAL_STATUS: any = "007";
    EDUCATION: any = "008";
    OCCUPATION: any = "009";
    RESIDENCE: any = "010";
    BUSINESS_RUNNER: any = "014"; // secondary income
    BUSINESS_OWNERSHIP: any = "015";
    NATURE_OF_DISABILITY: any = "020";
    CREDIT_TERMS: any = "023";
    LOAN_FREQUENCY: any = "024";
    CALCULATION_TYPE: any = "025";
    CHARGES_TYPE: any = "027";
    LOAN_UTILIZATION_EXPENSE: any = "063";
    INSURANCE_RELATION: any = "065";
    BREAD_EARNER: any = "066";
    SECOND_INCOME_SOURCE: any = "082";
    KSZB_EXCLUSION_CATEGORY: any = "083";
    HOUSEHOLD_EXPENSES: any = "084";
    BUSINESS_EXPENSES: any = "085";
    BUSINESS_LOCATION: any = "088";
    RELATION_TYPE_NOMINEE: any = "089";
    RELATION_TYPE_COBORROWER: any = "091";
    SCHOOL_PRINCIPAL: any = "093";
    SCHOOL_OWNERSHIP: any = "094";
    SCHOOL_TYPE: any = "095";
    LOAN_PURPOSE: any = "105";
    APPLICATION_STATUS: any = "106";
    PRODUCT_TYPE: any = "161";
    Reason_Codes: any = "298";
    Charge_Payment_Mode: any = "394";
    PDC: any = "395"
    CUSTOMER_TYPE: any = "396"
    BUSINESS_FREQUENCY: any = "500"
    INSTITUTE: any = "501"
    BUSINESS_INCOME_TYPE: any = "502"
    SALARY_TYPE: any = "503"
    WORK_STATUS: any = "504"
    SHROFF_ID: any = "505"
    SHROFF_BAG: any = "506"
    BRANCH_BAG: any = "507"
    GOLD_RETE: any = "508"
    VAULT_ID: any = ""
    VAULT_POSITION: any = "511"
    VAULT_COLOR: any = "512"
    SAFE_ID: any = ""
    BRANCH_TYPE: any = "15"
    Ref_Code_Grp:any="1021";
    BAG_STATUS:any="1060";
    BAG_PLACE_STATUS="1100";
    BAG_TYPE:any="1061";
    QST_CTGRY_KEY: any = "707";
    QST_TYP_KEY: any = "202";
    CHARGE_TYPE_ID: any = "1120";
}