<section>
    <div class="sidebar" [class.sidebar-close]="!_sharedSvc.openSidebar">
        <app-sidebar></app-sidebar>
    </div>
    <div class="home-section">
        <div class="home-main" [class.sidebar-close_home-main]="!_sharedSvc.openSidebar">
            <app-navbar></app-navbar>
            <div class="home-view" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
                [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
                <section>
                    <div class="row mb-3">
                        <div class="col-sm-8 col-md-8 col-lg-8">
                            <h4>{{'sideBarMenu.setup.commonCodes' | translate}}</h4>
                        </div>
                        <div class="col-sm-4 col-md-4 col-lg-4">
                            <div class="add-button" (click)="openModal(commonCodes,false)"><i class="fa fa-plus"></i>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <div class="dashboard-card loan-table">
                                <div class="row mb-3">
                                    <div class="col-sm-7 col-md-7 col-lg-8">
                                        <ng-container *ngIf="_sharedSvc.pageSize >= 10">
                                            <span>{{'common.show' | translate}}</span>
                                            <input name="entries" class="m-1 text-center"
                                                [value]="_SetupService.refCdGrpTablePG?.length" readonly
                                                style="width: 45px;">
                                            <span>{{'common.entries' | translate}}</span>
                                        </ng-container>
                                    </div>
                                    <div class="col-sm-5 col-md-5 col-lg-4">
                                        <div class="inside-icon">
                                            <i class="fa fa-search"></i>
                                            <input type="search" class="form-control search-input"
                                                placeholder="{{'common.search' | translate}}">
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                        <div class="table-responsive">
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <td>{{'common.code' | translate}}</td>
                                                        <td>{{'common.name' | translate}}</td>
                                                        <td>{{'common.description' | translate}}</td>
                                                        <td>{{'common.status' | translate}}</td>
                                                        <td class="text-center">{{'common.action' | translate}}</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let list of _SetupService.refCdGrpTablePG | sortbyPipe:'grpName'">
                                                        <td>{{list.grpId}}</td>
                                                        <td class="clr186ed1 pointer" (click)="navToRefCdVal(list)">
                                                            {{list.grpName}}</td>
                                                        <td>{{list.grpDscr}}</td>
                                                        <td>{{list.grpActvFlg ==true?
                                                            this.translate.instant('common.active') :
                                                            this.translate.instant('common.inActive')}}</td>
                                                        <td class="text-center">
                                                            <div class="action-inner">
                                                                <i class="fa fa-pencil"
                                                                    (click)="editSetupGrp(commonCodes ,list.grpSeq , true)"></i>
                                                                <i class="fa fa-trash"
                                                                    (click)="deleteRecord(list.grpSeq)"></i>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div class="row g-0" *ngIf="_sharedSvc.pageSize > 0">
                                                <div class="col-sm-12 col-md-12 col-lg-6">
                                                    <span class="clr-9d9a9a">{{'common.showing' | translate}}
                                                        {{_SetupService.refCdGrpTablePG?.length}} {{'common.of' |
                                                        translate}}
                                                        {{_SetupService.collectionSizeCdGrp}} {{'common.entries' |
                                                        translate}}</span>
                                                </div>
                                                <div class="col-sm-12 col-md-12 col-lg-6">
                                                    <div class="float-right">
                                                        <ngb-pagination [(page)]="_sharedSvc.page"
                                                            [pageSize]="_sharedSvc.pageSize" [maxSize]="10"
                                                            ngDefaultControl
                                                            [collectionSize]="_SetupService.collectionSizeCdGrp"
                                                            [rotate]="true"
                                                            (pageChange)="_SetupService.pageChangeCdGrp()">
                                                            <ng-template ngbPaginationPrevious> {{'common.prev' |
                                                                translate}}</ng-template>
                                                            <ng-template ngbPaginationNext> {{'common.next' |
                                                                translate}}</ng-template>
                                                        </ngb-pagination>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</section>

<ng-template id="display-modal" #commonCodes let-c="close" let-d="dismiss">
    <div class="modal-body" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
        [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
        <div class="p-3 pb-0">
            <div class="row mb-3">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="text-center">
                        <h4> {{'commonCodes.commonCodeGroup' | translate}}</h4>
                    </div>
                </div>
            </div>
            <form [formGroup]="addSetupGrpformData" (ngSubmit)="submitSetupGrpForm(addSetupGrpformData)">
                <div class="row mb-3">
                    <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
                        <div class="form-floating">
                            <input type="text" style="text-transform: capitalize;" autocomplete="off"
                                class="form-control input-radius" formControlName="grpName"
                                [class.is-invalid]="grpName.invalid && (grpName.dirty || grpName.touched)">
                            <div *ngIf="grpName.invalid && (grpName.dirty || grpName.touched)" class="invalid-feedback">
                                <div *ngIf="grpName.errors?.['required']">
                                    {{'common.nameRequire' | translate}}
                                </div>
                            </div>
                            <label>{{'common.name' | translate}}</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
                        <div class="form-floating">
                            <select name="gndrKey" id="gndrKey" class="form-control input-radius form-select"
                                formControlName="grpActvFlg"
                                [class.is-invalid]="grpActvFlg.invalid && (grpActvFlg.dirty || grpActvFlg.touched)">
                                <option [value]="true">{{'common.active' | translate}}</option>
                                <option [value]="false">{{'common.inActive' | translate}}</option>
                            </select>
                            <div *ngIf="grpActvFlg.invalid && (grpActvFlg.dirty || grpActvFlg.touched)"
                                class="invalid-feedback">
                                <div *ngIf="grpActvFlg.errors?.['required']">
                                    {{'common.statusRequire' | translate}}
                                </div>
                            </div>
                            <label>{{'common.status' | translate}}</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                        <div class="form-floating">
                            <input type="text" style="text-transform: capitalize;" autocomplete="off"
                                class="form-control input-radius" formControlName="grpDscr"
                                [class.is-invalid]="grpDscr.invalid && (grpDscr.dirty || grpDscr.touched)">
                            <div *ngIf="grpDscr.invalid && (grpDscr.dirty || grpDscr.touched)" class="invalid-feedback">
                                <div *ngIf="grpDscr.errors?.['required']">
                                    {{'common.descriptionRequire' | translate}}
                                </div>
                            </div>
                            <label>{{'common.description' | translate}}</label>
                        </div>
                    </div>

                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="button_div">
                            <button class="btn login-button-class" [class.pointerNone]="_sharedSvc.loading"
                                [disabled]="_sharedSvc.loading">{{'common.ok' | translate}}</button>
                            <button class="btn login-button-class cancelBtn" (click)="d('Cross click')"
                                type="reset">{{'common.cancel' | translate}}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>