<section>
    <div class="sidebar" [class.sidebar-close]="!_sharedSvc.openSidebar">
        <app-sidebar></app-sidebar>
    </div>
    <div class="home-section">
        <div class="home-main" [class.sidebar-close_home-main]="!_sharedSvc.openSidebar">
            <app-navbar></app-navbar>
            <div class="home-view">
                <section [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
                    [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
                    <div class="row mb-3">
                        <div class="col-sm-8 col-md-8 col-lg-8">
                            <div>
                                <h4> {{'sideBarMenu.clientOnboarding' | translate}}</h4>
                            </div>
                        </div>
                        <div class="col-sm-4 col-md-4 col-lg-4" *ngIf="isShowPlusBtn">
                            <div class="add-button" (click)="openModal(validation)"
                                [class.float-start]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
                                <i class="fa fa-plus"></i>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <div class="dashboard-card loan-table">
                                <div class="row mb-3">
                                    <div class="col-sm-7 col-md-7 col-lg-8">
                                        <ng-container *ngIf="_sharedSvc.collectionSize">
                                            <span> {{'common.show' | translate}}</span>
                                            <input name="entries" class="m-1 text-center"
                                                [value]="_clintOnboardingSvc.getAllClientPG?.length" readonly
                                                style="width: 45px;">
                                            <span> {{'common.entries' | translate}}</span>
                                        </ng-container>
                                    </div>
                                    <div class="col-sm-5 col-md-5 col-lg-4">
                                        <div class="inside-icon">
                                            <i class="fa fa-search"></i>
                                            <input name="search" type="search" class="form-control search-input" [(ngModel)]="AppSeq"
                                                placeholder="{{'common.search' | translate}}">
                                        </div>
                                    </div>
                                </div>
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <td>{{'common.name' | translate}}</td>
                                                <td>{{'common.id' | translate}}</td>
                                                <td>{{'common.cnicNo' | translate}}.</td>
                                                <!-- <td>{{'common.address' | translate}}</td> -->
                                                <td>{{'common.assignedTo' | translate}}</td>
                                                <td>{{'common.status' | translate}}</td>
                                                <td class="text-center">{{'common.action' | translate}}</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let data of _clintOnboardingSvc.getAllClientPG | tableFilterOnBoarding: AppSeq;">
                                                <td>
                                                    <div class="table-icon">
                                                        <i class="fa fa-user"></i>
                                                        {{data?.frstNm | titlecase}} {{data?.lastNm | titlecase}}
                                                    </div>
                                                </td>
                                                <td>{{data?.clntId}}</td>
                                                <td>{{data?.cnicNum}}</td>
                                                <!-- <td>{{'common.address' | translate}}</td> -->
                                                <td>
                                                    BDO
                                                </td>
                                                <ng-container
                                                    *ngFor="let item of _picklistService.APPLICATION_STATUS_DATA">
                                                    <td *ngIf="item.refCdSeq == data.clntStsKey ">
                                                        {{item.refCdDscr}}
                                                    </td>
                                                </ng-container>
                                                <td *ngIf="data.clntStsKey  == 0">
                                                </td>
                                                <td class="text-center">
                                                    <div class="action-inner">
                                                        <i class="fa fa-pencil"
                                                            [ngClass]="data.clntStsKey != '699' ? 'disabled' : ''"
                                                            (click)="onEdit(data,data.clntStsKey)"></i>
                                                        <i class="fa fa-eye" (click)="readOnlyView(data)"></i>
                                                        <i class="fa fa-trash"
                                                            [ngClass]="data.clntStsKey != '699' ? 'disabled' : ''"
                                                            (click)="DeletePopup(data)"></i>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="row g-0" *ngIf="_sharedSvc.pageSize > 0">
                                        <div class="col-sm-12 col-md-12 col-lg-6">
                                            <span class="clr-9d9a9a"> {{'common.showing' | translate}}
                                                {{_clintOnboardingSvc.getAllClientPG?.length}} {{'common.of' |
                                                translate}}
                                                {{_sharedSvc.collectionSize}} {{'common.entries' | translate}}</span>
                                        </div>
                                        <div class="col-sm-12 col-md-12 col-lg-6">
                                            <div class="float-right">
                                                <ngb-pagination [(page)]="_sharedSvc.page"
                                                    [pageSize]="_sharedSvc.pageSize" [maxSize]="10" ngDefaultControl
                                                    [collectionSize]="_sharedSvc.collectionSize" [rotate]="true"
                                                    (pageChange)="_clintOnboardingSvc.pageChange()">
                                                    <ng-template ngbPaginationPrevious> {{'common.prev' |
                                                        translate}}</ng-template>
                                                    <ng-template ngbPaginationNext> {{'common.next' |
                                                        translate}}</ng-template>
                                                </ngb-pagination>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</section>
<ng-template id="display-modal" #validation let-c="close" let-d="dismiss">
    <div class="modal-body" *ngIf="!_sharedSvc.loading"
        [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
        [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
        <div class="p-3">
            <div class="row mb-3">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="text-center">
                        <h4>{{'common.validationHistory' | translate}}</h4>
                    </div>
                </div>
            </div>
            <form [formGroup]="validationForm" (ngSubmit)="cnicValidation()">
                <div class="row mb-3">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="form-floating">
                            <input name="cnicNum" id="cnicNum" type="text" style="text-transform: capitalize;"
                                class="form-control input-radius" autocomplete="off" formControlName="cnicNum"
                                kzMask="99999-9999999-9"
                                (retornoKzMask)="applyMasking(_clintOnboardingSvc.cnicValidationModel.cnicNum)" required
                                [class.is-invalid]="cnicNum.invalid && (cnicNum.dirty || cnicNum.touched)">
                            <div *ngIf="cnicNum.invalid && (cnicNum.dirty || cnicNum.touched)" class="invalid-feedback">
                                <div *ngIf="cnicNum.errors?.['required']">
                                    CNIC {{'common.isRequired' | translate}}.
                                </div>
                            </div>
                            <label for="cnicNum">{{'common.client' | translate}} CNIC</label>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-sm-12 col-md-6 col-lg-6">
                        <div class="form-floating">
                            <input name="issueDate" id="issueDate" class="form-control input-radius readonly-field"
                                [ngClass]="issueDate.invalid ? 'requiredInput' : ''" formControlName="issueDate"
                                [minDate]="_sharedSvc.cnicIssueDate" [maxDate]="_sharedSvc.todayDate" ngbDatepicker
                                #d1="ngbDatepicker"
                                [class.is-invalid]="issueDate.invalid && (issueDate.dirty || issueDate.touched)"
                                readonly />
                            <i class="fa fa-calendar nbgCalender" (click)="d1.toggle()"></i>
                            <div *ngIf="issueDate.invalid && (issueDate.dirty || issueDate.touched)"
                                class="invalid-feedback">
                                <div *ngIf="issueDate.errors?.['required']">
                                    {{'clientOnboarding.issueDateRequire' | translate}}
                                </div>
                                <div *ngIf="issueDate.errors?.['min']">
                                    {{'clientOnboarding.min10Year' | translate}}
                                </div>
                                <div *ngIf="issueDate.errors?.['max']">
                                    {{'clientOnboarding.maxcurrentDate' | translate}}
                                </div>
                            </div>
                            <label for="issueDate">{{'common.issueDate' | translate}}</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6">
                        <div class="form-floating">
                            <input name="expiryDate" id="expiryDate" class="form-control input-radius readonly-field"
                                formControlName="expiryDate" [ngClass]="expiryDate.invalid ? 'requiredInput' : ''"
                                ngbDatepicker #d2="ngbDatepicker" 
                                [maxDate]="_sharedSvc.cnicExpiryDate"
                                [class.is-invalid]="expiryDate.invalid && (expiryDate.dirty || expiryDate.touched)"
                                readonly />
                            <i class="fa fa-calendar nbgCalender" (click)="d2.toggle()"></i>
                            <div *ngIf="expiryDate.invalid && (expiryDate.dirty || expiryDate.touched)"
                                class="invalid-feedback">
                                <div *ngIf="expiryDate.errors?.['required']">
                                    {{'clientOnboarding.expiryDateRequire' | translate}}
                                </div>
                                <div *ngIf="expiryDate.errors?.['minlength']">
                                    {{'clientOnboarding.expiryDate13Character' | translate}}
                                </div>
                                <div *ngIf="expiryDate.errors?.['maxlength']">
                                    {{'clientOnboarding.expiryDatemost13character' | translate}}
                                </div>
                            </div>
                            <label for="expiryDate">{{'common.expiryDate' | translate}}</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="button_div">
                            <button class="btn login-button-class" [class.pointerNone]="_sharedSvc.loading"
                                [disabled]="_sharedSvc.loading">{{'common.validate' | translate}}</button>
                            <button class="btn login-button-class cancelBtn" (click)="d('Cross click')"
                                type="reset">{{'common.cancel' | translate}}</button>
                        </div>
                        <div class="button_div" *ngIf="popupEditBtn">
                            <button class="btn login-button-class button-edit" (click)="onEdit()">
                                {{'clientOnboarding.editDetail' | translate}}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>