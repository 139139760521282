<section>
  <div class="sidebar" [class.sidebar-close]="!_sharedSvc.openSidebar">
    <app-sidebar></app-sidebar>
  </div>
  <div class="home-section">
    <div class="home-main" [class.sidebar-close_home-main]="!_sharedSvc.openSidebar">
      <app-navbar></app-navbar>

      <div class="home-view" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
        [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
        <section>
          <div class="row mb-3">
            <div class="col-sm-8 col-md-8 col-lg-8">
              <h4>{{ "common.questionnaire" | translate }}</h4>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4">
              <div class="display-flex float-right">
                <div class="add-button" (click)="openQstnrModal(qstnrModal, false)">
                  <i class="fa fa-plus"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="dashboard-card loan-table">
                <div class="row mb-3">
                  <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="table-responsive">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <td>
                              {{ "questionnaire.questionnaireId" | translate }}
                            </td>
                            <td>
                              {{
                              "questionnaire.questionnaireName" | translate
                              }}
                            </td>
                            <td>{{ "questionnaire.questions" | translate }}</td>
                            <td>{{ "common.status" | translate }}</td>
                            <td class="text-center">
                              {{ "common.action" | translate }}
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of _SetupService.questionnaireList">
                            <td>{{ item.qstnrSeq }}</td>
                            <td>{{ item.qstnrNm }}</td>
                            <td (click)="next(item.qstnrSeq)" class="clr186ed1 cursor-pointer">
                              Questions
                            </td>
                            <td>{{ getStatus(item.qstnrStsKey) }}</td>
                            <td class="text-center">
                              <div class="action-inner">
                                <i class="fa fa-pencil" (click)="
                                    editQstnr(qstnrModal, item.qstnrSeq, true)
                                  "></i>
                                <i class="fa fa-trash" (click)="deleteQstnr(item.qstnrSeq)"></i>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="row g-0">
                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <span class="clr-9d9a9a">{{ "common.showing" | translate }}
                            {{ "common.1To3Of" | translate }}
                            3 {{ "common.entries" | translate }}</span>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <div class="float-right">
                            <nav aria-label="Page navigation example">
                              <ul class="pagination">
                                <li class="page-item">
                                  <a class="page-link">{{
                                    "common.first" | translate
                                    }}</a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link">{{
                                    "common.previous" | translate
                                    }}</a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link">1</a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link">2</a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link">3</a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link">4</a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link">5</a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link">{{
                                    "common.next" | translate
                                    }}</a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link">{{
                                    "common.last" | translate
                                    }}</a>
                                </li>
                              </ul>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>

<ng-template id="display-modal" #qstnrModal let-c="close" let-d="dismiss">
  <div class="modal-body" [dir]="(translate?.currentLang ==='ar' || translate?.currentLang==='ur')?'rtl':''"
    [class.fix-direction]="translate?.currentLang === 'ar' || translate?.currentLang === 'ur'">
    <div class="p-3 pb-0">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="text-center">
            <h4>{{isEdit === true ? this.translate.instant('common.edit') :
              this.translate.instant('common.add')}} {{ "common.questionnaire" | translate }}</h4>
          </div>
        </div>
      </div>
      <form [formGroup]="qstnrFormdata" (ngSubmit)="submitQstnr(qstnrFormdata)">
        <div class="row mb-3">
          <!-- <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                        <div class="form-floating">
                            <input type="text" style="text-transform: capitalize;" formControlName="qstnrId"
                                autocomplete="off" class="form-control input-radius"
                                [class.is-invalid]="qstnrId.invalid && (qstnrId.dirty || qstnrId.touched)">
                            <div *ngIf="qstnrId.invalid && (qstnrId.dirty || qstnrId.touched)" class="invalid-feedback">
                                <div *ngIf="qstnrId.errors?.['required']">
                                    Questionnaire Id is required.
                                </div>

                            </div>
                            <label>Questionnaire Id</label>
                        </div>
                    </div> -->
          <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
            <div class="form-floating">
              <input type="text" style="text-transform: capitalize" formControlName="qstnrNm" autocomplete="off"
                class="form-control input-radius" [class.is-invalid]="
                  qstnrNm.invalid && (qstnrNm.dirty || qstnrNm.touched)
                " />
              <div *ngIf="qstnrNm.invalid && (qstnrNm.dirty || qstnrNm.touched)" class="invalid-feedback">
                <div *ngIf="qstnrNm.errors?.['required']">
                  {{ "questionnaire.questionnaireNameRequire" | translate }}
                </div>
              </div>
              <label>{{ "questionnaire.questionnaireName" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
            <div class="form-floating">
              <select name="qstnrStsKey" id="qstnrStsKey" class="form-control input-radius form-select"
                formControlName="qstnrStsKey" [class.is-invalid]="
                  qstnrStsKey.invalid &&
                  (qstnrStsKey.dirty || qstnrStsKey.touched)
                ">
                <option *ngFor="
                    let item of _picklistService.SHROFF_STATUS_Data;
                    let i = index
                  " [value]="item?.refCdSeq">
                  {{ item.refCdDscr }}
                </option>
              </select>

              <div *ngIf="
                  qstnrStsKey.invalid &&
                  (qstnrStsKey.dirty || qstnrStsKey.touched)
                " class="invalid-feedback">
                <div *ngIf="qstnrStsKey.errors?.['required']">
                  {{ "common.statusRequire" | translate }}
                </div>
              </div>
              <label>{{ "common.status" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
            <div class="form-floating">
              <select name="qstnrLvlKey" id="qstnrLvlKey" class="form-control input-radius form-select"
                formControlName="qstnrLvlKey" [class.is-invalid]="
                  qstnrLvlKey.invalid &&
                  (qstnrLvlKey.dirty || qstnrLvlKey.touched)
                ">
                <!-- <option *ngFor="let item of _picklistService.SHROFF_STATUS_Data; let i = index"
                                    [value]="item?.refCdSeq">
                                    {{ item.refCdDscr }}
                                </option> -->
                <option value="null" hidden></option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
              <div *ngIf="
                  qstnrLvlKey.invalid &&
                  (qstnrLvlKey.dirty || qstnrLvlKey.touched)
                " class="invalid-feedback">
                <div *ngIf="qstnrLvlKey.errors?.['required']">
                  {{ "questionnaire.levelRequired" | translate }}
                </div>
              </div>
              <label>{{ "questionnaire.level" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
            <div class="form-floating">
              <select name="qstnrCtgryKey" id="qstnrCtgryKey" class="form-control input-radius form-select"
                formControlName="qstnrCtgryKey" [class.is-invalid]="
                  qstnrCtgryKey.invalid &&
                  (qstnrCtgryKey.dirty || qstnrCtgryKey.touched)
                ">
                <!-- <option *ngFor="let item of _picklistService.SHROFF_STATUS_Data; let i = index"
                                    [value]="item?.refCdSeq">
                                    {{ item.refCdDscr }}
                                </option> -->
                <option value="null" hidden></option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
              <div *ngIf="
                  qstnrCtgryKey.invalid &&
                  (qstnrCtgryKey.dirty || qstnrCtgryKey.touched)
                " class="invalid-feedback">
                <div *ngIf="qstnrCtgryKey.errors?.['required']">
                  {{ "common.categoryRequire" | translate }}
                </div>
              </div>
              <label>{{ "common.category" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
            <div class="form-floating">
              <input type="text" style="text-transform: capitalize" formControlName="qstnrRul" autocomplete="off"
                class="form-control input-radius" [class.is-invalid]="
                  qstnrRul.invalid && (qstnrRul.dirty || qstnrRul.touched)
                " />
              <div *ngIf="qstnrRul.invalid && (qstnrRul.dirty || qstnrRul.touched)" class="invalid-feedback">
                <div *ngIf="qstnrRul.errors?.['required']">
                  {{ "questionnaire.ruleRequired" | translate }}
                </div>
              </div>
              <label>{{ "questionnaire.rule" | translate }}</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="button_div">
              <button class="btn login-button-class" [class.pointerNone]="_sharedSvc.loading"
                [disabled]="_sharedSvc.loading">
                {{ "common.save" | translate }}
              </button>
              <button class="btn login-button-class cancelBtn" (click)="d('Cross click')" type="reset">
                {{ "common.cancel" | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>