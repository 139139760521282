import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'src/app/components/shared/service/shared.service';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';

import { ClintOnboardingService } from 'src/app/components/clint-onboarding/shared/service/clint-onboarding.service';
import { SetupService } from '../../../shared/service/setup.service';
import { TranslateService } from '@ngx-translate/core';
import { ProductServices } from '../../../shared/service/product.service';
import { PicklistService } from 'src/app/components/shared/pickList/picklist.service';


@Component({
  selector: 'app-product-rules',
  templateUrl: './product-rules.component.html',
  styleUrls: ['./product-rules.component.css']
})
export class ProductRulesComponent implements OnInit {
  creditTermsFormdata: any;
  principleAmountFormdata: any;


  principleAmountList:any = [];
  CreditTermsList:any = [];

  prdSeq:any;

  advanceRulesList:any = [];


  dropdowncreditTermsList:any =[];
  dropdowngInstallmentFrequency:any =[];
  dropdownRule:any = [];
  isEdit:boolean = false;
  singleRecord:any;
  constructor(
    public router: Router,
    public _sharedSvc: SharedService,
    public _clintOnboardingSvc: ClintOnboardingService,
    public _SetupService: SetupService,
    private formBuilder: FormBuilder,
    private nav: NgbNav,
    public _productServices:ProductServices,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    public _picklistService : PicklistService,

    public translate: TranslateService

  ) { }

  ngOnInit(): void {
    this.creditTermsFormdata = this.formBuilder.group({
      trmKey: ['', Validators.required],
      pymtFreqKey: ['', Validators.required],
      rulSeq: ['', Validators.required]
    });

    if(this.route.snapshot.paramMap.get("id")){
      console.log("param ID " + this.route.snapshot.paramMap.get("id"));
      this.prdSeq =  this.route.snapshot.paramMap.get("id");
    } 

    this.principleAmountFormdata = this.formBuilder.group({
      maxAmt: ['', Validators.required],
      minAmt: ['', Validators.required],
      sgrtInstNum: ['', Validators.required]
    });

    this.getPrincipleAmountList();
    this.getAdvanceRulesList();
    this.getAdvanceRules();
    this.getCreditTerms();
    this.getInstallmentFrequency();
    this.getProductRulDropdown();
    this.getAllCreditTermsList();
  }





  get terms() { return this.creditTermsFormdata.get('trmKey') }
  get installmentFrequency() { return this.creditTermsFormdata.get('pymtFreqKey') }
  get rulSeq() { return this.creditTermsFormdata.get('rulSeq') }

  get minAmount() { return this.principleAmountFormdata.get('minAmt') }
  get maxAmount() { return this.principleAmountFormdata.get('maxAmt') }
  get segregateInstallment() { return this.principleAmountFormdata.get('sgrtInstNum') }


  openModal(content: any, styleClass?: any , edit?:boolean) {
    this.isEdit = edit;
    this.modalService.open(content, {
      // backdrop: "static",
      // keyboard: false,
      windowClass: styleClass ? styleClass : "popupWidth800",
      backdropClass: 'light-blue-backdrop',
      centered: true
    });
  }


  editprincipleAmount(content: any, id: any, edit: any) {
    let list = this.principleAmountList;
    let record = list.find((x) => x.prdPpalLmtSeq === id);
    this.singleRecord = record;

    if (record) {
      let data = {
        maxAmt: record.maxAmt,
        minAmt: record.minAmt,
        sgrtInstNum: record.sgrtInstNum
      };
      this.principleAmountFormdata.patchValue(data);
      this.openModal(content,'', edit);
    }
  }
  editCreditTerms(content: any, id: any, edit: any) {
    let list = this.CreditTermsList;
    let record = list.find((x) => x.prdTrmSeq === id);
    this.singleRecord = record;

    if (record) {
      let data = {
        trmKey: record.trmKey,
        pymtFreqKey: record.pymtFreqKey,
        rulSeq: record.rulSeq
      };
      this.creditTermsFormdata.patchValue(data);
      this.openModal(content,'', edit);
    }
  }


  getPrincipleAmountList(){
    this._sharedSvc.showSpinner();
    this._productServices.get_product_pal_list().subscribe({
      next: (response: any) => {
        this._sharedSvc.hideSpinner();
        if (response) {
          if (response.error) {
            this._sharedSvc.error(response.error);
            return;
          }
          this.principleAmountList = response;
          console.log(response)
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    })
  }

  getAdvanceRulesList(){
    this._sharedSvc.showSpinner();
    this._productServices.get_product_rul_list().subscribe({
      next: (response: any) => {
        this._sharedSvc.hideSpinner();
        if (response) {
          if (response.error) {
            this._sharedSvc.error(response.error);
            return;
          }
          // this.principleAmountList = response;
          console.log(response)
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    })
  }






  dismissModal() {
    this.modalService.dismissAll();
  }

  saveCreditTermsForm(formData: any) {
    if (this.creditTermsFormdata.invalid) {
      for (const control of Object.keys(this.creditTermsFormdata.controls)) {
        this.creditTermsFormdata.controls[control].markAsTouched();
      }
      return;
    }

    let data = {
      ...this.creditTermsFormdata.value,
      prdSeq:Number(this.prdSeq),
      prdTrmSeq:null,
      delFlg: 0
    }

    if(this.isEdit == true){
      data.prdTrmSeq = this.singleRecord.prdTrmSeq
    }

    this._productServices.saveProductChrgTrmModel = data;
    this._sharedSvc.showSpinner3();

    this._productServices.save_product_chrg_trm().subscribe({
      next: response => {
        if (response) {
          this._sharedSvc.success("Product Credit Term Saved");
          this._sharedSvc.hideSpinner3();
          this.modalService.dismissAll();
          this.getAllCreditTermsList();
        }
      },
      error: e => {
        this._sharedSvc.hideSpinner3();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    })

  }
  deleteCreditTerm(id:any){
    
    let list = this.CreditTermsList;
    let record = list.find((x) => x.prdTrmSeq === id);
    this.singleRecord = record;

    if (record) {
      let data = {
        ...record,
        delFlg: 1,
      };
      this._productServices.saveProductChrgTrmModel = data;
        this._sharedSvc.showSpinner3();

        this._productServices.save_product_chrg_trm().subscribe({
          next: response => {
            if (response) {
              this._sharedSvc.success("Product Credit Deleted");
              this._sharedSvc.hideSpinner3();
              this.modalService.dismissAll();
              this.getAllCreditTermsList();
            }
          },
          error: e => {
            this._sharedSvc.hideSpinner3();
            if (e.status == 401) {
              this._sharedSvc.error(this._sharedSvc.authMsg);
              this._sharedSvc.logout();
              return;
            }
            if (e?.error) {
              Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
                this._sharedSvc.error(value);
              });
            }
            if (e?.error?.errorMessage) {
              this._sharedSvc.error(e?.error?.error);
            }
          },
        })
    }
  }
  savePrincipleAmountForm(formData: any) {
    if (this.principleAmountFormdata.invalid) {
      for (const control of Object.keys(this.principleAmountFormdata.controls)) {
        this.principleAmountFormdata.controls[control].markAsTouched();
      }
      return;
    }

    let data = {
      ...this.principleAmountFormdata.value,
      sgrtInstNum:Number(this.principleAmountFormdata.value.sgrtInstNum),
      maxAmt:Number(this.principleAmountFormdata.value.maxAmt),
      minAmt:Number(this.principleAmountFormdata.value.minAmt),
      prdSeq:Number(this.prdSeq),
      rulSeq:Number(12),
      prdPpalLmtSeq:null,
      delFlg: 0
    }

    if(this.isEdit == true){
      data.prdPpalLmtSeq = this.singleRecord.prdPpalLmtSeq
    }


    this._productServices.saveProductPalModel = data;
    this._sharedSvc.showSpinner3();

    this._productServices.save_product_pal().subscribe({
      next: response => {
        if (response) {
          this._sharedSvc.success("Product Principle Saved");
          this._sharedSvc.hideSpinner3();
          this.modalService.dismissAll();
          this.getPrincipleAmountList();
        }
      },
      error: e => {
        this._sharedSvc.hideSpinner3();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    })


  }
  deletePrincipleAmount(id:any){
    
    let list = this.principleAmountList;
    let record = list.find((x) => x.prdPpalLmtSeq === id);
    this.singleRecord = record;

    if (record) {
      let data = {
        ...record,
        delFlg: 1,
      };

      this._productServices.saveProductPalModel = data;
      this._sharedSvc.showSpinner3();
  
      this._productServices.save_product_pal().subscribe({
        next: response => {
          if (response) {
            this._sharedSvc.success("Product Principle Deleted");
            this._sharedSvc.hideSpinner3();
            this.modalService.dismissAll();
            this.getPrincipleAmountList();
          }
        },
        error: e => {
          this._sharedSvc.hideSpinner3();
          if (e.status == 401) {
            this._sharedSvc.error(this._sharedSvc.authMsg);
            this._sharedSvc.logout();
            return;
          }
          if (e?.error) {
            Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
              this._sharedSvc.error(value);
            });
          }
          if (e?.error?.errorMessage) {
            this._sharedSvc.error(e?.error?.error);
          }
        },
      })
    }
  }
  moveToNextTab() {
    if (this.nav) {
      this._sharedSvc.showSpinner3();
      const currentTab = this.nav.activeId || 1;
      const nextTab = currentTab < this.nav.items.length ? currentTab + 1 : 1;
      this.nav.select(nextTab);
      this._sharedSvc.hideSpinner3();
    }
  }
  getAllCreditTermsList(){
    this._sharedSvc.showSpinner();

    this._productServices.get_product_chrg_trm_list().subscribe({
      next: (response) => {
        if (response) {
         console.log(response)
         this.CreditTermsList = response;
          this._sharedSvc.hideSpinner();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    })
  }

  getCreditTerms(){
    this._sharedSvc.showSpinner();

  this._picklistService.pickListAPI(this._picklistService.pickListModel.CREDIT_TERMS).subscribe({
    next: (response) => {
      if (response) {
       console.log(response)
       this.dropdowncreditTermsList = response;
        this._sharedSvc.hideSpinner();
      }
    },
    error: (e) => {
      this._sharedSvc.hideSpinner();
      if (e.status == 401) {
        this._sharedSvc.error(this._sharedSvc.authMsg);
        this._sharedSvc.logout();
        return;
      }
      if (e?.error) {
        Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
          this._sharedSvc.error(value);
        });
      }
      if (e?.error?.errorMessage) {
        this._sharedSvc.error(e?.error?.error);
      }
    },
    // complete: () => console.info('complete')
  })
  }

  getProductRulDropdown(){
    this._sharedSvc.showSpinner();

  this._productServices.get_product_rul_Dropdown().subscribe({
    next: (response) => {
      if (response) {
       console.log(response)
       this.dropdownRule = response;
        this._sharedSvc.hideSpinner();
      }
    },
    error: (e) => {
      this._sharedSvc.hideSpinner();
      if (e.status == 401) {
        this._sharedSvc.error(this._sharedSvc.authMsg);
        this._sharedSvc.logout();
        return;
      }
      if (e?.error) {
        Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
          this._sharedSvc.error(value);
        });
      }
      if (e?.error?.errorMessage) {
        this._sharedSvc.error(e?.error?.error);
      }
    },
    // complete: () => console.info('complete')
  })
  }

  getInstallmentFrequency(){
    this._sharedSvc.showSpinner();

  this._picklistService.pickListAPI(this._picklistService.pickListModel.LOAN_FREQUENCY).subscribe({
    next: (response) => {
      if (response) {
       console.log(response)
       this.dropdowngInstallmentFrequency = response;
        this._sharedSvc.hideSpinner();
      }
    },
    error: (e) => {
      this._sharedSvc.hideSpinner();
      if (e.status == 401) {
        this._sharedSvc.error(this._sharedSvc.authMsg);
        this._sharedSvc.logout();
        return;
      }
      if (e?.error) {
        Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
          this._sharedSvc.error(value);
        });
      }
      if (e?.error?.errorMessage) {
        this._sharedSvc.error(e?.error?.error);
      }
    },
    // complete: () => console.info('complete')
  })
  }


  //getAdvanceRules

  getAdvanceRules(){
    this._sharedSvc.showSpinner();
    this._productServices.get_product_rul(this.prdSeq).subscribe({
      next: (response: any) => {
        this._sharedSvc.hideSpinner();
        if (response) {
          if (response.error) {
            this._sharedSvc.error(response.error);
            return;
          }
          console.log(response)
          // this.advanceRulesList = response;
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    })
  }

}






