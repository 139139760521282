export class SaveCollateralHDR {
  // crdtCltrlHdrSeq: number;
  crdtAppSeq: number;
  crdtUpto: number;
  effctvCrdtAmnt: any;
  shrffId: number;
  shrffBgNum: number;
  brnchBgNum: number;
  crtdDt: string;
  lastUpdBy: string;
  lastUpdDt: string;
  delFlg: boolean;
  // crdtUpto: any
}
export class SaveCollateralDTL {
  crdtCltrlDtlSeq: number;
  // crdtCltrlHdrSeq: number;
  crdtAppSeq:number;
  dscr: number;
  grossWghtIncl: number;
  grossWghtExcl: number;
  gldQltyKrt: number;
  mrktByngRt: number;
  crtdBy: string;
  crtdDt: any;
  lastUpdBy: string;
  lastUpdDt: any;
  delFlg: boolean;
}
