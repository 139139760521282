import { Component, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SharedService } from "src/app/components/shared/service/shared.service";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
  NgForm,
  ValidatorFn,
  ValidationErrors,
} from "@angular/forms";
import { SetupService } from "../../shared/service/setup.service";
import { PicklistService } from "src/app/components/shared/pickList/picklist.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-refrence-group-values",
  templateUrl: "./refrence-group-values.component.html",
  styleUrls: ["./refrence-group-values.component.css"],
})
export class RefrenceGroupValuesComponent implements OnInit {
  loadingIndicator: boolean = false;
  rows: any;
  // currentDate: any = new Date().toISOString().split("T")[0];
  maskedCNIC: string;
  isEdit: boolean;
  singleRecord: any;
  grpSeq: any;
  addSetupValformData: any;

  constructor(
    private modalService: NgbModal,
    public router: Router,
    public _sharedSvc: SharedService,
    public _SetupService: SetupService,
    private formBuilder: FormBuilder,
    public _picklistService: PicklistService,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.grpSeq = sessionStorage.getItem("singleGrpSeqID");
    this._SetupService.get_ref_cd_val_list(this.grpSeq);
    this._picklistService.WORK_STATUS();
    this.createSetupValForm();
  }
  createSetupValForm() {
    this.addSetupValformData = this.formBuilder.group({
      refCdDscr: ["", Validators.required],
      sortOrder: ["", [Validators.required, this.isSortOrderExist()]],
      refCdActvFlg: ["", Validators.required],
    });
  }
  get refCdDscr() {
    return this.addSetupValformData.get("refCdDscr");
  }
  get sortOrder() {
    return this.addSetupValformData.get("sortOrder");
  }
  get refCdActvFlg() {
    return this.addSetupValformData.get("refCdActvFlg");
  }

  isSortOrderExist(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = parseInt(control.value);
      if (!value || value == this.singleRecord?.sortOrder) {
        return null;
      }
      let list = this._SetupService.refCdValList;
      let record = list?.find((x) => x.sortOrder === value);
      return record ? { isSortOrderExist: true } : null;
    };
  }
  submitSetupValForm(val) {
    if (this.addSetupValformData.invalid) {
      for (const control of Object.keys(this.addSetupValformData.controls)) {
        this.addSetupValformData.controls[control].markAsTouched();
      }
      return;
    }
    let data = {
      tntKey: 1,
      ...this.addSetupValformData.value,
      grpSeq: this.grpSeq,
      refCdSeq: null,
      delFlg: 0,
    };
    data.refCdActvFlg = data.refCdActvFlg === "true" ? 1 : 0;
    if (this.isEdit) {
      data.refCdSeq = this.singleRecord.refCdSeq;
      data.refCd = this.singleRecord.refCd;
    }
    this._SetupService.setupReferanceModel = data;
    this._sharedSvc.showSpinner3();
    this._SetupService.saveRefCdValue().subscribe({
      next: (response) => {
        if (response) {
          this._sharedSvc.success(
            this.translate.instant("toast.codeValue") +
            (this.isEdit === true
              ? this.translate.instant("toast.updated")
              : this.translate.instant("toast.added"))
          );
          this._sharedSvc.hideSpinner3();
          this.modalService.dismissAll();
          this.addSetupValformData.reset();
          this._SetupService.get_ref_cd_val_list(this.grpSeq);
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner3();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(
            ([key, value]: any, index: number) => {
              this._sharedSvc.error(value);
            }
          );
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    });
  }

  editSetupVal(content: any, id: any, edit: any) {
    let list = this._SetupService.refCdValList;
    let record = list.find((x) => x.refCdSeq === id);
    this.singleRecord = record;
    if (record) {
      let data = {
        refCdDscr: record.refCdDscr,
        sortOrder: record.sortOrder,
        refCdActvFlg: record.refCdActvFlg === true ? this.translate.instant('toast.true') : this.translate.instant('toast.false'),
      };
      this.addSetupValformData.patchValue(data);
      this.openModal(content, edit);
    }
  }

  deleteRecord(id) {
    let list = this._SetupService.refCdValList;
    let record = list.find((x) => x.refCdSeq === id);
    this.singleRecord = record;

    if (record) {
      let data = {
        ...record,
        delFlg: 1,
      };
      this._sharedSvc.showSpinner3();
      this._SetupService.setupReferanceModel = data;
      this._SetupService.saveRefCdValue().subscribe({
        next: (response) => {
          if (response) {
            this._sharedSvc.success(
              this.translate.instant("toast.codeValueDeleted")
            );
            this._sharedSvc.hideSpinner3();
            this.modalService.dismissAll();
            this._SetupService.get_ref_cd_val_list(this.grpSeq);
          }
        },
        error: (e) => {
          this._sharedSvc.hideSpinner3();
          if (e.status == 401) {
            this._sharedSvc.error(this._sharedSvc.authMsg);
            this._sharedSvc.logout();
            return;
          }
          if (e?.error) {
            Object.entries(e?.error).forEach(
              ([key, value]: any, index: number) => {
                this._sharedSvc.error(value);
              }
            );
          }
          if (e?.error?.errorMessage) {
            this._sharedSvc.error(e?.error?.error);
          }
        },
      });
    }
  }
  openModal(content: any, edit: boolean) {
    localStorage.removeItem("selectedOrderData");
    this.isEdit = edit;
    this.modalService.open(content, {
      // backdrop: "static",
      // keyboard: false,
      windowClass: "popupWidth800",
      // backdropClass: 'light-blue-backdrop',
      centered: true,
    });
  }

  grpSeqNav() {
    this.router.navigate(["/common-codes"]);
  }
}
