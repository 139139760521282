<section>
  <div class="row mb-3">
    <div class="col-sm-12 col-md-12 col-lg-12 marginAuto">
      <h3 class="text-center">{{ "common.summary" | translate }}</h3>
    </div>
    <div class="col-sm-12 col-md-2 col-lg-2" *ngIf="!_sharedSvc.creditInfo_FS">
      <!-- <div class="float-right">
                <button class="btn infoBtn  m-0" [class.infoBtn_bg]="!ifVerified" [class.verifiedBg]="ifVerified"
                    (click)="verifyBtn()">
                    {{verifyBtnVal}}
                </button>
            </div> -->
    </div>
  </div>
  <div class="summary-border mb-3">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="mt-3 mb-3">
          <h5>{{ "common.createdBy" | translate }}:</h5>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12 marginAuto">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
            <div class="form-floating">
              <input
                id="user"
                type="text"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius readonly-field"
                value="{{ _summarySvc.getSummary?.userId | titlecase }}"
                readonly
              />
              <label for="user">{{ "common.user" | translate }}:</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
            <div class="form-floating">
              <input
                id="reg"
                type="text"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius readonly-field"
                value="{{ _summarySvc.getSummary?.regNm }}"
                readonly
              />
              <label for="reg">{{ "common.region" | translate }}:</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
            <div class="form-floating">
              <input
                id="brnchNm"
                type="text"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius readonly-field"
                value="{{ _summarySvc.getSummary?.brnchNm }}"
                readonly
              />
              <label for="brnchNm">{{ "common.branch" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
            <div class="form-floating">
              <input
                id="areaNm"
                type="text"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius readonly-field"
                value="{{ _summarySvc.getSummary?.areaNm }}"
                readonly
              />
              <label for="areaNm">{{ "common.area" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
            <div class="form-floating">
              <input
                id="crtdDt"
                type="text"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius readonly-field"
                value="{{
                  _summarySvc.getSummary?.crtdDt | date : 'dd-MM-yyyy'
                }}"
                readonly
                *ngIf="clientSummary"
              />
              <label for="crtdDt" *ngIf="clientSummary">
                {{
                  "clientOnboarding.financialInfoTab.applicationDate"
                    | translate
                }}
              </label>
              <input
                id="crdtAppDate"
                type="text"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius readonly-field"
                value="{{
                  _summarySvc.getSummary?.crdtAppDate | date : 'dd-MM-yyyy'
                }}"
                readonly
                *ngIf="!clientSummary"
              />
              <label for="crdtAppDate" *ngIf="!clientSummary">{{
                "clientOnboarding.financialInfoTab.applicationDate" | translate
              }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="summary-border mb-3">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
        <div class="mt-3 mb-3">
          <h5>
            {{
              "clientOnboarding.financialInfoTab.clientPersonalInformation"
                | translate
            }}:
          </h5>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-2">
        <div class="summary-page btnCenter">
          <img src="https://www.w3schools.com/howto/img_avatar.png" />
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-10 marginAuto">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
            <div class="form-floating">
              <input
                id="clntNm"
                type="text"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius readonly-field"
                value="{{ _summarySvc.getSummary?.clntNm | titlecase }}"
                readonly
              />
              <label for="clntNm">{{ "common.clientName" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
            <div class="form-floating">
              <input
                id="mrtlStus"
                type="text"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius readonly-field"
                value="{{ _summarySvc.getSummary?.mrtlStus }}"
                readonly
              />
              <label for="mrtlStus">{{
                "clientOnboarding.maritalStatus" | translate
              }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
            <div class="form-floating">
              <input
                id="clntId"
                type="text"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius readonly-field"
                value="{{ _summarySvc.getSummary?.clntId | titlecase }}"
                readonly
              />
              <label for="clntId">{{ "common.clientId" | translate }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
            <div class="form-floating">
              <input
                id="occupation"
                type="text"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius readonly-field"
                value="{{ _summarySvc.getSummary?.occupation }}"
                readonly
              />
              <label for="occupation">{{
                "common.occupation" | translate
              }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
            <div class="form-floating">
              <input
                id="cnicNumFormate"
                type="text"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius readonly-field"
                value="{{ _summarySvc.cnicNumFormate }}"
                readonly
              />
              <label for="cnicNumFormate"
                >{{ "common.client" | translate }} CNIC
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="summary-border mb-3">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
        <div class="mt-3 mb-3">
          <h5>
            {{
              "clientOnboarding.financialInfoTab.householdInformation"
                | translate
            }}:
          </h5>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-3 mb-3">
            <div class="form-floating">
              <input
                id="rsdnc"
                type="text"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius readonly-field"
                value="{{ _summarySvc.getSummary?.rsdnc }}"
                readonly
              />
              <label for="rsdnc">
                {{
                  "clientOnboarding.financialInfoTab.residenceStatus"
                    | translate
                }}:</label
              >
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-9">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                <div class="form-floating">
                  <input
                    id="numOfChldrn"
                    type="text"
                    style="text-transform: capitalize"
                    autocomplete="off"
                    class="form-control input-radius readonly-field"
                    value="{{ _summarySvc.getSummary?.numOfChldrn }}"
                    readonly
                  />
                  <label for="numOfChldrn">
                    {{ "common.total" | translate }}
                    {{ "clientOnboarding.noOfChildren" | translate }}:</label
                  >
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                <div class="form-floating">
                  <input
                    id="mnthsResYears"
                    type="text"
                    style="text-transform: capitalize"
                    autocomplete="off"
                    class="form-control input-radius readonly-field"
                    value="{{ _summarySvc.mnthsResYears }}"
                    readonly
                  />
                  <label for="mnthsResYears">
                    {{
                      "clientOnboarding.financialInfoTab.residenceTenure"
                        | translate
                    }}</label
                  >
                </div>
              </div>
              <!-- <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                                <div class="form-floating">
                                    <input id="" type="text" style="text-transform: capitalize;" autocomplete="off"
                                        class="form-control input-radius readonly-field" 
                                        value="{{_summarySvc.getSummary}}" readonly>
                                    <label for="" >Total No. of Earners</label>
                                </div>
                            </div> -->
              <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                <div class="form-floating">
                  <input
                    id="hsHldMbrs"
                    type="text"
                    style="text-transform: capitalize"
                    autocomplete="off"
                    class="form-control input-radius readonly-field"
                    value="{{ _summarySvc.getSummary?.hsHldMbrs }}"
                    readonly
                  />
                  <label for="hsHldMbrs">
                    {{
                      "clientOnboarding.financialInfoTab.totalFamilyMembers"
                        | translate
                    }}</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="summary-border mb-3">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
        <div class="mt-3 mb-3">
          <h5 *ngIf="_sharedSvc.businessAppraisal_FS">
            {{
              "clientOnboarding.financialInfoTab.businessSummaryMonthly"
                | translate
            }}:
          </h5>
          <h5 *ngIf="_sharedSvc.EmployerInfo_FS">
            {{
              "clientOnboarding.financialInfoTab.employerSummaryMonthly"
                | translate
            }}:
          </h5>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="row">
          <ng-container *ngIf="_sharedSvc.businessAppraisal_FS">
            <div class="col-sm-12 col-md-6 col-lg-2 mb-3">
              <div class="form-floating">
                <input
                  id="bizIncm"
                  type="text"
                  style="text-transform: capitalize"
                  autocomplete="off"
                  class="form-control input-radius readonly-field"
                  value="{{ _summarySvc.getSummary?.bizIncm | number }}"
                  readonly
                />
                <label for="bizIncm"
                  >{{ "common.businessIncome" | translate }}:</label
                >
              </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-2 mb-3">
              <div class="form-floating">
                <input
                  id="bizExpns"
                  type="text"
                  style="text-transform: capitalize"
                  autocomplete="off"
                  class="form-control input-radius readonly-field"
                  value="{{ _summarySvc.getSummary?.bizExpns | number }}"
                  readonly
                />
                <label for="bizExpns">
                  {{
                    "clientOnboarding.financialInfoTab.businessExpense"
                      | translate
                  }}:</label
                >
              </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-2 mb-3">
              <div class="form-floating">
                <input
                  id="bizPrft"
                  type="text"
                  style="text-transform: capitalize"
                  autocomplete="off"
                  class="form-control input-radius readonly-field"
                  value="{{ _summarySvc.getSummary?.bizPrft | number }}"
                  readonly
                />
                <label for="bizPrft">
                  {{
                    "clientOnboarding.financialInfoTab.businessProfit"
                      | translate
                  }}</label
                >
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="_sharedSvc.EmployerInfo_FS">
            <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
              <div class="form-floating">
                <input
                  id="empSal"
                  type="text"
                  style="text-transform: capitalize"
                  autocomplete="off"
                  class="form-control input-radius readonly-field"
                  value="{{ _summarySvc.getSummary?.empSal | number }}"
                  readonly
                />
                <label for="empSal">
                  {{
                    "clientOnboarding.financialInfoTab.employerSalary"
                      | translate
                  }}:</label
                >
              </div>
            </div>
          </ng-container>
          <div class="col-sm-12 col-md-6 col-lg-3 mb-3">
            <div class="form-floating">
              <input
                id="prmryIncm"
                type="text"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius readonly-field"
                value="{{ _summarySvc.getSummary?.prmryIncm | number }}"
                readonly
              />
              <label for="prmryIncm">
                {{
                  "clientOnboarding.financialInfoTab.fatherSelfIncome"
                    | translate
                }}</label
              >
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-3 mb-3">
            <div class="form-floating">
              <input
                id="totPrmryIncm"
                type="text"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius readonly-field"
                value="{{ _summarySvc.getSummary?.totPrmryIncm | number }}"
                readonly
              />
              <label for="totPrmryIncm">{{
                "common.totalPrimaryIncome" | translate
              }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-3 mb-3">
            <div class="form-floating">
              <input
                id="scndIncm"
                type="text"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius readonly-field"
                value="{{ _summarySvc.getSummary?.scndIncm | number }}"
                readonly
              />
              <label for="scndIncm">
                {{ "common.secondaryIncome" | translate }}</label
              >
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-3 mb-3">
            <div class="form-floating">
              <input
                id="totIncm"
                type="text"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius readonly-field"
                value="{{ _summarySvc.getSummary?.totIncm | number }}"
                readonly
              />
              <label for="totIncm">
                {{
                  "clientOnboarding.financialInfoTab.totalIncome" | translate
                }}</label
              >
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-3 mb-3">
            <div class="form-floating">
              <input
                id="hshldExpns"
                type="text"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius readonly-field"
                value="{{ _summarySvc.getSummary?.hshldExpns | number }}"
                readonly
              />
              <label for="hshldExpns">
                {{
                  "clientOnboarding.financialInfoTab.householdExpense"
                    | translate
                }}</label
              >
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-3 mb-3">
            <div class="form-floating">
              <input
                id="ndi"
                type="text"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius readonly-field"
                value="{{ _summarySvc.getSummary?.ndi | number }}"
                readonly
              />
              <label for="ndi">{{
                "clientOnboarding.financialInfoTab.netDisposableIncome"
                  | translate
              }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-3 mb-3">
            <div class="form-floating">
              <input
                id="dbr1"
                type="text"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius readonly-field"
                value="{{ _summarySvc.getSummary?.dbr1 | number }}"
                readonly
              />
              <label for="dbr1">DBR 1</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-3 mb-3">
            <div class="form-floating">
              <input
                id="dbr2"
                type="text"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius readonly-field"
                value="{{ _summarySvc.getSummary?.dbr2 | number }}"
                readonly
              />
              <label for="dbr2">DBR 2</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-3 mb-3">
            <div class="form-floating">
              <input
                id="effMnthlyPymt"
                type="text"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius readonly-field"
                value="{{ _summarySvc.getSummary?.effMnthlyPymt | number }}"
                readonly
              />
              <label for="effMnthlyPymt">{{
                "common.effectiveMonthlyRepayment" | translate
              }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-3 mb-3">
            <div class="form-floating">
              <input
                id="effLoanSize"
                type="text"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius readonly-field"
                value="{{ _summarySvc.getSummary?.effLoanSize | number }}"
                readonly
              />
              <label for="effLoanSize">
                {{
                  "clientOnboarding.financialInfoTab.effectiveLoanSize"
                    | translate
                }}</label
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="summary-border mb-3" *ngIf="_sharedSvc.businessAppraisal_FS">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
        <div class="mt-3 mb-3">
          <h5>
            {{
              "clientOnboarding.financialInfoTab.businessInformation"
                | translate
            }}:
          </h5>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-5">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
                <div class="form-floating">
                  <input
                    id="bizSectNm"
                    type="text"
                    style="text-transform: capitalize"
                    autocomplete="off"
                    class="form-control input-radius readonly-field"
                    value="{{ _summarySvc.getSummary?.bizSectNm }}"
                    readonly
                  />
                  <label for="bizSectNm">
                    {{ "common.sector" | translate }}:</label
                  >
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
                <div class="form-floating">
                  <input
                    id="mnthInBizYears"
                    type="text"
                    style="text-transform: capitalize"
                    autocomplete="off"
                    class="form-control input-radius readonly-field"
                    value="{{ _summarySvc.mnthInBizYears }}"
                    readonly
                  />
                  <label for="mnthInBizYears">
                    {{
                      "clientOnboarding.financialInfoTab.businessTenure"
                        | translate
                    }}:</label
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-7 mb-3">
            <div class="form-floating">
              <input
                id="bizAdr"
                type="text"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius readonly-field"
                value="{{ _summarySvc.getSummary?.bizAdr }}"
                readonly
              />
              <label for="bizAdr">{{
                "common.businessAddress" | translate
              }}</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-5">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
                <div class="form-floating">
                  <input
                    id="runBiz"
                    type="text"
                    style="text-transform: capitalize"
                    autocomplete="off"
                    class="form-control input-radius readonly-field"
                    value="{{ _summarySvc.getSummary?.runBiz }}"
                    readonly
                  />
                  <label for="runBiz">
                    {{
                      "clientOnboarding.financialInfoTab.personRunningBusiness"
                        | translate
                    }}</label
                  >
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
                <div class="form-floating">
                  <input
                    id="bizOwn"
                    type="text"
                    style="text-transform: capitalize"
                    autocomplete="off"
                    class="form-control input-radius readonly-field"
                    value="{{ _summarySvc.getSummary?.bizOwn }}"
                    readonly
                  />
                  <label for="bizOwn">
                    {{
                      "clientOnboarding.financialInfoTab.businessOwner"
                        | translate
                    }}</label
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-7">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                <div class="form-floating">
                  <input
                    id="hshldExpns2"
                    type="text"
                    style="text-transform: capitalize"
                    autocomplete="off"
                    class="form-control input-radius readonly-field"
                    value="{{ _summarySvc.getSummary?.hshldExpns | number }}"
                    readonly
                  />
                  <label for="hshldExpns2">
                    {{
                      "clientOnboarding.financialInfoTab.householdExpense"
                        | translate
                    }}</label
                  >
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                <div class="form-floating">
                  <input
                    id="ndi2"
                    type="text"
                    style="text-transform: capitalize"
                    autocomplete="off"
                    class="form-control input-radius readonly-field"
                    value="{{ _summarySvc.getSummary?.ndi | number }}"
                    readonly
                  />
                  <label for="ndi2">
                    {{
                      "clientOnboarding.financialInfoTab.netDisposableIncome"
                        | translate
                    }}</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="summary-border mb-3" *ngIf="_sharedSvc.EmployerInfo_FS">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
        <div class="mt-3 mb-3">
          <h5>
            {{
              "clientOnboarding.employerInfoTab.employerInformation" | translate
            }}
          </h5>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
            <div class="form-floating">
              <input
                id="emplNm"
                type="text"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius readonly-field"
                value="{{ _summarySvc.getSummary?.emplNm | titlecase }}"
                readonly
              />
              <label for="emplNm"
                >{{ "common.employerName" | translate }}:</label
              >
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
            <div class="form-floating">
              <input
                id="dsgntn"
                type="text"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius readonly-field"
                value="{{ _summarySvc.getSummary?.dsgntn }}"
                readonly
              />
              <label for="dsgntn"
                >{{ "common.designation" | translate }}:</label
              >
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
            <div class="form-floating">
              <input
                id="_phNum"
                type="text"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius readonly-field"
                value="{{ _summarySvc._phNum }}"
                readonly
              />
              <label for="_phNum">Employer Phone Number</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
            <div class="form-floating">
              <input
                id="emplStartDate"
                type="text"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius readonly-field"
                value="{{ _summarySvc.getSummary?.emplStartDate }}"
                readonly
              />
              <label for="emplStartDate">Job Start Date</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-8 mb-3">
            <div class="form-floating">
              <input
                id="cmpnyAddr"
                type="text"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius readonly-field"
                value="{{ _summarySvc.getSummary?.cmpnyAddr }}"
                readonly
              />
              <label for="cmpnyAddr">Company Address</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="summary-border mb-3" *ngIf="_sharedSvc.creditInfo_FS">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
        <div class="mt-3 mb-3">
          <h5>{{ "common.creditInformation" | translate }}</h5>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-3 mb-3">
            <div class="form-floating">
              <input
                id="productName"
                type="text"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius readonly-field"
                value="{{ _summarySvc.getSummary?.productName | titlecase }}"
                readonly
              />
              <label for="productName"
                >{{ "common.product" | translate }} :</label
              >
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-3 mb-3">
            <div class="form-floating">
              <input
                id="srvcChargeAmount"
                type="text"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius readonly-field"
                value="{{ _summarySvc.getSummary?.srvcChargeAmount | number }}"
                readonly
              />
              <label for="srvcChargeAmount">Serving Charges:</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-3 mb-3">
            <div class="form-floating">
              <input
                id="prevCrdtAmount"
                type="text"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius readonly-field"
                value="{{ _summarySvc.getSummary?.prevCrdtAmount | number }}"
                readonly
              />
              <label for="prevCrdtAmount">Previous Credit Amount</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-3 mb-3">
            <div class="form-floating">
              <input
                id="instNumber"
                type="text"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius readonly-field"
                value="{{ _summarySvc.getSummary?.instNumber | number }}"
                readonly
              />
              <label for="instNumber">No. of Installments</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-3 mb-3">
            <div class="form-floating">
              <input
                id="cycleNumber"
                type="text"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius readonly-field"
                value="{{ _summarySvc.getSummary?.cycleNumber | number }}"
                readonly
              />
              <label for="cycleNumber">Credit Cycle</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-3 mb-3">
            <div class="form-floating">
              <input
                id="rqstdAmount"
                type="text"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius readonly-field"
                value="{{ _summarySvc.getSummary?.rqstdAmount | number }}"
                readonly
              />
              <label for="rqstdAmount">Requested Amount</label>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-3 mb-3">
            <div class="form-floating">
              <input
                id="rcmndAmount"
                type="text"
                style="text-transform: capitalize"
                autocomplete="off"
                class="form-control input-radius readonly-field"
                value="{{ _summarySvc.getSummary?.rcmndAmount | number }}"
                readonly
              />
              <label for="rcmndAmount">Recommended Amount</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="">
    <form
      *ngIf="clientSummary"
      [formGroup]="clientSummaryForm"
      
    >
      <div class="summary-border mb-3">
        <div class="mt-3 mb-3">
          <h5>{{ "common.comments" | translate }}:</h5>
        </div>
        <textarea
          name="cmntsClient"
          id="cmntsClient"
          formControlName="cmntsClient"
          class="form-control input-radius"
          autocomplete="off"
          [ngClass]="cmntsClient.invalid ? 'requiredInput' : ''"
          [class.is-invalid]="
            cmntsClient?.invalid && (cmntsClient?.dirty || cmntsClient?.touched)
          "
        ></textarea>
        <!-- <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">

                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <textarea class="form-control" name="cmnts" [ngModelOptions]="{ standalone: true }"
                            [(ngModel)]="_summarySvc.submitSummaryModel.cmnts"></textarea>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <textarea class="form-control" name="cmnts" formControlName="cmnts"></textarea>

                    </div>

                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <textarea formControlName="cmnts" autocomplete="off" class="form-control input-radius"
                            [class.is-invalid]="cmnts.invalid && (cmnts.dirty || cmnts.touched)">
                    <div *ngIf="cmnts.invalid && (cmnts.dirty || cmnts.touched)" class="invalid-feedback">
                        <div *ngIf="cmnts.errors?.['required']">
                           Comments required.
                        </div>
                    </div>
                    </div>

                </div> -->
        <div
          *ngIf="
            cmntsClient?.invalid && (cmntsClient?.dirty || cmntsClient?.touched)
          "
          class="invalid-feedback"
        >
          <div *ngIf="cmntsClient?.errors?.['required']">
            Comments required.
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div
            class="button_div"
            *ngIf="_conditionsService.readOnlyView_Client && userId === 'vousr'"
          >
            <button
              class="btn login-button-class"
              [class.pointerNone]="_sharedSvc.loading"
              [disabled]="_sharedSvc.loading"
              (click)="onSubmitStatus(702)"
            >
            Approved
            </button>
            <button
              class="btn login-button-class cancelBtn"
              (click)="onSubmitStatus(701)"
              type="reset"
            >
              Declined
            </button>
          </div>
          <div
            class="button_div"
            *ngIf="!_conditionsService.readOnlyView_Client"
          >
            <button
              class="btn login-button-class"
              [class.pointerNone]="_sharedSvc.loading"
              [disabled]="_sharedSvc.loading"
              (click)="onSubmit()"
            >
              {{ "common.submit" | translate }}
            </button>
            <button
              class="btn login-button-class cancelBtn"
              (click)="onCancelSm()"
              type="reset"
            >
              {{ "common.cancel" | translate }}
            </button>
          </div>
        </div>
      </div>
    </form>
    <form *ngIf="!clientSummary" [formGroup]="creditSummaryForm">
      <div class="summary-border mb-3">
        <textarea
          name="cmntsCredit"
          id="cmntsCredit"
          formControlName="cmntsCredit"
          class="form-control input-radius"
          autocomplete="off"
          [ngClass]="cmntsCredit?.invalid ? 'requiredInput' : ''"
          [class.is-invalid]="
            cmntsCredit?.invalid && (cmntsCredit?.dirty || cmntsCredit?.touched)
          "
        ></textarea>
        <div
          *ngIf="
            cmntsCredit?.invalid && (cmntsCredit?.dirty || cmntsCredit?.touched)
          "
          class="invalid-feedback"
        >
          <div *ngIf="cmntsCredit?.errors?.['required']">
            Comments required.
          </div>
        </div>
        <!-- <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="mt-3 mb-3">
                            <h5>Comments:</h5>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <textarea class="form-control" name="cmnts" [ngModelOptions]="{ standalone: true }"
                            [(ngModel)]="_summarySvc.submitCreditModel.cmnts"></textarea>
                    </div>
                </div> -->
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div
            class="button_div"
            *ngIf="!_conditionsService.readOnlyView_Client"
          >
            <button
              class="btn login-button-class"
              [class.pointerNone]="_sharedSvc.loading"
              [disabled]="_sharedSvc.loading"
              (click)="onSubmitCredit()"
            >
              {{ "common.submit" | translate }}
            </button>
            <button
              class="btn login-button-class cancelBtn"
              (click)="onCancelCredit()"
              type="reset"
            >
              {{ "common.cancel" | translate }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>
