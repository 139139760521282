export class OtherStanding {
    tenetKey:  number;
    clntSeq:  number;
    othOutsdCrdtSeq:  number;
    instnNm: string;
    totCrdtAmt:  number | null;
    crdtPrps: string;
    crntOutsdAmt:  number | null;
    cmplDt: any;
    mnthExpFlg: boolean;
    mfcibFlg: boolean;
    crtdBy: string;
    crtdDt: any;
    lastUpdBy: string;
    lastUpdDt: any;
    delFlg: boolean;
}