import { SharedService } from "src/app/components/shared/service/shared.service";
import { SortbyPipePipe } from "src/app/components/shared/service/sortby-pipe.pipe";
import { HttpClient } from '@angular/common/http';
import { environment } from "src/app/global";
import { Endpoint } from "src/app/components/shared/serviceEndPoints";
import { saveProductChrgModel, saveProductChrgSlbModel, saveProductChrgTrmModel, saveProductGrpModel, saveProductModel, saveProductPalModel, saveProductRulModel, saveProductSgrtInstModel } from "../model/setup";
import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
  })

export class ProductServices {

    saveProductModel: any = new saveProductModel();
    saveProductSgrtInstModel:any = new saveProductSgrtInstModel();
    saveProductRulModel:any = new saveProductRulModel();
    saveProductPalModel:any = new saveProductPalModel();
    saveProductGrpModel:any = new saveProductGrpModel();
    saveProductChrgModel:any = new saveProductChrgModel();
    saveProductChrgTrmModel:any = new saveProductChrgTrmModel();
    saveProductChrgSlbModel:any = new saveProductChrgSlbModel();


    constructor(
        private _http: HttpClient,
        public _sharedSvc: SharedService,
        private sortPipe: SortbyPipePipe
      ) { }




      //get Apis

      get_product_details(prd_seq:number) {
        this._sharedSvc.tokenHeader();
        return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.product.get_product_details, this._sharedSvc.requestOptions);
      }
      get_product_rul(prdseq:number) {
        this._sharedSvc.tokenHeader();
        return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.product.get_product_rul + prdseq, this._sharedSvc.requestOptions);
      }
      get_product_grp(prdGrpseq:number) {
        this._sharedSvc.tokenHeader();
        return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.product.get_product_grp, this._sharedSvc.requestOptions);
      }
      get_product_chrg(prdseq:number) {
        this._sharedSvc.tokenHeader();
        return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.product.get_product_chrg, this._sharedSvc.requestOptions);
      }
      get_product_chrg_trm(prdseq:number) {
        this._sharedSvc.tokenHeader();
        return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.product.get_product_chrg_trm, this._sharedSvc.requestOptions);
      }
      get_product_chrg_slb(chrgSeq:number) {
        this._sharedSvc.tokenHeader();
        return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.product.get_product_chrg_slb, this._sharedSvc.requestOptions);
      }
      get_product(prdSeq:number) {
        this._sharedSvc.tokenHeader();
        return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.product.get_product + prdSeq, this._sharedSvc.requestOptions);
      }
      get_product_sgrt_inst(prdSeq:number) {
        this._sharedSvc.tokenHeader();
        return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.product.get_porduct_sgrt_inst, this._sharedSvc.requestOptions);
      }
      get_product_pal(prdSeq:number) {
        this._sharedSvc.tokenHeader();
        return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.product.get_product_pal, this._sharedSvc.requestOptions);
      }
      get_product_sgrt_inst_list() {
        this._sharedSvc.tokenHeader();
        return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.product.get_product_sgrt_inst_list, this._sharedSvc.requestOptions);
      }
      get_product_rul_list() {
        this._sharedSvc.tokenHeader();
        return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.product.get_product_rul_list, this._sharedSvc.requestOptions);
      }
      get_product_pal_list() {
        this._sharedSvc.tokenHeader();
        return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.product.get_product_pal_list, this._sharedSvc.requestOptions);
      }
      get_product_list() {
        this._sharedSvc.tokenHeader();
        return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.product.get_product_list, this._sharedSvc.requestOptions);
      }
      get_product_grp_list() {
        this._sharedSvc.tokenHeader();
        return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.product.get_product_grp_list, this._sharedSvc.requestOptions);
      }
      get_product_chrg_trm_list() {
        this._sharedSvc.tokenHeader();
        return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.product.get_product_chrg_trm_list, this._sharedSvc.requestOptions);
      }
      get_product_chrg_slb_list() {
        this._sharedSvc.tokenHeader();
        return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.product.get_product_chrg_slb_list, this._sharedSvc.requestOptions);
      }
      get_product_chrg_list() {
        this._sharedSvc.tokenHeader();
        return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.product.get_product_chrg_list, this._sharedSvc.requestOptions);
      }
      get_product_rul_Dropdown() {
        this._sharedSvc.tokenHeader();
        return this._http.get<any>(environment.config.BASE_API_URL_pickList + Endpoint.Setup.get_rul_list, this._sharedSvc.requestOptions);
      }

      //post APIS

      save_product() {
        this._sharedSvc.tokenHeader();
        return this._http.post<any>(environment.config.BASE_API_URL_pickList + Endpoint.product.save_product, this.saveProductModel, this._sharedSvc.requestOptions)
      }
      save_product_sgrt_inst() {
        this._sharedSvc.tokenHeader();
        return this._http.post<any>(environment.config.BASE_API_URL_pickList + Endpoint.product.save_product_sgrt_inst, this.saveProductSgrtInstModel, this._sharedSvc.requestOptions)
      }
      save_product_rul() {
        this._sharedSvc.tokenHeader();
        return this._http.post<any>(environment.config.BASE_API_URL_pickList + Endpoint.product.save_product_rul, this.saveProductRulModel, this._sharedSvc.requestOptions)
      }
      save_product_pal() {
        this._sharedSvc.tokenHeader();
        return this._http.post<any>(environment.config.BASE_API_URL_pickList + Endpoint.product.save_product_pal, this.saveProductPalModel, this._sharedSvc.requestOptions)
      }
      save_product_grp() {
        this._sharedSvc.tokenHeader();
        return this._http.post<any>(environment.config.BASE_API_URL_pickList + Endpoint.product.save_product_grp, this.saveProductGrpModel, this._sharedSvc.requestOptions)
      }
      save_product_chrg() {
        this._sharedSvc.tokenHeader();
        return this._http.post<any>(environment.config.BASE_API_URL_pickList + Endpoint.product.save_product_chrg, this.saveProductChrgModel, this._sharedSvc.requestOptions)
      }
      save_product_chrg_trm() {
        this._sharedSvc.tokenHeader();
        return this._http.post<any>(environment.config.BASE_API_URL_pickList + Endpoint.product.save_product_chrg_trm, this.saveProductChrgTrmModel, this._sharedSvc.requestOptions)
      }
      save_product_chrg_slb() {
        this._sharedSvc.tokenHeader();
        return this._http.post<any>(environment.config.BASE_API_URL_pickList + Endpoint.product.save_product_chrg_slb, this.saveProductChrgSlbModel, this._sharedSvc.requestOptions)
      }


      
}
