import { Component, OnInit, DestroyRef, inject } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SharedService } from "../../shared/service/shared.service";
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
} from "@angular/router";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  NgForm,
} from "@angular/forms";
import { ClientDocsService } from "./shared/service/client-docs.service";
import { SharedPopupService } from "../../shared/service/shared-popup.service";
import { PicklistService } from "../../shared/pickList/picklist.service";
import {
  DataUrl,
  DOC_ORIENTATION,
  NgxImageCompressService,
  UploadResponse,
} from "ngx-image-compress";
import { RoutingConditionsService } from "../shared/service/routing-conditions.service";
import { ClintOnboardingService } from "../shared/service/clint-onboarding.service";
import { TranslateService } from "@ngx-translate/core";
interface IDoc {
  clntDocSeq: number;
  clntSeq: number;
  entySeq: number;
  entyFlag: number;
  docSeq: number;
  docImg: string;
  delFlg: boolean;
}
@Component({
  selector: "app-client-docs",
  templateUrl: "./client-docs.component.html",
  styleUrls: ["./client-docs.component.css"],
})
export class ClientDocsComponent implements OnInit {
  docForm: FormGroup;
  doc: IDoc;
  modal1: any;
  modal2: any;
  modal3: any;
  modal4: any;
  saveCnicData: any;
  invalid: boolean = false;
  cnicFUrl: any = "";
  cnicBUrl: any = "";
  userUrl: any = "";
  repUrl: any = "";
  imgResultBeforeCompress: DataUrl = "";
  imgResultAfterCompress: DataUrl = "";
  src: any;
  imgSizeAfter: any;
  imgSizeBefore: any;
  uploadMsg: any;
  base64: any;
  baseWithout64: any;
  clntSeq: any;
  imgPath: boolean = false;
  modelHeading: any;
  clientDocList: any = [];

  #destroyRef = inject(DestroyRef);

  constructor(
    public router: Router,
    public _sharedSvc: SharedService,
    public _clientDocsSvc: ClientDocsService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    public _sharedPopupSvc: SharedPopupService,
    public _picklistService: PicklistService,
    public route: ActivatedRoute,
    private imageCompress: NgxImageCompressService,
    public _conditionsService: RoutingConditionsService,
    public _clientOnBrdService: ClintOnboardingService,
    public translate: TranslateService
  ) {
    this.#destroyRef.onDestroy(() => {
      // localStorage.setItem("clientDocImg", JSON.stringify(this.clientDocList));
      localStorage.setItem(
        "clientDocImg",
        JSON.stringify({
          model1: this.modal1,
          model2: this.modal2,
          model3: this.modal3,
          model4: this.modal4,
        })
      );
      sessionStorage.setItem(
        "clientDocImg",
        JSON.stringify({
          model1: this.modal1,
          model2: this.modal2,
          model3: this.modal3,
          model4: this.modal4,
        })
      );
    });
  }

  ngOnInit(): void {
    
    if (localStorage.getItem("readOnlyView_Client")) {
      this._conditionsService.readOnlyView_Client = true;
    }
    if (localStorage.getItem("clntSeq")) {
      this.clntSeq = localStorage.getItem("clntSeq");
      this.clntSeq = JSON.parse(this.clntSeq);
      this.InItGetAllClientDocs(this.clntSeq);
    }
    if (localStorage.getItem("save_ClientModel")) {
      this.saveCnicData = localStorage.getItem("save_ClientModel");
    }
    if (localStorage.getItem("SaveCnicData")) {
      this.saveCnicData = localStorage.getItem("SaveCnicData");
    }
    this.saveCnicData = JSON.parse(this.saveCnicData);
    this.clntSeq = this.saveCnicData?.clntSeq;
    if (this.route.snapshot.paramMap.get("id")) {
      this.InItGetAllClientDocs(this.saveCnicData?.clntSeq);
    }
    if (localStorage.getItem("clientDocImg")) {
      this.docLSModel = localStorage.getItem("clientDocImg");
      this.docLSModel = JSON.parse(this.docLSModel);
      this.cnicFUrl = this.docLSModel.cnicFUrl;
      this.cnicBUrl = this.docLSModel.cnicBUrl;
      this.userUrl = this.docLSModel.userUrl;
      this.repUrl = this.docLSModel.repUrl;
    }
  }

  openModal(content: any, h4: any) {
    this.modelHeading = h4;
    this.modalService.open(content, {
      // backdrop: "static",
      // keyboard: false,
      windowClass: "popupWidth1k",
      backdropClass: "light-blue-backdrop",
      centered: true,
    });
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  setD_V() {
    this._clientDocsSvc.saveDocsModel = {
      clntDocSeq: 0,
      clntSeq: this.clntSeq,
      entySeq: this.clntSeq,
      entyFlag: 0,
      docSeq: 0,
      docImg: "",
      delFlg: false,
    };
  }

  compressFile(url: any) {
    
    this.setD_V();
    return this.imageCompress
      .uploadFile()
      .then(({ image, orientation, fileName }: UploadResponse) => {
        
        this.imgResultBeforeCompress = image;
        // console.warn('File Name:', fileName);
        // console.warn(
        //   `Original: ${image.substring(0, 50)}... (${image.length} characters)`
        // );

        if (url == "cnicFUrl") {
          this.cnicFUrl = image;
        }
        if (url == "cnicBUrl") {
          this.cnicBUrl = image;
        }
        if (url == "userUrl") {
          this.userUrl = image;
        }
        if (url == "repUrl") {
          this.repUrl = image;
        }
        // if (image.length > 500000) { this.s1 = 50; } // 0.5Mb
        // if (image.length > 1000000) { this.s1 = 30; } // 1MB
        // if (image.length > 5000000) { this.s1 = 10; } // 5MB
        // if (image.length > 10000000) { this.s1 = 1; } // 10MB
        // if (image.length > 50000000) { this.s1 = 0.3; } // 50MB
        // console.warn('Size in bytes was:', this.imageCompress.byteCount(image));
        this.imageCompress
          .compressFile(image, orientation, 50, 50)
          .then((result: DataUrl) => {
            
            this.imgResultAfterCompress = result;
            this._sharedSvc.loading3 = true;
            // console.warn(
            //   `Compressed: ${result.substring(0, 50)}... (${result.length
            //   } characters)`
            // );
            // console.warn(
            //   'Size in bytes is now:',
            //   this.imageCompress.byteCount(result)
            // );
            this.clientDocList.push(this._clientDocsSvc.saveDocsModel);
            if (url == "cnicFUrl") {
              // this.cnicFUrl = result;
              this._clientDocsSvc.saveDocsModel.docSeq = 2;
              this._clientDocsSvc.saveDocsModel.docImg = result;
              this.modal1 = this._clientDocsSvc.saveDocsModel;
              sessionStorage.setItem(
                "modal1",
                JSON.stringify(this._clientDocsSvc.saveDocsModel)
              );
              this.uploadMsg = "CNIC Front Image Upload";

              this.onSubmit();
            }
            if (url == "cnicBUrl") {
              // this.cnicBUrl = result;
              this._clientDocsSvc.saveDocsModel.docImg = result;
              this._clientDocsSvc.saveDocsModel.docSeq = 3;
              this.modal2 = this._clientDocsSvc.saveDocsModel;
              this.uploadMsg = "CNIC Back Image Upload";
              this.onSubmit();
            }
            if (url == "userUrl") {
              // this.userUrl = result;
              this._clientDocsSvc.saveDocsModel.docImg = result;
              this._clientDocsSvc.saveDocsModel.docSeq = 1;
              this.modal3 = this._clientDocsSvc.saveDocsModel;
              this.uploadMsg = "User Image Upload";
              this.onSubmit();
            }
            if (url == "repUrl") {
              // this.repUrl = result;
              this._clientDocsSvc.saveDocsModel.docImg = result;
              this._clientDocsSvc.saveDocsModel.docSeq = 10;
              this.modal4 = this._clientDocsSvc.saveDocsModel;
              this.uploadMsg = "Tasdeeq Consent Image Upload";
              this.onSubmit();
            }
          });
      });
  }

  public delete(url: any) {
    if (url == "cnicFUrl") {
      this.cnicFUrl = "";
    }
    if (url == "cnicBUrl") {
      this.cnicBUrl = "";
    }
    if (url == "userUrl") {
      this.userUrl = "";
    }
    if (url == "repUrl") {
      this.repUrl = "";
    }
  }
  docLSModel: any;

  onSubmit(): void {
    // if (this.cnicFUrl && this.cnicBUrl && this.userUrl && this.repUrl) {
    // } else {
    //   this._sharedSvc.error("Please upload all images");
    //   this.invalid = true;
    //   return;
    // }

    if (this._clientDocsSvc.getDoc?.length > 0) {
      
      for (let i = 0; i < this._clientDocsSvc.getDoc.length; i++) {
        
        if (this._clientDocsSvc.getDoc[i].docSeq == 2) {
          this.modal1.clntDocSeq = this._clientDocsSvc.getDoc[i]?.clntDocSeq;
        }
        if (this._clientDocsSvc.getDoc[i].docSeq == 3) {
          this.modal2.clntDocSeq = this._clientDocsSvc.getDoc[i]?.clntDocSeq;
        }
        if (this._clientDocsSvc.getDoc[i].docSeq == 1) {
          this.modal3.clntDocSeq = this._clientDocsSvc.getDoc[i]?.clntDocSeq;
        }
        if (this._clientDocsSvc.getDoc[i].docSeq == 10) {
          this.modal4.clntDocSeq = this._clientDocsSvc.getDoc[i]?.clntDocSeq;
        }
      }
    }
    // for (let i = 0; i < 4; i++) {
    //   if (i == 0) {
    //     if (this.modal1) {
    //       this._clientDocsSvc.saveDocsModel = this.modal1;
    //     }
    //   }
    //   if (i == 1) {
    //     if (this.modal2) {
    //       this._clientDocsSvc.saveDocsModel = this.modal2;
    //     }
    //   }
    //   if (i == 2) {
    //     if (this.modal3) {
    //       this._clientDocsSvc.saveDocsModel = this.modal3;
    //     }
    //   }
    //   if (i == 3) {
    //     if (this.modal4) {
    //       this._clientDocsSvc.saveDocsModel = this.modal4;

    //     }
    //   }
    this._sharedSvc.showSpinner3();
    this._clientDocsSvc.SaveDocs().subscribe({
      next: (response) => {
        if (response) {
          
          this.imgPath = true;
          this.modalService.dismissAll();
          this._sharedSvc.success(this.uploadMsg);

          this.docLSModel = {
            cnicFUrl: this.cnicFUrl,
            cnicBUrl: this.cnicBUrl,
            userUrl: this.userUrl,
            repUrl: this.repUrl,
          };
          localStorage.setItem("clientDocImg", JSON.stringify(this.docLSModel));
          this._sharedSvc.hideSpinner3();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner3();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(
            ([key, value]: any, index: number) => {
              this._sharedSvc.error(value);
            }
          );
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => console.info('complete')
    });
    // }
  }

  InItGetAllClientDocs(clnt_seq: any): void {
    this._sharedSvc.showSpinner2();
    this._clientDocsSvc.getAllClientDocs(clnt_seq, 0).subscribe({
      next: (response) => {
        if (response) {
          console.log("called for doc");

          this.imgPath = true;
          this._clientDocsSvc.getDoc = response;
          
          //this._clientDocsSvc.clientAllDocs = response;
          this.setD_V();
          for (let i = 0; i < this._clientDocsSvc.getDoc.length; i++) {
            if (this._clientDocsSvc.getDoc[i].docSeq == 2) {
              this.cnicFUrl = this._clientDocsSvc.getDoc[i]?.docImg;
              this._clientDocsSvc.saveDocsModel.docSeq = 2;
              this._clientDocsSvc.saveDocsModel.docImg = this.cnicFUrl;
              this._clientDocsSvc.saveDocsModel.clntDocSeq =
                this._clientDocsSvc.getDoc[i]?.clntDocSeq;
              this.modal1 = this._clientDocsSvc.saveDocsModel;
              this.cnicFUrl = "data:image/png;base64," + this.cnicFUrl;
            }
            if (this._clientDocsSvc.getDoc[i].docSeq == 3) {
              this.cnicBUrl = this._clientDocsSvc.getDoc[i]?.docImg;
              this._clientDocsSvc.saveDocsModel.docImg = this.cnicBUrl;
              this._clientDocsSvc.saveDocsModel.docSeq = 3;
              this._clientDocsSvc.saveDocsModel.clntDocSeq =
                this._clientDocsSvc.getDoc[i]?.clntDocSeq;
              this.modal2 = this._clientDocsSvc.saveDocsModel;
              this.cnicBUrl = "data:image/png;base64," + this.cnicBUrl;
            }
            if (this._clientDocsSvc.getDoc[i].docSeq == 1) {
              this.userUrl = this._clientDocsSvc.getDoc[i]?.docImg;
              this._clientDocsSvc.saveDocsModel.docImg = this.userUrl;
              this._clientDocsSvc.saveDocsModel.docSeq = 1;
              this._clientDocsSvc.saveDocsModel.clntDocSeq =
                this._clientDocsSvc.getDoc[i]?.clntDocSeq;
              this.modal3 = this._clientDocsSvc.saveDocsModel;
              this.userUrl = "data:image/png;base64," + this.userUrl;
            }
            if (this._clientDocsSvc.getDoc[i].docSeq == 4) {
              this.repUrl = this._clientDocsSvc.getDoc[i]?.docImg;
              this._clientDocsSvc.saveDocsModel.docImg = this.repUrl;
              this._clientDocsSvc.saveDocsModel.docSeq = 4;
              this._clientDocsSvc.saveDocsModel.clntDocSeq =
                this._clientDocsSvc.getDoc[i]?.clntDocSeq;
              this.modal4 = this._clientDocsSvc.saveDocsModel;
              this.repUrl = "data:image/png;base64," + this.repUrl;
            }
            if (this._clientDocsSvc.getDoc[i].docSeq == 10) {
              this.repUrl = this._clientDocsSvc.getDoc[i]?.docImg;
              this._clientDocsSvc.saveDocsModel.docImg = this.repUrl;
              this._clientDocsSvc.saveDocsModel.docSeq = 10;
              this._clientDocsSvc.saveDocsModel.clntDocSeq =
                this._clientDocsSvc.getDoc[i]?.clntDocSeq;
              this.modal4 = this._clientDocsSvc.saveDocsModel;
              this.repUrl = "data:image/png;base64," + this.repUrl;
            }
          }
          this._sharedSvc.hideSpinner2();
        }
      },
      error: (e) => {
        this._sharedSvc.hideSpinner2();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(
            ([key, value]: any, index: number) => {
              this._sharedSvc.error(value);
            }
          );
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
      // complete: () => {
      //   this._sharedSvc.hideSpinner();
      // }
    });
  }

  continue() {
    if (this.cnicFUrl && this.cnicBUrl && this.userUrl && this.repUrl) {
    } else {
      this._sharedSvc.error(this.translate.instant('toast.uploadAllImages'));
      this.invalid = true;
      return;
    }
    this._sharedSvc.active = 4;
  }
  continueForCheck() {
    // let temp = localStorage.getItem("SaveCnicData");
    // if (temp) {
    //   let clnt_seq = JSON.parse(temp).clntSeq;
    //   this._clientDocsSvc.getAllClientDocs(clnt_seq, 0).subscribe({
    //     next: response => {
    //       if (response) {
    //         this._sharedSvc.hideSpinner2();
    //         sessionStorage.setItem('clientDocs', JSON.stringify(response))
    //         console.log("Docs", JSON.parse(sessionStorage.getItem('clientDocs')));
    //       }
    //     },
    //     error: e => {
    //       this._sharedSvc.hideSpinner2();
    //       if (e.status == 401) {
    //         this._sharedSvc.error(this._sharedSvc.authMsg);
    //         this._sharedSvc.logout();
    //         return;
    //       }
    //       if (e?.error) {
    //         Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
    //           this._sharedSvc.error(value);
    //         });
    //       }
    //       if (e?.error?.errorMessage) {
    //         this._sharedSvc.error(e?.error?.error);
    //       }
    //     },
    //   });
    // } else {
    //   sessionStorage.setItem('clientDocs', null)
    // }
  }
}
