import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DeletePopupComponent } from '../popup/delete-popup/delete-popup.component';
import { AlertComponent } from '../popup/alert/alert.component';

@Injectable({
  providedIn: 'root'
})
export class SharedPopupService {

  constructor(private modalService: NgbModal) { }

  public popup(
    title : any,
    message: any,
    componentToOpen : "",
    btnOkText : string = 'Save',
    btnCancelText : string = 'Cancel',
    btnDiscardChange : any = null,
    contractNametoDelete : any = null,
    customValue : any = null,
    dialogSize : string = 'md',
    
   ): Promise<boolean> {
    let ngbModalOptions: NgbModalOptions = {
    //   backdrop : 'static',
      keyboard : false,
      size : dialogSize,
      centered: true
};
    const modalRef = this.modalService.open(DeletePopupComponent, ngbModalOptions);
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;
    modalRef.componentInstance.btnDiscardChange = btnDiscardChange;
    modalRef.componentInstance.contractNametoDelete = contractNametoDelete;
    modalRef.componentInstance.customValue = customValue;
    return modalRef.result;
  }

  public alert(
    title : any,
    message: any,
    componentToOpen : "",
    btnOkText : string = 'Save',
    btnCancelText : string = 'Cancel',
    btnDiscardChange : any = null,
    contractNametoDelete : any = null,
    customValue : any = null,
    dialogSize : string = 'md',
    
   ): Promise<boolean> {
    let ngbModalOptions: NgbModalOptions = {
    //   backdrop : 'static',
      keyboard : false,
      size : dialogSize,
      centered: true
};
    const modalRef = this.modalService.open(AlertComponent, ngbModalOptions);
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;
    modalRef.componentInstance.btnDiscardChange = btnDiscardChange;
    modalRef.componentInstance.contractNametoDelete = contractNametoDelete;
    modalRef.componentInstance.customValue = customValue;
    return modalRef.result;
  }
}
