import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { SharedService } from 'src/app/components/shared/service/shared.service';
import { ClintOnboardingService } from 'src/app/components/clint-onboarding/shared/service/clint-onboarding.service';
import { DisbursementFormService } from '../shared/service/disbursement-form.service';
import { DisbustDetail } from '../shared/model/disbustDetail';
import { DisbustmentUpfront } from '../shared/model/disbut-upfront';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-disbursement-up-front-recovery',
  templateUrl: './disbursement-up-front-recovery.component.html',
  styleUrls: ['./disbursement-up-front-recovery.component.css']
})
export class DisbursementUpFrontRecoveryComponent implements OnInit {

  outstandingForm: FormGroup;
  tableData2: any
  disbustment: DisbustDetail;
  upfront: any;
  total = 0.0;
  item = -1
  currentDate: any = new Date().toISOString().split("T")[0];
  constructor(
    private modalService: NgbModal,
    public router: Router,
    private formBuilder: FormBuilder,
    public _clintOnboardingSvc: ClintOnboardingService,
    public _sharedSvc: SharedService,
    private dismtService: DisbursementFormService,
    public translate: TranslateService
  ) {
    let c = localStorage.getItem('disbustment');
    if (c)
      this.disbustment = JSON.parse(c);
    // console.log(this.disbustment.crdtAppSeq);
    this.dismtService.getUpfrontSchedule(this.disbustment.crdtAppSeq, (res) => {
      //   // console.log(res);
      // })

      // this.dismtService.getUpfrontSchedule('5050503233774414',(res)=>{
      // console.log(res);
      if (res) {
        this.upfront = res;
        // for (let i = 0; i < this.upfront?.length; i++) {
        //   this.total+=
        // }
        this.upfront.forEach(it => {
          this.total += (it.amount);
        })
      }
    })
  }

  ngOnInit(): void {


  }


  openModal(content: any, i: number) {
    this.item = i;
    localStorage.removeItem("selectedOrderData");
    this.modalService.open(content, {
      // backdrop: "static",
      // keyboard: false,
      windowClass: "500",
      // backdropClass: 'light-blue-backdrop',
      centered: true
    });
  }

  onSubmitOutstandingForm(): void {

    if (this.outstandingForm.invalid) {
      return;
    }

    // this.router.navigate(['/dashboard']);

  }

  changeStatus() {

    this.upfront[this.item].post = true;
    this.item = -1;
    this.modalService.dismissAll('Save click');
  }
  UpdateDISBURSESTS(){    
    this.dismtService.UpdateDisbursement = {
      crdtAppSeq: this.disbustment.crdtAppSeq,
      stsKey: 703
    };
    this._sharedSvc.showSpinner3();
    this.dismtService.UpdateDsbmtAPI().subscribe({
      next: response => {
        if (response) {
          this._sharedSvc.success(this.translate.instant('toast.statusUpdated'));
          this._sharedSvc.hideSpinner3();
          this.modalService.dismissAll();
          this.router.navigate(['/disbursement-listing'])
        }
      },
      error: e => {
        this._sharedSvc.hideSpinner3();
        if (e.status == 401) {
          this._sharedSvc.error(this._sharedSvc.authMsg);
          this._sharedSvc.logout();
          return;
        }
        if (e?.error) {
          Object.entries(e?.error).forEach(([key, value]: any, index: number) => {
            this._sharedSvc.error(value);
          });
        }
        if (e?.error?.errorMessage) {
          this._sharedSvc.error(e?.error?.error);
        }
      },
    })
  }
}