import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms';
import { SharedService } from 'src/app/components/shared/service/shared.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-setup-add-product',
  templateUrl: './setup-add-product.component.html',
  styleUrls: ['./setup-add-product.component.css']
})
export class SetupAddProductComponent implements OnInit {


  disableTabs:boolean = false;
  constructor(
    private modalService: NgbModal,
    public router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public _sharedSvc: SharedService,
    public translate: TranslateService,
  ) {

  }

  ngOnInit(): void {
    if(this.route.snapshot.paramMap.get("id")){
      console.log("param ID " + this.route.snapshot.paramMap.get("id"));
      this.disableTabs = false;
    } else {
      this.disableTabs = true;
    }
  }

  addProducts(): void {
    this.router.navigate(['/setup-add-products']);
  }

}