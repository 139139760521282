<section>
    <div class="p-3 pb-0">
        <div class="row mb-3">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <div class="btnCenter otherOutstanding mb-3">
                    <h3 class="text-capitalize">{{'clientCreditCollateral.clientCreditInformation' |
                        translate}}</h3>
                </div>
            </div>
        </div>
        <form [formGroup]="creditAppForm">
            <div class="row">
                <div class="col-sm-6 col-md-6 col-lg-4 mb-3">
                    <ng-container *ngIf="!!userData">
                        <div class="form-floating">
                            <input type="text" style="text-transform: capitalize;"
                                class="form-control input-radius readonly-field" readonly [value]="userData[0]?.clntId">
                            <label>{{'common.clientId' | translate}}</label>
                        </div>
                    </ng-container>
                    <!-- <ng-container *ngIf="!_creditAppSvc.creditEdit">
                            <div class="form-floating ng-select-label">
                                <ng-select [searchable]="true" [clearable]="true" [clearOnBackspace]="true"
                                    [items]="_clintOnboardingSvc.getAllApprovedClientPG" bindLabel="clntId"
                                    formControlName="clntSeq" notFoundText="No client found"
                                    [(ngModel)]="_creditAppSvc.saveClientModel"
                                    (ngModelChange)="selectClientData($event)" required>
                                </ng-select>
                                <label>{{'common.clientId' | translate}}</label>
                            </div>
                        </ng-container> -->

                    <!-- <ng-container *ngIf="_creditAppSvc.creditEdit">
                            <div class="form-floating">
                                <input type="text" style="text-transform: capitalize;"
                                    class="form-control input-radius readonly-field" readonly
                                    [value]="creditData?.crdtAppSeq">

                                <label>Client ID</label>
                            </div>
                        </ng-container> -->

                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 mb-3">
                    <div class="form-floating">
                        <ng-container *ngIf="!_creditAppSvc.creditEdit">
                            <input type="text" style="text-transform: capitalize;"
                                class="form-control input-radius readonly-field" readonly
                                *ngIf="_clintOnboardingSvc?.saveClientModel?.frstNm"
                                [value]="_clintOnboardingSvc?.saveClientModel?.frstNm + ' ' +_clintOnboardingSvc?.saveClientModel?.lastNm">
                            <input type="text" style="text-transform: capitalize;"
                                class="form-control input-radius readonly-field" readonly
                                *ngIf="!_clintOnboardingSvc?.saveClientModel?.frstNm">
                        </ng-container>
                        <ng-container *ngIf="_creditAppSvc.creditEdit">
                            <input type="text" style="text-transform: capitalize;"
                                class="form-control input-radius readonly-field" readonly [value]="creditData?.clntNm ">
                        </ng-container>

                        <label>{{'common.clientName' | translate}}</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 col-md-6 col-lg-4 mb-3">
                    <div class="form-floating">
                        <!-- <input type="text" style="text-transform: capitalize;"
                            class="form-control input-radius readonly-field" readonly
                            [(ngModel)]="_creditAppSvc.creditAppModel.crdtAppSeq" formControlName="crdtAppSeq"> -->
                        <input type="text" style="text-transform: capitalize;"
                            class="form-control input-radius readonly-field" readonly formControlName="crdtAppSeq">
                        <label>{{'clientCreditCollateral.creditApplicationID' |
                            translate}}</label>
                    </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 mb-3">
                    <div class="form-floating">
                        <!-- <input type="text" style="text-transform: capitalize;"
                            class="form-control input-radius readonly-field" readonly
                            [(ngModel)]="_creditAppSvc.creditAppModel.crdtCyclNum" formControlName="crdtCyclNum"> -->
                        <input type="text" style="text-transform: capitalize;"
                            class="form-control input-radius readonly-field" readonly formControlName="crdtCyclNum">
                        <label>{{'clientCreditCollateral.creditCycle#' |
                            translate}}</label>
                    </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 mb-3">
                    <div class="form-floating">
                        <input type="text" style="text-transform: capitalize;"
                            class="form-control input-radius readonly-field" readonly>
                        <label>{{'clientCreditCollateral.previousCreditAmount' |
                            translate}}</label>
                    </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 mb-3">
                    <div class="form-floating">
                        <!-- <select class="form-control input-radius form-select " name="prdGroupName"
                            [ngModelOptions]="{ standalone: true }" [(ngModel)]="_creditAppSvc.prdGroupName"
                            [disabled]="_creditAppSvc.creditApp === true && allowedRoutes.length > 0 && allowedRoutes[0].rolId === 'CMO'">
                            <option hidden value=""></option>
                            <option *ngFor="let item of _picklistService.allProduct_Data; let i = index ;"
                                [value]=item?.productGrpSeq>
                                {{item.prdGroupName}}
                            </option>
                        </select> -->
                        <select class="form-control input-radius form-select " name="prdGroupName" id="prdGroupName"
                            formControlName="prdGroupName"
                            [class.is-invalid]="prdGroupName.invalid && (prdGroupName.dirty || prdGroupName.touched)"
                            [disabled]="_creditAppSvc.creditApp === true && allowedRoutes.length > 0 && allowedRoutes[0].rolId === 'CMO'">
                            <option hidden value="null"></option>
                            <option *ngFor="let item of _picklistService.allProduct_Data; let i = index ;"
                                [value]=item?.productGrpSeq>
                                {{item.prdGroupName}}
                            </option>
                        </select>
                        <label>{{'clientCreditCollateral.productGroup' |
                            translate}}</label>
                        <div *ngIf="prdGroupName.invalid && (prdGroupName.dirty || prdGroupName.touched)"
                            class="invalid-feedback">
                            <div *ngIf="prdGroupName.errors?.['required']">
                                {{'clientCreditCollateral.requestAmountReq' |
                                translate}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 mb-3">
                    <div class="form-floating">
                        <ng-container
                            *ngIf="_creditAppSvc.creditApp === true && allowedRoutes.length > 0 && allowedRoutes[0].rolId === 'CMO' else newCase">
                            <select class="form-control input-radius form-select"
                                [value]="_creditAppSvc.creditAppModel.prdSeq"
                                [disabled]="_creditAppSvc.creditApp === true">
                                <ng-container *ngIf="_creditAppSvc.prdGroupName">
                                    <option *ngFor="let item of _picklistService.allProduct_Data; let i = index ;"
                                        [value]=item?.productSeq>
                                        <span>{{item.productName}}</span>
                                    </option>
                                </ng-container>
                            </select>
                        </ng-container>
                        <ng-template #newCase>
                            <select class="form-control input-radius form-select" id="prdSeq" name="prdSeq"
                                formControlName="prdSeq" (ngModelChange)="product()"
                                [class.is-invalid]="prdSeq.invalid && (prdSeq.dirty || prdSeq.touched)">
                                <!-- <ng-container *ngIf="_creditAppSvc.prdGroupName"> -->
                                <option hidden value="null"></option>
                                <option *ngFor="let item of _picklistService.allProduct_Data; let i = index ;"
                                    [value]=item?.productSeq>
                                    <span>{{item.productName}}</span>
                                </option>
                                <!-- </ng-container> -->
                            </select>
                        </ng-template>
                        <div *ngIf="prdSeq.invalid && (prdSeq.dirty || prdSeq.touched)" class="invalid-feedback">
                            <div *ngIf="prdSeq.errors?.['required']">
                                {{'clientCreditCollateral.productReq' |
                                translate}}
                            </div>
                        </div>
                        <label>{{'common.product' | translate}}</label>
                    </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 mb-3">
                    <div class="form-floating">
                        <!-- <input type="number" class="form-control input-radius readonly-field" readonly
                            name="noOfInstallment" [ngModelOptions]="{ standalone: true }"
                            [(ngModel)]="_creditAppSvc.noOfInstallment"> -->
                        <input type="number" class="form-control input-radius readonly-field" readonly
                            name="noOfInstallment" formControlName="noOfInstallment">
                        <label> {{'clientCreditCollateral.noOfInstallments' |
                            translate}}</label>
                    </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 mb-3">
                    <div class="form-floating">
                        <ng-container
                            *ngIf="_creditAppSvc.creditApp === true && allowedRoutes.length > 0 && allowedRoutes[0].rolId === 'CMO' else check">
                            <input type="number" class="form-control input-radius "
                                [value]="_creditAppSvc.creditAppModel.rqstdAmt" min="1" max="500000"
                                [disabled]="_creditAppSvc.creditApp === true">
                        </ng-container>
                        <ng-template #check>
                            <!-- <input type="number" class="form-control input-radius "
                                [(ngModel)]="_creditAppSvc.creditAppModel.rqstdAmt" formControlName="rqstdAmt" min="1"
                                max="500000"
                                [class.is-invalid]="rqstdAmt.invalid && (rqstdAmt.dirty || rqstdAmt.touched)"> -->
                            <input type="number" class="form-control input-radius " formControlName="rqstdAmt" min="1"
                                max="500000"
                                [class.is-invalid]="rqstdAmt.invalid && (rqstdAmt.dirty || rqstdAmt.touched)">
                        </ng-template>
                        <div *ngIf="rqstdAmt.invalid && (rqstdAmt.dirty || rqstdAmt.touched)" class="invalid-feedback">
                            <div *ngIf="rqstdAmt.errors?.['required']">
                                {{'clientCreditCollateral.requestAmountReq' |
                                translate}}
                            </div>
                            <div *ngIf="rqstdAmt.errors?.['min']">
                                {{'clientCreditCollateral.least1DigitReq' |
                                translate}}
                            </div>
                            <div *ngIf="rqstdAmt.errors?.['max']">
                                {{'clientCreditCollateral.max500kReq' |
                                translate}}
                            </div>
                        </div>

                        <label>{{'clientCreditCollateral.requestAmount' |
                            translate}}</label>
                    </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 mb-3" *ngIf="_creditAppSvc.creditAppModel.rcmndAmt > 1">
                    <div class="form-floating">
                        <!-- <input type="number" class="form-control input-radius readonly-field" readonly
                            [(ngModel)]="_creditAppSvc.creditAppModel.rcmndAmt" formControlName="rcmndAmt" min="1"
                            max="500000" [class.is-invalid]="rcmndAmt.invalid && (rcmndAmt.dirty || rcmndAmt.touched)"> -->
                        <input type="number" class="form-control input-radius readonly-field" readonly
                            formControlName="rcmndAmt" min="1" max="500000"
                            [class.is-invalid]="rcmndAmt.invalid && (rcmndAmt.dirty || rcmndAmt.touched)">
                        <div *ngIf="rcmndAmt.invalid && (rcmndAmt.dirty || rcmndAmt.touched)" class="invalid-feedback">
                            <div *ngIf="rcmndAmt.errors?.['required']">
                                {{'clientCreditCollateral.recAmountReq' |
                                translate}}
                            </div>
                            <div *ngIf="rcmndAmt.errors?.['min']">
                                {{'clientCreditCollateral.least1DigitReq' |
                                translate}}
                            </div>
                            <div *ngIf="rcmndAmt.errors?.['max']">
                                {{'clientCreditCollateral.atMost6DigitReq' |
                                translate}}
                            </div>
                        </div>
                        <label> {{'clientCreditCollateral.recommendedAmount' |
                            translate}}</label>
                    </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 mb-3">
                    <div class="form-floating">
                        <ng-container
                            *ngIf="_creditAppSvc.creditApp === true && allowedRoutes.length > 0 && allowedRoutes[0].rolId === 'CMO' else check2">
                            <input type="number" class="form-control input-radius " name="aprvdAmt" min="1" max="500000"
                                [value]="_creditAppSvc.creditAppModel.aprvdAmt"
                                [class.is-invalid]="aprvdAmt.invalid && (aprvdAmt.dirty || aprvdAmt.touched)"
                                [disabled]="_creditAppSvc.creditApp === true">
                        </ng-container>

                        <ng-template #check2>
                            <!-- <input type="number" class="form-control input-radius "
                                [(ngModel)]="_creditAppSvc.creditAppModel.aprvdAmt" formControlName="aprvdAmt" min="1"
                                max="500000"
                                [class.is-invalid]="aprvdAmt.invalid && (aprvdAmt.dirty || aprvdAmt.touched)"> -->
                            <input type="number" class="form-control input-radius " formControlName="aprvdAmt" min="1"
                                max="500000"
                                [class.is-invalid]="aprvdAmt.invalid && (aprvdAmt.dirty || aprvdAmt.touched)">
                        </ng-template>
                        <div *ngIf="aprvdAmt.invalid && (aprvdAmt.dirty || aprvdAmt.touched)" class="invalid-feedback">
                            <div *ngIf="aprvdAmt.errors?.['required']">
                                {{'clientCreditCollateral.aprAmountReq' |
                                translate}}
                            </div>
                            <div *ngIf="aprvdAmt.errors?.['min']">
                                {{'clientCreditCollateral.least1DigitReq' |
                                translate}}
                            </div>
                            <div *ngIf="aprvdAmt.errors?.['max']">
                                {{'clientCreditCollateral.atMost6DigitReq' |
                                translate}}
                            </div>
                        </div>
                        <label>{{'clientCreditCollateral.approvedAmount' |
                            translate}}</label>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="button_div">
                        <button class="btn login-button-class" *ngIf="_creditAppSvc.creditApp"
                            (click)="_sharedSvc.activeCredit = 2">{{'common.continue' | translate}}</button>
                        <button *ngIf="!_conditionsService.readOnlyView_Credit"
                            class=" btn login-button-class secondaryBtn" [disabled]="_sharedSvc.loading"
                            [class.pointerNone]="_sharedSvc.loading" (click)="onSubmit()"
                            [disabled]="!creditAppForm.valid || _sharedSvc.loading">{{'common.save' |
                            translate}}</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</section>